import React from "react";
import { Modal } from "react-bootstrap";
import Skeleton from "../../../../../Components/Common/Skeleton";

const EmailDetailSkeleton = ({ isEmailMode }) => {
  const ModalBody = () => {
    return (
      <div className="d-flex w-100 vh-100 rounded-10 overflow-hidden form-row mx-0">
        <div className="w-100 d-flex flex-column border-right-2 border-gray-200 overflow-x-auto overflow-y-inherit mail-info col px-0">
          <Modal.Header className="border-bottom py-2 pl-10 pr-15 flex-wrap gap-10 flex-nowrap modal-header">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center gap-10">
                <div className="d-flex gap-5 align-items-center">
                  <Modal.Title>
                    <Skeleton width="130px" height="20px" />
                  </Modal.Title>
                </div>
              </div>
              <div className="d-flex align-items-center gap-5 flex-wrap mb-0">
                <Skeleton count={2} width="40px" height="15px" containerClassName="d-flex gap-5 mb-0" />
              </div>
            </div>

            <div className="d-flex align-items-center ml-auto gap-15">
              <Skeleton width="130px" height="30px" />
              <Skeleton width="32px" height="32px" />
              <div className="d-flex align-item-center gap-5">
                <Skeleton count={2} width="32px" height="32px" containerClassName="d-flex gap-5" />
              </div>
              <Skeleton width="32px" height="32px" />
              <Skeleton width="32px" height="32px" />
              <div className="d-flex align-item-center gap-5">
                <Skeleton count={2} width="32px" height="32px" containerClassName="d-flex gap-5" />
              </div>
              {isEmailMode !== "card" && <Skeleton width="32px" height="32px" />}
            </div>
          </Modal.Header>
          <Modal.Body className="px-0 pt-15 d-flex flex-column gap-10">
            <div className="d-flex align-items-start gap-15 px-15">
              <Skeleton circle={true} circleSize="35px" />
              <div className="d-flex flex-column w-100">
                <div className="d-flex align-items-center gap-10 mb-1 pointer">
                  <Skeleton width={80} height={18} />
                  <Skeleton width={130} height={18} />
                  <Skeleton width={100} height={18} />
                </div>
                <div className="clamp-1">
                  <Skeleton width={385} />
                </div>
              </div>
              <Skeleton width={150} height={18} />
              <Skeleton count={3} width={20} height={20} containerClassName="d-flex gap-10" />
            </div>
          </Modal.Body>
        </div>
        <div className="273" style={{ width: "273px" }}>
          <div className="py-10 px-10 bg-white mb-10 d-flex flex-column gap-10">
            <div className="bg-brand-50 py_5 px-1 rounded-5 gap-5 m-10">
              <div className="d-flex align-items-start gap-5">
                <Skeleton width={25} height={24} />
                <div className="justify-content-between d-flex w-100">
                  <div>
                    <Skeleton width={120} height={16} />
                    <Skeleton width={80} height={16} />
                  </div>
                  <Skeleton height={20} width={20} />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-5">
              <Skeleton height={15} width={200} />
              <div>
                <Skeleton count={2} width="50%" height={28} containerClassName="d-flex gap-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-white rounded-5 flex-grow-1">
      {isEmailMode === "card" ? (
        <ModalBody />
      ) : (
        <Modal
          show={true}
          dialogClassName="modal-fullpage modal-dialog-scrollable modal-email-dialog vh-100"
          animation={false}
        >
          <ModalBody />
        </Modal>
      )}
    </div>
  );
};

export default EmailDetailSkeleton;
