import moment from 'moment'
import React, { useRef } from 'react';
import { createPortal } from "react-dom";
import { useEffect } from 'react';
import { useState } from 'react'
import { DateTimeFormatUtils, getStorage } from '../../../../../../services';
import DateTimePicker from './DateTimePicker'

const DateWrapper = React.memo(({ defaultDate, isShowTime, onChange, OnBlurUpdate, onRemoveDate, placeholder, hideShowSelectDate, className, disabled,inputClassName, dateFormat, timeFormat, isLoader=false, systemGenerated=false, popupPlacement, disableUptoThisday, disableFromThisday, isNewDriverPayScreen,displayTimeZone }) => {
    const [show,setShow] = useState(false);
    const datePickerCellRef = useRef();
    let timeZone = displayTimeZone || getStorage("timeZone");

    useEffect(() => {
      const keydown = (e) => {
        if (e.keyCode === 9) setShow(false);
      }
      document.addEventListener('keydown', keydown);
      return () => {
        document.removeEventListener('keydown', keydown);
      }
    }, [defaultDate]);  
    return (
    <>
        <input
            className={`position-relative form-control loader-black-field  ${systemGenerated ? 'bg-primary-100' : 'transition-white-field'} ${inputClassName ? inputClassName : "" } ${isLoader ? "loader" :""} ${isNewDriverPayScreen && "form-control-focus-none"}`}
            type="text"
            disabled={disabled}
            placeholder={isLoader ? 'Checking Data' : placeholder || "Select"}
            value={
                (defaultDate && !isShowTime)
                    ? moment(defaultDate).tz(timeZone)?.format(dateFormat ||DateTimeFormatUtils.fullDateFormat())
                    : (defaultDate && isShowTime)
                        ? moment(defaultDate).tz(timeZone)?.format(`${timeFormat ? timeFormat : DateTimeFormatUtils.fullDateTimeFormat()}`)
                        : ""
            }
            onFocus={()=> setShow(!show)}
            ref={datePickerCellRef}
        />
        {(show && !isLoader) && createPortal(
           <DateTimePicker 
            onRemoveDate={onRemoveDate}
            displayTimeZone={timeZone}
            OnBlurUpdate={OnBlurUpdate}
            onChange={onChange}
            isShowTime={isShowTime}
            defaultDate={defaultDate
              ? moment(defaultDate).tz(timeZone)?.format("lll")
              : ""}
            hideShowSelectDate={hideShowSelectDate}
            setShow={setShow}
            className={className}
            popupPlacement={popupPlacement}
            disableUptoThisday={disableUptoThisday}
            disableFromThisday={disableFromThisday}
            datePickerCellRef={datePickerCellRef}
          />, document.body // Sending to document body so the X,Y coordinates get according to the viewport.
        )}
    </>
  )
}, (prevProps, nextProps) => {
    if (prevProps.defaultDate === nextProps.defaultDate && prevProps.disableUptoThisday === nextProps.disableUptoThisday && prevProps.disableFromThisday === nextProps.disableFromThisday) {
      return true;
    }
    return false; 
  });

export default DateWrapper