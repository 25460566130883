//  This file contains form input with State Implementation
import React, { useEffect, useState, useRef} from "react";
import {  IconEyeShow, IconEyeHidden } from "../Common/Icons";
import { getDecryptedValues } from "../../pages/tms/Driver/actionCreators";
import _debounce from 'lodash/debounce';

// input type
const EncryptFormInput = (props) => {
    const {
        formsTouched,
        formErrors,
        label,
        name,
        value,
        onValChange,
        placeholder,
        type,
        isRequired,
        className,
        onBlur,
        driverId,
        showEncryptedValue,
        setShowEncryptedValue,
        setEncryptedData,
        changeVal,
        editedValues,
        inputClassName
    } = props;
    const inputRef = useRef();
    let err =
        formErrors?.[name] && typeof formErrors[name] === "string"
            ? formErrors[name]
            : null;
    let touched = formsTouched[name];
    
    const [dataValue, setDataValue] = useState(value);
    const [showValue, setShowValue] = useState(false);
    const [loader, setLoader] = useState(false);
    const handleDecryptVal = (key) => {
        let data = {
          keyName: key,
          driverId: driverId
        }
        setLoader(true)
        setShowEncryptedValue(false)
        getDecryptedValues(data).then((res) => {
            setDataValue(res[key])
            setLoader(false)
        })
        .catch((err) => {
            setLoader(false);
        })
    }

    
    const onChangeValue = (data) => {
        if(!/^\S*$/.test(data)) {
            return;
        }
        if(!/^[a-zA-Z0-9_.-]*$/.test(data)) {
            return;
        }
        setDataValue(data);
        if(value !== data) {
            onValChange(name, data);
        }
    }
    
    useEffect(() => {
      setTimeout(() => {
        inputRef.current.value = dataValue ? dataValue : value ? "*******" : "";
      }, 500)
    }, [dataValue]);


    useEffect(() => {
        if(value !== dataValue) {
            setShowValue(false);
        }
    }, [value])

    useEffect(() => {
        if(!value && !showValue) {
            setDataValue("")
        } else if(setEncryptedData && !showValue) {
            setDataValue("*******")
        } else {
           showValue && handleDecryptVal(name);
        }
    }, [showValue])

    useEffect(() => {
        if(!value) {
            setDataValue("")
        }else if((setEncryptedData && !showValue) || showEncryptedValue) {
            setDataValue("*******");
            setShowValue(false);
        }
    }, [setEncryptedData, showEncryptedValue])

  // throttling user input
  const debounceFn = _debounce((val) => {
    onChangeValue(val);
  }, 1000);

    return (
        <div className={`form-group ${className} ${err && touched && "error"}`}>
            <label className="col-form-label">
                {isRequired && <span className="text-danger">* </span>}
                {label}
            </label>
            <div className="input-wrapper">
                <input
                    id={name}
                    type={type}
                    name={name}
                    ref={inputRef}
                    className={`form-control pr-35 ${inputClassName}`}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    placeholder={placeholder}
                    disabled={setEncryptedData  &&  !showValue && (editedValues !== dataValue)}
                    onChange={(e) => debounceFn(e.target.value)}
                    onBlur={(e) => onBlur && onBlur(name, e.target.value)}
                />
                {setEncryptedData && (dataValue || value) && 
                <div class="input-icon" onClick={() => {!loader && setShowValue(!showValue);}}> 
                    {(showValue || editedValues === dataValue) ? <IconEyeHidden /> : <IconEyeShow />}
                </div>
                }
            </div>
            {err && touched && (
                <small className="text-danger">{formErrors[name]}</small>
            )}
        </div>
    );
};



export {
    EncryptFormInput,
};