import moment from 'moment';
import { getTimeZone } from 'pages/tms/NewDispatcher/constants';
import { useEffect, useState } from 'react';
import { getStorage } from '../../../../../../services';
import { downloadCsvFromBuffer, formatTimeZoneString, getDataForChartFromDB } from '../../helper';
import CounterCard from '../Common/CounterCard';
import SkeletonCard from '../Common/SkeletonCard';
import PieDonutChart from './PieDonutChart';
import { REPORT_TYPES } from '../../constants';

const ExpandedPieDonutChart = (props) => {
    const {
        startDate,
        endDate,
        reportType
    } = props

    const [pieChartData, setPieChartData] = useState({})
    const [exportPieChartData, setExportPieChartData] = useState({})
    const [isPopup, setIsPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fromDate, setFromDate] = useState(startDate)
    const [toDate, setToDate] = useState(endDate)
    
    const getDataForPieChart = async (from, to, isDownload = false) => {
        try {
            if(!isDownload) setIsLoading(true)

            const _startDate = from || fromDate
            const _endDate = to || toDate
            
            let payload = {
                chartType: reportType,
                fromDate:  _startDate,
                toDate:  _endDate
            }
    
            if (reportType === REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE) payload.limit = 3
            if (isDownload) payload.isDownload = true
            let responseData = await getDataForChartFromDB(payload, reportType)
            
            if(!isDownload) setPieChartData(responseData)
            setIsLoading(false)
            // if (isPopup) {
                // setExportPieChartData(responseData?.reportData)
            // }
            return responseData
        } catch (error) {
            console.log("🚀 ~ getDataForPieChart ~ error:", error)
            setIsLoading(false)
            if(!isDownload) setPieChartData({})
        }
    }

    const downloadCSV = async () => {
        const csvBuffer = await getDataForPieChart(startDate, endDate, true)
        downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`)
    }

    useEffect(() => {
        getDataForPieChart()
    }, [])

    useEffect(() => {
        if(moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate))) return
        setFromDate(startDate)
        setToDate(endDate)
        getDataForPieChart(startDate, endDate)
    }, [startDate, endDate])

    if (isLoading)
        return <SkeletonCard style ={{ width: "100%", height: "100%"}}/>
    else
    return (
        <>
            <div className='rounded-5 bg-white d-flex flex-column h-100 justify-content-between'>
                <CounterCard {...{ ...props, ...pieChartData }}
                    downloadCSV={downloadCSV}
                    fromExpandedCard={true}
                    isPopup={isPopup}
                    setIsPopup={setIsPopup}
                />
                <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
                    <PieDonutChart {
                        ...{ ...props, ...pieChartData }}
                        isPopup={isPopup}
                        downloadCSV={downloadCSV}
                        setIsPopup={setIsPopup}
                        // getDataForPieChart={getDataForPieChart}
                        // exportPieChartData={exportPieChartData}
                    />
                    <div className='text-gray-300 font-12 font-weight-normal d-flex justify-content-end py-10 pr-2'>
                        Last updated at: {`${moment(pieChartData?.lastUpdatedAt || new Date())
                            .tz(getStorage("timeZone")).format('MM-DD-YY • hh:mm A')} (${formatTimeZoneString(getStorage("timeZone"))})`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpandedPieDonutChart
