import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Select from 'react-select';
import { getCustomerAsyncSearch, 
    getCitiesBySearch,
    getChassisPickCustomersAsyncSearch, 
    getChassisTerminationCustomersAsyncSearch,
    getCSRAsyncSearch,
    guidGenerator,
    getCommodityProfile,
} from './utils';
import { smallSelectStyle } from '../../assets/js/select-style';
import { getCarrierByUserId, getStorage, makeOptionsForSelect } from '../../services';
import { STOPOFF_TYPES } from '../../utils';
import { useCurrentUser } from 'hooks';
import SearchPlaces from 'pages/tms/AddressComponent/SearchPlaces';

let selectedGroup = [];
let selectedField = null;
let changableId = null
let changableField = {}
var SelectMulti = (props) => {
    const {
      onChange,
      onDelete,
      selectedValue,
      emptyValue,
      values,
      className,
      disabled = false,
      chassisOwner,
      chassisSize,
      chassisType,
      containerSize,
      containerType,
      containerOwner,
      customers, 
      callers ,
      chassisTerms,
      consigneesOptions,
      shippers,
    } = props;
    const [current] = useState(guidGenerator())
    const [commodityList, setCommodity] = useState([]);
    const[cityGroups , setCityGroups] = useState([]);
    const [zipCodeGroups, setZipCodeGroups] = useState([]);
    const {currentCarrierId} = useCurrentUser();

    useEffect(() => {
        getCommodityProfile().then((response) => {
          setCommodity(response.data.data);
        });
        getCarrierByUserId(currentCarrierId).then((response) => {
            let details = response.data.data;
            if (
              details &&
              details.groupedCities &&
              details.groupedCities.length > 0
            ) {
              setCityGroups(
                details.groupedCities.map((city) => ({
                  label: city.name,
                  value: city.name,
                }))
              );
            }
            if (details?.groupedZipcodes?.length > 0) {
                setZipCodeGroups(
                  details.groupedZipcodes.map((city) => ({
                    label: city.name,
                    value: city.name,
                  }))
                );
              }
          });
      }, []);

    const { measureUnitsReducer } = useSelector(state => state) ?? {};
    let groupedProfiles = [], measureUnits = measureUnitsReducer;
    const defaultCSROptions = props.csrList.map(csr => ({label:csr.name,value:csr._id}))
    const defaultStopOffOptions =  STOPOFF_TYPES()?.filter((d) => !d.isDeleted)?.map(tp => ({label:tp.name,value:tp._id}))
    if (measureUnits && measureUnits.groupedProfiles) {
      groupedProfiles = measureUnits.groupedProfiles;
    }

    const handleChange = (value, id) => {
        if(id){
            changableId = id;
            changableField = {[id] : {removedFields : [],fieldToAdd: [] }}
            selectedValue.forEach(item => {
                let val = JSON.parse(item);
                if(val.selectedGroupShipper || val.selectedGroupConsignee || val.selectedGroupCustomer) {
                    changableField?.[id]?.removedFields.push(val)
                }
            })
        }
        if(value.length > selectedValue.length) {
            onChange(JSON.stringify(value[value.length - 1])); 
        } else {
            for(const val of value ){
                if(val.selectedGroupShipper) delete val.selectedGroupShipper
                if(val.selectedGroupConsignee) delete val.selectedGroupConsignee
                if(val.selectedGroupCustomer) delete val.selectedGroupCustomer
            }
            const stringValue = value.map((val) => JSON.stringify(val));
            let valToRemove = '';
            selectedValue.map((sel_val) => {
                if(stringValue.indexOf(sel_val) === -1) valToRemove = sel_val;
            })
            onDelete(valToRemove);
        }
    }

    if(changableField?.[current]?.removedFields?.length){
        let val = changableField?.[current]?.removedFields.shift()
        onDelete(JSON.stringify(val));
        changableField?.[current]?.fieldToAdd.push(val)
    }
    if(changableField?.[current]?.removedFields.length == 0 && changableField?.[current]?.fieldToAdd.length ){
        let foundValue = selectedValue.find(item => {
            let val = JSON.parse(item);
            if(val.selectedGroupShipper || val.selectedGroupConsignee || val.selectedGroupCustomer) {
                return item
            }
        })
        if(!foundValue){
            let val = changableField?.[current]?.fieldToAdd.shift()
            if(val.selectedGroupShipper) delete val.selectedGroupShipper
            if(val.selectedGroupConsignee) delete val.selectedGroupConsignee
            if(val.selectedGroupCustomer) delete val.selectedGroupCustomer;
            onChange(JSON.stringify(val))
        }
    }

    const handleProfileGroupChange = (id) => {
        if(id == current){
            const cust = selectedGroup.shift();

            if (typeof cust === 'string') {
                onDelete(cust);
            } else {
                const changedValue = _.cloneDeep(selectedValue);
                changedValue.push(cust);    
                handleChange(changedValue);
            }
        }
    }

    if (selectedGroup.length > 0 && values[0].value === selectedField && changableId == current) {
        handleProfileGroupChange(current);
    }

    return (
        <div style={{width: 350}}>
            {values[0].value === "caller" && 
             <React.Fragment>
             {!disabled && groupedProfiles.map((obj) => {
               return (
                 <span
                   onClick={() => {
                     selectedGroup = [
                         ...selectedValue,
                         ...obj.customers.map((customer) => ({ label: customer.company_name, value: customer._id, selectedGroupShipper: obj._id}))
                     ];
                     selectedField = values[0].value;
                     changableId = current
                     handleProfileGroupChange(current);
                   }}
                   className="mb-2 mr-1 badge badge-info pointer font-12 empty-1"
                 >
                   {obj.name}
                 </span>
               );
             })}
                <AsyncSelect
                    isMulti
                    cacheOptions
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    loadOptions={(str)=>getCustomerAsyncSearch(str,["ALL","caller"])}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                    defaultOptions={makeOptionsForSelect(
                        callers && callers.concat(customers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                />
                </React.Fragment>
            }
            {values[0].value === "shipper" && 
                <React.Fragment>
                    {!disabled && groupedProfiles.map((obj) => {
                      return (
                        <span
                          onClick={() => {
                            selectedGroup = [
                                ...selectedValue,
                                ...obj.customers.map((customer) => ({ label: customer.company_name, value: customer._id, selectedGroupShipper: obj._id}))
                            ];
                            selectedField = values[0].value;
                            changableId = current
                            handleProfileGroupChange(current);
                          }}
                          className="mb-2 mr-1 badge badge-info pointer font-12 empty-1"
                        >
                          {obj.name}
                        </span>
                      );
                    })}
                    <AsyncSelect
                        isMulti
                        cacheOptions
                        value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                        onChange={val => handleChange(val, current)}
                        loadOptions={(str)=>getCustomerAsyncSearch(str,["ALL","shipper"])}
                        isDisabled={disabled}
                        styles={smallSelectStyle}
                        menuPlacement="auto"
                        defaultOptions={makeOptionsForSelect(shippers, "company_name", "address.address", "_id")}
                    />
                </React.Fragment>
            }
            {values[0].value === "consignee" && 
                <React.Fragment>
                    {!disabled && groupedProfiles.map((obj) => {
                      return (
                        <span
                          onClick={() => {
                            selectedGroup = [
                                ...selectedValue,
                                ...obj.customers.map((customer) => ({ label: customer.company_name, value: customer._id, selectedGroupConsignee: obj._id}))
                            ];
                            selectedField = values[0].value;
                            changableId = current;
                            handleProfileGroupChange(current);
                          }}
                          className="mb-2 mr-1 badge badge-info pointer font-12 empty-1"
                        >
                          {obj.name}
                        </span>
                      );
                    })}
                    <AsyncSelect
                        isMulti
                        cacheOptions
                        value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                        onChange={val => handleChange(val, current)}
                        loadOptions={(str)=>getCustomerAsyncSearch(str,["ALL","consignee"])}
                        isDisabled={disabled}
                        styles={smallSelectStyle}
                        menuPlacement="auto"
                        defaultOptions={makeOptionsForSelect(
                            consigneesOptions,
                            "company_name",
                            "address.address",
                            "_id"
                          )}
                    />
                </React.Fragment>
            }
             {values[0].value === "cityState" && 
                <AsyncSelect
                    isMulti
                    cacheOptions
                    isClearable={true}
                    placeholder="Enter Carrier Group city"
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    loadOptions={(strSearch) => getCitiesBySearch(strSearch, true)}
                    styles={smallSelectStyle}     
                    menuPlacement="auto"
                />
            }
            {values[0].value === "assignedCSR" && 
                <AsyncSelect
                    isMulti
                    cacheOptions
                    isClearable={true}
                    placeholder="Select CSR"
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    defaultOptions={defaultCSROptions}
                    loadOptions={getCSRAsyncSearch}
                    styles={smallSelectStyle}     
                    menuPlacement="auto"
                />
            }
            {values[0].value === "stopOff" && 
                <Select
                    isMulti
                    isClearable={true}
                    placeholder="Select StopOff types"
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={defaultStopOffOptions ?? []}
                    styles={smallSelectStyle}     
                />
            }
            {values[0].value === "chassisPick" && 
                <AsyncSelect
                    isMulti
                    cacheOptions
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    loadOptions={getChassisPickCustomersAsyncSearch}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                    defaultOptions={makeOptionsForSelect(chassisTerms, "company_name", "address.address", "_id")}
                    />
            }
            {values[0].value === "emptyOrigin" && 
            <React.Fragment>
            {!disabled && groupedProfiles.map((obj) => {
              return (
                <span
                  onClick={() => {
                    selectedGroup = [
                        ...selectedValue,
                        ...obj.customers.map((customer) => ({ label: customer.company_name, value: customer._id, selectedGroupCustomer: obj._id}))
                    ];
                    selectedField = values[0].value;
                    changableId = current
                    handleProfileGroupChange(current);
                  }}
                  className="mb-2 mr-1 badge badge-info pointer font-12 empty-1"
                >
                  {obj.name}
                </span>
              );
            })}
                <AsyncSelect
                    isMulti
                    cacheOptions
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={val => handleChange(val, current)}
                    loadOptions={(str)=>getCustomerAsyncSearch(str,["ALL","containerReturn","shipper"])}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                    defaultOptions={makeOptionsForSelect(shippers, "company_name", "address.address", "_id")}
                />
            </React.Fragment>
            }
            {values[0].value === "chassisTermination" && 
                <AsyncSelect
                    isMulti
                    cacheOptions
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    loadOptions={getChassisTerminationCustomersAsyncSearch}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                    defaultOptions={makeOptionsForSelect(chassisTerms, "company_name", "address.address", "_id")}
                />
            }
            {values[0].value === "containerType" && 
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={containerType.map((d, i) => ({ value: d._id, label: d.name }))}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                />
            }
            {values[0].value === "containerSize" && 
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={containerSize.map((d, i) => ({ value: d._id, label: d.name }))}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                />
            }
            {values[0].value === "containerOwner" && 
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={containerOwner.map((d, i) => ({ value: d._id, label: d.company_name }))}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                />
            }
            {values[0].value === "chassisType" &&
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={chassisType.map((d, i) => ({ value: d._id, label: d.name }))}
                    isDisabled={disabled}
                    menuPlacement="auto"
                />
            }
            {values[0].value === "chassisSize" && 
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={chassisSize.map((d, i) => ({ value: d._id, label: d.name }))}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                />
            }
            {values[0].value === "chassisOwner" && 
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={chassisOwner.map((d, i) => ({ value: d._id, label: d.company_name }))}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                />
            }
            {
                values[0].value === "commodityProfile" &&
                <Select
                isMulti
                value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                onChange={handleChange}
                options={commodityList?.map((ele) => ({
                    label: ele?.name,
                    value: ele?.name,
                  }))}
                isDisabled={disabled}
                styles={smallSelectStyle}
                menuPlacement="auto"
            />
            }
            {
                values[0].value === "cityGroups" &&
                <Select
                isMulti
                value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                onChange={handleChange}
                options={cityGroups}
                isDisabled={disabled}
                styles={smallSelectStyle}
                menuPlacement="auto"
            />
            }
            {
                values[0].value === "zipCodeGroups" &&
                <Select
                isMulti
                value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                onChange={handleChange}
                options={zipCodeGroups}
                isDisabled={disabled}
                styles={smallSelectStyle}
                menuPlacement="auto"
            />
            }
            {
                values[0].value === "state" &&
                <SearchPlaces
                    placeHolder="Enter State"
                    onChange={handleChange}
                    placeType={"STATE"}
                    defaultValue={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val))}
                    isMulti={true}
                />
            }
            { ["terminal", "deliveryDay", "overWeight", "type_of_load", "dropped", "sourceCountry", "destinationCountry", "deliveryCountry", "streetTurnType", "combineTripType"].indexOf(values[0].value) > -1 &&
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={values.filter((val, i) => i!=0)}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                />
            }
            { ['hot', 'hazmat', 'liquor', 'isGenset', 'domestic', 'ev', 'waste', 'gdp', 'isRail', 'scale', 'dualTransaction', 'isStreetTurn'].indexOf(values[0].value) > -1 &&
                <Select
                    isMulti
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    options={values.filter((val, i) => i!=0)}
                     isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                />
            }
            {values[0].value === "dropLocation" && 
                <AsyncSelect
                    isMulti
                    cacheOptions
                    value={selectedValue && selectedValue.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    loadOptions={(str)=>getCustomerAsyncSearch(str)}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                    defaultOptions={makeOptionsForSelect(
                        callers && callers.concat(customers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                />
            }
            {
                values[0]?.value === "zipCode" &&
                <AsyncCreatableSelect
                    isMulti
                    value={selectedValue?.length > 0 && selectedValue.map((sel_val) => JSON.parse(sel_val)) }
                    onChange={handleChange}
                    defaultOptions={[]}
                    isDisabled={disabled}
                    styles={smallSelectStyle}
                    menuPlacement="auto"
                    placeholder="Enter Zip Code"
                    formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                    cacheOptions
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
      chassis:state.chassisReducer.chassis,
      chassisSize:state.chassisReducer.chassisSize,
      chassisType:state.chassisReducer.chassisType,
      chassisOwner:state.chassisReducer.chassisOwner,
      containerSize:state.containerReducer.containerSize,
      containerType:state.containerReducer.containerType,
      containerOwner:state.containerReducer.containerOwner,
      csrList:state.TmsReducer.csrUsers,
      customers: state.TmsReducer.customers,
      callers: state.TmsReducer.callers,
      chassisTerms : state.TmsReducer.chassisTerms,
      consigneesOptions : state.TmsReducer.consignees,
      shippers: state.TmsReducer.shippers,
    }
  }
  
  
SelectMulti= connect(mapStateToProps)(SelectMulti);

export { SelectMulti };