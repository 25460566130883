import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { displayName, getStorage } from "../../../../../services/Common.services";
import Avatar from '../../../Avatar';
import { IconPen } from '../../../Icons';

let timeZone;
class GroupChatCard extends React.Component {
    constructor(props) {
        timeZone = getStorage("timeZone");
        super(props);
        this.state = {
        };
    }

    render() {
        const { unreadUserMessages, showEditGroupModal, displayChatBox, details, lastMessage } = this.props;
        let count = unreadUserMessages.length > 0 && unreadUserMessages.find((group) => group.groupId === details._id);
        return (
            <div
                className="clist__card clist__card--outline mb-10"
                
                key={Math.random()}
            >
                <div className="d-flex align-items-start p-10">
                    <div className="flex-shrink-0 mr-10 align-self-center" onClick={() => displayChatBox(details)}>
                        <Avatar size={56}>{displayName(details?.groupName ? details?.groupName : details?.title)}</Avatar>
                    </div>
                    <div className="flex-grow-1 text-truncate" onClick={() => displayChatBox(details)}>
                        <div className="d-flex align-items-center mb-1">
                            <a href="#" className="font-14 font-weight-500 text-dark text-truncate mr-2">
                                {details?.groupName ? details?.groupName : details?.title}
                            </a>
                            {/* {message.isOnline == true && (
                                <span className="show-online bg-primary d-inline-block ml-2"></span>
                            )} */}
                        </div>
                        <div className="text-muted text-truncate">{lastMessage}</div>
                        
                    </div>
                    <div class="flex-shrink-0 ml-auto">
                        <div className="d-flex flex-column align-items-end">
                            <span className="text-muted">{details.updatedAt && timeZone && moment(details.updatedAt).tz(timeZone).fromNow()}</span>
                            <div className='d-flex my-1'>
                                {count && count.count > 0 &&
                                    <span className='notification-icon-lists mx-3'>{count.count}</span>}
                                <span
                                    className="btn btn-none p-0"
                                    onClick={() => showEditGroupModal(details)}
                                >
                                    <IconPen className="text-muted" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

function mapStateToProps(state) {
    return {
        userProfile: state.ProfileReducer,
        unreadUserMessages: state.HomeReducer.unreadMessages,
        allGroups: state.HomeReducer.groups
    };
}

export default connect(mapStateToProps, null)(GroupChatCard);
