import React, { useEffect, useState } from 'react'
import { displayName, getNewTranscation } from '../../services'
import { IconCheck, IconWeightTime } from '../Common/Icons';
import { useSelector, shallowEqual } from "react-redux";

const SummaryDriverPay = ({ reference_number, classes, isFromNewAccountReceivable = false }) => {

  const [startDate, setStartDate] = useState(null)
  const [permissions, setPermissions] = useState(null);
  const [endDate, setEndDate] = useState(null)
  const [transcations, setTranscations] = useState();
  const [isLoader, setIsLoader] = useState(false);

  const { drivers = [] } = useSelector((state) => state.TmsReducer, shallowEqual);

  const getTranscation = (query = {}) => {
    setIsLoader(true);
    getNewTranscation({
      reference_number,
      ...query,
      from: startDate,
      to: endDate,
      order: 1
    }).then(async (response) => {
      let transcations = !response.data.data || response.data.data.result == null
        ? []
        : response.data.data.result;
      transcations = transcations.filter((info) => info._id);
      let updatedTransaction = [];

      try {
        updatedTransaction = await Promise.all(transcations.map(async (d) => {
          let driver = drivers?.find((x) => x?._id === d?.createdBy?._id)
          return { ...d, profilePicture: driver?.profilePicture }
        }))
        setTranscations(updatedTransaction)
        setIsLoader(false);
      } catch (error) {
        console.log(error)
      }
    })
  }
  const chackPermissions = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser)
            if (loggedInUser.role === "carrier") return true;
            else if (loggedInUser.permissions.length !== 0)
                if (loggedInUser.permissions.includes("customer_service_driver_pay"))
                    return true;
                else return false;
        else return false
  }
  useEffect(() => {
    if(isFromNewAccountReceivable) {
      //this separation is done to prevent new AR(account reveivable) from getting falsy data when user rapidly changes selected load.
      let timer;

      const debouncedFunction = () => {
        setPermissions(chackPermissions())
        reference_number && getTranscation()
      }
  
      clearTimeout(timer);
  
      timer = setTimeout(debouncedFunction, 500);
  
      return () => clearTimeout(timer);
      
    } else {
      setPermissions(chackPermissions())
      reference_number && getTranscation()
    }

  }, [reference_number])
  return (
    <>
      {transcations?.length > 0 && permissions && (
        <div className={`card mb_5 ${classes ? classes : "p-10"}`}>
          <h5 className="mt_3 font-14 mb-10">Driver Pay</h5>
          {transcations?.map((element) => {
          return (
            <div className="border-1 p-2 rounded-lg text-gray-100 mb-1 w-100">
              <div className="d-flex justify-content-between mb_5">
              {element?.profilePicture ?
                <div className="d-flex align-items-center">
                  <span className="avatar-circle avatar-xs mr-1 font-10 font-weight-normal text-dark wh-20px">
                    <img src={element?.profilePicture} className="avatar rounded-circle" alt="" width="30"></img>
                  </span>
                  <span className="text-dark font-12 font-weight-500 mr-1">
                    {element && element?.createdBy && element?.createdBy.name ? element?.createdBy.name : " "}{" "}
                    {element && element.createdBy && element.createdBy.lastName ? element.createdBy.lastName : ""}
                  </span>
                </div>
                :
                <div className="d-flex align-items-center">
                  <span className="avatar-circle avatar-xs mr-1 font-10 font-weight-normal text-dark wh-20px">
                    {element && element?.createdBy?.name[0]}
                  </span> 
                  <span className="text-dark font-12 font-weight-500 mr-1">
                    {element && element.createdBy && element?.createdBy.name ? element?.createdBy.name : " "}{" "}
                    {element && element.createdBy && element.createdBy.lastName ? element.createdBy.lastName : ""}
                  </span>
                </div>
              }
                <div className="d-flex align-items-center">
                  <span className="text-dark font-14 font-weight-500 mr_5">{element?.amount?.toCurrency(element?.invoiceCurrency)}</span>
                  {element.approved ?
                    <div className="wh-20px bg-success-500 rounded-circle d-flex align-items-center justify-content-center">
                      <IconCheck className="text-white" />
                    </div>
                  :
                    <IconWeightTime />  
                  }
                  
                </div>
              </div>
              <div className="d-flex align-items-center mb_5">
                <div className="text-gray-500 font-weight-normal font-12 minwidth-50px">From</div>
                <div className="text-gray-900 font-weight-normal font-12">{element?.from}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className="text-gray-500 font-weight-normal font-12 minwidth-50px">To</div>
                <div className="text-gray-900 font-weight-normal font-12">{element?.to}</div>
              </div>
            </div>
          )})}
        </div>
      )}
    </>
  )
}

export default SummaryDriverPay