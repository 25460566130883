import {
    ContentState,
    convertFromHTML,
    convertToRaw,
    EditorState,
  } from "draft-js";
  import draftToHtml from "draftjs-to-html";
  import $ from "jquery";
  import _ from "lodash";
  import moment from "moment";
  import axios from "axios";
  import React, { Component } from "react";
  import { Modal } from "react-bootstrap";
  import { Editor } from "react-draft-wysiwyg";
  import { connect } from "react-redux";
  import AsyncSelect from "react-select/async";
  import { bindActionCreators } from "redux";
  import { newSmallSelectStyle, smallSelectStyle } from "../../../assets/js/select-style";
  import CustomCreatableSelect from "../../../Components/Common/CustomSelect/creatable";
  import CustomSelect from "../../../Components/Common/CustomSelect/index";
  import {
    IconAngleArrowDown,
    IconCalendar,
    IconCircleClose,
    IconDownload,
    IconMinus,
    IconPlus,
    IconSendEmail,
    IconTrash,
  } from "../../../Components/Common/Icons";
  import { debounce } from "throttle-debounce";
  import NewPriceInput from "../../../Components/Common/PriceInput/NewPriceInput";
  import {
    checkIsTaxable,
    convertUnit,
    DateTimeFormatUtils,
    Currency,
    getBasicSettings,
    getStorage,
    isGlobalizationEnabled,
    makeOptionsForSelect,
    toastr,
    types,
    isPaymentTermFlagEnabled,
    isCreditMemo,
    checkCombineInvoice,
    getEmailConfiguration,
    hasVATPermissionForUser
  } from "../../../services/Common.services";
import { validateEmail } from "../../../utils";
  import * as actionCreators from "../Dispatcher/actionCreators";
  import { emailInvoice, getDocumentDesignswithLoad, getSubInvoiceDesign, getTaxRates } from "../DocumentConfiguration/actionCreators";
  import { SUB_COMBINE_NEW, SUB_INVOICE } from "../DocumentConfiguration/constants";
  import {
    UpdateAdditionalPricing,
    getSettings
  } from "./../../tms/services";
  import * as tmsAction from "./actionCreator";
  import SelectCustomComponent from '../SelectComponent'
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { NumberFormatterV2 } from "../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import ExistingCreditMemos from "../CreditMemo/components/ExistingCreditMemos";
import CreditMemoModal from "../CreditMemo/components/CreditMemoModal/CreditMemoModal";
  
import Select from "react-select";
import { setSubDefaultTaxType } from "./actionCreator";
import confirm from "../../../Components/Common/ConfirmAert";
import SelectCustomCurrency from "../SelectCurrency"
import { CREDITMEMODISPLAY } from "../CreditMemo/constants";
import CellSpinner from "Components/Common/Spinner/CellSpinner";

  let freeze = false; //mark delay
  let timer; //saved timer
  
  const getTMScallerAsyncSearch = async (strSearch) => {
    freeze = true; //set mark for stop calls
    return new Promise(async (res, err) => {
      //return promise
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer); //remove  prev timer
        timer = setTimeout(async () => {
          freeze = false;
          const r = await actionCreators.getTMScaller(strSearch); //request
          res(r);
        }, 1000);
      });
  
      p.then(function (x) {
        res(x);
      });
    });
  };
  
  class NewAdditionalPricing extends Component {
    constructor(props) {
      super(props);
      this.state = {
        additionalPricing: [],
        emails: [],
        cc: [],
        isSaving: false,
        errors: [],
        formErrors: [],
        asce: true,
        editorState: EditorState.createEmpty(),
        isEnable: false,
        isModified: false,
        currentCharge: {},
        isApiCall: false,
        nameColor : "transition-white-field",
        subBillIndex:null,
        isRebillPermitted: true,
        name:"",
        isSuccess: false,
        disableStatus:false,
        selectedCell:null,
        updateDateOnInvoice:false,
        taxRates: [],
        isTaxable: checkIsTaxable(),
        isVatEnabled: JSON.parse(getStorage("userBasicSettings"))?.isVAT,
        isActiveTab: [],
        isShowMemoModal: false,
        subCustomer:''
      };
      this.updateAdditionalPricing = this.updateAdditionalPricing.bind(this);
      this.handleextraEmailChange = this.handleextraEmailChange.bind(this);
      this.handleccEmailChange = this.handleccEmailChange.bind(this);
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.newRowRef = this.newRowRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.handleClickOutsideNewRow = this.handleClickOutsideNewRow.bind(this);
      this.handlePaymentTerm = debounce(1000,this.changePaymentTerm);
    }
  
    componentWillReceiveProps(props) {
      if(this.state.addCharge) return
      if (!_.isEqual(props.selectedLoads, this.props.selectedLoads)) {
        if (props.selectedLoads && !this.state.isModified) {
          this.setState({ additionalPricing: props.selectedLoads.additionalPricing });
        }
        if (props.selectedLoads.reference_number !== this.props.selectedLoads.reference_number) {
          this.setState({ additionalPricing: props.selectedLoads.additionalPricing })
        }
      }
    }
   
    componentWillMount() {
      const props = this.props;
      this.setState({ additionalPricing: props.selectedLoads ? props.selectedLoads.additionalPricing : [] })
      const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    }
  
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("mousedown", this.handleClickOutsideNewRow);
    }
  
    componentDidMount = () => {
      if (this.props.addCustomerPriceRows) {
        this.props.addCustomerPriceRows(() => this.AddCustomerPriceRows());
      }
      if (this.props.updateAdditionalPricing) {
        this.props.updateAdditionalPricing(() => this.updateAdditionalPricing());
      }
      this.setState({
        isActiveTab: this.state.additionalPricing?.map((item) => {return item._id}) 
      })
      
      this.getSettings();
      this.getUserBasicSetting();
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("mousedown", this.handleClickOutsideNewRow);

      if(getStorage("userBasicSettings")){
        const settings = JSON.parse(getStorage("userBasicSettings"));
        this.setState({userSettings: settings})
      }
    };

  
    getUserBasicSetting(){
      getBasicSettings().then(res=>{
        if(res?.updateDateOnInvoice){
          this.setState({updateDateOnInvoice:res?.updateDateOnInvoice})
        }
      })
    }

    handleextraEmailChange(emails) {
      this.setState({ emails });
    }
  
    handleccEmailChange(cc) {
      this.setState({ cc });
    }
  
    AddCustomerPriceRows() {
      const isAlready = this.state.additionalPricing.find(obj => obj.customerId === undefined)
      if(!isAlready){
        const _pricingObj = {
          customerId: undefined,
          id: Math.random().toString(16).slice(2),
          pricing: [],
          billingDate: null,
        };
        const newPricing = this.state.additionalPricing.insert(_pricingObj);
        this.setState({
          additionalPricing: newPricing,
        },()=>{
          this.scrollRef.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        });
        
      }
    }
    selectCell = (name) => {
      if(this.state.selectedCell===name) return
      this.setState({selectedCell:name});
    }
    
    unselectCell = () => {
      this.setState({selectedCell: null});
    }
    setWrapperRef(node) {
      this.wrapperRef = node;
    }
    newRowRef(node) {
      this.rowRef = node;
    }

    async handleClickOutside(event) {
      // if (this.wrapperRef && 
      //   (!this.wrapperRef.contains(event.target) || event?.target?.dataset?.indexNumber === 'sub-header-v1')  &&
      //   !document.querySelectorAll("[role='tablist']")?.contains(event.target) &&
      //   (document.querySelector(".tab-loads").contains(event.target) ||  document.querySelector(".fcpopup__left").contains(event.target))
      //   ) {
      //   if(this.state.selectedCell)return
      //   await this.handleSubmitPricing()
      // }
    }

    async handleClickOutsideNewRow(event) {
      if (
          this.rowRef &&
          !this.rowRef.contains(event.target) && 
          !document.querySelector("[role='tablist']")?.contains(event.target) &&
          (document.querySelector(".tab-loads").contains(event.target) ||  document.querySelector(".fcpopup__left").contains(event.target))
      ) {
        if(this.state.selectedCell)return
        await this.handleSubmitPricing()
      }
    }
  
    handleSubmitPricing = async () => {
      this.unselectCell()
      try {
        if(!this.state.name && this.state.addCharge){
          this.setState({nameColor: "background-error"}, () => {
            setTimeout(() => {
              this.setState({nameColor: "background-failure"})
            }, 400);
          });
          return false;
        }
        if(Object.keys(this.state.currentCharge).length > 0 && !this.state.isApiCall && this.state.currentCharge?.additionalId) {
          const { additionalId, reference_number, pricingId } = this.state.currentCharge
          const currentAdditionalPrice = this.state.additionalPricing.find(obj => obj._id === additionalId)
          let currentPricing = currentAdditionalPrice.pricing.find(obj => obj._id === pricingId)
          this.setState({ isApiCall : true})
          if(currentPricing?._id){
            const pricingId = currentPricing?._id
            delete currentPricing._id
            currentPricing.finalAmount = (parseFloat(currentPricing.finalAmount) || 0).toFixed(2)
            currentPricing.unit = (parseFloat(currentPricing.unit) || 0).toFixed(2)
            currentPricing.chargePerDay = currentPricing.chargePerDay ? parseFloat(currentPricing.chargePerDay).toFixed(2) : 0;
            currentPricing.freeDays = currentPricing.freeDays ? parseFloat(currentPricing.freeDays).toFixed(2) : 0;
            const data = { reference_number, additionalId, pricingId, pricing: currentPricing }
            if(currentPricing?.name){
              const additionalPricingNew = await tmsAction.updateManyPricing(data)
              this.setState({ additionalPricing: additionalPricingNew, currentCharge: {}, isSuccess: true }, () => {
                setTimeout(() => {
                  this.setState({ isSuccess: false })
              }, 400)
                return { additionalPricing: additionalPricingNew }
              })
            }
          } else {
            if(!currentPricing) return
            delete currentPricing._id
            currentPricing.finalAmount = (parseFloat(currentPricing.finalAmount) || 0).toFixed(2)
            currentPricing.unit = (parseFloat(currentPricing.unit) || 0).toFixed(2)
            currentPricing.chargePerDay = currentPricing.chargePerDay ? parseFloat(currentPricing.chargePerDay).toFixed(2) : 0;
            currentPricing.freeDays = currentPricing.freeDays ? parseFloat(currentPricing.freeDays).toFixed(2) : 0;
            const data = {additionalId, reference_number, pricing:currentPricing}
            if(currentPricing?.name){
              const additionalPricingNew = await tmsAction.addNewPricing(data)
              this.setState({ additionalPricing: additionalPricingNew, currentCharge: {},addCharge:false,subBillIndex:-1,name:"",  isSuccess: true }, () => {
                setTimeout(() => {
                  this.setState({ isSuccess: false })
              }, 400)
                document.querySelector(`#addChargeFocus`)?.focus()
                this.props.blockTabChange(false)
                this.props.disableAddSub(false)
                return { additionalPricing: additionalPricingNew }
              })
            }
          }
          this.props.blockTabChange(false)
          this.setState({ isApiCall : false,addCharge:false,subBillIndex:-1})
        }
        return;
      } catch(err){
        this.setState({ isApiCall : false, currentCharge: {},isError: true },()=>this.props.blockTabChange(false))
        setTimeout(() => {
          this.setState({ isError: false })
      }, 400)
        return;
      }
    }
  
    AddPriceRows(customerId, pIndex) {
      const oldPricing = this.state.additionalPricing;
      const newPricing = [];
      if(!customerId) {
        this.transitionEffect(`customer-${pIndex}`)
        return;
      }
      oldPricing.forEach((obj, k) => {
        if (obj.customerId === customerId && k === pIndex) {
          const isAlready = obj.pricing.find(price => price._id === undefined)
          const _pricingObj = {
            name: "",
            description: "",
            amount: 0,
            unit: "1.00",
            finalAmount: "",
            freeDays: 0,
            chargePerDay: 0,
            freeMinutes: 0,
            chargePerMinute: 0,
            manualFinalAmount: 0,
          };
          !isAlready && obj.pricing.push(_pricingObj);
          newPricing.push(obj);
        } else {
          newPricing.push(obj);
        }
      });
     
      this.setState((prevState) => {
        let additionalPricing = [...prevState.additionalPricing];
        additionalPricing = newPricing;
        return {
          additionalPricing: additionalPricing,
          sort: undefined,
          asce: true,
          isModified: true,
          addCharge:true,
          subBillIndex:pIndex,
          name:"",
          isEnable:false,
          nameColor : "transition-white-field",
        };
      },()=>{this.props.blockTabChange(true); this.props.disableAddSub(true); this.selectCell("add-Charge")});
    }
  
    deleteSubBill = async (additionalId) => {
      try {
        additionalId && await tmsAction.removeSubBill({ additionalId , reference_number: this.props.load?.reference_number})
        return;
      } catch(err){
        return;
      }
    }
  
    DeleteCustomerPriceRow = async (index, additionalId) => {
      await this.deleteSubBill(additionalId)
      const newPricing = this.state.additionalPricing.removeAt(index);
      this.setState((prevState) => {
        let additionalPricing = [...prevState.additionalPricing];
        additionalPricing = newPricing;
        return { additionalPricing: additionalPricing };
      });
      this.setState({
        isEnable: false,
        isModified: true
      });
    }
  
    deleteRowPricing = async (item, data, k) => {
      try {
        return item?._id && await tmsAction.removeSubPricing({ reference_number: this.props.load?.reference_number, pricingId: item?._id, additionalId: data[k]?._id })
      } catch(err){
        return;
      }
    }
  
    DeletePriceRow = async (k, key, item) => {
      let data = _.cloneDeep(this.state.additionalPricing);
      if (data[k].pricing && data[k].pricing.length) {
        const response = await this.deleteRowPricing(item, data, k)
        if(response &&  response?.length > 0 && response[k]) {
          data[k].pricing = response[k].pricing
          data[k].taxAmount = response[k].taxAmount
          data[k].totalTaxAmount = response[k].totalTaxAmount
        } else {
          data[k].pricing.splice(key, 1);
        }
        this.setState({
          additionalPricing: data,
          isEnable: false,
          isModified: true,
          addCharge:false,
          subBillIndex:-1
        },()=>{this.props.blockTabChange(false); {toastr.show("Your pricing has been deleted!", "success"); this.props.disableAddSub(false)}});
      }
    }
  
    PriceError(name, index, mainIndex) {
      const old = [...this.state.additionalPricing];
      let formErrors = [...this.state.formErrors];
      if (!formErrors[index]) {
        formErrors[index] = [];
      }
      
    }
  
    handleKeyDown = (e) => {
        if(e?.keyCode === 13 || e?.keyCode === 9){
          e?.target?.blur()
          this.handleSubmitPricing()
        }
    }
  
    pricingchanges(mainIndex, index, e, item, additionalId, apiCall) {
      const { name, value } = e.target;
      this.setState({rowId: `${mainIndex}-${index}`})
      const old = [...this.state.additionalPricing];
      const positiveValuesOnly = [
        "freeDays",
        "chargePerDay",
      ];
  
      const parseFloatValue = parseFloat(value) || 0;
  
      if (positiveValuesOnly.includes(name)) {
        if (parseFloatValue >= 0) {
          old[mainIndex].pricing[index][name] = value;
        } else {
          toastr.show("You can not add a negative number.", "error");
        }
      } else {
        old[mainIndex].pricing[index][name] = value;
      }
  
      let formErrors = [...this.state.formErrors];
      // formErrors
      if (!formErrors[mainIndex]) {
        formErrors[mainIndex] = [];
      }
      if (
        (name == "unit" || name == "freeDays" || name == "chargePerDay") 
        // old[mainIndex].pricing[index]["finalAmount"] < 0
      ) {
        this.PriceError(name, index, mainIndex);
      }
      if (!formErrors[mainIndex][index]) {
        formErrors[mainIndex][index] = {};
      }
      if (
        (
          name == "unit" ||
          name == "freeDays" ||
          name == "chargePerDay") &&
        parseFloat(value) < 0
      ) {
        formErrors[mainIndex][index][name] = "Only Positive numbers Allowed";
        this.setState({ formErrors });
        return;
      } else {
        formErrors[mainIndex][index][name] = null;
        if (
          (name == "unit" || name == "freeDays" || name == "chargePerDay") &&
          old[mainIndex].pricing[index]["finalAmount"] >= 0
        ) {
          formErrors[mainIndex][index]["finalAmount"] = null;
        }
        this.setState({ formErrors });
      }
      const basePrice = old[mainIndex].pricing.find((obj) => {
        return obj.name === "Base Price";
      });
      if (name === "type") {
        old[mainIndex].pricing[index]["finalAmount"] =
          old[mainIndex].pricing[index]["type"] === "fixed"
            ? old[mainIndex].pricing[index]["amount"]
            : (old[mainIndex].pricing[index]["amount"] *
                (basePrice ? basePrice.finalAmount : 0)) /
              100;
      } else if (name === "amount") {
        old[mainIndex].pricing[index]["finalAmount"] =
          old[mainIndex].pricing[index]["type"] === "fixed"
            ? old[mainIndex].pricing[index]["amount"]
            : (old[mainIndex].pricing[index]["amount"] *
                (basePrice ? basePrice.finalAmount : 0)) /
              100;
        old[mainIndex].pricing[index]["manualFinalAmount"] = old[mainIndex].pricing[index]["finalAmount"];
      } else if (name === "finalAmount" && value) {
        old[mainIndex].pricing[index]["manualFinalAmount"] = value;
      } else if (["unit", "freeDays", "chargePerDay","perType"].includes(name)) {
        let computedAmount =
        (parseFloat(old[mainIndex].pricing[index].unit) -
        parseFloat(old[mainIndex].pricing[index].freeDays)) *
        parseFloat(old[mainIndex].pricing[index].chargePerDay);
        old[mainIndex].pricing[index][name] = value;
        if (old[mainIndex].pricing[index]["name"] !== 'Fuel') {
          if (computedAmount > parseFloat(old[mainIndex]?.pricing[index]?.manualFinalAmount ?? 0)) {
            old[mainIndex].pricing[index]["finalAmount"] =
              computedAmount.toFixed(2);
          } else {
            old[mainIndex].pricing[index]["finalAmount"] =
            parseFloat(old[mainIndex].pricing[index].manualFinalAmount||0).toFixed(2);
          }
        }
        let basePrice = Number(old[mainIndex].pricing.find(item => item.name === "Base Price")?.finalAmount)||0;
        if((name == "perType" && value =="percentage")
        || (name !== "perType" && old[mainIndex].pricing[index]["perType"] == "percentage") ){
          if (old[mainIndex].pricing[index]["name"] !== 'Fuel') {
            old[mainIndex].pricing[index]["finalAmount"] = (basePrice * parseFloat(old[mainIndex].pricing[index]["unit"]) / 100).toFixed(2);
          }
        }
      } 
      this.setState((prevState) => {
        const additionalPricing = [...prevState.additionalPricing];
        additionalPricing[mainIndex].name = value;
        return { additionalPricing: additionalPricing };
      });
      this.setState({
        additionalPricing: [...old],
        currentCharge: {additionalId, index, reference_number: this.props.load?.reference_number,pricingId: item?._id}
      }, async() => {
        if(apiCall){
          await this.handleSubmitPricing()
        }});
      if (value) {
        this.setState({
          isEnable: true,
          isModified: true
        });
      }
    }
  
    updateAdditionalPricing() {
      const additionalPricing = _.cloneDeep(this.state.additionalPricing);
      let formErrors = [...this.state.formErrors];
      let isValid = true;
      if (
        additionalPricing.filter((obj) => {
          return !obj.customerId;
        }).length > 0
      ) {
        toastr.show("You must select a customer!", "warning");
        return;
      }
      if (
        additionalPricing.filter((obj) => {
          return (
            obj.pricing.filter((data) => {
              return !data.name;
            }).length > 0
          );
        }).length > 0
      ) {
        toastr.show("You must enter a name in the field.", "error");
        return;
      }
      if (additionalPricing.filter((obj) => {return obj.pricing.filter((data) => { return !data.name}).length > 0}).length > 0 ) {
        toastr.show("You must enter a name in the field.", "error");
        return;
      }
      // only save those which are not billing
  
      if (additionalPricing && additionalPricing.length) {
        additionalPricing.forEach((d, index) => {
          formErrors[index] = formErrors[index] ? formErrors[index] : [];
          const price = d.pricing;
          if (price && price.length) {
            price.forEach((p, i) => {
              formErrors[index][i] = formErrors[index][i]
                ? formErrors[index][i]
                : {};
              if (p.finalAmount) {
                d.pricing[i].finalAmount = parseFloat(p.finalAmount).toFixed(2);
                isValid = isValid && parseFloat(p.finalAmount) >= 0;
              }
              if (p.unit) {
                formErrors[index][i]["unit"] =
                  parseFloat(p.unit) < 0 ? "Only Positive numbers Allowed" : null;
                isValid = isValid && parseFloat(p.unit) >= 0;
              }
              if (p.freeDays) {
                formErrors[index][i]["freeDays"] =
                  parseFloat(p.freeDays) < 0
                    ? "Only Positive numbers Allowed"
                    : null;
                isValid = isValid && parseFloat(p.freeDays) >= 0;
              }
              if (p.amount) {
                d.pricing[i].amount = parseFloat(p.amount).toFixed(2);
              }
              if (p.chargePerDay) {
                formErrors[index][i]["chargePerDay"] =
                  parseFloat(p.chargePerDay) < 0
                    ? "Only Positive numbers Allowed"
                    : null;
                isValid = isValid && parseFloat(p.chargePerDay) >= 0;
              }
            });
          }
        });
        this.setState({ formErrors });
      }
      // else {
      //   isValid = false;
      // }
      // if (!isValid) {
      //   toastr.show("Please enter all mandatory fields properly.", "warning");
      //   return;
      // }
      this.setState({ isSaving: true });
      let payload = {
        reference_number: this.props.selectedLoads.reference_number,
        additionalPricing:  additionalPricing
      };
  
      this.props.changeSavingLoadStatus &&
        this.props.changeSavingLoadStatus(true);
      UpdateAdditionalPricing(payload)
        .then((response) => {
          toastr.show("Updated Successfully", "success");
          let load = this.props.selectedLoads;
          let data = response.data.data;
          if (data.length > 0) {
            let errors = [];
            data.map((dd, i) => {
              if (dd.error) {
                errors[i] = _.uniqBy(dd.error, "Code");
              } else {
                if (
                  load &&
                  load.additionalPricing &&
                  load.additionalPricing.length > 0 &&
                  dd.data &&
                  dd.data.updatedAdditionalPricing
                ) {
                  let ddData = dd.data.updatedAdditionalPricing[0]
                    ? dd.data.updatedAdditionalPricing[0]
                    : null;
                  if (ddData) {
                    load.additionalPricing = load.additionalPricing.map((ap) => {
                      return ap._id === ddData._id ? ddData : ap;
                    });
                  }
                }
                errors[i] = null;
              }
            });
            this.setState({ errors: errors });
          }
          this.setState({ isSaving: false, isModified: false , isSuccess: true});
          this.props.changeSavingLoadStatus &&
            this.props.changeSavingLoadStatus(false);
            setTimeout(() => {
              this.setState({ isSuccess: false })
          }, 400)
        })
        .catch((e) => {
          this.setState({ isSaving: false, isError: true  });
          setTimeout(() => {
            this.setState({ isError: false })
        }, 400)
          this.props.changeSavingLoadStatus &&
            this.props.changeSavingLoadStatus(false);
        });
    }
  
    onButtonClick = async (type, additionalId, reference_number, currentAdditionalPrice) => {
      
      const billingDate = moment.tz(getStorage("timeZone")).toISOString()
      try {
        if(additionalId && reference_number){
          if(type === 'approve') {
            await tmsAction.approveInvoice({ reference_number, additionalId})
            toastr.show("Updated Successfully","success")
          } else if(type === 'unapprove') {
            await tmsAction.unApproveInvoice({ reference_number, additionalId})
            toastr.show("Updated Successfully","success")

          } else if(type === 'invoice') {
            const aditionPrice = await tmsAction.invoiceSubBill({ reference_number, additionalId, billingDate: currentAdditionalPrice.billingDate ? currentAdditionalPrice.billingDate : billingDate })
            if(aditionPrice){
              this.setState(
                (prevState) => {
                  return { additionalPricing: aditionPrice };
                })
            }
            toastr.show("Updated Successfully","success")

          } else if(type === 'approve_invoice') {
            const aditionPrice = await tmsAction.invoiceSubBill({ reference_number, additionalId, isApproveAndInvoice: true, billingDate: currentAdditionalPrice.billingDate ? currentAdditionalPrice.billingDate : billingDate })
            if(aditionPrice){
              this.setState(
                (prevState) => {
                  return { additionalPricing: aditionPrice };
                })
            }
            toastr.show("Updated Successfully","success")

          } else if( type === "rebill" ){
            await tmsAction.rebillInvoice({ reference_number, additionalId})
            toastr.show("Updated Successfully","success")

          }
          this.setState({disableStatus:false})
        }
        return;
      } catch(err){
        this.setState({additionalPricing:this.state.oldAdditionalPricing,disableStatus:false})
        return;
      }
    }
  
    chkchange(e, k, type, additionalId) {
      const oldAdditionalPricing = _.cloneDeep(this.state.additionalPricing)
      const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      const reference_number = this.props.load?.reference_number
      if (
        ["fleetmanager", "carrier"].indexOf(getStorage("currentUserRole")) > -1
      ) {
        const {value} = e.target;
        if (loggedInUser.role === "fleetmanager" && type === "rebill") {
          if(loggedInUser.permissions.includes("rebill_loads")) {
            this.setState(
              (prevState) => {
                const additionalPricing = [...prevState.additionalPricing];
                additionalPricing[k].status = value;
                return { additionalPricing: additionalPricing,oldAdditionalPricing };
              },
              async () => {
                additionalId && await tmsAction.rebillInvoice({ reference_number, additionalId})
                
              }
            );
          } else {
            toastr.show("Please contact your admin to allow the setting in permissions page for you to rebill", "error")
          }
        } else if (loggedInUser.role === "fleetmanager" &&  (type === "invoice" || type === "approve_invoice")) {
            if(loggedInUser.permissions.includes("approve_invoice")) {
              const billingDate = this.state.additionalPricing[k]?.billingDate ?? moment.tz(getStorage("timeZone")).toISOString()
              
              this.setState(
                (prevState) => {
                  const additionalPricing = [...prevState.additionalPricing];
                  additionalPricing[k].status = value;
                  if(value==="REBILLING"){
                    additionalPricing[k].billingDate= billingDate
                  }
                  if(this.state.updateDateOnInvoice){
                    additionalPricing[k].billingDate=moment.tz(getStorage("timeZone")).toISOString()
                  }
                  return { additionalPricing: additionalPricing,oldAdditionalPricing };
                },
                async () => {
                  const aditionPrice = await tmsAction.invoiceSubBill({ reference_number, additionalId, isApproveAndInvoice: type === "approve_invoice" ? true : false, billingDate })
                  if(aditionPrice){
                    this.setState(
                      (prevState) => {
                        return { additionalPricing: aditionPrice };
                      })
                  }
                  toastr.show("Updated Successfully","success")
                }
              );
            } else {
              toastr.show("You do not have permission to invoice a bill.  Please contact your admin.", "error")
            }
        }  else {
          let currentAdditionalPrice
          this.setState(
            (prevState) => {
              const additionalPricing = [...prevState.additionalPricing];
              additionalPricing[k].status = value;
              if((type === "invoice" || type === "approve_invoice") && this.state.updateDateOnInvoice){
                additionalPricing[k].billingDate=moment.tz(getStorage("timeZone")).toISOString()
              }
              currentAdditionalPrice = additionalPricing[k]
              return { additionalPricing: additionalPricing ,disableStatus:true,oldAdditionalPricing};
            },
            async () => {
              await this.onButtonClick(type, additionalId, reference_number, currentAdditionalPrice)
              // this.updateAdditionalPricing();
            }
          );
        }
      }
    }
  
    arrayBufferToBase64(buffer) {
      let binary = "";
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  
    download_Invoice(customer, isDownload) {
      const { selectedLoads } = this.props;
      let progressField = `${customer.customerId._id}_isDownloadInProcess`;
     
      const allReferenceNumbers = [];
      allReferenceNumbers.push(selectedLoads.reference_number);
      this.setState({ isEmailSendInProcess: true });
      let query = {};
      let subLoad = selectedLoads.additionalPricing.find(item => item.customerId._id == customer.customerId._id);
      if (subLoad && subLoad.subInvoiceNumber) {
        query.subInvoiceNumber = subLoad.subInvoiceNumber;
        query.typeOfDocument = SUB_COMBINE_NEW
      } else {
        query.typeOfDocument = SUB_INVOICE;
      }
      if(selectedLoads.reference_number){
       query.reference_number = selectedLoads.reference_number 
      } 
       if (isDownload){this.setState({ [progressField]: true });}
    
        let obj = {isSubCustomer : true, additionalPriceId : customer._id, customerId : customer.customerId._id }
        if(isDownload){
          getSubInvoiceDesign({...obj,...query }).then(async (result) => {
            if(!result?.data) throw new Error();
            const url = result.data;
            const { data } = await axios({ url, responseType:"arraybuffer",method:"GET" });
            if(!data) throw new Error();
            this.setState({ isEmailSendInProcess: false, sendEmailModal: false });
            this.setState({ [progressField]: false });
            this.setState(
              {
                [`${customer.customerId._id}-invoice`]: `data:application/pdf;base64,${this.arrayBufferToBase64(data)}`,
              },
              () => {
                $(`#${customer.customerId._id}-invoice`)[0].click();
              }
            );
          })
          .catch((err) => {
            toastr.show("Something went wrong, Please try again", "error");
            this.setState({ [progressField]: false });
            this.setState({ isEmailSendInProcess: false });
          });
        }else{
          this.setState({ isEmailSendInProcess: true, sendEmailModal: true });
          let formData = {isSubCustomer : true, additionalPriceId : customer._id, customerId : customer.customerId._id, isEmailing : true};
     
          if (this.state.emails && this.state.emails.length > 0) {
            formData.email= this.state.emails.join(",")
          }
          if (this.state.cc && this.state.cc.length > 0) {
            formData.cc= this.state.cc.join(",")
          }
          if (this.state.inpSubject) {
            formData.subject= this.state.inpSubject
          }
          if (this.state.inpBody) {
            formData.body = this.state.inpBody
          }
           emailInvoice({...formData, ...query})
           .then(res => {
           this.setState({ isEmailSendInProcess: false, sendEmailModal: false ,[progressField]: false});
           toastr.show("Email Sent", "success");})
           .catch(err => {
           console.log("error after emailing invoice", err);
           toastr.show("Email not Sent", "error")
           this.setState({ isEmailSendInProcess: false, sendEmailModal: false, [progressField]: false });
          })
        }
        
       }
  
    async setEmails(customer,obj) {
      const updateState = {};
      const { selectedLoads } = this.props;
      let emails = [];
      let cc = [];
      emails=customer?.billingEmail ? customer?.billingEmail?.split(',')?.filter(Boolean) : [customer?.email]
      if (customer?.isEmailNeedToSend) {
        if (selectedLoads) {
          let carrierEmail = selectedLoads?.carrier?.carrier?.billingEmail?.split(',')?.filter(Boolean)
          cc = cc.concat(carrierEmail)
        }
        let ccEmails = customer?.extraEmail?.split(',')?.filter(Boolean);
        cc = cc.concat(ccEmails);
      }
  
      const {inpBody} = await this.defaultEmailConfig({
        company_name: customer?.company_name,
      },obj);

      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(inpBody ?? this.state.inpBody ?? ""))
        ),
      });
  
      updateState.emails = emails.compact(true);
      updateState.cc = cc.compact(true);
      this.setState(updateState);
    }
  
    getSettings() {
      getSettings()
        .then((result) => {
          const data = result.data.data;
          if (data) {
            this.setState({ settings: data }, async () => {
              const { inpBody } = await this.defaultEmailConfig(
                this.state?.selectedCustomer
                  ? {
                      company_name:
                        this.state?.selectedCustomer?.customerId?.company_name,
                    }
                  : {}
              );
              this.setState({
                editorState: EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(inpBody ?? this.state.inpBody ?? "")
                  )
                ),
              });
            });
          }
        })
        .catch((error) => {
          console.log("-*-* get settings error ----", error);
        });
    }
  
   async defaultEmailConfig(emailInfo,obj) {
      const { selectedLoads } = this.props;
      let emailConfiguration = await getEmailConfiguration(selectedLoads?.terminal?._id || selectedLoads?.terminal);
      let inpSubject = "",
        inpBody = "";
      inpSubject = emailConfiguration?.emailSubject
          ? this.dynamicStringByLoadInfo(
            emailConfiguration?.emailSubject,
              selectedLoads,
              emailInfo,
              obj
            )
          : `Billing for Load #${selectedLoads.reference_number}`;
      inpBody = emailConfiguration?.emailBody
          ? this.dynamicStringByLoadInfo(
            emailConfiguration?.emailBody,
              selectedLoads,
              emailInfo,
              obj
            ):"Please review attached billing invoices.";
      // inpBody = "Please review attached billing invoices.";
      this.setState({ inpSubject, inpBody });
      return {inpSubject, inpBody}
    }
  
    dynamicStringByLoadInfo(str, data, emailInfo, selectedObj) {
      var allData = str.split(" ");
      var pattern = /\@(.*?)\@/g;
      allData.filter((obj) => {
        let vals = obj.match(pattern);
        if (vals) {
          vals.forEach((key) => {
            if (key === "@referenceNo@") {
              str = str.replace(key, data.secondaryReferenceNo || "");
            }  else if (key == "@combinedInvoice@" || key === "@invoiceNo@" || key === "@LoadNo@") {
              str = checkCombineInvoice(allData, key, str,data, selectedObj);
            } else if (key === "@consigneeName@") {
              str = str.replace(key, data.consigneeName || "");
            } else if (key === "@weight@") {
              str = str.replace(key, data.totalWeight || "");
            } else if (emailInfo && key === "@callerName@") {
              str = str.replace(key, emailInfo?.company_name || "");
            } else {
              str = str.replace(key, data[key.substr(1, key.length - 2)] || "");
            }
          });
        }
      });
      return str;
    }
  
    _findCustomer(data) {
      let obj = null;
      if (data.customerId)
        obj = { value: data.customerId._id, label: data.customerId.company_name };
  
      return obj;
    }
  
    //   sortTable(sort){
    //     if(this.state.sort =="name" && !this.state.asce){
    //       this.setState({sort:undefined,asce:true})
    // }else{
    //   this.setState({
    //     sort,
    //     asce: this.state.sort !== sort ? true : !this.state.asce,
    //   });
    // }
    //   }
  
    // sortTable(sort) {
    //   if (this.state.sort == "name" && !this.state.asce) {
    //     this.setState({ sort: undefined, asce: true });
    //   } else {
    //     this.setState({
    //       sort,
    //       asce: this.state.sort !== sort ? true : !this.state.asce,
    //     });
    //   }
    // }
    saveAdditionalPricing(index) {
      let pricing = this.state.additionalPricing[index];
      if (pricing && !pricing.customerId) {
        toastr.show("You must select a customer!", "warning");
        return;
      } else {
        let payload = {
          reference_number: this.props.selectedLoads.reference_number,
          additionalPricing: [pricing],
        };
  
        if (pricing && pricing.status !== "BILLING") {
          payload.isSave = true;
        }
  
        UpdateAdditionalPricing(payload).then((response) => {
          toastr.show("Updated Successfully", "success");
          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
        });
      }
    }
  
    // changeBillingDate = (k, value) => {
    //   this.setState((prevState) => {
    //     var additionalPricing = [...prevState.additionalPricing];
    //     let subInvoiceNumber = additionalPricing[k].subInvoiceNumber
    //       ? additionalPricing[k].subInvoiceNumber
    //       : null;
    //     additionalPricing[k].billingDate = value ? value : undefined;
    //     if (subInvoiceNumber) {
    //       additionalPricing = additionalPricing.map((pricing) => {
    //         if (pricing.subInvoiceNumber == additionalPricing[k].subInvoiceNumber) {
    //           pricing.billingDate = value ? value : undefined;
    //         }
    //         return pricing;
    //       });
    //     }
    //     return { additionalPricing: additionalPricing };
    //   });
    // };
  
    changeBillingDate = async (k, value) => {
      this.setState(async (prevState) => {
        var additionalPricing = [...prevState.additionalPricing];
        let subInvoiceNumber = additionalPricing[k].subInvoiceNumber
          ? additionalPricing[k].subInvoiceNumber
          : null;
        additionalPricing[k].billingDate = value ? value : undefined;
        await tmsAction.changeSubBillingDate({ additionalId: additionalPricing[k]?._id, reference_number: this.props.load?.reference_number, billingDate: value, paymentTerm: additionalPricing[k]?.paymentTerm})
        if (subInvoiceNumber) {
          additionalPricing = additionalPricing.map((pricing) => {
            if (pricing.subInvoiceNumber == additionalPricing[k].subInvoiceNumber) {
              pricing.billingDate = value ? value : undefined;
            }
            return pricing;
          });
        }
        toastr.show("The billing date has been updated.", "success");
        this.transitionEffect(`billingDate-${k}`,true)
        return { additionalPricing: additionalPricing };
      });
    };

    changePaymentTerm = async (k, value) => {
      this.setState(async (prevState) => {
        var additionalPricing = [...prevState.additionalPricing];
        let subInvoiceNumber = additionalPricing[k].subInvoiceNumber
          ? additionalPricing[k].subInvoiceNumber
          : null;
        additionalPricing[k].paymentTerm = value ? parseInt(value) : null;
        await tmsAction.changeSubBillingDate({ additionalId: additionalPricing[k]?._id, reference_number: this.props.load?.reference_number, paymentTerm: additionalPricing[k]?.paymentTerm , billingDate: additionalPricing[k]?.billingDate})
        if (subInvoiceNumber) {
          additionalPricing = additionalPricing.map((pricing) => {
            if (pricing.subInvoiceNumber == additionalPricing[k].subInvoiceNumber) {
              pricing.paymentTerm = value ? value : null;
            }
            return pricing;
          });
        }
        this.transitionEffect(`paymentTerm-${k}`,true)
        return { additionalPricing: additionalPricing };
      });
    };
  
    onChangeCustomer = async (customer, k) => {
      try {
        this.setState(async (prevState) => {
          const additionalPricing = [
            ...prevState.additionalPricing,
          ];
          const oldCustomer = additionalPricing[k]?.customerId?._id
          additionalPricing[k].customerId = customer.allInfos;
          let additionalPricingMain = await this.changeSubCustomer(additionalPricing, customer, k, oldCustomer)
          const rowload = this.props.selectedLoads
          const load = { ...rowload, additionalPricing: additionalPricingMain }
          this.props.updateList(load)
          return { additionalPricing: additionalPricingMain, isModified: true };
        })
      } catch(err){ 
        return;
      }
    }
  
    changeSubCustomer = async (subBill, customer, k, oldCustomer) => {
      const isNewSubBill = subBill[k]?._id ? false : true
      let newAdditionalPricing = subBill
      try {
        if(isNewSubBill){
          newAdditionalPricing = await tmsAction.addNewSubBill({ customerId: customer?.allInfos?._id, reference_number: this.props.load?.reference_number})
          this.transitionEffect(`customer-${k}`,true)
        } else {
          if(oldCustomer !== customer?.allInfos?._id){
            newAdditionalPricing = await tmsAction.changeCustomerSubBill({ additionalId: subBill[k]?._id, customerId: customer?.allInfos?._id, reference_number: this.props.load?.reference_number})
            this.transitionEffect(`customer-${k}`,true)
          }
        }
        this.setState({ additionalPricing: newAdditionalPricing})
        return newAdditionalPricing;
      } catch(err){
        this.transitionEffect(`customer-${k}`)
        return subBill;
      }
    }
    transitionEffect = (field, color) => {
      const element = document.querySelector(`.${field}`);
      let dateOrInpt = ["rdt","input-wrapper"];
      const isThere = dateOrInpt.some((e)=>element?.className?.includes(e));
      const transitionElement = isThere ? document.querySelector(`.${field} > input`) : document.querySelector(`.${field} > div`);
      if(color){
        transitionElement?.classList.remove("background-failure");
        transitionElement?.classList.add("background-success");
        setTimeout(()=>{
          transitionElement?.classList.remove("background-success","text-danger");
        },400)
      }else {
        transitionElement?.classList.add("background-error","text-danger");
          setTimeout(()=>{
            transitionElement?.classList.add("background-failure","text-danger");
            transitionElement?.classList.remove("background-error","text-danger");
          },400)
      }
    }

    focusOnButton(k){
      const element = document?.getElementById(`addChargeFocus-${k}`)
      element.style.border = "1px solid black"
    }
    blurOnButton(k){
      const element = document?.getElementById(`addChargeFocus-${k}`)
      element.style.border = "none"
    }

    handleOnKeyDown = (e, customerId, k) => {
      if(e.keyCode === 13) {
        this.AddPriceRows(customerId, k)
        const element = document?.getElementById(`addChargeFocus-${k}`)
        element.style.border = "none"
      } else {
        const element = document?.getElementById(`addChargeFocus-${k}`)
        element.style.border = "none"
        return
      }
    }
    handleActiveTab = (add, remove) => {
      
      let test = _.cloneDeep(this.state.isActiveTab)
      // Remove Id 
      let index = test.findIndex((item) => item === remove);
         if (index > -1) test.splice(index, 1)

      // Add New ID
      if(test.includes(add)) return
        test.push(add)
      this.setState({
        isActiveTab: test
      })
    }
    handleCreateMemoModal = () => {
      this.setState({
        isShowMemoModal: false
      })
    }

    setDefaultTax = (additonalId, customerId,value)=>{
      let _additionalPricing = _.cloneDeep(this.state.additionalPricing)
      let index = _additionalPricing.findIndex((el) => el?._id == additonalId)
      _additionalPricing[index].defaultTaxType = value
      this.setState({additionalPricing : _additionalPricing})
      const payload={
        reference_number : this.props.load?.reference_number,
        defaultTaxType : value.toUpperCase(),
        additionalId : additonalId,
        customerId: customerId
      }
      setSubDefaultTaxType(payload).then((response)=>{
        if(response) {
          this.setState({additionalPricing : response?.additionalPricing})
          toastr.show("Successfully updated!", "success");
        }
      })
    } 
    render() {
      const { formErrors } = this.state;
      var disabled = false;
      const { selectedLoads } = this.props;
      const {chargeCode} = this.props;
      let chargeCodeList = [];
      if(chargeCode && chargeCode.length) {
        chargeCodeList = _.orderBy(chargeCode, "orderIndex", "asc");
      }
      const isVATPermission = hasVATPermissionForUser()
      return (
        <div>
          
          {this.state.additionalPricing.map((obj, k) => {
            let newPricing = obj.pricing;
            let downloadLabel = obj.subInvoiceNumber
              ? obj.subInvoiceNumber
              : obj.sub_reference_number
              ? obj.sub_reference_number
              : this.props.selectedLoads &&
                this.props.selectedLoads.reference_number
              ? this.props.selectedLoads.reference_number
              : "";
            let isTotalAmount = 0;
  
            // if (this.state.sort) {
            //   newPricing = orderBy(
            //     obj.pricing,
            //     (charge) => charge[this.state.sort].toLowerCase(),
            //     !this.state.asce && "desc"
            //   );
            // } else {
              newPricing = obj.pricing;
              // let isTotalAmount = NP.round(NP.plus(...newPricing.map((obj) => parseFloat(obj.finalAmount)), 0, 0), 2);
              newPricing.forEach(function(obj){
                if(obj["finalAmount"]){
                  isTotalAmount = isTotalAmount + parseFloat(obj["finalAmount"]);
                }
              });
            // }
              if(this.state.subBillIndex !=k && this.state.addCharge){
                disabled =true
              }
              disabled= [
                "APPROVED",
                "BILLING",
                "REBILLING",
                "PARTIAL_PAID",
                "FULL_PAID",
              ].includes(obj.status) || this.state.addCharge
              const disableStatus = this.state.addCharge || this.state.disableStatus
              const customerCurrency = obj?.invoiceCurrency ?? this.props.selectedLoads?.invoiceCurrency ?? Currency.default();

            return (
              <div key={k} ref={(el) => { this.scrollRef = el }} style={{opacity: `${this.state.subBillIndex !=k && this.state.addCharge ? "0.2":'' }`, transition: 'background .4s ease-out'}} >
                <div data-index-number="sub-header-v1" className="card p-15 mb-1 d-flex flex-row justify-content-between">
                  <div className={`d-flex flex-row align-items-center ${this.state.isSaving || (obj.qbInvoiceId) || this.state.addCharge ? 'disabled-pointer-events':''}`}>
                    <AsyncSelect
                      defaultOptions={makeOptionsForSelect(
                        this.props.customers,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      value={this._findCustomer(obj)}
                      onChange={(customer) => {
                        this.onChangeCustomer(customer, k)
                      }}
                      placeholder="Select Sub Customer..."
                      styles={smallSelectStyle}
                      className={`customer-${k} w-200`}
                      loadOptions={getTMScallerAsyncSearch}
                      isDisabled={(obj._id && disabled) || (isGlobalizationEnabled() && obj._id)} 
                    />
                    
                    {!isCreditMemo() && 
                    <button
                      className="btn btn-link text-muted"
                      onClick={() => this.DeleteCustomerPriceRow(k, obj._id, obj?.sub_reference_number)}
                      disabled={this.state.addCharge && obj._id || (isCreditMemo() && ["FULL_PAID", "BILLING", "APPROVED", "APPROVE_AND_BILLING", "PARTIAL_PAID"].includes(obj.status))}
                    >
                      <IconTrash />
                    </button>}
                  
                    {CREDITMEMODISPLAY.includes(obj.status) && isCreditMemo() &&  <ul className="nav nav-compact ml-4" role="tablist">
                        <li className="nav-item">
                            <a
                              className={`nav-link  ${
                                this.state.isActiveTab?.includes(obj._id) ?
                                "active" : ""
                              }`}
                              href="#!"
                              onClick={() => {
                                this.handleActiveTab(obj._id, k)
                              }}
                            >
                            Invoice
                          </a>
                        </li>
                        <li className="nav-item">
                            <a
                              className={`nav-link  ${
                                this.state.isActiveTab?.includes(k) ?
                                "active" : ""
                              }`}
                              href="#!"
                              onClick={() => {
                                this.handleActiveTab(k, obj._id)
                              }}
                            >
                            Credit Memos
                            <span className={`badge ${this.state.isActiveTab?.includes(k) ? "badge-primary" : "badge-gray-300"} font-12 ml-2 badge-pill`}>{this.props.allCreditMemo?.filter((item) => item?.sub_reference_number === obj.sub_reference_number)?.length}</span>
                          </a>
                        </li>
                    </ul>}
                  </div>

                  
                  <div className="d-flex flex-row">
                    {this.state.isVatEnabled && obj?.taxType && isVATPermission &&
                      (<div className="mr-20 d-flex align-items-center">
                        <label className="mr-10 mb-0">{obj?.taxType}</label>
                        <Select
                          isDisabled={disabled}
                          value={VATTypes.find((item) => item.value === obj?.defaultTaxType )}
                          options={VATTypes}
                          onChange={(value) => {
                            if (obj?.defaultTaxType === value.value) return
                            this.setDefaultTax(obj?._id, obj?.customerId?._id,value.value)
                          }}
                          placeholder="Applicable"
                          className="w-150"
                          styles={smallSelectStyle}
                          menuPlacement="top"
                        />
                      </div>) 
                    }

                    {this.state.additionalPricing[k] &&
                      ["BILLING"].indexOf(
                        this.state.additionalPricing[k].status
                      ) > -1 && (
                        <button
                          className="ml-auto btn btn-outline-light mr-2"
                          onClick={() => {
                            this.setEmails(obj.customerId,obj);
                            this.setState({
                              selectedCustomer: obj,
                              sendEmailModal: true,
                            });
                          }}
                          disabled={this.state.addCharge}
                        >
                          <IconSendEmail className="mr-2" />
                          Email
                        </button>
                      )}
  
                    <a
                      download={`${downloadLabel}-invoice.pdf`}
                      id={`${
                        obj.customerId ? obj.customerId._id : obj.customerId
                      }-invoice`}
                      href={
                        this.state[
                          `${
                            obj.customerId ? obj.customerId._id : obj.customerId
                          }-invoice`
                        ]
                      }
                    ></a>
                   {!this.state.isActiveTab.includes(k) && <button
                      disabled={
                        this.state[
                          `${
                            obj.customerId ? obj.customerId._id : obj.customerId
                          }_isDownloadInProcess`
                        ] ||this.state.addCharge
                      }
                      className="ml-auto btn btn-outline-light"
                      onClick={() => this.download_Invoice(obj, true)}
                    >
                    {
                      this.state[
                          `${
                            obj.customerId ? obj.customerId._id : obj.customerId
                          }_isDownloadInProcess`
                        ] ? (
                          <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        ):(
                      <IconDownload className="mr-2" />
                        )}
                      Download PDF
                    </button>}
                    {!this.state.isActiveTab?.includes(k) && isCreditMemo() && <button
                      className="btn btn-link text-muted"
                      onClick={() => this.DeleteCustomerPriceRow(k, obj._id, obj?.sub_reference_number)}
                      disabled={this.state.addCharge && obj._id || (isCreditMemo() && ["FULL_PAID", "BILLING", "APPROVED", "APPROVE_AND_BILLING", "PARTIAL_PAID"].includes(obj.status))}
                    >
                      <IconTrash />
                    </button>}
                    {this.state.isActiveTab.includes(k) &&  !(JSON.parse(localStorage.getItem("loggedInUser")).role === "fleetmanager" &&
                      JSON.parse(localStorage.getItem("loggedInUser")).fleetManager.isCustomer) &&
                      <button className="btn btn-primary" onClick={() => {
                          this.setState({isShowMemoModal: true, subCustomer: obj?.sub_reference_number})
                      }} >
                        <IconPlus className="mr-2" />
                        Add Credit Memo
                      </button> 
                   }
                  </div>
                </div>
               {!this.state.isActiveTab.includes(k) && <div className={`mb-1 ${this.state.isSaving ? 'disabled-pointer-events':''} `}>
                  <div className="table-responsive">
                  <table className={`table table-card  position-relative table-billing ${((obj.status && obj.status === "BILLING") || !obj?._id) ? 'disabled-pointer-events' : ''}`} ref={!this.state.isApiCall && this.setWrapperRef} >
                    {(this.state.isApiCall && this.state.subBillIndex === k) && <div className="overly-lays d-flex b-40 justify-content-center align-items-center text-white mb-1">
                      <span
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Processing...
                    </div>}
                    <thead>
                      <tr className="transition-white-field">
                        <th width="200">Name of Charge #
                        </th>
                        <th width="300">Description</th>
                        <th width="210" className="text-right">Price {
                          customerCurrency && `(${customerCurrency?.currencyCode})`
                        }</th>
                        <th width="140" className="text-right z-3">Unit Count</th>
                        <th width="150" className="z-1">Unit of Measure</th>
                        <th width="100" className="text-right">Free Units</th>
                        <th width="100" className="text-right">Per Units</th>
                        <th width="42"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {newPricing.map((item, key) => {
                        let chargeValue = chargeCodeList && chargeCodeList.find((d) => d.value === item.name)
                        let charges = chargeCodeList && chargeCodeList.filter((d) => d.isActive)
                        
                      
                        let success = this.state.isSuccess && this.state.rowId === `${k}-${key}`
                        let error = this.state.isError && this.state.rowId === `${k}-${key}`
                        if (!item._id && this.state.addCharge) {
                          return (<tr onKeyDown={(e)=>this.handleKeyDown(e)} className="select-custom-input newdriverrow shadow-md" key={key} ref={this.newRowRef}>
                            <td className={`cell-1 pl-2 pr-1 ${this.state.nameColor} text-dark ${!this.state.name ? 'requied_hover' : ""} px-0 ${this.state.selectedCell==="add-Charge" ? 'newdriverrow td-clicked' : ""}`} 
                              style={{transition: 'background .4s ease-out'}}
                              onClick={() => this.selectCell("add-Charge")}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  {chargeValue && chargeValue?.chargeName || <span className="text-muted">Select Charge</span>}
                                  <IconAngleArrowDown className='' />
                                  {this.state.selectedCell === "add-Charge" &&
                                   <SelectCustomComponent
                                    name='charges'
                                    searchable={true}
                                    refClassName={`cell-1`}
                                    listData={charges.filter((d) => d.isActive)}
                                    keyName="chargeName"
                                    onSelect={(name, value) => {
                                      this.pricingchanges(k, key, {
                                        target: { value:value.value, name: "name" },
                                      }, item, obj?._id)
                                      this.setState({name:value,nameColor : "transition-white-field"});
                                      this.unselectCell()
                                    }}
                                    unselectCell={this.unselectCell}
                                  />}
                                </div>
                            </td>
                            <td className={`px-0 ${this.state.selectedCell==="description" ? 'newdriverrow td-clicked' : ""}`}
                              onClick={() => this.selectCell("description")}>
                              <input
                                type="text"
                                className="form-control border-0"
                                placeholder="Add Description..."
                                autofocus="false"
                                name="description"
                                value={item.description || ""}
                                onChange={(e) => this.pricingchanges(k, key, e, item, obj?._id)}
                                autoComplete="off"
                                onBlur={async ()=>{
                                  await this.handleSubmitPricing(); 
                                  this.unselectCell()
                                }}
                              />
                            </td>
                            {item.name === "Fuel" ? (
                              <td className={`cell-7 px-0 ${["FA1","FA2","fixed"].includes(this.state.selectedCell) && 'newdriverrow td-clicked'}`}
                              // onClick={() => this.selectCell("amount")}
                              >
                                  <div className="d-flex null custom-select-border justify-content-between">
                                  <div className="d-flex align-items-center justify-content-center w-100" onClick={() => this.selectCell("FA1")}>
                                    {!isGlobalizationEnabled() && <input
                                      className="form-control border-0 text-right"
                                      type="number"
                                      name="amount"
                                      autofocus="false"
                                      autoComplete="off"
                                      value={item.amount || ""}
                                      onChange={(e) =>
                                        this.pricingchanges(k, key, e, item, obj?._id)
                                      }
                                      min={0}
                                      onBlur={async ()=> {
                                        await this.handleSubmitPricing(); 
                                        this.unselectCell()
                                      }}
                                    />}
                                    {isGlobalizationEnabled() && (
                                      <NumberFormatterV2 
                                        selected={["FA1","FA2","fixed"].includes(this.state.selectedCell)}
                                        className="form-control border-0 text-right"
                                        childClassName="form-control border-0 text-right"
                                        type="number"
                                        name="amount"
                                        autofocus="false"
                                        autoComplete="off"
                                        value={item.amount || ""}
                                        onChange={(e) =>
                                          this.pricingchanges(k, key, e, item, obj?._id)
                                        }
                                        min={0}
                                        onBlur={async ()=>{
                                          await this.handleSubmitPricing(); 
                                          this.unselectCell()
                                        }}
                                        showCurrency={false}
                                        autoFocus={false}
                                      />
                                    )}
                                  </div>
                                  {/* <div className="mr-1 d-flex w-100 custom-select-border justify-content-center price-component">
                                    <CustomSelect
                                      size="small"
                                      style={newSmallSelectStyle}
                                      options={[
                                        { value: "fixed", label: `${customerCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}` },
                                        { value: "percentage", label: "%" },
                                      ]}
                                      value={
                                        item.type && item.type === "fixed"
                                          ? { value: "fixed", label: `${customerCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`  }
                                          : {
                                            value: "percentage",
                                            label: "%",
                                          }
                                      }
                                      onChange={({ value }) =>{
                                        this.pricingchanges(k, key, {
                                          target: { value, name: "type" },
                                        }, item, obj?._id)
                                        this.unselectCell()
                                      }
                                      }
                                      isSearchable={false}
                                      onBlur={()=> this.unselectCell()}
                                    />
                                  </div> */}
                                  <div className="d-flex justify-content-between align-items-center min-width-50 line-height-30 pointer"
                                    onClick={() => this.selectCell("fixed")}
                                    >
                                      <div className="transition-y2">{this.state.type && this.state.type === "fixed" ? `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}` : "%"}</div>
                                      <div className="ml-20"><IconAngleArrowDown /></div>
                                      {this.state.selectedCell==="fixed" &&
                                        <SelectCustomCurrency
                                        name='value'
                                        searchable={false}
                                        refClassName="cell-7"
                                        listData={[
                                          {
                                            value: "fixed",
                                            label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                          },
                                          {
                                            value: "percentage",
                                            label: "%",
                                          },
                                        ]}
                                        keyName="label"
                                        onSelect={(name, value ) =>{
                                          this.setState({ type: value.value, isEdited: true });
                                          this.pricingchanges(k, key, {
                                            target: {
                                              value: value.value,
                                              name: "type",
                                            },
                                          }, item, obj?._id)  
                                          this.unselectCell()
                                        }}
                                        unselectCell={this.unselectCell}
                                        onBlur={async ()=> { 
                                          await this.handleSubmitPricing();  
                                          this.unselectCell()
                                        }}
                                      />}
                                    </div>  
                                  <div className="d-flex w-100" onClick={() => this.selectCell("FA2")}>
                                    {!isGlobalizationEnabled() && <input
                                      type="number"
                                      className="form-control border-0 text-right"
                                      name="finalAmount"
                                      autoComplete="off"
                                      value={item.finalAmount || ""}
                                      onChange={(e) =>
                                        this.pricingchanges(k, key, e, item, obj?._id)
                                      }
                                      min={0}
                                      onBlur={async ()=>{ 
                                        await this.handleSubmitPricing(); 
                                        this.unselectCell()
                                      }}
                                    />}
                                    {isGlobalizationEnabled() && (
                                      <NumberFormatterV2 
                                        selected={["FA1","FA2","fixed"].includes(this.state.selectedCell)}
                                        type="number"
                                        className="form-control border-0 text-right"
                                        childClassName="form-control border-0 text-right"
                                        name="finalAmount"
                                        autoComplete="off"
                                        value={item.finalAmount || ""}
                                        onChange={(e) =>
                                          this.pricingchanges(k, key, e, item, obj?._id)
                                        }
                                        min={0}
                                        onBlur={async ()=> {
                                          await this.handleSubmitPricing(); 
                                          this.unselectCell()
                                        }}
                                        showCurrency={false}
                                        autoFocus={false}
                                      />
                                    )}
                                    {formErrors[k] &&
                                      formErrors[k][key] &&
                                      formErrors[k][key].finalAmount && (
                                        <small style={{ color: "red" }}>
                                          {formErrors[k] &&
                                            formErrors[k][key] &&
                                            formErrors[k][key].finalAmount}
                                        </small>
                                      )}
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td className={` px-0 ${this.state.selectedCell==="finalAmount" && 'newdriverrow td-clicked'}`}
                              onClick={() => this.selectCell("finalAmount")}>
                                {!isGlobalizationEnabled() && <input
                                  className="form-control border-0 text-right"
                                  type="number"
                                  name="finalAmount"
                                  autoComplete="off"
                                  value={item.finalAmount || ""}
                                  placeholder = "0.00"
                                  onChange={(e) => this.pricingchanges(k, key, e, item, obj?._id)}
                                  min={0}
                                  onBlur={async ()=>{ 
                                    await this.handleSubmitPricing(); 
                                    this.unselectCell()
                                  }}
                                />}
                                {isGlobalizationEnabled() && (
                                  <NumberFormatterV2 
                                    selected={this.state.selectedCell==="finalAmount"}
                                    className="form-control border-0 text-right"
                                    childClassName="form-control border-0 text-right"
                                    type="number"
                                    name="finalAmount"
                                    autoComplete="off"
                                    value={item.finalAmount || ""}
                                    placeholder = "0.00"
                                    onChange={(e) => this.pricingchanges(k, key, e, item, obj?._id)}
                                    min={0}
                                    onBlur={async ()=>{
                                      await this.handleSubmitPricing(); 
                                      this.unselectCell()}}
                                    showCurrency={false}
                                  />
                                )}
                                {formErrors[k] &&
                                  formErrors[k][key] &&
                                  formErrors[k][key].finalAmount && (
                                    <small style={{ color: "red" }}>
                                      {formErrors[k] &&
                                        formErrors[k][key] &&
                                        formErrors[k][key].finalAmount}
                                    </small>
                                  )}
                              </td>
                            )}
                            <td 
                              className={`lint px-0 ${this.state.selectedCell==="unit" && 'newdriverrow td-clicked'}`}
                                onClick={() => this.selectCell("unit")}
                            >
                              <NewPriceInput
                                name="unit"
                                value={item.unit}
                                onChange={(val) => {
                                  this.pricingchanges(k, key, {
                                    target: {
                                      value: val,
                                      name: "unit",
                                    },
                                  }, item, obj?._id);
                                }}
                                onBlur={async ()=>{await this.handleSubmitPricing(); this.unselectCell()}}
                              />
                            </td>
                            <td className={`px-1 pl-2 cell-2 ${this.state.selectedCell==="perType" ? 'newdriverrow td-clicked' : ''}`}
                                onClick={() => this.selectCell("perType")} >
                              <div className="d-flex justify-content-between align-items-center">
                                    {item?.perType || <span className="text-muted">Select PerType</span>}
                                    <IconAngleArrowDown className='' />
                                    {this.state.selectedCell === "perType" &&
                                    <SelectCustomComponent
                                      name='perType'
                                      searchable={true}
                                      refClassName="cell-2"
                                      listData={options}
                                      keyName="label"
                                      onSelect={(name, value) =>{
                                        this.pricingchanges(k, key, {
                                          target: { value:value.value, name: "perType" },
                                        }, item, obj?._id)
                                        this.unselectCell()
                                      }
                                      }
                                      unselectCell={this.unselectCell}
                                    />}
                              </div>
                            </td>
                            <td className={` px-0 ${this.state.selectedCell==="freeDays" ? 'newdriverrow td-clicked' : ''}`}
                              onClick={() => this.selectCell("freeDays")}>
                              {!isGlobalizationEnabled() && <input
                                type="number"
                                className="form-control border-0"
                                placeholder="0"
                                autoComplete="off"
                                name="freeDays"
                                disabled={!item?.perType || item.perType === "percentage"}
                                value={item.freeDays || ""}
                                onChange={(e) =>
                                  item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                }
                                min={0}
                                onBlur={async ()=>{await this.handleSubmitPricing(); this.unselectCell()}}
                              />}
                              {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                  selected={this.state.selectedCell==="freeDays"}
                                  type="number"
                                  className="form-control border-0"
                                  childClassName="form-control border-0"
                                  placeholder="0"
                                  autoComplete="off"
                                  name="freeDays"
                                  disabled={!item?.perType || item.perType === "percentage"}
                                  value={item.freeDays || ""}
                                  onChange={(e) =>
                                    item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                  }
                                  min={0}
                                  onBlur={async ()=>{await this.handleSubmitPricing(); this.unselectCell()}}
                                  showCurrency={false}
                                  disablePlaceholder={!item?.perType || item.perType === "percentage"}
                                />
                              )}
                            </td>
                            <td className={` px-0 ${this.state.selectedCell==="chargePerDay" ? 'newdriverrow td-clicked' : ''}`}
                              onClick={() => this.selectCell("chargePerDay")}>
                              {!isGlobalizationEnabled() && <input
                                type="number"
                                className="form-control border-0"
                                placeholder="0"
                                autoComplete="off"
                                name="chargePerDay"
                                disabled={!item?.perType}
                                value={item.chargePerDay || ""}
                                onChange={(e) =>
                                  item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                }
                                min={0}
                                onBlur={async ()=>{await this.handleSubmitPricing(); this.unselectCell()}}
                              />}
                              {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                  selected={this.state.selectedCell==="chargePerDay"}
                                  type="number"
                                  className="form-control border-0"
                                  placeholder="0"
                                  autoComplete="off"
                                  name="chargePerDay"
                                  disabled={!item?.perType}
                                  value={item.chargePerDay || ""}
                                  onChange={(e) =>
                                    item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                  }
                                  min={0}
                                  onBlur={async ()=>{await this.handleSubmitPricing(); this.unselectCell()}}
                                  showCurrency={false}
                                  disablePlaceholder={!item?.perType}
                                />
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-xs btn-circle bg-soft-danger"
                                onClick={
                                  () => this.DeletePriceRow(k, key, item)
                                }
                              >
                                <IconMinus className="text-danger" />
                              </button>
                            </td>
                          </tr>)
                        } else {
                          return (
                            <tr className={`row-${k}-${key} select-custom-input ${this.state.addCharge ? "pointer-none" : ""} ${error?"background-error":""} ${success ? 'background-success' : ''}`} key={key}
                            style={{opacity: `${this.state.subBillIndex ==k && this.state.addCharge ? '0.2' : ""}`, transition: 'background .4s ease-out'}}
                            onKeyDown={(e)=>this.handleKeyDown(e)} 
                            >
                              <td className={`cell-${k}-${key}-3 pl-2 pr-1 "font-14 text-dark" ${(disabled)? 'disable-input':""} px-0 ${this.state.selectedCell===`cell-${k}-${key}-3` ? 'newdriverrow td-clicked' : ''}`} 
                                  onClick={() => this.selectCell(`cell-${k}-${key}-3`)}>
                                <div className="d-flex justify-content-between align-items-center min-width-200">
                                    {chargeValue && chargeValue.chargeName || <span className="text-muted font-12">Select Charge</span>}
                                    <IconAngleArrowDown className='' />
                                    {this.state.selectedCell === `cell-${k}-${key}-3` &&
                                    <SelectCustomComponent
                                      name='charges'
                                      searchable={true}
                                      refClassName={`cell-${k}-${key}-3`}
                                      listData={charges.filter((d) => d.isActive)}
                                      keyName="chargeName"
                                      onSelect={(name, value) => {
                                        this.pricingchanges(k, key, {
                                          target: { value:value.value, name: "name" },
                                        }, item, obj?._id,true)
                                        this.unselectCell()
                                      }}
                                      unselectCell={this.unselectCell}
                                    />}
                                  </div>
                              </td>
                              <td  className={`px-0 ${(disabled)? 'disable-input':""} ${this.state.selectedCell===`EditDescription-${k}-${key}` ? 'newdriverrow td-clicked' : ''}`}
                                onClick={() => this.selectCell(`EditDescription-${k}-${key}`)}>
                                  <div className="min-width-300">
                                    <input
                                      type="text"
                                      className="form-control border-0 bg-transparent"
                                      placeholder="Add Description..."
                                      name="description"
                                      autoComplete="off"
                                      value={item.description || ""}
                                      disabled={disabled}
                                      onBlur={async ()=> {
                                        await this.handleSubmitPricing()
                                        this.unselectCell()
                                      }}
                                      onChange={(e) => this.pricingchanges(k, key, e, item, obj?._id)}
                                    />
                                </div>
                              </td>
                              {item.name === "Fuel" ? (
                                <td  className={`EditAmount-${k}-${key} px-0 ${(disabled)? 'disable-input':''} ${[`E1-${k}-${key}`,`E2-${k}-${key}`,`EditAmount-${k}-${key}`].includes(this.state.selectedCell) && 'newdriverrow td-clicked'} lint`}
                                // onClick={() => this.selectCell(`EditAmount-${k}-${key}`)}
                                >
                                <div className="d-flex null custom-select-border justify-content-between min-width-190">
                                    <div className="d-flex align-items-center justify-content-center w-100" onClick={() => this.selectCell(`E1-${k}-${key}`)}>
                                      {!isGlobalizationEnabled() && <input
                                        className="form-control border-0 text-right"
                                        type="number"
                                        name="amount"
                                        value={item.amount || ""}
                                        disabled={disabled}
                                        onChange={(e) =>
                                          this.pricingchanges(k, key, e, item, obj?._id)
                                        }
                                        min={0}
                                        autoComplete="off"
                                        onBlur={async ()=> {
                                          await this.handleSubmitPricing()
                                          this.unselectCell()
                                        }}
                                      />}
                                      {isGlobalizationEnabled() && (
                                        <NumberFormatterV2 
                                          selected={[`E1-${k}-${key}`,`E2-${k}-${key}`,`EditAmount-${k}-${key}`].includes(this.state.selectedCell)}
                                          className="form-control border-0 text-right"
                                          childClassName="form-control border-0 text-right"
                                          type="number"
                                          name="amount"
                                          value={item.amount || ""}
                                          disabled={disabled}
                                          onChange={(e) =>
                                            this.pricingchanges(k, key, e, item, obj?._id)
                                          }
                                          min={0}
                                          autoComplete="off"
                                          onBlur={async ()=> {
                                            await this.handleSubmitPricing()
                                            this.unselectCell()
                                          }}
                                          autoFocus={false}
                                          showCurrency={false}
                                        />
                                      )}
                                    </div>
                                      <div className={`d-flex justify-content-between align-items-center min-width-50 line-height-30 pointer`}
                                        onClick={() => this.selectCell(`EditAmount-${k}-${key}`)}
                                        >
                                          <div className="transition-y2">{item.type && item.type === "fixed" ? `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}` : "%"}</div>
                                          <div className="ml-20"><IconAngleArrowDown /></div>
                                          {this.state.selectedCell===`EditAmount-${k}-${key}` &&
                                            <SelectCustomCurrency
                                                name='value'
                                                searchable={false}
                                                refClassName={`EditAmount-${k}-${key}`}
                                                listData={[
                                                    {
                                                        value: "fixed",
                                                        label: `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                                    },
                                                    {
                                                        value: "percentage",
                                                        label: "%",
                                                    },
                                                ]}
                                                keyName="label"
                                                onSelect={(name, value ) =>{
                                                  this.setState({type: value.value, isEdited: true });
                                                  this.pricingchanges(k, key, {
                                                      target: {
                                                          value: value.value,
                                                          name: "type",
                                                      },
                                                  })
                                                  this.unselectCell()                                 
                                              }}
                                              unselectCell={this.unselectCell}
                                              onBlur={async ()=> {await this.handleSubmitPricing(); this.unselectCell()}}
                                            /> 
                                          }
                                        </div>
                                    <div className="d-flex w-100" onClick={() => this.selectCell(`E2-${k}-${key}`)}>
                                      {!isGlobalizationEnabled() && <input
                                        type="number"
                                        className="form-control border-0 text-right"
                                        name="finalAmount"
                                        value={item.finalAmount || ""}
                                        placeholder="0.00"
                                        autoComplete="off"
                                        disabled={disabled}
                                        onChange={(e) =>
                                          this.pricingchanges(k, key, e, item, obj?._id)
                                        }
                                        onBlur={async ()=> {
                                          await this.handleSubmitPricing()
                                          this.unselectCell()
                                        }}
                                        min={0}
                                      />}
                                      {isGlobalizationEnabled() && (
                                        <NumberFormatterV2 
                                          selected={[`E1-${k}-${key}`,`E2-${k}-${key}`,`EditAmount-${k}-${key}`].includes(this.state.selectedCell)}
                                          type="number"
                                          className="form-control border-0 text-right"
                                          childClassName="form-control border-0 text-right"
                                          name="finalAmount"
                                          value={item.finalAmount || ""}
                                          placeholder="0.00"
                                          autoComplete="off"
                                          disabled={disabled}
                                          onChange={(e) =>
                                            this.pricingchanges(k, key, e, item, obj?._id)
                                          }
                                          onBlur={async ()=> {
                                            await this.handleSubmitPricing()
                                            this.unselectCell()
                                          }}
                                          min={0}
                                          showCurrency={false}
                                          autoFocus={false}
                                        />
                                      )}
                                      {formErrors[k] &&
                                        formErrors[k][key] &&
                                        formErrors[k][key].finalAmount && (
                                          <small style={{ color: "red" }}>
                                            {formErrors[k] &&
                                              formErrors[k][key] &&
                                              formErrors[k][key].finalAmount}
                                          </small>
                                        )}
                                    </div>
                                  </div>
                                </td>
                              ) : (
                                <td  className={`px-0 ${(disabled)? 'disable-input':""} ${this.state.selectedCell===`EditFinalAmount-${k}-${key}` && 'newdriverrow td-clicked'}`}
                                    onClick={() => this.selectCell(`EditFinalAmount-${k}-${key}`)}>
                                    <div className="min-width-200">
                                      {!isGlobalizationEnabled() && <input
                                        className="form-control border-0 bg-transparent text-right"
                                        placeholder = "0.00"
                                        type="number"
                                        name="finalAmount"
                                        autoComplete="off"
                                        value={item.finalAmount || ""}
                                        disabled={disabled}
                                        onChange={(e) => this.pricingchanges(k, key, e, item, obj?._id)}
                                        min={0}
                                        onBlur={async ()=> {
                                          await this.handleSubmitPricing()
                                          this.unselectCell()
                                        }}
                                      />}
                                      {isGlobalizationEnabled() && (
                                        <NumberFormatterV2 
                                          selected={this.state.selectedCell===`EditFinalAmount-${k}-${key}`}
                                          className="form-control border-0 bg-transparent text-right"
                                          childClassName="form-control border-0 bg-transparent text-right"
                                          placeholder = "0.00"
                                          type="number"
                                          name="finalAmount"
                                          autoComplete="off"
                                          value={item.finalAmount || ""}
                                          disabled={disabled}
                                          onChange={(e) => this.pricingchanges(k, key, e, item, obj?._id)}
                                          min={0}
                                          onBlur={async ()=> {
                                            await this.handleSubmitPricing()
                                            this.unselectCell()
                                          }}
                                          showCurrency={false}
                                        />
                                      )}
                                      {formErrors[k] &&
                                        formErrors[k][key] &&
                                        formErrors[k][key].finalAmount && (
                                          <small style={{ color: "red" }}>
                                            {formErrors[k] &&
                                              formErrors[k][key] &&
                                              formErrors[k][key].finalAmount}
                                          </small>
                                        )}
                                    </div>
                                </td>
                              )}
                              <td 
                                className={`lint px-0 ${this.state.selectedCell===`unit-${k}-${key}` && 'newdriverrow td-clicked'} ${(disabled)? 'disable-input':''}`}
                                  onClick={() => this.selectCell(`unit-${k}-${key}`)}
                              >
                                <NewPriceInput
                                  disabled={disabled}
                                  name="unit"
                                  value={item.unit}
                                  onChange={(val) => {
                                    this.pricingchanges(k, key, {
                                      target: {
                                        value: val,
                                        name: "unit",
                                      },
                                    }, item, obj?._id);
                                  }}
                                  onBlur={async ()=> {
                                    await this.handleSubmitPricing()
                                    this.unselectCell()
                                  }}
                                  selected={this.state.selectedCell===`unit-${k}-${key}`}
                                />
                              </td>
                              <td className={`px-1 pl-2 cell-${k}-${key}-4 ${disabled? 'disable-input':""} ${this.state.selectedCell===`cell-${k}-${key}-4` ? 'newdriverrow td-clicked' : ''}`}
                                  onClick={() => this.selectCell(`cell-${k}-${key}-4`)} >
                                <div className="d-flex justify-content-between align-items-center min-width-150">
                                      {item?.perType || <span className="text-muted">Select PerType</span>}
                                      <IconAngleArrowDown className='' />
                                      {this.state.selectedCell === `cell-${k}-${key}-4` &&
                                      <SelectCustomComponent
                                        name='perType'
                                        searchable={true}
                                        refClassName={`cell-${k}-${key}-4`}
                                        listData={options}
                                        keyName="label"
                                        onSelect={(name, value) =>{
                                          this.pricingchanges(k, key, {
                                            target: { value:value.value, name: "perType" },
                                          }, item, obj?._id,"apiCall")
                                          this.unselectCell()
                                        }
                                        
                                        }
                                        unselectCell={this.unselectCell}
                                      />}
                                </div>
                              </td>
                              <td className={` px-0 ${this.state.selectedCell===`EditFreeDays-${k}-${key}` && 'newdriverrow td-clicked'} ${(!item.perType || disabled || item.perType === "percentage")? 'disable-input':''}`}
                                  onClick={() => this.selectCell(`EditFreeDays-${k}-${key}`)}>
                                    <div className="min-width-100">
                                      {!isGlobalizationEnabled() && <input
                                        type="number"
                                        className="form-control border-0 bg-transparent"
                                        placeholder="0"
                                        name="freeDays"
                                        autoComplete="off"
                                        disabled={!item.perType || disabled || item.perType === "percentage"}
                                        value={item.freeDays || ""}
                                        onChange={(e) =>
                                          item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                        }
                                        min={0}
                                        onBlur={async ()=> {
                                          await this.handleSubmitPricing()
                                          this.unselectCell()
                                        }}
                                      />}
                                      {isGlobalizationEnabled() && (
                                        <NumberFormatterV2 
                                          selected={this.state.selectedCell===`EditFreeDays-${k}-${key}`}
                                          type="number"
                                          className="form-control border-0 bg-transparent"
                                          childClassName="form-control border-0 bg-transparent"
                                          placeholder="0"
                                          name="freeDays"
                                          autoComplete="off"
                                          disabled={!item.perType || disabled || item.perType === "percentage"}
                                          value={item.freeDays || ""}
                                          onChange={(e) =>
                                            item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                          }
                                          min={0}
                                          onBlur={async ()=> {
                                            await this.handleSubmitPricing()
                                            this.unselectCell()
                                          }}
                                          showCurrency={false}
                                          disablePlaceholder={!item.perType || disabled || item.perType === "percentage"}
                                        />
                                      )}
                                </div>
                              </td>
                              <td className={` px-0 ${this.state.selectedCell===`EditChargePerDay-${k}-${key}` ? 'newdriverrow td-clicked' : ''} ${(!item.perType|| disabled)? 'disable-input':''}`}
                                  onClick={() => this.selectCell(`EditChargePerDay-${k}-${key}`)}>
                                  <div className="min-width-100">  
                                      {!isGlobalizationEnabled() && <input
                                        type="number"
                                        className="form-control border-0 bg-transparent"
                                        placeholder="0"
                                        name="chargePerDay"
                                        autoComplete="off"
                                        disabled={!item.perType|| disabled}
                                        value={item.chargePerDay || ""}
                                        onChange={(e) =>
                                          item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                        }
                                        onBlur={async ()=> {
                                          await this.handleSubmitPricing()
                                          this.unselectCell()
                                        }}
                                        min={0}
                                      />}
                                      {isGlobalizationEnabled() && (
                                        <NumberFormatterV2
                                          selected={this.state.selectedCell===`EditChargePerDay-${k}-${key}`}
                                          type="number"
                                          className="form-control border-0 bg-transparent"
                                          childClassName="form-control border-0 bg-transparent"
                                          placeholder="0"
                                          name="chargePerDay"
                                          autoComplete="off"
                                          disabled={!item.perType|| disabled}
                                          value={item.chargePerDay || ""}
                                          onChange={(e) =>
                                            item.perType && this.pricingchanges(k, key, e, item, obj?._id)
                                          }
                                          onBlur={async ()=> {
                                            await this.handleSubmitPricing()
                                            this.unselectCell()
                                          }}
                                          min={0}
                                          showCurrency={false}
                                          disablePlaceholder={!item.perType || disabled}
                                        />
                                      )}
                                </div>
                              </td>
                              <td className={`${(disabled)? 'disable-input':''}`}>
                                <button
                                  className="btn btn-xs btn-circle bg-soft-danger"
                                  disabled={disabled}
                                  onClick={
                                    () => this.DeletePriceRow(k, key, item)
                                  }
                                >
                                  <IconMinus className="text-danger" />
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      })}
                      {
                       this.state.isTaxable &&
                       (!!obj?.taxAmount || obj?.dynamicTax?._id)&&
                        <tr
                          style={{opacity: `${this.state.subBillIndex ==k &&this.state.addCharge ? '0.2' : ""}`, transition: 'background .4s ease-out'}}
                        >
                          <td></td>
                          <td></td>
                          <td>
                            <h4 className="form-control m-0 p-0  border-0 d-flex flex-row align-items-center justify-content-between w-100">
                              <span className="w-50 ">
                                Tax {`${obj?.taxType && (obj?.taxRate || obj?.dynamicTax?._id) ? "(" + obj?.taxType + ") @ " + ((parseFloat(obj?.taxRate) * 100).toFixed(2) ?? 0)?.print() + " %" : ""}`}
                              </span>
                              <span className="w-50 text-right">

                              {(parseFloat(obj?.taxAmount).toFixed(2) ?? 0)?.toCurrency(customerCurrency)}
                              </span>
                            </h4>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      }
                      <tr style={{opacity: `${this.state.subBillIndex ==k &&this.state.addCharge ? '0.2' : ""}`, transition: 'background .4s ease-out'}}>
                        <td></td>
                        <td></td>
                        <td>
                          <h4 className="m-0 text-right">
                            {
                              this.state.isTaxable &&
                              !!obj?.totalTaxAmount ?
                              parseFloat(obj?.totalTaxAmount).toFixed(2)?.toCurrency(customerCurrency)
                              : parseFloat(isTotalAmount).toFixed(2)?.toCurrency(customerCurrency)
                            }
                          </h4>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  <div className="my-10">
                  <button 
                      className="btn btn-white btn-sm text-primary"
                      onKeyDown={(e) => this.handleOnKeyDown(e, obj.customerId, k)}
                      id={`addChargeFocus-${k}`}
                      onFocus={()=>this.focusOnButton(k)}
                      onBlur={()=>this.blurOnButton(k)}
                      >
                      <a
                        className={`text-primary font-weight-500 ${(obj.status === "BILLING" || disabled || this.state.isCalling ||(this.state.isApiCall && this.state.subBillIndex === k) ) ? "disabled-pointer-events" : ""}`}
                        onClick={() => {
                          const element = document?.getElementById(`addChargeFocus-${k}`)
                          element.style.border = "none"
                          this.AddPriceRows(obj.customerId, k)}
                        }
                      >
                      <IconPlus className="mr-2" />
                      Add Charge
                    </a>
                    </button>
                  </div>
                  <div className="card mt-1 mb-3 p-15">
                   
                    <div data-index-number="sub-header-v1" className="d-flex flex-row align-items-center justify-content-between">
                      <div className="d-flex flex-row">
                        <div
                          className={`rounded-3 py-2 px-2 mr-1 ${
                            obj.status === "APPROVED"
                              ? "badge-primary-400"
                              : "badge-light"
                          }`}
                        >
                          <div className="form-check">
                            <input
                              type="checkbox"
                              disabled={
                                (obj.status !== "COMPLETED") &&
                                (!["PARTIAL_PAID","FULL_PAID"].includes(obj.status)) &&
                                (!this.state.isEnable || obj.status === "APPROVED") &&
                                (obj.status !== "UNAPPROVED") ||
                                (["BILLING","REBILLING"].includes(obj.status))||disableStatus
                              }
                              value="APPROVED"
                              name={`status_` + k}
                              className="form-check-input rounded-circle form-check-input--radio-check"
                              id={`chkApproved_${obj._id + "_" + k}`}
                              checked={
                                obj.status === "APPROVED"
                              }
                              onChange={(e) => this.chkchange(e, k, 'approve', obj?._id)}
                              autoComplete="off"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`chkApproved_${obj._id + "_" + k}`}
                            >
                              Approved
                            </label>
                          </div>
                        </div>
                        <div
                          className={`rounded-3 py-2 px-2 mr-1 ${
                            obj.status === "UNAPPROVED"
                              ? "badge-primary-400"
                              : "badge-light"
                          }`}
                        >
                          <div className="form-check">
                            <input
                              type="checkbox"
                              disabled={
                                (!["PARTIAL_PAID","FULL_PAID"].includes(obj.status)) &&
                                (!this.state.isEnable || obj.status === "UNAPPROVED") &&
                                (obj.status !== "REBILLING" || !obj.status) &&
                                (obj.status !== "APPROVED") ||
                                (["BILLING"].includes(obj.status))||disableStatus
                              }
                              value="UNAPPROVED"
                              name={`status_` + k}
                              className="form-check-input rounded-circle form-check-input--radio-check"
                              id={`chkUnapproved_${obj._id + "_" + k}`}
                              checked={obj.status === "UNAPPROVED"}
                              onChange={(e) => this.chkchange(e, k, 'unapprove', obj?._id)}
                              autoComplete="off"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`chkUnapproved_${obj._id + "_" + k}`}
                            >
                              Unapproved
                            </label>
                          </div>
                        </div>
                        <div
                          className={`rounded-3 py-2 px-2 mr-1 ${
                            obj.status === "REBILLING"
                              ? "badge-primary-400"
                              : "badge-light"
                          }`}
                        >
                          <div className="form-check">
                            <input
                              type="checkbox"
                              disabled={
                                (!["PARTIAL_PAID","FULL_PAID"].includes(obj.status)) &&
                                (!this.state.isEnable || obj.status === "REBILLING") && 
                                (obj.status !== "BILLING") || 
                                (["APPROVED","UNAPPROVED"].includes(obj.status))||
                                !obj.status
                              }
                              value="REBILLING"
                              name={`status_` + k}
                              className="form-check-input rounded-circle form-check-input--radio-check"
                              id={`chkRebill_${obj._id + "_" + k}`}
                              checked={obj.status === "REBILLING"}
                              onChange={(e) => this.chkchange(e, k, "rebill", obj?._id)}
                              autoComplete="off"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`chkRebill_${obj._id + "_" + k}`}
                            >
                              Credit/Rebill
                            </label>
                          </div>
                        </div>
  
                        <div
                          className={`rounded-3 py-2 px-2 mr-1 ${
                            obj.status === "BILLING"
                              ? "badge-primary-400"
                              : "badge-light"
                          }`}
                        >
                          <div className="form-check">
                            <input
                              disabled={
                                this.state.isEnable &&
                                (["APPROVED","BILLING"].includes(obj.status)) &&
                                (!obj.hasOwnProperty('status') || !obj.status) ||
                                (obj.status === "UNAPPROVED") ||
                                (["BILLING"].includes(obj.status)) || 
                                !obj.status ||disableStatus
                              }
                              type="checkbox"
                              value="BILLING"
                              name={`status_` + k}
                              className="form-check-input rounded-circle"
                              id={`chkBilling_${obj._id + "_" + k}`}
                              checked={["BILLING"].includes(obj.status)}
                              onChange={(e) => this.chkchange(e, k, "invoice", obj?._id)}
                              autoComplete="off"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`chkBilling_${obj._id + "_" + k}`}
                            >
                              Invoice
                            </label>
                          </div>
                        </div>
                        <div
                          className={`rounded-3 py-2 px-2 mr-1 ${
                            obj.status === "BILLING"
                              ? "badge-primary-400"
                              : "badge-light"
                          }`}
                        >
                          <div className="form-check">
                            <input
                              type="checkbox"
                              disabled={
                                this.state.isEnable &&
                                (["APPROVED","BILLING"].includes(obj.status)) &&
                                (!obj.hasOwnProperty('status') || !obj.status) ||
                                (obj.status !== "UNAPPROVED") ||
                                (["BILLING"].includes(obj.status))||disableStatus
                              }
                              value="APPROVE_AND_BILLING"
                              name={`status_` + k}
                              className="form-check-input rounded-circle"
                              id={`chkApprove_Billing_${obj._id + "_" + k}`}
                              checked={["BILLING"].includes(obj.status)}
                              onChange={(e) => this.chkchange(e, k, "approve_invoice", obj?._id)}
                              autoComplete="off"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`chkApprove_Billing_${obj._id + "_" + k}`}
                            >
                              Approve and Invoice
                            </label>
                          </div>
                        </div>
                      </div>
  
                      {/* {obj.billingDate && ( */}
                      <div className={`d-flex ${!isCreditMemo() ? "align-items-center" : "justify-content-end " } `}>
                        {["BILLING",
                          "REBILLING",
                          "PARTIAL_PAID",
                          "FULL_PAID"].includes(obj.status) && (
                            <>
                             {obj.subInvoiceNumber && (
                                <div className={`p-0 mb-0 ${!isCreditMemo() ?  "d-flex align-items-center mr-3" : "mr-10 form-group col-md"}`}>
                                  <label className={`${!isCreditMemo() ? "text-nowrap d-block  mb-0" : ""}`}>Combined Invoice No.</label> 
                                  {!isCreditMemo() ? 
                                    <span class="badge badge-light">{obj.subInvoiceNumber}</span> :
                                    <input className="form-control" type="text" disabled={true} value={obj.subInvoiceNumber} name="CombinedInvoice" placeholder=""/>
                                  }
                                </div>
                              )}
                              {obj.sub_reference_number && (
                                <div className={`p-0 mb-0 ${!isCreditMemo() ?  "d-flex align-items-center mr-3" : "mr-10 form-group col-md"}`}>
                                  <label className={`${!isCreditMemo() ? "text-nowrap d-block  mb-0" : ""}`}>Sub Reference No.</label>
                                  {!isCreditMemo() ? 
                                  <span class="badge badge-light">{obj.sub_reference_number}</span> :
                                  <input className="form-control" type="text" disabled={true} value={obj.sub_reference_number} name="subReferenceNo" placeholder=""/>
                                  }
                                </div>
                              )}
                        </>
                        )}
                          <div className={`form-group col-md p-0 mb-0 ${!isCreditMemo() ?  "d-flex align-items-center" : ""}`}>
                            <label className={`label-control ${!isCreditMemo() ? "text-nowrap d-block mr-3 mb-0" : ""}`}>Sub Billing Date</label>
                            <div className={`${this.state.addCharge && "pointer-none"} input-wrapper`}>
                              <DateWrapper
                                dateFormat={DateTimeFormatUtils.fullDateFormat()}
                                displayTimeZone={getStorage("timeZone")}
                                onChange={(e) =>
                                  this.changeBillingDate(k, moment(e).toISOString())
                                }
                                defaultDate={obj.billingDate
                                  ? moment(obj.billingDate)
                                  : ""
                                }
                                inputClassName={`billingDate-${k}`}
                                dontShowSelectDate={true}
                                isShowTime={false}
                                hideShowSelectDate={true}
                                className="right-0"
                                placeholder="Select Date..."
                              />
                              <div className="input-icon">
                                  {
                                !obj?.isBilled &&
                                !obj?.subInvoiceNumber &&
                                obj.billingDate ? 
                                <span
                                  className="pointer"
                                  onClick={() => {
                                    this.changeBillingDate(k, null)
                                  }}
                                >
                                  <IconCircleClose className="fill-primary" />
                                </span>
                                :
                                <IconCalendar />
                                  }
                            </div>
                            </div>
                          </div>
                          {obj?._id &&
                        <>
                          {isPaymentTermFlagEnabled() && (
                            <>
                            <div className={`d-flex ${!isCreditMemo() ? "align-items-center" : "justify-content-end ml-2" } `}>
                            <div className={`p-0 mb-0 ${!isCreditMemo() ?  "d-flex align-items-center mr-3" : "mr-10 form-group col-md"}`}>
                              <label className={`${!isCreditMemo() ? "text-nowrap d-block  mb-0 ml-3 mr-3" : ""}`}>Payment Terms</label> 
                                <div className={`paymentTerm-${k} input-wrapper`} style={{ width: "124px" }}>
                                  <input 
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onWheel={() => document.activeElement.blur()}
                                    onChange={(e)=>{
                                      let temp = _.cloneDeep(this.state.additionalPricing);
                                      temp[k].paymentTerm = e.target.value;
                                      this.setState({additionalPricing: temp},() => {
                                      this.handlePaymentTerm(k, e.target.value);
                                    })
                                    }}
                                    value={obj.paymentTerm}
                                    type='number' 
                                    placeholder="Enter Term.."
                                    className={`form-control`} 
                                  />
                                </div>
                              </div>
                            </div>
                            </>
                            )}
                        </>}
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>}

                {this.state.isActiveTab.includes(k) &&  isCreditMemo() &&
                    <ExistingCreditMemos
                      selectedLoad={selectedLoads}
                      existingCreditMemos={this.props.allCreditMemo.filter((item) => item?.sub_reference_number === obj.sub_reference_number )}
                      chargeCode={chargeCodeList}
                      isSubCustomer = {true}
                      setNewCreditMemo={this.props.setNewCreditMemo}
                      AdditionalObj={obj}
                      isTotalAmount={isTotalAmount}
                      isTaxable={this.state.isTaxable}
                      customerCurrency={customerCurrency}
                    />
                }
                {isCreditMemo() && this.state.isActiveTab.includes(k) && this.state.isShowMemoModal && this.state.subCustomer &&
                  <CreditMemoModal
                    setShowCreditMemoModal={this.handleCreateMemoModal}
                    selectedLoad={this.props.selectedLoads}
                    chargeCode={chargeCode}
                    isEdit={true}
                    isCreate={true}
                    subCustomer={this.state.subCustomer}
                    setNewCreditMemo={this.props.setNewCreditMemo}
                  />
                }
              </div>
            );
          })}
  
          <Modal className="backdrop_modal_open" show={this.state.sendEmailModal} centered animation={false}>
            <Modal.Header>
              <Modal.Title>Send Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-wrapper">
                <form>
                  <div className="form-group">
                    <label className="col-form-label">To</label>
  
                    <CustomCreatableSelect
                      name={"name"}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Enter emails.."
                      value={this.state.emails.map((i) => {
                        const val = {
                          label: i,
                          value: i,
                        };
  
                        return val;
                      })}
                      onChange={(val) => {
                        this.handleextraEmailChange(
                          _.compact(
                            val.map((i) => {
                              if (!validateEmail(i.value)) return i.value;
                            })
                          )
                        )
                      }}
                    />
  
                    <span className="text-gray-400 d-block mt-1">
                      You can add multiple email addresses
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">CC/BCC</label>
                    <CustomCreatableSelect
                      name={"name"}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Enter emails.."
                      value={this.state.cc.map((i) => {
                        const val = {
                          label: i,
                          value: i,
                        };
  
                        return val;
                      })}
                      onChange={(val) => {
                        this.handleccEmailChange(val.map((i) => i.value));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Subject"
                      autoComplete="off"
                      value={this.state.inpSubject}
                      onChange={(e) => {
                        this.setState({ inpSubject: e.target.value });
                      }}
                    />
                  </div>
                  <div className="editor">
                    <label className="col-form-label">Message</label>
                    <Editor
                      wrapperClassName="demo-wrapper"
                      ref="draftRef"
                      onEditorStateChange={(editorState) => {
                        this.setState({
                          editorState,
                          inpBody: draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          ),
                        });
                      }}
                      handlePastedText={() => false}
                      editorState={this.state.editorState}
                      editorClassName="editorClassName"
                      placeholder="Enter Messsage"
                    />
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-close"
                onClick={() => this.setState({ sendEmailModal: false })}
              >
                Cancel
              </button>
              <button
                disabled={this.state.isEmailSendInProcess||this.state.emails.length === 0}
                className="btn btn-primary"
                onClick={() =>
                  this.download_Invoice(this.state.selectedCustomer, false) 
                }
              >
                {this.state.isEmailSendInProcess ? (
                  <CellSpinner className="mr-1" />
                ) : (
                  ""
                )}
                Send Email
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
  
  const states = (state) => ({
    customers: state.TmsReducer.customers,
    chargeCode: state.chargeCodeReducer.chargeCode
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(actionCreators, dispatch),
      dispatch: dispatch,
      tmsAction: bindActionCreators(tmsAction, dispatch),
    };
  }
  
  const VATTypes=[
    {
      value: "APPLICABLE",
      label: "APPLICABLE",
    },
    {
      value: "EXEMPT",
      label: "EXEMPT",
    },
  ]
  
  export default connect(states, mapDispatchToProps)(NewAdditionalPricing);
  
  const options = types;
  