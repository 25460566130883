import React, { Component } from 'react'
import CarrierPayRow from "./CarrierPayRow";
import CarrierPayPricingApprovalC from "./CarrierPayPricingApproval/CarrierPayPricingApproval.c";
import { IconAngleArrowDown, IconClock, IconDownload, IconInfoCircleFilledWhite, IconInfoFilled, IconMinus, IconPlus, IconTimes } from '../../../Common/Icons';
import { removeCarrierPayPrice } from "./actionCreator";
import { checkIsTaxable, checkIsVAT,Currency, isGlobalizationEnabled, convertUnit, defaultNumberSystem, getStorage, isNewChargeCode, isVendorPayAndBillsEnabled, toastr } from '../../../../services';
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from "redux";
import CustomSelect from '../../../Common/CustomSelect';

import * as tmsAction from "./actionCreator";
import _, { cloneDeep } from "lodash";
import SelectCustomComponent from '../../../../pages/tms/SelectComponent';
import moment from 'moment';
import NewPriceInput from '../../../Common/PriceInput/NewPriceInput';
import { newSmallSelectStyle, smallSelectStyle } from '../../../../assets/js/select-style';
import Select from 'react-select';
import TenderHistorySection from './TenderHistoryComponent';
import InvoiceApprovalHistory from "./InvoiceApprovalHistory/InvoiceApprovalHistory"
import { CHARGE_SET_TAB_LABELS, auditSubTabs, chargeSetTabs } from './VendorChargeSet/constants';
import { VATTYPES } from '../../../../pages/tms/CreditMemoV2/constants';
import { gettaxNote } from './utils';
import { NumberFormatterV2 } from '../Components/NumberFormater';
import SelectCustomCurrency from "pages/tms/SelectCurrency";

const MANUALLY_CHANGED = 'MANUALLY_CHANGED';
class CarrierPayTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCharge: false,
      isFailed: "transition-white-field",
      selectedCell: null,
      name: "",
      nameColor: "transition-white-field",
      isEdited: false,
      chargePerDay: 0,
      freeMinutes: 0,
      amount: 0,
      chargePerMinute: 0,
      perType: "",
      chargeType: MANUALLY_CHANGED,
      freeDays: 0,
      unit: 1,
      isSuccess: false,
      chargePerDay: 0,
      finalAmount: "",
      type: "percentage",
      description: "",
      pricingRules: [],
      carrierPayId: this.props.carrierPayId,
      labelName: "",
      labelValue: "",
      carrierChargeCodeId: "",
      chargeName: "",
      tenderHistory: this.props.tenderHistory,
      // pricing: this.props.carrierObj?.pricing ? this.props.carrierObj?.pricing : []
      rateOrAuditTab: CHARGE_SET_TAB_LABELS.CHARGE_RATE,
      subAuditTab: CHARGE_SET_TAB_LABELS.TENDER_STATUS,
      carrierPay: this.props.carrierObj,
      billFromCurrency:this.props?.carrierObj?.billFromDetail?.currency,
      taxType : this.props?.carrierObj?.taxDetail?.defaultTaxType === VATTYPES.APPLICABLE ? VATTYPES.APPLICABLE : VATTYPES.EXEMPT,
    }
    this.pricingDescription = null;
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.unselectCell = this.unselectCell.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.pricingchanges = this.pricingchanges.bind(this);
    this.newRowChanges = this.newRowChanges.bind(this)
    this.chargeCodeList = [];
    if (this.props.chargeCode?.length) {
      this.chargeCodeList = _.orderBy(this.props.chargeCode, "orderIndex", "asc");
    }
    this.carrierPricing = this.props.carrierObj?.carrierPricing ? this.props.carrierObj?.carrierPricing : [];
    this.isNewChargeCodeEnabled = isNewChargeCode();
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillMount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(props) {
    if (
      this.props.carrierObj?.pricing !== props.carrierObj.pricing ||
      (props.carrierObj?.pricing && !this.state.pricing)
    ) {
      let base_price = 0,
        x_charges = 0;
      let allPricings = props.carrierObj?.pricing.filter((x) => x);
      if (allPricings && allPricings.length > 0) {
        allPricings = _.sortBy(allPricings, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
        allPricings.map((x) => {
          if (x) {
            if (x.name == "Base Price") {
              base_price += parseFloat(x.finalAmount);
            } else {
              x_charges = (
                parseFloat(x_charges) + parseFloat(x.finalAmount)
              ).toFixed(2);
            }
            //x.manualFinalAmount = x.finalAmount;
          }
        })
      }
      const Total = (parseFloat(base_price) + parseFloat(x_charges)).toFixed(2);
      this.setState(
        { pricing: allPricings, base_price: base_price.toFixed(2), x_charges, Total, },
        () => { }
      );
    }

  }

  selectCell = (name) => {
    if (this.state.selectedCell === name) return
    this.setState({ selectedCell: name });

  }
  unselectCell = () => {
    this.setState({ selectedCell: null });
  }

  DeletePriceRow = async (pricingId) => {
    try {
      this.setState({ isAPICall: true });
      const data = await removeCarrierPayPrice({ carrierPayId: this.props.carrierObj?._id || this.state.carrierPayId, pricingId, reference_number: this.props.selectedLoads?.reference_number, loadId: this.props.carrierObj?.loadId, drayOSCarrierId: this.props.carrierObj?.drayosCarrier?._id, moveIds: this.props.carrierObj?.moveIds, })
      if (data) {
        this.props?.handleReloadOfBillingSummary && this.props.handleReloadOfBillingSummary()
        toastr.show("Your pricing has been deleted!", "success");
        //this.props.getCarrierPayRequest()
        let total = 0
        data?.pricing?.forEach(element => {
          total = total + parseFloat(element?.finalAmount)
        })

        const updatedCarrierPay = {...this.props.carrierObj, carrierPayId: this.props.carrierObj?._id, pricing: data?.pricing ?? []};
        this.props.handleUpdateCarrierPays(updatedCarrierPay);

        this.setState({ pricing: data?.pricing, isAPICall: false, Total: total.toFixed(2), carrierPay:data}, () => this.props.blockTabChange(false))
      }
    } catch (error) {
      console.log(error)
    }
  }

  onAddSelectCharge = (name, value) => {
    this.setState({
      name: value.value,
      nameColor: "transition-white-field",
      isEdited: true,
      selectedCell: null,
      labelName: value.labelName,
      labelValue: value.labelValue,
      carrierChargeCodeId: value._id,
      chargeName: value.chargeName
    });
    if (this.pricingDescription) this.pricingDescription.focus();
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  clearAddPricing = () => {
    this.setState({
      name: "",
      description: "",
      unit: 1,
      amount: 0,
      finalAmount: "",
      perType: "",
      chargeType: MANUALLY_CHANGED,
      freeDays: 0,
      chargePerDay: 0,
      chargePerMinute: 0,
      type: "",
      manualFinalAmount: 0,
      carrierChargeCodeId: "",
      labelName: "",
      labelValue: "",
      chargeName: ""
    })
  }
  newRowChanges(e) {
    const { name, value } = e.target;
    const old = [...this.state?.pricing];
    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });

    let { type, amount, unit, freeDays, chargePerDay, perType, manualFinalAmount, finalAmount } = this.state;
    if (name === "type") {
      finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
      manualFinalAmount = finalAmount;
    } else if (name === "amount") {
      finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
    } else if (name === "finalAmount" && value) {
      manualFinalAmount = value;
    } else if (["unit", "freeDays", "chargePerDay", 'perType'].includes(name)) {
      let computedAmount = (parseFloat(unit) - parseFloat(freeDays)) * parseFloat(chargePerDay);
      if (this.state.name !== 'Fuel') {
        if (computedAmount >= parseFloat(finalAmount || 0)) {
          finalAmount = computedAmount.toFixed(2);
        } else {
          finalAmount = parseInt(finalAmount || 0).toFixed(2);
        }
      }

      if ((name == "perType" && value == "percentage") || (name !== "perType" && perType == "percentage")) {
        if (this.state.name !== 'Fuel') {
          finalAmount = (this.state.base_price * unit / 100).toFixed(2);
        }
      }
    }
    this.setState({
      finalAmount: finalAmount,
    });
  }
  pricingchanges(index, e) {
    const { name, value, chargeData } = e.target;
    const old = [...this.state.pricing];
    const positiveValuesOnly = [
      "freeDays",
      "chargePerDay",
    ];

    const parseFloatValue = parseFloat(value) || 0;

    if (positiveValuesOnly.includes(name)) {
      if (parseFloatValue >= 0) {
        old[index][name] = value;
      } else {
        toastr.show("You can not add a negative number.", "error");
      }
    } else {
      if (name === "amount" && !value) value = 0
      old[index][name] = value;
    }

    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });
    if (name === "name") {
      if (chargeData && chargeData?.chargeName && chargeData?.labelName && chargeData?.labelValue) {
        old[index]["chargeName"] = chargeData?.chargeName;
        old[index]["labelName"] = chargeData?.labelName;
        old[index]["labelValue"] = chargeData?.labelValue;
      }
      this.setState({
        pricing: [...old],
        rowIndex: index,
      });
      return;
    }
    if (name === "type") {
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
          100;
      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "amount") {
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
          100;

      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "finalAmount" && value) {
      old[index]["manualFinalAmount"] = value;
    } else if (["unit", "freeDays", "chargePerDay", 'perType'].includes(name)) {
      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
      let computedAmount = (parseFloat(old[index].unit) - parseFloat(old[index].freeDays)) * parseFloat(old[index].chargePerDay);
      old[index][name] = value;
      if (old[index].name != "Fuel") {
        if (computedAmount >= parseFloat(old[index].manualFinalAmount || 0)) {
          old[index]["finalAmount"] = computedAmount.toFixed(2);
        } else {
          old[index]["finalAmount"] = parseInt(old[index].manualFinalAmount || 0).toFixed(2);
        }
      }

      if ((name == "perType" && value == "percentage")
        || (name !== "perType" && old[index]["perType"] == "percentage")) {
        if (old[index]["name"] !== 'Fuel') {
          old[index]["finalAmount"] = (this.state.base_price * old[index]["unit"] / 100).toFixed(2);

        }
      }

    }
    this.setState({
      pricing: [...old],
    });
  }

  handleSubmit = () => {
    if (!this.state.name) {
      this.setState({ nameColor: "background-error" }, () => {
        setTimeout(() => {
          this.setState({ nameColor: "background-failure" })
        }, 400);
      });
      return false;
    }
    if (this.state.selectedCell) return
    if (
      !this.state.isAPICall &&
      this.state.isEdited &&
      this.state.name) {
      let payload = {};
      payload.shipId = this.props.carrierObj?.shipId
      payload.loadId = this.props.carrierObj?.loadId
      payload.reference_number = this.props.selectedLoads?.reference_number
      payload.carrierPayId = this.props.carrierObj?._id ? this.props.carrierObj?._id : this.state.carrierPayId
      payload.drayOSCarrierId = this.props.carrierObj?.drayosCarrier?._id
      payload.pricingObj = {
        "name": this.state.name,
        "chargeName": this.state.chargeName,
        "carrierChargeCodeId": this.state.carrierChargeCodeId,
        "labelName": this.state.labelName,
        "labelValue": this.state.labelValue,
        "amount": this.state.amount,
        "chargePerDay": this.state.chargePerDay ? parseFloat(this.state.chargePerDay).toFixed(2) : 0,
        "chargePerMinute": this.state.chargePerMinute ? parseFloat(this.state.chargePerMinute).toFixed(2) : 0,
        "finalAmount": (parseFloat(this.state.finalAmount) || 0).toFixed(2),
        "unit": (parseFloat(this.state.unit) || 0).toFixed(2),
        "freeDays": this.state.freeDays ? parseFloat(this.state.freeDays).toFixed(2) : 0,  
        "freeMinutes": this.state.freeMinutes,
        "perType": this.state.perType || "fixed",
        "description": this.state.description,
        "type": this.state.type,
        "chargeType": "SYSTEM_GENERATED"

      }
      payload.tenderId = this.props.carrierObj?.tenderId?._id
      this.setState({ isAPICall: true, rowIndex: this.state.pricing?.length }, () => this.props.blockTabChange(true))

      tmsAction.addCarrierPayPricing(JSON.stringify(payload)).then((response) => {
        this.props?.handleReloadOfBillingSummary && this.props.handleReloadOfBillingSummary()
        let total = 0
        response?.pricing?.forEach(element => {
          total = total + parseFloat(element?.finalAmount);
        })
        if (!this.state.carrierPayId) this.props.getCarrierPayRequest()
        this.setState({ Total: total?.toFixed(2), isAPICall: false, isEdited: false, isSuccess: true, isFailed: "transition-white-field", carrierPayId: response?._id });
        let x_charges = 0, base_price = 0;
        response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
        response.pricing.map((x) => {
          if (x) {
            if (x.name == "Base Price") {
              base_price += parseFloat(x.finalAmount);
            } else {
              x_charges = (
                parseFloat(x_charges) + parseFloat(x.finalAmount)
              ).toFixed(2);
            }
            //x.manualFinalAmount = x.finalAmount;
          }
        });
        this.setState({ base_price, x_charges });
        toastr.show(`Your pricing has been added`, "success");
        setTimeout(() => {
          this.setState({ isSuccess: false });
        }, 400);

        const updatedCarrierPay = {...this.props, pricing: response.pricing ?? []};
        this.props.handleUpdateCarrierPays(updatedCarrierPay);

        this.setState({ pricing: response.pricing, addCharge: false, selectedCell: null, carrierPay:response }, () => {
          //this.props.getCarrierPayRequest()
          this.props.blockTabChange(false);
        });
        this.clearAddPricing();
      })
        .catch((err) => {
          toastr.show("Something went wrong! Please try again.", "error");
          this.clearAddPricing();
          this.setState({ isFailed: "background-error", isAPICall: false }, () => {
            this.props.blockTabChange(false);
            setTimeout(() => {
              this.setState({ isFailed: "background-failure" })
            }, 400);
          });
        })
    }
  }
  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) && !document.querySelector("[role='tablist']")?.contains(event.target) &&
      (document.querySelector(".tab-loads").contains(event.target) || document.querySelector(".fcpopup__left").contains(event.target))
    ) {
      this.handleSubmit()
    }
  }
  updateRowPricing = (index) => {
    this.setState({ rowIndex: index })
    let pricing = _.cloneDeep(this.state.pricing);
    let currentRow = pricing[index];
    currentRow.finalAmount = (parseFloat(currentRow.finalAmount) || 0).toFixed(2);
    currentRow.unit = (parseFloat(currentRow.unit) || 0).toFixed(2);
    currentRow.chargePerDay = currentRow.chargePerDay ? parseFloat(currentRow.chargePerDay).toFixed(2) : 0;
    currentRow.freeDays = currentRow.freeDays ? parseFloat(currentRow.freeDays).toFixed(2) : 0;
    delete currentRow.subChargeType;
    currentRow.chargeType = MANUALLY_CHANGED;
    if (!currentRow.name) {
      this.setState({ nameColor: "background-error" }, () => {
        setTimeout(() => {
          this.setState({ nameColor: "background-failure" })
        }, 400);
      });
      return false;
    }
    let payload = {};

    delete currentRow.manualFinalAmount;
    delete currentRow.isManual
    delete currentRow.chargeType
    delete currentRow.carrierRate
    payload.reference_number = this.props.selectedLoads?.reference_number
    payload.pricingObj = currentRow;
    payload.shipId = this.props.carrierObj?.shipId
    payload.loadId = this.props.carrierObj?.loadId
    payload.carrierPayId = this.props.carrierObj?._id ? this.props.carrierObj?._id : this.state.carrierPayId
    payload.drayOSCarrierId = this.props.carrierObj?.drayosCarrier?._id
    payload.moveIds = this.props.carrierObj?.moveIds
    this.setState({ isAPICall: true });
    tmsAction.updateCarrierPayPricing(payload)
      .then((response) => {
        this.props?.handleReloadOfBillingSummary && this.props?.handleReloadOfBillingSummary()
        if (response) {
          let total = 0
          response?.pricing?.forEach(element => {
            total = total + parseFloat(element?.finalAmount)
          });
          this.setState({ Total: total.toFixed(2), isSuccess: true, isAPICall: false });
          let x_charges = 0, base_price = 0;
          response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
          response.pricing.map((x) => {
            if (x) {
              if (x.name == "Base Price") {
                base_price += parseFloat(x.finalAmount);
              } else {
                x_charges = (
                  parseFloat(x_charges) + parseFloat(x.finalAmount)
                ).toFixed(2);
              }
              //x.manualFinalAmount = x.finalAmount;
            }
          });
          this.setState({ base_price, x_charges });
          toastr.show(`Your pricing has been updated!`, "success");
          setTimeout(() => {
            this.setState({ isSuccess: false });
          }, 400);

          const updatedCarrierPay = {...this.props, pricing: response.pricing ?? []};
          this.props.handleUpdateCarrierPays(updatedCarrierPay);
          
          this.setState({ pricing: response.pricing, addCharge: false, carrierPay:response }, () => {
            //this.props.getCarrierPayRequest()
            this.props.blockTabChange(false)
          });
        }
      })
      .catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({ isFailed: "background-error", isAPICall: false }, () => {
          this.props.blockTabChange(false);
          setTimeout(() => {
            this.setState({ isFailed: "background-failure" })
          }, 400);
        });
      })

  }
  keyPressOnNumberInput = (evt) => {
    var invalidChars = ["+", "e"];
    if (invalidChars.includes(evt.key)) {
        evt.preventDefault();
    }
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
};


  handleKeyDown(e) {
    if (e.keyCode === 13) {
      e?.target?.blur();
      this.setState({ selectedCell: null }, () => {
        this.handleSubmit();
      });
    }
  }
  getBadgeDetails = (status) => {
    switch (status) {
      case "PENDING":
        return { text: "Requested", colorClass: "badge-light" };
      case "ACCEPTED":
        return { text: "Confirmed", colorClass: "badge-success-light" };
      case "REJECTED":
        return { text: "Rejected", colorClass: "badge-warning-50" };
      case "DECLINED":
        return { text: "Declined", colorClass: "badge-status-pending" };
      default:
        return { text: status, colorClass: "badge-status-pending" };
    }
  }

  getbadgeColor = (status) => {
    switch (status) {
      case "PENDING":
        return "badge-status-pending "
      case "REJECTED":
        return "badge-danger-light"
      case "ACCEPTED":
        return "badge-success-light"
      default:
        return "badge-status-pending"
    }
  }

  handleRateOrAuditTab = (tab) => {
    this.setState({ rateOrAuditTab: tab });
  };

  handleSubAuditTab = (tab) => {
    this.setState({ subAuditTab: tab });
  };

  updateCarrierPayState = (updatedCarrierPay)=>{
    this.setState({carrierPay: updatedCarrierPay})
  }
  
  tenderStatusColorClass =  (status)=>{
    let colorClass;
    if(this.getBadgeDetails(status)?.text === "Confirmed" && isVendorPayAndBillsEnabled()){
      let initalStatus = "PENDING"
      colorClass = this.getBadgeDetails(initalStatus)?.colorClass;
    }else{
      colorClass = this.getBadgeDetails(status)?.colorClass
    }
    return colorClass;
  }
  
  filteredTenderHistory = (tenderHistory)=>{
    return tenderHistory?.filter(history=>history?.tenderReferenceNumberAtStatusChange===this.props.carrierObj?.tenderId?.drayOSTenderRefNo) ?? [];
  }


  handleToggleTaxType = (e) => {
    let data = {
        "carrierPayId": this.props.carrierObj?._id,
        "defaultTaxType": this.props.carrierObj.taxDetail?.defaultTaxType
    };
    if (e.target.checked) {
        this.setState({
            ...this.state,
            taxType: VATTYPES.APPLICABLE
        });
        tmsAction.changeCarrierPayDefaultTaxType({ ...data, defaultTaxType: VATTYPES.APPLICABLE }).then((res) => {
          if (res?.statusCode === 200) {
            toastr.show("Updated Successfully", "success")
            this.setState({carrierPay:res?.data})
            this.props?.handleReloadOfBillingSummary && this.props.handleReloadOfBillingSummary()
            if (res?.data?.taxDetail?.defaultTaxType === VATTYPES.APPLICABLE) {
              this.setState({ taxType: VATTYPES.APPLICABLE })
            } else {
              this.setState({ taxType: VATTYPES.EXEMPT })
            }
          }
        }).catch()

    } else {
        this.setState({
            ...this.state,
            taxType: VATTYPES.EXEMPT
        });

        tmsAction.changeCarrierPayDefaultTaxType({ ...data, defaultTaxType: VATTYPES.EXEMPT }).then((res) => {
            if (res?.statusCode === 200) {
                toastr.show("Updated Successfully", "success")
                this.setState({carrierPay:res?.data})
                this.props?.handleReloadOfBillingSummary && this.props.handleReloadOfBillingSummary()
            }
        }).catch()
    }
}

  render() {
    let isTotalAmount = 0
    let showSave = false;
    const { chargeCode, selectedLoads, carrierObj, carrierIndex } = this.props;
    let drayOSCarrierId = carrierObj?.drayosCarrier?._id || carrierObj?.drayosCarrier;
    let tenderId = carrierObj?.tenderId || null;
    let allowCarrierPayUpdate = tenderId?.status !== "ACCEPTED";
    let { pricing } = this.state
    if (selectedLoads && selectedLoads.driverOrder?.length) {
      let tenderdLeg = selectedLoads?.driverOrder?.find(leg => (leg?.drayosCarrier?._id || leg?.drayosCarrier) + "" == drayOSCarrierId + "");
      if (tenderdLeg) tenderId = tenderdLeg?.tenderId || null;
    }
    let isChargeLabel = pricing?.findIndex(d=> !d?.labelValue) !=-1 ? false : true;

    const approvalHistory = this.props?.carrierPays?.map((pays) => pays?.approvalPricingHistory);
    // const auditHistory = [...this.props?.tenderHistoryForCarrier ?? [], ..._.flattenDeep(approvalHistory ?? []) ?? []];
    const filteredTenderHistory = this.filteredTenderHistory(this.props.tenderHistory);

    const newTaxRate = this.state.carrierPay?.taxDetail?.taxRate ? this.state.carrierPay?.taxDetail?.taxRate*100 : 0
    let isVatDisabled = false;

    if (!this?.props?.carrierObj?.billFromDetail?._id) {
      isVatDisabled = true;
    }

    return (
      <>
        <div className="card card-tborder border-gray p-10 mb-15" id="tab-document">
        {!this.props?.carrierObj?.drayosCarrier && (!this.props.tenderHistory || Object.keys(this.props?.tenderHistory).length === 0) && (
            <React.Fragment>
              <h4 className="text-center mt-2 text-muted">No Data Found</h4>
            </React.Fragment>
        )}
        {this.props?.carrierObj?.drayosCarrier && (
          <React.Fragment>
              <div className='d-flex justify-content-between mb-10 flex-wrap gap-5'>
                <div className='d-flex align-items-center gap-10 flex-wrap'>
                  {(this.state?.carrierPay?.loadPricingApprovalStatus === "BILLING" || this.props?.carrierObj?.loadPricingApprovalStatus === "PENDING") && (
                    
                    <span className={`text-capitalize text-white badge badge-sm badge-status-pending`}>REQUESTED</span>
                  )}
                  {this.state.carrierPay?.approvalStatus && (
                    <span class={`text-capitalize badge badge-sm ${this.getbadgeColor(this.state?.carrierPay?.approvalStatus)}`}>
                      {this.state?.carrierPay?.approvalStatus}
                    </span>
                  )}
                  {!(
                    this.props?.carrierObj?.loadPricingApprovalStatus === "BILLING" ||
                    this.props?.carrierObj?.loadPricingApprovalStatus === "PENDING"
                  ) &&
                    !this.state?.carrierPay?.approvalStatus &&
                    this.state.carrierPay?.tenderId?.status && (
                      <div className="d-flex flex-column gap-2">
                        <span
                          className={`text-capitalize badge badge-sm ${
                            this.tenderStatusColorClass(tenderId?.status)
                          }`}
                        >
                          {this.getBadgeDetails(tenderId?.status)?.text === "Confirmed" && isVendorPayAndBillsEnabled()
                            ? "Drafted"
                            : this.getBadgeDetails(tenderId?.status)?.text}
                        </span>
                      </div>
                    )}
                  <span className='text-muted'>Bill To</span>
                  <Select
                    className='w-150'
                    styles={smallSelectStyle}
                    value={this.props?.carrierObj?.drayosCarrier?.company_name
                      ? {
                        label: this.props?.carrierObj.drayosCarrier?.company_name,
                      }
                      : null
                    }
                  />
                  {
                    this.props?.carrierObj?.billNumber && (
                      <div class="d-flex flex-column">
                        <span class="text-muted">Bill # </span>
                        <span class="font-medium">{this.props?.carrierObj?.billNumber ?? '-'}</span>
                      </div>
                    )
                  }

                  <ul className="nav nav-compact gap-5" role="tablist">
                    {
                      chargeSetTabs?.map((d, i) => (
                        <li className="nav-item" key={i.toString()}>
                          <a
                            href="#!"
                            className={`nav-link ${this.state.rateOrAuditTab === d?.value ? 'active' : ""}`}
                            onClick={() => this.handleRateOrAuditTab(d?.value)}
                          >{d?.label}</a>
                        </li>
                      ))
                    }
                  </ul>

                  {
                    this.state.rateOrAuditTab === CHARGE_SET_TAB_LABELS.AUDIT && (
                      <ul className="nav nav-compact gap-5" role="tablist">
                        {
                          auditSubTabs?.map((d, i) => (
                            <li className="nav-item" key={i.toString()}>
                              <a
                                href="#!"
                                className={`nav-link ${this.state.subAuditTab === d?.value ? 'active' : ""}`}
                                onClick={() => this.handleSubAuditTab(d?.value)}
                              >{d?.label}</a>
                            </li>
                          ))
                        }
                      </ul>
                    )
                  }

                </div>
              </div>
              <>
                {
                  this.state.rateOrAuditTab === CHARGE_SET_TAB_LABELS.CHARGE_RATE && (
                    <>
                      {Array.isArray(filteredTenderHistory) && (
                        filteredTenderHistory.map((tender, index) => {

                          return (
                            <>
                              {(tender?.status === "REJECTED") && (
                                <div className="toast-container-relative">
                                  <div key={index} className="toast toast-warning mw-100 w-100 mb-10">
                                    <div className="toast-message">
                                      {tender?.rejectionReason}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {tender?.status === "DECLINED" && (
                                <div className="toast-container-relative">
                                  <div key={index} className="toast toast-warning mw-100 w-100 mb-10">
                                    <div className="toast-message">
                                      {tender?.rejectionReason}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      )}
                        {this.carrierPricing.length === 0 && (
                      <div className="p-1 bg-gray-50 rounded-5">
                          <div id="bills" className="table">
                            <div className="position-relative">
                              <table className="table table-card table-card--wrap mb-1 table-billing">
                                {this.state.isAPICall && this.state.addCharge &&
                                  <div className="overly-lays d-flex justify-content-center align-items-center text-white b-40 mb-1" >
                                    <span
                                      class="spinner-border spinner-border-sm mr-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Processing...
                                  </div>
                                }
                                <thead>
                                  <tr>
                                    <th width="">Name of Charge #</th>
                                    {isChargeLabel && <th width="">Charge Code Label</th>}
                                    <th width="">Description</th>
                                    <th width="" className="text-right">Price {this.state.carrierPay?.billFromDetail?.currency?.currencyCode ? `(${this.state.carrierPay?.billFromDetail?.currency?.currencyCode})` : ""}</th>
                                    <th width="" className="z-3">
                                      Unit Count
                                    </th>
                                    <th width="" className="z-1">
                                      Unit of Measure
                                    </th>
                                    <th width="" className="text-right">Free Units</th>
                                    <th width="" className="text-right">Per Units</th>
                                    <th width="42"><span className='d-inline-block mr-1'>Auto</span></th>
                                    <th width="42"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {

                                    pricing &&
                                    pricing?.length > 0 &&
                                    pricing?.map((item, key) => {
                                      //isTotalAmount = parseFloat(item["finalAmount"]) + isTotalAmount
                                      let chargeValue = this.chargeCodeList && this.chargeCodeList.find((d) => d.value === item.name)
                                      let charges = this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive);
                                      if (this.isNewChargeCodeEnabled) {
                                          charges = this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive && d?.chargeName && d?.labelName && d?.labelValue)
                                      }
                                      return (
                                        <React.Fragment>
                                          <CarrierPayRow
                                            billFromCurrency={this.state.billFromCurrency}
                                            keyPressOnNumberInput={this.keyPressOnNumberInput}
                                            isTenderId={!allowCarrierPayUpdate}
                                            pricing={this.state.pricing}
                                            index={key}
                                            key={key}
                                            item={item}
                                            charges={charges}
                                            chargeValue={chargeValue}
                                            pricingchanges={this.pricingchanges}
                                            newRowChanges={this.newRowChanges}
                                            chargeCode={chargeCode}
                                            showSave={showSave}
                                            addCharge={this.state.addCharge}
                                            DeletePriceRow={this.DeletePriceRow}
                                            isAPICall={this.state.isAPICall}
                                            isSuccess={this.state.isSuccess}
                                            rowIndex={this.state.rowIndex}
                                            setWrapperRef={this.setWrapperRef}
                                            selectedCell={this.state.selectedCell}
                                            selectCell={this.selectCell}
                                            unselectCell={this.unselectCell}
                                            updateRowPricing={this.updateRowPricing}
                                            isChargeLabel={isChargeLabel}
                                            carrierIndex={carrierIndex}
                                          />

                                        </React.Fragment>
                                      )
                                    })}

                                  {this.state.addCharge && allowCarrierPayUpdate &&
                                    <tr className={`select-custom-input newdriverrow shadow-md ${this.state.isFailed}`}
                                      onKeyDown={(e) => this.handleKeyDown(e)}
                                      ref={this.setWrapperRef}
                                    >
                                      <td className={`${this.state.nameColor} carrier-new-chargecell-${carrierIndex} text-dark ${!this.state.name ? 'requied_hover' : ""} 
                                ${this.state.selectedCell === "add-Charge" ? 'newdriverrow td-clicked' : ""}
                                pl-2 pr-1`}
                                        style={{ transition: 'background .4s ease-out' }}
                                        onClick={() => this.selectCell("add-Charge")}
                                      >
                                        <div className="select-custom-input">
                                          <div className="d-flex justify-content-between align-items-center text-dark">
                                            {this.state?.chargeName ? this.state.chargeName?.toUpperCase() : this.state.name?.toUpperCase() || <span className="text-muted">Select Charge</span>}
                                            <IconAngleArrowDown className='' />
                                            {this.state.selectedCell === "add-Charge" &&
                                              <SelectCustomComponent
                                                name='charges'
                                                searchable={true}
                                                refClassName={`carrier-new-chargecell-${carrierIndex}`}
                                                listData={
                                                  (this.isNewChargeCodeEnabled)
                                                      ? this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive && d?.chargeName && d?.labelName && d?.labelValue)
                                                      : this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive)
                                              }
                                                keyName="chargeName"
                                                onSelect={this.onAddSelectCharge}
                                                unselectCell={this.unselectCell}
                                              />
                                            }
                                          </div>
                                        </div>
                                      </td>
                                      <td><span className="badge badge-sm text-white badge-status-navy-1"></span></td>
                                      <td className={`px-0 ${this.state.selectedCell === "description" ? 'newdriverrow td-clicked' : ""}`}
                                        onClick={() => this.selectCell("description")}
                                      >
                                        <div className="select-custom-input">
                                          <input
                                            ref={(i) => { this.pricingDescription = i; }}
                                            type="text"
                                            className={`form-control border-0 bg-transparent`}
                                            name="description"
                                            value={this.state.description || ""}
                                            onChange={(e) =>
                                              this.setState({ description: e.target.value, isEdited: true })
                                            }
                                            placeholder="Add description..."
                                            autoComplete="off"
                                            onBlur={() => this.unselectCell()}
                                          />
                                        </div>
                                      </td>
                                      {this.state.name === "Fuel" ? (
                                        <td
                                          className={`px-0  w-200 cell-7 ${["amountCell", "finalAmountCell", "typeCell"].includes(this.state.selectedCell) && 'newdriverrow td-clicked'}`}
                                        // onClick={() => this.selectCell("finalAmount")}
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                           <div className="mr-1 d-flex w-100" onClick={() => this.selectCell("amountCell")}>
                                              {!isGlobalizationEnabled() && <input
                                                  className="form-control border-0  bg-transparent text-right"
                                                  type="number"
                                                  name="amount"
                                                  min={0}
                                                  value={this.state.amount || ""}
                                                  onChange={(e) => {
                                                    this.setState({ amount: e.target.value, isEdited: true }, () => {
                                                      this.newRowChanges({
                                                        target: {
                                                          value: e.target.value,
                                                          name: "amount",
                                                        },
                                                      });
                                                    });
                                                  }}
                                                  autoFocus={["amountCell"].includes(this.state.selectedCell)}
                                                  autoComplete="off"
                                                  onBlur={() => this.unselectCell()}
                                                />}
                                                {isGlobalizationEnabled() && (
                                                  <NumberFormatterV2
                                                    selected={["amountCell", "finalAmountCell", "typeCell"].includes(this.state.selectedCell)}
                                                    className="form-control border-0 text-right"
                                                    type="number"
                                                    name="amount"
                                                    min={0}
                                                    value={this.state.amount || "0"}
                                                    onChange={(e) => {
                                                      this.setState({ amount: e.target.value, isEdited: true }, () => {
                                                        this.newRowChanges({
                                                          target: {
                                                            value: e.target.value,
                                                            name: "amount",
                                                          },
                                                        });
                                                      });
                                                    }}
                                                    autoComplete="off"
                                                    onBlur={() => this.unselectCell()}
                                                    childClassName="form-control border-0 text-right"
                                                    autoFocus={false}
                                                    showCurrency={false}
                                                    disablePlaceholder={true}
                                                  />
                                                )}
                                              </div>
                                              {/* <div className="mr-1 d-flex w-100 select-custom-input price-component">
                                                <CustomSelect
                                                  size="small"
                                                  placement="right"
                                                  placeholder="Select..."
                                                  style={newSmallSelectStyle}
                                                  options={[
                                                    {
                                                      value: "fixed",
                                                      label: "$",
                                                    },
                                                    {
                                                      value: "percentage",
                                                      label: "%",
                                                    },
                                                  ]}
                                                  value={
                                                    this.state.type && this.state.type === "fixed"
                                                      ? {
                                                        value: "fixed",
                                                        label: "$",
                                                      }
                                                      : {
                                                        value: "percentage",
                                                        label: "%",
                                                      }
                                                  }
                                                  onChange={({ value }) => {
                                                    this.setState({ type: value, isEdited: true }, () => {
                                                      this.newRowChanges({
                                                        target: {
                                                          value: value,
                                                          name: "type",
                                                        },
                                                      });
                                                    })
                                                    this.unselectCell()
                                                  }}
                                                  onBlur={() => this.unselectCell()}
                                                />
                                              </div> */}
                                            <div className="mr-1 d-flex">
                                              <div className="d-flex justify-content-between align-items-center min-width-50 pointer bg-gray-50 p-1 rounded-3"
                                                onClick={() => this.selectCell("typeCell")}
                                              >
                                                <div>
                                                  {this.state.type  
                                                  === "percentage" ? "%" : (this.state.billFromCurrency?.symbol ?? Currency.default()?.symbol ?? '$')}
                                                </div>

                                                <div className="ml-1"><IconAngleArrowDown /></div>
                                                {this.state.selectedCell === "typeCell" &&
                                                  <SelectCustomCurrency
                                                    name='value'
                                                    searchable={false}
                                                    refClassName="cell-7"
                                                    listData={[
                                                      {
                                                        value: "fixed",
                                                        label: `${this.state.billFromCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                                      },
                                                      {
                                                        value: "percentage",
                                                        label: "%",
                                                      },
                                                    ]}
                                                    keyName="label"
                                                    onSelect={(name, value) => {
                                                      this.setState({ type: value.value, perType:value?.value, isEdited: true }, () => {
                                                        this.newRowChanges({
                                                          target: {
                                                            value: value,
                                                            name: "type",
                                                          },
                                                        });
                                                      })
                                                      this.unselectCell()
                                                    }}

                                                    unselectCell={this.unselectCell}
                                                  />}
                                                </div>
                                              </div>
                                              <div className="d-flex w-100" onClick={() => this.selectCell("finalAmountCell")}>
                                              {!isGlobalizationEnabled() && <input
                                                  min={0}
                                                  placeholder="0.00"
                                                  onKeyDown={(e) => {
                                                    this.keyPressOnNumberInput(e);
                                                  }}
                                                  className="form-control border-0 bg-transparent text-right"
                                                  type="number"
                                                  name="finalAmount"
                                                  value={this.state.finalAmount || ""}
                                                  onChange={(e) => {
                                                    this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                      this.newRowChanges({
                                                        target: {
                                                          value: e.target.value,
                                                          name: "finalAmount",
                                                        },
                                                      });
                                                    })
                                                  }}
                                                  autoComplete="off"
                                                  onBlur={() => this.unselectCell()}
                                                />}
                                              {isGlobalizationEnabled() && (
                                                <NumberFormatterV2
                                                  selected={["amountCell", "finalAmountCell", "typeCell"].includes(this.state.selectedCell)}
                                                  // min={0}
                                                  placeholder="0.00"
                                                  onKeyDown={(e) => {
                                                    this.keyPressOnNumberInput(e);
                                                  }}
                                                  className="form-control border-0 text-right"
                                                  type="number"
                                                  name="finalAmount"
                                                  value={this.state.finalAmount || "0"}
                                                  onChange={(e) => {
                                                    this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                      this.newRowChanges({
                                                        target: {
                                                          value: e.target.value,
                                                          name: "finalAmount",
                                                        },
                                                      });
                                                    })
                                                  }}
                                                  autoComplete="off"
                                                  onBlur={() => this.unselectCell()}
                                                  showCurrency={false}
                                                  autoFocus={false}
                                                  childClassName="form-control border-0 text-right"
                                                  disablePlaceholder={true}
                                                />
                                              )}
                                              </div>
                                            </div>
                                        </td>
                                      ) : (
                                        <td className={`px-0 ${this.state.selectedCell === "finalAmount" ? 'newdriverrow td-clicked' : ""}`}
                                          onClick={() => this.selectCell("finalAmount")}
                                        >
                                          <div className="select-custom-input">
                                            <span data-tip data-for={`discount${this.state.pricing.length}`}>
                                            {!isGlobalizationEnabled() && (<input
                                                min={0}
                                                placeholder="0.00"
                                                type="number"
                                                onKeyDown={(e) => {
                                                  this.keyPressOnNumberInput(e);
                                                }}
                                                className={`form-control border-0 bg-transparent text-right`}
                                                name="finalAmount"
                                                value={this.state.finalAmount || ""}
                                                onChange={(e) => {
                                                  this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                    this.newRowChanges({
                                                      target: {
                                                        value: e.target.value,
                                                        name: "finalAmount",
                                                      },
                                                    });
                                                  })
                                                }}
                                                autoComplete="off"
                                                onBlur={() => this.unselectCell()}
                                              />)}
                                                {isGlobalizationEnabled() && (
                                                  <NumberFormatterV2
                                                    selected={["finalAmount"].includes(this.state.selectedCell)}
                                                    // min={0}
                                                    placeholder="0.00"
                                                    type="number"
                                                    className={`form-control border-0 text-right`}
                                                    onKeyDown={(e) => {
                                                      this.keyPressOnNumberInput(e);
                                                    }}
                                                    name="finalAmount"
                                                    value={this.state.finalAmount || "0"}
                                                    onChange={(e) => {
                                                      this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                        this.newRowChanges({
                                                          target: {
                                                            value: e.target.value,
                                                            name: "finalAmount",
                                                          },
                                                        });
                                                      })
                                                    }}
                                                    autoComplete="off"
                                                    onBlur={() => this.unselectCell()}
                                                    showCurrency={false}
                                                    childClassName={`form-control border-0 text-right`}
                                                    autoFocus={["finalAmount"].includes(this.state.selectedCell)}
                                                    disablePlaceholder={true}
                                                  />
                                                )}
                                            </span>
                                            {this.state.discountApplied &&
                                              <ReactTooltip effect="solid" id={`discount${this.state.pricing?.length}`}>
                                                <span>A discount has been applied</span>
                                              </ReactTooltip>
                                            }
                                          </div>
                                        </td>
                                      )}
                                      <td
                                        className={`px-0 ${this.state.selectedCell === "unit" && 'newdriverrow td-clicked'}`}
                                        onClick={() => this.selectCell("unit")}
                                      >
                                        <div className="select-custom-input">
                                          <div>
                                            <NewPriceInput
                                              name="unit"
                                              value={this.state.unit}
                                              onChange={(val) => {
                                                this.setState({ unit: val, isEdited: true }, () => {
                                                  this.newRowChanges({
                                                    target: {
                                                      value: val,
                                                      name: "unit",
                                                    },
                                                  });
                                                })
                                              }}
                                              onBlur={() => this.unselectCell()}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td className={`px-1 pl-2 carrier-new-pertypecell-${carrierIndex} ${this.state.selectedCell === "perType" ? 'newdriverrow td-clicked' : ""}`}
                                        onClick={() => this.selectCell("perType")}
                                      >
                                        <div className="select-custom-input">
                                          <div className="d-flex justify-content-between align-items-center">
                                            {this.state.perType || <span className="text-muted">Select...</span>}
                                            <IconAngleArrowDown className='' />
                                            {this.state.selectedCell === "perType" &&
                                              <SelectCustomComponent
                                                name='perType'
                                                searchable={true}
                                                refClassName={`carrier-new-pertypecell-${carrierIndex}`}
                                                listData={options}
                                                keyName="label"
                                                onSelect={(name, value) =>
                                                  this.setState(prevState => (
                                                    { perType: value.value,  
                                                      type: prevState.name === "Fuel" ? (value.value === "percentage" ? "percentage" : "fixed") : prevState.type,
                                                      isEdited: true, 
                                                      selectedCell: null 
                                                    }), () => {
                                                    this.newRowChanges({
                                                      target: {
                                                        value: value.value,
                                                        name: "perType",
                                                      },
                                                    });
                                                    if (this.pricingFreeUnit) this.pricingFreeUnit.focus();
                                                  })
                                                }
                                                unselectCell={this.unselectCell}
                                              />}
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell === "freeDays" && 'newdriverrow td-clicked'}`}
                                        onClick={() => {
                                          if (!this.state.perType) return
                                          this.selectCell("freeDays")
                                        }}
                                      >
                                        <div className="select-custom-input">
                                          {!isGlobalizationEnabled() && <input
                                            ref={(i) => { this.pricingFreeUnit = i; }}
                                            min={0}
                                            type="number"
                                            className={`form-control border-0  bg-transparent text-right`}
                                            name="freeDays"
                                            onKeyDown={(e) => {
                                              this.keyPressOnNumberInput(e);
                                            }}
                                            disabled={!this.state.perType || this.state.perType === "percentage"}
                                            value={this.state.freeDays || ""}
                                            onChange={(e) => {
                                              this.state.perType &&
                                                this.setState({ freeDays: e.target.value, isEdited: true }, () => {
                                                  this.newRowChanges({
                                                    target: {
                                                      value: e.target.value,
                                                      name: "freeDays",
                                                    },
                                                  });
                                                })
                                            }}
                                            placeholder="0"
                                            autoComplete="off"
                                            onBlur={() => this.unselectCell()}
                                          />}
                                          {isGlobalizationEnabled() && (
                                            <NumberFormatterV2
                                              selected={this.state.selectedCell === "freeDays"}
                                              ref={(i) => { this.pricingFreeUnit = i; }}
                                              min={0}
                                              type="number"
                                              className={`form-control border-0 text-right`}
                                              name="freeDays"
                                              onKeyDown={(e) => {
                                                this.keyPressOnNumberInput(e);
                                              }}
                                              disabled={!this.state.perType || this.state.perType === "percentage"}
                                              value={this.state.freeDays || "0"}
                                              onChange={(e) => {
                                                this.state.perType &&
                                                  this.setState({ freeDays: e.target.value, isEdited: true }, () => {
                                                    this.newRowChanges({
                                                      target: {
                                                        value: e.target.value,
                                                        name: "freeDays",
                                                      },
                                                    });
                                                  })
                                              }}
                                              placeholder="0.0"
                                              autoComplete="off"
                                              onBlur={() => this.unselectCell()}
                                              showCurrency={false}
                                              childClassName={`form-control border-0 text-right`}
                                            />
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell === "chargePerDay" && 'newdriverrow td-clicked'}`}
                                        onClick={() => {
                                          if (!this.state.perType) return
                                          this.selectCell("chargePerDay")
                                        }}
                                      >
                                        <div className="select-custom-input">
                                        {!isGlobalizationEnabled() && <input
                                            min={0}
                                            type="number"
                                            className={`form-control border-0 bg-transparent text-right`}
                                            name="chargePerDay"
                                            disabled={!this.state.perType || this.state.perType === "percentage"}
                                            value={this.state.chargePerDay || ""}
                                            onChange={(e) => {
                                              this.state.perType &&
                                                this.setState({ chargePerDay: e.target.value, isEdited: true }, () => {
                                                  this.newRowChanges({
                                                    target: {
                                                      value: e.target.value,
                                                      name: "chargePerDay",
                                                    },
                                                  });
                                                })
                                            }}
                                            placeholder="0"
                                            autoComplete="off"
                                            onBlur={() => this.unselectCell()}
                                          />}
                                          {isGlobalizationEnabled() && (
                                            <NumberFormatterV2
                                              selected={this.state.selectedCell === "chargePerDay"}
                                              min={0}
                                              type="number"
                                              className={`form-control border-0 text-right`}
                                              childClassName={`form-control border-0 text-right`}
                                              name="chargePerDay"
                                              disabled={!this.state.perType || this.state.perType === "percentage"}
                                              value={this.state.chargePerDay || "0"}
                                              onChange={(e) => {
                                                this.state.perType &&
                                                  this.setState({ chargePerDay: e.target.value, isEdited: true }, () => {
                                                    this.newRowChanges({
                                                      target: {
                                                        value: e.target.value,
                                                        name: "chargePerDay",
                                                      },
                                                    });
                                                  })
                                              }}
                                              placeholder="0"
                                              autoComplete="off"
                                              onBlur={() => this.unselectCell()}
                                              showCurrency={false}
                                            />
                                          )}
                                        </div>
                                      </td>
                                      <td className="px-1">
                                      </td>
                                      {this.state.addCharge && (
                                        <td>
                                          <button
                                            className="btn btn-xs btn-circle bg-soft-danger"

                                            onClick={() => {
                                              this.setState({
                                                addCharge: false,
                                                nameColor: "transition-white-field"
                                              }, () => {
                                                this.clearAddPricing();
                                                this.props.blockTabChange(false)
                                              })

                                            }}
                                          >
                                            <IconMinus className="text-danger" />
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  }
                                </tbody>
                              </table>
                            </div>
                            <div className='d-flex align-items-start mt-1'>
                              <button
                                disabled={!allowCarrierPayUpdate || !isChargeLabel}
                                hidden={!allowCarrierPayUpdate || !isChargeLabel ? true : false}
                                className="btn btn-white btn-sm text-primary">
                                <a
                                  className="text-primary font-weight-500"
                                  //disabled={this.props.isTenderId}
                                  onClick={() => {
                                    if (!allowCarrierPayUpdate || !isChargeLabel) return
                                    this.setState({ addCharge: true }, () => {
                                      this.props.blockTabChange(true);
                                      this.selectCell("add-Charge")
                                    })
                                  }}
                                >
                                  <IconPlus className="mr-2" />
                                  Add Charge
                                </a>
                              </button>
                              <div className="d-flex flex-wrap gap-5 mb-1 ml-auto">
                                <div className="rbox rbox--white text-right d-flex align-items-center gap-10">
                                  <div className="flex-grow-1">
                                    <div className="rbox__title-mb">Base</div>
                                    <div className="font-medium">{(this.state.base_price || 0).toCurrency(this.state.billFromCurrency)}</div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="rbox__title-mb">Accessorials</div>
                                    <div className="font-medium">{(this.state.x_charges || 0).toCurrency(this.state.billFromCurrency)}</div>
                                  </div>
                                  <div className="vr-line mx-2"></div>
                                  <div className="flex-grow-1">
                                    <div className="rbox__title-mb">Total Charges</div>
                                    <div className="font-medium">{(this.state.Total || 0).toCurrency(this.state.billFromCurrency)}</div>
                                  </div>
                                </div>
                                {checkIsTaxable() && this?.state?.carrierPay?.taxDetail?.taxType && <div className="rbox rbox--white d-flex align-items-center justify-content-between text-right">
                                  {checkIsVAT() ? <div className="form-check form-switch form-switch-md">
                                    <input type="checkbox"
                                       disabled={isVatDisabled} 
                                       class={`form-check-input ${isVatDisabled  ? "opacity-05 pointer-not-allowed" : ""}`} 
                                       id="vat-enabled-billing" 
                                      onChange={this.handleToggleTaxType}
                                      checked={this?.state?.taxType === VATTYPES.APPLICABLE ? true : false} />
                                  </div> : <div></div>}
                                  <div>
                                    <div className='d-flex align-items-center gap-2'>
                                      <span className="text-muted">{this?.state?.carrierPay?.taxDetail?.taxType} @ </span>
                                      <span>{newTaxRate || 0}%</span>
                                      {this.state.carrierPay?.taxDetail?.taxNote && <>
                                        <div data-tip data-for={`taxNote-${carrierIndex}`}>
                                          <IconInfoCircleFilledWhite />
                                        </div>
                                        <ReactTooltip id={`taxNote-${carrierIndex}`} place="top" effect="solid" className="react-tooltip-new">
                                          {gettaxNote(this.state.carrierPay)}
                                        </ReactTooltip>
                                      </>}
                                    </div>
                                    {(this?.state?.carrierPay?.taxDetail?.defaultTaxType === VATTYPES.APPLICABLE && checkIsVAT()) ? <div className="font-medium text-dark font-12">{(this.state?.carrierPay?.taxAmount || 0).toCurrency(this.state.billFromCurrency)}</div> : <div className='text-muted font-weight-500 font-12'>{VATTYPES.EXEMPT}</div>}
                                  </div>
                                </div>}
                                <div className="rbox rbox--primary-100 text-right rbox--vertically-centered">
                                  <div className="text-gray-800 rbox__title-mb">Billing Total</div>
                                  <div className="font-medium">{(this.state.carrierPay?.totalAmountWithTax || 0).toCurrency(this.state.billFromCurrency)}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                        )}
                      <div className='mt-2'>
                        {this.props?.carrierObj?.drayosCarrier && this.state?.pricing &&
                          <CarrierPayPricingApprovalC
                            totalBillAmount={parseFloat(this.state.Total ?? 0)}
                            carrierObj={this.props.carrierObj}
                            handleReloadOfBillingSummary={this.props.handleReloadOfBillingSummary}
                            tenderId={tenderId}
                            tenderHistory={this.props.tenderHistory}
                            getbadgeColor={this.getbadgeColor}
                            isTotalAmount={this.state.Total ? this.state.Total : 0.0}
                            selectedLoads={selectedLoads}
                            updateCarrierPayState={this.updateCarrierPayState}
                            setDefaultVendorChargeSets={this.props.setDefaultVendorChargeSets}
                            handleUpdateCarrierPays={this.props.handleUpdateCarrierPays}
                          ></CarrierPayPricingApprovalC>
                        }
                      </div>
                    </>
                  )
                }
                {
                  this.state.rateOrAuditTab === CHARGE_SET_TAB_LABELS.AUDIT && this.state.subAuditTab === CHARGE_SET_TAB_LABELS.TENDER_STATUS && (
                    <div className='mt-2'>
                      {this.props.tenderHistory && Object.keys(this.props.tenderHistory).length > 0 && (
                        <div className="p-1 bg-gray-50 mt-10 rounded-5">
                          <TenderHistorySection
                            tenderId={tenderId}
                            tenderHistory={filteredTenderHistory}
                            getbadgeColor={this.getbadgeColor}
                          />
                        </div>
                      )}
                    </div>
                  )
                }

                {
                  this.state.rateOrAuditTab === CHARGE_SET_TAB_LABELS.AUDIT && this.state.subAuditTab === CHARGE_SET_TAB_LABELS.CHARGE_SET_STATUS && (
                    <div className='mt-2'>
                      {this.props.carrierObj?.approvalPricingHistory?.length > 0 && (
                        <InvoiceApprovalHistory
                          carrierPays={this.props.carrierObj}
                          setCarrierPays={this.updateCarrierPayState}
                        ></InvoiceApprovalHistory>
                      )}
                    </div>
                  )
                }
              </>
          </React.Fragment>
        )}
        </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierPayTable);

const options = [
  { value: "perday", label: "perday" },
  { value: "perhour", label: "perhour" },
  { value: "permile", label: `per${convertUnit().toLowerCase()}` },
  {
    value: "perpound",
    label: `per${convertUnit("convertedUnit", "weight").toLowerCase()}`,
  },
  { value: "fixed", label: "fixed" },
  { value: "percentage", label: "percentage" },
];

const CHARGE_TYPES = {
  CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES' },
  DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING' },
  SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED' },
  MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED' },
  PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES' },
  ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES' },
  CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES' },
};