import { getStorage } from "services";
import { isManageFleetOrOutSource } from "../../../../services";

export const defaultConnectConfig = {
    isConnectConfigEnabled: false, // Indicates whether the Connect configuration is enabled
    syncConnectFields: {
        loadInfos: {
            loadRoutingLocation: { autoAccept: false, acceptIfBlank: false },
            loadRoutingApptTimes: { autoAccept: false, acceptIfBlank: false }
        },
        loadDates: {
            vesselETA: { autoAccept: false, acceptIfBlank: false },
            lastFreeDay: { autoAccept: false, acceptIfBlank: false },
            dischargedDate: { autoAccept: false, acceptIfBlank: false },
            outgateDate: { autoAccept: false, acceptIfBlank: false },
            emptyDay: { autoAccept: false, acceptIfBlank: false },
            freeReturnDate: { autoAccept: false, acceptIfBlank: false },
            ingateDate: { autoAccept: false, acceptIfBlank: false },
            readyToReturnDate: { autoAccept: false, acceptIfBlank: false },
            loadTime: { autoAccept: false, acceptIfBlank: false },
            containerAvailableDay: { autoAccept: false, acceptIfBlank: false },
            cutOffDate: { autoAccept: false, acceptIfBlank: false },
            return: { autoAccept: false, acceptIfBlank: false },
        },
        equipment: {
            containerNo: { autoAccept: false, acceptIfBlank: false },
            containerSize: { autoAccept: false, acceptIfBlank: false },
            containerType: { autoAccept: false, acceptIfBlank: false },
            containerOwner: { autoAccept: false, acceptIfBlank: false },
            chassisNo: { autoAccept: false, acceptIfBlank: false },
            chassisSize: { autoAccept: false, acceptIfBlank: false },
            chassisType: { autoAccept: false, acceptIfBlank: false },
            chassisOwner: { autoAccept: false, acceptIfBlank: false },
            genset: { autoAccept: false, acceptIfBlank: false },
            temperature: { autoAccept: false, acceptIfBlank: false },
            routeType: { autoAccept: false, acceptIfBlank: false },
            scac: { autoAccept: false, acceptIfBlank: false },
            tareWeight: { autoAccept: false, acceptIfBlank: false },
            hazmat: { autoAccept: false, acceptIfBlank: false },
            overWeight: { autoAccept: false, acceptIfBlank: false },
            overHeight: { autoAccept: false, acceptIfBlank: false },
            liquor: { autoAccept: false, acceptIfBlank: false },
            isHot: { autoAccept: false, acceptIfBlank: false },// same for 1 key hot
            // hot: { autoAccept: false, acceptIfBlank: false }, manage bith for hot checkbox
            isGenset: { autoAccept: false, acceptIfBlank: false },
            isStreetTurn: {autoAccept: false, acceptIfBlank: false },
            trailer: { autoAccept: false, acceptIfBlank: false }, //road
            trailerType: { autoAccept: false, acceptIfBlank: false }, //road
            trailerSize: { autoAccept: false, acceptIfBlank: false },
            grayContainerNo: { autoAccept: false, acceptIfBlank: false },
            grayContainerSize: { autoAccept: false, acceptIfBlank: false },
            grayContainerType: { autoAccept: false, acceptIfBlank: false },
            grayContainerOwner: { autoAccept: false, acceptIfBlank: false },
            grayChassisNo: { autoAccept: false, acceptIfBlank: false },
            grayChassisSize: { autoAccept: false, acceptIfBlank: false },
            grayChassisType: { autoAccept: false, acceptIfBlank: false },
            grayChassisOwner: { autoAccept: false, acceptIfBlank: false },
        },
        referenceNumbers: {
            callerbillLandingNo: { autoAccept: false, acceptIfBlank: false }, // master bill of lading
            doNo: { autoAccept: false, acceptIfBlank: false }, // house bill of lading
            sealNo: { autoAccept: false, acceptIfBlank: false }, // seal number
            secondaryReferenceNo: { autoAccept: false, acceptIfBlank: false }, // ref #
            deliveryOrderNo: { autoAccept: false, acceptIfBlank: false }, // vessel name,
            bookingNo: { autoAccept: false, acceptIfBlank: false }, // booking no
            releaseNo: { autoAccept: false, acceptIfBlank: false }, // voyage import, export , bill only
            releaseNo: { autoAccept: false, acceptIfBlank: false }, // order # road only load 
            purchaseOrderNo: { autoAccept: false, acceptIfBlank: false },//purchase OrderNo
            shipmentNo: { autoAccept: false, acceptIfBlank: false }, // shipment number
            callerPONo: { autoAccept: false, acceptIfBlank: false }, //pickup number
            appointmentNo: { autoAccept: false, acceptIfBlank: false }, // appointment number
            returnNo: { autoAccept: false, acceptIfBlank: false }, // return no
            reservationNo: { autoAccept: false, acceptIfBlank: false }, // reservation number
        },
        freight: {
            freight: { autoAccept: false, acceptIfBlank: false }
        },
        loadHolds: {
            freight: { autoAccept: false, acceptIfBlank: false },
            custom: { autoAccept: false, acceptIfBlank: false },
            carrierHold: { autoAccept: false, acceptIfBlank: false },
            brokerHold: { autoAccept: false, acceptIfBlank: false },
        }
    }
};

export const connectConfigNameObj = {
    loadInfos: "Load Info",
    loadDates: "Dates",
    isLive: "Is Live",
    equipment: "Equipment",
    referenceNumbers: "Reference #",
    freight: "Freight",
    loadHolds: "Holds",
    loadRoutingLocation: "Load Info Locations",
    loadRoutingApptTimes: "Appt Times",
    vesselETA: "Vessel ETA",
    lastFreeDay: "Last Free Day",
    dischargedDate: "Discharged Date (import)",
    outgateDate: "Outgate Date",
    emptyDay: "Empty Date (import)",
    freeReturnDate: "Return Day (import)",
    ingateDate: "Ingate Date",
    readyToReturnDate: "Ready To Return Date",
    loadTime: "Load (export)",
    containerAvailableDay: "ERD (export)",
    cutOff: "Cutoff (export)",
    return: "Return (export)",
    containerNo: "Container #",
    containerSize: "Size",
    containerType: "Type",
    containerOwner: "Owner",
    chassisNo: "Chassis #",
    chassisSize: "Size",
    chassisType: "Type",
    chassisOwner: "Owner",
    genset: "Genset #",
    temperature: "Temperature",
    routeType: "Route",
    scac: "SCAC",
    tareWeight: "Tare Weight",
    hazmat: "Hazmat",
    overWeight: "Overweight",
    overHeight: "Overheight",
    liquor: "Liquor",
    isHot: "Hot",
    isGenset: "Genset",
    isStreetTurn: "Street Turn",
    trailer: "Trailer (road)",
    trailerType: "Type (road)",
    trailerSize: "Size (road)",
    grayContainerNo: "Gray Container #",
    grayContainerSize: "Size",
    grayContainerType: "Type",
    grayContainerOwner: "Owner",
    grayChassisNo: "Gray Chassis #",
    grayChassisSize: "Size",
    grayChassisType: "Type",
    grayChassisOwner: "Owner",
    callerbillLandingNo: "Master Bill of Lading",
    doNo: "House Bill of Lading",
    sealNo: "Seal #",
    secondaryReferenceNo: "Reference #",
    deliveryOrderNo: "Vessel Name",
    bookingNo: "Booking # (export only)",
    releaseNo: "Voyage (Import, Export)/ Order # (Road)",
    purchaseOrderNo: "Purchase Order #",
    shipmentNo: "Shipment #",
    callerPONo: "Pickup #",
    appointmentNo: "Appointment #",
    returnNo: "Return #",
    reservationNo: "Reservation #",
    custom: "Custom Hold",
    carrierHold: "Carrier Hold",
    brokerHold: "Broker Hold",
    items: "Items",
}

export const connectConfurigationTabName = [
    { label: "Sync Control", value: "tab-sync-controll" },
    { label: "Notifications", value: "tab-notifications" }
]

export const connectConfurigationSubTabName = [
    ...(isManageFleetOrOutSource()?.isOutSource ? [{ label: "Settings", value: "settings" }] : []),
    { label: "Load Info", value: "tab-load-info" },
    { label: "Documents", value: "tab-documents" },
]

export const emailNotificationFrequencies = [
    { label: "30 mins", value: "30" },
    { label: "1 hour", value: "60" },
    { label: "2 hours", value: "120" },
    { label: "4 hours", value: "240" }
]
export const documentsArray = [
    {value : "per_diem_audit", label : "Per Diem Autdit"},
    {value : "rate_confirmation", label : "Rate Confirmation"},
    {value : "delivery_order", label : "Delivery Order"},
    {value : "invoice", label : "Invoice"},
    {value : "proof_if_delivery", label : "Proof of Delivery"},
    {value : "combined_invoiced", label : "Combine Invoiced"},
    {value : "carrier_rate_confirmation", label : "Carrier Rate Confirmation"},
    {value : "subcustomer_invoice", label : "Sub Customer Invoice"},
];

export const profileArray = [
    {value : "ebay", label : "eBay"},
    {value : "apple", label : "Apple"},
    {value : "mistubishi", label : "Mistubishi"},
    {value : "mastercard", label : "Master Card"},
    {value : "nintendo", label : "nintendo"},
];

export const customerProfileType = [
    {value : "CUSTOMER", label : "Customer"},
    {value : "SEND_TO_CARRIER", label : "Send To Carrier"},
    {value : "RECEIVE_FROM_CARRIER", label : "From Carrier Sharing"},
];

export const documentJson = [
    {profileName : "Docs to send all customer", type : "Customer", documents : documentsArray}
];

export const documentProfileGroupTab = [
    { label: "Profiles", value: "profile" },
    { label: "Groups", value: "groups" },
]

export const disableToggleOfSyncLoadInfoChassis = [
    "chassisNo", 
    "chassisSize", 
    "chassisType", 
    "chassisOwner", 
    "sealNo", 
    "trailer",
    "freeReturnDate",
    "isLive",
]

export const disableToggleOfSyncLoadInfo = [
    "freight", 
    "custom", 
    "carrierHold", 
    "brokerHold"
]

