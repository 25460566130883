import moment from 'moment';
import _ from 'lodash';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { shallowEqual, useSelector } from 'react-redux';
import { newSmSelectStyle } from 'assets/js/select-style';
import RedirectToRates from "Components/CustomerServiceSideBar/Tabs/Components/RedirectToRates";
import { IconBoldLink, IconCompleted, IconRightDownArrow, IconSaveAsDefault, IconTimes, IconTrash } from 'Components/Common/Icons';
import { DateTimeFormatUtils, addressFormatEnum, addressParser, checkAccountPayablePermission, defaultAddressFormat, getStorage, isConnectedWithOutSource, isGlobalizationEnabled, makeOptionsForSelect, toastr, updateNewTranscation } from 'services';
import { STOPOFF_TYPES } from "utils";
import { updateStopOffType } from '../Dispatcher/actionCreators';
import { ROUTING_ADDRESS_FIELDS } from '../Load/DriverOrderUtility';
import RecommendedReturnTerminals from "../Load/RecommendedReturnTerminals";
import { statusType } from '../LoadRouting/constants';
import { getDistanceBetweenEvents, getDurationInHHMMFormat, isValidDriverOrder, getTollDistanceBetweenEvents } from '../LoadRouting/utilities';
import ExactDateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper";
import { createSource, DUAL_TRANSACTION_MESSAGES, loadEvents, tmsHooks } from '../constant';
import { manualDriverPay } from '../services';
import { TransitionEffect } from "./TransitionEffect";
import { addEvent, cabotagePointCalculate, manageEventOrder, removeEvent, removeEventAddress, updateEventAddress, updateVoidOut } from './actionCreator';
import { getAddressdefaultOptions, getCustomerFields, getRelatedEventIndex, removeEventFromLocalState, validateOrderStatus } from './helpers';
import ChassisValidationModal from './ChassisValidationModal';
import { useRef, useState } from 'react';
import {APPOINTMENT_MESSAGES, MOVE_TYPES , APPOINTMENT_STATUSES} from '../../../constants';
import { withCurrentUserSettings, withAwaitablePrompt } from "../../../hocs";
import RoutingSyncError from '../Load/RoutingSyncError';
import { SYNC_REQUEST_SENT } from '../Load/utility';
import ReviewButton from './ReviewButton';
import { VENDOR_TYPES } from '../DrayosCarrier/constants';
import PopperTooltip from '../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';
import { useHover } from '../../../hooks';
import ReactTooltip from 'react-tooltip';
import AlertBreakDualTransaction from '../../../Components/Common/AlertBreakDualTransaction';
import { isCabotageEnabled } from '../../../services';
import { CABOTAGE_CODE_STATUS } from './constant';
import { CabotageViolationModal } from 'pages/tms/LoadRoutingV2/CabotageViolationModal'


function DriverEvent(props) {
	const {
		event,
		eventIndex,
		dIndex,
		move,
		driverMoves,
		moveIndex,
		showEvent,
		isCombinedTrip,
		isDriverPayLoading,
		currencySymbol,
		redirectToRates,
		isDragging,
		handleOnDrop,
		handleDragOver,
		handleDragEnter,
		droppedIndex,
		loadDetails,
		updateArrivedOrDeparted,
		isCompleted,
		disabled,
		loading,
		loadCompletedAt,
		setDriverOrder,
		editDriverPayData,
		driverPays,
		updateDriverPay,
		setLoading,
		driverOrder,
		checkIsChassisV3,
		setDriverPays,
		setEditDriverPayData,
		isTenderHaveLoadRef,
		checkIsManageFleetOrOutSource,
		invalidDriverOrder,
		setInvalidDriverOrder,
		isInvalidDriverOrder,
		setIsInvalidDriverOrder,
		routingSyncRequests,
		routingSentSyncRequests,
		fetchSyncChanges,
		setIsReviewModalVisible,
		selectedRoute,
		isDualTransaction,
		updateLoadInfo,
		updateSupportMovesInLocal
	} = props;
	const setting = JSON.parse(localStorage.getItem('userBasicSettings'));
	const timeZone = getStorage("timeZone");
	const { customers, shippers, consignees, chassisTerms, isEmptyEnabled } = useSelector(
		(state) => state.TmsReducer,
		shallowEqual
	)
	const [chassisValidation, setChassisValidation] = useState(false)
    const [isPickupApptPrompt, setIsPickupApptPrompt] = useState(
      loadDetails?.pickupAppointmentSystem && loadDetails?.pickUpApptStatus !== APPOINTMENT_STATUSES.CANCELLED
    );
    const [isEmptyApptPrompt, setIsEmptyApptPrompt] = useState(
      loadDetails?.emptyAppointmentSystem && loadDetails?.emptyApptStatus !== APPOINTMENT_STATUSES.CANCELLED
    );
	const [showViolatedModal,setShowViolatedModal] = useState(false);

	const savedFunction = useRef();
	const isBrokerTender = loadDetails?.createSource === createSource.BROKER_TENDER;
    const [railOrderNoElementRef, railOrderNoElement] = useHover();
    const [arrivedElementRef, arrivedElement] = useHover();
    const [departedElementRef, departedElement] = useHover();


	// TODO: Void Out
	const _updateVoidOut = (leg) => {
		let payload = {
			loadId: loadDetails?._id,
			eventId: leg?._id,
		}
		setLoading(true);
		updateVoidOut(payload).then((result) => {
			setDriverOrder(result?.driverOrder)
			setLoading(false);
		})
			.catch((err) => {
				setLoading(false);
				setDriverOrder(loadDetails?.driverOrder)
				console.log("🚀 ~ file: index.jsx:728 ~ updateAllowDriverCompletion ~ err:", err)
			})
	}

	const manageEventDriverOrder = (index, value, _newDriverOrder) => {
		let _driverOrder = _.cloneDeep(_newDriverOrder)
		let newPayload = [];
		_driverOrder?.forEach((event) => {
			newPayload.push({
				...(event?._id ? {_id: event._id} : {}),
				type: event?.type,
				...(event?.customerId?._id ? { customerId: event.customerId._id } : {}),
				...((loadDetails?.preSetOrderStatus !== selectedRoute) ? { isGrayPool: event.isGrayPool } : {}),
			})
		})
		if (value?.value) {
			newPayload[index].customerId = value?.value
			const relatedEventIndex = getRelatedEventIndex(_driverOrder, index);
			if ( relatedEventIndex >= 0 )
				newPayload[relatedEventIndex].customerId = value?.value
		}
		let payloadData = {
			loadId: loadDetails?._id,
			driverOrder: newPayload
		};

		if (loadDetails?.preSetOrderStatus !== selectedRoute) 
			payloadData.preSetOrderStatus = selectedRoute

		setLoading(true)
		manageEventOrder(payloadData).then((result) => {
			setLoading(false)
			setIsInvalidDriverOrder(false)
			if (result?.isChassisv3) {
				setChassisValidation(result?.isChassisv3)
				return
			}
			toastr.show("Update Successfully!", 'success')
		})
			.catch((err) => {
				console.log("🚀 ~ file: index.jsx:250 ~ handleOnDrop ~ err:", err)
				setDriverOrder(loadDetails?.driverOrder)
				setLoading(false)
			})
	}

	const handleUpdateEventDriverOrder = async ({ index, value, status, className, _driverOrder, _payload, addEventWithAddress }) => {
		setShowViolatedModal(false);
		setLoading(true);
		if (value) {
			savedFunction.current = () => addEditEventAddress(index, value, status, className)
			if(addEventWithAddress){
				manageEventDriverOrder(index, value, _driverOrder);
				return;
			}
			await updateEventAddress(_payload).then((result) => {
				if(result === SYNC_REQUEST_SENT){
					fetchSyncChanges && fetchSyncChanges()
					setDriverOrder(loadDetails?.driverOrder)
				}
				if (result?.isChassisv3) {
					setChassisValidation(result?.isChassisv3)
					return
				}
			});
		} else {
			let payloadData = {
				eventId: _driverOrder?.[index]?._id,
				loadId: loadDetails?._id,
			};
			await removeEventAddress(payloadData);
		}
		setLoading(false);
	}

	const updateEventDriverOrder = async (index, value, status, className, _driverOrder) => {
		try {
			const addEventWithAddress = !_driverOrder[index]?._id || (isInvalidDriverOrder && ![loadEvents.CHASSISPICK, loadEvents.RETURNCONTAINER, loadEvents.CHASSISTERMINATION].includes(_driverOrder[index]?.type));
			let eventIDs = _driverOrder?.[tmsHooks.includes(_driverOrder[index].type) ? index - 2 : index - 1]?._id
			const _payload = {
				loadId: loadDetails?._id,
				eventId: addEventWithAddress ? eventIDs : _driverOrder?.[index]?._id,
				customerId: value?.value
			}
			setLoading(true);
			if (isCabotageEnabled() && move?.[index]?.driver) {
				const res = await cabotagePointCalculate({
					loadId: _payload?.loadId,
					eventId: _payload?.eventId,
					moveId: move?.[index]?.moveId,
					oldCustomerId: event?.customerId?._id,
					newCustomerId: _payload?.customerId,
					reference_number: loadDetails?.reference_number,
				});

				if (res?.code === CABOTAGE_CODE_STATUS.VIOLATED) {
					setLoading(false);
					return setShowViolatedModal({ message: res?.message ?? '', response: res, index, value, status, className, _driverOrder, _payload, addEventWithAddress });
				}
			}
			await handleUpdateEventDriverOrder({ index, value, status, className, _driverOrder, _payload, addEventWithAddress })
			setLoading(false);
			TransitionEffect(className, true);
		} catch (error) {
			console.error(error);
			setLoading(false);
			TransitionEffect(className, false);
		}
	}

const onApptCancelConfirm = ({ promptType = "" }) => {
	if (promptType === 'all' || promptType === MOVE_TYPES.PICKUP)
		setIsPickupApptPrompt(false);
	if (promptType === 'all' || promptType === MOVE_TYPES.EMPTY)
		setIsEmptyApptPrompt(false);
};

const onAwaitablePromptClose =(index)=>{
	let _driverOrder = _.cloneDeep(driverOrder);
	_driverOrder[index].customerId = props?.driverOrder[index]?.customerId;
	setDriverOrder(_driverOrder);
}
const isLoadingFunc = (val) => {
	setLoading(val)
}
const addEditEventAddress = async (index, value, status, className) => {
	if (value?.value === driverOrder?.[index]?.customerId?._id) return;

	const _driverOrder = _.cloneDeep(driverOrder);
	if (driverOrder[index]?.isDualTransaction &&
		((driverOrder[index].type === loadEvents.PULLCONTAINER && driverOrder[index]?.dualTransactionFrom) ||
			(driverOrder[index].type === loadEvents.RETURNCONTAINER && driverOrder[index]?.dualTransactionTo))) {
		await AlertBreakDualTransaction(loadDetails, DUAL_TRANSACTION_MESSAGES.UPDATE_ADDRESS, isLoadingFunc)
	}

	if (value) {
		_driverOrder[index].customerId = getCustomerFields(value);

		if ([loadEvents.LIFTOFF, loadEvents.DROPCONTAINER].includes(_driverOrder[index - 1]?.type) && !_driverOrder[index - 1]?._id) {
			_driverOrder[index - 1].customerId = getCustomerFields(value);
		}

		if ([loadEvents.DELIVERLOAD].includes(_driverOrder[index].type) && !_driverOrder[index]?._id) {
			_driverOrder[index].customerId = getCustomerFields(value);
		}
	} else {
		let isDriver = _driverOrder.some((i) => i.driver && !i.isVoidOut && i.moveId === _driverOrder[index]?.moveId);
		
		if (!isDriver && [loadEvents.HOOKCONTAINER, loadEvents.LIFTON].includes(_driverOrder[index]?.type)) {
			const moveIndex = driverMoves.findIndex((move) => move[0]?.moveId === _driverOrder[index]?.moveId);
			isDriver = driverMoves?.[moveIndex - 1]?.[0]?.driver;
		}
		if (![loadEvents.CHASSISPICK, loadEvents.RETURNCONTAINER, loadEvents.CHASSISTERMINATION].includes(_driverOrder[index].type) && isDriver) {
			return toastr.show(
				"A valid location is required here." + _driverOrder[index].type,
				"error"
			);
		} else {
			delete _driverOrder[index].customerId
		}
	}



	setDriverOrder(_driverOrder);
	if (!validateOrderStatus(_driverOrder, loadDetails)) return;
	
	const driverOrderValidation = isValidDriverOrder(_driverOrder)
	if(!driverOrderValidation?.isValid){
		toastr.show(driverOrderValidation.message, "error")
		return;
	}

	// handle appointment cancel prompt
	const { openAwaitablePrompt } = props;
	const { isAppointmentFeatureEnabled } = props?.currentUserSettings || {};
	const type = status?.type === "PULLCONTAINER" ? MOVE_TYPES.PICKUP : MOVE_TYPES.EMPTY;
	const isApptPrompt = type === MOVE_TYPES.PICKUP  ? isPickupApptPrompt : isEmptyApptPrompt;
	if(isAppointmentFeatureEnabled && isApptPrompt && (status?.type === "PULLCONTAINER" || status?.type === "RETURNCONTAINER")){
		document.activeElement?.blur();
		const confirmed = await openAwaitablePrompt(type);
		if (confirmed)
			updateEventDriverOrder(index, value, status, className, _driverOrder);
		else return onAwaitablePromptClose(index);
	}else {
		updateEventDriverOrder(index, value, status, className, _driverOrder);
	}
};
	
	// TODO: Changes StopOff
	const updateStopOffName = async (value, status, field) => {
		let param = {
			loadId: loadDetails?._id,
			eventId: status._id,
			stopOffType: value.value,
			stopOffName: value.label
		}

		let _driverOrder = _.cloneDeep(driverOrder)

		_driverOrder.forEach((p, index) => {
			if (p._id === status._id) {
				_driverOrder[index].stopOffType = value.value
				_driverOrder[index].stopOffTypeName = value.label
			}
		})
		setDriverOrder(_driverOrder)
		updateStopOffType(param)
			.then((res) => {
				toastr.show("Update Successfully!", 'success')
				TransitionEffect(field, true);
			})
			.catch((err) => {
				toastr.show(err, 'error')
				TransitionEffect(field, false);
				console.log("error -=-=-= ", err)
			})
	}

	// TODO: On Buler Driver Pay Func.
	const onBlurHandleForDrivePay = (driverPay, legIndex, statusIndex) => {
		const _editDriverPayData = _.cloneDeep(editDriverPayData);
		const field = `driverPay-${legIndex}-${statusIndex}`;
		if (!_editDriverPayData) return;

		if (_editDriverPayData?._id) {
			const actualPay = driverPays.find((d) => d._id === _editDriverPayData._id);
			if (!actualPay || parseFloat(actualPay.amount) === parseFloat(_editDriverPayData.amount)) {
				setEditDriverPayData(null);
				return;
			}
			const params = {
				id: _editDriverPayData._id,
				amount: _editDriverPayData.amount || 0,
				isManual: true,
				isFromSettlement: false
			}
			updateNewTranscation(params)
				.then((response) => {
					const _driverPays = _.cloneDeep(driverPays);
					if (response?.data?.data) {
						const dpIndex = _driverPays.findIndex((d) => d._id === response.data.data._id);
						if (dpIndex > -1) _driverPays.splice(dpIndex, 1, response.data.data);
					}
					setDriverPays(_driverPays);
					setEditDriverPayData(null);
					TransitionEffect(field, true);
				}).catch(err => {
					TransitionEffect(field, false);
				})
		}
		else {
			delete _editDriverPayData?.invoiceCurrency?.symbol;
			delete _editDriverPayData.isManual;
			delete _editDriverPayData.currencySymbol;
			const params = {
				..._editDriverPayData
			};
			
			if(isGlobalizationEnabled() && (props.loadDetails?.terminal?._id ?? props.loadDetails?.terminal)) {
				params.terminal = props.loadDetails?.terminal?._id ?? props.loadDetails?.terminal;
			}

			manualDriverPay(params).then(async (response) => {
				const _driverPays = _.cloneDeep(driverPays);
				if (response?.data?.data) _driverPays.push(response.data.data);
				setDriverPays(_driverPays);
				setEditDriverPayData(null);
				TransitionEffect(field, true)
			}).catch(err => {
				TransitionEffect(field, false)
			})
		}
	}

	// TODO: Remove Event Func.
	const removeEventFromMove = (index, skipValidation) => {
		let _driverOrder = _.cloneDeep(driverOrder);
		let modifiedDriverOrder = removeEventFromLocalState(_driverOrder, index)
		
		if (!modifiedDriverOrder) return;
		if (!validateOrderStatus(modifiedDriverOrder, loadDetails)) return;

		const driverOrderValidation = isValidDriverOrder(modifiedDriverOrder)
		if (!driverOrderValidation?.isValid) {
			setIsInvalidDriverOrder(true)
			setDriverOrder(modifiedDriverOrder)
			return toastr.show(driverOrderValidation.message, "error")
		} else setDriverOrder(modifiedDriverOrder)


		if(_.isEqual(modifiedDriverOrder.map(x=>x._id+""), loadDetails.driverOrder.map(x=>x._id+""))){
			setDriverOrder(loadDetails.driverOrder)
			setIsInvalidDriverOrder(false)
			return;
		}
		// TODO: Add New API
		setLoading(true)
		let newPayload = [];
		modifiedDriverOrder?.forEach((event) => {
			newPayload.push({
				...(event?._id ? {_id: event._id} : {}),
				type: event?.type,
				...(event?.customerId?._id ? { customerId: event.customerId._id } : {}),
				...((loadDetails?.preSetOrderStatus !== selectedRoute) ? { isGrayPool: event.isGrayPool } : {}),
			})
		})
		let payloadData = {
			loadId: loadDetails?._id,
			driverOrder: newPayload
		};

		if(skipValidation) payloadData.skipValidation = true
		savedFunction.current = () => removeEventFromMove(index, true)

		if (loadDetails?.preSetOrderStatus !== selectedRoute) 
			payloadData.preSetOrderStatus = selectedRoute

		  manageEventOrder(payloadData).then((result) => {
			setLoading(false)
				setIsInvalidDriverOrder(false)
				if (result?.isChassisv3) {
					setChassisValidation(result?.isChassisv3)
					return
				}
				toastr.show("Remove Successfully!", 'success')
		  })
		  .catch((err) => {
			console.log("🚀 ~ file: index.jsx:250 ~ handleOnDrop ~ err:", err)
			setDriverOrder(loadDetails?.driverOrder)
			setLoading(false)
		  })
	}
	const carrierDetails = JSON.parse(getStorage("carrierDetail"))
	const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"))
	let { driverPayShowPermission, driverPayEditPermission } = checkAccountPayablePermission(loggedInUser)

	const formattedAddress = addressParser(event)
	const index = dIndex + eventIndex
	const addressComp = ROUTING_ADDRESS_FIELDS[event.type]

	const nextValidEventIndex = move.findIndex((d, i) => i > eventIndex && !d.isVoidOut)
	let nextMoveEvent = null
	if (driverMoves[moveIndex + 1]) {
		const nextMove = driverMoves[moveIndex + 1]
		nextMoveEvent = nextMove.find((d) => !d.isVoidOut);
	}

	const nextValidEvent = move[nextValidEventIndex]

	let timeDurationOfEvent = getDurationInHHMMFormat(
		nextValidEvent?.arrived, 
		event?.departed, 
		(setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: event?.timeZone || timeZone, toTimeZone: event?.timeZone || timeZone}));
	let nextEventDuration = getDurationInHHMMFormat(
		nextValidEvent?.departed, 
		nextValidEvent?.arrived, 
		(setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: event?.timeZone || timeZone, toTimeZone: nextValidEvent?.timeZone || timeZone}));

	let distanceBwEvents = getDistanceBetweenEvents(move, eventIndex, nextValidEventIndex)
	const distanceMeasure = carrierDetails?.carrier?.distanceMeasure || "mi"
	let tollDistanceBwEvents = getTollDistanceBetweenEvents(move, eventIndex, nextValidEventIndex, distanceMeasure)

	const _editDriverPayData = editDriverPayData
	const isEditingPay =
		_editDriverPayData?.fromEventId === event._id &&
		_editDriverPayData?.toEventId === nextValidEvent?._id
	const currentDriverId = nextValidEvent?.driver?._id || nextValidEvent?.driver
	let driverPay = null
	if (isEditingPay) driverPay = _editDriverPayData
	if (!driverPay) {
		driverPay = driverPays?.find(
			(pay) =>
				pay.fromEventId === event._id &&
				pay.toEventId === nextValidEvent?._id &&
				pay?.createdBy?._id === currentDriverId
		)
	}
	const dynamicHeight = `${111 * (nextValidEventIndex - eventIndex - 1) + 93 + "px"}`

	const onChassisEventSuccess = (result) => {
		setLoading(false)
		if(result?.driverOrder) setDriverOrder(result?.driverOrder)
		if (savedFunction.current) {
			savedFunction.current()
			savedFunction.current = null
		}
	  }
	
	  const onChassisEventFailure = () => {
		if (props && props.setOrderForCheck) props.setOrderForCheck(loadDetails.driverOrder)
		setLoading(false)
		setDriverOrder(loadDetails?.driverOrder)
	  }
	const isDrayosCarrier = driverOrder[dIndex]?.drayosCarrier;

	const { AwaitablePromptAlert } = props;

	const checkRequest = (event) => {
		if(routingSyncRequests?.length === 0 && routingSentSyncRequests?.length === 0) return { className: '' }
		if(![loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD, loadEvents.RETURNCONTAINER, loadEvents.CHASSISPICK, loadEvents.CHASSISTERMINATION].includes(event?.type)) return { className: '' }
		let eventCheck
		if(event.type === loadEvents.PULLCONTAINER) eventCheck = "Pickup"
		if(event.type === loadEvents.DELIVERLOAD) eventCheck = "Deliver"
		if(event.type === loadEvents.RETURNCONTAINER) eventCheck = "Return"
		if(event.type === loadEvents.CHASSISPICK) eventCheck = "Pickup Chassis"
		if(event.type === loadEvents.CHASSISTERMINATION) eventCheck = "Terminate Chassis"

		const request = routingSyncRequests?.find((request) => (request.value?.loadRoutingLocation?.eventId === event?._id && request.value?.loadRoutingLocation?.event === eventCheck))
		const sentRequest = routingSentSyncRequests?.find((request) => (request.value?.loadRoutingLocation?.eventId === event?._id && request.value?.loadRoutingLocation?.event === eventCheck))
		let className ='';
		if(request) className = 'input-warning request-wrapper ';
		if(sentRequest) className = 'input-primary-100  request-wrapper request-wrapper-cancel '
		let requestObj = {}
		if(request) requestObj = request
		if(sentRequest) requestObj = sentRequest
		return { className, requestObj }
	}

	const isRailMove = event?.vendorType === VENDOR_TYPES.RAIL;
	const blockRoutingActions = isCompleted || isCombinedTrip || isRailMove;
	const isUnassignedMove = !event.driver && !event.drayosCarrier;
	const isPreventToStart = isBrokerTender && loadDetails?.preventToStart;
	const isStopOffDisabled = isCombinedTrip || !event._id || event?.arrived;
	const isDriverPayDisabled = !event.driver || isDriverPayLoading || isCombinedTrip || !driverPayEditPermission || isInvalidDriverOrder;
	const isAddressUpdateDisabled = event.isVoidOut || isCompleted || isTenderHaveLoadRef 
									|| (isInvalidDriverOrder && (!(event?.driver || event?.drayosCarrier) || [loadEvents.CHASSISPICK, loadEvents.RETURNCONTAINER, loadEvents.CHASSISTERMINATION].includes(event?.type) || event?.customerId )) // return true for existing events that doesn't need address if vendor is assigned ( at the time of invalid event order )
									|| isRailMove;

	return (
		<div className={`position-relative ${isConnectedWithOutSource() && checkRequest(event)?.className}`}>
			{checkIsManageFleetOrOutSource?.isManageFleet && eventIndex !== move.length - 1 && !event.isVoidOut && driverPayShowPermission && !showEvent && (
				<div
					className={`position-absolute driver__right`}
					style={{ maxWidth: "270px" }}
				>
					<div className="position-relative z-1">
						<div
							className="position-absolute assign-arrow"
							style={{ height: dynamicHeight }}
						></div>
						<div className={`pl-40 ${isDrayosCarrier ? "pt-4" : ""}`}>
							<div className={`align-items-center mb-1 ${isDrayosCarrier ? "d-none" : "d-flex"}`}>
								<span className="mr-1 text-muted font-12 font-weight-normal">Driver Pay</span>
								<div className="form-group m-0" style={{ width: "103px" }}>
									<div className={`input-wrapper driverPay-${moveIndex}-${eventIndex}`}>
										<div>
											<input
												type="text"
												name="driverPay"
												disabled={isDriverPayDisabled}
												className={`form-control form-control-xs driverPay-${moveIndex}-${eventIndex} pr-30 transition-white-field`}
												id={`driverPay-${moveIndex}-${eventIndex}`}
												placeholder={currencySymbol + "00.00"}
												value={
													driverPay
														? `${driverPay?.invoiceCurrency?.symbol || currencySymbol}${isEditingPay
															? driverPay.amount
															: parseFloat(driverPay.amount || 0)?.toFixed(2)
														}`
														: ""
												}
												onChange={(e) =>
													updateDriverPay(driverPay, e.target.value, event, nextValidEvent)
												}
												onBlur={(e) =>
													onBlurHandleForDrivePay(driverPay, moveIndex, eventIndex)
												}
												onKeyPress={(event) => {
													if (event.key === "Enter") {
														onBlurHandleForDrivePay(driverPay, moveIndex, eventIndex)
													}
													if (
														!/^[-]?[0-9]*\.?[0-9]*$/.test(event.key) ||
														(event.target.value
															.replace(
																driverPay?.invoiceCurrency?.symbol || currencySymbol,
																""
															)
															.trim()
															.indexOf(".") != -1 &&
															event.key === ".")
													) {
														event.preventDefault()
													}
												}}
											/>
										</div>
										<div className="input-icon p-0 pr-10">
											{driverPay ? (
												<RedirectToRates info={driverPay} redirectToRates={redirectToRates} />
											) : (
												<IconSaveAsDefault />
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center mb-1">
								<span className="mr-15 text-muted font-12 font-weight-normal">Duration</span>
								<div className="text-dark  font-12 font-weight-bold">
									{nextValidEvent?.departed ? nextEventDuration : "-"}
								</div>
							</div>
							<div className="d-flex align-items-center mb-1">
								<span className="mr-15 text-muted font-12 font-weight-normal">Distance</span>
								<div className="text-dark  font-12 font-weight-bold">
									{event?.address && nextValidEvent?.address
										? distanceBwEvents + " " + distanceMeasure
										: "-"}
								</div>
							</div>

							{tollDistanceBwEvents && tollDistanceBwEvents != ""  && (<div className="d-flex align-items-center mb-1">
								<span className="mr-15 text-muted font-12 font-weight-normal">Toll Distance</span>
								<div className="text-dark  font-12 font-weight-bold">
									{event?.address && nextValidEvent?.address
										? tollDistanceBwEvents
										: "-"}
								</div>
							</div>)}
						</div>
					</div>
				</div>
			)}
			<div
				onDrop={isDragging && handleOnDrop}
				onDragOver={(e) => handleDragOver(e)}
				onDragEnter={() => {
					handleDragEnter(index)
				}}
			>
				<div className={`outline-card p-10 mb-1`}>
					<div className="d-flex align-items-center mb-10">
						{(event.driver || event.drayosCarrier) && !event.isVoidOut && event.arrived && event.departed && (
							<IconCompleted className="mr-2" />
						)}
						<span className="font-14 text-dark font-wight-500 mr-10">
							{statusType[event.type]}
						</span>
						{event.type === loadEvents.STOPOFF && (
							<div className="p-0 select-wrapper__input">
								<Select
									isDisabled={isStopOffDisabled}
									placeholder="Select Option"
									className={`w-200 stopoff-${index}`}
									styles={newSmSelectStyle}
									options={STOPOFF_TYPES()
										?.filter((d) => !d.isDeleted)
										?.map((d) => {
											return { label: d.name, value: d._id }
										})}
									value={
										event?.stopOffType
											? { value: event?.stopOffType, label: event?.stopOffTypeName }
											: ""
									}
									onChange={(e) => {
										updateStopOffName(e, event, `stopoff-${index}`)
									}}
								/>
							</div>
						)}
						<div className="d-flex ml-auto">
						{
								event?.orderNo && driverMoves?.find((move)=>move[0]?._id===event?._id) &&
								<>
									<div
										className="btn btn-sm btn-outline-light text-primary mr-1"
									>
										<span ref={railOrderNoElementRef} className="text-gray-900 d-flex align-items-center">
											<IconRightDownArrow />
											<span className="ml-2">{event?.orderNo}</span>
										</span>
										{railOrderNoElement && 
											<PopperTooltip 
											isOpen={railOrderNoElement}
												refNo={railOrderNoElementRef.current}
												name={"Connected to Rail Order."}
												color={'gray-700'} cssClass={"w-150 load-open"}>
											</PopperTooltip>
										}
									</div>
								</>
							}
							{
								event?.tenderId?.drayOSTenderRefNo &&
								<div className="d-flex align-items-center mr-3">
									Tender Reference No.
									<span className="badge badge-light">{event?.tenderId?.drayOSTenderRefNo}</span>
								</div>
							}
							{!isCompleted && !event?.isVoidOut &&
								event?.arrived &&
								!nextValidEvent?.arrived &&
								!nextMoveEvent?.arrived &&
								!loadCompletedAt && (
									<button
										className="btn btn-xss btn-secondary text-center mr-10"
										onClick={() => _updateVoidOut(event)}
										disabled={
											!disabled ||
											(!event.driver && !event.drayosCarrier) ||
											isRailMove
										}
									>
										<span className="w-100">Void Out</span>
									</button>
								)}

							{(eventIndex == (move.length - 1)) && event?.dualTransactionTo && isDualTransaction && (
								<div>
									<button
										data-tip
										data-for="cloneicon"
										className="btn btn-xss btn-outline-light mr-2"
										onClick={() => {
											updateLoadInfo && updateLoadInfo(event?.dualTransactionTo?.reference_number);
										}}
									>
										<IconBoldLink className='mr-2 text-muted' />
										{event?.dualTransactionTo?.reference_number}
									</button>
									<ReactTooltip id="cloneicon" place="top" effect="solid" className="react-tooltip-new">
										{"Dual Transaction"}
									</ReactTooltip>
								</div>
							)}
							{(eventIndex == 0) && event?.dualTransactionFrom && isDualTransaction && (
								<div>
									<button
										data-tip
										data-for="cloneicon"
										className="btn btn-xss btn-outline-light mr-2"
										onClick={() => {
											updateLoadInfo && updateLoadInfo(event?.dualTransactionFrom?.reference_number);
										}}
									>
										<IconBoldLink className='mr-2 text-muted' />
										{event?.dualTransactionFrom?.reference_number}
									</button>
									<ReactTooltip id="cloneicon" place="top" effect="solid" className="react-tooltip-new">
										{"Dual Transaction"}
									</ReactTooltip>
								</div>
							)}

							{!event.isVoidOut && !event.departed && !event.combineTripId && (
								<button
									className="btn btn-link text-danger p-0"
									disabled={isRailMove || isCompleted || isTenderHaveLoadRef}
									onClick={() => {
										if (driverOrder.length > 1) removeEventFromMove(index)
									}}
								>
									<IconTrash />
								</button>
							)}
						</div>
					</div>
					<div className="form-group">
						{isConnectedWithOutSource() && <ReviewButton data={checkRequest(event)} setIsReviewModalVisible={setIsReviewModalVisible} fetchSyncChanges={fetchSyncChanges} load_reference_number={loadDetails?.reference_number}/>}
						{addressComp && (
							<AsyncSelect
								className={`w-100 event-${index}`}
								isClearable
								defaultOptions={makeOptionsForSelect(
									getAddressdefaultOptions(event, customers, chassisTerms, shippers, consignees),
									"company_name",
									"address.address",
									"_id"
								)}
								cacheOptions
								value={
									defaultAddressFormat() === addressFormatEnum.EU
										? event.customerId && event.customerId?._id
											? {
												label:
													`${event?.customerId?.company_name} ${formattedAddress}`?.trim(),
												value: event.customerId?._id,
											}
											: null
										: event.customerId && event.customerId?._id
											? {
												label: `${event.customerId.company_name} ${event.customerId.address ? event.customerId.address.address : ""
													}`?.trim(),
												value: event.customerId._id,
											}
											: null
								}
								onChange={(value) => addEditEventAddress(index, value, event, `event-${index}`)}
								loadOptions={addressComp.loadOptions}
								isDisabled={isAddressUpdateDisabled}
								styles={newSmSelectStyle}
								isLoading={event.type === loadEvents.RETURNCONTAINER && loading}
							/>
						)}
						{isConnectedWithOutSource() && <RoutingSyncError
							data={checkRequest(event)}
						/>}
					</div>
					<div className="d-flex mb-0 align-items-center">
						{event.isVoidOut && (
							<span class="btn btn-xss bg-danger text-light mr-2">
								{event?.driver?.name ? 
									`Driver: ${event?.driver?.name} ${event?.driver?.lastName || "".trim()}`
								:
									`Carrier: ${event?.drayosCarrier?.company_name?.trim()}`
								}
							</span>
						)}
						{event.isVoidOut && (
							<span class="btn bg-danger text-light btn-xss mr-2">
								Voided Out{" "}
								{!nextValidEvent?.arrived && !isCompleted && !isCombinedTrip && (
									<span
										className="ml-2"
										style={{
											cursor: "pointer",
										}}
										onClick={() =>
											!nextValidEvent?.arrived &&
											!isCompleted &&
											!isCombinedTrip &&
											removeEventFromMove(index)
										}
									>
										<IconTimes />
									</span>
								)}
							</span>
						)}
						{!event.isVoidOut && !event.departed && (
							<button
								className="btn btn-xss btn-success w-150 text-center"
								onClick={() => updateArrivedOrDeparted(null, "departed", event)}
								disabled={!disabled || blockRoutingActions || isUnassignedMove || isPreventToStart}
							>
										<span className="w-100" ref={event?.orderNo ?  arrivedElementRef : null}  >Arrived</span>

										{arrivedElement && 
											<PopperTooltip 
											isOpen={arrivedElement}
												refNo={arrivedElementRef.current}
												name={"Moves linked to Rail Order’s can’t be individually edited. Remove from Rail Order to edit."}
												color={'gray-700'} cssClass={"w-200 load-open"}>
											</PopperTooltip>
										}
								
								
							</button>
						)}
						{(event.driver || event.drayosCarrier) && !event.isVoidOut && event.departed && (
							<div className="form-group mb-0">
								<div className={`input-wrapper arrivedDate-${eventIndex}`}>
									<ExactDateWrapper
										className={`arrivedDate-${eventIndex} laDate`}
										isShowTime={true}
										dateFormat="MM-DD-yyyy hh:mm A"
										componentClassName={`date-departed-${event._id}`}
										timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
										disabled={!disabled || blockRoutingActions}
										displayTimeZone={getStorage("timeZone")}
										defaultDate={event.departed ? moment(event.departed) : ""}
										OnBlurUpdate={(_date) =>
											updateArrivedOrDeparted(
												`arrivedDate-${eventIndex}`,
												"departed",
												event,
												_date,
											)
										}
										hideShowSelectDate={true}
										name="arrivedDate"
									/>
									{!isCompleted && (
										<div
											className="input-icon p-0 pr-10 text-muted"
											onClick={() => {
												!isCombinedTrip && event?.vendorType!== VENDOR_TYPES.RAIL &&
													updateArrivedOrDeparted(null, "departed", event, null, true)
											}}
										>
											<IconTimes />
										</div>
									)}
								</div>
							</div>
						)}
						{!event.isVoidOut &&
							nextValidEvent &&
							((nextValidEvent.driver || nextValidEvent.drayosCarrier) || !nextValidEvent.arrived) && (
								<div className="mx-10 bg-gray-50 p-1 minwidth-60px px-1 h-24px text-center rounded-lg">
									{(event.driver || event.drayosCarrier) && nextValidEvent.arrived ? (
										<span className="font-10 font-weight-bold">{timeDurationOfEvent}</span>
									) : (
										<span
											className="font-12 font-weight-bold"
											style={{
												color: "rgba(23, 42, 65, 0.5)",
											}}
										>
											-
										</span>
									)}
								</div>
							)}
						{!event.isVoidOut && nextValidEvent && !nextValidEvent.arrived && (
							<button
								className="btn btn-xss btn-success w-150 text-center"
								onClick={() => updateArrivedOrDeparted(null, "arrived", nextValidEvent)}
								disabled={!disabled || blockRoutingActions || isUnassignedMove || isPreventToStart}
							>
								<span className="w-100" ref={event?.orderNo ?  departedElementRef : null}  >Departed</span>

								{departedElement &&
									<PopperTooltip
										isOpen={departedElement}
										refNo={departedElementRef.current}
										name={"Moves linked to Rail Order’s can’t be individually edited. Remove from Rail Order to edit."}
										color={'gray-700'} cssClass={"w-200 load-open"}>
									</PopperTooltip>
								}
							</button>
						)}
						{!event.isVoidOut &&
							nextValidEvent &&
							(nextValidEvent.driver || nextValidEvent.drayosCarrier) &&
							nextValidEvent.arrived && (
								<div className="form-group mb-0">
									<div className={`input-wrapper departedDate-${eventIndex}`}>
										<ExactDateWrapper
											className={`departedDate-${eventIndex} laDate`}
											displayTimeZone={getStorage("timeZone")}
											componentClassName={`date-arrived-${nextValidEvent?._id}`}
											disabled={!disabled || blockRoutingActions}
											defaultDate={nextValidEvent.arrived ? moment(nextValidEvent.arrived) : ""}
											dateFormat="MM-DD-yyyy hh:mm A"
											timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
											OnBlurUpdate={(_date) =>
												updateArrivedOrDeparted(
													`departedDate-${eventIndex}`,
													"arrived",
													nextValidEvent,
													_date,
												)
											}
											hideShowSelectDate={true}
											isShowTime={true}
											name="arrivedDate"
										/>
										{!isCompleted && (
											<div
												className="input-icon p-0 pr-10 text-muted"
												onClick={() => {
													!isCombinedTrip && event?.vendorType!== VENDOR_TYPES.RAIL &&
														updateArrivedOrDeparted(null, "arrived", nextValidEvent, null, true)
												}}
											>
												<IconTimes />
											</div>
										)}
									</div>
								</div>
							)}
					</div>
					{addressComp && event.type === loadEvents.RETURNCONTAINER && isEmptyEnabled && (
						<div className="d-flex form-row-md">
							<div className="form-group mb-0 mt-10 col">
								<div className="w-100">
									<RecommendedReturnTerminals row={loadDetails} isLoadInfo={true} />
								</div>
							</div>
						</div>
					)}
				</div>
				{droppedIndex === index && (
					<div className="col-12 kanban-card-view my-2">
						<p className="text-center my-4">Drop Your Status Here</p>
					</div>
				)}
			</div>

			{chassisValidation && <ChassisValidationModal
				validations={chassisValidation}
				loadDetails={loadDetails}
				onChassisEventSuccess={onChassisEventSuccess}
				onChassisEventFailure={onChassisEventFailure}
				updateSupportMovesInLocal={updateSupportMovesInLocal}
			/>}
			 <AwaitablePromptAlert content={APPOINTMENT_MESSAGES.CONFIRM_CANCEL_APPOINTMENT} confirmCallback={onApptCancelConfirm}/>

			{isCabotageEnabled() && showViolatedModal &&
				<CabotageViolationModal
					isWarning
					response={showViolatedModal?.response ?? {}}
					contentBody={showViolatedModal?.message ?? ''}
					showModal={showViolatedModal}
					setShowModal={setShowViolatedModal}
					isLoading={loading}
					removeDriver={async () => await handleUpdateEventDriverOrder(showViolatedModal)}
					confirmText='Continue'
				/>
			}   
		</div>
	)
}

export default (withCurrentUserSettings(withAwaitablePrompt(DriverEvent)))