export const TMS_LOGIN_SUCCESS = "TMS_LOGIN_SUCCESS";
export const TMS_LOGOUT = "TMS_LOGOUT";
export const TMS_CREATE_LOAD = "TMS_CREATE_LOAD";
export const TMS_FETCH_PRICING = "TMS_FETCH_PRICING";
export const TMS_GET_USER_LOAD = "TMS_GET_USER_LOAD";
export const TMS_GET_USER_LOAD_FETCH_SUCCEEDED =
  "TMS_GET_USER_LOAD_FETCH_SUCCEEDED";
export const TMS_GET_CUSTOMERS_ALL = "TMS_GET_CUSTOMERS_ALL";
export const TMS_GET_CUSTOMERS_ALL_START = "TMS_GET_CUSTOMERS_ALL_START";
export const TMS_GET_CUSTOMERS_ALL_FAILED = "TMS_GET_CUSTOMERS_ALL_FAILED";
export const TMS_GET_CUSTOMERS_ALL_SUCCEEDED =
  "TMS_GET_CUSTOMERS_ALL_SUCCEEDED";
export const TMS_GET_CUSTOMERS = "TMS_GET_CUSTOMERS";
export const TMS_UPDATE_STATE = "TMS_UPDATE_STATE";
export const TMS_GET_FLEETCUSTOMERS = "TMS_GET_FLEETCUSTOMERS";
export const TMS_GET_FLEETCUSTOMERS_FAILED = "TMS_GET_FLEETCUSTOMERS";
export const TMS_GET_FLEETCUSTOMERS_START = "TMS_GET_FLEETCUSTOMERS";
export const TMS_GET_CUSTOMERS_START = "TMS_GET_CUSTOMERS_START";
export const TMS_GET_CUSTOMERS_FAILED = "TMS_GET_CUSTOMERS_FAILED";
export const TMS_GET_CUSTOMERS_SUCCEEDED = "TMS_GET_CUSTOMERS_SUCCEEDED";
export const TMS_GET_CUSTOMERS_FLEETSUCCEEDED =
  "TMS_GET_CUSTOMERS_FLEETSUCCEEDED";
export const TMS_GET_LOCATION_PROFILE = "TMS_GET_LOCATION_PROFILE";
export const TMS_GET_LOCATION_PROFILE_SUCCEEDED =
  "TMS_GET_LOCATION_PROFILE_SUCCEEDED";
export const TMS_NEW_LOAD = "TMS_NEW_LOAD";
export const TMS_NEW_LOAD_SUCCEEDED = "TMS_NEW_LOAD_SUCCEEDED";
export const LOCATION_PRICE = "LOCATION_PRICE";
export const UPDATE_LOCATION_PRICE = "UPDATE_LOCATION_PRICE";
export const GET_CALLERS_START = "GET_CALLERS_START";
export const GET_CALLERS_SUCCESS = "GET_CALLERS_SUCCESS";
export const GET_CALLERS_FAILED = "GET_CALLERS_FAILED";
export const GET_SHIPPERS_START = "GET_SHIPPERS_START";
export const GET_SHIPPERS_SUCCESS = "GET_SHIPPERS_SUCCESS";
export const GET_SHIPPERS_FAILED = "GET_SHIPPERS_FAILED";
export const GET_DRAYOSCARRIERS_START = "GET_DRAYOSCARRIERS_START";
export const GET_DRAYOSCARRIERS_SUCCESS = "GET_DRAYOSCARRIERS_SUCCESS";
export const GET_DRAYOSCARRIERS_FAILED = "GET_DRAYOSCARRIERS_FAILED"
export const GET_CONSIGNEES_START = "GET_CONSIGNEES_START";
export const GET_CONSIGNEES_SUCCESS = "GET_CONSIGNEES_SUCCESS";
export const GET_CONSIGNEES_FAILED = "GET_CONSIGNEES_FAILED";
export const GET_ALL_CUSTOMER_START = "GET_ALL_CUSTOMER_START";
export const GET_ALL_CUSTOMER_SUCCESS = "GET_ALL_CUSTOMER_SUCCESS";
export const GET_ALL_CUSTOMER_FAILED = "GET_ALL_CUSTOMER_FAILED";
export const GET_CHASSIS_TERMS_START = "GET_CHASSIS_TERMS_START";
export const GET_CHASSIS_TERMS_SUCCESS = "GET_CHASSIS_TERMS_SUCCESS";
export const GET_CHASSIS_TERMS_FAILED = "GET_CHASSIS_TERMS_FAILED";
export const GET_ALL_USER_PUBLIC_INFO_SUCCESS =
  "GET_ALL_USER_PUBLIC_INFO_SUCCESS";
export const GET_ALL_USER_PUBLIC_INFO_FAILED =
  "GET_ALL_USER_PUBLIC_INFO_FAILED";
export const GET_TIMESLOTS_SUCCESS = "GET_TIMESLOTS_SUCCESS";
export const GET_TIMESLOTS_START = "GET_TIMESLOTS_START"
export const GET_TIMESLOTS_FAILED = "GET_TIMESLOTS_START";

export const GET_BILLING_CHARGE_LISTS_PENDING = "GET_BILLING_CHARGE_LISTS_PENDING";
export const GET_BILLING_CHARGE_LISTS_SUCCESS = "GET_BILLING_CHARGE_LISTS_SUCCESS";
export const GET_BILLING_CHARGE_LISTS_FAILED = "GET_BILLING_CHARGE_LISTS_FAILED";

export const CUSTOMER_BILLING_CHARGE_LISTS_PENDING = "CUSTOMER_BILLING_CHARGE_LISTS_PENDING";
export const CUSTOMER_BILLING_CHARGE_LISTS_SUCCESS = "CUSTOMER_BILLING_CHARGE_LISTS_SUCCESS";
export const CUSTOMER_BILLING_CHARGE_LISTS_FAILED = "CUSTOMER_BILLING_CHARGE_LISTS_FAILED";
export const SET_MEASURE_UNITS = "SET_MEASURE_UNITS";

export const size = ["26'", "40'", "45'", "48'", "53'"];
export const trailerTypes = [
  "Dry Van",
  "Reefer",
  "Flat Bed",
  "Drop Deck",
  "Low Boy",
  "Double Drop Deck",
];
export const customerTypes = [
  { value: "caller", label: "CUSTOMER" },
  { value: "shipper", label: "PORT/SHIPPER" },
  { value: "consignee", label: "CONSIGNEE" },
  { value: "chassisPick", label: "CHASSIS PICK" },
  { value: "containerReturn", label: "CHASSIS RETURN" },
  { value: "chassisTermination", label: "CHASSIS TERMINATION" },
  { value: "ALL", label: "ALL" },
];
export const DOCUMENT_CUSTOMER = [
  "Check",
  "ACH",
  "Wire",
  "Credit",
  "Credit Card",
  "Cash",
  "Other",
];

export const ROUTING_LABELS =
{
  "PULLCONTAINER": "From",
  "DELIVERLOAD": "To",
  "DROPCONTAINER": "To",
  "LIFTOFF": "To",
  "RETURNCONTAINER": "To",
  "HOOKCONTAINER": "From",
  "LIFTON": "From",
  "BOBTAIL_STARTED" : "From",
  "BOBTAIL_ENDED" : "To",
  "CHASSIS_STARTED" : "From",
  "CHASSIS_ENDED" : "To"

}

export const timeZoneList = [
  { value: 'America/New_York', label: 'Eastern Time (US & Canada)' },
  { value: 'America/Indiana/Indianapolis', label: 'Indiana (East)' },
  { value: 'America/Chicago', label: 'Central Time (US & Canada)' },
  { value: 'America/Regina', label: 'Saskatchewan' },
  { value: 'America/Denver', label: 'Mountain Time (US & Canada)' },
  { value: 'America/Phoenix', label: 'Arizona' },
  { value: 'America/Juneau', label: 'Alaska' },
  { value: 'America/Los_Angeles', label: 'Pacific Time (US & Canada)' },
  { value: 'America/Halifax', label: 'Atlantic Time (Canada)' },
  { value: "Europe/Kiev", label: "Eastern European Time" },
  { value: "Europe/Amsterdam", label: "Central European Time" },
  { value: "Europe/London", label: "Western European Time" },
  { value: "UTC", label: "No Time Zone" },
]
export const loadEvents = {
  PULLCONTAINER : "PULLCONTAINER",
  DELIVERLOAD : "DELIVERLOAD",
  DROPCONTAINER: "DROPCONTAINER",
  HOOKCONTAINER:"HOOKCONTAINER",
  RETURNCONTAINER: "RETURNCONTAINER",
  CHASSISPICK : "CHASSISPICK",
  CHASSISTERMINATION : "CHASSISTERMINATION",
  LIFTOFF  : "LIFTOFF",
  LIFTON  : "LIFTON",
  STOPOFF : "STOPOFF",
  DROPCHASSIS: "DROPCHASSIS"
};
export const loadEventsShortLable = {
  PULLCONTAINER : "PU",
  DELIVERLOAD : "DL",
  DROPCONTAINER: "DC",
  HOOKCONTAINER:"HC",
  RETURNCONTAINER: "RC",
  CHASSISPICK : "HS",
  CHASSISTERMINATION : "TC",
  LIFTOFF  : "LO",
  LIFTON  : "LN",
  STOPOFF : "SO",
  CHASSISTRANSFER: "CT",
  BOBTAILTRANSFER: "BT",
}

export const SUPPORT_MOVE_EVENTS =  {
  ...loadEvents,
  CHASSISTRANSFER: 'CHASSISTRANSFER',
  BOBTAILTRANSFER: 'BOBTAILTRANSFER',
  DROPCHASSIS: 'DROPCHASSIS'
}

export const tmsEndMoveEvents = ['DROPCONTAINER', 'LIFTOFF', 'CHASSISTERMINATION']
export const tmsDrops = ['DROPCONTAINER', 'LIFTOFF'];
export const tmsHooks = ['HOOKCONTAINER','LIFTON'];
export const loadEventRel = {
  'DROPCONTAINER': 'HOOKCONTAINER',
  'LIFTOFF': 'LIFTON'
}
export const supportMoveTypes = {
  "BOBTAIL_STARTED" : "BOBTAIL_STARTED",
  "BOBTAIL_ENDED" : "BOBTAIL_ENDED",
  "CHASSIS_STARTED" : "CHASSIS_STARTED",
  "CHASSIS_ENDED" : "CHASSIS_ENDED"
}

export const SUPPORT_MOVE_TYPES = {
  "MANUAL" : "MANUAL",
  "SYSTEM" : "SYSTEM"
}

export const LOAD_STATUS = [
  { value: "COMPLETED", label: "COMPLETED"}, 
  { value: "APPROVED", label: "APPROVED"},
  { value: "BILLING", label: "BILLING"},
  { value: "UNAPPROVED", label: "UNAPPROVED"},
  { value: "REBILLING", label: "REBILLING"},
  { value: "PARTIAL_PAID", label: "PARTIAL_PAID"},
  { value: "FULL_PAID", label: "FULL_PAID"},
];

export const CHARGE_STATUS = [
  { value: "COMPLETED", label: "COMPLETED"}, 
  { value: "APPROVED", label: "APPROVED"},
  { value: "INVOICED", label: "BILLING"},
  { value: "UNAPPROVED", label: "UNAPPROVED"},
  { value: "REBILLING", label: "REBILLING"},
  { value: "PARTIALLY_PAID", label: "PARTIALLY_PAID"},
  { value: "PAID", label: "PAID"},
]

export const DATE_STATUS = [
  { value: "BILLING_DATE", label: "Billing Date"},
  { value: "COMPLETE_DATE", label: "Complete Date"}
]

export const PREVENT_BILLING_DATE_CLEAR_INVOICE_STATUS = [
  "BILLING",
  "PARTIAL_PAID",
  "FULL_PAID",
  "REBILLING",
];

export const MAIL_AUDIT_REPORT_HEADERS = ["Sender", "To", "Subject", "Date and Time", "Status", "Notes"];
export const MAIL_AUDIT_REPORT_STATUS = [
  { label: "All Statuses", value: "all" },
  { label: "Delivered", value: "delivered" },
  { label: "Rejected", value: "reject" },
  { label: "Rejected (Soft Bounce)", value: "soft_bounce" },
  { label: "Rejected (Hard Bounce)", value: "hard_bounce" }
];
export const AuditTypeEnum = {
  EMAIL_AUDIT_REPORT: "email_audit_report",  
  DOWNLOAD_AUDIT_REPORT: "download_audit_report",
  IS_EMAIL_AUDIT_ENABLED: "isEmailAuditEnabled",
  IS_DOWNLOAD_AUDIT_ENABLED: "isDownloadAuditEnabled",
} 

export const IMPORT_LOAD_TYPE = "IMPORT";
export const EXPORT_LOAD_TYPE = "EXPORT";
export const ROAD_LOAD_TYPE = "ROAD";
export const BILL_ONLY_LOAD_TYPE = "EXPORT";

export const LoadType = [
  { value: IMPORT_LOAD_TYPE, label: "IMPORT" },
  { value: EXPORT_LOAD_TYPE, label: "EXPORT" },
  { value: ROAD_LOAD_TYPE, label: "ROAD" },
  { value: BILL_ONLY_LOAD_TYPE, label: "BILL ONLY" },
];

export const DEFAULT_TIMEZONE = "America/New_York";

export const CombinationMethod = {
  BY_LOAD: 'BY_LOAD',
  BY_DAY: 'BY_DAY',
  BY_WEEK: 'BY_WEEK',
  BY_REFERENCE: 'BY_REFERENCE',
  ALL_COMPLETED: 'ALL_COMPLETED',
  MANUAL: 'MANUAL'
}

export const LOAD_CHARGE_COMBINATION = [
  { label: "Manually", value: CombinationMethod.MANUAL },
  { label: "By Load", value: CombinationMethod.BY_LOAD },
  { label: "By Day", value: CombinationMethod.BY_DAY },
  { label: "By Week", value: CombinationMethod.BY_WEEK },
  { label: "By Reference # when all are Completed", value: CombinationMethod.BY_REFERENCE },
  { label: "All Completed Load", value: CombinationMethod.ALL_COMPLETED },
]

export const PAYMENT_TERMS_METHOD = [
  { label: "Daily", value: 'day' },
  // { label: "Weekly", value: 'week' },
  { label: "Monthly", value: 'month' },
  // { label: "Yearly", value: 'year' },
]

export const PAYMENT_TERMS_OPTIONS = [
  {label : 'Last Day of Invoice month', value : 'month'},
  {label : 'Invoice Date', value : 'day'}
]

export const PAYMENT_TERMS_OPTIONS_TERMINAL = [
  {label : 'Last Day of Invoice month', value : 'month'},
  {label : 'Invoice Date', value : 'day'},
  {label: 'Use Default', value: 'default'}
]

export const DEFAULT_TERMINAL_TERMS = {label: 'Use Default', value: 'default'}
export const AFTER_COMPLETE_STATUS = ["UNAPPROVED","APPROVED","REBILLING","BILLING","PARTIAL_PAID", "FULL_PAID"]
export const NEW_CUSTOMER_ROUTES = [
  "/tms/charge-profiles",
  "/tms/load-tariffs",
  "/tms/charge-profiles-groups",
  "/tms/driver-charge-profiles",
  "/tms/driver-charge-profiles-group",
  "/tms/driver-tariffs",
  "/tms/carrier-tariffs",
  "/tms/carrier-charge-profile",
  "/tms/carrier-charge-profile-groups",
];

export const NEW_CUSTOMER_ROUTES_ROLES = ["customer_rates", "charge_profiles", "charge_groups"];

export const DUAL_TRANSACTION_MESSAGES = {
  ASSIGN_DRIVER : "If you assign this driver",
  REMOVE_DRIVER : "If you unassign this driver",
  ASSIGN_LOAD : "If you assign this load",
  UPDATE_ADDRESS : "If you update this load address",
  UPDATE_LOAD_ASSIGN_DATE : "If you update this load assign date",
  VOID_OUT : "If you void out event",
  ADD_NEW_EVENT : "If you add new event",
  RESET_LOAD : "If you reset this load",
}

export const SEPARATE_WITH = {
  SPACE: "SPACE",
  UNDER_SCORE: "UNDER_SCORE"
}
 export const containerStatusOption = [
  { label: "Not Found", value: "Not Found" },
  { label: "On Vessel", value: "On Vessel" },
  { label: "Not Available", value: "Not Available" },
  { label: "Available", value: "Available" },
  { label: "Outgated", value: "Outgated" },
  { label: "Terminated", value: "Terminated" },
]

export const customsTypeOption = [
  {label: "Customs Ref", value: "Customs Ref"},
  {label: "T1 / NCTS", value: "T1 / NCTS"},
  {label: "Export MRN", value: "Export MRN"}
]

export const ZipCodeRegex =
  /^(?![A-Z\d]{8,})(?=[A-Z\d]*\d)(?:(?![A-Z\d]{3}$)[A-Z\d]{3,4}\s?[A-Z\d]{0,4}|([A-Z\d]{5,7}|\d{5}(?:-\d{4})?|[A-Z]\d[A-Z] \d[A-Z]\d|\d{5} \d{4}|\d{4} \d{4}|\d{3}|[A-Z]{1,2}\d[A-Z\d]?\d[A-Z]{2}|\d[A-Z]{2,3}\d|[A-Z]\d{1,2} \d[A-Z]{2}|[A-Z\d]{4}))$/

export const Organizations = "Organizations"
export const Organization = "Organization"
export const People = "People"
export const createSource = {
  BROKER_TENDER: "BROKER-TENDER"
}

export const YARD_CHECK_STATUS = [
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED",
  "HOOKCONTAINER_DEPARTED",
]

export const TRIP_TYPES = {
  FREE_FLOW: 'FREEFLOW',
  COMBINED: 'COMBINED',
  SM_SYSTEM: 'SM_SYSTEM',
  SM_MANUAL: 'SM_MANUAL',
  DUAL_TRANSACTION: 'DUAL_TRANSACTION'
}