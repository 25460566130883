import moment from 'moment';
import { useEffect, useState } from 'react';
import { getStorage } from 'services';
import { downloadCsvFromBuffer, formatTimeZoneString, getDataForChartFromDB } from '../../helper';
import CounterCard from '../Common/CounterCard';
import Popup from '../Popup';
import ListReport from './ListReport';

const CustomListTable = (props) => {
    const { startDate, endDate, reportType } = props
    const [listTableData, setListTableData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isPopup, setIsPopup] = useState(false)
    const [fromDate, setFromDate] = useState(startDate)
    const [toDate, setToDate] = useState(endDate)

    const getDataListTable = async (limit, from, to, isDownload = false) => {
        try {
            if(!isDownload) setIsLoading(true)

            const _startDate = from || fromDate
            const _endDate = to || toDate

            let payload = {
                chartType: reportType,
                fromDate: _startDate,
                toDate: _endDate
            }
            if(limit) payload.limit = limit
            if (isDownload) payload.isDownload = true;

            let responseData = await getDataForChartFromDB(payload, reportType)
            
            if(!isDownload) setListTableData(responseData)
            setIsLoading(false);

            return responseData;
        } catch (error) {
            console.log("🚀 ~ getDataListTablt ~ error:", error)
            setIsLoading(false)
            setListTableData({})
        }
    }

    const downloadCSV = async () => {
        const csvBuffer = await getDataListTable(0, startDate, endDate, true)
        downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`)
    }

    useEffect(() => {
        getDataListTable(10)
    }, [])

    useEffect(() => {
        if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate))) return
        setFromDate(startDate)
        setToDate(endDate)
        getDataListTable(10, startDate, endDate)
    }, [startDate, endDate])

    return (
        <>
            <CounterCard {...{...props,...listTableData}} setIsPopup={setIsPopup} downloadCSV={downloadCSV} />
            <ListReport {...{...props,...listTableData}} isLoading={isLoading} handleOnOpen={() => setIsPopup(true)} />
            {!isLoading && <div className='text-gray-300 font-12 font-weight-normal d-flex justify-content-end mt-10 pb-10 pr-20'>
                Last updated at: {`${moment(listTableData?.lastUpdatedAt || new Date())
                    .tz(getStorage("timeZone")).format('MM-DD-YY • hh:mm A')} (${formatTimeZoneString(getStorage("timeZone"))})`}
            </div>}
            {isPopup &&
                <Popup
                    rows={listTableData.reportData}
                    downloadCSV={downloadCSV}
                    reportType={props?.reportType}
                    isPopup={isPopup}
                    handleOnClose={() => setIsPopup(false)}
                    fromDate={startDate}
                    toDate={endDate}
                />
            }
        </>
    )
}

export default CustomListTable