import _ from "lodash";
import { getStorage, isFreeFlowOn, isMultiContainerOn } from "../../../../services/Common.services";
import { HTTP } from "../../../../services/Http.service";
import { REQUIRED_TRIP_PARAMS, TRIP_ORDER_PARAMS } from "./constants";
import jsonToQueryParams from "Components/Common/jsonToQueryParams";

export const getDriverPlannerData = async (
  startDate,
  endDate,
  timeZone,
  skip,
  limit,
  driverIds,
  sort,
  selectedLocations,
  isAssignedFilterApplied,
  selectedTerminal,
  driverTags,
  isTruck = false,
  isUpcoming = false,
  filterParams,
  isBoardFilter=false,
  searchTerm= '',
  isNotSendToApp=false,
  isSendToApp=false,
  isAssigned=false
) => {
  return new Promise(async (resolve) => {
    try {

      let endPoint = 'driverPlanner';

      let obj = {
        startDate:startDate,
        endDate:endDate,
        timeZone:timeZone,
        skip:skip,
        limit:limit,
        sort: sort ?? 1,
        showTruck: isTruck,
        upcomingMove: isUpcoming,
        isAssigned
      };

      if (driverIds && isAssignedFilterApplied) {
        obj.driverIds = driverIds ?? null;
      }
      if (selectedLocations) {
        obj.selectedLocations=selectedLocations ?? null;
      }
      if(selectedTerminal?.[0]){
        selectedTerminal = selectedTerminal.map(el=>el.value)
        obj.terminals = selectedTerminal
      }
      if(driverTags?.length){
        obj.driverTags = driverTags
      }
      if (isBoardFilter) {
        obj = {
          ...obj,
          ...filterParams,
          isBoardFilter
        }
      }
      if (searchTerm?.length > 0) {
        obj.searchTerm = searchTerm;
      }
      if (isUpcoming && typeof isNotSendToApp === 'boolean') {
        obj.isNotSendToApp = isNotSendToApp
      }
      if (isUpcoming && typeof isSendToApp === 'boolean') {
        obj.isSendToApp = isSendToApp
      }
      const bearerToken = getStorage("token");
      const header = {
        Authorization: bearerToken,
      };

      const response = await HTTP("post", endPoint, obj, header);

      if (response.status === 200) {
        const data = response.data;
        return resolve([data, null]);
      } else {
        return resolve([
          null,
          new Error("Unable to retrieve driver itinerary"),
        ]);
      }
    } catch (e) {
      console.error(e);
      return resolve([null, e]);
    }
  });
  
};

export const fetchAssignedUnassignedDrivers = async (
  startDate,
  endDate,
  timeZone,
  driverIds,
  selectedTerminal,
  driverTags,
  isTruck = false,
  isUpcoming = false,
  _boardFilterParams = null,
  isBoardFilter=false,
  isAssigned=false
) => {
  return new Promise(async (resolve) => {
    try {
      let endPoint = `fetchAndUpdateAssignedUnassignedDriverCount?startDate=${startDate}&endDate=${endDate}&showTruck=${isTruck}&upcomingMove=${isUpcoming}&timeZone=${timeZone}&skip=0&limit=0&isAssigned=${isAssigned}`;
      if (driverIds) {
        endPoint = endPoint + `&driverIds=${encodeURIComponent(JSON.stringify(driverIds))?? null}`;
      }
      if(selectedTerminal?.[0]){
        selectedTerminal = selectedTerminal.map(el=>el.value)
        endPoint =endPoint+`&terminals=${encodeURIComponent(JSON.stringify(selectedTerminal))}`
      }
      if(driverTags?.length){
        endPoint = endPoint+`&driverTags=${encodeURIComponent(JSON.stringify(driverTags))}`
      }
      if (_boardFilterParams && isBoardFilter) {
        endPoint = endPoint + `&${jsonToQueryParams(_boardFilterParams)}&isBoardFilter=${isBoardFilter}`
      }
      const bearerToken = getStorage("token");
      const header = {
        Authorization: bearerToken,
      };

      const response = await HTTP("get", endPoint, null, header);

      if (response.status === 200) {
        const data = response.data;
        return resolve([data, null]);
      } else {
        return resolve([
          null,
          new Error("Unable to retrieve driver itinerary"),
        ]);
      }
    } catch (e) {
      console.error(e);
      return resolve([null, e]);
    }
  });
};

export const getDriverPlannerSearchData = async (
  startDate,
  endDate,
  timeZone,
  skip,
  limit,
  searchTerm,
  selectedTerminal,
  isTruck,
  isUpcoming,
  _boardFilterParams,
  isBoardFilter=false,
  isAssigned=false
) => {
  return new Promise(async (resolve) => {
  try {
      let endPoint = `driverPlanner/search?startDate=${startDate}&endDate=${endDate}&showTruck=${isTruck}&upcomingMove=${isUpcoming}&timeZone=${timeZone}&skip=${skip}&limit=${limit}&isAssigned=${isAssigned}`;
      if (searchTerm) {
        endPoint = endPoint + `&searchTerm=${encodeURIComponent(searchTerm)}`;
      }
      if(selectedTerminal?.[0]){
        selectedTerminal = selectedTerminal.map(el=>el.value)
        endPoint =endPoint+`&terminals=${encodeURIComponent(JSON.stringify(selectedTerminal))}`
      }
      if (_boardFilterParams && isBoardFilter) {
        endPoint = endPoint + `&${jsonToQueryParams(_boardFilterParams)}&isBoardFilter=${isBoardFilter}`
      }
      const bearerToken = getStorage("token");
      const header = {
        Authorization: bearerToken,
      };

      const response = await HTTP("get", endPoint, null, header);

      if (response.status === 200) {
        const data = response.data;
        return resolve([data, null]);
      } else {
        return resolve([
          null,
          new Error("Unable to retrieve driver itinerary"),
        ]);
      }
    } catch (e) {
      console.error(e);
      return resolve([null, e]);
    }
  });
};
export function getFiteredLoads(params) {
  let url = "driverPlanner/getUnassignedLoads";
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        const loads = [];
        result.data.data.forEach((obj) => {
          loads.push({
            value: obj.reference_number,
            label: obj.reference_number,
            allInfos: obj,
          });
        });
        resolve({loads, maxDriverOrder: result.data.other?.maxDriverOrder});
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUnassignedLoadsCount(params) {
  let url = "driverPlanner/getUnassignedLoadsCount";
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAvailableCount(params) {
  let url = "driverPlanner/getAvailableCount";
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDriverById(id) {
  let url = `carrier/getDriverById/${id}`;
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      url,
      {},
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function assignLoadToDriver({
  reference_number,
  driverId,
  loadAssignedDate,
  priority,
  skipValidation
}) {
  let url = "driverPlanner/assignLoadToDriver";
  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      {
        reference_number,
        userId: driverId,
        loadAssignedDate,
        priority,
        skipValidation
      },
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateLoadAssignedDate({
  reference_number,
  loadAssignedDate,
  userId,
  driverOrders,
  priority = 0,
  isTrip
}) {
  let url = "driverPlanner/updateLoadAssignedDate";

  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      {
        reference_number,
        userId: userId,
        loadAssignedDate,
        driverOrders,
        priority,
        ...((isFreeFlowOn()||isMultiContainerOn())&&{isTrip})
      },
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function reassignAndUpdateLoadAssignedDate({
  reference_number,
  loadAssignedDate,
  oldDriverUserId,
  newDriverUserId,
  driverOrders,
  priority = 0,
  isTrip,
}) {
  let url = "driverPlanner/reassignAndUpdateLoadAssignedDate";

  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      {
        reference_number,
        loadAssignedDate,
        oldDriverUserId,
        newDriverUserId,
        driverOrders,
        priority: priority ?? 0,
        ...((isFreeFlowOn()||isMultiContainerOn())&&{isTrip})
      },
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateDriverLoadPriority({
  userId,
  referenceNumbersWithPriority,
  referenceNumberToUpdate,
  priority = 0,
}) {
  let url = "driverPlanner/updateDriverLoadPriority";

  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      {
        userId,
        referenceNumbersWithPriority,
        referenceNumberToUpdate,
        priority: priority ?? 0,
      },
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function swapDriverLoadPriority({
  userId,
  referenceNumbersWithPriority,
  referenceNumberToUpdate,
  priority = 0,
}) {
  let url = "driverPlanner/swapDriverLoadPriority";

  return new Promise((resolve, reject) => {
    HTTP(
      "post",
      url,
      {
        userId,
        referenceNumbersWithPriority,
        referenceNumberToUpdate,
        priority: priority ?? 0,
      },
      {
        authorization: getStorage("token"),
      }
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDriverPlannerHeaders() {
  let url = 'user/getDriverPlannerHeaders';
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
        resolve(result.data)
    })
    .catch((error) => {
        reject(error);
    });
  })
}

export function setDriverPlannerHeaders(params) {
  let url = 'user/setDriverPlannerHeaders';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
          resolve(result.data)
      })
      .catch((error) => {
          reject(error);
      });
  })
}

export function createCombineTrip(params) {
  let url = 'combine-trip/create';
  let reqParams = _.pick(params,REQUIRED_TRIP_PARAMS)
  reqParams.tripOrder.forEach((move,index)=>{
    reqParams.tripOrder[index] = _.pick(move,TRIP_ORDER_PARAMS);
  }) 
  return new Promise((resolve, reject) => {
      HTTP('post', url, reqParams, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
          resolve(result.data)
      })
      .catch((error) => {
          reject(error);
      });
  })
}
export function updateCombineTripMoves(params) {
  let url = 'combine-trip/update-moves';
  let reqParams = _.pick(params,REQUIRED_TRIP_PARAMS)
  reqParams.tripOrder.forEach((move,index)=>{
    reqParams.tripOrder[index] = _.pick(move,TRIP_ORDER_PARAMS);
  }) 
  return new Promise((resolve, reject) => {
      HTTP('put', url, reqParams, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
          resolve(result.data)
      })
      .catch((error) => {
          reject(error);
      });
  })
}
export function getPendingMoves(data) {
  let url = 'routing/getPendingMoves';
  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
          resolve(result.data)
      })
      .catch((error) => {
          reject(error);
      });
  })
}
export function removeTrip(params){
  let url = 'combine-trip/discard';
  return new Promise((resolve, reject) => {
      HTTP('put', url, params, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
          resolve(result.data)
      })
      .catch((error) => {
          reject(error);
      });
  })
}

export const getCabotageConfig = (driverId) => {
  let url = `cabotage-points/get-latest-truck-cabotage?driverId=${driverId}`
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
        resolve(result.data)
    })
    .catch((error) => {
        reject(error);
    });
  })
}
export function createDualTrip(params) {
  let url = 'dual-transaction/create';
  let reqParams = _.pick(params,REQUIRED_TRIP_PARAMS)
  reqParams.tripOrder.forEach((move,index)=>{
    reqParams.tripOrder[index] = _.pick(move,TRIP_ORDER_PARAMS);
  }) 
  return new Promise((resolve, reject) => {
      HTTP('post', url, reqParams, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
          resolve(result.data)
      })
      .catch((error) => {
          reject(error);
      });
  })
}

export function removeDualTransaction(params) {
  let url = 'dual-transaction/remove';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token'),
      })
      .then((result) => {
          resolve(result.data)
      })
      .catch((error) => {
          reject(error);
      });
  })
}