import jsonToQueryParams, { objToQueryParams } from "Components/Common/jsonToQueryParams";
import { getStorage, isEmbeddedEmailEnabled, nylasAccountId } from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";
import { isEmbeddedEmailConnected } from "./helper";
import config from '../../../config';

export const getCachedEmails = async (params) => {
    let url = `${nylasAccountId()}/v1/embedded-email/email/cache`;
    // url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired })
        })
        .catch((error) => {
            console.log("Error while fetching cached data : ", error)
            // reject(error);
        });
    })
}

export function getEmailFromThreadId(params) {
    // need to change later with BE call
    let url = `${nylasAccountId()}/v1/embedded-email/email/by-threadId`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            const res = result?.data?.data
            resolve(res?.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getEmails(params) {
    // need to change later with BE call
    let url = `${nylasAccountId()}/v1/embedded-email/email`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, pageToken: res?.nextCursor ?? "", apiCallRequired: res?.apiCallRequired })
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function clearCachedInboxMails() {
    // need to change later with BE call
    let url = `${nylasAccountId()}/v1/embedded-email/email/clear-inbox-cache`;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            resolve(result)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getAllEmails(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/all-email`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                const res = result?.data?.data
                resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function getDraftEmails(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/draft`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
export function createDraftEmail(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/draft-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function updateDraftEmail(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/draft-email`
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function deleteDraftEmail(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/draft-email`
    return new Promise((resolve, reject) => {
        HTTP('delete', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getLabels(params) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/label`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}
export function getFolders() {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/folder`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function createLabel(params) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/label`
        return new Promise((resolve, reject) => {
            HTTP('post', url, params, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function deleteLabel(labelId) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/delete-label/${labelId}`;
        return new Promise((resolve, reject) => {
            HTTP('delete', url, null, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function createFolder(params) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/folder`
        return new Promise((resolve, reject) => {
            HTTP('post', url, params, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function deleteFolder(folderId) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/delete-folder/${folderId}`;
        return new Promise((resolve, reject) => {
            HTTP('delete', url, null, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function sendMail(payload, isDraft = false) {
    // need to change later with BE call
    let url = isDraft ? `${nylasAccountId()}/v1/embedded-email/draft` : `${nylasAccountId()}/v1/embedded-email/send-email`;
    const auth = {
        "Accept":"application/json",
        "Content-Type":"application/json"
    }
    auth.Authorization = getStorage('token')
    return new Promise((resolve, reject) => {
        HTTP('post', url, payload, auth, null)
        .then((result) => {
            const res = result?.data?.data
            resolve(res)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function uploadFiles(params) {
    let fullUrl = `${nylasAccountId()}/v1/embedded-email/upload-file`;
    return new Promise((resolve, reject) => {
        HTTP('post', fullUrl, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function authenticateEmbeddedEmail(params) {
    let url = 'v1/embedded-email-auth/generate-auth-url';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                if (result)
                    resolve(result.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function exchangeMailBoxToken(params) {
    let url = 'v1/embedded-email-auth/exchange-mailbox-token';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                if (result)
                    resolve(result.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function archiveEmail(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/add-to-archive`
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            const res = result?.data?.data
            resolve(res)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function forwardEmail(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/forward-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function replyEmail(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/reply-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getCounts() {
    const url = `${nylasAccountId()}/v1/embedded-email/count`
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result?.data?.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getSentEmails(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/sent`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
          })
          .catch((error) => {
            reject(error);
          });
      })
  }

export function getEmailByLabel(labelName, params) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-by-label/${labelName}`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
        }).catch((error) => {
            reject(error);
        });
    })
}

export function connectToLoads(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/connect-to-load`;
      return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function getSingleEmail(id, isReply, skipMiddleWare, isRetrieveFromNylas) {
    let query = isReply ? `?isReply=true` : ''
    let url = `${nylasAccountId()}/v1/embedded-email/email-detail/${id}`;
    const params = {
        isRetrieveFromNylas
    };
    if(skipMiddleWare) params.skipMiddleWare = skipMiddleWare;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data?.data
            resolve(res)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function getSignleDraft(id) {
    let url = `${nylasAccountId()}/v1/embedded-email/draft-detail/${id}`;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data?.data
            resolve(res)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function getEmailByLoad(params, reference_number) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-by-load/${reference_number}`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        if(!isEmbeddedEmailConnected()) return resolve([])
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getEmailByLoadId(params, loadId) {
    let url = `${nylasAccountId()}/v1/embedded-email/loads-by-id/${loadId}`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getEmailByQuoteId(params, quoteNumber) {
    let url = `${nylasAccountId()}/v1/embedded-email/quote/get-emails-by-quote-number?quoteNumber=${quoteNumber}`;
    url = params ? url + "&&" + jsonToQueryParams(params) : url;

    return new Promise((resolve, reject) => {
        if(!isEmbeddedEmailEnabled()) return resolve([]);
        
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getTotalEmailCountByQuoteId(params, quoteId) {
    let url = `${nylasAccountId()}/v1/embedded-email/quote/get-emails-count-by-quote-number?quoteNumber=${quoteId}`;
    url = params ? url + "&&" + jsonToQueryParams(params) : url;

    return new Promise((resolve, reject) => {
        if(!isEmbeddedEmailEnabled()) return resolve({});
        
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getLoadsByEmailId(emailId, param = {}) {
    let url = `${nylasAccountId()}/v1/embedded-email/ref-by-id/${emailId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res?.load)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function disconnectToLoads(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-to-load`;
      return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function disconnectToQuotes(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-to-quote`;
      return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function toggleMarkAsUnRead(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/mark-as-unread`;
      return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data.data
            resolve(res)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function getEmailPeople(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-people`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function addLabelsToEmail(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/add-label-to-email`
    return new Promise((resolve, reject) => {
        HTTP('put', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function removeLabelsFromEmail(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/remove-label-from-email`
    return new Promise((resolve, reject) => {
        HTTP('put', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function removeEmail(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-email-account`;
      return new Promise((resolve, reject) => {
        HTTP('delete', url, params, {
          'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
          }).catch((error) => {
            reject(error);
          });
      })
}

export function reconnectEmail(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/reconnect-email-account`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function disconnectEmail(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-email-account`;
      return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
          'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
          }).catch((error) => {
            reject(error);
          });
      })
}

export function getAllMailUnreadCount() {
    let url = `${nylasAccountId()}/v1/embedded-email/all-connected-emails-count`;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function changeDefaultEmail(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/change-default-mail`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function changeEmailNameForPesonal(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/change-email-name-personal`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function customerEmployeeUploadFile(params) {
    let url = 'tms/uploadfile';
    return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
        'authorization': getStorage('token'),
    })
        .then((result) => {
        if (result)
            resolve(result.data)
        })
        .catch((error) => {
        reject(error);
        });
    })
  }

export function getAttachment(fileId, param, messageId) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-attachment/${fileId}/${messageId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getAttachmentsByThread(param, messageId) {
    param.skipMiddleWare = !isEmbeddedEmailConnected()
    let url = `${nylasAccountId()}/v1/embedded-email/email-attachment/${messageId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getContact(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/contact`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function moveEmail(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/move-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function toggleStarredEmail(params) {
  return new Promise((resolve, reject) => {
    HTTP("put", `${nylasAccountId()}/v1/embedded-email/star`, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        const res = result?.data?.data
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function draftLoadEmailInfo(id) {
    const isEmailConnected = isEmbeddedEmailConnected() ?? {}
    const params = {}
    if(!isEmailConnected){
        params.skipMiddleWare = true
    }
    let url = `${nylasAccountId()}/v1/embedded-email/draft-load-emails/${id}`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("get", url , null, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}
export function updateDraftLoadInfo(id, data) {
    return new Promise((resolve, reject) => {
        HTTP("post", `${nylasAccountId()}/v1/embedded-email/update-draft-load/${id}`, data, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function reviewTenderLoad(draftLoadId) {
    return new Promise((resolve, reject) => {
        HTTP("post", `${nylasAccountId()}/v1/embedded-email/review-tender/${draftLoadId}`, {}, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getLoadsByThreadId(threadId, param = {}) {
    let url = `${nylasAccountId()}/v1/embedded-email/ref-by-id/${threadId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getEmailViews(){
    let url = 'email-view/get-views'
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getCompanyEmailViews(){
    let url = 'email-view/get-company-views'
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function deleteEmailView(params) {
    let url = 'email-view/delete-view';
    return new Promise((resolve, reject) => {
        HTTP('delete', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function createEmailView(params) {
    let url = 'email-view/create-view';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function editEmailView(params) {
    let url = 'email-view/edit-view';
    return new Promise((resolve, reject) => {
        HTTP('PATCH', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getEmailByFilters(params = {}) {
    return new Promise((resolve, reject) => {
        let url = `${nylasAccountId()}/v1/embedded-email/email-by-filter`;
        url = params ? url + "?" + jsonToQueryParams(params) : url;
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function getSubCardCounts(params = {}) {
  return new Promise((resolve, reject) => {
      let url = `${nylasAccountId()}/v1/embedded-email/sub-card-count`;
      url = params ? url + "?" + jsonToQueryParams(params) : url;
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getTopBarCounts(params = {}) {
  return new Promise((resolve, reject) => {
      let url = `${nylasAccountId()}/v1/embedded-email/email-count`;
      url = params ? url + "?" + jsonToQueryParams(params) : url;
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getEmailStatus(payload) {
    return new Promise((resolve, reject) => {
    const url = `${nylasAccountId()}/v1/embedded-email/status`;
    if(!isEmbeddedEmailConnected()) payload.skipMiddleWare = true
    if(payload?.threadIds) payload.threadIds= payload?.threadIds?.filter(Boolean)
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getChangedLoads(params) {
    return new Promise((resolve, reject) => {
    const url = `${nylasAccountId()}/v1/embedded-email/load-changes`;
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateLoadChangeStatus(params) {
    return new Promise((resolve, reject) => {
    const url = `${nylasAccountId()}/v1/embedded-email/load-changes-status`;
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function makeLoadReadyForPickup(params) {
    return new Promise((resolve, reject) => {
    const url = `tms/makeLoadReadyForPickup`;
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateEmailStatus(payload) {
    const url = `${nylasAccountId()}/v1/embedded-email/status`;

    if(!isEmbeddedEmailConnected()) payload.skipMiddleWare = true

    return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllEmailSignature() {
    let url = `${nylasAccountId()}/v1/embedded-email/get-signature?`;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data);
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getUpdateEmailSignature(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/update-signature`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}


export function deleteEmailSignature(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/delete-signature`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function updateAccountInfo(payload) {
    const url = `${nylasAccountId()}/v1/embedded-email/update-account-info`;
    return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function convertMailBox(payload) {
    const url = `${nylasAccountId()}/v1/embedded-email/convert-mailbox`;
    return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getFoldersInfo(params) {
    if (!params?.folderIds || params?.folderIds?.length === 0) return [];
    const url = `${nylasAccountId()}/v1/embedded-email/folders-info`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function createSignature(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/signature`
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getEmailInsights(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-insights`
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        })
        .then((result) => {
            resolve(result?.data?.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function getThreadSummary(params) {
    if(!isEmbeddedEmailConnected()) params.skipMiddleWare = true
    let url = `${nylasAccountId()}/v1/embedded-email/thread-summary`
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getEmailAssignees = (threadId) => {
    let url = `${nylasAccountId()}/v1/embedded-email/assignee?threadId="${threadId}"`
    if(!isEmbeddedEmailConnected()) url = `${url}&skipMiddleWare=true`
    return new Promise((resolve, reject) => {
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const addEmailAssignee = (params) => {
    let url = `${nylasAccountId()}/v1/embedded-email/assignee`
    return new Promise((resolve, reject) => {
        HTTP("post", url, params, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const removeEmailAssignee = (params) => {
    let url = `${nylasAccountId()}/v1/embedded-email/assignee`
    return new Promise((resolve, reject) => {
        HTTP("put", url, params, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getMainFolderList() {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/main-folders`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function signatureImageUpload(data) {
  let url = `${config.newDocUrl}emailImageUpload`
  return new Promise((resolve, reject) => {
    HTTP('post', null, data, {
      'authorization': getStorage('token'),
    },url)
      .then((result) => {
        resolve(result?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function convertUrlToBuffer(obj) {
    let url = 'tms/convert-url-to-buffer';
    url = obj ? url + "?" + objToQueryParams(obj) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

export function getReplyEmailTemplate(){
    try {
        const url = "notification-templates/get-notification-template-by-rule?ruleName=TENDER"
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function getEmailTemplateValue(params) {
    let url = `tender-reply`
    return new Promise((resolve, reject) => {
        HTTP("post", url, params, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function updateAccountConfig(params) {
    let url = `v1/embedded-email-auth/update-account-config`
    return new Promise((resolve, reject) => {
        HTTP("post", url, params, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function userAccountInfo() {
    try {
        const url = `v1/embedded-email-auth/user-account-info`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function getEmailsUnreadCountFromRedis() {
    let url = `${nylasAccountId()}/v1/embedded-email/email-unread-count-from-redis`;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}