import React, { PureComponent, Component } from "react";
import ReactTooltip from "react-tooltip";
import {
    IconAngleArrowDown,
    IconMinus,
} from "../../../Components/Common/Icons/index";
import {
    convertUnit, types, Currency, isGlobalizationEnabled, isNewBillingEnabled, checkUserPermisison, getStorage,
} from "../../../services/Common.services";
import SelectCustomComponent from "../SelectComponent";
import NewPriceInput from "../../../Components/Common/PriceInput/NewPriceInput";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import { newSmallSelectStyle } from "../../../assets/js/select-style";
import { NumberFormatterV2 } from "../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import SelectCustomCurrency from "../SelectCurrency"
class ExpenseComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEdited: false,
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (
            this.state.isEdited &&
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.setState({ isEdited: false });
            if(this.state.selectedCell) return 
            this.props.updateRowExpense(this.props.index);
        }
    }

    onEnter(e,index) {
        if(e.keyCode === 13||e.keyCode === 9) {
            e?.target?.blur()
            this.props.unselectCell()
            if(!this.state.isEdited)return
            this.props.updateRowExpense(index);
        }
    }
    render() {
        let { index, charges, selectedCell,unselectCell, nameColor, expenseChanges,chargeValue,chargeCodeList, item, name, disabled, conflictedCharge, showSave, addExpense, DeletePriceRow,selectCell, keyPressOnNumberInput, redirectToRates, isAPICall, isSuccess, updateRowExpense,rowIndex, disableDriverExpenseEditAccess = false} = this.props;
        let success = rowIndex === index && isSuccess;
        return(
            <tr 
                key={index} 
                style={{opacity: `${addExpense ? '0.2' : ""}`, transition: 'background .4s ease-out'}}
                className={`cell-${index}-1 select-custom-input ${success ? 'background-success' : ''} ${(addExpense || disableDriverExpenseEditAccess) ? "disabled-pointer-events":""}`}
                onKeyDown={(e) => { this.setState({ isEdited: false }); this.onEnter(e, index); }}
                ref={this.setWrapperRef}
            >
                <td className={`${(disabled || disableDriverExpenseEditAccess) ? "disable-input" : ""} px-1 pl-2
                            ${selectedCell === `cell-${index}-1` ? 'newdriverrow td-clicked' : ""}
                            `}
                    style={{ transition: 'background .4s ease-out' }}
                    onClick={() => !disableDriverExpenseEditAccess && selectCell(`cell-${index}-1`)}
                >
                    <div className="d-flex justify-content-between align-items-center min-width-100">
                        {chargeValue?.chargeName.toUpperCase() || <span className="text-muted">Select Status</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-1` &&
                            <SelectCustomComponent
                                name='charges'
                                searchable={true}
                                refClassName={`cell-${index}-1`}
                                listData={charges}
                                keyName="chargeName"
                                onSelect={(name, value) => {
                                    expenseChanges(index, {
                                        target: { value: value.value, name: "name" },
                                    })
                                    updateRowExpense(index);
                                    unselectCell()
                                }}
                                unselectCell={unselectCell}
                            />}
                    </div>
                </td>

                <td 
                    className={`w-200 cell-${index}-chargeset ${(disabled || disableDriverExpenseEditAccess) ? "disable-input" : ""} px-1 pl-2
                        ${selectedCell === `cell-${index}-chargeset` ? 'newdriverrow td-clicked' : ""}`
                    }
                    style={{ transition: 'background .4s ease-out' }}
                    onClick={() => !disableDriverExpenseEditAccess && selectCell(`cell-${index}-chargeset`)}
                >
                    <div className="d-flex justify-content-between align-items-center min-width-100">
                        {item?.charge_reference_number?.toUpperCase() || <span className="text-muted">Select</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-chargeset` &&
                            <SelectCustomComponent
                                name='chargeSets'
                                searchable={true}
                                refClassName={`cell-${index}-chargeset`}
                                keyName="chargeName"
                                onSelect={(name, value) => {
                                    expenseChanges(index, {
                                        target: { value, name: "charge_reference_number" },
                                    })
                                    updateRowExpense(index);
                                    unselectCell()
                                }}
                                unselectCell={unselectCell}
                                listData={this.props.chargeSetReferenceNumbersList ?? []}
                            />}
                    </div>
                </td>

                <td className={`${(disabled || disableDriverExpenseEditAccess) ? "disable-input":''} px-0
                 ${selectedCell === `cell-${index}-2` ? 'newdriverrow td-clicked':""}
                `}
                  onClick={() => !disableDriverExpenseEditAccess && selectCell(`cell-${index}-2`)}>
                    <div className="custom-select-border min-width-100">
                    <input
                        type="text"
                        className="form-control border-0"
                        name="description"
                        value={item.description || ""}
                        onChange={(e) => {
                            this.setState({ isEdited: true });
                            expenseChanges(index, e)
                        }}
                        placeholder="Add description..."
                        disabled={disabled}
                        autoComplete="off"
                        onBlur={()=>unselectCell()}
                        />
                    </div>
                </td>
                {item.name === "Fuel" ? (
                    <td className={`cell-${index}-3 ${(disabled || disableDriverExpenseEditAccess) && "disable-input"} px-0 ${[`A1-${index}-3`,`A2-${index}-3`,`f-${index}-3`].includes(selectedCell) && 'newdriverrow td-clicked'}`}
                    // onClick={() => selectCell(`cell-${index}-3`)}
                    >
                    <div className={`d-flex min-width-200 custom-select-border justify-content-between`}>
                    <div className="d-flex align-items-center justify-content-center w-100" onClick={() => !disableDriverExpenseEditAccess && selectCell(`A1-${index}-3`)}>
                        {/* <div className="mr-1 d-flex w-100"> */}
                            {!isGlobalizationEnabled() && <input
                                className="form-control border-0 text-right"
                                type="number"
                                disabled={disabled || disableDriverExpenseEditAccess}
                                name="amount"
                                min={0}
                                value={item.amount || ""}
                                onChange={(e) => {
                                    this.setState({ isEdited: true });
                                    expenseChanges(index, e);
                                }}
                                autoComplete="off"
                                onBlur={()=>unselectCell()}
                                onFocus={(e)=>e.target.select()}
                            />}
                            {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                    selected={[`A1-${index}-3`,`A2-${index}-3`,`f-${index}-3`].includes(selectedCell)}
                                    className="form-control border-0 text-right"
                                    childClassName="form-control border-0 text-right"
                                    type="number"
                                    disabled={disabled || disableDriverExpenseEditAccess}
                                    name="amount"
                                    min={0}
                                    value={item.amount || ""}
                                    onChange={(e) => {
                                        this.setState({ isEdited: true });
                                        expenseChanges(index, e);
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>unselectCell()}
                                    onFocus={(e)=>e.target.select()}
                                    showCurrency={false}
                                    autoFocus={false}
                                />
                            )}
                        </div>
                        {/* <div className="mr-1 d-flex w-100 custom-select-border justify-content-center price-component">
                            <CustomSelect
                                size="small"
                                placement="right"
                                placeholder="Select..."
                                style={newSmallSelectStyle}
                                options={[
                                    {
                                        value: "fixed",
                                        label: `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                    },
                                    {
                                        value: "percentage",
                                        label: "%",
                                    },
                                ]}
                                value={
                                    item.type && item.type === "fixed"
                                        ? {
                                            value: "fixed",
                                            label: `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                        }
                                        : {
                                            value: "percentage",
                                            label: "%",
                                        }
                                }
                                onChange={({ value }) => {
                                    this.setState({ isEdited: true });
                                    expenseChanges(index, {
                                        target: {
                                        value,
                                        name: "type",
                                        },
                                    })
                                    unselectCell()
                                }}
                                isSearchable={false}
                                onBlur={()=>unselectCell()}
                            />
                        </div> */}
                        <div className="d-flex justify-content-between align-items-center min-width-50 line-height-30 pointer" onClick={() => !disableDriverExpenseEditAccess && selectCell(`f-${index}-3`) }>
                        <div className="transition-y2">{item.type && item.type === "fixed" ? `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}` : "%"}</div>
                        <div className="ml-20"><IconAngleArrowDown /></div>
                        {selectedCell===`f-${index}-3` &&
                                <SelectCustomCurrency
                                    name='value'
                                    searchable={false}
                                    refClassName={`cell-${index}-3`}
                                    listData={[
                                        {
                                            value: "fixed",
                                            label: `${this.props?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                        },
                                        {
                                            value: "percentage",
                                            label: "%",
                                        },
                                    ]}
                                    keyName="label"
                                    onSelect={(name, value ) =>{
                                        this.setState({type: value.value, isEdited: true });
                                        expenseChanges(index, {
                                            target: {
                                                value: value.value,
                                                name: "type",
                                            },
                                        })
                                        // updateRowPricing(index)
                                        unselectCell()                                    
                                    }}
                                    unselectCell={unselectCell}
                                /> 
                            }
                        </div>
                        <div className="d-flex w-100" onClick={() => selectCell(`A2-${index}-3`)}>
                            {!isGlobalizationEnabled() && <input
                                placeholder= "0.00"
                                min={0}
                                onKeyDown={(e) => {
                                    keyPressOnNumberInput(e);
                                }}
                                className="form-control border-0 text-right"
                                type="number"
                                disabled={disabled || disableDriverExpenseEditAccess}
                                name="finalAmount"
                                value={item.finalAmount || ""}
                                onChange={(e) => {
                                    this.setState({ isEdited: true });
                                    expenseChanges(index, e)
                                }}
                                autoComplete="off"
                                onBlur={()=>unselectCell()}
                            />}
                            {isGlobalizationEnabled() && (
                                <NumberFormatterV2 
                                    selected={[`A1-${index}-3`,`A2-${index}-3`,`f-${index}-3`].includes(selectedCell)}
                                    placeholder= "0.00"
                                    min={0}
                                    onKeyDown={(e) => {
                                        keyPressOnNumberInput(e);
                                    }}
                                    className="form-control border-0 text-right"
                                    childClassName="form-control border-0 text-right"
                                    type="number"
                                    disabled={disabled || disableDriverExpenseEditAccess}
                                    name="finalAmount"
                                    value={item.finalAmount || ""}
                                    onChange={(e) => {
                                        this.setState({ isEdited: true });
                                        expenseChanges(index, e)
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>unselectCell()}
                                    autoFocus={false}
                                    showCurrency={false}
                                />
                            )}
                        </div>
                    </div>
                    </td>
                ) : (
                    <td className={`${(disabled || disableDriverExpenseEditAccess) ? "disable-input":""} px-0 ${selectedCell === `cell-${index}-3` ? 'newdriverrow td-clicked':""}`}
                        onClick={() => selectCell(`cell-${index}-3`)}>
                        <div className="custom-select-border min-width-190">
                            <span data-tip data-for={`discount${index}`}>
                                {!isGlobalizationEnabled() && <input
                                    min={0}
                                    placeholder="0.00"
                                    type="number"
                                    className={`form-control text-right ${conflictedCharge
                                        ? "input-border-red"
                                        : (item.discountApplied ? "border-2 border-primary rounded":"")} border-0`}
                                    disabled={disabled || disableDriverExpenseEditAccess}
                                    name="finalAmount"
                                    value={item.finalAmount || ""}
                                    onChange={(e) => {
                                        this.setState({ isEdited: true });
                                        expenseChanges(index, e)
                                    }}
                                    autoComplete="off"
                                    onBlur={() => unselectCell()}
                                />}
                                {isGlobalizationEnabled() && (
                                    <NumberFormatterV2 
                                        selected={selectedCell === `cell-${index}-3`}
                                        min={0}
                                        placeholder="0.00"
                                        type="number"
                                        className={`form-control text-right ${conflictedCharge
                                            ? "input-border-red"
                                            : (item.discountApplied ? "border-2 border-primary rounded":"")} border-0`}
                                        childClassName={`form-control text-right ${conflictedCharge
                                            ? "input-border-red"
                                            : (item.discountApplied ? "border-2 border-primary rounded":"")} border-0`}
                                        disabled={disabled || disableDriverExpenseEditAccess}
                                        name="finalAmount"
                                        value={item.finalAmount || ""}
                                        onChange={(e) => {
                                            this.setState({ isEdited: true });
                                            expenseChanges(index, e)
                                        }}
                                        autoComplete="off"
                                        onBlur={() => unselectCell()}
                                        showCurrency={false}
                                    />
                                )}
                            </span>
                            {item.discountApplied &&
                                <ReactTooltip id={`discount${index}`}>
                                    <span>A discount has been applied</span>
                                </ReactTooltip>
                            }
                        </div>
                    </td>
                )}
                <td className={`${(disabled || disableDriverExpenseEditAccess) && "disable-input"} px-0 ${selectedCell === `cell-${index}-4` && 'newdriverrow td-clicked'}`}
                    onClick={() => !disableDriverExpenseEditAccess && selectCell(`cell-${index}-4`)}
                >
                    <div className="custom-select-border min-width-100">
                        <NewPriceInput
                        name="unit"
                        value={item.unit}
                        onChange={(val) => {
                            this.setState({ isEdited: true });
                            expenseChanges(index, {
                                target: {
                                value: val,
                                name: "unit",
                                },
                            });
                        }}
                        disabled={disabled || disableDriverExpenseEditAccess}
                        onBlur={()=>unselectCell()}
                        selected={selectedCell === `cell-${index}-4`}
                    />
                    </div>
                </td>
    
                <td className={`option-box-${index} ${(disabled || disableDriverExpenseEditAccess) ? "disable-input":""} px-1 pl-2
                  ${selectedCell===`cell-${index}-5` ? 'newdriverrow td-clicked':""}
                `}
                    onClick={() => !disableDriverExpenseEditAccess && selectCell(`cell-${index}-5`)}>
                    <div className="d-flex justify-content-between align-items-center min-width-100">
                        {item?.perType || <span className="text-muted">Select...</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-5` &&
                            <SelectCustomComponent
                                name='perType'
                                searchable={true}
                                refClassName={`option-box-${index}`}
                                listData={options}
                                keyName="label"
                                onSelect={(name, i) => {
                                    expenseChanges(index, {
                                        target: {
                                        value:i.value,
                                        name: "perType",
                                        },
                                    })
                                    updateRowExpense(index);
                                    unselectCell()
                                }
                                }
                                unselectCell={unselectCell}
                            />}
                    </div>
                </td>
                <td className={`${((!item.perType && !success) || disabled || disableDriverExpenseEditAccess) ? 'disable-input' : ""} px-0
                    ${selectedCell === `cell-${index}-6` ? 'newdriverrow td-clicked' : ""}`}
                    onClick={() => selectCell(`cell-${index}-6`)}>
                    <div className="custom-select-border min-width-100">
                        {!isGlobalizationEnabled() && <input
                            min={0}
                            type="number"
                            className="form-control text-right border-0"
                            name="freeDays"
                            onKeyDown={(e) => {
                                keyPressOnNumberInput(e);
                            }}
                            disabled={!item.perType || disabled || disableDriverExpenseEditAccess}
                            value={item.freeDays || ""}
                            onChange={(e) => {
                                this.setState({ isEdited: true });
                                item.perType && expenseChanges(index, e);
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={() => unselectCell()}
                        />}
                        {isGlobalizationEnabled() && (
                            <NumberFormatterV2 
                                selected={selectedCell===`cell-${index}-6`}
                                min={0}
                                type="number"
                                className="form-control text-right border-0"
                                childClassName="form-control text-right border-0"
                                name="freeDays"
                                onKeyDown={(e) => {
                                    keyPressOnNumberInput(e);
                                }}
                                disabled={!item.perType || disabled || disableDriverExpenseEditAccess}
                                value={item.freeDays || ""}
                                onChange={(e) => {
                                    this.setState({ isEdited: true });
                                    item.perType && expenseChanges(index, e);
                                }}
                                placeholder="0"
                                autoComplete="off"
                                onBlur={() => unselectCell()}
                                showCurrency={false}
                                disablePlaceholder={((!item.perType && !success) || disabled)}
                            />
                        )}
                    </div>
                </td>
    
                <td className={`${((!item.perType && !success) || disabled || disableDriverExpenseEditAccess) ? 'disable-input':""} px-0
                 ${selectedCell===`cell-${index}-7` ? 'newdriverrow td-clicked':""}
                `}
                 onClick={() => !disableDriverExpenseEditAccess && selectCell(`cell-${index}-7`)}>
                    <div className="custom-select-border min-width-100">
                    {!isGlobalizationEnabled() && <input
                        min={0}
                        type="number"
                        className="form-control text-right border-0"
                        name="chargePerDay"
                        onKeyDown={(e) => {
                            keyPressOnNumberInput(e);
                        }}
                        disabled={!item.perType || disabled || disableDriverExpenseEditAccess}
                        value={item.chargePerDay  || ""}
                        onChange={(e) => {
                            this.setState({ isEdited: true });
                            item.perType && expenseChanges(index, e);
                        }}
                        placeholder="0"
                        autoComplete="off"
                        onBlur={()=>unselectCell()}
                    />}
                    {isGlobalizationEnabled() && (
                        <NumberFormatterV2 
                            selected={selectedCell===`cell-${index}-7`}
                            min={0}
                            type="number"
                            className="form-control text-right border-0"
                            childClassName="form-control text-right border-0"
                            name="chargePerDay"
                            onKeyDown={(e) => {
                                keyPressOnNumberInput(e);
                            }}
                            disabled={!item.perType || disabled || disableDriverExpenseEditAccess}
                            value={item.chargePerDay  || ""}
                            onChange={(e) => {
                                this.setState({ isEdited: true });
                                item.perType && expenseChanges(index, e);
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={()=>unselectCell()}
                            showCurrency={false}
                        />
                    )}
                    </div>
                </td>
                {showSave && (
                    <td className={`${disabled ? "disable-input":''}`}>
                    <button
                        className="btn btn-xs btn-circle bg-soft-danger"
                        disabled={disabled || disableDriverExpenseEditAccess}
                        onClick={() => !isAPICall && !disableDriverExpenseEditAccess && DeletePriceRow(index)}
                    >
                        <IconMinus className="text-danger" />
                    </button>
                    </td>
                )}
            </tr>
        )
    }
}
export default ExpenseComponent;

const options = types;

  const CHARGE_TYPES = {
    CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES'},
    DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING'},
    SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED'},
    MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED'},
    PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES'},
    ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES'},
    CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES'},
  };