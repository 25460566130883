import React, { useEffect, useRef, useState } from "react";

import _, { get } from "lodash";
import useBoxPosition from "../../CustomHooks/useBoxPosition";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IconBurgerExapand, IconBurgerInner } from "../Icons";
import { getDriverAndCarrierName, isBargeVendorEnabled } from "services";
import { VENDOR_TYPES } from "pages/tms/DrayosCarrier/constants";

import { createPortal } from "react-dom";
import { isManageFleetOrOutSource } from "../../../services";
import { useOnClickOutside } from "hooks";

import { TrackingProvider } from "pages/trucker/Tracking/context/TrackingContext";
import DriverCard from "./DriverCard";



const DriverList = (props) => {
  const { CellRef, drivers, onChange, onClose, value, customStyle = {}, Row, setShowViolatedModal } = props;
  const { isDriversCardVide } = useSelector((state) => state.TmsReducer, shallowEqual);
  const [dList, setDList] = useState([]);
  const [cursor, setCursor] = useState(-1);
  const [searchDriver, setSearchDriver] = useState(null);
  const [cardView, setCardView] = useState(isDriversCardVide?.cardView);
  const [defaultOption, setDefaultOption] = useState(isDriversCardVide?.filterType || "ALL");

  const boxRef = useRef();
  const dispatch = useDispatch();
  const searchInputLLRef = useRef(null);
  const filterType = {
    ...(isManageFleetOrOutSource().isManageFleet ? { DRIVER: "DRIVER" } : {}),
    ...(isManageFleetOrOutSource().isOutSource ? {
      CARRIER: VENDOR_TYPES.CARRIER,
      ...(isBargeVendorEnabled() ? { BARGE: VENDOR_TYPES.BARGE } : {}),
    } : {}),
  };
  const Style = useBoxPosition(CellRef, boxRef, Object.keys(filterType).length > 1 ? 375 : 350, cardView);


  useEffect(() => {
    fetchDriverUser();
  }, [searchDriver, cardView, drivers]);

  useEffect(() => {

    let element = document.querySelector(".credit-info-modal");
    if (element) {
      element.removeAttribute('tabindex');
    } else {
      document.querySelector(".modal")?.removeAttribute('tabIndex');
      const modalElement = document.querySelector(".modal")
      if(modalElement){
        modalElement.style.overflow = "unset";
      }
    }
    searchInputLLRef?.current?.focus();
    document.getElementById("root").style.pointerEvents = "none";
    return () => {
      document.getElementById("root").style.pointerEvents = "auto";
      if (element) {
        element.setAttribute('tabindex', -1);
      } else {
        document.querySelector(".modal")?.setAttribute("tabIndex", -1);
        const modalElement = document.querySelector(".modal")
        if(modalElement){
          modalElement.style.overflow = "";
        }
      }

    };
  }, []);

  useOnClickOutside(boxRef, () => {
    onClose(false);
    dispatch({
      type: "UPDATE_DRIVERS_CARD_VIDE",
      payload: {
        filterType: defaultOption,
        cardView: cardView,
      },
    });
  });

  const fetchDriverUser = () => {
    let vendorOptions = drivers || [];
    if (searchDriver) {
      vendorOptions = vendorOptions.filter((el) => {
        let data =
          el?.allInfos?.role === "driver"
            ? `${el?.allInfos?.name} ${el?.allInfos?.lastName || ""}`
            : el?.allInfos?.company_name;
        return (
          data.toLowerCase().indexOf(searchDriver.toLowerCase().trim()) != -1 ||
          (el?.allInfos?.driver?.truck?.equipmentID
            ? el?.allInfos?.driver?.truck?.equipmentID.toLowerCase()
            : ""
          ).indexOf(searchDriver.toLowerCase().trim()) != -1
        );
      });
    }

    let FinalDrivers = vendorOptions?.map((d) => {
      return {
        isDisabled: d?.allInfos?.disableValue,
        ...d,
      };
    });

    setDList(FinalDrivers);
  };


  const handleKeyDown = (event) => {
    if(["ArrowDown","ArrowUp"].includes(event.key)){
      event.preventDefault();
      const ref = dList?.[cursor - 1]?._id;
      const myElement = document.querySelector(`.Ref${ref}`);
      if(ref && myElement){
        myElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
    if (event.key === 'ArrowDown') {
      setCursor((prevCursor) =>
        prevCursor < dList.length - 1 ? prevCursor + 1 : 0
      );
    } else if (event.key === 'ArrowUp') {
      setCursor((prevCursor) => (prevCursor > 0 ? prevCursor - 1 : dList.length - 1));
    } else if (event.key === 'Enter' && cursor >= 0) {
      const selectedDriver = dList[cursor];
      if (!selectedDriver.isDisabled) {
        onClose(false);
        onChange(selectedDriver);
      }
    }else if(event.key === 'Escape'){
      onClose(false);
    }else{
      searchInputLLRef?.current?.focus();
    }
  };


  useEffect(() => {
    if (cursor >= 0) {
      const card = document.getElementById(`driver-${cursor}`);
      card?.focus();
    }
  }, [cursor]);
 

  return createPortal(
    <TrackingProvider contextType="drivers">
      <div className="dlist__card mb-1 shadow-5" ref={boxRef}  style={{ ...Style, left: `calc(${Style?.left} - 8px)`, width: cardView ? "450px" : "300px", zIndex: 1051, ...customStyle }} tabIndex="1" onKeyDown={handleKeyDown}>
        <div>
          {Object.keys(filterType).length > 1 && (
            <div className="px-10 pt-2">
              <ul className="nav nav-compact nav-justified text-nowrap flex-nowrap border-0 flex-nowrap" role="tablist">
                {Object.entries({ ALL: "All", ...filterType }).map(([key, label]) => (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${defaultOption === key ? "active" : ""}`}
                      data-bs-toggle="tab"
                      role="tab"
                      onClick={() => setDefaultOption(key)}
                    >
                      {`${label?.charAt(0)}${label?.slice(1)?.toLowerCase()}`}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="d-flex align-items-center p-10 flex-nowrap">
            <div className="app-search  px-2 rounded-5 flex-grow-1">
              <input
                className="input-search-left form-control py-2"
                placeholder={`Search ${getDriverAndCarrierName()}...`}
                ref={searchInputLLRef}
                // id="domTextElement"
                type="search"
                onChange={(e) => {
                  setSearchDriver(e.target.value);
                  setCursor(null);
                }}
              />
              <span className="search-icon left-auto"></span>
            </div>
            <ul className="nav nav-compact border-0 gap-5 ml-1 flex-nowrap">
              <li className={`nav-link px_3 pointer ${cardView ? "" : "active"}`} onClick={() => setCardView(false)}>
                <IconBurgerExapand />
              </li>
              <li className={`nav-link px_3 pointer ${cardView ? "active" : ""}`} onClick={() => setCardView(true)}>
                <IconBurgerInner />
              </li>
            </ul>
          </div>
        </div>
        <div className="hr-light my-0"></div>

        {dList.filter((e) =>
          defaultOption === "ALL" ? e : (e?.allInfos?.role || e?.allInfos?.vendorType)?.toUpperCase() === defaultOption
        )?.length === 0 ? (
          <p className="p-10 mb-0 text-center">No {defaultOption === "ALL" ? getDriverAndCarrierName()?.toLowerCase() : defaultOption?.toLowerCase()} available</p>
        ) : (
          <div className="p-10">
             <div className="overflow-y-auto pr-1 custom-scrollbar-sm overflow-x-hidden" style={{ maxHeight: "250px" }}  >
              {dList
                .filter((e) => (defaultOption === "ALL" ? e : (e?.allInfos?.role || e?.allInfos?.vendorType)?.toUpperCase() === defaultOption))
                .map((el, index) => (
                  <DriverCard
                    key={index}
                    value={value}
                    driver={el}
                    onClose={onClose}
                    cardView={cardView}
                    onChange={onChange}
                    index={index}
                    cursor={cursor}
                    setCursor={setCursor}
                    Row={Row}
                    setShowViolatedModal={setShowViolatedModal}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </TrackingProvider>,
    document.getElementById("pp-overlay-container")
  );
};

export default DriverList;
