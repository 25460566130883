const activePathIcon = window.L.divIcon({
  html: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" class="text-primary">
    <g filter="url(#filter0_d_54562_146164)">
      <circle cx="13" cy="12" r="10" fill="white" />
      <circle cx="13" cy="12" r="8.5" stroke="white" stroke-width="3" />
    </g>
    <circle cx="13" cy="12" r="4.5" fill="white" stroke="currentColor" stroke-width="3" />
    <defs>
      <filter
        id="filter0_d_54562_146164"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.203922 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_54562_146164" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_54562_146164" result="shape" />
      </filter>
    </defs>
  </svg>`,
  iconSize: [24, 24],
  className: "customLeaflet-icon",
});

const inActivePathIcon = window.L.divIcon({
  html: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" class="text-gray-200">
    <g filter="url(#filter0_d_54562_146164)">
      <circle cx="13" cy="12" r="10" fill="white" />
      <circle cx="13" cy="12" r="8.5" stroke="white" stroke-width="3" />
    </g>
    <circle cx="13" cy="12" r="4.5" fill="white" stroke="currentColor" stroke-width="3" />
    <defs>
      <filter
        id="filter0_d_54562_146164"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.203922 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_54562_146164" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_54562_146164" result="shape" />
      </filter>
    </defs>
  </svg>`,
  iconSize: [24, 24],
  className: "customLeaflet-icon",
});

const terminalIcon = (className) => {
  return window.L.divIcon({
  html: `<div class="trm-warehouse terminalIcon">
  <svg viewbox="0 0 10 11" width="10" height="11" fill="none"><path fill="#394E66" fill-rule="evenodd" d="M0 10.758V3.782L5 0l5 3.782v6.976H0Zm4.135-3.897h1.73v2.647H8.75V4.403L5 1.567 1.25 4.403v5.105h2.885V6.861Z" clip-rule="evenodd"/></svg></div>`,
  iconSize: [24, 24],
  className: `customLeaflet-icon ${className && className}`,
});
}

const IconCheckedPoint = window.L.divIcon({
  html: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_54562_146161)">
  <circle cx="13" cy="12" r="10" fill="white"/>
  <circle cx="13" cy="12" r="8.5" stroke="white" stroke-width="3"/>
  </g>
  <rect x="5" y="4" width="16" height="16" rx="8" fill="#A3B3C6"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.503 9.61973L12.2144 15.2781L9.79883 12.8626L10.7039 11.9575L12.1284 13.3819L15.5197 8.80029L16.503 9.61973Z" fill="white"/>
  <defs>
  <filter id="filter0_d_54562_146161" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="1.5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.203922 0 0 0 0.2 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_54562_146161"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_54562_146161" result="shape"/>
  </filter>
  </defs>
  </svg>`,
  iconSize: [24, 24],
  className: "customLeaflet-icon",
});

export { activePathIcon, inActivePathIcon, IconCheckedPoint, terminalIcon };

