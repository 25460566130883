import AES from "crypto-js/aes";
import Utf8 from 'crypto-js/enc-utf8'
import moment from "moment";
import { DateTimeFormatUtils, isNewBillingEnabled, isNewSupportMovesEnabled, toastr } from "../../services/Common.services";
import _ from "lodash";
import { HTTP } from "../../services/Http.service";
import { getStorage } from "../../services/Common.services";
import { browserHistory } from "react-router"
import { AFTER_COMPLETE_STATUS, AuditTypeEnum, loadEvents, tmsEndMoveEvents } from "../../pages/tms/constant";

const Buffer = require('buffer/').Buffer

export function beautifyDate(date, formatType) {
  return moment(new Date(date)).format(formatType);
}

export function getDistanceFromLatLonMile(lat1, lon1, lat2, lon2) {
  const deg2rad = function deg2rad(deg) {
    return deg * (Math.PI / 180);
  };
  var R = 3959; // Radius of the earth in mile
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in mile
  return d;
}

export function sortShipmentsToClosetPickupTime(shipments) {
  var diffdate = new Date();

  let sortedShipmetns = shipments.sort(function (a, b) {
    var distancea = Math.abs(diffdate - new Date(a.pickupDateTimeFrom));
    var distanceb = Math.abs(diffdate - new Date(b.pickupDateTimeFrom));
    if (
      new Date(a.pickupDateTimeFrom) < diffdate &&
      new Date(b.pickupDateTimeFrom) >= diffdate
    ) {
      return -1;
    }
    if (
      new Date(a.pickupDateTimeFrom) >= diffdate &&
      new Date(b.pickupDateTimeFrom) < diffdate
    ) {
      return 1;
    }
    return distancea - distanceb; // sort a before b when the distance is smaller
  });
  return sortedShipmetns;
}

export function copyToClipboard(_dataString) {
  document.addEventListener("copy", function (e) {
    if (_dataString !== null) {
      try {
        e.clipboardData.setData("text/plain", _dataString);
        e.preventDefault();
      } finally {
        toastr.show("Copied to your clipboard!", "success");
        _dataString = null;
      }
    }
  });
  document.execCommand("copy");
}

export const objCycle = {
  "70_8": "USA . 70 Hour / 8 Day",
  "60_7": "USA . 60 Hour / 7 Day",
  "80_8": "California . 80 Hour / 8 Day",
  tx_70_7: "Texas 70 Hour / 7 Day",
  ak_70_7: "Alaska 70 Hour / 7 Day",
  ak_80_8: "Alaska 80 Hour / 8 Day",
  "70_7": "Canada South 70 Hour / 7 Day",
  "120_14": "Canada South 120 Hour / 14 Day",
  canada_oil: "Canada South Oil and Gas",
  "80_7": "Canada North 80 Hour / 7 Day (Cycle 1)",
  "120_14_north": "Canada North 120 Hour / 14 Day (Cycle 2)",
  Other: "Other",
};

export function hourDifference(startdate, enddate) {
  let difference = moment(enddate).diff(startdate, "minutes");
  let h = Math.floor(difference / 60);
  let m = difference % 60;
  if (m == 59) {
    h += 1;
    m = 0;
  }
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  return h + ":" + m;
}

export function isoToUTCDate(isodate, time = null) {
  if (time != null)
    return new Date(
      moment(new Date(isodate))
        .add(time, "seconds")
        .utc()
        .format(DateTimeFormatUtils.fullDateTimeFormat())
    );

  return new Date(
    moment(new Date(isodate)).utc().format(DateTimeFormatUtils.fullDateTimeFormat())
  );
}

export function getOnedayErrors(currentDay, nextDay) {
  let allErrors = [];
  let combinedLog = [];
  let isNeedToConsiderInPrevDay = true;
  var total_DriveHoursInDay = 0;
  var total_OnHoursInDay = 0;
  currentDay.logs.forEach((_log, k) => {
    if (["ON", "D"].indexOf(_log.status) > -1) {
      _log["combinedStatus"] = "ON-D";
    } else {
      _log["combinedStatus"] = "OFF-SL";
    }
    combinedLog.push(_log);
  });
  if (nextDay && nextDay.logs) {
    nextDay.logs.forEach((_log, k) => {
      let lastAddedLog = combinedLog[combinedLog.length - 1];
      if (["ON", "D"].indexOf(_log.status) > -1) {
        _log["combinedStatus"] = "ON-D";
      } else {
        _log["combinedStatus"] = "OFF-SL";
      }
      if (lastAddedLog) {
        if (_log.combinedStatus == lastAddedLog.combinedStatus) {
          combinedLog.push(_log);
        }
      }
    });
  }

  let consiCutiveON_D_Hours = 0;
  let partialOFFSLBreak = 0;

  combinedLog.forEach((log, k) => {
    let endDateTime = moment(log.day).tz("UTC").endOf("day")._d;
    if (
      log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    let duration = moment(log.timeEnd || endDateTime).diff(
      log.timeStart,
      "minutes"
    );
    if (log.combinedStatus == "ON-D") {
      consiCutiveON_D_Hours = consiCutiveON_D_Hours + duration;
    } else {
      partialOFFSLBreak += duration;
      if (partialOFFSLBreak >= 30) {
        consiCutiveON_D_Hours = 0;
        partialOFFSLBreak = 0;
      }
    }
    if (consiCutiveON_D_Hours > 8 * 60) {
      allErrors.push("Break Limit Exceeded");
    }
  });

  combinedLog.forEach((_log, k) => {
    let endDateTime = moment(_log.day).tz("UTC").endOf("day")._d;
    if (
      _log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    let duration = moment(_log.timeEnd || endDateTime).diff(
      _log.timeStart,
      "minutes"
    );
    if (_log.status == "ON") {
      total_OnHoursInDay += duration;
    }
    if (_log.status == "D") {
      total_DriveHoursInDay += duration;
    }
  });

  if (total_DriveHoursInDay > 11 * 60) {
    allErrors.push("Exceeded 11 Hours Driving Limit");
  }
  if (total_OnHoursInDay + total_DriveHoursInDay > 14 * 60) {
    allErrors.push("Exceeded 14 Hours Shift Limit");
  }
  if (currentDay.day != moment().tz("UTC").startOf("day").toISOString()) {
    if (currentDay.reviewLogs == undefined) {
      allErrors.push("Review Logs Form is not filled up");
    } else {
      if (currentDay.reviewLogs.signature == undefined) {
        allErrors.push("Logs are not Signed");
      }
    }
  }
  var uniqueErrors = allErrors.filter((v, i, a) => a.indexOf(v) === i);
  return uniqueErrors;
}

export function getOneDayCycleLogs(allLogs, day) {
  let matchFound = false;
  let combinedLog = [];
  allLogs.forEach((dayLog, k) => {
    if (!matchFound) {
      matchFound = dayLog.day === day;
    }
    if (matchFound) {
      dayLog.logs.forEach((_row) => {
        const _log = Object.assign({}, _row);
        if (["ON", "D"].indexOf(_log.status) > -1) {
          _log.combinedStatus = "ON-D";
        } else {
          _log.combinedStatus = "OFF-SL";
        }
        combinedLog.push(_log);
      });
    }
  });
  combinedLog = combinedLog.sort((a, b) => {
    return moment(b.day)._d - moment(a.day)._d;
  });
  let continousOFFSLMinutes = 0;
  let lastIndex = -1;
  combinedLog.forEach((log, k) => {
    let endDateTime = moment(log.day).tz("UTC").endOf("day")._d;
    if (
      log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    const duration = moment(log.timeEnd || endDateTime).diff(
      log.timeStart,
      "minutes"
    );
    if (log.combinedStatus === "ON-D") {
      continousOFFSLMinutes = 0;
    } else {
      continousOFFSLMinutes += duration;
    }
    if (continousOFFSLMinutes >= 10 * 60 && lastIndex === -1) {
      lastIndex = k;
    }
  });
  return combinedLog.splice(0, lastIndex + 1);
}

export function getOnedayCycleErrors(cycleLogs, currentDay) {
  const allErrors = [];
  const isNeedToConsiderInPrevDay = true;
  var total_DriveHoursInDay = 0;
  var total_OnHoursInDay = 0;

  let consiCutiveON_D_Hours = 0;
  let partialOFFSLBreak = 0;

  cycleLogs.forEach((log, k) => {
    let endDateTime = moment(log.day).tz("UTC").endOf("day")._d;
    if (
      log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    const duration = moment(log.timeEnd || endDateTime).diff(
      log.timeStart,
      "minutes"
    );
    if (log.combinedStatus == "ON-D") {
      consiCutiveON_D_Hours += duration;
    } else {
      partialOFFSLBreak += duration;
      if (partialOFFSLBreak >= 30) {
        consiCutiveON_D_Hours = 0;
        partialOFFSLBreak = 0;
      }
    }
    if (consiCutiveON_D_Hours >= 8 * 60) {
      allErrors.push("Break Limit Exceeded");
    }
  });

  cycleLogs.forEach((_log, k) => {
    let endDateTime = moment(_log.day).tz("UTC").endOf("day")._d;
    if (
      _log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    const duration = moment(_log.timeEnd || endDateTime).diff(
      _log.timeStart,
      "minutes"
    );
    if (_log.status == "ON") {
      total_OnHoursInDay += duration;
    }
    if (_log.status == "D") {
      total_DriveHoursInDay += duration;
    }
  });

  if (total_DriveHoursInDay >= 11 * 60) {
    allErrors.push("Exceeded 11 Hours Driving Limit");
  }
  if (total_OnHoursInDay + total_DriveHoursInDay >= 14 * 60) {
    allErrors.push("Exceeded 14 Hours Shift Limit");
  }
  if (currentDay.day != moment().tz("UTC").startOf("day").toISOString()) {
    if (currentDay.reviewLogs == undefined) {
      allErrors.push("Review Logs Form is not filled up");
    } else if (currentDay.reviewLogs.signature == undefined) {
      allErrors.push("Logs are not Signed");
    }
  }
  var uniqueErrors = allErrors.filter((v, i, a) => a.indexOf(v) === i);
  return uniqueErrors;
}

export const statusColors = {
  CHASSISPICK_ARRIVED: 'normal',
  CHASSISPICK_DEPARTED: 'normal',
  PULLCONTAINER_ARRIVED: 'normal',
  PULLCONTAINER_DEPARTED: 'normal',
  DROPCONTAINER_ARRIVED: 'normal',
  DROPCONTAINER_DEPARTED: 'blue',
  HOOKCONTAINER_ARRIVED: 'normal',
  HOOKCONTAINER_DEPARTED: 'normal',
  DELIVERLOAD_ARRIVED: 'normal',
  DELIVERLOAD_DEPARTED: 'normal',
  RETURNCONTAINER_ARRIVED: 'normal',
  RETURNCONTAINER_DEPARTED: 'normal',
  CHASSISTERMINATION_ARRIVED: 'normal',
  CHASSISTERMINATION_DEPARTED: 'normal',
  STOPOFF_ARRIVED: 'normal',
  STOPOFF_DEPARTED: 'normal',
  FULL_PAID: 'dark-brown',
  PARTIAL_PAID: 'light-brown',
  COMPLETED: 'dark',
  PENDING: 'pending',
  REBILLING: 'brown',
  APPROVED: 'normal',
  UNAPPROVED: 'unapproved',
  BILLING: 'brown',
  AVAILABLE:'green',
  DISPATCHED: 'purple',
  DEPARTED: 'purple',
  PARTIALLY_PAID: 'light-brown',
}

const statusColorsForAR = {
  CHASSISPICK_ARRIVED: 'normal',
  CHASSISPICK_DEPARTED: 'normal',
  PULLCONTAINER_ARRIVED: 'bluesky-100',
  PULLCONTAINER_DEPARTED: 'normal',
  DROPCONTAINER_ARRIVED: 'normal',
  DROPCONTAINER_DEPARTED: 'blue',
  HOOKCONTAINER_ARRIVED: 'normal',
  HOOKCONTAINER_DEPARTED: 'normal',
  DELIVERLOAD_ARRIVED: 'normal',
  DELIVERLOAD_DEPARTED: 'normal',
  RETURNCONTAINER_ARRIVED: 'normal',
  RETURNCONTAINER_DEPARTED: 'LightBlue-100',
  CHASSISTERMINATION_ARRIVED: 'normal',
  CHASSISTERMINATION_DEPARTED: 'normal',
  FULL_PAID: 'dark-brown',
  PARTIAL_PAID: 'light-brown',
  COMPLETED: 'SpanishGray-100',
  PENDING: 'pending',
  REBILLING: 'brown',
  APPROVED: 'normal',
  UNAPPROVED: 'unapproved',
  BILLING: 'brown',
  AVAILABLE:'green',
  DISPATCHED: 'purple',
}

export const driverStatusColors = {
  AVAILABLE:"green-light",
  OFF_DUTY:"normal",
  ON_HOLD:"danger",
  CHASSISPICK_ARRIVED:"normal",
  CHASSISPICK_DEPARTED:"normal",
  PULLCONTAINER_ARRIVED:"normal",
  PULLCONTAINER_DEPARTED:"normal",
  DELIVERLOAD_ARRIVED:"normal",
  DELIVERLOAD_DEPARTED:"normal",
  RETURNCONTAINER_ARRIVED:"normal",
  RETURNCONTAINER_DEPARTED:"normal",
  CHASSISTERMINATION_ARRIVED:"normal",
  CHASSISTERMINATION_DEPARTED:"normal",
  DROPCONTAINER_ARRIVED:"normal",
  DROPCONTAINER_DEPARTED:"normal",
  STOPOFF_DEPARTED:"normal",
  STOPOFF_ARRIVED:"normal",
  LIFTON_ARRIVED:"normal",
  LIFTON_DEPARTED:"normal",
  HOOKCONTAINER_ARRIVED:"normal",
  HOOKCONTAINER_DEPARTED:"normal",
  
  CHASSISTRANSFER_ARRIVED: "purple-light",
  CHASSISTRANSFER_DEPARTED: "purple-light",

  BOBTAILTRANSFER_ARRIVED: "purple-light",
  BOBTAILTRANSFER_DEPARTED: "purple-light",
}

export function convertTMSStatusName(
  type_of_load,
  status = "",
  info,
  isDriverList,
  needColor = false,
  toUpperCase = true,
  convertToComplete = false
) {
  if(isNewBillingEnabled() && convertToComplete) status = AFTER_COMPLETE_STATUS.includes(status) ? "COMPLETED" : status
  let colorStatus = statusColors[status];
  let labelStatus = status;
  const isLiftOnOrOff = [loadEvents.LIFTOFF, loadEvents.LIFTON].includes(info?.driverOrderId?.type);
  
  let loadTypes = ["IMPORT", "EXPORT", "ROAD"];
  if (isNewSupportMovesEnabled()) {
    loadTypes.push("SUPPORT")
  }

  if ( loadTypes.includes(type_of_load)) {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = "Enroute to Chassis";
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = "Arrived to Chassis";
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = type_of_load === "ROAD" ? "Enroute to Pick Up" : "Enroute to Pick Container";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = type_of_load === "ROAD" ? "Arrived at Pick Up" : "Arrived at Pick Container";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift Off" : "Enroute to Drop Container";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift On" : `Enroute to Hook ${type_of_load === "ROAD" ? "Trailer" : "Container"}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Arrived to Lift On" : `Arrived to Hook ${type_of_load === "ROAD" ? "Trailer" : "Container"}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = type_of_load === "EXPORT" ? "Enroute to Get Loaded" : "Enroute to Deliver Load";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = type_of_load === "EXPORT" ? "Arrived at Get Loaded" : "Arrived at Deliver Load";
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      if(info?.preSetOrderStatus === "SHUNT_PULL_RETURN"){
        labelStatus = type_of_load === "EXPORT" ?  "Enroute to Return Empty" : "Enroute to Return Load";
      }else{
        labelStatus = type_of_load === "EXPORT" ?  "Enroute to Return Load" : "Enroute to Return Empty";
      }
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      if(info?.preSetOrderStatus === "SHUNT_PULL_RETURN"){
        labelStatus = type_of_load === "EXPORT" ?  "Arrived to Return Empty" : "Arrived to Return Load";
      }else{
        labelStatus = type_of_load === "EXPORT" ? "Arrived at Return Load" : "Arrived at Return Empty";
      }
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = "Enroute to Return Chassis";
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = "Arrived to Return Chassis";
    }
    if (status === "DISPATCHED") {
      labelStatus = "Dispatched";
    }
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
    if (status === "COMPLETED" && isDriverList) {
      labelStatus = "RETURN";
    }
    if (status === "PENDING" && info && (info.custom || info.freight)) {
      labelStatus = "PENDING";
    }
    if (status === "LIFTOFF_ARRIVED") {
      labelStatus =  "Enroute to Lift Off" ;
    }
    if (status === "LIFTOFF_DEPARTED") {
      labelStatus = "Grounded" ;
    }
    if (status === "LIFTON_ARRIVED") {
      labelStatus = "Enroute to Lift On" ;
    }
    if (status === "LIFTON_DEPARTED") {
      labelStatus = "Arrived to Lift On";
    }
    if (status === 'STOPOFF_DEPARTED') {
      labelStatus = "Arrived at Stop Off"
    }
    if (status === 'STOPOFF_ARRIVED') {
      labelStatus = "Enroute to Stop Off"
    }
    if (status === 'CHASSISTRANSFER_ARRIVED') {
      labelStatus = "Enroute to Chassis Transfer"
    }
    if (status === 'CHASSISTRANSFER_DEPARTED') {
      labelStatus = "Arrived at Chassis Transfer"
    }
    if (['BOBTAILTRANSFER_ARRIVED', "BOBTAIL_STARTED_ARRIVED",'BOBTAIL_ENDED_ARRIVED'] .includes(status)) {
      labelStatus = "Enroute to Bobtail Transfer"
   }
   if (['BOBTAILTRANSFER_DEPARTED', "BOBTAIL_STARTED_DEPARTED", 'BOBTAIL_ENDED_DEPARTED'].includes(status)) {
     labelStatus = "Arrived at Bobtail Transfer"
   }
   if (status === "DROPCHASSIS_ARRIVED") {
      labelStatus = "Enroute to Drop Chassis"
   }
   if (status === "DROPCHASSIS_DEPARTED") {
      labelStatus = "Arrived at Drop Chassis"
   }
  } else if (type_of_load === "BILL_ONLY") {
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
  }
  if (needColor) {
    return {
      status: toUpperCase
        ? labelStatus?.toUpperCase()
        : `${labelStatus.charAt(0).toUpperCase()}${labelStatus
          .slice(1)
          .toLowerCase()}`,
      colorStatus: colorStatus,
    };
  } else {
    return toUpperCase
      ? labelStatus.toUpperCase()
      : `${labelStatus.charAt(0).toUpperCase()}${labelStatus
        .slice(1)
        .toLowerCase()}`;
  }
}
export function convertTMSStatusNameChassis(
  type_of_load,
  status = "",
  info,
  isDriverList,
  convertToComplete = false
) {
  if(isNewBillingEnabled() && convertToComplete) status = AFTER_COMPLETE_STATUS.includes(status) ? "COMPLETED" : status
  let colorStatus = statusColors[status];
  let labelStatus = status;
  const isLiftOnOrOff = [loadEvents.LIFTOFF, loadEvents.LIFTON].includes(info?.driverOrderId?.type);

  let loadTypes = ["IMPORT", "EXPORT", "ROAD"];
  if (isNewSupportMovesEnabled()) {
    loadTypes.push("SUPPORT")
  }

  if (loadTypes.includes(type_of_load)) {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = "Enroute To Chassis";
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = "Arrived To Chassis";
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = type_of_load === "ROAD" ? "Enroute To Pick Up" : "Enroute To Pick Container";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = type_of_load === "ROAD" ? "Arrived At Pick Up" : "Arrived At Pick Container";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute To Lift Off" : "Enroute To Drop Container";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute To Lift On" : `Enroute To Hook ${type_of_load === "ROAD" ? "Trailer" : "Container"}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Arrived To Lift On" : `Arrived To Hook ${type_of_load === "ROAD" ? "Trailer" : "Container"}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = type_of_load === "EXPORT" ? "Enroute To Get Loaded" : "Enroute To Deliver Load";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = type_of_load === "EXPORT" ? "Arrived At Get Loaded" : "Arrived At Deliver Load";
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      if(info?.preSetOrderStatus === "SHUNT_PULL_RETURN"){
        labelStatus = type_of_load === "EXPORT" ?  "Enroute To Return Empty" : "Enroute To Return Load";
      }else{
        labelStatus = type_of_load === "EXPORT" ?  "Enroute To Return Load" : "Enroute To Return Empty";
      }
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      if(info?.preSetOrderStatus === "SHUNT_PULL_RETURN"){
        labelStatus = type_of_load === "EXPORT" ?  "Arrived To Return Empty" : "Arrived To Return Load";
      }else{
        labelStatus = type_of_load === "EXPORT" ? "Arrived At Return Load" : "Arrived At Return Empty";
      }
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = "Enroute To Return Chassis";
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = "Arrived To Return Chassis";
    }
    if (status === "DISPATCHED") {
      labelStatus = "Dispatched";
    }
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
    if (status === "COMPLETED" && isDriverList) {
      labelStatus = "RETURN";
    }
    if (status === "PENDING" && info && (info.custom || info.freight)) {
      labelStatus = "PENDING";
    }
    if (status === "LIFTOFF_ARRIVED") {
      labelStatus =  "Enroute To Lift Off" ;
    }
    if (status === "LIFTOFF_DEPARTED") {
      labelStatus = "Grounded" ;
    }
    if (status === "LIFTON_ARRIVED") {
      labelStatus = "Enroute To Lift On" ;
    }
    if (status === "LIFTON_DEPARTED") {
      labelStatus = "Arrived To Lift On";
    }
    if (status === 'STOPOFF_DEPARTED') {
      labelStatus = "Arrived At Stop Off"
    }
    if (status === 'STOPOFF_ARRIVED') {
      labelStatus = "Enroute To Stop Off"
    }
    if (status === 'CHASSISTRANSFER_ARRIVED') {
      labelStatus = "Enroute at Chassis Transfer"
    }
    if (status === 'CHASSISTRANSFER_DEPARTED') {
      labelStatus = "Arrived at Chassis Transfer"
    }
    if (['BOBTAILTRANSFER_ARRIVED', "BOBTAIL_STARTED_ARRIVED",'BOBTAIL_ENDED_ARRIVED'] .includes(status)) {
      labelStatus = "Enroute to Bobtail Transfer"
    }
    if (['BOBTAILTRANSFER_DEPARTED', "BOBTAIL_STARTED_DEPARTED", 'BOBTAIL_ENDED_DEPARTED'].includes(status)) {
      labelStatus = "Arrived at Bobtail Transfer"
    }
    if (status === "DROPCHASSIS_ARRIVED") {
      labelStatus = "Enroute to Drop Chassis"
    }
    if (status === "DROPCHASSIS_DEPARTED") {
      labelStatus = "Arrived at Drop Chassis"
    }
  } else if (type_of_load === "BILL_ONLY") {
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
  }
    return labelStatus;
}

export function convertTMSStatusNameForAR(
  type_of_load,
  status = "",
  info,
  isDriverList,
  needColor = false,
  toUpperCase = true
) {
  if(isNewBillingEnabled()) status = AFTER_COMPLETE_STATUS.includes(status) ? "COMPLETED" : status
  let colorStatus = statusColorsForAR[status];
  let labelStatus = status;
  const isLiftOnOrOff = [loadEvents.LIFTOFF, loadEvents.LIFTON].includes(info?.driverOrderId?.type);

  if (type_of_load === "IMPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = "Enroute to Chassis";
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = "Arrived to Chassis";
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Pick Container";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Pick Container";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift Off" : "Enroute to Drop Container";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift On" : "Enroute to Hook Container";
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Arrived to Lift On" : "Arrived to Hook Container";
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = "Enroute to Deliver Load";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = "Arrived at Deliver Load";
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = (info?.preSetOrderStatus === "SHUNT_PULL_RETURN") ?  "Enroute to Return Load" : "Enroute to Return Empty";
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = (info?.preSetOrderStatus === "SHUNT_PULL_RETURN") ? "Arrived at Return Load" : "Arrived at Return Empty"
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = "Enroute to Return Chassis";
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = "Arrived to Return Chassis";
    }
    if (status === "DISPATCHED") {
      labelStatus = "Dispatched";
    }
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
    if (status === "COMPLETED" && isDriverList) {
      labelStatus = "RETURN";
    }
    if (status === "PENDING" && info && (info.custom || info.freight)) {
      labelStatus = "PENDING";
    }
    if (status === "LIFTOFF_ARRIVED") {
      labelStatus =  "Enroute to Lift Off" ;
    }
    if (status === "LIFTOFF_DEPARTED") {
      labelStatus = "Grounded" ;
    }
    if (status === "LIFTON_ARRIVED") {
      labelStatus = "Enroute to Lift On" ;
    }
    if (status === "LIFTON_DEPARTED") {
      labelStatus = "Arrived to Lift On";
    }
  } else if (type_of_load === "EXPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = "Enroute to Chassis";
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = "Arrived to Chassis";
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Pick Container";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Pick Container";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift Off" : "Enroute to Drop Container";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift On" : "Enroute to Hook Container";
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Arrived to Lift On" : "Arrived to Hook Container";
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = "Enroute to Get Loaded";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = "Arrived at Get Loaded";
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = (info?.preSetOrderStatus === "SHUNT_PULL_RETURN") ? "Enroute to Return Empty"  : "Enroute to Return Load";
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = (info?.preSetOrderStatus === "SHUNT_PULL_RETURN") ? "Arrived at Return Empty"  : "Arrived at Return Load";
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = "Enroute to Return Chassis";
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = "Arrived to Return Chassis";
    }
   if (status === "FULL_PAID") {
     labelStatus = "Full Paid";
   }
   if (status === "PARTIAL_PAID") {
     labelStatus = "Partial Paid";
   }
   if (status === "LIFTOFF_ARRIVED") {
    labelStatus =  "Enroute to Lift Off" ;
  }
  if (status === "LIFTOFF_DEPARTED") {
    labelStatus = "Grounded" ;
  }
  if (status === "LIFTON_ARRIVED") {
    labelStatus = "Enroute to Lift On" ;
  }
  if (status === "LIFTON_DEPARTED") {
    labelStatus = "Arrived to Lift On";
  }
  } else if (type_of_load === "ROAD") {
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Pick Up";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Pick Up";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift Off" : "Enroute to Drop Trailer";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOnOrOff ? "Enroute to Lift On" : "Enroute to Hook Trailer";
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOnOrOff ? "Arrived to Lift On" : "Arrived to Hook Trailer";
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = "Enroute to Deliver Load";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = "Arrived at Deliver Load";
    }
   if (status === "FULL_PAID") {
     labelStatus = "Full Paid";
   }
   if (status === "PARTIAL_PAID") {
     labelStatus = "Partial Paid";
   }
   if (status === "LIFTOFF_ARRIVED") {
    labelStatus =  "Enroute to Lift Off" ;
  }
  if (status === "LIFTOFF_DEPARTED") {
    labelStatus = "Grounded" ;
  }
  if (status === "LIFTON_ARRIVED") {
    labelStatus = "Enroute to Lift On" ;
  }
  if (status === "LIFTON_DEPARTED") {
    labelStatus = "Arrived to Lift On";
  }
  if (status === 'CHASSISTRANSFER_ARRIVED') {
    labelStatus = "Enroute at Chassis Transfer"
  }
  if (status === 'CHASSISTRANSFER_DEPARTED') {
    labelStatus = "Arrived at Chassis Transfer"
  }
  if (['BOBTAILTRANSFER_ARRIVED', "BOBTAIL_STARTED_ARRIVED",'BOBTAIL_ENDED_ARRIVED'] .includes(status)) {
     labelStatus = "Enroute to Bobtail Transfer"
  }
  if (['BOBTAILTRANSFER_DEPARTED', "BOBTAIL_STARTED_DEPARTED", 'BOBTAIL_ENDED_DEPARTED'].includes(status)) {
    labelStatus = "Arrived at Bobtail Transfer"
  }
  if (status === "DROPCHASSIS_ARRIVED") {
    labelStatus = "Enroute to Drop Chassis"
  }
  if (status === "DROPCHASSIS_DEPARTED") {
    labelStatus = "Arrived at Drop Chassis"
  }
  } else if (type_of_load === "BILL_ONLY") {
     if (status === "FULL_PAID") {
       labelStatus = "Full Paid";
     }
     if (status === "PARTIAL_PAID") {
       labelStatus = "Partial Paid";
     }
  }
  if (needColor) {
    return {
      status: toUpperCase
        ? labelStatus.toUpperCase()
        : `${labelStatus.charAt(0).toUpperCase()}${labelStatus
          .slice(1)
          .toLowerCase()}`,
      colorStatus: colorStatus,
    };
  } else {
    return toUpperCase
      ? labelStatus.toUpperCase()
      : `${labelStatus.charAt(0).toUpperCase()}${labelStatus
        .slice(1)
        .toLowerCase()}`;
  }
}

export function convertDriverStatusName(status,toUpperCase, isFromDriverPlanner){
  const statusColors = { ...driverStatusColors }
  if (isFromDriverPlanner) {
    statusColors.AVAILABLE = 'bg-brand-600'
    statusColors.OFF_DUTY = 'bg-gray-200'
    statusColors.ON_HOLD = 'bg-danger-500'
  }

  let colorStatus = statusColors[status];
  let labelStatus = status;
    if (status === 'AVAILABLE') {
      labelStatus = "Available"
    }
    else if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = "Enroute To Chassis";
    }
    else if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = "Arrived To Chassis";
    }
    else if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = "Enroute To Pick Container";
    }
    else if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = "Arrived At Pick Container";
    }
    else if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = "Enroute to Deliver Load";
    }
    else if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = "Arrived at Deliver Load";
    }
    else if (status === "RETURNCONTAINER_ARRIVED") {
        labelStatus = "Enroute to Return Empty";
    }
    else if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Return Empty";
    }
    else if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = "Enroute to Return Chassis";
    }
    else if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = "Arrived to Return Chassis";
    }
    else if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = "Enroute To Drop Container";
    }
    else if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = "Dropped";
    }
    else if (status === "LIFTON_ARRIVED") {
      labelStatus = "Enroute To Lift On" ;
    }
    else if (status === "LIFTON_DEPARTED") {
      labelStatus = "Arrived To Lift On";
    }
    else if (status === 'STOPOFF_DEPARTED') {
      labelStatus = "Arrived At Stop Off"
    }
    else if (status === 'STOPOFF_ARRIVED') {
      labelStatus = "Enroute To Stop Off"
    }
    else if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Hook Container";
    }
    else if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = "Arrived to Hook Container";
    }
    else if(status==="OFF_DUTY"){
      labelStatus = "Off duty";
    }
    else if(status==="ON_HOLD"){
      labelStatus = "On hold";
    }
    if (status === 'CHASSISTRANSFER_ARRIVED') {
      labelStatus = "Enroute to Chassis Transfer"
    }
    if (status === 'CHASSISTRANSFER_DEPARTED') {
      labelStatus = "Arrived at Chassis Transfer"
    }
    if (['BOBTAILTRANSFER_ARRIVED', "BOBTAIL_STARTED_ARRIVED",'BOBTAIL_ENDED_ARRIVED'] .includes(status)) {
      labelStatus = "Enroute to Bobtail Transfer"
    }
    if (['BOBTAILTRANSFER_DEPARTED', "BOBTAIL_STARTED_DEPARTED", 'BOBTAIL_ENDED_DEPARTED'].includes(status)) {
      labelStatus = "Arrived at Bobtail Transfer"
    }
    if (status === "DROPCHASSIS_ARRIVED") {
      labelStatus = "Enroute to Drop Chassis"
    }
    if (status === "DROPCHASSIS_DEPARTED") {
      labelStatus = "Arrived at Drop Chassis"
    }
    return {
      status: toUpperCase
        ? labelStatus.toUpperCase()
        : `${labelStatus.charAt(0).toUpperCase()}${labelStatus
          .slice(1)
          .toLowerCase()}`,
      colorStatus: colorStatus,
    };
}

export function statusOrderAddressFormat(obj, address) {
  if (address && obj && (obj.customerId || obj.locationId)) {
    if (obj.customerId) {
      address = `${obj.customerId.company_name}, ${address}`;
    } else if (obj.locationId) {
      address = `${obj.locationId.company_name}, ${address}`;
    }
  }
  return address;
}

export function deepClone(nestedObject) {
  const value = _.cloneDeep(nestedObject);
  // const value = Object.assign({}, nestedObject);
  // const value = JSON.parse(JSON.stringify(nestedObject));;
  return value;
}

export function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}



export function isEmptyOrLoaded(info, index = -1){
  let status;
  let customer;
  let LoadedIndex = -1;

  if (info?.driverOrderId) {
    let activeDriverOrderId, prevDriverOrderId;
    const indexOfActiveEvent = info.driverOrder?.findIndex((d) => d._id === info?.driverOrderId?._id);
    info.driverOrder.forEach((_routeEvent, indx) => {
      if (indx === indexOfActiveEvent) activeDriverOrderId = _routeEvent;
      if (_routeEvent.type === 'DELIVERLOAD' && indx < indexOfActiveEvent) prevDriverOrderId = _routeEvent;
    })

    if (
      activeDriverOrderId &&
      [
        "DROPCONTAINER_DEPARTED",
        "HOOKCONTAINER_ARRIVED",
        "HOOKCONTAINER_DEPARTED",
      ].indexOf(info.status) > -1
    ) {
      if (["EXPORT", "IMPORT"].indexOf(info.type_of_load) > -1) {
        if (activeDriverOrderId.prevType === "PULLCONTAINER") {
          status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
        } else if (
          activeDriverOrderId.prevType === "DELIVERLOAD" &&
          activeDriverOrderId.customerId &&
          prevDriverOrderId?.customerId &&
          prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
        ) {
          status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
        } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
          if (info.isReadyForPickup) {
            status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
          } else {
            status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
          }
        }
      } else if (["ROAD"].indexOf(info.type_of_load) > -1) {
        if (activeDriverOrderId.prevType === "PULLCONTAINER") {
          status = "loaded";
        } else if (
          activeDriverOrderId.prevType === "DELIVERLOAD" &&
          activeDriverOrderId.customerId &&
          prevDriverOrderId?.customerId &&
          prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
        ) {
          status = "empty";
        } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
          status = "loaded";
        }
      }
    }
    if (
      activeDriverOrderId &&
      activeDriverOrderId.customerId &&
      activeDriverOrderId.customerId.company_name
    ) {
      customer = activeDriverOrderId.customerId.company_name;
    }
  }
  if (window.location.search === "?pickup") {
    if (status === "Loaded" && LoadedIndex === -1) {
      LoadedIndex = index;
    }
  } else if (window.location.search === "?orderStatus") {
    if (info.driver && LoadedIndex === -1) {
      LoadedIndex = index;
    }
  }
  return { customer, status, LoadedIndex };
};

export function convertTMSStatusToDriverLocation(
  load,
  activeDriverOrder,
  toUpperCase = false
) {
  const type_of_load = load.type_of_load;
  const status = ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(
    load.status
  ) > -1 && !load.isLive
    ? "DROPCONTAINER_DEPARTED"
    : load.status;
  const info = load;
  const driverLocation = activeDriverOrder && activeDriverOrder.company_name && activeDriverOrder.company_name.toUpperCase();
  
  let labelStatus = status;
  if (type_of_load === "IMPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DISPATCHED") {
      labelStatus = `Dispatched`;
    }
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
    if (status === "COMPLETED") {
      labelStatus = `RETURN`;
    }
    if (status === "PENDING" && info && (info.custom || info.freight)) {
      labelStatus = `PENDING`;
    }
  } else if (type_of_load === "EXPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
  } else if (type_of_load === "ROAD") {
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
  } else if (type_of_load === "BILL_ONLY") {
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
  }
  
  return toUpperCase
    ? labelStatus.toUpperCase()
    : labelStatus;
  
}
export const encryptAndGenerateLiveShareCode = (referenceNumber, isoTimeString) => {
  const secretKey = process.env.REACT_APP_LIVE_SHARE_SECRET_KEY;
  const liveShareURL = process.env.REACT_APP_LIVE_SHARE_URL;

  const plainText = `${referenceNumber};${isoTimeString}`;
  const cipherText = AES.encrypt(plainText, secretKey);
  const base64String = cipherText.toString();
  const hexString = Buffer.from(base64String, "base64").toString("hex");

  /**
   * const decryption = AES.decrypt(Buffer.from(hexString, "hex").toString("base64"), secretKey);
   * console.log("Decrypted hax method: ", decryption.toString(utf8));
   */
  
  return `${liveShareURL}?code=${hexString}`;
};


export const getDocumentTypesByType = (profileType) => {
  let url = `tms/getDocumentTypesByType?profileType=${profileType}`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const encryptString = (data) => {
  const secretKey = process.env.REACT_APP_LIVE_SHARE_SECRET_KEY;
  return AES.encrypt(data, secretKey).toString();
}
export const descryptString = (data) => {
  const secretKey = process.env.REACT_APP_LIVE_SHARE_SECRET_KEY;
  return AES.decrypt(data, secretKey).toString(Utf8)
}

export const isMoveEndingEvent = (_routingEvent, _nextEvent) => {
  if (!_routingEvent || !_nextEvent) return false;
  
  return !_routingEvent?.isVoidOut
      && tmsEndMoveEvents.includes(_routingEvent.type)
      && !tmsEndMoveEvents.includes(_nextEvent?.type);
}

export const createLegsFromDriverOrder = (driverOrder, options = {}) => {
  const {
    excludeVoidOut = false,
    excludeCombinedMove = false,
    excludeDualTransactionMove = false
  } = options;

  const driverLegs = [], legIndexes = [0];
  let currentPos = 0, lastPos = 0;

  const _driverOrder = _.cloneDeep(driverOrder);
  _driverOrder?.forEach((el, i) => el.dIndex = i);
  
  while (currentPos < _driverOrder?.length) {
    if (
      isMoveEndingEvent(_driverOrder[currentPos], _driverOrder?.[currentPos + 1]) ||
      currentPos === _driverOrder?.length - 1
    ) {
      let _move = _driverOrder?.slice(lastPos, currentPos + 1);
      const isCombinedTrip = _move.find((_event) => _event.combineTripId);
      const isDualTransaction = _move.find((_event) => _event.isDualTransaction);
      
      if (excludeVoidOut) {
        _move = _move.filter((_event) => !_event.isVoidOut);
      }
      if (!(excludeCombinedMove && isCombinedTrip) || (!excludeDualTransactionMove && isDualTransaction)) {
        driverLegs.push(_move);
        legIndexes.push(lastPos);
      }
      lastPos = currentPos + 1;
    }
    currentPos++;
  }
  return { driverLegs, legIndexes };
};

export const getAppointmentDateForDriverOrder = (load, driverOrder) => {
  const userTimeZone = getStorage("timeZone");
  const isPullContainer = driverOrder.type === "PULLCONTAINER";
  const isDelivery = driverOrder.type === "DELIVERLOAD";
  const isReturn = driverOrder.type === "RETURNCONTAINER";

  if (isPullContainer && (load.pickupTimes?.length ?? 0) > 0 && load.pickupTimes[0]?.pickupFromTime) {
    const pickupTime = load.pickupTimes[0]?.pickupFromTime;

    return moment(pickupTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateTimeFormat());
  }

  if (isDelivery && (load.deliveryTimes?.length ?? 0) > 0 && load.deliveryTimes[0]?.deliveryFromTime) {
    const deliveryTime = load.deliveryTimes[0]?.deliveryFromTime;

    return moment(deliveryTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateTimeFormat());
  }

  if (isReturn && load.returnFromTime) {
    const returnTime = load.returnFromTime;

    return moment(returnTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateTimeFormat());
  }

  return "";
}

export const etaFormatter = (date) => {
  const duration = moment.duration(moment(date).diff(moment()));

  const dayFormat =
    parseInt(duration.asDays()) > 0
      ? `${parseInt(duration.asDays()).toString().padStart(2, "0")} days `
      : "";
  const hrFormat = `${parseInt(duration.asHours() % 24)
    .toString()
    .padStart(2, "0")} hr `;
  const minFormat = `${parseInt(duration.asMinutes() % 60)
    .toString()
    .padStart(2, "0")} min`;

  const formattedCountdown = `${dayFormat}${hrFormat}${minFormat}`;

  return formattedCountdown;
};

export const getAssignedDriverOrders = (load, driverId) => {
  let tempAssingedDriverOrders = [];
  if (load && load.driverOrder) {
    for (let driverOrderIndex in load.driverOrder) {
      if (
        (load.driverOrder[driverOrderIndex]?.driver?._id ??
          load.driverOrder[driverOrderIndex]?.driver) === driverId
      ) {
        tempAssingedDriverOrders.push(load.driverOrder[driverOrderIndex]);
      }
    }
  }
  return tempAssingedDriverOrders;
};

export const legTypeToHuman = (type) => {
  if(!type) {
    return "";
  }

  switch (type.toUpperCase()) {
    case "PULLCONTAINER":
      return "Pull Container";
    case "CHASSISPICK" :
      return "Pickup Chassis";
    case "DELIVERLOAD":
      return "Deliver Load";
    case "RETURNCONTAINER":
      return "Return Container";
    case "DROPCONTAINER":
      return "Drop Container";
    case "CHASSISTERMINATION":
      return "Terminate Chassis";
    case "COMPLETED":
      return "Completed"; 
    case "HOOKCONTAINER":
      return "Hook Container";
    case loadEvents.LIFTOFF : 
      return "Lift Off";
    case loadEvents.LIFTON : 
      return "Lift On";    
    case "STOPOFF":
      return "Stop Off";
    case "CHASSISTRANSFER":
      return "Chassis Transfer";
    case "BOBTAILTRANSFER":
      return 'Bobtail Transfer';
    case "BOBTAIL_ENDED":
      return 'Bobtail End';
    case "BOBTAIL_STARTED":
      return 'Bobtail Start';
    case "DROPCHASSIS":
      return "Drop Chassis";
    default:
      return type;
  }
}

export const getAppointmentDateForDriverOrderWithLabel = (
  load,
  driverOrder
) => {
  const userTimeZone = getStorage("timeZone") ?? 'America/New_York';
  const isPullContainer = driverOrder.type === "PULLCONTAINER";
  const isDelivery = driverOrder.type === "DELIVERLOAD";
  const isReturn = driverOrder.type === "RETURNCONTAINER";

  if (
    isPullContainer &&
    (load.pickupTimes?.length ?? 0) > 0 &&
    load.pickupTimes[0]?.pickupFromTime
  ) {
    const pickupTime = load.pickupTimes[0]?.pickupFromTime;
    const pickupTimeTo = load.pickupTimes[0]?.pickupToTime;
    const dateIsSame = moment(pickupTime).tz(userTimeZone).isSame(moment(pickupTimeTo).tz(userTimeZone),'second');
    const pickupTimeToObj = {
      labelTo: "Pickup To: ",
      dateTo: moment(pickupTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      timeTo: moment(pickupTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
    };
    return {
      label: dateIsSame ? "Pickup: " : "Pickup From: ",
      date: moment(pickupTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      time: moment(pickupTime).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
      ...(!dateIsSame) && pickupTimeToObj,
      type: "pickup",
    };
  }

  if (
    isDelivery &&
    (load.deliveryTimes?.length ?? 0) > 0 &&
    load.deliveryTimes[0]?.deliveryFromTime
  ) {
    const deliveryTime = load.deliveryTimes[0]?.deliveryFromTime;
    const deliveryTimeTo = load.deliveryTimes[0]?.deliveryToTime;
    const dateIsSame = moment(deliveryTime).tz(userTimeZone).isSame(moment(deliveryTimeTo).tz(userTimeZone),'second');
    const deliveryTimeToObj = {
      labelTo: "Delivery To: ",
      dateTo: moment(deliveryTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      timeTo: moment(deliveryTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
    }
    return {
      label: dateIsSame ? "Delivery: " : "Delivery From: ",
      date: moment(deliveryTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      time: moment(deliveryTime).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
      ...(!dateIsSame) && deliveryTimeToObj,
      type: "delivery",
    };
  }

  if (isReturn && (load.returnFromTime || load.freeReturnDate)) {
    const returnTime = load.returnFromTime ?? load.freeReturnDate;
    const returnTimeTo = load.returnToTime;
    const dateIsSame = moment(returnTime).tz(userTimeZone).isSame(moment(returnTimeTo).tz(userTimeZone),'second');
    const returnTimeToObj = {
      labelTo: "Return To: ",
      dateTo: moment(returnTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      timeTo: moment(returnTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
    };
    return {
      label: dateIsSame ? "Return: " : "Return From: ",
      date: moment(returnTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      time: moment(returnTime).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
      ...(!dateIsSame) && returnTimeToObj,
      type: "return",
    };
  }

  return { label: null, date: null, time: null, type: null };
};

export const BADGE_COLOR_CLASSES = {
  SELECTED: 'badge-status-dark',
  UPCOMING: 'badge-gray-50',
  COMPLETED: 'badge-gray-300 ',
  ACTIVE: 'badge-gray-300 bg-blue-500',
}
export const SUPPORT_MOVE_BADGE_COLOR_CLASSES = {
  SELECTED: 'badge-status-dark',
  UPCOMING: 'badge-gray-50',
  COMPLETED: 'badge-gray-300 ',
  ACTIVE: 'bg-purple-400 text-white',
}

export const SUPPORT_MOVE_TIMELINE_COLOR_CLASSES = {
  UPCOMING: 'text-gray-100',
  COMPLETED: 'text-gray-100 ',
  ACTIVE: 'text-purple-600',
}

export const TIMELINE_COLOR_CLASSES = {
  UPCOMING: 'text-gray-100',
  COMPLETED: 'text-gray-100 ',
  ACTIVE: 'text-blue-500',
}

export const DRIVER_ORDER_STATUS = {
  COMPLETED: 'COMPLETED',
  UPCOMING: 'UPCOMING',
  ACTIVE: 'ACTIVE',
}

export const getBadgeColorForDriverOrder = (driverOrder, isSupportMove) => {
  if(driverOrder?.arrived && driverOrder?.departed) {
    return BADGE_COLOR_CLASSES.COMPLETED;
  } else if(driverOrder?.arrived && !driverOrder?.departed) {
    return isSupportMove ? SUPPORT_MOVE_BADGE_COLOR_CLASSES.ACTIVE : BADGE_COLOR_CLASSES.ACTIVE;
  }

  return BADGE_COLOR_CLASSES.UPCOMING;
}

export const getTimelineColorForDriverOrder = (driverOrder, isSupportMove) => {
  if(driverOrder?.arrived && driverOrder?.departed) {
    return TIMELINE_COLOR_CLASSES.COMPLETED;
  } else if(driverOrder?.arrived && !driverOrder?.departed) {
    return isSupportMove ? SUPPORT_MOVE_TIMELINE_COLOR_CLASSES.ACTIVE : TIMELINE_COLOR_CLASSES.ACTIVE;
  }

  return TIMELINE_COLOR_CLASSES.UPCOMING;
}

export const getDriverOrderStatus = (driverOrder) => {
  if(driverOrder?.arrived && driverOrder?.departed) {
    return DRIVER_ORDER_STATUS.COMPLETED;
  } else if(driverOrder?.arrived && !driverOrder?.departed) {
    return DRIVER_ORDER_STATUS.ACTIVE;
  }

  return DRIVER_ORDER_STATUS.UPCOMING;
}

export const getDriverPlannerOrderColorClass = (driverOrder, selected, isSupportMove = false) => {
  const plannerColorClasses = {
    badgeColor: !selected ? getBadgeColorForDriverOrder(driverOrder, isSupportMove): BADGE_COLOR_CLASSES.SELECTED,
    timelineColor: getTimelineColorForDriverOrder(driverOrder, isSupportMove),
    status: getDriverOrderStatus(driverOrder),
  };

  return plannerColorClasses;
}

export const getFormattedDateTime = (date) => {
  const formattedDateTime = {
    date: null,
    time: null,
  };
  try {
    if (date) {
      const userTimeZone = getStorage("timeZone");
      formattedDateTime.date = moment(date).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat());
      formattedDateTime.time = moment(date).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat());
    }
  } catch (e) {
    console.error(e);
  } finally {
    return formattedDateTime;
  }
}

export const isFlagEnabled = (flagName) => {
  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings"));
  const flagEnableStatus = userBasicSettings?.[flagName] ?? false;

  if(loggedInUser?.role === "fleetmanager"){
    const permissionKey = flagName?.includes(AuditTypeEnum.IS_EMAIL_AUDIT_ENABLED) ? AuditTypeEnum.EMAIL_AUDIT_REPORT : AuditTypeEnum.DOWNLOAD_AUDIT_REPORT;
    return flagEnableStatus && loggedInUser?.permissions?.includes(permissionKey);
  }
  else if(loggedInUser?.role === "carrier") return flagEnableStatus;
  return false;
}

export const isDefaultEmailFrom = () => {
  const emailConfiguration = JSON.parse(getStorage("emailConfiguration"))?.[0];

  if(emailConfiguration?.emailFrom) return _.find(process.env?.REACT_APP_COMPANY_DOMAIN?.split(","), (d) => emailConfiguration?.emailFrom?.includes(d)) ?? false;
  return true;
}

export const getFormattedQueryParams = (params) => {
  let queryParams = "";
  Object.keys(params).forEach((key) => {
    if(/^(null|undefined|)$/.test(params[key])) return;
    const value = params[key]?.toString()?.trim();
    if(value) queryParams += `${key}=${value}&`;
  });
  queryParams = queryParams?.substring(0, queryParams.length - 1);
  return queryParams;
}

export const isTareWeightEnabled = ()=>{
  const enabledTareWeight = JSON.parse(getStorage("userBasicSettings"))?.isTareWeightEnabled
  if(enabledTareWeight) {
    return true;
  }
  return false;
}

export const isZipCodeForQuotesEnabled = ()=>{
  return !!JSON.parse(getStorage("userBasicSettings"))?.isZipCodeForQuotesEnabled;
}

export const isDakosySlotsEnabled = () => {
  return JSON.parse(getStorage("userBasicSettings"))?.isDakosySlotsEnabled ?? false;
}

export const isNewCustomerRateEnabled = () => {
  const settings = JSON.parse(getStorage("userBasicSettings"));
  return settings?.isCustomerRateRecordsEnabled ?? false;
}
export const isDriverTariffEnabled = () => {
  const settings = JSON.parse(getStorage("userBasicSettings"));
  return settings?.tariffConfiguration?.isDriverTariffEnabled ?? false;
}
export const isShowDeliveryReferenceField = () => {
  const enabledDeliveryReferenceField = JSON.parse(getStorage("userBasicSettings"))?.isShowDeliveryReferenceField;
  if(enabledDeliveryReferenceField) {
    return true;
  }
  return false;
}

export const ALL_STATUS_LABEL = {
  CHASSISPICK_ARRIVED: "Enroute to Chassis",
  CHASSISPICK_DEPARTED:"Arrived to Chassis",
  PULLCONTAINER_ARRIVED:"Enroute to Pick Container",
  PULLCONTAINER_DEPARTED:"Arrived at Pick Container",
  DROPCONTAINER_ARRIVED: "Enroute to Drop Container",
  DROPCONTAINER_DEPARTED: "Dropped",
  HOOKCONTAINER_ARRIVED: `Enroute to Hook Container`,
  HOOKCONTAINER_DEPARTED: `Arrived to Hook Container`,
  DELIVERLOAD_ARRIVED:"Enroute to Deliver Load",
  DELIVERLOAD_DEPARTED:"Arrived at Deliver Load",
  RETURNCONTAINER_ARRIVED:"Enroute to Return Load",
  RETURNCONTAINER_DEPARTED:"Arrived at Return Load",
  CHASSISTERMINATION_ARRIVED:"Enroute to Return Chassis",
  CHASSISTERMINATION_DEPARTED:"Arrived to Return Chassis",
  DISPATCHED:"Dispatched",
  FULL_PAID:"Full Paid",
  PARTIAL_PAID:"Partial Paid",
  COMPLETED:"COMPLETED",
  PENDING:"PENDING",
  LIFTOFF_ARRIVED:"Enroute to Lift Off" ,
  LIFTOFF_DEPARTED:"Arrived at Lift Off" ,
  LIFTON_ARRIVED:"Enroute to Lift On" ,
  LIFTON_DEPARTED:"Arrived at Lift On",
  STOPOFF_DEPARTED:"Arrived at Stop Off",
  STOPOFF_ARRIVED:"Enroute to Stop Off",
}

export const getStatusLabel = (status) => {
  return ALL_STATUS_LABEL?.[status]?.toUpperCase() || status?.toUpperCase() || ""
}

export const isChassisListInDriverPlannerEnabled = ()=>{
  const enabledChassisTracking = JSON.parse(getStorage("userBasicSettings"))?.isChassisListInDriverPlannerEnabled
  if(enabledChassisTracking) {
    return true;
  }
  return false;
}

export const getDeliveryAppointmentDate = (
  deliveryTimes,
  driverOrder,
) => {
  const userTimeZone = getStorage("timeZone") ?? 'America/New_York';
  const isDelivery = driverOrder.type === "DELIVERLOAD";
  
  if(isDelivery) {
    const deliveryTime = deliveryTimes?.deliveryFromTime;
    const deliveryTimeTo = deliveryTimes?.deliveryToTime;
    const dateIsSame = moment(deliveryTime).tz(userTimeZone).isSame(moment(deliveryTimeTo).tz(userTimeZone),'second');
    const deliveryTimeToObj = {
      labelTo: "Delivery To: ",
      dateTo: moment(deliveryTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      timeTo: moment(deliveryTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
    }
    return {
      label: dateIsSame ? "Delivery: " : "Delivery From: ",
      date: moment(deliveryTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      time: moment(deliveryTime).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
      ...(!dateIsSame) && deliveryTimeToObj,
      type: "delivery",
    };
  }
  return { label: null, date: null, time: null, type: null };
};

export const handleRightClickNavigateToCustomer = (event, customerId) => {
  event.preventDefault(); 
  if (customerId) {
    window.open(`/tms/customer?customerId=${customerId}&isGenerateSignUrl=true`, "_blank");
  }
};
export const handleNavigateToCustomer = (event, customerId) => {
  event.preventDefault();
  if (customerId) {
    browserHistory.push(`/tms/customer?customerId=${customerId}&isGenerateSignUrl=true`);
  }
}; 

export const getPickupAppointmentDate = (
  pickupTimes,
  driverOrder,
) => {
  const userTimeZone = getStorage("timeZone") ?? 'America/New_York';
  const isPullContainer = driverOrder.type === "PULLCONTAINER";
  
  if (isPullContainer) {
    const pickupTime = pickupTimes?.pickupFromTime;
    const pickupTimeTo = pickupTimes?.pickupToTime;
    const dateIsSame = moment(pickupTime).tz(userTimeZone).isSame(moment(pickupTimeTo).tz(userTimeZone),'second');
    const pickupTimeToObj = {
      labelTo: "Pickup To: ",
      dateTo: moment(pickupTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      timeTo: moment(pickupTimeTo).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
    };
    return {
      label: dateIsSame ? "Pickup: " : "Pickup From: ",
      date: moment(pickupTime).tz(userTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()),
      time: moment(pickupTime).tz(userTimeZone).format(DateTimeFormatUtils.timeFormat()),
      ...(!dateIsSame) && pickupTimeToObj,
      type: "pickup",
    };
  }
  return { label: null, date: null, time: null, type: null };
};


export const isDriverAcceptMovesEnabled = () => {
  const isDriverAcceptMovesEnabled = JSON.parse(getStorage("userBasicSettings"))?.isDriverAcceptMovesEnabled || false;
  return isDriverAcceptMovesEnabled;
};

export const getIsLoadOrTripLive = (load) => {
  if(!isDriverAcceptMovesEnabled()) return false;
    if(load?.isTrip){
      const isLoadDeleted = load?.isDeleted;
      const isTripStartable = load?.isTripStartable;
      const isLoadAccept = load?.isAccept;
      let isTripAlreadyStarted = false;
      // driver order array is available when it is in driver planner trip
      if (load?.hasOwnProperty("driverOrder") && load?.driverOrder?.length > 0) {
        isTripAlreadyStarted = load?.driverOrder?.some((i) => i?.arrived && !i?.isVoidOut);
      } else {
        // in freeflow / trips triporder array will be available
        isTripAlreadyStarted = load?.tripOrder?.length > 0 && load?.tripOrder?.some((i) => i?.arrived && !i?.isVoidOut);
      }
      if (!isLoadDeleted && isTripStartable && isLoadAccept && isTripAlreadyStarted) return true;
    }
    else{
      const isMoveAlreadyStarted = load?.driverOrder?.length > 0 && load?.driverOrder?.some((i)=>i?.arrived && !i?.isVoidOut) // driver or carrier started the move
      if(load?.isLive || isMoveAlreadyStarted) return true;
    }

  return false;
}
export const getShortNameForDriverEvent = (type, index=0) => {
  if(!type) {
    return "";
  }
  switch (type.toUpperCase()) {
    case "PULLCONTAINER":
      return "P";
    case "DELIVERLOAD":
      return "D";
    case "RETURNCONTAINER":
      return "R";
    case "DROPCONTAINER":
      return "DC";
    case "HOOKCONTAINER":
      return "HC";
    case loadEvents.LIFTOFF : 
      return "LF";
    case loadEvents.LIFTON : 
      return "LN";    
    case "STOPOFF":
      return "SO";
    case "CHASSISPICK":
      return "H";
    case "CHASSISTERMINATION":
      return "T";
    case "BOBTAIL_STARTED":
      return "S";
    case "BOBTAIL_ENDED":
      return "DE";
    case "CHASSISTRANSFER":
      return index === 0 ? "H" : "DR";
      case "DROPCHASSIS":
        return "DR";
    default:
      return type?.[0];
  }
};
