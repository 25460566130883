import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import * as actionCreators from "../Dispatcher/actionCreators";
import { convertTMSStatusName, statusColors } from "../../../Components/Common/functions";
import {
  deepDiffMapper,
  differnceFinder,
  pricingDiffernceFinder,
  toastr,
  getStorage,
  auditKey,
  isMilitaryTime,
  DateTimeFormatUtils,
  isNewBillingEnabled,
  amplitudeTrack,
  checkUserPermisison
} from "../../../services/Common.services";
import { formatToDateAndTime, formatToTime } from "../services";
import $ from "jquery";
import { withRouter } from "react-router";
import { Fragment } from "react";
import _ from "lodash";
import { getPreSetOrderStatus } from "./DriverOrderUtility";
import { ROUTING_STATUS, statusType } from "../LoadRouting/constants";
import { APPOINTMENT_STATUSES, MOVE_TYPES } from "constants/appointmentSystem.constant";
import { INVOICE_STATUS } from "../InvoiceModal/constant";
import { LOAD_ROUTES_LABEL } from "../carrier/BulkUploadNew/constants";
import { commodityFieldLable } from "./utility";
import { STATUS } from "../ConnectNotices/constant";
import { IconFile } from "../../../Components/Common/Icons";
import ScreenGrabsModal from "./ScreenGrabsModal/ScreenGrabsModal";
import FilterAuditLoadList from "./AuditLoad/FilterAuditLoadList";
import { getTimeZone } from "../NewDispatcher/constants";
import { checkIfPortSupportsScreengrab } from "../NewDispatcher/utility";

const chargeStatus = "Charge Status Changed";
const notesStatus = "Notes Update";

const auditType = {
  LOAD_ADD: "Load Added",
  LOAD_ADD_DUPLICATE: "Load Added via Duplication",
  LOAD_ADD_BULKUPLOAD: "Load Added via Bulk Upload",
  LOAD_ADD_TENDER: "Load Added via tender",
  LOAD_ADD_QUOTE: "Load Added via Quote",
  LOAD_ADD_AUTOMATICALLY: 'Document Uploaded via PortPal',
  LOAD_ADD_ONLINE_TENDER: 'Load Added via Online Tender',
  CUTOFF_ADD: "Cutoff Added",
  LOAD_ADD_ARTIFICIAL: "LOAD ADDED VIA ARTIFICAL INTELLIGENCE",
  LOAD_EDIT: "Load Edited",
  REMOVE_FIELD: "Value Erased",
  ASSIGN_COMPANY: "Load Assigned",
  LOAD_ACCEPT: "Load Accepted",
  SYSTEM_CHANGE_STATUS: "System Order Status Created",
  LOAD_CHANGE_STATUS: "Load Status Change",
  LOAD_REMOVE: "Load Removed",
  LOAD_ENABLE: "Load Enable",
  PRICING_UPDATE: "Price Update",
  ADDITIONAL_PRICING_UPDATE: "Additional Price Update",
  CHASSIS_PRICE_CALCULATED: "Chassis Price Calculated",
  SYSTEM_PRICE_UPDATE: "System Price Update",
  ASSIGN_LOAD_DRIVER: "Driver Assigned to Load",
  ASSIGN_LOAD_CODRIVER: "Co Driver Assigned to Load",
  ASSIGN_LOAD_EQUIPMENT: "Equipment Assigned to Load",
  AUTO_ASSIGN_LOAD_DRIVER: "Load Driver Auto Assigned",
  CRON_ASSIGN_LOAD_DRIVER: "System Load Driver Auto Assigned",
  UNASSIGN_LOAD_DRIVER: "Load Driver Removed",
  AUTO_UNASSIGN_LOAD_DRIVER: "System Load Driver Removed",
  UNASSIGN_LOAD_EQUIPMENT: "Load Equipment Removed",
  HARD_REMOVE_DRIVER: "Manual Driver Removed",
  START_LOAD_DRIVER: "Driver Has Started Load",
  UPLOAD_DOCUMENT_LOAD: "Documents Uploaded",
  REMOVE_DOCUMENT_LOAD: "Documents Removed",
  DUPLICATE_DOCUMENT_LOAD: "Documents Duplicated",
  MOVE_DOCUMENT_LOAD: "Documents Moved",
  CRUX_CONTAINER_STATUS: "Container API Status",
  NAME_SIGNATURE_UPLOAD_LOAD: 'Load Signature Uploaded by "X"',
  UPDATE_LOAD_STATUSORDER: "Load Status Updated",
  BUILD_STATUS_ORDER: "Order Status Created",
  MOBILEAPP_LOAD_STATUS_UPDATE: "Mobile App Load Status Update",
  MOBILEAPP_DRIVER_ORDER_UPDATE: "Load Status Update",
  DRIVER_ORDER_UPDATE: "Order Status Updated",
  UPDATE_DOCUMENT_STATUS_LOAD: "Document Status Updated",
  STATUS_COMPLETE_DATE_CHANGE: "Manual Date/Address change for Status",
  RESET_LOAD: "Load Reseted",
  STREET_TURN: "Street Turn Added",
  DOMESTIC_MOVE: "Domestic Move Added",
  STREET_TURN_EDIT: "Street Turn Removed",
  LOAD_READY_FOR_PICKUP: "Ready To Return",
  PAYMENT_ADD: "Payment Added",
  PAYMENT_REMOVED: "Payment Removed",
  DRIVER_LOAD_REJECTED: "Driver rejected the load.",
  ADDED_CSR : "CSR Added",
  REMOVED_CSR : "CSR Removed",
  NOTES_UPDATE: "Notes Update",
  DRIVER_PAY_APPROVAL: "Driver Pay",
  SUB_BILL_ADDED:"Sub Bill Added",
  PER_DIEM_INVOICE_ADDED: "Invoice Added from Per Diem",
  SUB_BILL_REMOVED: "Sub Bill Removed",
  SUB_BILL_PRICING_ADDED: "Sub Bill Pricing Added",
  SUB_BILL_PRICING_PER_DIEM_ADDED: "Sub Bill Pricing Added from Per Diem",
  SUB_BILL_PRICING_UPDATED: "Sub Bill Pricing Updated",
  SUB_BILL_CUSTOMER_UPDATED: "Sub Bill Customer was changed",
  SUB_BILL_PRICING_REMOVED: "Sub Bill Pricing removed",
  SUB_BILL_BILLING_DATE_UPDATED: "Sub Bill Billing Date changed",
  SUB_BILL_STATUS_CHANGED: "Sub Bill Status changed",
  MYAPP_UPDATE: "MyApp Update",
  GEO_FENCE_CHANGE: "Load Status Update",
  PER_DIEM_AUDIT_STATUS_CHANGE: "Per Diem Audit Status Change",
  DRIVER_PAY_UPDATE: "Driver Pay Update",
  DRIVER_PAY_UPDATE_CONDITION_MET: "Conditions Met",
  SUB_QB_INVOICE_ID_CHANGED: "Sub Bill QuickBooks ID changed",
  QB_INVOICE_ID_CHANGED : "QuickBooks ID changed",
  LOAD_DEFAULT_TAX_TYPE_CHANGE : "Load TAX type Update",
  LOAD_TAX_UPDATED: "Load TAX Update",
  APPOINTMENT_LOAD_EDIT: "Appointment Load Edit",
  TRIP_LINKED: "Trip Linked",
  CHARGE_GROUP_ADDED :"Charge Set Added",
  CHARGE_GROUP_BILLTO_CHANGED:"Charge Set BillToId was changed",
  CHARGE_GROUP_REMOVED :"Charge Set Removed",
  CHARGE_PRICING_ADDED:"Charge Pricing Added",
  CHARGE_PRICING_UPDATED:"Charge Pricing Updated",
  CHARGE_PRICING_REMOVED:"Charge Pricing Removed",
  CHARGE_PRICING_PASTED:"Charge Pricing Pasted",
  CHARGE_GROUP_UNAPPROVED:chargeStatus,
  CHARGE_GROUP_APPROVED:chargeStatus,
  CHARGE_GROUP_BILLED:chargeStatus,
  CHARGE_GROUP_REBILL:chargeStatus,
  BILLING_NOTE_ADDED:notesStatus,
  BILLING_NOTE_REMOVED:notesStatus,
  BILLING_NOTE_UPDATED:notesStatus,
  CHARGE_GROUP_DEFAULT_TAX_TYPE_CHANGED:"Charge Set tax update",
  INVOICE_PAYMENT_ADDED:"Payment Added",
  INVOICE_PAYMENT_REMOVED:"Payment Removed",
  AUTO_APPOINTMENT_EDIT: "Auto Appointment Edit",
  CREDIT_MEMO_APPLIED : "Credit Memo Applied",
  CREDIT_MEMO_REMOVED : "Applied Credit Memo Removed",
  VOID_INVOICE : chargeStatus,
  ORDER_UPDATE: "Order Update",
  MOVE_UPDATE: "Move Update",
  EVENT_UPDATE: "Event Update",
  LOAD_OPTIONS: "Load Options",
  ROUTING_LOAD_UPDATES: "Routing Load Update",
  LOAD_EDIT_REJECTED : "Load Edit Rejected",
  RATE_CONFIRMATION_REQUESTED : "Charge Set Request Confirmation",
  VENDOR_CHARGE_SET_APPROVED:"Vendor Charge Set Approved",
  VENDOR_CHARGE_SET_UNAPPROVED:"Vendor Charge Set Unapproved",
  VENDOR_CHARGE_PRICING_UPDATED:"Vendor Charge Pricing Updated",
  VENDOR_CHARGE_PRICING_REMOVED:"Vendor Charge Pricing Removed",
  VENDOR_CHARGE_SET_ADDED:"Vendor Charge Set Added",
  VENDOR_CHARGE_SET_REMOVED:"Vendor Charge Set Removed",
  VENDOR_CHARGE_PRICING_ADDED:"Vendor Charge Pricing Added",          
  VENDOR_CHARGE_BILL_FROM_CHANGED:"Vendor Charge Bill From Changed",
  VENDOR_CHARGE_BULK_STATUS_CHANGE:"Vendor Charge Bulk Status Changed",
  VENDOR_CHARGE_SET_BILLED:"Vendor Charge Set Billed",
  VENDOR_BILL_PAYMENT_ADDED:"Vendor Bill Payment Added",
  VENDOR_BILL_PAYMENT_REMOVED:"Vendor Bill Payment Removed",
  VENDOR_BILL_ACCEPTED:"Vendor Bill Accepted",
  VENDOR_BILL_DECLINED:"Vendor Bill Declined",
  MANUAL_EXPENSE_ADDED:"Manual Expense Added",
  EXPENSE_UPDATED:"Expense Updated",
  EXPENSE_REMOVED:"Expense Removed",
  MOVE_ACCEPTED:"Move Accepted",
  CABOTAGE_POINT_AUDIT: "Cabotage Audit"
};

const auditNotes = [
  {value: "officeNote", label: "Load Notes"},
  {value: "yardNote", label: "Yard Notes"},
  {value: "billingNote", label: "Billing Notes"},
  {value: "driverNote", label: "Driver Notes"},
  {value: "instructionNote", label: "Instruction Notes"},
  {value: "portNote", label: "Port Notes"},
  {value: "customsNote", label: "Customs Notes"},
]

const auditConstants={
  GEO_FENCE_CHANGE:"GEO_FENCE_CHANGE",
  LOAD_TEMPLATE_EVENT:"LOAD_TEMPLATE_EVENT",
  PICK_RUN_LIVE_UNLOAD:"Pick and Run + Live Unload"
}

const badgeColorCode = {
  "N":"badge-soft-green",
  "D":"badge-danger-light",
  "E":"badge-soft-purple",
  "A":"badge-soft-purple",
  "C":"badge-danger-light",
  "default":"badge-gray-100"
};

const routing_audit_type = [
  "ORDER_UPDATE",
  "MOVE_UPDATE",
  "EVENT_UPDATE",
  "LOAD_OPTIONS",
  "ROUTING_LOAD_UPDATES",
]

const LOAD_OPTIONS = {
  isPrePullCharge: "Pre pull Charge",
  isNextContainerNeedToPickup: "Driver select container",
  allowDriverCompletion: "Allow Driver Completion"
}


class AuditLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auditList: [],
      originalAuditList: [], // When filtering, store original auditList here to restore the "auditList" when all the filters are removed
      equipmentList: [],
      ContainerSize: [],
      ContainerType: [],
      TMSContainerOwner: [],
      ChassisSize: [],
      ChassisType: [],
      TMSChassisOwner: [],
      terminals:[],
      showScreenGrabsModal: false,
      selectedAuditData: null,
    };
    this.handleChangeOfSearch = this.handleChangeOfSearch.bind(this);
    this.getDriverName = this.getDriverName.bind(this);
    this.getEquipmentName = this.getEquipmentName.bind(this);
    this.userTimeFormat = isMilitaryTime();
    this.changeColorCode = {
      created: "#F4D4CF",
      updated: "#F4ECCF",
      deleted: "#CFF4EE",
      unchanged: "",
    };
  }

  handleShowScreenGrabsModal = (info) => {
    if (info?.type === "APPOINTMENT_LOAD_EDIT") this.setState({ showScreenGrabsModal: true, selectedAuditData: info });
  };

  handleHideScreenGrabsModal = () => {
    this.setState({ showScreenGrabsModal: false });
  };

  componentWillReceiveProps(props) {
    if (
      (!this.props.selectedLoads && props.selectedLoads) ||
      (this.props.selectedLoads &&
        this.props.selectedLoads != props.selectedLoads)
    ) {
      this.fetchAllInfos();
    }
  }

  componentWillMount() {
    this.fetchAllInfos();
  }

  fetchAllInfos() {
    if(!checkUserPermisison(["customer_service_audit"])) return 
    const { createdAt } = this.props.selectedLoads
    let eventProperties = {
      source: 'LOAD_AUDIT',
      days: moment().diff(moment(createdAt), 'days'),
      loadCreatedDate : moment(createdAt).toISOString(),
    }
    amplitudeTrack("LOAD_AUDIT_ACCESS",eventProperties)
    this.props.actions.allTrucks().then((result) => {
      this.setState({
        equipmentList: result,
      });
    });
    const { reference_number } = this.props.selectedLoads
      ? this.props.selectedLoads
      : this.props.location.query;

    this.props.actions
      .auditByLoad({ reference_number })
      .then((result) => {
        let data=this.getfilteredAuditList(result);
        this.setState({ auditList: data, originalAuditList: data });
        // this.setState({ auditList: result });
      })
      .catch((err) => {
        console.log("-*-* get audit load *-*-", err);
        toastr.show("No audit information is available.", "error");
      });

  }

  getfilteredAuditList=(result=[])=>{
    let excluedStatus = [
      "BILLING_DATE_CHANGED",
      "STATUS_APPROVED",
      "STATUS_UNAPPROVED",
      "STATUS_BILLING",
      "STATUS_REBILLING",
    ]
    result = result.filter((d) => !excluedStatus.includes(d.type))
    result=result.map((item, key)=>{
      item.ikey=key;
      return item
    });
    let systemLfdData=result.filter(item=>{
      if( (!item.userId && !item.userData) && item.data && ('lastContainerTracedDate' in  item.data)){
        return true;
      }
    })
    let userData=result.filter(item=>{
      if( (item.userId||item.userData) || item.data && !('lastContainerTracedDate' in  item.data)){
        return true;
      }
    })
    let trueIndexes=[];
    let falseIndexes=[];
    let count=0; 
    systemLfdData.map((rr, i)=>{
      if(rr.data.lastContainerErrorDate || rr.data.caution || rr.data.errorDate){
        count++
        // trueIndexes[i]
      }else{
        count=0;
        falseIndexes.push(i)
      }
      if( count===3){
        count=0;
        trueIndexes.push(i-2);
      }
    });
  
    systemLfdData=systemLfdData.filter((rr, i)=>{
      if([...trueIndexes,...falseIndexes].includes(i)){
        return rr
      }
    })
    systemLfdData=systemLfdData.map((item, key)=>{
      if(trueIndexes.includes(key) && (item?.additionalInfo || item?.previousData)){
        if(item?.additionalInfo){
          item.additionalInfo.caution=false;
          item.additionalInfo.errorMessage='';
        }
        if(item?.previousData){
          item.previousData.caution=false;
          item.previousData.errorMessage='';
        }
      }
      return item; 
     })
 
    let final= _.orderBy(_.concat(systemLfdData,userData ),'ikey')
 
    return final;
  }

  handleFilterAuditList = (selectedTypes = []) => {
    const { originalAuditList } = this.state;

    if (selectedTypes.length === 0) {
      this.setState({
        auditList: [...originalAuditList],
      });
    } else {
      const filteredList = originalAuditList.filter((item) => selectedTypes.includes(item.type));

      this.setState({
        auditList: filteredList,
      });
    }
  };

  handleChangeOfSearch(e) {
    var filterVal = e.target.value.toLowerCase();
    $("#auditTable tbody tr").each(function (table) {
      var isSubstr = false;
      $(this)
        .find("td")
        .each(function () {
          var tdVal = $(this).text().toLowerCase();
          if (tdVal.indexOf(filterVal) >= 0) {
            isSubstr = true;
          }
        });
      if (isSubstr) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  }

  audit_LOAD_ADD_DUPLICATE(infos) {
    const { additionalInfo, previousData } = infos;
    return (
      <div className="auditField">
        {`Load Duplicated From ${previousData ? previousData.reference_number: additionalInfo.reference_number}`}{" "}
      </div>
    );
  }

  audit_LOAD_ACCEPT(infos) {
    const { additionalInfo, previousData } = infos;
    return (
      <div className="auditField">
        {`Load Accepted From ${
          previousData
            ? previousData.customer
            : additionalInfo?.customer
        }`}{" "}
      </div>
    );
  }

  audit_ASSIGN_COMPANY(infos) {
    let { data, additionalInfo, previousData } = infos;
    previousData = previousData ?? additionalInfo;
    const driverName = this.getAssignedName(data, previousData);
    return (
      <div className="auditField">
        {driverName && (
          <span>Load Assigned to {driverName}</span>
        )}
      </div>
    );
  }

  audit_BUILD_STATUS_ORDER(infos) {
    return <div>{``} </div>;
  }
  audit_PAYMENT_ADD(infos) {
    const { data } = infos;
    return (
      <div className="auditField">
        payment added{" "}
        <span className="badge badge-info badge-pill ml-auto mr-3 font-medium px-2 py-1">{`$${(data.amount ?? 0)?.print()}`}</span>{" "}
      </div>
    );
  }
  audit_PAYMENT_REMOVE(infos) {
    const { data } = infos;
    return (
      <div className="auditField">
        payment removed{" "}
        <span className="badge badge-info badge-pill ml-auto mr-3 font-medium px-2 py-1">{`$${(data.amount ?? 0)?.print()}`}</span>{" "}
      </div>
    );
  }

  audit_LOAD_CHANGE_STATUS(infos) {
    const { data } = infos;
    const { selectedLoads } = this.props;
    let newStatus = data.status;
    if (!newStatus && data.set && data.set.status) {
      newStatus = data.set.status;
    }
    let statusObj = convertTMSStatusName(
      selectedLoads ? selectedLoads.type_of_load : "",
      newStatus,
      selectedLoads,
      null,
      true
    );
    return (
      <div className="auditField">
        Load Status Changed To{" "}
        <span className={`badge badge-soft-${statusObj.colorStatus}  mr-3 `}>
          {statusObj.status}
        </span>{" "}
      </div>
    );
  }

  audit_MOBILEAPP_LOAD_STATUS_UPDATE(infos) {
    const { data } = infos;
    const { selectedLoads } = this.props;
    if (data && data.nearLocation) {
      let newStatus = data.nearLocation.loadStatus;
      let statusObj = convertTMSStatusName(
        selectedLoads ? selectedLoads.type_of_load : "",
        newStatus,
        null,
        null,
        true
      );
      return (
        <div className="auditField">
          Load Status Changed To{" "}
          <span
            className={`badge badge-soft-warning badge-${statusObj.colorStatus}`}
          >
            {statusObj.status}
          </span>{" "}
        </div>
      );
    } else {
      return <div>{"No Status"}</div>;
    }
  }

  audit_MOBILEAPP_DRIVER_ORDER_UPDATE(infos) {
    const { updatedStatus, removedStatus } = infos.data;
    const { selectedLoads } = this.props;
    if (updatedStatus) {
      return (
        <div className="auditField">
          Load Status Changed To{" "}
          {updatedStatus.map((status) => {
            let statusObj = convertTMSStatusName(
              selectedLoads ? selectedLoads.type_of_load : "",
              status,
              selectedLoads,
              null,
              true
            );
            return (
              <><span
                className={`badge badge-soft-${statusObj.colorStatus} mr-0`}
              >
                {statusObj.status}
              </span>{infos.type === auditConstants.GEO_FENCE_CHANGE ?
              <b>by Geofence</b>
              :""}</>
            );
          })}
        </div>
      );
    } else if (removedStatus) {
      return (
        <div className="auditField">
          Load Status Removed{" "}
          {removedStatus.map((status) => {
            return (
              <span className="badge badge-danger-light">{`${convertTMSStatusName(
                selectedLoads ? selectedLoads.type_of_load : "",
                status,
                selectedLoads
              )}`}</span>
            );
          })}
        </div>
      );
    } else {
      return <div>{"No Status"}</div>;
    }
  }

  audit_ASSIGN_LOAD_DRIVER(infos) {
    const { data } = infos;
    let driverName, equipmentName;
    if (data?.driver?.constructor?.name === "Object") {
      driverName = data?.driver?.name ?? "";
    } else {
      driverName = this.getDriverName(data?.driver);
    }

    if (data?.equipment?.constructor?.name === "Object") {
      equipmentName = data?.equipment?.equipmentID ?? "";
    } else {
      equipmentName = this.getEquipmentName(data?.equipment);
    }

    return (
      <div className="auditField">
        {`Driver ${driverName} and Truck ${equipmentName} assigned`}{" "}
      </div>
    );
  }

  audit_DRIVER_LOAD_REJECTED(infos) {
    let { additionalInfo, previousData } = infos;

    previousData = previousData ?? additionalInfo;

    let driverName;
    if (previousData?.driver?.constructor?.name === "Object") {
      driverName = previousData?.driver?.name;
    } else {
      driverName = this.getDriverName(previousData.driver);
    }
    if (previousData) {
      return (
        <div className="auditField">
          {`${driverName} rejected the load.`}{" "}
        </div>
      );
    } else {
      return <div className="auditField">{`Driver  removed`} </div>;
    }
  }

  audit_HARD_REMOVE_DRIVER(infos) {
    let { additionalInfo, previousData } = infos;
    previousData=previousData?? additionalInfo

    let driverName, equipmentName;
    if (previousData?.driver?.constructor?.name === "Object") {
      driverName = previousData?.driver?.name ?? "";
    } else {
      driverName = this.getDriverName(previousData?.driver);
    }

    if (previousData?.equipment?.constructor?.name === "Object") {
      equipmentName = previousData?.equipment?.equipmentID ?? "";
    } else {
      equipmentName = this.getEquipmentName(previousData?.equipment);
    }

    if (previousData) {
      return (
        <div className="auditField">
          {`Driver ${driverName} and Truck ${equipmentName} removed`}{" "}
        </div>
      );
    } else {
      return <div className="auditField">{`Driver  removed`} </div>;
    }
  }

  audit_STREET_TURN(infos) {
    const { data } = infos;
    return (
      <div className="auditField">
        {data && (
          <span>
            <span className="font-medium">Street Turn </span>
            {data.type_of_load === "IMPORT" && "to"}
            {data.type_of_load === "EXPORT" && "from"}
            <span className="font-medium"> {data.streetTurn}</span>
          </span>
        )}
      </div>
    );
  }

  audit_DOMESTIC_MOVE(infos) {
    const { data } = infos;
    console.log(data);
    return (
      <div className="auditField">
        {data && (
          <span>
            <span className="font-medium">Domestic Move </span>
            {data.type_of_load === "IMPORT" && "to"}
            {data.type_of_load === "EXPORT" && "from"}
            <span className="font-medium"> {data.domesticLoadNo}</span>
          </span>
        )}
      </div>
    );
  }

  displayCommodityData(data) {
    const { action, newValue } = data;
  
    const renderField = (label, commodityValue, field) => {
      const isUpdateAction = newValue?.actionType === "UPDATE";
      const changeFields = commodityValue?.changedFields ? commodityValue?.changedFields[field] : {}
      const commodityFieldValue = commodityValue?.[field] ?? ""
      const displayValue = isUpdateAction ? changeFields : commodityFieldValue;
      const displayedLabel = commodityFieldLable[field] || label;
      if (displayValue) {
        return (
          <div className="auditField mb-1">
            {isUpdateAction ? (
              <>
                {Object.values(changeFields)?.length && (changeFields?.oldValue !== undefined && changeFields?.newValue !== undefined) ?
                  <>
                   
                    <span className="font-medium font-weight-bold">{`Commodity - ${commodityValue?.commodity?.commodity ?? ""} : ${displayedLabel}`}</span> &nbsp;
                    <span className="font-medium font-weight-bold">{changeFields?.oldValue ?? ""}</span> changed to &nbsp;
                    <span className="font-medium font-weight-bold">{changeFields?.newValue ?? ""}</span></>
                  : <></>}
              </>
            ) : (
              <>
                <span className="font-medium font-weight-bold">{`${displayedLabel} - ${displayValue} `}</span>
                <span className="badge badge-soft-green text-uppercase">{newValue?.actionType}</span>
              </>
            )}
          </div>
        );
      }
  
      return null;
    };
  
    const fields = ["commodity", "description", "pieces", "weight", "weightKgs", "pallets"];
    return (
      <>
        {fields?.map((field) => renderField(field.charAt(0).toUpperCase() + field.slice(1), newValue?.commodity, field))}
      </>
    );
  }
  audit_LOAD_EDIT(infos, prevUserId) {
    let { data, additionalInfo, userId, previousData, userData, createdAt } = infos;
    const createdAtDate = moment(createdAt).tz(getStorage("timeZone")).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`);
    const requestcreatedAtDate = moment(data?.requestCreatedAt).tz(getStorage("timeZone")).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`);
    previousData = previousData ?? additionalInfo;
    const changedInfos = differnceFinder(
      previousData,
      data,
      userData ?? userId,
      prevUserId
    );
    const removeArray=[
      "isDrayosTrackosError",
      "isUserEnteredfreight",
      "isUserEnteredcustom",
      "isUserEnteredlastFreeDay",
      "isUserEnteredvessel",
      "isUserEnteredcontainerSize",
      "isUserEnteredcontainerType",
      "isUserEnteredcontainerOwner",
      "isUserEnterdischargedDate",
      "isUserEnteredreleaseNo",
      "isUserEntereddeliveryOrderNo",
      "isUserEnteredingateDate",
      "isUserEnteredoutgateDate",
      "systemGeneratedContainerSizeName",
      "systemGeneratedOwnerName",
      "systemGeneratedTypeName",
      "systemGeneratedLastFreeDay",
      "systemGeneratedDischarge",
      "systemGeneratedEstimatedToArrive",
      "systemGeneratedOutGateDate",
      "systemGeneratedInGateDate",
      "systemGeneratedVesselName",
      "systemGeneratedVoyageName",
      "isSelectedFromRecommendation",
      "systemGeneratedcutOff",
      "systemGeneratedcontainerAvailableDay",
      "isUserEnteredcutOff",
      "isUserEnteredcontainerAvailableDay",
      "isUpdateBillToCustomer",
      "systemGenerateddeliveryOrderNo",
      "systemGeneratedreleaseNo",
      "isUserEnteredEmptyTar",
      "isUserEnteredStatus",
      "sendtoProblemContainer",
      "isUserEnteredbrokerHold"
    ]

    const newAuditHandledKey = [
      "chassisType",
      "chassisSize",
      "chassisOwner",
      "containerType",
      "terminal",
      "containerSize",
      "containerOwner",
      "grayChassisType",
      "grayChassisSize",
      "grayChassisOwner",
      "grayContainerType",
      "grayContainerSize",
      "grayContainerOwner",
      "isoContainerType"
    ];

    removeArray.forEach(i=>{
      delete changedInfos[i]
    })
    
    if (Object.keys(changedInfos).length === 0) {
      return (
        <div>
          <span>Not anything changed</span>
        </div>
      );
    }

    return (
      <div>
        {Object.keys(changedInfos).map((key, i) => {
          let fieldName = key === "vessel" ? "Vessel ETA" : key;
          let value = changedInfos[key];
          if (key === "chassisType" || key === "grayChassisType") {
            if(value.constructor.name === "Object"){
              value = value.name;
            }else{
              const chassisInfo = this.props.ChassisType.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.name;
              }
            }
            
          } else if (key === "chassisSize" || key === "grayChassisSize") {
            if(value.constructor.name === "Object"){
              value = value.name;
            }else{
              const chassisInfo = this.props.ChassisSize.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.name;
              }
            }
          
          } else if (key === "chassisOwner" || key === "grayChassisOwner") {
            if(value.constructor.name === "Object"){
              value = value.company_name;
            }else{
              const chassisInfo = this.props.TMSChassisOwner.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.company_name;
              }
            }
            
          } else if (key === "isoContainerType") {
            if (value.constructor.name === "Object") {
              value = value.name;
            } else {
              const containerInfo = this.props.IsoContainerType.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.name;
              }
            }
          } else if (key === "containerType" || key === "grayContainerType") {
            if (value.constructor.name === "Object") {
              value = value.name;
            } else {
              const containerInfo = this.props.ContainerType.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.name;
              }
            }
          }else if (key === "terminal") {
            if (value.constructor.name === "Object") {
              value = value.name;
            }else{
              const terminalInfo = this.props.terminals.find((obj) => {
                return obj._id === value;
              });
              if (terminalInfo) {
                value = terminalInfo.name;
              }
            }
           
          } else if (key === "containerSize" || key === "grayContainerSize") {
            if (value.constructor.name === "Object") {
              value = value.name;
            }else{
              const containerInfo = this.props.ContainerSize.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.name;
              }
            }
            
          } else if (key === "containerOwner" || key === "grayContainerOwner") {
            if (value.constructor.name === "Object") {
              value = value.company_name;
            }else{
              const containerInfo = this.props.TMSContainerOwner.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.company_name;
              }
            }
            
          }else if (value && value.length > 10 && moment(value, moment.ISO_8601).isValid()) {
            value = moment(value)
            .tz(getStorage("timeZone")).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`)
          } else if (key === "pickupTimes") {
            const date = { ...changedInfos[key][`0`] };

            if (date?.pickupToTime) {
              value = `FromTime: ${moment(date.pickupFromTime)
                .tz(getStorage("timeZone"))
                .format(DateTimeFormatUtils.fullDateTimeFormat())}`;
              value += `, ToTime: ${moment(date.pickupToTime)
                .tz(getStorage("timeZone"))
                .format(DateTimeFormatUtils.fullDateTimeFormat())}`;
            }

            changedInfos.pickupTimes = value;
          } else if (key === "deliveryTimes") {
            const date = { ...changedInfos[key][`0`] };

            if (date?.deliveryToTime) {
              value = `FromTime: ${moment(date.deliveryFromTime)
                .tz(getStorage("timeZone"))
                .format(DateTimeFormatUtils.fullDateTimeFormat())}`;
              value += `, ToTime: ${moment(date.deliveryToTime)
                .tz(getStorage("timeZone"))
                .format(DateTimeFormatUtils.fullDateTimeFormat())}`;
            }

            changedInfos.deliveryTimes = value;
          } else if (
            key === "vessel" &&
            Object.keys(changedInfos[key]).includes("eta") &&
            changedInfos[key].eta
          ) {
            value = moment(changedInfos[key].eta)
              .tz(getStorage("timeZone"))
              .format(DateTimeFormatUtils.fullDateTimeFormat());
            changedInfos.vessel = value;
          } else if (key === "deliveryReference") {
              if (changedInfos.deliveryReference.length) {
                return changedInfos.deliveryReference.map((val) => {
                  if (!val?.oldReferenceNo) {
                    if (val?.newReferenceNo) {
                      return (
                        <div className="d-flex align-items-center">
                          <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100" >{val.deliveryPosition}</div>
                           <div className="auditField ml-1" key={i}>
                            Delivery Reference # added
                            <span className="font-medium font-weight-bold">
                              {' ' + val?.newReferenceNo + ' '} </span>
                          </div>
                        </div>
                      );
                    } else {
                      return
                    }
                  } else if (!val?.newReferenceNo) {
                    return (
                      <div className="d-flex align-items-center">
                        <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100" >{val.deliveryPosition}</div>
                        <div className="auditField ml-1" key={i}>
                          Delivery Reference # removed
                          <span className="font-medium font-weight-bold">{' ' + val?.oldReferenceNo + ' '}</span>
                        </div>
                      </div>
                    );
                  } else if (val?.oldReferenceNo && val?.newReferenceNo) {
                    return (
                      <div className="d-flex align-items-center">
                        <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100" >{val.deliveryPosition}</div>
                          <div className="auditField ml-1" key={i}>
                            Delivery Reference #
                            <span className="font-medium font-weight-bold">{' ' + val?.oldReferenceNo + ' '}</span>
                            changed to
                            <span className="font-medium font-weight-bold">{' ' + val?.newReferenceNo + ' '}</span>
                        </div>
                      </div>
                    );
                  }
                }) 
              }
          }else if (key==="toRemoveLfd"){
            return (
              <div>
               <div className="auditField">
                <span className="font-medium">Previously Provided Last Free Day was removed by the terminal on their site.</span>
                </div>
              </div>
            );
          }else if(key === "source") {
            return;
          }

          if (fieldName == "caution") {
            return;  
          }

          if (fieldName == "cautionCount") {
            return;  
          }
          if (fieldName == "brokerHold" || fieldName == "carrierHold" || fieldName == "isGrayPool" || fieldName == "isContainerConfirm") {
            if (value == true) {
              value = "Yes";
            }
            if (value === false) {
              value = "No";
            }
          }
          if(fieldName === "lastContainerErrorDate" || fieldName === "errorDate"){
            return;
          }

          if (fieldName === "pickupTimes" || fieldName === "deliveryTimes") {
            if (Object?.keys(changedInfos?.[key]?.[`0`] || {})?.includes("customerId"))
              delete changedInfos?.[key]?.[`0`]?.customerId;
            if (changedInfos?.[key]?.[`0`] && Object.keys({ ...changedInfos[key][`0`] }).length === 0) {
              const result = !_.isEqualWith(previousData[fieldName].map((d) => {
                delete d._id;
                return d;
              }) , data[fieldName].map((d) => {
                delete d._id;
                return d;
              }));
              if (result) {
                return (
                  <div>
                    <div className="auditField">
                        <span className="font-medium"> {auditKey[fieldName]?auditKey[fieldName]:fieldName} was Removed</span>
                    </div>
                  </div>
                );
              }
            }
          }

          if (key === "problemMsg") {
            return (
              <div className="auditField">
                <span className="font-medium">{data[key]}</span>
              </div>
            );
          }

          if (
            fieldName !== "isEmptyOrigin" &&
            fieldName !== "isLastFreeDay" &&
            fieldName !== "isLastFreeDay" &&
            fieldName !== "chassisId" &&
            fieldName !== "grayChassisId" &&
            fieldName !== "shipperInfo" &&
            fieldName !== "consigneeInfo" &&
            fieldName !== "isContainerInvalid" && !data?.action
          ){
            
            return (
              (typeof changedInfos[key] !== "object" ||newAuditHandledKey.includes(key)) && (
                <div className="auditField" key={i}>
                  {
                    fieldName !== "caution" && fieldName !== "errorMessage" &&
                    <Fragment>
                        <span className="font-medium">
                          <b>{auditKey[fieldName]?auditKey[fieldName]:fieldName}</b>
                        </span>
                        {` changed to `}  
                    </Fragment>
                  }
                  <span className="font-medium"><b>{value}</b></span> 
                  {(data.isContainerInvalid && fieldName === "containerNo") && <span className="font-medium text-danger"> {"   "}Container # doesn't match check digit</span>}
                </div>
              )
            );
          }
          if(key === "shipperInfo" || key === "consigneeInfo"){
            return value.map((val,i) =>{
              return (
                <div className="auditField" key={i}>
                  {auditKey[fieldName]?auditKey[fieldName]:fieldName}
                  <span className="font-medium">{' ' + val.value + ' '}</span>
                    {val.editType}
                </div>
            );
              })
          }

          if (key === "action") {
            const isObject = typeof data?.newValue === 'object' && data?.newValue !== null;
            let result = "";
            let _auditType = auditKey?.[data?.fieldName] ?? (["loadRoutingLocation", "loadRoutingApptTimes"].includes(data?.fieldName) ? auditKey?.[(data?.newValue?.locationType ?? data?.newValue?.event ?? data?.newValue?.type)] : data?.fieldName )
            if (isObject) {
              if(data?.fieldName === "loadRoutingLocation") {
                result = data?.newValue?.company_name
              }else if(data?.fieldName === "loadRoutingApptTimes"){
                _auditType += " " + (data?.newValue?.fromTo ? "("+data?.newValue?.fromTo+")" : "" )
                result = data?.newValue?.date ? moment(data?.newValue?.date).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-"
              }
            } else if (typeof data?.newValue === 'boolean') {
              result = Boolean(data?.newValue) ? "True" : "False";
            } else if (moment(data?.newValue, moment.ISO_8601, true).isValid()) {
              result = moment(data?.newValue).tz(getStorage("timeZone")).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`)
            } else if (data?.newValue !== undefined) {
              result = data?.newValue;
            }
            return (
              <div className="auditField">
                {changedInfos[key]=== 'PENDING' && <span className="badge badge-soft-green text-uppercase">
                  {data?.auditType === 'CANCEL_REQUEST' ? "Cancelled request for load edit" : "Change Request Sent"}
                </span>}
                {data?.fieldName === "items" && data?.newValue?.commodity ?
                  this.displayCommodityData(data) :
                  <div className="auditField mb-1">
                      <span className="font-medium font-weight-bold mr-1">{_auditType}{" "}</span> 
                      changed to 
                      <span className="font-medium font-weight-bold ml-1">{" "}{result}</span>
                      </div>
                }
                {
                  changedInfos[key]!== 'PENDING' && <>
                    <div className="auditField mb-1"> 
                    <span className="badge badge-soft-green text-uppercase">
                          {data?.action?.replace(/_/g, " ")}
                          </span> 
                          <span className="mx-1" >by</span>
                    <span className="font-medium font-weight-bold">
                            {data?.updatedBy?.name}  {data?.updatedBy?.lastName ? ` ${data?.updatedBy?.lastName}`: ""}
                    </span> on 
                    <span className="font-medium font-weight-bold ml-1">{createdAtDate}</span>
                    </div>
                    <div className="auditField">Change submitted on <span className="font-medium font-weight-bold">{requestcreatedAtDate}</span> </div>
                </>
                }
                
              </div>
            )
          }  
        })}
      </div>
    );
  }

  audit_UPDATE_LOAD_STATUSORDER(infos) {
    return <div>{``} </div>;
  }

  audit_LOAD_READY_FOR_PICKUP(infos) {
    return (
      <div className="auditField">
        <span className="font-medium">{`${infos?.data?.isReadyForPickup ? 'Load Become Ready To Return' : 'Ready to Return status was removed'} `}</span>{" "}
      </div>
    );
  }
  audit_LOAD_CONFIRM(infos) {
    return (
      <div className="auditField">
        <span className="font-medium">
          {infos.data.isConfirm ? `Load Confirm` : `Load Un Confirm`}
        </span>
        {infos.data.isConfirm && infos.data.sealNo && (
          <span className="font-medium">, seal No: {infos.data.sealNo} </span>
        )}
        {infos.data.isConfirm && infos.data.notes && (
          <span className="font-medium">, notes: {infos.data.notes} </span>
        )}
      </div>
    );
  }
  audit_PRICING_UPDATE(infos){
    let { data, additionalInfo, previousData } = infos;
    previousData=previousData?? additionalInfo
    const changedInfos = pricingDiffernceFinder(
      previousData ? previousData : {},
      data ? data : {},
    );

    if (changedInfos.length === 0) {
      return null;
    }
    else {
      return (
        <div className="auditField">
          {changedInfos.map((charge) => {
            let badge = 'badge-soft-purple';
            if (charge.editType === 'DELETED PRICING') badge = 'badge-danger-light';
            else if (charge.editType === 'ADDED PRICING') badge = 'badge-soft-green';

            return (
              <div>
                <span className={"mr-3 badge " + badge}>{charge.editType}</span>
                <span className="mr-3 font-weight-bold">{charge.name}</span>
                <span>(Price: {charge.finalAmount?.print()}, Unit: {charge.unit?.print()}, Type: {charge.perType}
                , Free Units: {charge.freeDays?.print()}, Per Units: {charge.chargePerDay?.print()})</span>
              </div>
            )
          })}
        </div>
      )
    }
  }

  audit_DRIVER_PAY_CHANGE(infos){

    let { additionalInfo, userId, previousData, userData } = infos;
    previousData=previousData?? additionalInfo
    userData=userData??userId

    let driverName;
   
    if (previousData?.driver?.constructor?.name === "Object") {
      driverName = previousData?.driver?.name;
    } else {
      driverName = this.getDriverName(previousData.driver);
    }
    
    const changedInfos = (approveStatusData)=>{
      if(approveStatusData.approved===true){
        return "approved"
      }else{
        return "unapproved"
      }
    }
      return (
        <div className="auditField">
        <span className="font-medium"><b>{userData.name}</b> {changedInfos(previousData)} driver pay for driver <b>{driverName}</b> on <b>{previousData.status}</b></span>
      </div>
      )
  }

 audit_CSR_CHANGE(infos){
    let csrName = ""
    if(infos.data){
     csrName = infos.data.name ? csrName+infos.data.name+" " : "";
     csrName = infos.data.lastName ? csrName+infos.data.lastName : ""
    } 
    const desc = infos.type === "ADDED_CSR" ? "New CSR Assigned " : "CSR Removed "
  return <p>{desc}   <b className={`badge ${infos.type === "ADDED_CSR" ? "badge-soft-green" : "badge-soft-brown"} `}>{csrName}</b></p>
  }

noteChangeData= (rawData)=>{
  if(rawData?.driverNote){
    return rawData?.driverNote
  } 
  if(rawData?.billingNote){
  return rawData?.billingNote
  }
  if(rawData?.yardNote){
    return rawData?.yardNote
  }
  if(rawData?.officeNote){
    return rawData?.officeNote
  }
  if(rawData?.customsNote){
    return rawData?.customsNote
  }
  if(rawData?.portNote){
    return rawData?.portNote
  }
  return rawData?.instructionNote
}
  
  audit_NOTES_CHANGE(infos){
    const previousData=infos?.previousData ?? infos?.additionalInfo
    return (
      <div className="auditField">
        {auditNotes.map((note) => {
          if((Object.keys(previousData))?.includes(note.value)) {
            return note.label;
          }
        })}
        {" "}
        changed to {" "}
        <b>{this.noteChangeData(previousData)}</b>{" "}
      </div>
    )
  }

  audit_LOAD_ADD_QUOTE(infos) {
    return (
      <div className="auditField">
        Generated off of QUOTE {" "} <b>{infos?.data?.quote}</b>
      </div>
    );
  }

  myapp_update(info, userId) {
    let importAvailablityKeys = [
      "custom",
      "freight",
      "brokerHold",
      "deliveryOrderNo",
      "releaseNo", "status",
      "containerOwnerName",
      "containerSizeName",
      "containerTypeName",
      "lastFreeDay",
      "outgateDate",
      "vessel",
      "dischargedDate",
      "ingateDate",
      "sealNo",
      "callerbillLandingNo",
      "pickupAppointment",
      "emptyAppointment",
      "callerPONo",
      "returnNo",
      "status",
      "cutOff",
      "containerAvailableDay",
      "appointmentNo",
      "reservationNo",
      "pickupTimes",
      "returnFromTime",
      "returnToTime",
      "portNote",
      "appointmentNote",
      "errorMessage",
      "removelastFreeDay",
      "removedischargedDate"
    ]
    const { data } = info
    if(data.vessel && data.vessel.eta){
      data.vessel=data.vessel.eta
    }
    
    if(data?.pickupAppointment?.status){
      data.pickupAppointment = data.pickupAppointment.status
    } else {
      if (typeof data.pickupAppointment == "object" && data.pickupAppointment !== null) {
        data.pickupAppointment = "";
      }
    }
    
    if(data?.emptyAppointment?.status){
      data.emptyAppointment = data.emptyAppointment.status
    } else {
      if (typeof data.emptyAppointment == "object" && data.emptyAppointment !== null) {
        data.emptyAppointment = "";
      }
    }

    Object.keys(data).forEach(i => {
      if (!importAvailablityKeys.includes(i)) {
        delete data[i]
      }
    })
    return (
      <div className="auditField">
        {Object.keys(data).map((key, i) => {
          let val = data[key];

          if (key === "appointmentNote") {
            return <span>{val}</span>;
          }

          if (key === "brokerHold") {
            const mapper = {
              true: "YES",
              false: "NO",
            };
            val = mapper[val];
          }
          if (["cautionCount","caution", "lastContainerErrorDate","errorDate"].includes(key)) {
            return;  
          }
          if (key === "pickupTimes") {
            return (
              <>
                <span className="font-medium">{auditKey[key]}</span> changed to{" "}
                <span className="font-medium">
                  FromTime:
                  {moment(data?.pickupTimes[0]?.pickupFromTime)
                    .tz(getStorage("timeZone"))
                    .format(DateTimeFormatUtils.fullDateTimeFormat())}
                  , ToTime:
                  {moment(data?.pickupTimes[0]?.pickupToTime)
                    .tz(getStorage("timeZone"))
                    .format(DateTimeFormatUtils.fullDateTimeFormat())}
                </span>
              </>
            );
          }else if(["removelastFreeDay","removedischargedDate"].includes(key)){
            const value = {
              "removelastFreeDay":"Last Free Day",
              "removedischargedDate":"Discharged Date",
            }
            return (
              <div>
              <div className="auditField">
              <span className="font-medium">Previously Provided  {value[key]} was removed by the terminal on their site.</span>
               </div>
             </div>
            );
          } else {
            return (
              <div>
                {["Appointment #", "Reservation #", "Pickup Appointment Status", "Empty Appointment Status"].includes(
                  auditKey[key]
                ) && !val ? (
                  <span>
                    {auditKey[key] === "Empty Appointment Status" &&
                    this.props.selectedLoads?.type_of_load === "EXPORT" &&
                    this.props.selectedLoads?.preSetOrderStatus?.includes("SHUNT")
                      ? "Loaded Appointment Status"
                      : auditKey[key]}{" "}
                    was removed
                  </span>
                ) : (
                  <>
                    {key !== "caution" && key !== "errorMessage" && (
                      <>
                        <span className="font-medium">
                          {auditKey[key] === "Empty Appointment Status" &&
                          this.props.selectedLoads?.type_of_load === "EXPORT" &&
                          this.props.selectedLoads?.preSetOrderStatus?.includes("SHUNT")
                            ? "Loaded Appointment Status"
                            : auditKey[key]}{" "}
                        </span>{" "}
                        changed to{" "}
                      </>
                    )}
                    <span className="font-medium">
                      {" "}
                      {[
                        "lastFreeDay",
                        "outgateDate",
                        "vessel",
                        "dischargedDate",
                        "ingateDate",
                        "returnFromTime",
                        "returnToTime",
                        "cutOff",
                        "containerAvailableDay",
                      ].includes(key)
                        ? moment(data[key]).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat())
                        : val}
                    </span>
                  </>
                )}
              </div>
            );
          }
        })}
      </div>
    );



  }


  audit_REMOVE_FIELD(infos){
    const { data } = infos;
    const key = data.key === "vessel" ? "vesselETA" : data.key;

    return (
      <div>
        <div className="auditField">
          <span className="font-medium">{auditKey[key] ? auditKey[key] : key} was Removed</span>
        </div>
      </div>
    );
   
  }

  audit_SUB_BILL_ADDED(infos){
    const { data } = infos;
    return (
      <div>
       <div className="auditField">
        <span className="font-medium">New Sub bill <b className="badge badge-gray-100 badge-sm">{data?.sub_reference_number ? data.sub_reference_number : ""}</b> was added.</span>
        </div>
      </div>
    );
  }

  audit_SUB_BILL_REMOVED(infos){
    let { additionalInfo,previousData } = infos;
    previousData= previousData ?? additionalInfo;
    return (
      <div>
       <div className="auditField">
        <span className="font-medium">Sub bill <b className="badge badge-danger-light">{previousData?.sub_reference_number ? previousData.sub_reference_number : ""}</b> was removed.</span>
        </div>
      </div>
    );
  }

  audit_SUB_BILL_PRICING_ADDED(infos, isUpdate){
    const { data } = infos;
    const { sub_reference_number, pricing } = data
    return (
      <div className="auditField">

          <span className={isUpdate ? "badge badge-soft-purple" : "badge badge-soft-green"}>{isUpdate ? "PRICING UPDATED" :"ADDED PRICING"}</span> In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b><br></br>
          <span className="mr-3 font-weight-bold">{pricing?.name}</span>
          <span>(Price: {pricing?.finalAmount?.print()}, Unit: {pricing?.unit?.print()}, Type: {pricing?.perType}
          , Free Units: {pricing?.freeDays?.print()}, Per Units: {pricing?.chargePerDay?.print()})</span>

      </div>
    )
  }

  audit_SUB_BILL_PRICING_REMOVED(infos){
    const { data } = infos;
    const { sub_reference_number, pricing } = data
    return (
      <div className="auditField">

          <span className={"badge badge-danger-light"}>PRICING REMOVED</span> In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b><br></br>
          <span className="mr-3 font-weight-bold">{pricing?.name}</span>
          <span>(Price: {pricing?.finalAmount?.print()}, Unit: {pricing?.unit?.print()}, Type: {pricing?.perType}
          , Free Units: {pricing?.freeDays?.print()}, Per Units: {pricing?.chargePerDay?.print()})</span>

      </div>
    )
  }

  audit_SUB_BILL_CUSTOMER_UPDATED(infos){
    const { data } = infos;
    const { sub_reference_number, oldCustomerName, newCustomerName } = data
    return (
      <div className="auditField">

          <span className={"badge badge-soft-green"}>CUSTOMER CHANGED</span>  In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b>
          <span className="font-weight-bold">{oldCustomerName}</span> TO <span className="mr-3 font-weight-bold">{newCustomerName}</span>

      </div>
    )
  }

  audit_SUB_BILL_BILLING_DATE_UPDATED(infos){
    const { data } = infos;
    const { sub_reference_number, oldBillingDate, newBillingDate } = data
    return (
      <div className="auditField">

          <span className={"badge badge-soft-purple"}>BILLING DATE CHANGED</span>  In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b>
          <span className="font-weight-bold">{oldBillingDate ? moment(oldBillingDate).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateFormat()) : "EMPTY"}</span> TO 
          <span className="mr-3 font-weight-bold">{" " + moment(newBillingDate).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateFormat())}</span>

      </div>
    )
  }

  audit_SUB_BILL_STATUS_CHANGED(infos){
    const { data } = infos;
    const { sub_reference_number, oldStatus, newStatus } = data
    return (
      <div className="auditField">

          <span className={"badge badge-soft-green"}>STATUS CHANGED</span>  In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b>
          <span className="font-weight-bold">{oldStatus}</span> TO  
          <span className="mr-3 font-weight-bold"> {newStatus}</span>

      </div>
    )
  }

  audit_PER_DIEM_AUDIT_STATUS_CHANGE(infos){
    const {data} = infos;
    return (
      <div className="auditField">

          <span className={"badge badge-soft-green"}>Per Diem Audit Status</span>  changed to
          <b className="badge badge-gray-100 badge-sm m-2">{data?.myAppPerDiemAuditStatus}</b>

      </div>
    )
  }

  audit_TAX_AMOUNT_UPDATED(infos){
    const { data } = infos;

    const { taxType, description,defaultTaxType,taxRate, taxAmount,totalTaxAmount } = data || {}
    return (
      <div className="auditField">

          <span className={"badge badge-soft-green"}>TAX UPDATED</span>   
          <span className="mr-3 ">{"("} Description : {description},  Default Tax Type : {defaultTaxType},  Tax Type : {taxType},Tax Rate : {taxRate*100}%, Tax Amount : {taxAmount}  {")"}</span> 

      </div>
    )
  }
  audit_DEFAULT_TAX_TYPE_UPDATED(infos){
    const { data } = infos;
    const { defaultTaxType } = data
    return (
      <div className="auditField">

          <span className={"badge badge-soft-green"}>Default tax type changed </span> 
         {" "}TO  
          <span className="mr-3 font-weight-bold"> {defaultTaxType}</span> 

      </div>
    )
  }

  audit_DRIVER_PAY_UPDATE = (infos) => {
    let { additionalInfo, previousData } = infos;
    previousData= previousData ?? additionalInfo;
    if(previousData){
      if (Object.keys(previousData)?.length === 0) {
        return null
      }
      else {
        let badge = 'badge-soft-purple';
        if (previousData.operationType === 'DELETED DRIVER PAY') badge = 'badge-danger-light';
        else if (previousData.operationType === 'ADDED DRIVER PAY') badge = 'badge-soft-green';
        return (
          <div className="auditField">

              <span className={"mr-3 badge " + badge}>{previousData.operationType}</span>
              <span className="mr-3 font-weight-bold">{previousData.status}</span>
              <span>
                (
                  <span><text className="font-weight-bold">Amount:</text> {(previousData?.amount ?? 0)?.print()}</span>
                  {previousData?.from && (<span><text className="font-weight-bold">, From:</text> {previousData?.from} </span>)}
                  {previousData?.to && (<span><text className="font-weight-bold">,  To:</text> {previousData?.to} </span>)}
                  {previousData?.taxType && (<span className="font-weight-bold">, {previousData?.taxType}:</span>)}
                  {previousData?.defaultTaxType && (<span> {previousData?.defaultTaxType} </span>)}
                  {previousData?.time && (<span><text className="font-weight-bold">,  Time:</text> {moment(previousData?.time).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat())}</span>)}
                )
              </span>

          </div>
        )
      }
    }
  }
  
  audit_SUB_QB_INVOICE_ID_CHANGED(infos){
    const { data } = infos;
    const { sub_reference_number, newQbInvoiceId, oldQbInvoiceId } = data
    return (
      <div className="auditField">

          <span className={"badge badge-soft-purple"}>QuickBooks ID</span>  In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b>
          <span className="font-weight-bold">{oldQbInvoiceId ? oldQbInvoiceId : "EMPTY"}</span> TO{" "}
          <span className="mr-3 font-weight-bold">{newQbInvoiceId ? newQbInvoiceId : "EMPTY" }</span>

      </div>
    )
  }
  
  audit_QB_INVOICE_ID_CHANGED(infos){
    const { data } = infos;
    const {  newQbInvoiceId, oldQbInvoiceId } = data
    return (
      <div className="auditField">

          <span className={"badge badge-soft-purple"}>QuickBooks ID</span> changed to{" "}
          <span className="font-weight-bold">{oldQbInvoiceId ? oldQbInvoiceId : "EMPTY"}</span> TO{" "}
          <span className="mr-3 font-weight-bold">{newQbInvoiceId ? newQbInvoiceId : "EMPTY" }</span>

      </div>
    )
  }

  getUserAvatar= (rawData) =>{

    if(rawData?.data?.updatedBy?.name){
      return `${rawData?.data?.updatedBy?.name?.charAt(0)}${rawData?.data?.updatedBy?.lastName ? rawData?.data?.updatedBy?.lastName?.charAt(0) : ""}`
    }


    if(rawData?.drayOSCompanyName && rawData?.drayOSCompanyName != "") return rawData?.drayOSCompanyName?.charAt(0).toUpperCase() + rawData?.drayOSCompanyName?.charAt(1).toUpperCase()

    const isUserDataNotEmpty = rawData?.userData && (rawData?.userData?.name || rawData?.userData?.lastName);
    if(isUserDataNotEmpty){
      return `${rawData?.userData?.name?.charAt(0)}${rawData?.userData?.lastName ? rawData?.userData?.lastName?.charAt(0) : ""}`
    }

    if(rawData?.userId){
      return `${rawData?.userId?.name?.charAt(0)}${rawData?.userId?.lastName ? rawData?.userId?.lastName?.charAt(0) : ""}`
    }

    return "S"
  }

  getUserName= (rawData) =>{

    if(rawData?.data?.updatedBy?.name){
      return `${rawData?.data?.updatedBy?.name} ${rawData?.data?.updatedBy?.lastName? rawData?.data?.updatedBy?.lastName : ""}`
    }

    if(rawData?.drayOSCompanyName && rawData?.drayOSCompanyName != "") return rawData?.drayOSCompanyName;

    const isUserDataNotEmpty = rawData?.userData && (rawData?.userData?.name || rawData?.userData?.lastName);
    if(isUserDataNotEmpty){
      return `${rawData?.userData?.name} ${rawData?.userData?.lastName? rawData?.userData?.lastName : ""}`
    }
    if(rawData?.userId){
      return `${rawData?.userId?.name}${rawData?.userId?.lastName ? ` ${rawData?.userId?.lastName}` : ""}${
        rawData?.adminId
          ? ` (via PortPro Admin User)`
          : ""
      }`;
    }

    return "SYSTEM"
  }
  audit_DRIVER_ORDER_UPDATE(infos){ 
    const {data} = infos;

    const temp = Object.keys(data ?? {})?.map((key) => {
      return {key, _data: data[key]} 
    })

    return(
      <div className="auditField">
          {
            temp?.map(({key,_data}) => {
              let lhs = this.filterLhsRhs(key, _data?.lhs);
              let rhs = this.filterLhsRhs(key, _data?.rhs);
              let activityType = _data?.activityType;

              if(!activityType) return;

              if(key?.includes("_EVENT")){
                if (/false/.test(lhs) && /true/.test(rhs)) activityType = "N";
                else if(/true/.test(lhs) && /false/.test(rhs)) activityType = "D";
              }

              if(key?.includes("MOVE DRIVER")){
                if(lhs) lhs = this.props.driverList.find((d) => d._id === lhs);
                if(rhs) rhs = this.props.driverList.find((d) => d._id === rhs);

                if(lhs?.name && lhs?.lastName) lhs = `${lhs.name} ${lhs.lastName}`;
                else lhs = ROUTING_STATUS.UNASSIGNED;

                if(rhs?.name && rhs?.lastName) rhs = `${rhs.name} ${rhs.lastName}`;
                else rhs = ROUTING_STATUS.UNASSIGNED;
              }

              if(key?.includes(ROUTING_STATUS.VOID_OUT)){
                const event = key.split("TO")[1];
                key = event;
                rhs = ROUTING_STATUS.VOID_OUT;
                activityType = 'C';
              }
              
              if(key === auditConstants.LOAD_TEMPLATE_EVENT){
                lhs = getPreSetOrderStatus()?.find((option) => option.value === lhs)?.label ?? lhs;
                rhs = getPreSetOrderStatus()?.find((option) => option.value === rhs)?.label ?? rhs;
              }

              return(
                <div>
                  <span className={`badge ${badgeColorCode[activityType ?? "default"]} text-uppercase`}>{key}</span> 
                  {
                    activityType === "N" && (
                      <>
                        <span className="mx-2">has changed from</span>
                        <span className="font-weight-bold">{[ROUTING_STATUS.DRIVER_NAME, ROUTING_STATUS.LOAD_ASSINGED_DATE].includes(key) ? ROUTING_STATUS.UNASSIGNED : ROUTING_STATUS.NONE}</span>
                        <span className="mx-2">to</span>
                        <span className="font-weight-bold">{rhs}</span>
                      </>
                    )
                  }
                  {
                    activityType === "D" && <span className="mx-2">has removed.</span>
                  }
                  {
                    activityType === "A" && <span className="mx-2"> has modified.</span>
                  }
                  {
                    activityType === "E" && (
                      <>
                        <span className="mx-2">has changed from</span>
                        <span className="font-weight-bold">{lhs}</span>
                        <span className="mx-2">to</span>
                        <span className="font-weight-bold">{rhs}</span>
                      </>
                    )
                  }
                  {
                    activityType === "C" && (
                      <>
                        <span className="mx-2">has changed to</span>
                        <span className="font-weight-bold">{rhs}</span>
                      </>
                    )
                  }
                </div>    
              )
            })
          }
      </div>
    )
  }

  audit_TRIP_LINKED(infos){ 
    const {data} = infos;

    if(!data?.isTripAudit && !data?.tripNumber) return;
    return <div className="auditField">Load Linked from Trip #:{data?.tripNumber}</div>
  }
  audit_ADD_LOADS(infos){
    const {data} = infos;
    return (<>
   {Object.keys(data).map((key, i) => {
          let fieldName = key;
          let value = data[key];
          if(key === "callerName" || key === "consigneeName" || key === "shipperName"){
            return( 
                <div className="auditField" key={i}>
                  {auditKey[key]?auditKey[key]:fieldName}
                  <span className="font-medium">{' ' + data[key] + ' '}</span>
                </div>)
          
          }
    })}
    {data?.containerNo && 
      <div className="auditField">
          {"Container # :"}
            <span className="font-medium">{' ' + data?.containerNo + ' '}</span>
        {data?.isContainerInvalid && data?.containerNo && <span className="font-medium text-danger"> {"   "}Container # doesn't match check digit</span>}
      </div>}
    </>)
  }

  audit_APPOINTMENT_LOAD_EDIT(infos) {
    const { data } = infos;
    return Object.keys(data).map((key, i) => {
      if (["isRescheduled"]?.includes(key)){
        const label = data.hasOwnProperty("callerPONo") ? "Pickup # removed for rescheduling appointment." : "Return # removed for rescheduling appointment.";
        return (
          <div className="auditField">
              <span className="mr-3 my-1 badge badge-soft-info">
                Appointment#
              </span>
              <span className="font-medium">{label}</span>
          </div>
        )
      }

      if(key === "pickupTimes") {
        return (
          <div className="auditField">
            <span className="mr-3 my-1 badge badge-soft-green">
              PickupTimes
            </span>
            {data?.pickupTimes?.map((eachPickupTime) => (
              <div>
                <span className="font-medium">Pickup Times</span> changed to 
                FromTime: {formatToDateAndTime(eachPickupTime.pickupFromTime)}, 
                ToTime: {formatToDateAndTime(eachPickupTime.pickupToTime)}
              </div>
            ))}
          </div>
        )
      }
      if(key === "returnFromTime") {
        return (
          <div className="auditField">
              <span className="mr-3 badge badge-soft-green">
                Return Times
              </span>
              <span className="font-medium">Return Times</span> changed to 
                FromTime: {formatToDateAndTime(data?.returnFromTime)}, 
                ToTime: {formatToDateAndTime(data?.returnToTime)}
          </div>
        )
      }
      if(["pickUpApptStatus", "emptyApptStatus"].includes(key)) {
        const label = key === "pickUpApptStatus" ? "Pickup Appointment Status" : "Return Appointment Status";
        
        return (
          <div className="auditField">
              <span className="mr-3 my-1 badge badge-soft-info">
                Appointment Status
              </span>
              <span className="font-medium">{label}</span> changed to {data[key]}
              {data[key] === "CANCELLED" && data.timesChangedMessage && <p> { data.timesChangedMessage } </p>}
              {data[key] === "CANCELLED" && data.portChangedMessage && <p> { data.portChangedMessage } </p>}
          </div>
        )
      }
      if(["requestedPickupTimes", "requestedReturnTimes"].includes(key)) {
        return (
          <div className="auditField">
            <span className="mr-3 badge badge-soft-green">Appointment Times</span>
            {data?.[key]?.map((eachDateTime) => (
              <div>
                <span className="font-medium">
                  Requested {key === "requestedPickupTimes" ? "Pickup" : "Return"} Times
                </span>
                {eachDateTime?.dateType?.includes("TIME") ? (
                  <>
                    &nbsp; FromTime: {eachDateTime?.startTime && formatToTime(eachDateTime.startTime)}, ToTime:{" "}
                    {eachDateTime?.endTime && formatToTime(eachDateTime.endTime)}
                  </>
                ) : (
                  <>
                    &nbsp; FromTime: {eachDateTime?.startTime && formatToDateAndTime(eachDateTime.startTime)}, ToTime:{" "}
                    {eachDateTime?.endTime && formatToDateAndTime(eachDateTime.endTime)}
                  </>
                )}
              </div>
            ))}
          </div>
        );
      }

      if(["callerPONo","returnNo"]?.includes(key) && !data?.isRescheduled){
        const label = key === "callerPONo" ? "Pickup Confirmed Appointment Number" : "Return Confirmed Appointment Number";
        return (
          <div className="auditField">
              <span className="mr-3 my-1 badge badge-soft-info">
                Appointment#
              </span>
              <span className="font-medium">{label}</span> changed to {data[key]}
          </div>
        )
      }

      if (key === "unset") {
        const removedMessages = []
        if (data[key]?.hasOwnProperty("pickupTimes"))
          removedMessages.push({ value: "pickupTimes", label: "Pickup Times was removed" })
        if (data[key]?.hasOwnProperty("returnFromTime") && data[key]?.hasOwnProperty("returnToTime"))
          removedMessages.push({ value: "returnTime", label: "Return Time was removed" })
        // Can add more if needed in the future
        return (
          <div className="auditField">
            {
              removedMessages?.map(message => <span key={message.value} className="font-medium pt-1 pb-1 d-inline-block">{message.label}</span>)
            }
          </div>
        )
      }

      if(["remarks"]?.includes(key) && data?.moveType) {
        const label = data.moveType === "PICKUP" ? "Pickup Appointment Remarks" : "Return Appointment Remarks";
        
        return (
          <div className="auditField">
              <span className="mr-3 my-1 badge badge-soft-info">
                Appointment Remarks
              </span>
              <span className="font-medium">{label}: </span>{data[key]}
          </div>
        )
      }


    })
  }
  
  filterLhsRhs(key, value){
    const utcDateFormatWithZ = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    const utcDateFormatWithNoZ = 'YYYY-MM-DDTHH:mm:ss.SSS';
    if(moment.utc(value, utcDateFormatWithZ, true).isValid() || moment.utc(value, utcDateFormatWithNoZ, true).isValid()) {
      const date = moment(value)?.tz(getStorage("timeZone"))?.format(DateTimeFormatUtils?.fullDateTimeFormat());
      return date;
    }
    value = value?.toString();
    
    if(key === auditConstants.LOAD_TEMPLATE_EVENT && !value) return auditConstants.PICK_RUN_LIVE_UNLOAD;
    else return value?.length ? value : [ROUTING_STATUS.DRIVER_NAME, ROUTING_STATUS.LOAD_ASSINGED_DATE].includes(key) ? ROUTING_STATUS.UNASSIGNED : ROUTING_STATUS.NONE;
  }

  getDriverName(id) {
    const driver = this.props.driverList.find((obj) => {
      return obj._id === id;
    });
    return driver ? driver.name : "";
  }

  getEquipmentName(id) {
    const equipment = this.state.equipmentList.find((obj) => {
      return obj._id === id;
    });
    return equipment ? equipment.equipmentID : "";
  }

  audit_CHARGE_PRICING_ADD = (infos) => {
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const pricing = data?.data?.pricing
    const updatedPricing = Array.isArray(pricing) ? pricing : [pricing]
    const isCustomerRateRecordAdded = data?.data?.isCustomerRateRecordAdded ?? false;
      return (
        <div className="auditField">
          <span className="mr-3 badge badge-soft-green">ADDED PRICING</span>
          <span >Charge Set #</span>
          <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup}</b>
          {isCustomerRateRecordAdded ? <> from <span className="badge badge-gray-100 badge-sm">Load Tariff</span></> : ""}
          <br/> 
          {updatedPricing.map((charge) => {
            return (
              <div>
                <span className="mr-3 font-weight-bold">{charge.name}</span>
                <span>(Price: {charge.finalAmount?.print()}, Unit: {charge.unit?.print()}, Type: {charge.perType}
                , Free Units: {charge.freeDays?.print()}, Per Units: {charge.chargePerDay?.print()})</span>
              </div>
            )
          })}
        </div>
      )
  }

  audit_CHARGE_PRICING_UPDATE = (infos) => {
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const pricing = data?.data?.pricing
    const updatedPricing = Array.isArray(pricing) ? pricing : [pricing]
    const isCustomerRateRecordAdded = data?.data?.isCustomerRateRecordAdded ?? false;

      return (
        <div className="auditField">
          <span className="mr-3 badge badge-soft-purple">UPDATE PRICING</span>
          <span>Charge Set #</span>
          <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup}</b>
          {isCustomerRateRecordAdded ? <> from <span className="badge badge-gray-100 badge-sm">Load Tariff</span></> : ""}
          <br /> 
          {updatedPricing.map((charge) => {
            return (
              <div>
                <span className="mr-3 font-weight-bold">{charge.name}</span>
                <span>(Price: {charge.finalAmount?.print()}, Unit: {charge.unit?.print()}, Type: {charge.perType}
                , Free Units: {charge.freeDays?.print()}, Per Units: {charge.chargePerDay?.print()})</span>
              </div>
            )
          })}
        </div>
      )
  }

  audit_CHARGE_PRICING_DELETED = (infos) => {
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const pricing = data?.data?.deletedPricing
    const updatedPricing = Array.isArray(pricing) ? pricing : [pricing]
    const isCustomerRateRecordAdded = data?.data?.isCustomerRateRecordAdded ?? false;
      return (
        <div className="auditField">
          <span className="mr-3 badge badge-danger-light">DELETED PRICING</span>
          <span>Charge Set #</span>
          <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup}</b>
          {isCustomerRateRecordAdded ? <> from <span className="badge badge-gray-100 badge-sm">Load Tariff</span></> : ""}
          <br /> 
          {updatedPricing.map((charge) => {
            return (
              <div>
                <span className="mr-3 font-weight-bold">{charge?.name}</span>
                <span>(Price: {charge?.finalAmount?.print()}, Unit: {charge?.unit?.print()}, Type: {charge?.perType}
                , Free Units: {charge?.freeDays?.print()}, Per Units: {charge?.chargePerDay?.print()})</span>
              </div>
            )
          })}
        </div>
      )
  }

  audit_CHARGE_GROUP = (infos, type) => {
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const pricing = data?.data?.deletedPricing
    const customerName = data?.data?.customerData?.company_name;
    const isCustomerRateRecordAdded = data?.data?.isCustomerRateRecordAdded ?? false;
    
    switch (type) {
      case "CHARGE_GROUP_ADDED":
        return (
          <div className="auditField">
            <span className="font-medium">New Charge Set <b className="badge badge-gray-100 badge-sm">{chargeGroup ?? ""}</b> was added
              {isCustomerRateRecordAdded ? <> from <span className="badge badge-gray-100 badge-sm">Load Tariff</span></> : ""}.</span>
          </div>
        )
      case "CHARGE_GROUP_REMOVED":
        return(
          <div className="auditField">
        <span className="font-medium">Charge Set <b className="badge badge-danger-light">{chargeGroup ?? ""}</b> was removed.</span>
        </div>
        )
      case "CHARGE_GROUP_BILLTO_CHANGED":
        return(
          <div className="auditField">
          <div>
            <span className={"badge badge-soft-green"}>CUSTOMER CHANGED</span>  Charge Set
            <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup ?? ""}</b>
            <span className="font-weight-bold">{customerName}</span>
          </div>
        </div> 
        )
      default:
        return ""
    }
  }

  audit_BILLING_NOTES(infos){
    const billingNote=infos?.data?.data?.billingNote;
    return (
      <div className="auditField">
        Billing Notes changed to {" "}
        <b>{billingNote}</b>{" "}
      </div>
    )
  }

  audit_CHARGE_GROUP_STATUS(infos){
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const chargeStatus = data?.data?.status;
    const inVoiceNumber = data?.data?.invoiceNumber
    return (
      <div className="auditField">
        <div>
          <span className={"badge badge-soft-green"}>STATUS CHANGED</span>  Charge Set
          <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup}</b>
          <span className="font-weight-bold">{chargeStatus}</span> 
           {["INVOICED","REBILLING"].includes(chargeStatus) ?  <b className="badge badge-gray-100 badge-sm m-2">{inVoiceNumber}</b>  : ""}
        </div>
      </div>
    )
  }
  audit_VOID_INVOICE(infos){
    const {data} = infos
    const chargeGroup = data?.charge_reference_number;
    const chargeStatus = data?.data?.status;
    const inVoiceNumber = data?.data?.invoiceNumber;
    return (
      <div className="auditField">
        <div className="">

          <span className={"badge badge-soft-green"}>STATUS CHANGED</span>  Charge Set
          <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup}</b>
          <span className="font-weight-bold"> DRAFT </span> 
        </div>
        <div className="mb-2">
        <b>Invoice Voided :</b>
           {[INVOICE_STATUS.VOIDED].includes(chargeStatus) ?  <b className="badge badge-gray-100 badge-sm ">{inVoiceNumber}</b>  : ""}
        </div>
      </div>
    )
  }

  audit_CHARGE_GROUP_DEFAULT_TAX_TYPE(infos){
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const taxType = data?.data?.defaultTaxType
    return (
      <div className="auditField">

            Default tax type Change to
            <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup ?? ""}</b>
            <span className="font-weight-bold">{taxType}</span>

        </div>
    )
  }

  audit_VENDOR_CHARGE_DEFAULT_TAX_TYPE(infos){
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const taxType = data?.data?.defaultTaxType
    return (
      <div className="auditField">
          <div className="mb-2">
            Default tax type Change to
            <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup ?? " "}</b>
            <span className="font-weight-bold">{taxType}</span>
          </div>
        </div>
    )
  }
  audit_PAYMENT(infos,value){
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const invoiceNumber = data?.data?.invoiceNumber
    const isBatchpayment = data?.data?.batchPaymentId||false
    let totalAmount = 0

   if(isBatchpayment){
    totalAmount = data?.data?.payload?.invoices?.reduce((prev,current)=>prev+current?.amount,0)||0
   }

    switch (value) {
      case "INVOICE_PAYMENT_ADDED":
        return (
          <div className="auditField">

              {`${isBatchpayment?"Batch ":""}`} Payment added in charge
              <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup ?? ""}</b>,
              Invoice number <b className="badge badge-gray-100 badge-sm m-2">{invoiceNumber}</b>
              {data?.data?.payload && (<><br></br>
              {isBatchpayment && <><span className="mr-2 font-weight-bold">Batch Amount :</span>
              <span className="mr-2">{totalAmount?.toCurrency()}</span></>}
              <span className="mr-2 font-weight-bold">Applied Payment Amount :</span>
              <span className="mr-2" >{data?.data?.amount?.toCurrency()}</span>
              <span className="mr-2 font-weight-bold">Invoice Balance Due :</span>
              <span>{data?.data?.invoiceRemainAmount?.toCurrency()}</span></>)}

          </div>
        )
        case "INVOICE_PAYMENT_REMOVED":
          return (
            <div className="auditField">

                  Payment removed in charge
                  <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup ?? ""}</b>,
                  Invoice number <b className="badge badge-gray-100 badge-sm m-2">{invoiceNumber}</b>
                {data?.data?.payload && (<><br></br>
                  <span className="mr-2 font-weight-bold">Deleted Paid Amount :</span>
                  <span className="mr-2" >{data?.data?.amount?.toCurrency()}</span>
                  <span className="mr-2 font-weight-bold">Invoice Balance Due :</span>
                  <span>{data?.data?.invoiceRemainAmount?.toCurrency()}</span></>)}

              </div>
          )  
      default:
        break;
    }
    
  }
  audit_CREDIT_MEMO_PAYMENTS(infos,value){
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const invoiceNumber = data?.data?.invoiceNumber
    const isBatchpayment = data?.data?.batchPaymentId||false
    let totalAmount = 0

   if(isBatchpayment){
    totalAmount = data?.data?.payload?.invoices?.reduce((prev,current)=>prev+current?.amount,0)||0
   }

    switch (value) {
      case "CREDIT_MEMO_APPLIED":
        return (
          <div className="auditField">

                Credit Memo applied to charge
                <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup ?? ""}</b>,
              Invoice number <b className="badge badge-gray-100 badge-sm m-2">{invoiceNumber}</b>
              {data?.data?.payload && (<><br></br>
                {isBatchpayment && <><span className="mr-2 font-weight-bold">Batch Amount :</span>
                  <span className="mr-2">{totalAmount?.toCurrency()}</span></>}
                <span className="mr-2 font-weight-bold">Applied Payment Amount :</span>
                <span className="mr-2" >{data?.data?.amount?.toCurrency()}</span>
                <span className="mr-2 font-weight-bold">Invoice Balance Due :</span>
                <span>{data?.data?.invoiceRemainAmount?.toCurrency()}</span></>)}

            </div>
        )
        case "CREDIT_MEMO_REMOVED":
          return (
            <div className="auditField">

                  Applied Credit Memo removed from charge
                  <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup ?? ""}</b>,
                  Invoice number <b className="badge badge-gray-100 badge-sm m-2">{invoiceNumber}</b>
                  {data?.data?.payload && (<><br></br>
                  <span className="mr-2 font-weight-bold">Deleted Paid Amount :</span>
                  <span className="mr-2" >{data?.data?.amount?.toCurrency()}</span>
                  <span className="mr-2 font-weight-bold">Invoice Balance Due :</span>
                  <span>{data?.data?.invoiceRemainAmount?.toCurrency()}</span></>)}

              </div>
          )  
      default:
        break;
    }
    
  }

  
  audit_CHARGE_PRICING_PASTED = (infos) => {
    const { data } = infos;
    const chargeGroup = data?.charge_reference_number;
    const pricing = data?.data?.pricing
    const updatedPricing = Array.isArray(pricing) ? pricing : [pricing]
    return (
      <div className="auditField">
        <span className="mr-3 badge badge-soft-purple">PASTED PRICING</span>
        <span>Charge Set #</span>
        <b className="badge badge-gray-100 badge-sm m-2">{chargeGroup}</b><br/> 
        {updatedPricing.map((charge) => {
          return (
            <div className="mb-2">
              <span className="mr-3 font-weight-bold">{charge?.name}</span>
              <span>(Price: {charge?.finalAmount?.print()}, Unit: {charge?.unit?.print()}, Type: {charge?.perType}
              , Free Units: {charge?.freeDays?.print()}, Per Units: {charge?.chargePerDay?.print()})</span>
            </div>
          )
        })}
      </div>
    )
  }
  audit_AUTO_APPOINTMENT_EDIT(infos) {
    const { data } = infos;
    return Object.keys(data)?.map((key, i) => {
      if (key === "message") {
        return (
          <div className="auditField">
            <span className="mr-2 badge badge-soft-primary">AUTO APPOINTMENT</span>
            <span>
              {data['moveType']} {data[key]}
            </span>
          </div>
        )
      }
      if (key === "slots") {
        return (
          <div className="auditField">
            {!data['message'] && 
              <span className="mr-3 badge badge-soft-primary">Auto Appointment</span>
            }
            <span className="font-medium">
              Requested {data["moveType"] === MOVE_TYPES.PICKUP ? "Pickup" : "Return"} Times:
            </span>
            {data[key]?.map((eachSlot) => (
              <div>
                <span>{formatToDateAndTime(eachSlot)}</span>
              </div>
            ))}
          </div>
        );
      }
    })
  }
  getDescriptionFromObj = (key, value) => {
    const { eventType, arrivedOrDeparted, newValue, oldValue, eventVoidOut, removedEvents, addedEvents, moveDetails } = value
    if (key === "STOPOFF_TYPE") {
      let description = (!oldValue || !newValue) ?
        <> StopOff Type <span className={`badge badge-${oldValue ? "danger-light" : "soft-green"} text-uppercase`}>{oldValue || newValue}</span> is {oldValue ? "removed" : "added"} </>
        : <> StopOff Type <span className={`badge badge-danger-light text-uppercase`}>{oldValue}</span> is updated to <span className={`badge badge-soft-green text-uppercase`}>{newValue}</span> </>
      return <>
        <span>
          {description}
          <span className="badge badge-gray-100 badge-sm">{eventType}</span>
        </span>
      </>
    }
    else if (key === "ADDERESS_UPDATED") {
      let description = (!oldValue || !newValue) ?
        <> Customer <b>{oldValue || newValue}</b> is {oldValue ? "removed from" : "added at"} </>
        : <> Customer <b>{oldValue}</b> is updated to <b>{newValue}</b> at </>
      return <>
        <span>
          {description}
          <span className="badge badge-gray-100 badge-sm">{eventType}</span>
        </span>
      </>
    }
    else if (key === "STATUS_UPDATE") {
      return <>
        <span className="badge badge-soft-purple text-uppercase mr-1">Status</span>
        {(typeof oldValue === "object" && oldValue?.[0]) ? "Load status removed " : "Load status changed to "} 
        {
          typeof oldValue === "object" && oldValue?.[0] &&
          <>
            {
              (oldValue || [])?.map(x=>{
                const oldStatusColor = statusColors[x]
                return (
                  <span className={`text-white badge badge-sm badge-status-${oldStatusColor} mr-1`}>
                    {convertTMSStatusName(this.props?.selectedLoads?.type_of_load ?? "IMPORT", x, this.props?.selectedLoads, null, null, false)}
                  </span>
                )
              })
            }
            {value.updatedFromGeofence && <b>by Geofence</b>}
            {value.updatedFromEldGeofence && <b>by ELD Geofence</b>}
          </>
        }
        {
          <>
          {

            typeof newValue === "object" ? newValue?.[0] && (newValue || [])?.map(x=>{
              const newStatusColor = statusColors[x]
              return (
                <span className={`text-white badge badge-sm badge-status-${newStatusColor} mr-1`}>
                  {convertTMSStatusName(this.props?.selectedLoads?.type_of_load ?? "IMPORT", x, this.props?.selectedLoads, null, null, false)}
                </span>
              )
            }) : 
              <span className={`text-white badge badge-sm badge-status-${statusColors[newValue]} mr-1`}>
                {convertTMSStatusName(this.props?.selectedLoads?.type_of_load ?? "IMPORT", newValue, this.props?.selectedLoads, null, null, false)}
              </span>
          }
          {value.updatedFromGeofence && <b>by Geofence</b>}
          {value.updatedFromEldGeofence && <b>by ELD Geofence</b>}
          </>
        }
      </>

    }
    else if (key === "EVENT_DATE_UPDATE") {
      return <>
        <span> <span className="badge badge-gray-100 badge-sm">{statusType?.[eventType] ?? eventType}</span> {" "} {arrivedOrDeparted === "arrived" ? "enroute" : "arrived"} is updated to {" "}
          <b> {moment(newValue).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat())}</b>
        </span>
      </>
    }
    else if (key === "LOAD_ASSIGN_DATE") {
      return <> <span> <span className="badge badge-soft-purple text-uppercase">Load assign date </span> updated to {" "} <b> {moment(newValue).tz(getTimeZone({preferred: true})).format(DateTimeFormatUtils.fullDateTimeFormat())}</b></span> </>
    }
    else if (key === "BOBTAIL") {
      return <>
        <span><span className="badge badge-soft-purple text-uppercase">Bobtail</span> {""} {newValue ? "added" : "removed"} </span>
      </>
    }
    else if (key === "GRAYPOOL") {
      return <>
        <span> <span className="badge badge-soft-purple text-uppercase">Graypool </span> {newValue ? "added" : "removed"} </span>
      </>
    }
    else if (key === "LOAD_COMPLETED_AT") {
      let description = (!oldValue || !newValue) ?
        (oldValue ? <> Load <span className="badge badge-danger-light text-uppercase">Uncompleted</span> </> : <> Load <span className="badge badge-soft-green">Completed</span></>)
        : <>
          Load completed date{" "}
          <b>{moment(oldValue).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat())}</b>
          {" "}is updated to{" "}
          <b>{moment(newValue).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat())}</b>
        </>
      return <>
        <span>
          {description}
        </span>
      </>
    }
    else if (key === "ROUTING_TEMPLATE") {
      return <>
        <span><span className="badge badge-soft-purple text-uppercase">Routing template</span> {''} is updated from{" "}
          <b>{LOAD_ROUTES_LABEL[oldValue ?? ""]}</b>
          {" "}to{" "}
          <b>{LOAD_ROUTES_LABEL[newValue ?? ""]}</b>
        </span>
      </>
    }
    else if (key === "EVENT_ADDED") {
      return <><span className="badge badge-soft-green text-uppercase">{eventType}</span> is added in routing</>
    }
    else if (key === "EVENT_REMOVED") {
      return <><span className="badge badge-danger text-uppercase">{eventType}</span> {eventVoidOut && "(Void Out)"} is removed from routing</>
    }
    else if (key === "EVENT_ORDER_UPDATE") {
      return <>
      <div className="py-1">
        {(addedEvents || [])?.length > 0 && <span> Added events </span>}
        {
          (addedEvents || [])?.map(x=>{
            return (
              <span className={`badge badge-soft-green text-uppercase ml-2`}>
                {x?.type}
              </span>
            )
          })
        }
      </div>
      <div className="py-1">
        {(removedEvents || [])?.length > 0 && <span> Removed events </span>}
        {
          (removedEvents || [])?.map(x=>{
            return (
              <span className={`badge badge-danger text-uppercase ml-2`}>
                {x?.type}
              </span>
            )
          })
        }
      </div>
      </>
    }
    else if (key === "LOAD_OPTIONS") {
      const updatedFields = Object.keys(newValue);
      return updatedFields.map(x => <><span className="badge badge-soft-purple text-uppercase">{LOAD_OPTIONS[x]}</span>{""} is {newValue[x] ? "added" : "removed"} </>)
    }
    else if (key === "VENDOR") {
      if(oldValue?.[0]){
        if(!newValue){
          return <>
            <span>
              {oldValue.map(vendor => {
              const vendorName = vendor.name ? vendor.name+" "+vendor.lastName : vendor.company_name  
              return <>
                <span className="badge badge-danger-light text-uppercase">{vendorName}</span>{" "}
              </>})}
              removed from the load 
            </span>
          </>
        }
      }else{
        if((newValue?.name || newValue?.company_name) && !oldValue){
          const vendorName = newValue.name ? newValue.name + " " + newValue.lastName : newValue.company_name
          return <>
            <span>
              <span className="badge badge-soft-green text-uppercase">{vendorName}</span> is assigned to load 
            </span>
          </>
        }else if(newValue?.name || newValue?.company_name){
          const oldVendorName = oldValue.name ? oldValue.name + " " + oldValue.lastName : oldValue.company_name
          const newVendorName = newValue.name ? newValue.name + " " + newValue.lastName : newValue.company_name
          return <>
            <span>
              <span className="badge badge-danger-light text-uppercase">{oldVendorName}</span> is removed and <span className="badge badge-soft-green text-uppercase">{newVendorName}</span> is assigned to load
            </span>
          </>
        }
      }
    }
    else if (key === "TRIP") {
      return (
        <>
          Move{" "}
          {moveDetails?.map((x) => (
            <span className={`badge badge-soft-green text-uppercase ml-2`}>{x?.type}</span>
          ))}{" "}
          is {newValue ? "added into" : "removed from"} the Trip #<b>{newValue || oldValue || ""}</b>
        </>
      );
    }
    else if (key === "DUAL_TRANSACTION") {
      return (
        <>
          Move{" "}
          {moveDetails?.map((x) => (
            <span className={`badge badge-soft-green text-uppercase ml-2`}>{x?.type}</span>
          ))}{" "}
          is {newValue ? "added into" : "removed from"} the Dual Transaction
        </>
      );
    }
    else if (key === "VOID_OUT") {
      return <><span className="badge badge-danger text-uppercase">{eventType}</span> is voided out</>
    }
  }
  audit_ROUTING_SERVICE(infos) {
    const { data, type } = infos;
    let event = data ? Object.keys(data) : []

    return (
      <div className="auditField">
        {event?.length > 0 && event.map((name) => {
          return (
            this.getDescriptionFromObj(name, data?.[name])
          )
        }
        )}
      </div>
    );
  }

  displayCommodityRejectedData(data){
    const { action, newValue } = data;

    const renderField = (label, commodityValue, field) => {
      const isUpdateAction = newValue?.actionType === "UPDATE";
      const changeFields = commodityValue?.changedFields ? commodityValue?.changedFields[field] : {}
      const commodityFieldValue = commodityValue?.[field] ?? ""
      const displayValue = isUpdateAction ? changeFields : commodityFieldValue;
      const displayedLabel = commodityFieldLable[field] || label;
      if (displayValue) {
        return (
          <div className="auditField mb-1">
            {isUpdateAction ? (
              <>
                {Object.values(changeFields)?.length && (changeFields?.oldValue !== undefined && changeFields?.newValue !== undefined) ?
                  <>
                    <span className="badge badge-danger-light text-uppercase">{action?.replace(/_/g, " ")}</span>&nbsp;
                    {`change for Commodity ${commodityValue?.commodity?.commodity ?? ""} : ${displayedLabel}`} &nbsp;
                    <span className="font-medium font-weight-bold">{changeFields?.oldValue ?? ""}</span> changed to &nbsp;
                    <span className="font-medium font-weight-bold">{changeFields?.newValue ?? ""}</span>
                  </>
                  : <></>}
              </>
            ) : (
              <>
                <span className="badge badge-danger-light text-uppercase">{action?.replace(/_/g, " ")}</span>&nbsp;
                {`change for Commodity ${displayValue} `}
                <span className="badge badge-soft-green text-uppercase">{newValue?.actionType}</span>
              </>
            )}
          </div>
        );
      }

      return "";
    };

    const fields = ["commodity", "description", "pieces", "weight", "weightKgs", "pallets"];

    return (
      <>
        {fields?.map((field) => renderField(field.charAt(0).toUpperCase() + field.slice(1), newValue?.commodity, field))}
      </>
    );
  }
  audit_LOAD_EDIT_REJECTED(infos) {
    const { data, createdAt } = infos;
    const createdAtDate = moment(data.actionAt).tz(getStorage("timeZone")).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`);
    const newValue = data?.newValue;
    let rejectNewvalue = "";
    const isObject = typeof newValue === 'object' && newValue !== null;
    let _auditType = auditKey?.[data?.fieldName] ?? (["loadRoutingLocation", "loadRoutingApptTimes"].includes(data?.fieldName) ? auditKey?.[(data?.newValue?.locationType ?? data?.newValue?.event ?? data?.newValue?.type)] : data?.fieldName )

    if(isObject){
      if(data?.fieldName === "loadRoutingLocation") {
        rejectNewvalue = data?.newValue?.company_name
      }else if(data?.fieldName === "loadRoutingApptTimes"){
        _auditType += " " + (data?.newValue?.fromTo ? "("+data?.newValue?.fromTo+")" : "" )
        rejectNewvalue = data?.newValue?.date ? moment(data?.newValue?.date).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-"
      }
    }else if (moment(newValue, moment.ISO_8601, true).isValid()) {
      rejectNewvalue = moment(newValue).tz(getStorage("timeZone")).format(`${DateTimeFormatUtils.fullDateTimeFormat()}`);
    } else if (typeof newValue === 'boolean') {
      rejectNewvalue = Boolean(newValue) ? "True" : "False";
    } else if (newValue !== undefined) {
      rejectNewvalue = newValue;
    }
    return <div>
      {data?.fieldName === "items" && data?.newValue?.commodity ? this.displayCommodityRejectedData(data) : 
      <div className="auditField mb-1">
        <span class="badge badge-danger-light text-uppercase">
          {data?.action === STATUS?.AUTO_REJECTED ? "AUTO REJECTED" : "REJECTED" }
        </span> change for <span className="font-medium font-weight-bold mr-1">{_auditType}</span>
         to 
        <span className="font-medium font-weight-bold ml-1">{rejectNewvalue}</span>
        </div>}
      <div className="auditField">Rejected by <span className="font-medium font-weight-bold">{data?.updatedBy?.name} {data?.updatedBy?.lastName ? ` ${data?.updatedBy?.lastName}`: ""}</span> on  <span className="font-medium font-weight-bold">{createdAtDate}</span></div>
    </div>
  }

  rate_confirmation_requested(infos){
     return<div>
      <span class="badge badge-gray-100 badge-sm">REQUESTED</span>
     </div>
  }

  audit_VENDOR_CHARGE_SET_ADDED(infos) {
    const { data } = infos;

    return (
      <div className="d-flex gap-10 font-medium">
        New Vendor Charge Set <span className="badge badge-gray-100">{data?.charge_reference_number}</span> was added
        {data?.data?.company_name && <span className="font-bold">{data?.data?.company_name}</span>}
      </div>
    )
  }

  audit_VENDOR_CHARGE_SET_REMOVED(infos){
    const { charge_reference_number,data } = infos?.data ?? {};

    return (
      <div>
        Vendor Charge Set <span className="badge badge-danger-light">{charge_reference_number}</span> was Removed{" "}
        {data?.company_name && <span className="font-bold">{data?.company_name}</span>}
      </div>
    )
  }

  audit_VENDOR_CHARGE_PRICING_ADDED(infos) {
    const { data, charge_reference_number } = infos?.data ?? {};
    const { finalAmount, unit, perType, labelValue, freeDays, chargePerDay, description} = data?.pricing ?? {};

    return (
      <div>
        <div className="d-flex gap-10">
          <span className="badge badge-soft-green">ADDED VENDOR CHARGE PRICING</span>
          <div className="d-flex gap-10">
            <span>Vendor Charge Set #</span>
            <span className="badge badge-gray-100">{charge_reference_number}</span>
          </div>
        </div>
        <div className="mt-1 d-flex gap-10">
          {labelValue && <span className="font-bold">{labelValue}</span> }
          <span>(Price: {finalAmount ?? 0}, Unit: {unit ?? 0}, Type: {perType ?? ''}, Free Units: {freeDays ?? 0}, Per Units: {chargePerDay ?? 0}, Description: {description ?? ''})</span>
        </div>
      </div>
    )
  }

  audit_VENDOR_CHARGE_BILL_FROM_CHANGED(infos){
    const { data, charge_reference_number } = infos?.data ?? {};
    const { company_name } = data ?? {};

    return (
      <div className="d-flex align-items-center gap-5">
        <span className={"badge badge-soft-green"}>VENDOR CHANGED</span>
        Vendor Charge Set
        <span className="badge badge-gray-100">{charge_reference_number}</span>
        <span className="font-bold">{company_name}</span>
      </div>
    )
  }

  audit_VENDOR_CHARGE_BULK_STATUS_CHANGE(infos){
    const { data, charge_reference_number } = infos?.data ?? {};
    const { status } = data ?? {};

    return (
      <div>
        Vendor Charge Set <span className="badge badge-danger-light">{charge_reference_number}</span> was {status}.
      </div>
    )
  }

  audit_VENDOR_CHARGE_SET_BILLED(infos){
    const { charge_reference_number } = infos?.data ?? {};

    return (
      <div>
        Vendor Charge Set <span className="badge badge-gray-100">{charge_reference_number}</span> was billed.
      </div>
    )
  }

  audit_VENDOR_CHARGE_SET_APPROVED(infos) {
    const { data, charge_reference_number } = infos?.data ?? {};
    const { status } = data ?? {};

    return (
      <div className="d-flex align-items-center gap-5">
        <span className={"badge badge-soft-green"}>STATUS CHANGED</span>
        Vendor Charge Set
        <span className="badge badge-gray-100">{charge_reference_number}</span>
        <span className="font-bold">{status}</span>
      </div>
    )
  }

  audit_VENDOR_CHARGE_SET_UNAPPROVED(infos) {
    const { data, charge_reference_number } = infos?.data ?? {};
    const { status } = data ?? {};

    return (
      <div className="d-flex align-items-center gap-5">
        <span className={"badge badge-soft-green"}>STATUS CHANGED</span>
        Vendor Charge Set
        <span className="badge badge-gray-100">{charge_reference_number}</span>
        <span className="font-bold">{status}</span>
      </div>
    )
  }


  audit_VENDOR_CHARGE_PRICING_UPDATED(infos) {
    const { data, charge_reference_number } = infos?.data ?? {};
    const { finalAmount, unit, perType, labelValue, freeDays, chargePerDay, description } = data?.pricing ?? {};

    return (
      <div>
        <div className="d-flex gap-10">
          <span className="badge badge-soft-green">UPDATED VENDOR CHARGE PRICING</span>
          <div className="d-flex gap-10">
            <span>Vendor Charge Set #</span>
            <span className="badge badge-gray-100">{charge_reference_number}</span>
          </div>
        </div>
        <div className="mt-1 d-flex gap-10">
          {labelValue && <span className="font-bold">{labelValue}</span> }
          <span>(Price: {finalAmount ?? 0}, Unit: {unit ?? 0}, Type: {perType ?? ''}, Free Units: {freeDays ?? 0}, Per Units: {chargePerDay ?? 0}, Description: {description ?? ''})</span>
        </div>
      </div>
    )
  }

  audit_VENDOR_CHARGE_PRICING_REMOVED(infos){
    const { data, charge_reference_number } = infos?.data ?? {};
    const { finalAmount, unit, perType, labelValue, freeDays, chargePerDay, description } = data?.pricing ?? {};

    return (
      <div>
        <div className="d-flex gap-10">
          <span className="badge badge-danger-light">DELETED VENDOR CHARGE PRICING</span>
          <div className="d-flex gap-10">
            <span>Vendor Charge Set #</span>
            <span className="badge badge-gray-100">{charge_reference_number}</span>
          </div>
        </div>
        <div className="mt-1 d-flex gap-10">
          {labelValue && <span className="font-bold">{labelValue}</span> }
          <span>(Price: {finalAmount ?? 0}, Unit: {unit ?? 0}, Type: {perType ?? ''}, Free Units: {freeDays ?? 0}, Per Units: {chargePerDay ?? 0}, Description: {description ?? ''})</span>
        </div>
      </div>
    )
  }

  audit_BILL_PAYMENT(infos,value){
    const { data  , reference_number} = infos;
    const billNumber = data?.billNumber

    switch (value) {
      case "VENDOR_BILL_PAYMENT_ADDED":
        return (
          <div className="auditField">

              Bill Payment added in charge
              <b className="badge badge-gray-100 badge-sm m-2">{reference_number ?? ""}</b>,
              Bill number <b className="badge badge-gray-100 badge-sm m-2">{billNumber}</b>
              {data?.billData && (<><br></br>
              <span className="mr-2 font-weight-bold">Applied Payment Amount :</span>
              <span className="mr-2" >{data?.data?.amount?.toCurrency()}</span>
              <span className="mr-2 font-weight-bold">Bill Balance Due :</span>
              <span>{data?.billData?.totalRemainAmount?.toCurrency()}</span></>)}

          </div>
        )
        case "VENDOR_BILL_PAYMENT_REMOVED":
          return (
            <div className="auditField">

                  Bill Payment removed in charge
                  <b className="badge badge-gray-100 badge-sm m-2">{reference_number ?? ""}</b>,
                  Bill number <b className="badge badge-gray-100 badge-sm m-2">{billNumber}</b>
                {data?.billData && (<><br></br>
                  <span className="mr-2 font-weight-bold">Deleted Paid Amount :</span>
                  <span className="mr-2" >{data?.data?.amount?.toCurrency()}</span>
                  <span className="mr-2 font-weight-bold">Bill Balance Due :</span>
                  <span>{data?.billData?.totalRemainAmount?.toCurrency()}</span></>)}

              </div>
          )  
      default:
        break;
    }
    
  }

  VENDOR_BILL_ACCEPTED(infos) {
    const { data, billNumber } = infos?.data ?? {};
    const { status } = data ?? {};

    return (
      <div className="d-flex align-items-center gap-5">
        <span className={"badge badge-soft-green"}>STATUS CHANGED</span>
        Vendor Bill
        <span className="badge badge-gray-100">{billNumber}</span>
        <span className="font-bold">{status}</span>
      </div>
    )
  }
  
  VENDOR_BILL_DECLINED(infos) {
    const { data, billNumber } = infos?.data ?? {};
    const { status } = data ?? {};

    return (
      <div className="d-flex align-items-center gap-5">
        <span className={"badge badge-soft-green"}>STATUS CHANGED</span>
        Vendor Bill
        <span className="badge badge-gray-100">{billNumber}</span>
        <span className="font-bold">{status}</span>
      </div>
    )
  }
  
  MANUAL_EXPENSE_ADDED(infos){
    const { data, charge_reference_number } = infos?.data ?? {};
    const { finalAmount, unit, perType, name, freeDays, chargePerDay, description } = data?.expense ?? {};

    return (
      <div>
        <div className="d-flex gap-10">
          <span className="badge badge-soft-green">MANUAL EXPENSE ADDED</span>
          <div className="d-flex gap-10">
            <span>Charge Set #</span>
            <span className="badge badge-gray-100">{charge_reference_number}</span>
          </div>
        </div>
        <div className="mt-1 d-flex gap-10">
          {name && <span className="font-bold">{name.toUpperCase()}</span> }
          <span>(Price: {finalAmount ?? 0}, Unit: {unit ?? 0}, Type: {perType ?? ''}, Free Units: {freeDays ?? 0}, Per Units: {chargePerDay ?? 0}, Description: {description ?? ''})</span>
        </div>
      </div>
    )
  }

  EXPENSE_UPDATED(infos){
    const { data, charge_reference_number } = infos?.data ?? {};
    const { finalAmount, unit, perType, name, freeDays, chargePerDay, description } = data?.expense ?? {};

    return (
      <div>
        <div className="d-flex gap-10">
          <span className="badge badge-soft-green">EXPENSE UPDATED</span>
          <div className="d-flex gap-10">
            <span>Charge Set #</span>
            <span className="badge badge-gray-100">{charge_reference_number}</span>
          </div>
        </div>
        <div className="mt-1 d-flex gap-10">
          {name && <span className="font-bold">{name.toUpperCase()}</span> }
          <span>(Price: {finalAmount ?? 0}, Unit: {unit ?? 0}, Type: {perType ?? ''}, Free Units: {freeDays ?? 0}, Per Units: {chargePerDay ?? 0}, Description: {description ?? ''})</span>
        </div>
      </div>
    )
  }

  EXPENSE_REMOVED(infos){
    return (
      <div>
        <div className="d-flex gap-10">
          <span className="badge badge-danger-light">EXPENSE DELETED</span>
        </div>
      </div>
    )
  }

  audit_REMOVE_DOCUMENT_LOAD = (infos) => {
    const { data } = infos;
    const type = data?.type;
    const documentName = data?.url?.split('/')?.pop()
      return (
        <div className="auditField">
          <span className="mr-3 badge badge-danger-light badge-sm mb_2">DELETED DOCUMENT</span>
              <div className="mb-2">
                <span className="badge badge-gray-100 badge-sm">{documentName}</span>
                <span> of type </span>
                <span className="mr-3 font-weight-bold">{type}</span>
              </div>
        </div>
      )
  }

  audit_UPLOAD_DOCUMENT_LOAD = (infos) => {
    const { data } = infos;
    const type = data?.type;
    const documentName = data?.url?.split('/')?.pop()?.split('?')?.[0];    
      return (
        <div className="auditField">
          <span className="mr-3 badge badge-soft-green badge-sm mb_2">UPLOADED DOCUMENT</span>
              <div className="mb-2">
                <span className="badge badge-gray-100 badge-sm">{documentName}</span>
                <span> of type </span>
                <span className="mr-3 font-weight-bold">{type}</span>
              </div>
        </div>
      )
  }

  move_ACCEPTED = (infos) => {
    const { data } = infos;
    if(data.isFromTripUpdate){
      return (
        <span>
            Driver has accepted <span className="badge badge-gray-100 badge-sm"> Trip #{data.combineTripNumber}</span>
        </span>
      )
    }
    return (
      <span>
            Driver has accepted <span className="badge badge-gray-100 badge-sm">Container Move {data.containerMoveIndex}</span>
      </span>
    )
  }

  audit_CABOTAGE = (infos) => {
    const { data } = infos;
    const timeZone = getStorage("timeZone");
    if (data?.type === "TRUCK_ENTRY") {
      const formattedReturnDate = data?.shouldExitBy ? moment(data?.shouldExitBy).tz(timeZone).format(`${DateTimeFormatUtils.verboseDateFormat()}`) : null;
      return (
        <>
          {<div className="badge badge-gray-100 badge-sm">TRUCK ENTERED</div>}
          {formattedReturnDate && (
            <div>
              Return Day: <span className="font-medium">{formattedReturnDate}</span>
            </div>
          )}
          {data?.country && (
            <div>
              Country: <span className="font-medium">{data?.country ?? ""}</span>
            </div>
          )}
        </>
      );
    }
    if (data?.type === "TRUCK_EXIT") {
      const formattedCoolDownDate = data?.coolDownDate ? moment(data?.coolDownDate).tz(timeZone).format(`${DateTimeFormatUtils.verboseDateFormat()}`) : null;
      return (
        <>
          {<div className="badge badge-gray-100 badge-sm">TRUCK EXITED</div>}
          {formattedCoolDownDate && (
            <div>
              CoolDown Date: <span className="font-medium">{formattedCoolDownDate}</span>
            </div>
          )}
          {data?.country && (
            <div>
              Country: <span className="font-medium">{data?.country ?? ""}</span>
            </div>
          )}
        </>
      );
    }
    return (
      <>
        {data?.violation && (
          <div className="badge badge-danger">CABOTAGE VIOLATION</div>
        )}
        <div>{data?.message ?? ''}</div>
        {data?.cabotage !== null && data?.maxCabotage !== null && (
          <div>
            Cabotage:{" "}
            <span className="font-medium">{`${data.cabotage}/${data.maxCabotage}`}</span>
          </div>
        )}
        {data?.country && <div>Country: <span className="font-medium">{data?.country ?? ''}</span></div>}
      </>
    );
  };


  displayDifference(infos, prevUserId) {
    const { type } = infos;

    if (type === "LOAD_ADD_DUPLICATE") {
      return this.audit_LOAD_ADD_DUPLICATE(infos);
    } else if (type === "BUILD_STATUS_ORDER") {
      return this.audit_BUILD_STATUS_ORDER(infos);
    } else if (type === "LOAD_CHANGE_STATUS") {
      return this.audit_LOAD_CHANGE_STATUS(infos);
    } else if (type === "MOBILEAPP_LOAD_STATUS_UPDATE") {
      return this.audit_MOBILEAPP_LOAD_STATUS_UPDATE(infos);
    } else if (type === "MOBILEAPP_DRIVER_ORDER_UPDATE" 
                || type === "GEO_FENCE_CHANGE") {
      return this.audit_MOBILEAPP_DRIVER_ORDER_UPDATE(infos);
    } else if (type === "ASSIGN_LOAD_DRIVER") {
      return this.audit_ASSIGN_LOAD_DRIVER(infos);
    } else if (type === "HARD_REMOVE_DRIVER") {
      return this.audit_HARD_REMOVE_DRIVER(infos);
    } else if (type === "LOAD_EDIT") {
      return this.audit_LOAD_EDIT(infos, prevUserId);
    } else if (type === "UPDATE_LOAD_STATUSORDER") {
      return this.audit_UPDATE_LOAD_STATUSORDER(infos);
    } else if (type === "LOAD_READY_FOR_PICKUP") {
      return this.audit_LOAD_READY_FOR_PICKUP(infos);
    } else if (type === "LOAD_CONFIRM") {
      return this.audit_LOAD_CONFIRM(infos);
    } else if (type === "LOAD_ACCEPT") {
      return this.audit_LOAD_ACCEPT(infos);
    } else if (type === "ASSIGN_COMPANY") {
      return this.audit_ASSIGN_COMPANY(infos);
    } else if (type === "PAYMENT_ADD") {
      return this.audit_PAYMENT_ADD(infos);
    } else if (type === "PAYMENT_REMOVED") {
      return this.audit_PAYMENT_REMOVE(infos);
    } else if (type === "STREET_TURN") {
      return this.audit_STREET_TURN(infos);
    } else if (type === "DOMESTIC_MOVE") {
      return this.audit_DOMESTIC_MOVE(infos);
    } else if (type === 'PRICING_UPDATE') {
      return this.audit_PRICING_UPDATE(infos);
    } else if(type === "DRIVER_LOAD_REJECTED") {
      return this.audit_DRIVER_LOAD_REJECTED(infos);
    } else if (['REMOVED_CSR','ADDED_CSR'].includes(type)){
      return this.audit_CSR_CHANGE(infos)
    } else if(type === "NOTES_UPDATE") {
      return this.audit_NOTES_CHANGE(infos)
    } else if(type === "LOAD_ADD_QUOTE") {
      return this.audit_LOAD_ADD_QUOTE(infos)
    }else if(type === "DRIVER_PAY_APPROVAL") {
      return this.audit_DRIVER_PAY_CHANGE(infos)
    }else if(type === "REMOVE_FIELD"){
      return this.audit_REMOVE_FIELD(infos,prevUserId)
    } else if(type === "SUB_BILL_ADDED" || type === "PER_DIEM_INVOICE_ADDED"){
      return this.audit_SUB_BILL_ADDED(infos)
    } else if(type === "SUB_BILL_REMOVED"){
      return this.audit_SUB_BILL_REMOVED(infos)
    } else if(type === 'SUB_BILL_PRICING_ADDED' || type === 'SUB_BILL_PRICING_PER_DIEM_ADDED'){
      return this.audit_SUB_BILL_PRICING_ADDED(infos)
    } else if(type === 'SUB_BILL_PRICING_UPDATED'){
      return this.audit_SUB_BILL_PRICING_ADDED(infos, true)
    } else if( type === 'SUB_BILL_CUSTOMER_UPDATED'){
      return this.audit_SUB_BILL_CUSTOMER_UPDATED(infos)
    } else if(type === "SUB_BILL_PRICING_REMOVED"){
      return this.audit_SUB_BILL_PRICING_REMOVED(infos)
    } else if(type === "SUB_BILL_BILLING_DATE_UPDATED"){
      return this.audit_SUB_BILL_BILLING_DATE_UPDATED(infos)
    } else if( type === 'SUB_BILL_STATUS_CHANGED'){
      return this.audit_SUB_BILL_STATUS_CHANGED(infos)
    } else if (type === "MYAPP_UPDATE") {
      return this.myapp_update(infos, prevUserId)
    } else if (type == "LOAD_DEFAULT_TAX_TYPE_CHANGE") {
      return this.audit_DEFAULT_TAX_TYPE_UPDATED(infos)
    } else if (type == "LOAD_TAX_UPDATED"){
      return this.audit_TAX_AMOUNT_UPDATED(infos)
    }else if (type === "PER_DIEM_AUDIT_STATUS_CHANGE"){
      return this.audit_PER_DIEM_AUDIT_STATUS_CHANGE(infos)
    }else if (type === "DRIVER_PAY_UPDATE" || type === "DRIVER_PAY_UPDATE_CONDITION_MET"){
      return this.audit_DRIVER_PAY_UPDATE(infos);
    } else if (type === "SUB_QB_INVOICE_ID_CHANGED"){
      return this.audit_SUB_QB_INVOICE_ID_CHANGED(infos)
    } else if(type === "QB_INVOICE_ID_CHANGED"){
      return this.audit_QB_INVOICE_ID_CHANGED(infos)
    }else if (type == "DRIVER_ORDER_UPDATE"){
      return this.audit_DRIVER_ORDER_UPDATE(infos);
    } else if (type === "APPOINTMENT_LOAD_EDIT") {
      return this.audit_APPOINTMENT_LOAD_EDIT(infos);
    } else if (type === "TRIP_LINKED") {
      return this.audit_TRIP_LINKED(infos);
    } else if (type === "LOAD_ADD") {
      return this.audit_ADD_LOADS(infos)
    } else if (["LOAD_ADD_BULKUPLOAD", "LOAD_ADD_ONLINE_TENDER", "LOAD_ADD_TENDER"].includes(type)) {
      return this.audit_ADD_LOADS(infos)
    } else if (type === "CHARGE_PRICING_ADDED") {
      return this.audit_CHARGE_PRICING_ADD(infos)
    } else if (type === "CHARGE_PRICING_UPDATED"){
      return this.audit_CHARGE_PRICING_UPDATE(infos)
    }else if( type == "CHARGE_PRICING_REMOVED"){
      return this.audit_CHARGE_PRICING_DELETED(infos)
    }else if (type == "CHARGE_GROUP_ADDED"){
      return this.audit_CHARGE_GROUP(infos,"CHARGE_GROUP_ADDED")
    }else if(type == "CHARGE_GROUP_REMOVED"){
      return this.audit_CHARGE_GROUP(infos,"CHARGE_GROUP_REMOVED")
    }else if(type == "CHARGE_GROUP_BILLTO_CHANGED"){
      return this.audit_CHARGE_GROUP(infos,"CHARGE_GROUP_BILLTO_CHANGED")
    }else if(type == "BILLING_NOTE_ADDED"){
      return this.audit_BILLING_NOTES(infos);
    }else if(type == "BILLING_NOTE_UPDATED"){
      return this.audit_BILLING_NOTES(infos);
    }else if(type == "BILLING_NOTE_REMOVED"){
      return this.audit_BILLING_NOTES(infos);
    }else if (type == "CHARGE_GROUP_APPROVED"){
      return this.audit_CHARGE_GROUP_STATUS(infos);
    }else if (type == "CHARGE_GROUP_UNAPPROVED"){
      return this.audit_CHARGE_GROUP_STATUS(infos);
    }else if (type == "CHARGE_GROUP_BILLED"){
      return this.audit_CHARGE_GROUP_STATUS(infos);
    }else if (type == "CHARGE_GROUP_REBILL"){
      return this.audit_CHARGE_GROUP_STATUS(infos);
    }else if(type=="CHARGE_GROUP_DEFAULT_TAX_TYPE_CHANGED"){
      return this.audit_CHARGE_GROUP_DEFAULT_TAX_TYPE(infos);
    }else if(type=="VENDOR_CHARGE_DEFAULT_TAX_TYPE_CHANGED"){
      return this.audit_VENDOR_CHARGE_DEFAULT_TAX_TYPE(infos);
    }else if(type == "INVOICE_PAYMENT_ADDED"){
      return this.audit_PAYMENT(infos,"INVOICE_PAYMENT_ADDED");
    }else if(type=="INVOICE_PAYMENT_REMOVED"){
      return this.audit_PAYMENT(infos,"INVOICE_PAYMENT_REMOVED");
    } else if (type === "LOAD_ADD_AUTOMATICALLY") {
      return this.audit_ADD_LOADS(infos)
    }else if( type == "CHARGE_PRICING_PASTED"){
      return this.audit_CHARGE_PRICING_PASTED(infos)
    } else if (type === "AUTO_APPOINTMENT_EDIT") {
      return this.audit_AUTO_APPOINTMENT_EDIT(infos)
    } else if (type == "CREDIT_MEMO_APPLIED" || type == "CREDIT_MEMO_REMOVED"){
      return this.audit_CREDIT_MEMO_PAYMENTS(infos, type)
    } else if (type == 'VOID_INVOICE'){
      return this.audit_VOID_INVOICE(infos)
    } else if ([...routing_audit_type].includes(type)) {
      return this.audit_ROUTING_SERVICE(infos)
    } else if (type === "LOAD_EDIT_REJECTED"){
      return this.audit_LOAD_EDIT_REJECTED(infos);
    }
    else if (type === "RATE_CONFIRMATION_REQUESTED"){
      return this.rate_confirmation_requested(infos)
    } else if (type === "VENDOR_CHARGE_SET_APPROVED") {
      return this.audit_VENDOR_CHARGE_SET_APPROVED(infos);
    } else if (type === "VENDOR_CHARGE_SET_UNAPPROVED") {
      return this.audit_VENDOR_CHARGE_SET_UNAPPROVED(infos);
    } else if (type === "VENDOR_CHARGE_PRICING_UPDATED") {
      return this.audit_VENDOR_CHARGE_PRICING_UPDATED(infos);
    } else if (type === "VENDOR_CHARGE_PRICING_REMOVED") {
      return this.audit_VENDOR_CHARGE_PRICING_REMOVED(infos);
    } else if (type === "VENDOR_CHARGE_SET_ADDED") {
      return this.audit_VENDOR_CHARGE_SET_ADDED(infos);
    } else if (type === "VENDOR_CHARGE_SET_REMOVED") {
      return this.audit_VENDOR_CHARGE_SET_REMOVED(infos);
    } else if (type === "VENDOR_CHARGE_PRICING_ADDED") {
      return this.audit_VENDOR_CHARGE_PRICING_ADDED(infos);
    } else if (type === "VENDOR_CHARGE_BILL_FROM_CHANGED") {
      return this.audit_VENDOR_CHARGE_BILL_FROM_CHANGED(infos);
    } else if (type === "VENDOR_CHARGE_BULK_STATUS_CHANGE") {
      return this.audit_VENDOR_CHARGE_BULK_STATUS_CHANGE(infos);
    } else if (type === "VENDOR_CHARGE_SET_BILLED") {
      return this.audit_VENDOR_CHARGE_SET_BILLED(infos);
    } else if (type === "VENDOR_BILL_PAYMENT_ADDED") {
      return this.audit_BILL_PAYMENT(infos,"VENDOR_BILL_PAYMENT_ADDED");
    } else if (type === "VENDOR_BILL_PAYMENT_REMOVED") {
      return this.audit_BILL_PAYMENT(infos,"VENDOR_BILL_PAYMENT_REMOVED");
    } else if (type === "VENDOR_BILL_ACCEPTED"){
      return this.VENDOR_BILL_ACCEPTED(infos,"VENDOR_BILL_ACCEPTED");
    } else if (type === "VENDOR_BILL_DECLINED"){
      return this.VENDOR_BILL_DECLINED(infos,"VENDOR_BILL_DECLINED");
    } else if(type === "MANUAL_EXPENSE_ADDED"){
      return this.MANUAL_EXPENSE_ADDED(infos , "MANUAL_EXPENSE_ADDED");
    } else if(type === "EXPENSE_UPDATED"){
      return this.EXPENSE_UPDATED(infos , "EXPENSE_UPDATED");
    } else if (type === "EXPENSE_REMOVED"){
      return this.EXPENSE_REMOVED(infos , "EXPENSE_REMOVED");
    } else if (type === "REMOVE_DOCUMENT_LOAD"){
      return this.audit_REMOVE_DOCUMENT_LOAD(infos);
    } else if (type === "UPLOAD_DOCUMENT_LOAD"){
      return this.audit_UPLOAD_DOCUMENT_LOAD(infos);
    } else if(type === "MOVE_ACCEPTED"){
      return this.move_ACCEPTED(infos , "MOVE_ACCEPTED");
    } else if(type === "CABOTAGE_POINT_AUDIT"){
      return this.audit_CABOTAGE(infos);
    }
    
    return "";
  }

  getAssignedName(data, oldData) {
    if (oldData?.company) return oldData.company;

    if (data?.driverName) return data.driverName;

    const driverData = data?.find(singleData => singleData?.driver)?.driver;

    if (driverData?.name || driverData?.lastName) {
      return `${driverData?.name ? driverData?.name : ""} ${driverData?.lastName ? driverData?.lastName : ""}`
    }
    return null;
   };

  renderReturn = () => {
    return (
      <div className="tab" id="tab-document">
        <div className="card px-20 py-10 mb-0 mt-10 align-items-center flex-row">
          <div className="app-search">
            <input
              type="text"
              className="input-search-left form-control"
              placeholder="Search.."
              onChange={(e) => {
                this.handleChangeOfSearch(e);
              }}
            />
            <span className="search-icon"></span>
          </div>
          <div class="d-flex ml-auto">
            <FilterAuditLoadList auditList={this.state.originalAuditList} handleFilterAuditList={this.handleFilterAuditList}/>
          </div>          
        </div>
        <div className="mb-20">
          <table
            id="auditTable"
            className="table table-card table-card--wrap mb-10"
          >
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Time</th>
                {this.state.auditList?.some((each) => each?.type === "APPOINTMENT_LOAD_EDIT" && checkIfPortSupportsScreengrab(each?.data?.portCode)) && (<th></th>)}
                <th>Data</th>
              </tr>
            </thead>
          
            <tbody>
              {
                this.state.auditList.map((info, key) => {
                  if(info.type === "MYAPP_UPDATE"){
                    delete info.data.documents
                    delete info.data.isDrayosTrackosError
                    if(_.isEmpty(info.data)){
                       return 
                    }
                  }
                  if(info.type==="LOAD_EDIT" && !info.userId){
                    delete info?.data?.message;
                  }
                  let prevUserId = null;
                  if (
                    key !== this.state.auditList.length - 1 &&
                    (this.state.auditList[key + 1].userId ||this.state.auditList[key + 1].userData)
                  ) {
                    prevUserId = this.state.auditList[key + 1].userData??this.state.auditList[key + 1].userId;
                  }
                  if (
                    info.type === "LOAD_EDIT" && 
                    Object.keys(
                      differnceFinder(
                        info?.previousData ?? info?.additionalInfo,
                        info.data,
                        info?.userData ?? info.userId,
                        prevUserId
                      )
                    ).length === 0
                  ) {
                    return;            
                  }   
                  
                  if (info?.data && Object.keys(info?.data)?.length === 1 && info.data?.hasOwnProperty('portCode')) {
                    return;
                  }
                  
                  let tdVal = this.displayDifference(info, prevUserId);
                  if (
                    !['LOAD_EDIT', 'PRICING_UPDATE'].includes(info.type) || 
                    (tdVal?.props?.children &&
                    _.compact(tdVal?.props?.children)?.length > 0)
                  ) {
                    return (
                      <tr key={info._id} id={info._id}>
                        <td>
                          <div className="d-flex align-items-center">
                          <span className="avatar-circle avatar-xs mr-1">
                            {this.getUserAvatar(info)}
                          </span>{" "}
                          {this.getUserName(info)}
                          </div>
                        </td>
                        <td>
                          {auditType[info.type] && (
                            <span className="badge badge-gray-100 badge-sm">
                              {auditType[info.type]}{" "}
                            </span>
                          )}
                        </td>
                        <td>
                          <div>
                            {moment(info.createdAt)
                            .tz(getTimeZone({preferred: true}))
                            .format(DateTimeFormatUtils.fullDateFormat())}
                          </div>
                          <div className="text-muted">
                            {moment(info.createdAt)
                            .tz(getTimeZone({preferred: true}))
                            .format(`${DateTimeFormatUtils.timeFormat()}`)}
                          </div>
                        </td>
                        {this.state.auditList?.some((each) => each?.type === "APPOINTMENT_LOAD_EDIT" && checkIfPortSupportsScreengrab(each?.data?.portCode)) && (
                          <td 
                            className="text-center pointer" 
                            onClick={() => this.handleShowScreenGrabsModal(info)}>
                              {info.type === "APPOINTMENT_LOAD_EDIT" && 
                              <span
                                data-tip="Click to view the screenshots"
                                data-for="appointment-screenshots"
                              >
                                <IconFile />
                                <ReactTooltip id="appointment-screenshots" />
                              </span>
                              }
                          </td>
                        )}
                        <td>
                          <div className="auditField">{tdVal}</div>
                        </td>
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
          {this.state.showScreenGrabsModal &&
            this.state.selectedAuditData &&
            (this.props.selectedLoads?.referenceNumber || this?.props?.location?.query?.reference_number || this?.state?.selectedAuditData?.reference_number) && (
              <ScreenGrabsModal
                show={this.state.showScreenGrabsModal}
                hide={this.handleHideScreenGrabsModal}
                auditData={this.state.selectedAuditData}
                referenceNumber={
                  this.props.selectedLoads?.reference_number || this.props.location?.query?.reference_number || this?.state?.selectedAuditData?.reference_number
                }
              />
            )}
        </div>
      </div>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (loggedInUser.role === "carrier") return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (loggedInUser.permissions.includes("customer_service_audit"))
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch: dispatch,
  };
}

const states = (state) => ({
  driverList: state.TmsReducer.drivers,
  ContainerSize: state.containerReducer.containerSize,
  ContainerType: state.containerReducer.containerType,
  IsoContainerType: state.containerReducer.isoContainerType,
  TMSContainerOwner: state.containerReducer.containerOwner,
  ChassisType: state.chassisReducer.chassisType,
  ChassisSize: state.chassisReducer.chassisSize,
  TMSChassisOwner: state.chassisReducer.chassisOwner,
  terminals: state.HomeReducer.terminals,
});

export default connect(states, mapDispatchToProps)(withRouter(AuditLoad));
