import { browserHistory } from "react-router";
import { HTTP } from "./Http.service";
import { AddProfile } from "../pages/tms/services";
import axios from "axios";
import config from "../config";
import moment from "moment";
import _ from "lodash";
import ReactDOMServer from 'react-dom/server';
import * as amplitude from '@amplitude/analytics-browser';
import themesOptions from "../pages/tms/Profile/themeOptions";
import jsonToQueryParams, { objToQueryParams } from "../Components/Common/jsonToQueryParams";
import { createDispatcherFilter } from "../pages/tms/Dispatcher/DispatcherView/actionCreators";
import { RANGES } from "../pages/tms/Dispatcher/DispatcherView/constant";
import { countriesList } from "./countriesList";
import { getSelectedDate } from "./../Components/Common/DispatcherDayCalenderFilter/dateFilter"
import { getTaxRates } from "../pages/tms/Load/actionCreator";
import { SEPARATE_WITH, tmsDrops } from "../pages/tms/constant";

import * as turf from "@turf/turf";
import { getEmailByPermissions, getValidDefaultAccount, isEmbeddedEmailConnected, sortAllMailBasedOnDefaul } from "pages/tms/Email/helper";
import { adminEmployeePermissions } from "Components/SupportRole/Components/AdminUsers/constant";
import { getPlaceDetails } from "pages/tms/Customer/actionCreators";
import { IconCloseBold } from "Components/Common/Icons";

let groupByLoadsh = require("lodash/groupBy");

let terminalIds = [];

export const limit = 500;

export const defaultAddressFormat = () =>
  JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ??
  "us";

export const isTrialUser = ()=>{ 
  const trial =  JSON.parse(localStorage.getItem('loggedInUser'))
  if(trial && trial.isTrialUser){
     return trial.isTrialUser
  }else return false
}
export const disableScreen =(pageClassName)=> {
    var div= document.createElement("div");
    div.className += "freemium";
    if(pageClassName){
      document.querySelector(pageClassName).appendChild(div);
    }
}

const camelize = (str) => {
  return str.replace(/\W+(.)/g, function (match, chr) {
    return chr.toUpperCase();
  });
};

export function addCustomerCommonFunction(data) {
  return new Promise((resolve, reject) => {
    if (data.documentFile && !data.fileType) {
      toastr.show("You must choose a type.", "warning");
      this.setState({ tab: 2 });
      return;
    }
    let customerProfileData = JSON.parse(JSON.stringify(data));
    delete customerProfileData?.formattedAddress1;
    customerProfileData.address = {
      address: customerProfileData.address,
      lat: customerProfileData.lat ? customerProfileData.lat : 12,
      lng: customerProfileData.lng ? customerProfileData.lng : 12,
      address1: data.address1,
      city: data.city,
      state: data.state,
      country: data.country,
      zip_code: data.zip_code
    };
    customerProfileData.companyID =
      camelize(customerProfileData.company_name) +
      Math.floor(Math.random() * 10000 + 1);

    delete customerProfileData.lat;
    delete customerProfileData.lng;
    if (customerProfileData.createdAt) {
      delete customerProfileData.createdAt;
    }
    if (customerProfileData.updatedAt) {
      delete customerProfileData.updatedAt;
    }
    delete customerProfileData.__v;
    delete customerProfileData.carrier;
    delete customerProfileData.SyncToken;
    delete customerProfileData.qbId;
    if (customerProfileData.mobile) {
      customerProfileData.mobile = cleanMobileNumber(customerProfileData.mobile) 
    }

    if (!customerProfileData._id && !customerProfileData.password) {
      customerProfileData.password = "123456";
    }

    if (data.documentFile && data.fileType) {
      customerProfileData.document = data.documentFile;
      customerProfileData.fileType = data.fileType;
      customerProfileData.filename = `${new Date().getTime().toString()}`;
      delete data.documentFile;
      delete customerProfileData.documentFile;
    }
    if (!customerProfileData.notes || customerProfileData.notes === "") {
      delete customerProfileData.notes;
    }
    Object.keys(customerProfileData).forEach((key) => {
      if (customerProfileData[key] === null) {
        delete customerProfileData[key];
      }
    });
    AddProfile("carrier/addTMSCustomer", customerProfileData)
      .then((result) => {
        toastr.show("Successfully added", "success");
        clearLocalCache(customerApiCacheKeys())
        resolve(result);
      })
      .catch((error) => {
        reject();
      });
  });
};

export async function setStorage(key, data) {
  const value = await localStorage.setItem(key, data);
  return value;
}

export function getStorage(key) {
  let value = localStorage.getItem(key);
  if (key === "timeZone") {
    value = new RegExp(/null/, "i").test(value) ? `America/New_York` : value;
  }
  return value;
}

export function convertDateWithTimezoneToEquivalentUTC (date, timeZone) {
  // Parse the date as UTC, then find the equivalent local time in the given timezone
  const localTime = moment.utc(date).tz(timeZone);

  // Calculate the offset in minutes between the local time zone and UTC, then adjust the date
  const offset = localTime.utcOffset();
  const adjustedDate = moment.utc(date).add(offset, 'minutes').toISOString();

  return adjustedDate;
}

export function getCurrentDateWithTimeZone (timeZone) {
  const date = moment().toISOString();
  return convertDateWithTimezoneToEquivalentUTC(date, timeZone)
}

export function checkTMXPermission(key) {
  let value = localStorage.getItem('loggedInUser');
  let isValid = false
  if(value){
    let user = JSON.parse(value)
    if(user.role === 'carrier'){
      if(user.featurePermission && user.featurePermission.length && user.featurePermission.indexOf(key) > -1){
        isValid = true
      }
    } else {
      let carrier = localStorage.getItem('carrierDetail');
      if(carrier){
        let carrierData = JSON.parse(carrier)
        if(carrierData.featurePermission && carrierData.featurePermission.length && carrierData.featurePermission.indexOf(key) > -1){
          isValid = true
        }
      }
    }
  }
  return isValid;
}

export function removeItem(key) {
  return localStorage.removeItem(key);
}

export async function clearStorage() {
  let clear = await localStorage.clear();
  sessionStorage.clear();
  return clear;
}


export function setSessionStorage(key, data, isJson=false) {
  sessionStorage.setItem(key, isJson ? JSON.stringify(data) : data);
  return  sessionStorage.getItem(key);
}

export function getSessionStorage(key, isJson=false) {
  let value = sessionStorage.getItem(key);
  if(key==="timeZone"){
    value= value === "null"?`America/New_York`:value
  }
  return isJson ? JSON.parse(value) : value;
}

export function removeSessionItem(key) {
  return sessionStorage.removeItem(key);
}

export function clearSessionStorage() {
  let clear =  sessionStorage.clear();
  return clear;
}

export function redirection() {
  browserHistory.push("/");
  return;
}

export function customerRedirection() {
  browserHistory.push("/customer-shipments");
  return;
}

export function clearSession() {
  localStorage.clear();
  sessionStorage.clear();
  return;
}

export async function defaultAccount(token) {
  const response = HTTP("get", "getDefaultAccount", null, {
    Authorization: token,
  });
  return response;
}

export async function getAllUsers() {
  const response = await HTTP(
    "get",
    `admin/getAllUsers`,
    {},
    { Authorization: `Bearer ${getStorage("@AdminToken")}` }
  );
  return response;
}

export async function getCarrierByUserId(id) {
  const response = await HTTP(
    "get",
    `getFleetCarrier?carrier=${id}`,
    {},
    { Authorization: `${getStorage("token")}` }
  );
  return response;
}

export async function getCustomer(id) {
  const response = await HTTP(
    "get",
    `tms/getCustomer`,
    {},
    { Authorization: `${getStorage("token")}` }
  );
  return response;
}

export function renderDuty(data) {
  let status;
  switch (data) {
    case "D":
      status = "DRIVING";
      break;
    case "SL":
      status = "SLEEPER-BERTH";
      break;
    case "OFF":
      status = "OFF-DUTY";
      break;
    case "ON":
      status = "ON-DUTY";
      break;
    default:
      status = "OFF-DUTY";
  }
  return status;
}

export function getYear() {
        let rows = [];
        for (var i = 2035; i >= 1970; i--) {
          rows.push(i);
        }
        return rows;
}

export function getMake() {
  return [
    "American LaFrance",
    "Autocar",
    "Bering",
    "Chevrolet",
    "Dodge",
    "Ford",
    "Freightliner",
    "GMC",
    "Hino",
    "International",
    "Isuzu",
    "Kenworth",
    "Mack",
    "Marmon",
    "Mercedes",
    "Mitsubishi",
    "Nissan",
    "Peterbilt",
    "Volvo",
    "Western Start",
  ];
}

export function getFuel() {
  return [
    "Diesel",
    "Gasoline",
    "Propane",
    "Liquid Natural Gas",
    "Compressed Natural Gas",
    "Ethanol",
    "Methanol",
    "E-85",
    "M-85",
    "A55",
    "Biodiesel",
    "Other",
  ];
}

export async function getGooglePlaces(query) {
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyD1WC-07rfhCPOaSCmuvKDr5-x5nbquf04&address=" +
    encodeURIComponent(query +
    "");
  const response = await axios({
    method: "get",
    url: url,
  });
  return response;
}

export async function getGoogleAutoPlaces(query) {
  let url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&language=pt_BR&key=AIzaSyBr2wqrbPk4xxs8vS3qvZRiMrlI3vVx7fo`;
  
  let response = await axios({
    method: "get",
    url: url,
    headers: { }
  });
  console.log('response', JSON.stringify(response))
  return response;
}

export async function getGooglePlacesAutoComplete(query) {
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyD1WC-07rfhCPOaSCmuvKDr5-x5nbquf04&address=" +
    encodeURIComponent(query +
    "");
  const response = await axios({
    method: "get",
    url: url,
  });
  const locations = [];
  response.data.results.forEach((obj) => {
    locations.push({
      value: obj.formatted_address,
      label: obj.formatted_address,
      allInfos: {
        address: obj.formatted_address,
        lat: obj.geometry.location.lat,
        lng: obj.geometry.location.lng,
      },
    });
  });
  return locations;
}

export async function getTmsLocation(params) {
  const token = getStorage("token");
  let url = "carrier/getTMSCustomers";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    `${url}`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getPlace(query) {
  let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${config.map_box_api_key}&country=us`;
  const response = await axios({
    method: "get",
    url: url,
  });
  return response;
}

export const groupBy = (array, callback) => {
  var groups = {};
  array.forEach((o) => {
    var group = JSON.stringify(callback(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};

export const roles = {
  carrier: "carrier",
  customer: "customer",
  dispatcher: "dispatcher",
  customer_service: "customer_service",
  safety_manager: "safety_manager",
  accounting: "accounting",
  billing: "billing",
  mechanic: "mechanic",
};

export async function getLoadForCustomer() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/getLoadsForCustomer`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function changeQbSetting(type, data) {
  const token = getStorage("token");
  const newFormData = new FormData();
  if (type == "qbConnection") {
    newFormData.append("qbConnection", data);
  }
  if (type == "qbDesktop") {
    newFormData.append("qbDesktop", data);
  }
  if (type == "driverPreferences") {
    newFormData.append("driverPreferences", data);
  }
  if (type == "showTerminalData") {
    newFormData.append("showTerminalData", data);
  }
  if (type == 'requireReference'){
    newFormData.append("requireReference", data);
  }
  if(type == 'driverAccountHold'){
    newFormData.append("driverAccountHold", data);
  }
  if(type == 'isAllowSystem'){
    newFormData.append("isAllowSystem", data);
  }
  if(type === "isNegativeDriverSettlementAmount"){
    newFormData.append("isNegativeDriverSettlementAmount", data)
  }
  clearGivenCache(cacheURLKeys().getUser)
  const response = await HTTP("post", `user/changeQbSetting`, newFormData, {
    authorization: token,
  });
  return response;
}

export async function changeUserSettings(type, data) {
  const token = getStorage("token");
  const newFormData = new FormData();

  if(type == 'isDocRequiredToInvoice'){
    newFormData.append("isDocRequiredToInvoice", data);
  }
  if (type == 'isContainerNoValid') {
    newFormData.append("isContainerNoValid", data)
  }
  if(type == 'isUniqueReferenceNo'){
    newFormData.append("isUniqueReferenceNo", data);
  }
  if(type == 'isEquipmentValidations') {
    newFormData.append("isEquipmentValidations", data);
  }
  if (type == "isShowDeliveryReferenceField"){
    newFormData.append('isShowDeliveryReferenceField', data);
  }
  if (type == "isShowTrackingDistanceTime"){
    newFormData.append('isShowTrackingDistanceTime', data);
  }
  if (type == "allowedDocumentTypesForDriver") {
    newFormData.append('allowedDocumentTypesForDriver', data);
  }
  if (type == "isContainerNoValidationDisable") {
    newFormData.append('isContainerNoValidationDisable', data);
  }
  if(type === "isAppendSealNoAllowed"){
    newFormData.append("isAppendSealNoAllowed", data);
  }
  if(type === "isShowDriverWorkingDaysHours"){
    newFormData.append("isShowDriverWorkingDaysHours", data);
  }
  if(type === "isShowLiftOffReferenceField"){
    newFormData.append("isShowLiftOffReferenceField", data);
  }
  const response = await HTTP("post", `user/changeUserSettings`, newFormData, {
    authorization: token,
  });
  return response;
}
export async function deletFleetCustomer(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/removeFleetCustomer`, data, {
    authorization: token,
  });
  return response;
}
export async function deletTmsCustomer(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `carrier/removeTMSCustomer`, data, {
    authorization: token,
  });
  clearLocalCache(customerApiCacheKeys())
  return response;
}

export async function getInvoice(params) {
  // console.log("alert===========");
  const token = getStorage("token");
  const newFormData = new FormData();
  newFormData.append("reference_number", params);
  newFormData.append("print", true);
  const response = await HTTP(
    "POST",
    "tms/sendEmailDocumentOfLoad",
    newFormData,
    {
      authorization: token,
    }
  );
  return response;
}

export async function approveDeduction(params) {
  const token = getStorage("token");
  const newFormData = new FormData();
  newFormData.append("approved", params.approved);
  newFormData.append("_id", params._id);
  if (params.settlementNo) {
    newFormData.append("settlementNo", params.settlementNo);
  }
  newFormData.append("startDate", params.startDate);
  newFormData.append("endDate", params.endDate);
  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }

  const response = await HTTP("put", `tms/deduction/approve`, newFormData, {
    authorization: token,
  });
  return response;
}

export async function approveNewDeduction(params) {
  const token = getStorage("token");
  const newFormData = new FormData();
  newFormData.append("approved", params.approved);
  newFormData.append("_id", params._id);
  if (params.settlementNo) {
    newFormData.append("settlementNo", params.settlementNo);
  }
  if (params.startDate) {
    newFormData.append("startDate", params.startDate);
  }
  if (params.endDate) {
    newFormData.append("endDate", params.endDate);
  }
  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }
  if (params.from) {
    newFormData.append("from", params.from);
  }
  const response = await HTTP("put", `tms/deduction/new-approve`, newFormData, {
    authorization: token,
  });
  return response;
}

export async function searchLoads(params) {
  const token = getStorage("token");
  const response = await HTTP(
    "post",
    `tms/getLoads`,
    { searchTerm: params.searchTerm },
    {
      authorization: token,
    }
  );
  return response;
}

export async function getTranscation(params) {
  const token = getStorage("token");
  let url = "tms/transcation";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getNewTranscationDriverPay(params) {
  //used by the driver pay
  params.timeZone = getStorage("timeZone");
  const token = getStorage("token");
  let fullUrl;
  fullUrl = `${config.newDocUrl}report/new-transcation`;
  fullUrl = params ? fullUrl + "?" + jsonToQueryParams(params) : fullUrl;
  return new Promise((resolve, reject) => {
    HTTP("GET", null, params, { authorization: token }, fullUrl)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getNewTranscation(params) {
  const addressFormat = JSON.parse(getStorage("userAddressFormat"))?.userAddressFormat ?? addressFormatEnum.US;
  const token = getStorage("token");
  let url = "tms/new-transcation";

  params.addressFormat = addressFormat;
  if(!params?.from){
    params.from = ""
  }
  if(!params?.to){
    params.to = ""
  }
  url = params
    ? url +
      "?" +
      objToQueryParams(params)
    : url;
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function updateTranscation(params) {
  const token = getStorage("token");
  let url = "tms/transcation";
  const newFormData = new FormData();
  newFormData.append("id", params.id);
  if (params.amount) {
    newFormData.append("amount", params.amount);
  }
  if (params.additionalPrice) {
    newFormData.append("additionalPrice", params.additionalPrice);
  }
  newFormData.append("isFromSettlement", params.isFromSettlement);
  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }
  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function updateNewTranscation(params) {
  const token = getStorage("token");
  let url = "tms/new-transcation";
  const newFormData = new FormData();
  newFormData.append("id", params.id);
  if (params.containerNo) {
    newFormData.append("containerNo", params.containerNo);
  }
  if (params.amount|| params.amount===0) {
    newFormData.append("amount", params.amount);
  }
  if (params.additionalPrice) {
    newFormData.append("additionalPrice", params.additionalPrice);
  }

  newFormData.append("isFromSettlement", params.isFromSettlement);

  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if(params.chargeCode){
    newFormData.append("chargeCode", params.chargeCode);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }
  if (params.from) {
    newFormData.append("from", params.from);
  }
  if (params.to) {
    newFormData.append("to", params.to);
  }
  if (params.isManual) {
    newFormData.append("isManual", params.isManual);
  }
  if (params.defaultTaxType) {
    newFormData.append("defaultTaxType", params.defaultTaxType);
  }
  if (params.isCombinedTrip) {
    newFormData.append("isCombinedTrip", params.isCombinedTrip);
  }
  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function sendDocumentsInEmail(params) {
  const token = getStorage("token");
  let url = "tms/sendDocumentsToEmail";
  const newFormData = new FormData();
  Object.keys(params).forEach((key) => {
    if (key == "documents") {
      newFormData.append("documents", JSON.stringify(params[key]));
    } else {
      newFormData.append(key, params[key]);
    }
  });
  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function changeWeek(params) {
  const token = getStorage("token");
  const newFormData = new FormData();
  Object.keys(params).forEach((key) => {
    newFormData.append(key, params[key]);
  });
  const response = await HTTP(
    "post",
    "tms/driver-pay/change-week",
    newFormData,
    {
      authorization: token,
    }
  );
  return response;
}

export async function addCheck(params) {
  const token = getStorage("token");
  let url = "tms/changeDocumentStatus";
  const newFormData = new FormData();

  newFormData.append("documentId", params.documentId);
  newFormData.append("reference_number", params.reference_number);
  newFormData.append("checked", params.checked);

  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function getPayments(params) {
  const token = getStorage("token");
  let url = "tms/driver-payment";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  // let url = `tms/driver-payment`;
  // if (params.type) {
  //   url = `${url}?type=${params.type}`
  // }
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getDocumentForEquipment(params) {
  const token = getStorage("token");
  let url = "driver/getImagingDocs";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getPaymentGroups() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/create-payment-group`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function createPaymentGroup(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/create-payment-group`, data, {
    authorization: token,
  });
  return response;
}

export async function addPayment(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/driver-payment`, data, {
    authorization: token,
  });
  return response;
}

export async function removePayment(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/remove-driver-payment`, data, {
    authorization: token,
  });
  return response;
}

function getMonday(d) {
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export async function getEachTranscation(_id) {
  const token = getStorage("token");
  let url = "tms/getDriverPayById";
  if (_id) {
    url = `${url}?_id=${_id}`;
  }
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function updateCharges(payload) {
  const token = getStorage("token");
  const response = await HTTP("put", `tms/updateCharge`, payload, {
    authorization: token,
  });
  return response;
}

export async function getCharges() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/getCharges`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export const loadStatus = [
  {
    name: "Pending",
    value: "PENDING",
  },
  {
    name: "Needs Carrier",
    value: "NEEDS_CARRIER",
  },
  {
    name: "Needs Driver",
    value: "NEEDS_DRIVER",
  },
  {
    name: "Booked - awaiting for confirmation",
    value: "BOOKED_AWAITING_CONFIRMATION",
  },
  {
    name: "Driver Assigned",
    value: "DRIVER_ASSIGNED",
  },
  {
    name: "Ready - Confirmation signed",
    value: "READY_CONFIRMATION_SIGNED",
  },
  {
    name: "Dispatched",
    value: "DISPATCHED",
  },
  {
    name: "In Transit",
    value: "IN_TRANSIT",
  },
  {
    name: "Watch",
    value: "WATCH",
  },
  {
    name: "Possible Claim",
    value: "POSSIBLE_CLAIM",
  },
  {
    name: "Delivered",
    value: "DELIVERED",
  },
  {
    name: "Completed",
    value: "COMPLETED",
  },
  {
    name: "To Be Billed",
    value: "TO_BE_BILLED",
  },
  {
    name: "Actual Claim",
    value: "ACTUAL_CLAIM",
  },
];

export const groupByTime = function (arr, key, group) {
  var groupings = {
    day: function (obj) {
      var date = new Date(obj[key]);
      date.setHours(0, 0, 0, 0);
      return date.valueOf();
    },
    week: function (obj) {
      var date = new Date(obj[key]);
      date.setHours(0, 0, 0, 0);
      return getMonday(date).valueOf();
    },
    month: function (obj) {
      var date = new Date(obj[key]);
      return new Date(date.getFullYear(), date.getMonth(), 1).valueOf();
    },
  };

  if (!group) {
    group = "day";
  }
  return groupByLoadsh(arr, groupings[group]);
};

export const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: "created",
    VALUE_UPDATED: "updated",
    VALUE_DELETED: "deleted",
    VALUE_UNCHANGED: "unchanged",
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw "Invalid argument. Function given, object expected.";
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
        };
      }

      var diff = {};
      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }
        var value2 = undefined;
        if ("undefined" != typeof obj2[key]) {
          value2 = obj2[key];
        }
        diff[key] = this.map(obj1[key], value2);
      }
      for (var key in obj2) {
        if (this.isFunction(obj2[key]) || "undefined" != typeof diff[key]) {
          continue;
        }
        diff[key] = this.map(undefined, obj2[key]);
      }
      return diff;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (
        this.isDate(value1) &&
        this.isDate(value2) &&
        value1.getTime() === value2.getTime()
      ) {
        return this.VALUE_UNCHANGED;
      }
      if ("undefined" == typeof value1) {
        return this.VALUE_CREATED;
      }
      if ("undefined" == typeof value2) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (obj) {
      return {}.toString.apply(obj) === "[object Function]";
    },
    isArray: function (obj) {
      return {}.toString.apply(obj) === "[object Array]";
    },
    isObject: function (obj) {
      return {}.toString.apply(obj) === "[object Object]";
    },
    isDate: function (obj) {
      return {}.toString.apply(obj) === "[object Date]";
    },
    isValue: function (obj) {
      return !this.isObject(obj) && !this.isArray(obj);
    },
  };
})();

export const differnceFinder = function (
  obj1,
  obj2,
  userId = null,
  prevUserId = null,
  terminals = [],
  isEquipmentAudit
) {
  if(isEquipmentAudit && terminals?.length) terminalIds = terminals;

  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }

  obj1 = obj1 || {};
  obj2 = obj2 || {};
  Object.keys(obj1)
    .concat(Object.keys(obj2))
    .forEach((key) => {
      if (key !== "extraTracerData" && key!=="lastContainerTracedDate" && key!== "lastContainerErrorDate" &&  key!== "cautionCount" ) {
        const currentUserId = userId ? userId._id : null;
        const previousUserId = prevUserId ? prevUserId._id : null;

        if (
          currentUserId &&
          previousUserId &&
          currentUserId === previousUserId
        ) {
          if (
            key === "lastFreeDay" ||
            key === "status" ||
            key === "custom" ||
            key === "freight" ||
            key === "extraTracerData"
          ) {
            result[key] = obj2[key];
          }
        }
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
          if (obj2[key] !== undefined) {
            result[key] = obj2[key];
          }
        }
        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = differnceFinder(obj1[key], obj2[key]);
          if (value !== undefined) {
            result[key] = value;
          }
        }
        if(key === 'consigneeInfo' || key === 'shipperInfo'){
          result[key] = []
          obj1[key] && obj1[key].forEach((val) => {
            if(obj2[key] && !obj2[key].includes(val)){
              result[key].push({value: val, editType: 'Deleted '});
            }
          })
          obj2[key] && obj2[key].forEach((val) => {
            if(obj1[key] && !obj1[key].includes(val)){
              result[key].push({value: val, editType: 'Added '});
            }
          })
        }
        if(key === "deliveryReference") {
          result[key] = [];
          obj2[key].forEach((newItem, index) => {
            const oldItem = obj1[key].find(old => old?.customerId === newItem?.customerId);
            if (oldItem) {
                if (oldItem?.referenceNo !== newItem?.referenceNo) {
                    result[key].push({
                        oldReferenceNo: oldItem?.referenceNo ?? "",
                        newReferenceNo: newItem?.referenceNo ?? "",
                        deliveryPosition: index+1
                    });
                }
            } else if (obj2[key] && !obj1[key].length) {
              result[key].push({
                oldReferenceNo: oldItem?.referenceNo ?? "",
                newReferenceNo: newItem?.referenceNo ?? "",
                deliveryPosition: index+1
              });
            } else {
              result[key].push({
                oldReferenceNo: oldItem?.referenceNo ?? "",
                newReferenceNo: newItem?.referenceNo ?? "",
                deliveryPosition: index+1
              });
            }
        });
        }

        const prevVal = obj1[key];
        const newVal = obj2[key]; 

        if(isEquipmentAudit && /null|undefined|^$/.test(prevVal) && /null|undefined|^$/.test(newVal)) return delete result[key];
        if(isEquipmentAudit && key === 'newTerminal'){
          if(!prevVal || !newVal || !Array.isArray(prevVal) || !Array.isArray(newVal))  return;

          const containsPrevValue = _.intersection(newVal, prevVal);
          const addedDiff = _.xor(prevVal, newVal);
          const deletedDiff = containsPrevValue ? _.difference(prevVal, newVal) : [];
          const replacedDiff = !containsPrevValue ?.length ? _.difference(newVal, prevVal) : [];

          const hasSingleNewTerminals = prevVal?.length === 1 && newVal?.length === 1;
          const hasUniqSingleTerminalValues = prevVal[0] !== newVal[0];

          const addedTerminalNames = addedDiff?.map((id) => terminalIds?.find((terminal) => terminal._id === id)?.name)?.filter(d => d)?.join(", ");
          const deletedTerminals = deletedDiff?.map((id) => terminalIds?.find((terminal) => terminal._id === id)?.name)?.filter(d => d)?.join(", ");
          const replacedTerminals = replacedDiff?.map((id) => terminalIds?.find((terminal) => terminal._id === id)?.name)?.filter(d => d)?.join(", ");

          let updatedTerminals = {};

          if (hasSingleNewTerminals && hasUniqSingleTerminalValues) updatedTerminals = { editType: "N", data: terminalIds?.find((terminal) => terminal?._id === newVal[0])?.name };
          else if(replacedDiff?.length) updatedTerminals = { editType: "N", data: replacedTerminals };
          else if (deletedDiff?.length) updatedTerminals = { editType: "D", data: deletedTerminals };
          else if (addedDiff?.length) updatedTerminals = { editType: "A", data: addedTerminalNames };

          result["newTerminal"] = JSON.stringify(updatedTerminals);
        }
      }
    });

  if(result?.hasOwnProperty("load")) delete result['load'];
  if(result?.hasOwnProperty("driver")) delete result['driver'];
  if(isEquipmentAudit && result?.hasOwnProperty('carrier')) return {};  // remove unnecessary bulk changes on doc remove

  return result;
};

export const pricingDiffernceFinder = function (obj1, obj2) {
  let pricing1 = (obj1.pricing && obj1.pricing.map((charge) => {
    return {
      ...charge,
      amount: charge.amount ? parseFloat(charge.amount) : 0,
      chargePerDay: charge.chargePerDay ? parseFloat(charge.chargePerDay) : 0,
      freeDays: charge.freeDays ? parseFloat(charge.freeDays) : 0,
      unit: charge.unit ? parseFloat(charge.unit) : 0,
      finalAmount: charge.finalAmount ? parseFloat(charge.finalAmount) : 0,
    }
  })) || [];
  let pricing2 = (obj2.pricing && obj2.pricing.map((charge) => {
    return {
      ...charge,
      amount: charge.amount ? parseFloat(charge.amount) : 0,
      chargePerDay: charge.chargePerDay ? parseFloat(charge.chargePerDay) : 0,
      freeDays: charge.freeDays ? parseFloat(charge.freeDays) : 0,
      unit: charge.unit ? parseFloat(charge.unit) : 0,
      finalAmount: charge.finalAmount ? parseFloat(charge.finalAmount) : 0,
    }
  })) || [];

  const result = [];
  pricing1.forEach((charge) => {
    const charge2 = pricing2.find((d) => d.name === charge.name && d.perType === charge.perType && d._id + "" === charge._id + "");
    if (!charge2) {
      result.push({ ...charge, editType: 'DELETED PRICING' });
    }
  });

  pricing2.forEach((charge) => {
    const charge1 = pricing1.find((d) => d.name === charge.name && d.perType === charge.perType && d._id + "" === charge._id + "");
    if (!charge1) {
      result.push({ ...charge, editType: 'ADDED PRICING' })
    }
    else if (!_.isEqual(charge, charge1)) {
      result.push({ ...charge, editType: 'UPDATED PRICING' });
    }
  });

  return result;
};

export const customerSubType=[
  {label:'MVOCC',value:'MVOCC'},
  {label:'Freight Forwarder',value:'Freight Forwarder'},
  {label:' TruckBroker',value:'TruckBroker'},
  {label:'Importer/Exporter',value:'Importer/Exporter'},
  {label:'Customs Brokerage',value:'Customs Brokerage'},
];

export const visibleToastCount = () => {
  let toastCount = Array.from(document.querySelectorAll('.toast')).filter(toast => toast.offsetParent !== null);
  return toastCount?.length;
};

export const visibleCloseBtn = () => {
  return document.querySelector('.btn-clear-toast');
};

export const toastr = {
  show: function (message, type, title, timeOut, customOptions) {
    let isShowBtn = visibleToastCount() >= 1 && !visibleCloseBtn();
    let isHideBtn = visibleToastCount() < 1;
    let totalTimeOut = 1000 * 3 * 60;
    let remainingTime = totalTimeOut;
    let countdownInterval;

    function updateCountdown() {
      if (remainingTime <= 0) {
        clearInterval(countdownInterval);
        return;
      }
      remainingTime -= 1000;
    }

    const commonOptions = {
      showMethod: "slideDown",
      hideMethod: "slideUp",
      timeOut: timeOut ? timeOut : 5000,
      onShown: function () {
        isShowBtn && checkAndShowClearButton();
        countdownInterval = setInterval(updateCountdown, 1000);
      },
      onHidden: function () {
        clearInterval(countdownInterval);
        remainingTime = totalTimeOut;
        setTimeout(() => {
          checkAndHideClearButton();
        }, 1000);
      }
    };

    if (type === "success") {
      window.toastr.success(message, title, commonOptions);
    } else if (type === "error") {
      window.toastr.error(message, title, customOptions || commonOptions);
    } else if (type === "info") {
      window.toastr.info(message, title, commonOptions);
    } else if (type === "warning") {
      window.toastr.warning(message, title, customOptions || commonOptions);
    } else {
      window.toastr.info(message, title, commonOptions);
    }
  },
};

export function checkAndShowClearButton() {
  let checkVisible = document.getElementsByClassName('toast-clear-button')
  let toastContainer = document.getElementById('toast-container');
  if (visibleToastCount() > 1 && checkVisible?.length < 1 ) {
    toastContainer.classList.add('top-50');
    const clearIcon = ReactDOMServer.renderToStaticMarkup(<IconCloseBold className={'text-white ml-2'} />);
    const clearButton = `<div class="btn-clear-toast"><button class="toast-clear-button btn bg-grey-300 font-14 font-weight-normal text-white">Close all ${clearIcon}</button></div>`;
    toastContainer.insertAdjacentHTML('afterbegin', clearButton);
    let clearAllButton = toastContainer.querySelector('.btn-clear-toast');
    if (clearAllButton) {
      clearAllButton.addEventListener('click', function () {
        toastContainer.remove();
        checkAndHideClearButton();
      });
    }
  } else {
    checkAndHideClearButton()
  }
}

export function checkAndHideClearButton() {
  const toastContainer = document.getElementById('toast-container');
  let clearButton = toastContainer?.querySelector('.btn-clear-toast');
    clearButton?.remove();
}

document.addEventListener('DOMContentLoaded', function() {
  visibleToastCount() <= 1 && visibleCloseBtn() && checkAndHideClearButton();
});

export async function getChatNotifications(params) {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/getDriverChatUnreadNotifications`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}
export function tableHeightOffset(containerLine) {
  const box = containerLine.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

  var clientTop = docEl.clientTop || body.clientTop || 0;

  // var getOffset = box.top + scrollTop - clientTop + 10;
  return;
  // return Math.round(getOffset);
}

// export function tableRadius() {
//   var tableFixedHeader = document.querySelector('.table-header-fixed'),
//     theadTag = tableFixedHeader.querySelector('thead');
//   var tableHeader = theadTag.offsetHeight;
//   var topValue = this.offsetTop - this.scrollTop;
//   var top = Math.round(topValue + tableHeader);

//   var verticalScroll = document.querySelector(".radius-wrapper");
//   verticalScroll.setAttribute("style", "top:" + top + "px");

//   return;
// }

export function displayName(fullName) {
  if (fullName) {
    const name = fullName.split(" ");
    const lname = name[1];
    const fname = name[0];
    if (lname) {
      return (
        fname.substr(0, 1).toUpperCase() + lname.substr(0, 1).toUpperCase()
      );
    } else return fname.substr(0, 2).toUpperCase();
  }
  return "";
}

export async function connectDesktop(params) {
  const token = getStorage("token");
  const response = await HTTP(
    "post",
    `tms/connectDesktop`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function verifyAutoFy() {
  const token = getStorage("token");
  const response = await HTTP(
    "post",
    `tms/verifyAutoFy`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export const getPortMarkets = () => (dispatch) => {
  const type = "SET_PORT_MARKETS";
  return new Promise((resolve, reject) => {
    HTTP("get", "setup/portMarkets")
      .then((response) => {
        dispatch({ type, payload: _.get(response, "data.data", []) });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const isGlobalizationEnabled = () =>{
  const trial = JSON.parse(localStorage.getItem('userBasicSettings'))
  if(trial && trial.isGlobalizationEnabled){
    return trial.isGlobalizationEnabled
  }else return false
}
export const isGroupSettlementsByBranchEnabled = () =>{
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  if(setting && setting.isGroupSettlementsByBranchEnabled){
    return setting.isGroupSettlementsByBranchEnabled
  }else return false
}

export const isPaymentTermFlagEnabled = () =>{
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  if(setting && setting.isPaymentTermEnabledForSub){
    return setting.isPaymentTermEnabledForSub
  }else return false
}

export const isMilitaryTime = () => {
  const carrierDetail = JSON.parse(localStorage.getItem('userBasicSettings'));
  if(carrierDetail && carrierDetail.isMilitaryTime){
    return "HH:mm"
  } else return false
}

export const defaultCarrierCurrency = () => {
  const currency = JSON.parse(localStorage.getItem('accountCurrencies'));
  
  return currency?.carrier ?? null;
};

export const defaultBranchCurrency = () => {
  const currency = JSON.parse(localStorage.getItem('accountCurrencies'));

  return currency?.branch ?? null;
}

export const defaultCustomerCurrency = () => {
  const currency = JSON.parse(localStorage.getItem('accountCurrencies'));

  return currency?.customer ?? null;
}

export const Currency = {
  defaultCarrierCurrency: () => defaultCarrierCurrency(),
  defaultBranchCurrency: () => defaultBranchCurrency(), 
  defaultCustomerCurrency: () => defaultCustomerCurrency(),
  default: () => defaultCarrierCurrency(),
};

export const pricingSettingsOptionsTMX = [
  { value: 'ATTEMPT', label: 'Delivery Attempt at Ocean Terminal' },
  { value: 'Base Price', label: 'Line Haul' },
  { value: 'BOBTAIL', label: 'Bob Tail / Dry Run' },
  { value: 'BONDED', label: 'BONDED MOVE' },
  { value: 'C & C SPLIT', label: 'C & C SPLIT' },
  { value: 'CHANGE', label: 'CHANGE' },
  { value: 'CHASSIS', label: 'Chassis Use' },
  { value: 'COMPLIANCE', label: 'COMPLIANCE' },
  { value: 'CONGESTION', label: 'Terminal Congestion' },
  { value: 'CONRET', label: 'Container Return' },
  { value: 'CROTOL', label: 'Croxton Toll' },
  { value: 'CSXTOL', label: 'CSX Toll' },
  { value: 'DEDELIVER', label: 'Destination Delivery' },
  { value: 'DEPICKUP', label: 'Destination Pickup Export' },
  { value: 'DETENT', label: 'Detention' },
  { value: 'DPAYADJ', label: 'Driver Pay Adjustment' },
  { value: 'DRVTOL', label: 'Driver Toll' },
  { value: 'DSTYARD', label: 'Destination to Yard' },
  { value: 'E - Z PASS', label: 'E-Z Pass Use Owner Operator' },
  { value: 'EMISC', label: 'EMISC' },
  { value: 'EU', label: 'E-Z Pass' },
  { value: 'EXLOAD', label: 'Export Load' },
  { value: 'EXPDSTYARD', label: 'EXPDSTYARD' },
  { value: 'EXPORT', label: 'Export Ship Load' },
  { value: 'EZ - PASS', label: 'E-Z Pass Use Company Driver' },
  { value: 'FC', label: 'Driver Fuel Card' },
  { value: 'FLATBED', label: 'Flat Bed' },
  { value: 'FLEXITANK', label: 'FLEXITANK' },
  { value: 'FOODGRADE', label: 'Food Grade Cleanup' },
  { value: 'FUEL SURCHARGE', label: 'FUEL SURCHARGE' },
  { value: 'FUEL CARD', label: 'Fuel Card' },
  { value: 'FUELEXTRCH', label: 'Fuel Extra Charge' },
  { value: 'GENSET', label: 'GENSET' },
  { value: 'GENSETFUEL', label: 'Genset Fuel' },
  { value: 'GENSETUSE', label: 'Genset Use' },
  { value: 'GLOBTOL', label: 'Global Toll' },
  { value: 'HAZMAT', label: 'Hazardous' },
  { value: 'HNMTOL', label: 'H&M Toll' },
  { value: 'IMISC', label: 'IMISC' },
  { value: 'IMPORT', label: 'Import Terminal P/U' },
  { value: 'LAYOVER', label: 'LAYOVER ELD Miles' },
  { value: 'LINING', label: 'Line Container' },
  { value: 'LIQUOR', label: 'Liquor  Permit' },
  { value: 'LOW BOY 20', label: 'LOW BOY 20' },
  { value: 'LOW BOY 40', label: 'LOW BOY 40' },
  { value: 'MILES', label: 'Extra Miles' },
  { value: 'MISC', label: 'Misc' },
  { value: 'NJTINC', label: 'NJ Toll Increase' },
  { value: 'NYCTOLL', label: 'NY Toll Increase' },
  // { value: 'NYTINC', label: 'NYTINC' },
  { value: 'OVERWEIGHT', label: 'OVERWEIGHT PERMIT' },
  { value: 'PDETIN', label: 'PDETIN' },
  { value: 'PDETOUT', label: 'PDETOUT' },
  { value: 'PERDIEM', label: 'Per Diem' },
  { value: 'PETTY CASH', label: 'PETTY CASH' },
  { value: 'PLACARD REMOVE', label: 'PLACARD REMOVAL' },
  { value: 'PORTDELAY', label: 'Port Delay' },
  { value: 'PREPULL', label: 'Pre Pull for Storage' },
  { value: 'REEFER', label: 'Reefer Charge' },
  { value: 'REEFERFUEL', label: 'Reefer Fuel' },
  { value: 'REEFMONITOR', label: 'Reefer Monitoring' },
  { value: 'REEFSTOR', label: 'Over Night Reefer Storage' },
  { value: 'RENTREVENUE', label: 'Rent Revenue' },
  { value: 'RESIDENCE', label: 'Residence' },
  { value: 'SCALE', label: 'Scale Weight' },
  { value: 'SCALEXP', label: 'SCALEXP' },
  { value: 'SECSTOP', label: '2ND STOP' },
  { value: 'SIDELIFT', label: 'SIDELIFT' },
  { value: 'SPLITCHAS', label: 'Split Chassis' },
  { value: 'SPLITMOVE', label: 'SPLITMOVE' },
  { value: 'STEPDECK', label: 'STEPDECK' },
  { value: 'STORE', label: 'Over Night Storage' },
  { value: 'TANKEND', label: 'Tank Endorsement' },
  { value: 'TERMPICK', label: 'Export Container  P/U' },
  // { value: 'TERMYARD', label: 'Terminal to YARD' },
  { value: 'TERMYARD', label: 'Terminal to Yard' },
  { value: 'TILT', label: 'TILT' },
  { value: 'TOLL', label: 'TOLL' },
  { value: 'TRANSLOAD', label: 'TRANSLOAD' },
  { value: 'TRIAX', label: 'Tri-Axle' },
  { value: 'TRIAXNY', label: 'Tri-Axle NY' },
  { value: 'TRUCK MILES', label: 'Truck Miles' },
  { value: 'TRUCK RENT', label: 'Truck Rent' },
  { value: 'UNLOAD', label: 'Import Unload' },
  { value: 'WAREHOUS', label: 'Warehouse' },
  // { value: 'WC', label: 'Workmans Comp Exp' },
  { value: 'WCOMP', label: 'Workmans Comp Exp' },
  { value: 'Tolls', label: 'Tolls' },
]

export const pricingSettingsOptions = [
  { value: "Base Price", label: "Base Price".toUpperCase() },
  { value: "Chassis", label: "Chassis".toUpperCase() },
  { value: "Detention", label: "Detention".toUpperCase() },
  { value: "Fuel", label: "Fuel".toUpperCase() },
  { value: "demur_det_fee", label: "demur-det fee".toUpperCase() },
  { value: "Over Weight", label: "Over Weight".toUpperCase() },
  { value: "Toll", label: "Toll".toUpperCase() },
  { value: "PrePull", label: "PrePull".toUpperCase() },
  { value: "attempted_to_drop", label: "attempted to drop".toUpperCase() },
  {
    value: "attempted_to_pick_up",
    label: "attempted to pick up".toUpperCase(),
  },
  { value: "bonded_cargo_charge", label: "bonded cargo charge".toUpperCase() },
  {
    value: "container_inspection",
    label: "container inspection".toUpperCase(),
  },
  { value: "demurrage", label: "demurrage".toUpperCase() },
  { value: "domestic_move", label: "domestic move".toUpperCase() },
  { value: "drop_charge", label: "drop charge".toUpperCase() },
  { value: "dry_run", label: "dry run".toUpperCase() },
  { value: "Hazmat", label: "Hazmat".toUpperCase() },
  { value: "line_hall", label: "line haul".toUpperCase() },
  {
    value: "maintenance_and_repair",
    label: "maintenance and repair".toUpperCase(),
  },
  { value: "other", label: "other".toUpperCase() },
  { value: "per_diem", label: "per diem".toUpperCase() },
  { value: "pick_up_charge", label: "pick up charge".toUpperCase() },
  { value: "port_congestion_fee", label: "Port Congestion Fee".toUpperCase() },
  { value: "redelivery", label: "redelivery".toUpperCase() },
  { value: "scale_load", label: "scale load".toUpperCase() },
  { value: "stop_off", label: "stop off".toUpperCase() },
  { value: "storage", label: "storage".toUpperCase() },
  { value: "strap", label: "strap".toUpperCase() },
  { value: "tire_rebill", label: "tire rebill".toUpperCase() },
  { value: "traffic_fine", label: "traffic fine".toUpperCase() },
  { value: "transaction_fee", label: "transaction fee".toUpperCase() },
  { value: "tri_axle", label: "tri axle".toUpperCase() },
  { value: "washout_container", label: "washout container".toUpperCase() },
  { value: "reefer", label: "Reefer".toUpperCase() },
  { value: "bobtail_charge", label: "Bobtail Charge".toUpperCase() },
  { value: "pier_congestion", label: "Pier Congestion".toUpperCase() },
  { value: "hourly_pay", label: "Hourly Pay".toUpperCase() },
  // { value: "storage", label: "Storage" },
  { value: "transload", label: "Transload".toUpperCase() },
  { value: "placard_removal", label: "Placard Removal".toUpperCase() },
  { value: "flexi_bag_disposal", label: "Flexi Bag Disposal".toUpperCase() },
  {
    value: "Handling - Documentation Fee",
    label: "Handling - Documentation Fee".toUpperCase(),
  },
  { value: "AES Fee", label: "AES Fee".toUpperCase() },
  { value: "Ocean Freight", label: "Ocean Freight".toUpperCase() },
  { value: "Dead Run", label: "Dead Run".toUpperCase() },
  { value: "Tarp", label: "Tarp".toUpperCase() },
  { value: "Unload", label: "Unload".toUpperCase() },
  { value: "Flatbed", label: "Flatbed".toUpperCase() },
  { value: "Waiting time", label: "Waiting time".toUpperCase() },
  { value: "Shunt", label: "Shunt".toUpperCase() },
  { value: "Tolls", label: "Tolls".toUpperCase() },
  { value: "Exam Site Pick Up", label: "Exam Site Pick Up".toUpperCase() },
  { value: "pier_pass", label: "Pier-Pass".toUpperCase() },
  { value: "Chassis Split", label: "Chassis Split".toUpperCase() },
  { value: "Tank", label: "Tank".toUpperCase() },
];

export const pricingSettingsOptionsForGeorge = [
  { value: "Genset", label: "Genset" },
  { value: "Seal Charge", label: "Seal Charge" },
  { value: "Weekend Delivery", label: "Weekend Delivery" },
  { value: "Night Delivery", label: "Night Delivery" },
  { value: "Dangerous Goods", label: "Dangerous Goods" },
  { value: "Residential", label: "Residential" },
  { value: "Wait Time", label: "Wait Time" },
];
export const pricingSettingsOptionsForDHC = [
  { value: "230BOL", label: "230BOL" },
  { value: "230DEVAN", label: "230DEVAN" },
  { value: "230HANDLING", label: "230HANDLING" },
  { value: "230REWORK", label: "230REWORK" },
  { value: "230SEAL", label: "230SEAL" },
  { value: "230TRANSLOAD", label: "230TRANSLOAD" },
  { value: "233BOL", label: "233BOL" },
  { value: "233DEVAN", label: "233DEVAN" },
  { value: "233HANDLING", label: "233HANDLING" },
  { value: "233REWORK", label: "233REWORK" },
  { value: "233SEAL", label: "233SEAL" },
  { value: "233TRANSLOAD", label: "233TRANSLOAD" },
  { value: "233WHSE STORAGE", label: "233WHSE STORAGE" },
  { value: "ADVANCEMENT FEE", label: "ADVANCEMENT FEE" },
  { value: "BONBLOCKBRACE", label: "BONBLOCKBRACE" },
  { value: "BONITABOL", label: "BONITABOL" },
  { value: "BONREMOVEBLKBRC", label: "BONREMOVEBLKBRC" },
  { value: "BONREWORK", label: "BONREWORK" },
  { value: "BONSEAL", label: "BONSEAL" },
  { value: "BONTRANSLOAD", label: "BONTRANSLOAD" },
  { value: "DHE CHASSIS", label: "DHE CHASSIS" },
  { value: "DIVERSION", label: "DIVERSION" },
  { value: "FLIP CHARGE", label: "FLIP CHARGE" },
  { value: "FULFILLMENT", label: "FULFILLMENT" },
  { value: "NIGHT PICK", label: "NIGHT PICK" },
  { value: "PALLET HANDLING", label: "PALLET HANDLING" },
  { value: "PALLETS", label: "PALLETS" },
  { value: "PORTCHASSIS", label: "PORTCHASSIS" },
  { value: "PP-ADV. FEE", label: "PP-ADV. FEE" },
  { value: "PREPULL", label: "PREPULL" },
  { value: "SUNDAYGATE", label: "SUNDAYGATE" },
];
export const pricingSettingsOptionsForCobra = [
  { value: "CP Rail Surcharge", label: "CP Rail Surcharge" },
  { value: "Lift Charge", label: "Lift Charge" },
  { value: "Flip Charge", label: "Flip Charge" },
  { value: "Layover Charge", label: "Layover Charge" },
  { value: "Additional Drayage", label: "Additional Drayage" },
];

export const importChargeCodesForDHC = {
  "230BOL": "3165-60-11-00",
  "230DEVAN": "3165-60-11-00",
  "230HANDLING": "3165-60-11-00",
  "230REWORK": "3165-60-11-00",
  "230SEAL": "3165-60-11-00",
  "230TRANSLOAD": "3165-60-11-00",
  "233BOL": "3165-60-14-00",
  "233DEVAN": "3165-60-14-00",
  "233HANDLING": "3165-60-14-00",
  "233REWORK": "3165-60-14-00",
  "233SEAL": "3165-60-14-00",
  "233TRANSLOAD": "3165-60-14-00",
  "233WHSE STORAGE": "3165-60-14-00",
  "ADVANCEMENT FEE": "3210-40-10-00",
  "AES FEE": "3210-40-10-00",
  ATTEMPTED_TO_DROP: "3200-40-10-00",
  ATTEMPTED_TO_PICK_UP: "3200-40-10-00",
  "BASE PRICE": "3200-40-10-00",
  BOBTAIL_CHARGE: "3200-40-10-00",
  BONDED_CARGO_CHARGE: "3210-40-10-00",
  "CHASSIS SPLIT": "3216-40-10-00",
  CHASSIS: "3220-40-10-00",
  CONTAINER_INSPECTION: "3210-40-10-00",
  "DEAD RUN": "3200-40-10-00",
  DEMURRAGE: "1750-00-00-00",
  DETENTION: "1760-00-00-00",
  "DHE CHASSIS": "3220-40-10-00",
  DIVERSION: "3203-40-10-00",
  DOMESTIC_MOVE: "3200-40-10-00",
  DROP_CHARGE: "3200-40-10-00",
  DRY_RUN: "3200-40-10-00",
  "EXAM SITE PICK UP": "3200-40-10-00",
  FLATBED: "3200-40-10-00",
  FLEXI_BAG_DISPOSAL: "3200-40-10-00",
  "FLIP CHARGE": "3200-40-10-00",
  FUEL: "3104-40-10-00",
  FULFILLMENT: "3165-60-11-00",
  "HANDLING - DOCUMENTATION FEE": "3210-40-10-00",
  HAZMAT: "3205-40-10-00",
  HOURLY_PAY: "3200-40-10-00",
  LINE_HALL: "3200-40-10-00",
  MAINTENANCE_AND_REPAIR: "5901-40-10-95",
  "NIGHT PICK": "3204-40-10-00",
  "OCEAN FREIGHT": "3200-40-10-00",
  OTHER: "3200-40-10-00",
  "OVER WEIGHT": "3225-40-10-00",
  "PALLET HANDLING": "3165-60-11-00",
  PALLETS: "3165-60-11-00",
  PER_DIEM: "1760-00-00-00",
  PICK_UP_CHARGE: "3200-40-10-00",
  PIER_CONGESTION: "3207-40-10-00",
  PIER_PASS: "1755-00-00-00",
  PLACARD_REMOVAL: "3205-40-10-00",
  PORT_CONGESTION_FEE: "3207-40-10-00",
  PORTCHASSIS: "3220-40-10-00",
  "PP-ADV. FEE": "3210-40-10-00",
  PREPULL: "3202-40-10-00",
  REDELIVERY: "3200-40-10-00",
  REEFER: "3200-40-10-00",
  SCALE_LOAD: "3200-40-10-00",
  SHUNT: "3200-40-10-00",
  STRAP: "3200-40-10-00",
  STOP_OFF: "3200-40-10-00",
  STORAGE: "3102-40-10-00",
  SUNDAYGATE: "3200-40-10-00",
  TANK: "3200-40-10-00",
  TARP: "3200-40-10-00",
  TIRE_REBILL: "5925-40-10-95",
  TOLLS: "3200-40-10-00",
  TRANSACTION_FEE: "3210-40-10-00",
  TRANSLOAD: "3165-60-11-00",
  TRI_AXLE: "3225-40-10-00",
  UNLOAD: "3200-40-10-00",
  "WAITING TIME": "3207-40-10-00",
  WASHOUT_CONTAINER: "5955-40-10-94",
};

export const exportChargeCodesForDHC = {
  "230BOL": "3165-60-11-00",
  "230DEVAN": "3165-60-11-00",
  "230HANDLING": "3165-60-11-00",
  "230REWORK": "3165-60-11-00",
  "230SEAL": "3165-60-11-00",
  "230TRANSLOAD": "3165-60-11-00",
  "233BOL": "3165-60-14-00",
  "233DEVAN": "3165-60-14-00",
  "233HANDLING": "3165-60-14-00",
  "233REWORK": "3165-60-14-00",
  "233SEAL": "3165-60-14-00",
  "233TRANSLOAD": "3165-60-14-00",
  "233WHSE STORAGE": "3165-60-14-00",
  "ADVANCEMENT FEE": "3240-40-10-00",
  "AES FEE": "3240-40-10-00",
  ATTEMPTED_TO_DROP: "3230-40-10-00",
  ATTEMPTED_TO_PICK_UP: "3230-40-10-00",
  "BASE PRICE": "3230-40-10-00",
  BOBTAIL_CHARGE: "3230-40-10-00",
  BONDED_CARGO_CHARGE: "3240-40-10-00",
  "CHASSIS SPLIT": "3246-40-10-00",
  CHASSIS: "3250-40-10-00",
  CONTAINER_INSPECTION: "3240-40-10-00",
  "DEAD RUN": "3230-40-10-00",
  DEMURRAGE: "1750-00-00-00",
  DETENTION: "1760-00-00-00",
  "DHE CHASSIS": "3250-40-10-00",
  DIVERSION: "3256-40-10-00",
  DOMESTIC_MOVE: "3230-40-10-00",
  DROP_CHARGE: "3230-40-10-00",
  DRY_RUN: "3230-40-10-00",
  "EXAM SITE PICK UP": "3230-40-10-00",
  FLATBED: "3230-40-10-00",
  FLEXI_BAG_DISPOSAL: "3230-40-10-00",
  "FLIP CHARGE": "3230-40-10-00",
  FUEL: "3107-40-10-00",
  FULFILLMENT: "3165-60-11-00",
  "HANDLING - DOCUMENTATION FEE": "3240-40-10-00",
  HAZMAT: "3235-40-10-00",
  HOURLY_PAY: "3230-40-10-00",
  LINE_HALL: "3230-40-10-00",
  MAINTENANCE_AND_REPAIR: "5901-40-10-95",
  "NIGHT PICK": "3234-40-10-00",
  "OCEAN FREIGHT": "3230-40-10-00",
  OTHER: "3230-40-10-00",
  "OVER WEIGHT": "3256-40-10-00",
  "PALLET HANDLING": "3165-60-11-00",
  PALLETS: "3165-60-11-00",
  PER_DIEM: "1760-00-00-00",
  PICK_UP_CHARGE: "3230-40-10-00",
  PIER_CONGESTION: "3237-40-10-00",
  PIER_PASS: "1755-00-00-00",
  PLACARD_REMOVAL: "3235-40-10-00",
  PORT_CONGESTION_FEE: "3237-40-10-00",
  PORTCHASSIS: "3250-40-10-00",
  "PP-ADV. FEE": "3240-40-10-00",
  PREPULL: "3232-40-10-00",
  REDELIVERY: "3230-40-10-00",
  REEFER: "3230-40-10-00",
  SCALE_LOAD: "3230-40-10-00",
  SHUNT: "3230-40-10-00",
  STRAP: "3230-40-10-00",
  STOP_OFF: "3230-40-10-00",
  STORAGE: "3106-40-10-00",
  SUNDAYGATE: "3230-40-10-00",
  TANK: "3230-40-10-00",
  TARP: "3230-40-10-00",
  TIRE_REBILL: "5925-40-10-95",
  TOLLS: "3230-40-10-00",
  TRANSACTION_FEE: "3240-40-10-00",
  TRANSLOAD: "3165-60-11-00",
  TRI_AXLE: "3256-40-10-00",
  UNLOAD: "3230-40-10-00",
  "WAITING TIME": "3237-40-10-00",
  WASHOUT_CONTAINER: "5955-40-10-94",
};

// get pricingSettingOptions according to Carrier _id
export const getPricingSettingsOptions = () => {
  let defaultOptions = pricingSettingsOptions;
  let userData = JSON.parse(getStorage('loggedInUser'))
  let carrierId = getCarrierId();
  if (carrierId && carrierId === "6058a1f9afecf214ec2fab2b") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForGeorge,
    ];
  }
  if (carrierId && carrierId === "60747b8448cb687a0f377e82") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForDHC,
    ];
  }
  // test ltlexpress Id //ignored in Production
  if (carrierId && carrierId === "5ae8972b5eb3b02839cad0e1") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForDHC,
    ];
  }
  if (carrierId && carrierId === "6079fd99b8cedc0368c197c5") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForCobra,
    ];
  }
  if(checkTMXPermission('tmx_charge_code')){
    defaultOptions = pricingSettingsOptionsTMX
  }
  return defaultOptions;
};

export const makeOptionsForSelect = (
  object = [],
  labelKeyPath1 = "",
  labelKeyPath2 = "",
  valueKeyPath = ""
) => {
  const userAddressFormat = defaultAddressFormat();
  if (userAddressFormat !== addressFormatEnum.US) {
    object = object.map((singleElement) => {
      const rawAddressData = {
        address1: singleElement?.address1,
        countryCode: singleElement?.countryCode,
        zip_code: singleElement?.zip_code,
        city: singleElement?.city,
        country: singleElement?.country,
      };
      if(singleElement?.address?.address){
          singleElement.address.address = parsedAddressIntoLocality(rawAddressData);
      }
      return singleElement;
    });
  }
  return object
    .map((obj) => ({
      label: `${_.get(obj, labelKeyPath1)}  ${_.get(
        obj,
        labelKeyPath2,
        ""
      ) ? "| " + _.get(
        obj,
        labelKeyPath2,
        ""
      ) : ""}`.trim(),
      value: _.get(obj, valueKeyPath),
      allInfos: obj,
    }))
    .unique();
};

export const makeOptionsForSelectPeople = (
  object = [],
  labelKeyPath1 = "",
  labelKeyPath2 = "",
  valueKeyPath = ""
) => {
  const userAddressFormat = defaultAddressFormat();
  if (userAddressFormat !== addressFormatEnum.US) {
    object = object.map((singleElement) => {
      const rawAddressData = {
        address1: singleElement?.address1,
        countryCode: singleElement?.countryCode,
        zip_code: singleElement?.zip_code,
        city: singleElement?.city,
        country: singleElement?.country,
      };
      if(singleElement?.address?.address){
          singleElement.address.address = parsedAddressIntoLocality(rawAddressData);
      }
      return singleElement;
    });
  }
  return object
    .map((obj) => ({
      label: `${_.get(obj, labelKeyPath1)}  ${_.get(
        obj,
        labelKeyPath2,
        ""
      ) ? " " + _.get(
        obj,
        labelKeyPath2,
        ""
      ) : ""}`.trim(),
      value: _.get(obj, valueKeyPath),
      allInfos: obj,
    }))
    .unique();
};

export const getDistanceFromLatLon = (
  lat1,
  lon1,
  lat2,
  lon2,
  unit = "miles"
) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km

  return unit === "miles" ? d * 0.621371 : d;
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("MM-DD-YYYY"));
    now.add(1, "days");
  }
  return dates;
};

export const convertUnit = (
  method = "convertedUnit",
  type = "distance",
  value = 0,
  convertTo
) => {
  const measureUnits = JSON.parse(getStorage("measureUnits"));
  const weightUnit = _.get(measureUnits, "weightUnit", "lbs");
  const distanceUnit = _.get(measureUnits, "distanceUnit", "mi");

  switch (method) {
    case "convertedUnit":
      if (type === "weight") {
        return weightUnit === "kg" ? "Kilograms" : "Pounds";
      } else {
        return distanceUnit === "km" ? "Kilometers" : "Miles";
      }

    case "convertedValue":
      if (type === "weight") {
        if (weightUnit === "kg") {
          return parseFloat((value * 0.453592).toFixed(2));
        }
        return value;
      } else {
        if (distanceUnit === "km") {
          return parseFloat(
            floatingValue.format(value * 1.60934).replace(",", "")
          );
        }

        return value;
      }

    case "convertTo":
      switch (convertTo) {
        case "km_mi":
          return parseFloat((value * 0.621371).toFixed(2));
        case "mi_km":
          return parseFloat((value * 1.60934).toFixed(2));
        case "kg_lbs":
          return parseFloat((value * 2.20462).toFixed(2));
        case "lbs_kg":
          return parseFloat((value * 0.453592).toFixed(2));
        default:
          return value;
      }
  }
};

export const floatingValue = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const treeToJsonParser = (children) => {
  let jsonLogicBit = [];
  children.map((data) => {
    if (data.type === "GROUP") {
      if (data.isNegated) {
        jsonLogicBit.push({
          "!": {
            [`${data.value.toLowerCase()}`]: treeToJsonParser(data.children),
          },
        });
      } else {
        jsonLogicBit.push({
          [`${data.value.toLowerCase()}`]: treeToJsonParser(data.children),
        });
      }
    } else if (
      [
        "EQUAL",
        "NOT_EQUAL",
        "LARGER",
        "SMALLER",
        "LARGER_EQUAL",
        "SMALLER_EQUAL",
      ].indexOf(data.operator) > -1
    ) {
      jsonLogicBit.push({
        [`${getOperator(data.operator)}`]: [
          {
            var: data.field,
          },
          data.value,
        ],
      });
    } else if (data.operator === "BETWEEN") {
      jsonLogicBit.push(getBetweenRule(data.value, data.field));
    } else if (data.operator === "NOT_BETWEEN") {
      jsonLogicBit.push({
        "!": getBetweenRule(data.value, data.field),
      });
    } else if (data.operator === "LIKE") {
      jsonLogicBit.push(getLikeRule(data.value, data.field));
    } else if (data.operator === "NOT_LIKE") {
      jsonLogicBit.push({
        "!": getLikeRule(data.value, data.field),
      });
    } else if (
      data.operator === "ANY_IN" &&
      ["shipper", "consignee", "state", "commodityProfile"].indexOf(
        data.field
      ) === -1
    ) {
      jsonLogicBit.push(getAnyRule(data.value, data.field));
    } else if (
      data.operator === "NOT_IN" &&
      ["shipper", "consignee", "state", "commodityProfile"].indexOf(
        data.field
      ) === -1
    ) {
      jsonLogicBit.push({
        "!": getAnyRule(data.value, data.field),
      });
    } else if (
      data.operator === "ANY_IN" &&
      ["shipper", "consignee", "commodityProfile"].indexOf(data.field) > -1
    ) {
      jsonLogicBit.push(getArrayAnyRule(data.value, data.field));
    } else if (
      data.operator === "NOT_IN" &&
      ["shipper", "consignee", "commodityProfile"].indexOf(data.field) > -1
    ) {
      jsonLogicBit.push({
        "!": getArrayAnyRule(data.value, data.field),
      });
    } else if (data.operator === "ANY_IN" && data.field === "state") {
      jsonLogicBit.push(getConsigneeStateRule(data.value, data.field));
    } else if (data.operator === "NOT_IN" && data.field === "state") {
      jsonLogicBit.push({
        "!": getConsigneeStateRule(data.value, data.field),
      });
    }
  });
  return jsonLogicBit;
};
const getOperator = (operator) => {
  switch (operator) {
    case "EQUAL":
      return "==";
    case "NOT_EQUAL":
      return "!=";
    case "LARGER":
      return ">";
    case "SMALLER":
      return "<";
    case "LARGER_EQUAL":
      return ">=";
    case "SMALLER_EQUAL":
      return "<=";
  }
};
const getBetweenRule = (value, field) => {
  return {
    "<=": [
      value[0],
      {
        var: field,
      },
      value[1],
    ],
  };
};
const getLikeRule = (value, field) => {
  return {
    in: [
      value,
      {
        var: field,
      },
    ],
  };
};
const getAnyRule = (value, field) => {
  return {
    in: [
      {
        var: field,
      },
      value.map((val) => JSON.parse(val).value),
    ],
  };
};
const getArrayAnyRule = (value, field) => {
  return {
    some: [
      {
        var: field,
      },
      {
        in: [
          {
            var: "",
          },
          value.map((val) => JSON.parse(val).value),
        ],
      },
    ],
  };
};
const getConsigneeStateRule = (value, field) => {
  const arr = [];
  value.forEach((val) => {
    arr.push({
      in: [
        JSON.parse(val).value,
        {
          var: field,
        },
      ],
    });
  });
  return {
    or: arr,
  };
};

export const getDriverOrderLocation = (driverOrder, createdBy, status) => {
  let from, to;
  let location = {
    from: [],
    to: [],
  };
  const getPoint = function (type, point, isNeedToGet) {
    let index = isNeedToGet
      ? _.findLastIndex(
          driverOrder,
          (D) => tmsDrops.includes(D.type) && D.prevType == "DELIVERLOAD"
        )
      : driverOrder.findIndex((D) => D.type == type);

    let searchIndex;
    if (point == "previous") {
      searchIndex = index - 1;
      if (searchIndex == -1) {
        searchIndex = index;
      }
    }
    if (point == "current") {
      searchIndex = index;
    }
    if (point == "next") {
      searchIndex = index + 1;
    }

    let location = driverOrder[searchIndex];
    if (index != -1 && location) {
      return Object.assign({}, location, {
        company: location.company_name,
      });
    }
    return {};
  };

  if (status == "DELIVERED") {
    from = getPoint("DELIVERLOAD", "previous");
    to = getPoint("DELIVERLOAD", "current");
  }
  if (status == "ARRIVED_PICKUP") {
    from = getPoint("PULLCONTAINER", "previous");
    to = getPoint("PULLCONTAINER", "current");
    if (from && !from.city) {
      from = to;
    }
    if (from && to && from._id == to._id) {
      to = getPoint("PULLCONTAINER", "next");
    }
  }
  if (status == "COMPLETED") {
    from = getPoint("RETURNCONTAINER", "previous");
    to = getPoint("RETURNCONTAINER", "current");
  }
  if (status == "NEED_TO_GET_DROP") {
    from = getPoint("HOOKCONTAINER", "previous", true);
    to = getPoint("DROPPEDCONTAINER", "current", true);
  }
  if (from || to) {
    location.from = [from];
    location.to = [to];
  }
  return location;
};

export const getFileTypeFromFilename = (item) => {
  var fileType = "";
  if (item) {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(item)[1].toLowerCase();
    if (ext === "jpeg" || ext === "jpg" || ext === "png" || ext === "bmp") {
      fileType = "IMG";
    } else if (ext === "pdf") {
      fileType = "PDF";
    } else if (ext === "csv") {
      fileType = "CSV";
    } else if (ext === "xls") {
      fileType = "EXCEL";
    } else if (ext === "doc" || ext === "docx") {
      fileType = "DOC";
    } else if (ext === "txt") {
      fileType = "TXT";
    } else {
      fileType = "OTHER";
    }
  }

  return fileType;
};

export const showForCarrier = () => {
  if (
    getStorage("currentUserRole") == "fleetmanager" &&
    config.idForCarrier.includes(
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
    )
  ) {
    return true;
  } else if (
    getStorage("currentUserRole") == "carrier" &&
    config.idForCarrier.includes(JSON.parse(getStorage("loggedInUser"))._id)
  ) {
    return true;
  } else {
    return false;
  }
};

export const showForCompany = () => {
  if (config.idForCompany.includes(getStorage("currentUserID"))) {
    return true;
  } else if (
    getStorage("currentUserRole") == "fleetmanager" &&
    config.idForCompany.includes(
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
    )
  ) {
    return true;
  } else if (
    getStorage("currentUserRole") == "carrier" &&
    config.idForCompany.includes(getStorage("currentUserID"))
  ) {
    return true;
  } else {
    return false;
  }
};


// Show For Terminal
export const showForTerminal = () => {
  if (config.idForTerminal.includes(getStorage("currentUserID"))) {
    return true;
  } else if (
    getStorage("currentUserRole") == "fleetmanager" &&
    config.idForTerminal.includes(
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
    )
  ) {
    return true;
  } else if (
    getStorage("currentUserRole") == "carrier" &&
    config.idForTerminal.includes(getStorage("currentUserID"))
  ) {
    return true;
  } else {
    return false;
  }
};

// get Carrier _id for Logged User With All Roles
export const getCarrierId = () => {
  const currentUser = JSON.parse(getStorage("loggedInUser"));
  const carrierDetail = JSON.parse(getStorage("carrierDetail"));
  if (currentUser) {
    let carrierId = currentUser?._id;
    if (currentUser.role === "driver") {
      carrierId = currentUser?.driver?.carrier;
    }
    if (currentUser?.role === "fleetmanager") {
      if(currentUser?.fleetManager?.isCustomer){
        carrierId = carrierDetail?._id
      }else{
        carrierId = currentUser?.fleetManager?.carrier;
      }
    }
    
    if (currentUser.role === "customer") {
      carrierId = currentUser?.customer?.carrier;
    }
    return carrierId;
  } else {
    return false;
  }
};

export const getDate = (dayINeed, endDate) => {
  // if we haven’t yet passed the day of the week that I need:
  let endDay = moment(endDate).tz(getStorage("timeZone")).isoWeekday();
  // console.log(endDay, dayINeed);
  if (endDay <= dayINeed) {
    // then just give me this week’s instance of that day
    return moment(endDate)
      .tz(getStorage("timeZone"))
      .isoWeekday(dayINeed)
      .startOf("days")
      .toISOString();
    // let a = moment(endDate).tz(getStorage('timeZone'))
    // let b = moment().tz(getStorage('timeZone')).isoWeekday(dayINeed);
    // if (b.diff(a) > 0) {
    //    return moment().tz().add(1, 'weeks').isoWeekday(dayINeed);
    // }
  } else {
    // otherwise, give me next week’s instance of that day
    return moment(endDate)
      .add(1, "weeks")
      .isoWeekday(dayINeed)
      .startOf("days")
      .toISOString();
  }
};

export var currencies = [
  { label: "Canadian Dollar", value: "CAD" },
  { label: "US Dollar", value: "USD" },
];

export const downloadCSV = (csv, filename) => {
  let csvFile;
  let downloadLink;
  csvFile = new Blob([csv], { type: "text/csv" });
  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const downloadTableAsCSV = (filename, rows) => {
  const csv = [];
  for (let i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td.csv, th.csv");
    for (let j = 0; j < cols.length; j++) {
      if (cols && cols[j]) {
        row.push(cols[j].innerText.replace(/,|\n/g, ""));
      }
    }
    csv.push(row.join(","));
  }

  downloadCSV(csv.join("\n"), `${new Date().getTime()}-${filename}.csv`);
};

export const getDatesBetweenTwoDate = (startDate, endDate, timeZone) => {
  const datesObj = [moment(startDate).tz(timeZone).format("MM/DD/YYYY")];
  var currDate = moment(startDate);
  var lastDate = moment(endDate);
  while (currDate.add(1, "days").diff(lastDate) < 0) {
    datesObj.push(currDate.tz(timeZone).format("MM/DD/YYYY"));
  }
  const lastDateMDY = moment(endDate).tz(timeZone).format("MM/DD/YYYY");
  if (datesObj.indexOf(lastDateMDY) === -1) {
    datesObj.push(lastDateMDY);
  }
  return datesObj;
};

export const timeFormatter = (date) => {
  let timeZone = getStorage("timeZone");
  return moment(date).tz(timeZone).format(DateTimeFormatUtils?.fullDateTimeFormat())
}


export function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}



export function changeTheme(themeVal) {
  if(!themeVal){
    const currenttheme = JSON.parse(getStorage("themeSetting"));
    if(currenttheme && currenttheme.themeName){
      themeVal=themesOptions.find(theme=>theme.name===currenttheme.themeName);
    }
    if(!themeVal){
      themeVal=themesOptions[0]
    }
  }
  let themeData=themeVal.themeData?themeVal.themeData:themeVal;
  const scssVariables = Object.keys(themeData);
  scssVariables.forEach((variable) => {
    document.documentElement.style.setProperty(variable, themeData[variable]);
  });
}

export var auditKey={
  "_id": "_id",
  "updatedAt": "Updated At",
  "createdAt": "Created At",
  "type_of_load": "Load Type",
  "status": "Status",
  "preSetOrderStatus": "Preset Order Status",
  "caller": "Caller",
  "addedBy": "Added By",
  "carrier": "Carrier",
  "reference_number": "Reference Number",
  "distance": "Distance",
  "pricing": "Pricing",
  "callerName": "Customer Name",
  "caller": "Customer ",
  "callerInfo": "Customer Info",
  "shipperName": "Shipper Name",
  "shipperAddress": "Shipper Address",
  "shipperInfo": "Shipper Info",
  "consigneeName": "Consignee Name",
  "consigneeAddress": "Consignee Address",
  "consigneeInfo": "Consignee Info",
  "weight": "Weight",
  "expense": "expense",
  "customerEmployee": "Customer Employee",
  "consignee": "Consignee",
  "shipper": "Port",
  "carrierHold": "Carrier Hold",
  "brokerHold": "Broker Hold",
  "overWeight": "Over Weight",
  "isConfirm": "Confirm",
  "remainAmount": "Remain Amount",
  "totalAmount": "Total Amount",
  "paidAmount": "Paid Amount",
  "totalWeight": "Total Weight",
  "isHot": "Hot",
  "isReadyForPickup": "Ready To Return",
  "isloadStartable": "Load Startable",
  "radio": "Radio",
  "isDeleted": "Is Deleted",
  "items": "Items",
  "additionalPricing": "Additional Pricing",
  "deliveryTimes": "Delivery Times",
  "returnTimes": "Return Times",
  "pickupTimes": "Pickup Times",
  "hot": "Hot",
  "hazmat": "Hazmat",
  "totalMiles": "Total Miles",
  "callerbillLandingNo": "Master Bill of Lading",
  "containerNo": "Container #",
  "containerOwner": "Container Owner",
  "containerOwnerName": "Container Owner Name",
  "containerSize": "Container Size",
  "containerSizeName": "Container Size",
  "containerType": "Container Type",
  "containerTypeName": "Container Type",
  "secondaryReferenceNo": "Reference #",
  "shipmentNo": "Shipment No",
  "scac": "Scac",
  "vessel": "Vessel",
  "caution": "caution",
  "custom": "Custom",
  "freight": "Freight",
  "errorMessage": "Error Message",
  "lastScrappedAt": "Data Last Scrapped Date",
  "lastContainerTracedDate": "Container Data Last Traced Date",
  "errorDate": "Error Date",
  "lastContainerErrorDate":"Last Traced Error at",
  "lastFreeDay":"Last Free Day",
  "chassisType":"Chassis Type",
  "chassisOwner":"Chassis Owner",
  "chassisNo":"Chassis #",
  "chassisSize":"Chassis Size",
  "bookingNo":"Booking #",
  "purchaseOrderNo":"Purchase Order #",
  "emptyOrigin":"Empty Origin",
  "trailer":"Trailer",
  "containerAvailableDay":"ERD",
  "callerPONo":"Pick Up #",
  "freeReturnDate":"Return Day",
  "emptyDay":"Empty Date",
  "returnToTime":"Return To",
  "returnFromTime":"Return From",
  "chassisTermination":"Terminate Chassis",
  "chassisPick":"Pickup Chassis",
  "reservationNo":"Reservation #",
  "returnNo":"Return #",
  "appointmentNo":"Appointment #",
  "releaseNo":"Voyage",
  "deliveryOrderNo":"Vessel Name",
  "sealNo":"Seal #",
  "doNo":"House Bill of Lading",
  "liquor":"Liquor",
  "routeType":"Route Type",
  "temperature":"Temperature",
  "trailerType":"Trailer Type",
  "trailerSize":"Trailer Size",
  "grayChassisNo": "Gray Chassis #",
  "grayContainerOwner": "Gray Container Owner",
  "grayContainerType": "Gray Container Type",
  "grayContainerSize": "Gray Container Size",
  "grayChassisOwner": "Gray Chassis Owner",
  "grayChassisSize": "Gray Chassis Size",
  "grayChassisType": "Gray Chassis Type",
  "grayContainerNo": "Gray Container #",
  "isContainerConfirm": "Container Confirm",
  "isGrayPool": "Gray Pool",
  "consigneeInfo": "Consignee",
  "shipperInfo": "Port",
  "loadCompletedAt": "Load Completed Date",
  "ingateDate":"InGate date",
  "outgateDate":"OutGate date",
  "myDates.outgateDate": "OutGate date",
  "myDates.deliveredToUnloadDate": "Delivered To Unload Date",
  "myDates.emptyDay": "Empty Date",
  "myDates.ingateDate": "Ingate Date",
  "myDates.loadCompletedDate": "Load Completed Date",
  "availableDate":"Available Date",
  "dischargedDate":"Discharge Date",
  "status":"Status",
  "pickupAppointment":"Pickup Appointment Status",
  "emptyAppointment":"Empty Appointment Status",
  "cutOff":"cutOff",
  "isoContainerType":"ISO container type",
  "portNote": "Port Note",
  'vesselETA': 'Vessel ETA',
  'readyToReturnDate': 'Ready To Return Date',
  'isGenset': 'Genset',
  'isStreetTurn': 'Street Turn',
  'domestic': 'Domestic',
  'ev': 'EV',
  'waste': 'Waste',
  'gdp': 'GDP',
  'isRail': 'Rail',
  "overHeight": "Over Height",
}

export const selectedCharge  = (chargeCodeList, name) =>{
  let charge = chargeCodeList.find(
   (pso) => pso.value == name
  ) 
  if(charge){
    return {label :  charge.chargeName, value : charge.value}
  }else{
    return {}
  }
}

export async function addAgentToken() {
  const token = getStorage("token");
  let data={isConfirm:true};
  const response = await HTTP("post", `tms/qbDesktopCreateAgentToken`, data, {
    authorization: token,
  });
  return response;
}

export async function refreshAgentToken() {
  const token = getStorage("token");
  const response = await HTTP("get", `tms/qbDesktopCheckConnectedAgentToken`, null, {
    authorization: token,
  });
  return response;
}


export async function authorizeAutoFyToken() {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/qbDesktopSyncAutofy`, null, {
    authorization: token,
  });

  return response;
}
// ****** Dispatch Filter Start
export const setupDispatchSessionTab = (state) => {
  const views = getSessionStorage("tabDispatchViews", true)
  if(!views) {
    setSessionStorage("tabDispatchViews", [
      {
        id: 'default',
        ...convertStateToViewParams(state),
      }
    ], true);
    setSessionStorage("activeDispatchView", "default");
  }
}

export const syncSessionDispatchWithDb = () => {
  const dispatchViews = getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews"));
  const sessionViews = getSessionStorage("tabDispatchViews", true);
  if(!dispatchViews || dispatchViews==="undefined") return
  setSessionStorage("tabDispatchViews", [
    sessionViews ? sessionViews[0] : {id : 'deafult'},
    ...dispatchViews
  ], true);
}

// Update each tab fiters attributes
// currentView is view.id or view_id
const updateDispatchView = (currentView, keys, values, clearAll=false) => {
  const views = getSessionStorage("tabDispatchViews", true);
  if(!currentView) currentView = "default";

  const selectedViewIdx = views && views.findIndex((view) => {
    return (view.id == currentView) || (view._id == currentView);
  })
  if(clearAll){
    views[selectedViewIdx] = {id: currentView} 
    setSessionStorage("tabDispatchViews", views, true)
    setSessionStorage("activeDispatchView", currentView);
    
    return
  }
  if(typeof selectedViewIdx === 'number' && selectedViewIdx !== -1){

    keys.map((key, index) => {
      views[selectedViewIdx][key] = values[index];
    })

    setSessionStorage("tabDispatchViews", views, true);
    setSessionStorage("activeDispatchView", currentView);

  }
}
// update Tab specific dispatcher State to localstorage and session storage
export const updateSessionDispatchView = (state, clearAll=false) => {
  const viewParams = convertStateToViewParams(state);
  const keys = Object.keys(viewParams);
  const values = Object.values(viewParams); 
  // convertStateToDispatchURL(state);
  // Clear filter query param
  // const baseLocation = document.location.href.split("?")[0]
  // window.history.pushState({}, null, `${baseLocation}`);

  updateDispatchView(state.currentView, [...keys],[...values], clearAll);
}

export const updateQueryString = (filterParams) => {
  const baseLocation = document.location.href.split("?")[0];
  if (!_.isEmpty(filterParams)) {
    const qs = '?' + Object.keys(filterParams)
    .map(key => `${key}=${encodeURIComponent(filterParams[key])}`)
    .join('&')
    window.history.pushState({}, null, `${baseLocation}${qs}`);
  } else {
    window.history.pushState({}, null, `${baseLocation}`);
  }
}

export const getObjFromQueryString = (querystring) => {
  const queryObj = {};
  let queries = querystring.split('&');
  queries.forEach((_str) => {
    const field = _str.split('=');
    queryObj[field[0]] = decodeURIComponent(field[1]);
  });
  return queryObj;
}

export const convertStateToDispatchURL = (state) => {
  convertStateToQueryString(state).then((filterParams) => {
    updateQueryString(filterParams)
  })
  // const str = encryptString(JSON.stringify(filterParams))
  // const dec = descryptString(str)
  // const qs = '?' + "filter=" + encodeURIComponent(str)

}
// Get current tab dispatcher filter state from localstorage
export const getSessionDispatchView = (viewId, isState=true) => {
  
  let views = (getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews")))
  if(!views || views==="undefined") 
    return null
  const idx = views.findIndex((view) => {
    return view.id === viewId || view._id === viewId
  });
  if(idx===-1) return null
  
  let ret = views[idx];
  if(isState) {
    ret = convertViewParamsToState(views[idx])
  }
  return ret;
}



// Convert state loaded form localstorage to dispatcherView format
export const convertStateToViewParams = (state, saveEmpty=true) => {
  let viewParams = {}
  if(state.currentView){
    viewParams['id'] = state.currentView
  }

  viewParams['viewName'] = state.currentViewName ? state.currentViewName.trim() : "default"
  viewParams['isDefaultView'] = state.isDefaultView
  viewParams['selectedStatuses'] = state.selectedStatuses
  viewParams['subFilter'] = state.subFilter2
  if(state.vesselName && state.vesselName !== "")
    viewParams['vesselName'] = state.vesselName
  else
    if(saveEmpty)  
      viewParams['vesselName'] = ""

  if (state.grid3TypeOfLoad && state.grid3TypeOfLoad.length > 0)
    viewParams.type_of_load = state.grid3TypeOfLoad.map((obj) => {
      return obj.value;
    });
  else
    if(saveEmpty)
      viewParams.type_of_load = []

  if (state.grid3caller && state.grid3caller.length > 0)
    viewParams.caller = state.grid3caller.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.caller = []

  if (state.selectedCarriers && state.selectedCarriers.length > 0)
    viewParams.carrier = state.selectedCarriers.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.carrier = []
  if (state.grid3shipper && state.grid3shipper.length > 0)
    viewParams.shipper = state.grid3shipper.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.shipper = []

  if (state.grid3consignee && state.grid3consignee.length > 0)
    viewParams.consignee = state.grid3consignee.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.consignee = []
  if (state.grid3emptyorigin && state.grid3emptyorigin.length > 0)
    viewParams.emptyOrigin = state.grid3emptyorigin.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.emptyOrigin = []

  if (state.grid3ContainerSize && state.grid3ContainerSize.length > 0)
    viewParams.containerSize = state.grid3ContainerSize.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.containerSize = []

  if (state.grid3ContainerType && state.grid3ContainerType.length > 0)
    viewParams.containerType = state.grid3ContainerType.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.containerType = []

  if (state.grid3SSL && state.grid3SSL.length > 0)
    viewParams.containerOwner = state.grid3SSL.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.containerOwner = []

  if (state.grid3ChassisType && state.grid3ChassisType.length > 0)
    viewParams.chassisType = state.grid3ChassisType.map((obj) => {
      return obj.allInfo;
    });
  else
    if (saveEmpty)
      viewParams.chassisType = []

  if (state.grid3ChassisSize && state.grid3ChassisSize.length > 0)
    viewParams.chassisSize = state.grid3ChassisSize.map((obj) => {
      return obj.allInfo;
    });
  else
    if (saveEmpty)
      viewParams.chassisSize = []

  if (state.grid3ChassisOwner && state.grid3ChassisOwner.length > 0)
    viewParams.chassisOwner = state.grid3ChassisOwner.map((obj) => {
      return obj.allInfo;
    });
  else
    if (saveEmpty)
      viewParams.chassisOwner = []
  
  if (state.grid3driver && state.grid3driver.length > 0)
    viewParams.drivers = state.grid3driver.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.drivers = []

  if (state.grid3filterStatusField && state.grid3filterStatusField.length > 0)
    viewParams.status = state.grid3filterStatusField.map((obj) => {
      return obj.value;
    });
  else
    if(saveEmpty)
      viewParams.status = []

  if (state.subFilter)
    viewParams.status = viewParams.status && viewParams.status.length > 0 ?
                        _.union(viewParams.status, [state.subFilter]) : [state.subFilter]
  
  if(state.pickUpLabel)
    viewParams["pickUpLabel"] = state.pickUpLabel;
  else 
    viewParams["pickUpLabel"] = undefined

  if (state.pickupFrom && state.pickUpTo) {
    viewParams["pickupFrom"] = moment(state.pickupFrom).toISOString();
    viewParams["pickUpTo"] = moment(state.pickUpTo).toISOString();
  } else {
    viewParams["pickupFrom"] = undefined
    viewParams["pickUpTo"] = undefined
  }

  if(state.deliveryLabel)
    viewParams["deliveryLabel"] = state.deliveryLabel;
  else 
    viewParams["deliveryLabel"] = undefined  

  if (state.deliveryFrom && state.deliveryTo ) {
    viewParams["deliveryFrom"] = moment(state.deliveryFrom).toISOString();
    viewParams["deliveryTo"] = moment(state.deliveryTo).toISOString(); 
  } else {
    viewParams["deliveryFrom"] = undefined
    viewParams["deliveryTo"] = undefined
  }


  if(state.returnAPTLabel)
    viewParams["returnAPTLabel"] = state.returnAPTLabel;
  else 
    viewParams["returnAPTLabel"] = undefined  
  
  if (state.returnAPTFrom && state.returnAPTTo) {
    viewParams["returnAPTFrom"] = moment(state.returnAPTFrom).toISOString();
    viewParams["returnAPTTo"] = moment(state.returnAPTTo).toISOString();
  } else {
    viewParams["returnAPTFrom"] = undefined
    viewParams["returnAPTTo"] = undefined
  }

  if(state.lastFreeDayLabel)
    viewParams["lastFreeDayLabel"] = state.lastFreeDayLabel;
  else 
    viewParams["lastFreeDayLabel"] = undefined  
    
  if (state.lastFreeDayFrom && state.lastFreeDayTo) {
    viewParams["lastFreeDayFrom"] = moment(state.lastFreeDayFrom).toISOString();
    viewParams["lastFreeDayTo"] = moment(state.lastFreeDayTo).toISOString();
  } else {
    viewParams["lastFreeDayFrom"] = undefined
    viewParams["lastFreeDayTo"] = undefined
  }

  if(state.freeReturnLabel)
    viewParams["freeReturnLabel"] = state.freeReturnLabel;
  else 
    viewParams["freeReturnLabel"] = undefined  
      
  if (state.freeReturnFrom && state.freeReturnTo) {
    viewParams["freeReturnFrom"] = moment(state.freeReturnFrom).toISOString();
    viewParams["freeReturnTo"] = moment(state.freeReturnTo).toISOString();
  } else {
    viewParams["freeReturnFrom"] = undefined
    viewParams["freeReturnTo"] = undefined
  }


  if(state.cutOffLabel)
    viewParams["cutOffLabel"] = state.cutOffLabel;
  else 
    viewParams["cutOffLabel"] = undefined  
        
  if (state.cutOffFrom && state.cutOffTo) {
    viewParams["cutOffFrom"] = moment(state.cutOffFrom).toISOString();
    viewParams["cutOffTo"] = moment(state.cutOffTo).toISOString();
  } else {
    viewParams["cutOffFrom"] = undefined
    viewParams["cutOffTo"] = undefined
  }


  if (state.terminal && state.terminal.value) {
    viewParams["terminal"] = state.terminal.allInfo;
  } else {
    if(saveEmpty)
      viewParams["terminal"] = {}
  }
  if (state.grid3createdByUser) {
    viewParams["addedBy"] = state.grid3createdByUser.map(
      (user) => user.allInfo
    );
  } else {
    if(saveEmpty)
      viewParams["addedBy"] = []
  }
  if(state.grid3csr){
    viewParams['assignedCSR'] = state.grid3csr
  } else {
    if(saveEmpty)
      viewParams["assignedCSR"] = []
  }
  viewParams["isReadyForPickup"] = state.isReadyForPickupShow
  if(state.sortBy){
    viewParams["sortBy"] = state.sortBy
  } else {
    viewParams["sortBy"] = {createdAt: -1}
  }

  // EXTRA ADDED

  // // -------------------- add subcard filter --------------------
  if (state.filterBySubCard) {
    viewParams["filterBySubCard"] = state.filterBySubCard;
  }



  return viewParams
}

export const filterAppointmentMap = (from, to, label) => {
  let updatedLabel = null, updatedFrom = null, updatedTo = null;
  if(label){
    updatedLabel = label
    if(updatedLabel === "Custom Range") {
      if(from){
        updatedFrom = from
      }
      if(to){
        updatedTo = to
      }
    } else {
      updatedFrom = RANGES[updatedLabel][0]
      updatedTo = RANGES[updatedLabel][1]
    }
  }
   else {
    if(from){
      updatedFrom = from
    }
    if(to){
      updatedTo = to
    }
  }
  return [updatedFrom, updatedTo, updatedLabel]
} 

export const convertViewParamsToState = (viewParams) => {
  let updatedDrivers = [],
  updatedCallers = [], 
  updatedShippers = [], 
  updatedConsignees = [], 
  updatedEmptyOrigins = [], 
  updatedAddedBy = [],
  updatedContainerType = [],
  updatedContainerSize = [],
  updatedContainerOwner = [],
  updatedChassisType = [],
  updatedChassisSize = [],
  updatedChassisOwner = [],
  updatedTerminal = [],
  updatedSelectedStatuses = [],
  updatedTypeOfLoad = [],
  updatedStatus = [],
  updatedCSR = [],
  updatedSortBy = { createdAt: -1 },
  updatedFilterBySubCard = [],
  updatedVesselName = '',
  dateFilter = 'all',
  selectedDate = null;
  
  if(viewParams.drivers){
    updatedDrivers = viewParams.drivers.map((d) =>{
      return {
        value: d._id,
        label: `${d.name} ${d.lastName}`.trim(),
        allInfo: d
      }
    })
  }
  if(viewParams.caller) {
    updatedCallers = makeOptionsForSelect(
      viewParams.caller,
      "company_name",
      "address.address",
      "_id") 
  }
  if(viewParams.shipper){
    updatedShippers = makeOptionsForSelect(
      viewParams.shipper,
      "company_name",
      "address.address",
      "_id")
  }
  if(viewParams.consignee){
    updatedConsignees = makeOptionsForSelect(
      viewParams.consignee,
      "company_name",
      "address.address",
      "_id")
  }
  if(viewParams.emptyOrigin){
    updatedEmptyOrigins =  makeOptionsForSelect(
      viewParams.emptyOrigin,
      "company_name",
      "address.address",
      "_id")
  }
  if(viewParams.addedBy){
    updatedAddedBy = viewParams.addedBy.map((d) => {
      return {
        value: d._id,
        label: `${d.name} ${
          d.name.includes(d.lastName) ? "" : d.lastName 
        }`.trim(),
        allInfo: d,
      }
    })
  }
  if(viewParams.containerType){
    updatedContainerType = viewParams.containerType.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      }
    })
  }
  if(viewParams.containerSize){
    updatedContainerSize = viewParams.containerSize.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      }
    })
  }
  if(viewParams.containerOwner){
    updatedContainerOwner = viewParams.containerOwner.map((d) => {
      return {
        value: d._id,
        label: d.company_name,
        allInfo: d,
      }
    })
  }

  if(viewParams.chassisType){
    updatedChassisType = viewParams.chassisType.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      }
    })
  }
  if(viewParams.chassisSize){
    updatedChassisSize = viewParams.chassisSize.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      }
    })
  }
  if(viewParams.chassisOwner){
    updatedChassisOwner = viewParams.chassisOwner.map((d) => {
      return {
        value: d._id,
        label: d.company_name,
        allInfo: d,
      }
    })
  }
  if(viewParams.terminal){
    updatedTerminal = { 
        value: viewParams.terminal._id,
        label: viewParams.terminal.name,
        allInfo: viewParams.terminal,
    }
  }
  if(viewParams.selectedStatuses){
    updatedSelectedStatuses = viewParams.selectedStatuses
  }

  // Pickup
 const [
  updatedPickUpFrom,
  updatedPickUpTo,
  updatedPickUpLabel
 ] = filterAppointmentMap(
  viewParams.pickupFrom, 
  viewParams.pickUpTo, 
  viewParams.pickUpLabel
  )
  // Delivery
 const [
  updatedDeliveryFrom,
  updatedDeliveryTo,
  updatedDeliveryLabel
 ] = filterAppointmentMap(
  viewParams.deliveryFrom, 
  viewParams.deliveryTo, 
  viewParams.deliveryLabel
  )
  // ReturnAPT
  const [
    updatedReturnAPTFrom,
    updatedReturnAPTTo,
    updatedReturnAPTLabel
   ] = filterAppointmentMap(
    viewParams.returnAPTFrom, 
    viewParams.returnAPTTo, 
    viewParams.returnAPTLabel
    )
  // LastFreeDay
  const [
    updatedLastFreeDayFrom,
    updatedLastFreeDayTo,
    updatedLastFreeDayLabel
   ] = filterAppointmentMap(
    viewParams.lastFreeDayFrom, 
    viewParams.lastFreeDayTo, 
    viewParams.lastFreeDayLabel
    )
  // FreeReturn
  const [
    updatedFreeReturnFrom,
    updatedFreeReturnTo,
    updatedFreeReturnLabel
   ] = filterAppointmentMap(
    viewParams.freeReturnFrom, 
    viewParams.freeReturnTo, 
    viewParams.freeReturnLabel
    )
  // CutOff
  const [
    updatedCutOffFrom,
    updatedCutOffTo,
    updatedCutOffLabel
   ] = filterAppointmentMap(
    viewParams.cutOffFrom, 
    viewParams.cutOffTo, 
    viewParams.cutOffLabel
    )
  if (viewParams.type_of_load){ 
    updatedTypeOfLoad = viewParams.type_of_load.map((d) =>{
      return {
        value: d,
        label: d
      }
    })
  }
  if (viewParams.status)
    updatedStatus = viewParams.status.map((d) => {
      return {
        value: d,
        label: d
      }
    });
  if(viewParams.assignedCSR){
    updatedCSR = viewParams.assignedCSR
  }
  if(viewParams.sortBy){
    if(typeof viewParams.sortBy == "string")
      updatedSortBy = JSON.parse(viewParams.sortBy)
    else
      updatedSortBy = viewParams.sortBy
  }
  if(viewParams.dateFilter){
    dateFilter = viewParams.dateFilter;
    selectedDate = getSelectedDate(dateFilter)
  }
    // EXTRA ADDED

  // // -------------------- add subcard filter --------------------
  if(viewParams.filterBySubCard && viewParams.filterBySubCard.length > 0){
    updatedFilterBySubCard = viewParams.filterBySubCard
  }
  if(viewParams.vesselName){
    updatedVesselName = viewParams.vesselName
  }

  const subFiter = _.intersection(["AVAILABLE", "PENDING"], updatedStatus.map((status) => status.value))
  let loadsFilter = {
    grid3TypeOfLoad: updatedTypeOfLoad,
    grid3caller: updatedCallers,
    grid3consignee: updatedConsignees,
    grid3emptyorigin: updatedEmptyOrigins,
    grid3driver: updatedDrivers,
    grid3shipper: updatedShippers,
    grid3filterStatusField: updatedStatus,
    grid3ContainerType: updatedContainerType,
    grid3ContainerSize: updatedContainerSize,
    grid3SSL: updatedContainerOwner,
    grid3ChassisType: updatedChassisType,
    grid3ChassisSize: updatedChassisSize,
    grid3ChassisOwner: updatedChassisOwner,
    grid3csr : updatedCSR,
    grid3createdByUser: updatedAddedBy,
    vesselName: updatedVesselName
  };
  return {
    currentView : viewParams._id || viewParams.id,
    currentViewName : viewParams.viewName, 
    grid3caller : updatedCallers, 
    grid3driver : updatedDrivers, 
    grid3shipper : updatedShippers,
    grid3consignee : updatedConsignees,
    grid3emptyorigin : updatedEmptyOrigins,
    grid3createdByUser : updatedAddedBy,
    grid3ContainerType : updatedContainerType,
    grid3ContainerSize : updatedContainerSize,
    grid3SSL : updatedContainerOwner,
    grid3ChassisType: updatedChassisType,
    grid3ChassisSize: updatedChassisSize,
    grid3ChassisOwner: updatedChassisOwner,
    terminal : updatedTerminal,
    selectedStatuses : updatedSelectedStatuses,
    pickupFrom : updatedPickUpFrom,
    pickUpTo : updatedPickUpTo,
    deliveryFrom : updatedDeliveryFrom,
    deliveryTo : updatedDeliveryTo,
    returnAPTFrom: updatedReturnAPTFrom,
    returnAPTTo: updatedReturnAPTTo,
    lastFreeDayFrom: updatedLastFreeDayFrom,
    lastFreeDayTo: updatedLastFreeDayTo,
    freeReturnFrom: updatedFreeReturnFrom,
    freeReturnTo: updatedFreeReturnTo,
    cutOffFrom: updatedCutOffFrom,
    cutOffTo: updatedCutOffTo,
    pickUpLabel: updatedPickUpLabel,
    deliveryLabel: updatedDeliveryLabel,
    returnAPTLabel: updatedReturnAPTLabel,
    lastFreeDayLabel: updatedLastFreeDayLabel,
    freeReturnLabel: updatedFreeReturnLabel,
    cutOffLabel: updatedCutOffLabel,
    grid3TypeOfLoad : updatedTypeOfLoad,
    isReadyForPickupShow : viewParams.isReadyForPickup,
    grid3filterStatusField : updatedStatus,
    loadsFilter: loadsFilter,
    grid3csr : updatedCSR,
    sortBy: updatedSortBy,
    isDefaultView: viewParams.isDefaultView || false,
    subFilter2: viewParams.subFilter,
    subFilter: subFiter && subFiter.length>0 ? subFiter[0] : undefined,
    filterBySubCard: updatedFilterBySubCard,
    vesselName: updatedVesselName,
    dateFilter: dateFilter,
    selectedDate : selectedDate,
  }
}

export const convertStateToQueryString = async (state) => {
  let filterParams = {};
  const params = convertStateToViewParams(state, false)
  const data = await createDispatcherFilter({
    filter: JSON.stringify(params)
  })
  if(data && data.filterId) {
    filterParams["filter"] = data.filterId
  }
  if(state.currentView) {
    filterParams["view"] = state.currentView
  } else {
    filterParams["view"] = "default"
  }
  return filterParams
}

export const getBasicSettings = () => {
  return new Promise((resolve, reject) => {
    let url = "user/get-basic-settings";
    const token = getStorage("token");
    HTTP("get", url, null, {
      Authorization: token,
    }).then((result) => {
      let userBasicSettings = result.data.data;
      
      delete userBasicSettings?.groupedProfiles;
      delete userBasicSettings?.groupedCities;
      delete userBasicSettings?.groupedZipcodes;

      setStorage("userBasicSettings", JSON.stringify(userBasicSettings));
      resolve(result?.data?.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getEmbeddedEmailAccount = (email, firebaseUpdate = false) => {
  return new Promise((resolve, reject) => {
    let url = "v1/embedded-email-auth/account";
    try {
      const token = getStorage("token");
        HTTP("get", url, null, {
          Authorization: token,
        }).then((result) => {
          let embeddedEmailAccount = result?.data?.data;
          if(embeddedEmailAccount && embeddedEmailAccount?.length) {
            const currentActiveUser = JSON.parse(getStorage("embeddedEmailAccount")) ?? {};
            // change logic when we add default account 
            const validAccounts = getEmailByPermissions(embeddedEmailAccount)
            const defaultAccount = getValidDefaultAccount(validAccounts)
            if(defaultAccount && !firebaseUpdate) setStorage("embeddedEmailAccount", JSON.stringify(defaultAccount));
            if(email && !firebaseUpdate){
              const mailRecentlyConnected = validAccounts?.find((mail) => mail?.email?.toLowerCase() === email?.toLowerCase())
              if(mailRecentlyConnected) setStorage("embeddedEmailAccount", JSON.stringify(mailRecentlyConnected));
            }
            setStorage('allConnectedEmailAccounts', JSON.stringify(sortAllMailBasedOnDefaul(validAccounts)))
          }
          else {
            removeItem("embeddedEmailAccount")
            removeItem("allConnectedEmailAccounts");
          };
          resolve(embeddedEmailAccount);
        })
          .catch((error) => {
            removeItem("embeddedEmailAccount");
            removeItem("allConnectedEmailAccounts");
            reject(error);
          });
    } catch(err){
      console.log(err, "err")
      return
    }
  });
}

export const getEmbeddedEmailAccountDetail = () => {
  return new Promise((resolve, reject) => {
    let url = "v1/embedded-email-auth/account";
    try {
      const token = getStorage("token");
        HTTP("get", url, null, {
          Authorization: token,
        }).then((result) => {
          let embeddedEmailAccount = result?.data?.data;
          const validAccount = getEmailByPermissions(embeddedEmailAccount)
          resolve(validAccount);
        })
          .catch((error) => {
            reject(error);
          });
    } catch(err){
      console.log(err, "err")
      return
    }
  });
}
export const types = [
  { index: 0, value: 'perday', label: 'perday' },
  { index: 1, value: 'perhour', label: 'perhour' },
  { index: 2, value: 'perpound', label: `per${convertUnit('convertedUnit', 'weight')}` },
  { index: 3, value: 'permile', label: `per${convertUnit()}` },
  { index: 4, value: 'fixed', label: 'fixed' },
  { index: 5, value: 'percentage', label: 'percentage' },
  { index: 6, value: 'per15min',label: 'per 15min' },
  { index: 7, value: 'per30min',label: 'per 30min' },
  { index: 8, value: 'per45min',label: 'per 45min' },
];
/// Chnage label in load info modal and load info screen

export const LoadInfoLabel = (type,field) => {
  
  if (field === "consignee") {
    if (type === 'EXPORT') {
      return "Loading Location"
    } else if (type === "ROAD" || type === "IMPORT")  {
      return "Delivery Location"
    }
  } else if (field === "shipper") {
    return "Pick Up Location"
  }else {
    return field
  }
}
export const isCreditMemo = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.creditFlag ?? false;
  if (loggedInUser && loggedInUser.role === "carrier" && flag) {
    return true;
  } else if (flag && loggedInUser && (loggedInUser.role === "fleetmanager" && !loggedInUser.fleetManager?.isCustomer)) {
    return true;
  } 
  else {
    return false;
  }
}

export const isNewDeduction = () => {
  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const userSettings = JSON.parse(getStorage("userBasicSettings") ?? "{}");
  const isCarrier = loggedInUser?.role === "carrier";
  const isFleetManager = loggedInUser?.role === "fleetmanager";
  const flag = userSettings?.isNewDeduction;

  return (flag && (isCarrier || isFleetManager)) || false;
};
export const checkAccountPayablePermission = (currentUser) => {
  const defaultTrue = currentUser?.role === 'carrier' || currentUser?.role === 'admin';
  let driverPayEditPermission = defaultTrue || currentUser?.permissions.includes("billing_driver_adjust");
  let driverPayApprovedPermission = defaultTrue || currentUser?.permissions.includes("billing_driver_approve");
  let driverPayDeletePermission = defaultTrue || currentUser?.permissions.includes("billing_driver_allow_delete");
  let driverPayShowPermission = defaultTrue || currentUser?.permissions.includes("billing_driver_pay");
  let settlementShowPermission = defaultTrue || currentUser?.permissions.includes("billing_driver_settlement");
  let settlementFinalizePermission = defaultTrue || currentUser?.permissions.includes("billing_driver_settlement_Finalize");
  let settlementReviewPermission = defaultTrue || currentUser?.permissions.includes("billing_driver_settlement_review");
  let driverDeductionShowPermission = defaultTrue || currentUser?.permissions.includes("driver_deduction_show");
  let driverDeductionEditPermission = defaultTrue || currentUser?.permissions.includes("driver_deduction_adjust");
  let driverDeductionApprovedPermission = defaultTrue || currentUser?.permissions.includes("driver_deduction_approve");
  let driverDeductionDeletePermission = defaultTrue || currentUser?.permissions.includes("driver_deduction_allow_delete");
  return {
    settlementFinalizePermission, settlementReviewPermission, settlementShowPermission,
    driverPayApprovedPermission, driverPayDeletePermission, driverPayShowPermission, driverPayEditPermission,
    driverDeductionShowPermission, driverDeductionEditPermission, driverDeductionApprovedPermission, driverDeductionDeletePermission
  }
}

export const checkCustomerProfilePermission = (currentUser) => {
  const defaultTrue = currentUser?.role === 'carrier' || currentUser?.role === 'admin';
  let customerProfileCustomersPermission = defaultTrue || currentUser?.permissions.includes("customer_profile_customers");
  let customerProfileShipperPermission = defaultTrue || currentUser?.permissions.includes("customer_profile_shipper");
  let customerProfileConsigneePermission = defaultTrue || currentUser?.permissions.includes("customer_profile_consignee");
  let customerProfileChassisPermission = defaultTrue || currentUser?.permissions.includes("customer_profile_chassis");
  
  return {
    customerProfileCustomersPermission, customerProfileShipperPermission, customerProfileConsigneePermission, customerProfileChassisPermission
  }
}

export const checkAccountReceivablePermission = () => {
  const currentUser = JSON.parse(getStorage('loggedInUser'));
  let defaultTrue = !isNewBillingEnabled() || currentUser.role === "carrier" || currentUser.role === 'admin';
  if(!defaultTrue) {
    if(currentUser.role === 'fleetmanager') {
      if(currentUser?.fleetManager?.isCustomer) defaultTrue = true
    } else {
      defaultTrue = true
    }
  }
  let billingInvoiceEditPermission = defaultTrue || currentUser?.permissions.includes("billing_invoice_edit_permission");
  let billingEmailSendPermission = defaultTrue || currentUser?.permissions.includes("billing_invoice_send_email_permission");
  let billingApprovedPermission = defaultTrue || currentUser?.permissions.includes("billing_invoice_approve_permission");
  let billingInvoicePermission = defaultTrue || currentUser?.permissions.includes("billing_invoice_permission");
  let billingInvoiceDownloadPermission = defaultTrue || currentUser?.permissions.includes("billing_invoice_download_permission");
  let billingInvoiceDeletePermission = defaultTrue || currentUser?.permissions.includes("billing_invoice_delete_permission");
  let creditMemoEditPermission = defaultTrue || currentUser?.permissions.includes("credit_memo_edit_permission");
  let creditMemoEmailSendPermission = defaultTrue || currentUser?.permissions.includes("credit_memo_send_email_permission");
  let creditMemoApprovedPermission = defaultTrue || currentUser?.permissions.includes("credit_memo_approve_permission");
  let creditMemoInvoicePermission = defaultTrue || currentUser?.permissions.includes("credit_memo_invoice_permission");
  let creditMemoDownloadPermission = defaultTrue || currentUser?.permissions.includes("credit_memo_download_permission");
  let creditMemoDeletePermission = defaultTrue || currentUser?.permissions.includes("credit_memo_delete_permission");
  let applyPaymentInvoicePermission = defaultTrue || currentUser?.permissions.includes("billing_batch_apply_payment");
  let loadApplayPaymentTabPermission = defaultTrue || currentUser?.permissions.includes('customer_service_payments');
  let driverExpenseEditPermission = defaultTrue || currentUser?.permissions.includes("driver_expense_adjust");
  let voidInvoicePermission = defaultTrue || currentUser?.permissions.includes("invoice_void_invoice");
  return {
    billingInvoiceEditPermission, billingEmailSendPermission, billingApprovedPermission, billingInvoicePermission, 
    billingInvoiceDownloadPermission, billingInvoiceDeletePermission, applyPaymentInvoicePermission,
    creditMemoEditPermission, creditMemoEmailSendPermission, creditMemoApprovedPermission, creditMemoInvoicePermission,
    creditMemoDownloadPermission, creditMemoDeletePermission, loadApplayPaymentTabPermission, driverExpenseEditPermission, voidInvoicePermission
  }
}

export const isInlineDriverEdit = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isInlineDriverEdit ?? false;
  if (loggedInUser && loggedInUser.role === "carrier" && flag) {
    return true;
  } else if (flag && loggedInUser && loggedInUser.role === "fleetmanager") {
    return true;
  } 
  else {
    return false;
  }
}

export const isHideMessageBubble = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = JSON.parse(getStorage("userBasicSettings"))?.permissions?.isHideMessageBubble;
  if (loggedInUser && (getStorage("currentUserRole") === "customer" || loggedInUser?.fleetManager?.isCustomer) && flag) {
    return true;
  } else {
    return false;
  }
}
export const isHideLoadSummary = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = JSON.parse(getStorage("userBasicSettings"))?.permissions?.isHideLoadSummary;
  if (loggedInUser && (getStorage("currentUserRole") === "customer" || loggedInUser?.fleetManager?.isCustomer) && flag) {
    return true;
  } else {
    return false;
  }
}
export const isFreeFlowOn = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isFreeFlow ?? false;
  if (loggedInUser && loggedInUser.role === "carrier" && isFlagOn) {
    return true
  } else if (isFlagOn && loggedInUser && loggedInUser.role === "fleetmanager" && loggedInUser.permissions.length !== 0 && loggedInUser.permissions.includes("load_free_flow")) {
    return true
  } else {
    return false
  }
}

export const isTripOn = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const isTripsOn = isTripEnabled();
  if (loggedInUser && loggedInUser.role === "carrier" && isTripsOn) {
    return true
  } else if (isTripsOn && loggedInUser && loggedInUser.role === "fleetmanager" && loggedInUser.permissions.length !== 0 && loggedInUser.permissions.includes("load_free_flow")) {
    return true
  } else {
    return false
  }
}

export const isRailOrderOn = () => {
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isRailOrderEnabled ?? false
  if (isFlagOn) {
    return true
  } else {
    return false
  }
}

export const isBargeVendorEnabled = () => !!(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isBargeVendor ?? false)

export const isTerminalContainerStatusEnabled = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"))
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isTerminalContainerStatusEnabled ?? false
  if (loggedInUser && loggedInUser.role === "carrier" && isFlagOn) {
    return true
  } else if (isFlagOn && loggedInUser && loggedInUser.role === "fleetmanager") {
    return true
  } else {
    return false
  }
}

export const isCustomsTypeEnabled = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"))
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isCustomsTypeEnabled ?? false

  if (loggedInUser && loggedInUser.role === "carrier" && isFlagOn) return true
  else if (isFlagOn && loggedInUser && loggedInUser.role === "fleetmanager") return true
  else return false
}

export const isRadiusRateOn = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isRadiusRate ?? false
  if (loggedInUser && loggedInUser.role === "carrier" && isFlagOn) {
    return true
  } else if (isFlagOn && loggedInUser && loggedInUser.role === "fleetmanager") {
    return true
  } else {
    return false
  }
}

export const isStopOffOn = () => {
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isStopOff ?? false;
  if (isFlagOn) {
    return true
  } else {
    return false
  }
}


export const isMultiContainerOn = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isMultiContainerEnabled ?? false
  if (loggedInUser && loggedInUser.role === "carrier" && isFlagOn) {
    return true
  } else if (isFlagOn && loggedInUser && loggedInUser.role === "fleetmanager") {
    return true
  } else {
    return false
  }
}
export const isValidContainer = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isContainerNoValid ?? false
  if (isFlagOn) {
    return true
  } else {
    return false
  }
}

export const getUserAddressFormat = () => {
  return new Promise((resolve, reject) => {
    let url = "user/getUserAddressFormat";
    const token = getStorage("token");
    HTTP("get", url, null, {
      Authorization: token,
    })
      .then((result) => {
        setStorage("userAddressFormat", JSON.stringify(result.data.data));
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserDateTimeFormat = async() => {
  return new Promise(async(resolve,reject) => {
    try {
      const url = "user/getDefaultDateFormat";
      const token = getStorage("token")
      const response = await HTTP("get", url, null, {
        Authorization: token,
      }); 
      setStorage("userDateTimeFormat",JSON.stringify(response?.data?.data));
      resolve(response?.data?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export const DateTimeFormatUtils = {
  verboseDateFormat: () =>
    JSON.parse(getStorage("userDateTimeFormat"))?.verboseDateFormat ??
    "MMMM DD, YYYY",
  fullDateFormat: () =>
    JSON.parse(getStorage("userDateTimeFormat"))?.fullDateFormat ?? "MM/DD/YY",
  abbreviatedDateFormat: () =>
    JSON.parse(getStorage("userDateTimeFormat"))?.abbreviatedDateFormat ??
    "MM/DD",
  timeFormat: () =>
    JSON.parse(getStorage("userDateTimeFormat"))?.timeFormat ?? "hh:mm A",
  verboseDateTimeFormat: () =>
    `${DateTimeFormatUtils.verboseDateFormat()} ${DateTimeFormatUtils.timeFormat()}` ??
    "MMMM DD, YYYY hh:mm A",
  fullDateTimeFormat: () =>
    `${DateTimeFormatUtils.fullDateFormat()} ${DateTimeFormatUtils.timeFormat()}` ??
    "MM/DD/YY hh:mm A",
  abbreviatedDateTimeFormat: () =>
    `${DateTimeFormatUtils.abbreviatedDateFormat()} ${DateTimeFormatUtils.timeFormat()}` ??
    "MM/DD hh:mm A",
};

export const defaultDateTimeFormat = {
  verbose: "MMMM DD, YYYY",
  full: "MM/DD/YY",
  abbreviated: "MM/DD",
  time: "hh:mm A",
};

export const getFullYearDateFormat = (format) => {
  // Replace any occurrence of 'Y', 'YY', or 'YYY' with 'YYYY'
  return format.replace(/Y+/g, 'YYYY');
}

export const userTimeZone = () => getStorage("timeZone") ?? moment.tz.guess();

export const dateTimeFormatOptions = () => ({
  VERBOSE: [
    { value: "MMMM D, YYYY", label: moment().tz(userTimeZone()).format("MMMM D, YYYY") },
    { value: "D MMMM YYYY", label: moment().tz(userTimeZone()).format("D MMMM YYYY") },
    { value: "MMMM DD, YYYY", label: moment().tz(userTimeZone()).format("MMMM DD, YYYY") },
  ],
  FULL_DATE: [
    { value: "DD/MM/YY", label: moment().tz(userTimeZone()).format("DD/MM/YY") },
    { value: "DD-MMM-YY", label: moment().tz(userTimeZone()).format("DD-MMM-YY") },
    { value: "DD-MM-YY", label: moment().tz(userTimeZone()).format("DD-MM-YY") },
    {value:"MM/DD/YY",label:moment().tz(userTimeZone()).format("MM/DD/YY")},
    {value:"MMM-DD-YY",label:moment().tz(userTimeZone()).format("MMM-DD-YY")},
    {value:"MM-DD-YY",label:moment().tz(userTimeZone()).format("MM-DD-YY")}
  ],
  ABBREVIATED_DATE: [
    { value: "DD/MM", label: moment().tz(userTimeZone()).format("DD/MM") },
    { value: "DD-MMM", label: moment().tz(userTimeZone()).format("DD-MMM") },
    { value: "DD-MM", label: moment().tz(userTimeZone()).format("DD-MM") },
    {value:"MM/DD",label:moment().tz(userTimeZone()).format("MM/DD")},
    {value:"MMM-DD",label:moment().tz(userTimeZone()).format("MMM-DD")},
    {value:"MM-DD",label:moment().tz(userTimeZone()).format("MM-DD")},
  ],
  TIME_FORMAT: [
    { value: "hh:mm A", label: `${moment().tz(userTimeZone()).format("hh:mm A")} (12H)` },
    { value: "HH:mm", label: `${moment().tz(userTimeZone()).format("HH:mm")} (24H)` },
  ],
});

export const payloadForReports = () => {
  return {
    verboseDate: DateTimeFormatUtils.verboseDateFormat(),
    fullDate: DateTimeFormatUtils.fullDateFormat(),
    abbreviatedDate: DateTimeFormatUtils.abbreviatedDateFormat(),
    timeFormat: DateTimeFormatUtils.timeFormat(),
    verboseDateTime: DateTimeFormatUtils.verboseDateTimeFormat(),
    fullDateTime: DateTimeFormatUtils.fullDateTimeFormat(),
    abbreviatedDateTime: DateTimeFormatUtils.abbreviatedDateTimeFormat(),
  };
};

export const checkDateTimeLocalization = () => JSON.parse(getStorage("userBasicSettings"))?.dateTimeLocalization;

export const isEurope = () => checkAddressLocalization() === true && defaultAddressFormat() === addressFormatEnum.EU;

export const validateCountryCode = (countryCode) => Object.values(addressFormatEnum).includes(countryCode);

export const addressParser = (rawAddress) => {
  if (defaultAddressFormat() === addressFormatEnum.US) {
    return (
      rawAddress?.lastAddress ? rawAddress?.lastAddress
      : (typeof rawAddress?.address === "string" && rawAddress?.address) ? rawAddress?.address :
      rawAddress?.address?.address ? rawAddress?.address?.address :
      (typeof rawAddress === "string" && rawAddress) ? rawAddress : ""
    );
  }
  const address1 =
    rawAddress?.address1 ??
    rawAddress?.address?.address1 ??
    rawAddress?.customerId?.address1 ??
    rawAddress?.customerId?.address?.address1 ??
    rawAddress?.lastAddress?.split(",")?.[0] ??
    rawAddress?.address?.address?.split(",")?.[0] ??
    (typeof rawAddress?.address === "string" &&
      rawAddress?.address?.split(",")?.[0]);
  const countryCode =
    rawAddress?.countryCode ??
    rawAddress?.address?.countryCode ??
    rawAddress?.customerId?.countryCode ??
    rawAddress?.customerId?.address?.countryCode ??
    rawAddress?.lastCompletedStatus?.countryCode;
  const zip_code =
    rawAddress?.zip_code ??
    rawAddress?.address?.zip_code ??
    rawAddress?.customerId?.zip_code ??
    rawAddress?.customerId?.address?.zip_code ??
    rawAddress?.lastCompletedStatus?.zip_code;
  const city =
    rawAddress?.city ??
    rawAddress?.address?.city ??
    rawAddress?.customerId?.city ??
    rawAddress?.customerId?.address?.city ??
    rawAddress?.lastCompletedStatus?.city;
  let country =
    rawAddress?.country ??
    rawAddress?.address?.country ??
    rawAddress?.customerId?.country ??
    rawAddress?.customerId?.address?.country ??
    rawAddress?.lastCompletedStatus?.country;

  if (country) {
    const fullConuntryName = countriesList.find(
      (singleCountry) => singleCountry.code === country
    )?.name;
    country = fullConuntryName ?? country;
  }

  const addressPayload = {
    address1,
    countryCode,
    zip_code,
    city,
    country,
  };

  const parsedAddress = parsedAddressIntoLocality(addressPayload);
  if (!parsedAddress || parsedAddress?.trim() === "") {
    const unParsedAddress =
      rawAddress?.lastAddress ? rawAddress?.lastAddress :
      (typeof rawAddress?.address === "string" && rawAddress?.address) ? rawAddress?.address :
      rawAddress?.address?.address ? rawAddress?.address?.address :
      (typeof rawAddress === "string" && rawAddress) ? rawAddress :null;
      return unParsedAddress ?? "";
    
  }
  return parsedAddress;
};

export const addressSplitterForSetting = (combinedAddress) => {
  if (defaultAddressFormat() === addressFormatEnum.US) {
    return combinedAddress;
  } else {
    const regex = /,\s?/;

    const chunkAddresses = combinedAddress?.split(regex);
    const streetAddress = chunkAddresses?.[0];
    const city = chunkAddresses?.[1];
    const stateZip = chunkAddresses?.[2];
    const country = chunkAddresses?.[3];

    let stateName, zipCode;
    if (/\d/.test(stateZip)) {
      const splittedStateZip= stateZip?.trim()?.split(" ")
      if(checkIfNumber(splittedStateZip[splittedStateZip?.length-1])) {
        zipCode= splittedStateZip[splittedStateZip?.length-1]
        stateName= stateZip?.split(zipCode)?.[0]
      }else if(checkIfNumber(splittedStateZip[0])){
        zipCode= splittedStateZip[0]
        stateName= stateZip?.split(zipCode)?.[1]
      }
    } else {
      stateName = stateZip;
    }

    const rawAddress = {
      address1: streetAddress,
      countryCode: stateName,
      zip_code: zipCode,
      city: city,
      country: country,
    };
    const parsedAddress = parsedAddressIntoLocality(rawAddress);
    if (!parsedAddress || parsedAddress?.trim() === "") {
      return combinedAddress ?? "";
    }
    return parsedAddress;
  }
};

export const checkIfNumber = (stringValue) => Number(stringValue) || false 

export const splitStreetNameNumber = (combinedStreetNameNumber) => {
  if (defaultAddressFormat() === addressFormatEnum.EU) {
    if (
      /\d/.test(combinedStreetNameNumber) &&
      isNaN(combinedStreetNameNumber?.at(-1))
    ) {
      combinedStreetNameNumber = combinedStreetNameNumber?.split(",")?.[0];
      const streetNumber = combinedStreetNameNumber?.trim()?.split(" ")?.[0];
      const streetName = combinedStreetNameNumber
        ?.trim()
        ?.split(streetNumber)?.[1];
      const parsedAddress = `${streetName ?? ""} ${streetNumber ?? ""}`;
      return parsedAddress ?? combinedStreetNameNumber;
    } else {
      const splittedStreet = combinedStreetNameNumber?.split(" ");
      if (checkIfNumber(splittedStreet?.[0])) {
        const streetNumber = splittedStreet?.[0];
        const streetName = combinedStreetNameNumber
          ?.trim()
          ?.split(splittedStreet?.[0])?.[1];
        const parsedAddress = `${streetName ?? ""} ${streetNumber ?? ""}`;
        return parsedAddress ?? combinedStreetNameNumber;
      } else {
        return combinedStreetNameNumber;
      }
    }
  } else {
    return combinedStreetNameNumber;
  }
};

export const addressFormatOptions = [
  { value: "us", label: "1210 Corbin Street, Elizabeth, New Jersey 07201, US (US Format)" },
  { value: "eu", label: "Corbin Street 1210 07201 Elizabeth, US (EU Format)" },
];

export const addressTerminologyOptions = [
  { value: "zipCode", label: "Zip Code" },
  { value: "postalCode", label: "Postal Code" },
];

export const getAddressTerminology = () =>
  addressTerminologyOptions.find(
    (singleOption) => singleOption.value === addressTerminology()
  )?.label;


export const addressTerminology = () =>
  JSON.parse(getStorage("userAddressFormat") ?? null)?.addressTerminology ??
  "zipCode";

export const parsedAddressIntoLocality = (rawAddress) => {
  try {
    const userAddressFormat =
      JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ??
      addressFormatEnum.US;
    if (userAddressFormat === addressFormatEnum.US) {
      return rawAddress?.address;
    }
    let streetNameNumber;
    if (/\d/.test(rawAddress?.address1) && isNaN(rawAddress?.address1?.at(-1))) {
      const streetNumber = rawAddress?.address1?.trim()?.split(" ")?.[0];
      const streetName = rawAddress?.address1?.trim()?.split(streetNumber)?.[1];
      streetNameNumber = `${streetName} ${streetNumber},`;
    } else {
      const splittedStreet = rawAddress?.address1?.split(" ")
      if (checkIfNumber(splittedStreet?.[0])) {
        const streetNumber = splittedStreet?.[0];
        const streetName = rawAddress?.address1?.trim()?.split(splittedStreet?.[0])?.[1];
        streetNameNumber = `${streetName} ${streetNumber},`;
      } else {
        streetNameNumber = rawAddress?.address1 + ",";
      }
    }

    const countryCode = rawAddress?.countryCode
      ? `${rawAddress?.countryCode}-`
      : "";

    const postalCode = rawAddress?.zip_code ?? "";
    const cityName = rawAddress?.city ? rawAddress?.city + "," : "";
    const countryName = rawAddress?.country ?? "";
    const combinedAddress = `${streetNameNumber} ${countryCode}${postalCode} ${cityName} ${countryName}`.replace(
      /,\s*$/,
      ""
    );

    if (combinedAddress == "undefined" && rawAddress?.address && typeof rawAddress?.address == "string") {
      const streetNameNo = splitStreetNameNumber(rawAddress?.address?.split(",")?.[0])
      return rawAddress?.address?.split(rawAddress?.address?.split(",")?.[0])?.[1] 
      ? streetNameNo +
        rawAddress?.address?.split(rawAddress?.address?.split(",")?.[0])?.[1]
        : streetNameNo
    }

    return combinedAddress
  } catch (e) {
    console.error("parsedAddressIntoLocality Error", e)
    return null
  }

};

export const addressFormatEnum = {
  US: "us",
  EU: "eu",
};

export const changePropertyName = (array) => {
  const keys = Object.keys(array[0])
  let arr = []
  array.map((data) => {
    const obj = {}
    obj.label = data[keys[0]] + ' (' + data[keys[1]] + ')'
    obj.value = data[keys[1]]
    return arr.push(obj)
  })
  return arr;
}

export const addressTerminologyEnum = {
  ZIP_CODE: "zipCode",
  POSTAL_CODE: "postalCode",
  ZIP: "Zip",
  POSTAL: "Postal Code",
};

export const addressTerminologyMessage = {
  ZIP: "Enter Zip",
  POSTAL: "Enter Postal Code",
};

export const addressTerminologyErrorMessage = {
  ZIP: "Zip code is Required.",
  POSTAL: "Postal code is Required.",
};

export const getAddressTerminologyMessage = () => {
  if (addressTerminology() === addressTerminologyEnum.POSTAL_CODE) {
    return addressTerminologyMessage.POSTAL;
  } else {
    return addressTerminologyMessage.ZIP;
  }
};

export const getAddressTerminologyErrorMessage = () => {
  if (addressTerminology() === addressTerminologyEnum.POSTAL_CODE) {
    return addressTerminologyErrorMessage.POSTAL;
  } else {
    return addressTerminologyErrorMessage.ZIP;
  }
};

export const getAddressTerminologyFullList = () => {
  if (addressTerminology() === addressTerminologyEnum.POSTAL_CODE) {
    return addressTerminologyEnum.POSTAL;
  } else {
    return addressTerminologyEnum.ZIP;
  }
};

export const getAddressSuggestion = (rawSuggestion) => {
  if (defaultAddressFormat() === addressFormatEnum.EU) {
    if (/\d/.test(rawSuggestion?.formattedSuggestion?.mainText)) {
      if (
        !/^\d+$/.test(
          rawSuggestion?.formattedSuggestion?.mainText?.split(" ")[0]
        )
      )
        return rawSuggestion?.description;
      const splittedStreet = rawSuggestion?.formattedSuggestion?.mainText
        ?.trim()
        ?.split(" ");
      const formattedSuggestion = `${splittedStreet[1]} ${splittedStreet[0]}, ${rawSuggestion?.formattedSuggestion?.secondaryText}`;
      return formattedSuggestion ?? rawSuggestion.description;
    } else {
      return rawSuggestion.description;
    }
  } else {
    return rawSuggestion.description;
  }
};

export const googleAddressParser = (rawLocation) => {
  if (defaultAddressFormat() === addressFormatEnum.US) {
    return rawLocation.formatted_address;
  }
  const streetNumber =
    rawLocation?.find((component) =>
      component?.types?.includes("street_number")
    )?.short_name ?? "";
  const route =
    rawLocation?.find((component) => component?.types?.includes("route"))
      ?.long_name ?? "";
  const postalCode =
    rawLocation?.find((component) => component?.types?.includes("postal_code"))
      ?.short_name ?? "";
  const city =
    rawLocation?.find((component) => component?.types?.includes("locality"))
      ?.short_name ?? "";
  const country =
    rawLocation?.find((component) => component?.types?.includes("country"))
      ?.short_name ?? "";
  const state =
    rawLocation?.find((component) =>
      component?.types?.includes("administrative_area_level_1")
    )?.short_name ?? "";
  const parsedAddress = parsedAddressIntoLocality({
    address1: streetNumber + " " + route,
    countryCode: state,
    zip_code: postalCode,
    city,
    country,
  });
  return parsedAddress ?? rawLocation.formatted_address;
};

export const googleAddressParserForDoc = (rawLocation) => {
  const streetNumber =
    rawLocation?.find((component) =>
      component?.types?.includes("street_number")
    )?.short_name ?? "";
  const streetName =
    rawLocation?.find((component) => component?.types?.includes("route"))
      ?.long_name ?? "";
  const postalCode =
    rawLocation?.find((component) => component?.types?.includes("postal_code"))
      ?.short_name ?? "";
  const city =
    rawLocation?.find((component) => component?.types?.includes("locality"))
      ?.short_name ?? null;
  const countryName =
    rawLocation?.find((component) => component?.types?.includes("country"))
      ?.short_name ?? "";
  const state =
    rawLocation?.find((component) =>
      component?.types?.includes("administrative_area_level_1")
    )?.short_name ?? "";

  const countryCode = "";
  const cityName = city ? city + "," : "";

  return `${streetName} ${streetNumber} ${countryCode}${postalCode} ${cityName} ${countryName}`.replace(
    /,\s*$/,
    ""
  );
};

export const googleAddressParserForDocUS = (rawLocation) => {
  const streetNumber =
    rawLocation?.find((component) =>
      component?.types?.includes("street_number")
    )?.short_name ?? "";
  const streetName =
    rawLocation?.find((component) => component?.types?.includes("route"))
      ?.long_name ?? "";
  const postalCode =
    rawLocation?.find((component) => component?.types?.includes("postal_code"))
      ?.short_name ?? "";
  const city =
    rawLocation?.find((component) => component?.types?.includes("locality"))
      ?.short_name ?? null;
  const countryName =
    rawLocation?.find((component) => component?.types?.includes("country"))
      ?.short_name ?? "";
  const state =
    rawLocation?.find((component) =>
      component?.types?.includes("administrative_area_level_1")
    )?.short_name ?? "";

  const cityName = city ? city + "," : "";

  return `${streetNumber} ${streetName} ${cityName} ${state} ${postalCode} ${countryName}`.replace(
    /,\s*$/,
    ""
  );
};
export const checkAddressLocalization = () => JSON.parse(getStorage("userBasicSettings"))?.addressLocalization;







export const isSignedURLEnabled = () => {
  const basicSettings = JSON.parse(localStorage.getItem('userBasicSettings'))
  if (basicSettings && basicSettings.isSignedURLEnabled){
    return basicSettings.isSignedURLEnabled
  }
  return false
}

/**
 * @param {FormData} formData 
 * @returns {{url: String, obj: Object}}
 */
export const getUrlBodyForNewEmailDocOfLoad = (formData) => {
  try{
    let obj = {};
    const basicSettings = JSON.parse(getStorage("newDocAPIFlags"))
    if (!basicSettings?.isNewEmailDocOfLoad) {
      return [null, null]
    }
    obj.body = {};
    //converting form-data to JSON obj
    for(let [key, value] of formData){
      obj.body[key] = value;
    }
    obj.url = config.newDocUrl;
    return [obj.body, obj.url];
  }catch(err){
    return [null, null];
  }
}

export const fileNameTypeFromUrl = (url) => {
  try {
    let fileName = ""
    let fileType = ""
    if(url){
      fileName = url.split(/[?#]/)[0].split("/").pop()
      fileType = fileName && fileName.split(".").pop()?.toLowerCase();
    }
    return [fileName, fileType]
  } catch (err) {
    console.log("Error Invalid Url")
    return [url, ""]
  }
}  

// ****** Dispatch Filter End
export const getAccountCurrencies = () => {
  return new Promise((resolve, reject) => {
    const url = "account-currencies";
    const token = getStorage("token");
    HTTP("get", url, null, {Authorization: token})
    .then((result) => {
      setStorage("accountCurrencies", JSON.stringify(result.data.data));
      resolve(result?.data?.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
};

export const getCurrencies = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = "currency";
      const token = getStorage("token");

      const response = await HTTP("get", url, null, {Authorization: token});
      resolve(response?.data?.data);
    } catch(e) {
      console.error(e);
      reject(e);
    }
  });
}

export const getConfigForGlobalFlag = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = "user/config";
      const token = getStorage("token");
      if(token){
        const response = await HTTP("get", url, null, {Authorization: token});
        setStorage("flagConfig", JSON.stringify(response?.data?.data));
        resolve(response?.data?.data);
      }
      
    } catch(e) {
      console.error(e);
      reject(e);
    }
  });
}

export function getAllExchangeRate(payload) {
  const url = `currency/exchange-rate?${objToQueryParams(payload)}`;

  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    }).then(response => {
      setStorage("exchangeRates", JSON.stringify(response.data.data));
      resolve(response?.data?.data);
    }).catch(error => {
      console.error(error);
      reject(error);
    });
  });
}

export function createExchangeRate(payload) {
  const url = `currency/exchange-rate`;

  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      'authorization': getStorage('token'),
    }).then(response => {
      resolve(response?.data?.data);
    }).catch(error => {
      console.error(error);
      reject(error);
    });
  });
}

export const getExchangeRateHistory = (payload) => {
  const url = `currency/exchange-rate-history-by-id?${objToQueryParams(payload)}`;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    }).then(response => {
      resolve(response?.data?.data);
    }).catch(error => {
      console.error(error);
      reject(error);
    });
  });
}

export const getTotalExchangeRateHistory = (payload) => {
  const url = `currency/total-exchange-rate-history-size?${objToQueryParams(payload)}`;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    }).then(response => {
      resolve(response?.data?.data);
    }).catch(error => {
      console.error(error);
      reject(error);
    });
  });
}

export function getExchangeRatesFromStorage() {
  return JSON.parse(getStorage("exchangeRates"));
}

export const getDriverCurrency = (load, driver, branch) => {
  try {
    const branchCurrencyMap = driver?.invoiceCurrencyWithBranch;
    if(branch?.value) {
      let branchFind = branchCurrencyMap?.find((e) => e.branch === branch?.value);

      if(branchFind) {
        return branchFind.currency;
      }
    } else {
      const branch = load?.terminal;
      const branchId = branch?._id ?? branch;

      let branchFind = branchCurrencyMap?.find((e) => e.branch === branchId);

      if(branchFind) {
        return branchFind.currency;
      }
    }

    if(driver?.invoiceCurrencyWithCarrier) {
      return driver?.invoiceCurrencyWithCarrier;
    }

    return Currency.default(); 
  } catch(e) {
    console.error(e);
    return Currency.default();
  }
}

export const getCustomerCurrency = (customer, branch) => {
  try {
    if(!customer && !branch) {
      return null;
    }
  
    if(!branch && customer?.invoiceCurrencyWithCarrier) {
      return customer?.invoiceCurrencyWithCarrier;
    }
    
    if(!branch && !customer?.invoiceCurrencyWithCarrier) {
      return null;
    }
  
    // customer + branch
    const customerBranchMap = customer?.invoiceCurrencyWithBranch?.find((e) => {
      return e?.branch?._id === branch?._id;
    });
    if(customerBranchMap) { 
      return customerBranchMap?.currency 
    };
  
    // customer default
    if(customer?.invoiceCurrencyWithCarrier) {
      return customer?.invoiceCurrencyWithCarrier;
    }
  
    // branch default
    if(branch?.currency) {
      return branch?.currency;
    }
  
    // carrier default 
    if(defaultCarrierCurrency()) {
      return defaultCarrierCurrency();
    }
    
    return null;
  } catch(e) {
    console.error(e);
    return null;
  }
}

export const exchangeCurrencyByCurrencyCode = ({targetCurrencyCode, baseCurrencyCode, baseCurrencyValue, exchangeDate}) => {
  return new Promise(async (resolve) => {
    try {
      if(baseCurrencyCode === targetCurrencyCode) {
        return resolve([null, {
          baseCurrencyCode,
          baseCurrencyValue,
          targetCurrencyValue: baseCurrencyValue,
          targetCurrencyCode,
          exchangeDate,
          exchangeRate: 1,
        }]);
      }

      const response = await HTTP(
        'GET',
        `currency/convert-by-currency-code?baseCurrencyCode=${baseCurrencyCode}&targetCurrencyCode=${targetCurrencyCode}&exchangeDate=${exchangeDate}&baseCurrencyValue=${baseCurrencyValue}`,
        null,
        {
          'authorization': getStorage('token'),
        }
      );

      if(response.data?.data) {
        return resolve([null, response.data?.data]);
      }

      return resolve(['Could not find exchange rate', null]);
    } catch(e) {
      console.error(e);
      return resolve([e, null]);
    }
  })
}


/**
 *  
 * @param {conversions[].baseCurrencyCode} 
 * @param {conversions[].targetCurrencyCode}
 * @param {conversions[].baseCurrencyValue}
 * @param {conversions[].label}  
 * @param {conversions[].exchangeDate}
 * 
 * @returns {Promise<conversions[].baseCurrencyCode>} 
 * @returns {Promise<conversions[].targetCurrencyCode>}
 * @returns {Promise<conversions[].baseCurrencyValue>}
 * @returns {Promise<conversions[].targetValue>}
 * @returns {Promise<conversions[].label}> 
 * @returns {Promise<conversions[].exchangeDate>}
 * @returns {Promise<conversions[].exchangeRate>}
 */
export const getBulkExchangeRates = async ({conversions}) => {
  try {
    const promiseResponses = await Promise.allSettled(conversions.map(((conversion) => {
      return exchangeCurrencyByCurrencyCode(conversion);
    })));

    promiseResponses.forEach((res, i) => {
      const [err, data] = res?.value;
      if(!err) {
        conversions[i] = {...conversions[i], ...data, label: `${conversions[i].label}${data?.targetCurrencyValue?.toCurrency(conversions[i]?.targetCurrency)} Rate: ${data?.exchangeRate} Conversion Date: ${moment(data?.exchangeDate).tz(getStorage("timeZone")).format("dddd, " + DateTimeFormatUtils.verboseDateTimeFormat())}`}
      } else {
        conversions[i] = { ...conversions[i], exchangeRate: 1, targetCurrencyValue: conversions[i].baseCurrencyValue, label: `${conversions[i].label}${conversions[i]?.baseCurrencyValue?.toCurrency(conversions[i]?.targetCurrency)} Exchange Rate not found` }
      }
    });
    return [null, conversions];
  } catch(e) {
    console.error(e);
    return [e, null];
  }
}

export const isCustomerAccount = () => {
  const loggedInUser = JSON.parse(getStorage("loggedInUser"));

  const isFleetManagerOfCustomer = loggedInUser?.fleetManager?.isCustomer;
  const isCustomer = ["customer", "fleetcustomer"].includes(loggedInUser?.role);

  return isFleetManagerOfCustomer || isCustomer;
}

export const getCurrenciesForSelect = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = "currency";
      const token = getStorage("token");

      const response = await HTTP("get", url, null, {Authorization: token});

      const options = [];
      response?.data?.data?.forEach((e) => {
        options.push({
          label: `${e?.currencyCode} (${e?.symbol})`,
          value: e?._id,
        });
      });

      resolve(options);
    } catch(e) {
      console.error(e);
      reject(e);
    }
  });
}

const routesList = ["dispatcher", "customer_service_load_info","customer_service_billing","customer_service_tendered_loads",
"other_profile_driver","billing_account_receivable","dispatcher_problem_container", "other_permission", "settings_general_info", "other_profile_customer", "invoice_account_receivable", "dispatcher_connect_notices", "settings_connect_configuration"];
export const allowedRoutes = routesList;
export const checkIsTaxable = () => {
  if (getStorage("userBasicSettings")) {
    const settings = JSON.parse(getStorage("userBasicSettings"));
    return settings?.isCalculateTax || settings?.isVAT ? true: false;
  }
  return false;
}

export const CurrencyDisplayModeEnums = {
  SYMBOL: 'symbol',
  CODE: 'code',
};

export const currencyDisplayOptions = () => [
  {
    label: `Symbol (${Currency.default()?.symbol ?? '$'})`,
    value: CurrencyDisplayModeEnums.SYMBOL,
  },
  {
    label: `Currency Code (${Currency.default()?.currencyCode ?? 'USD'})`,
    value: CurrencyDisplayModeEnums.CODE,
  }
];

export const vedicDisplayOptions = () => [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  }
];

export const separatorOptions = () => [
  {
    label: ',',
    value: ',',
  },
  {
    label: '.',
    value: '.',
  },
];

export const defaultNumberSystem = () => {
  const userSettings = JSON.parse(localStorage.getItem('userBasicSettings'))

  if(userSettings && userSettings?.numberSystem) {
    return userSettings?.numberSystem;
  }

  return {};
}

export const formatNumberToDisplay = (input, emptyIfErased) => {
  if(emptyIfErased && !input?.toString()?.length) {
    return null;
  }

  const numberSystem = defaultNumberSystem();
  const decimalSeparator = numberSystem?.decimal?.trim();

  let regex = /[^-+\d.eE]/g;
  if(decimalSeparator === ',') {
    input = input?.toString().replace('.', ',');
    regex = /[^-+\d,eE]/g;
  } else {
    input = input?.toString().replace(',', '.');
  }
  const output = input?.toString().replace(regex, '');
  return output;
}

export const parseNumber = (input, emptyIfErased) => {
  if(emptyIfErased && !input?.toString()?.length) {
    return null;
  }
  
  let val = parseFloat(input?.toString()?.replace(',', '.'));

  if(isNaN(val) && !isFinite(val)) {
    val = 0;
  }

  return val;
}

export const dateTimeFormatConstants = {
  reportDateFilter: "MM-DD-YYYY",
};

export const isStandardPort = (port, standard_ports) => {
  if(standard_ports){
    let allports=Object.values(standard_ports);
    allports=_.flattenDeep(allports);
    allports=allports.map(pp=>pp.toUpperCase());
    return allports.includes(port.toUpperCase());
  }
  return false;
};

export const checkIsCreditMemo = () => {
  if (getStorage("userBasicSettings")) {
    const settings = JSON.parse(getStorage("userBasicSettings"));
    return settings?.creditFlag ?? false;
  }
  return false;
}
export const checkIsCustomer = () => {
  try {
    const loggedInUser = JSON.parse(getStorage('loggedInUser') || '{}');
    const { role, fleetManager } = loggedInUser;
    return role === 'customer' || (fleetManager && fleetManager?.isCustomer) || false;
  } catch (error) {
    return false;
  }
}

export const checkCombineInvoice = (allData, key, str, data = {}, selectedObj) => {
  if (allData.indexOf("@combinedInvoice@") > -1 && data.invoiceNumber) {
    allData.splice(allData.indexOf("@invoiceNo@"), 1);
    str = str.replace('@invoiceNo@', '');
    str = str.replace('@LoadNo@', '');
    str = str.replace("@combinedInvoice@", data.invoiceNumber || "");
  } else if (key === "@invoiceNo@" || key === "@LoadNo@") {
    str = str.replace('@combinedInvoice@', '');
    if (selectedObj) {
      str = str.replace(key, selectedObj.sub_reference_number || "");
    } else {
      str = str.replace(key, data.reference_number || "");
    }
  } else if (allData.indexOf("@combinedInvoice@") > -1) {
    str = str.replace('@combinedInvoice@', '');
  }
  return str;
};
export const getEmailConfig = async () => {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `email-configuration/getCarrierConfiguration`,
    {},
    {
      authorization: token,
    }
  );
  return response?.data?.data;
}

export const getEmailConfiguration = async (terminal) => {
  let allConfigues = JSON.parse(getStorage("emailConfiguration"));
  let terminaId = terminal?._id || terminal;
  let emailConfig ;
  if(terminaId){
    emailConfig =  allConfigues.find(item => item.terminalId == terminaId)
  }
  if(!emailConfig){
    emailConfig =  allConfigues.find(item => !item.terminalId);
  }

  return emailConfig
}
export const validateMobileFormat = (mobile, format) => {
  
  if(format == 'international'){
    let intRegex = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/;
    if(intRegex.test(mobile)){return true}
    else {
      return false
    }
  }else{
    let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (phoneRegex.test(mobile)) {
          return true;
      } else {
        return false
      }
  }
}
export const cleanMobileNumber = (mobile) => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  if(phoneformat == "international"){
    let cleaned =  String(mobile).trim().replace(/[^0-9 \-\(\)\.+]/g, "");
    return cleaned;
  }else{
    let cleaned = String(mobile).trim().replace(/-|\s/g, "");
    return cleaned;
  }
}


export const perDiemEnums = {
  PER_DIEM_HISTORY: "PERDIEM_HISTORY",
  PER_DIEM_PERMISSION: "per_diem",
  DISPUTED: "Disputed",
  DRAFT: "Draft",
  INVOICED: "Invoiced",
  NOTIFIED: "Notified",
  PER_DIEM_DISPUTE_DOC: "PER_DIEM_DISPUTE_DOC",
  PER_DIEM_NOTIFY_DOC: "PER_DIEM_NOTIFY_DOC",
  PER_DIEM_EMAIL_DOC: "PER_DIEM_EMAIL_DOC",
  PER_DIEM_INVOICE_DOC: "PER_DIEM_INVOICE_DOC",
  PER_DIEM: "Per Diem"
};

export const checkIfPerDiemEnabled = () => {
  const myAppFeatureAccessArray= JSON.parse(getStorage("userBasicSettings"))?.myappFeature
  return myAppFeatureAccessArray?.includes(perDiemEnums.PER_DIEM_HISTORY)
}

export const fullCalendarView = {
  week: {
    dayHeaderFormat: function(date){
      return moment(date?.date).format(`ddd ${DateTimeFormatUtils.abbreviatedDateFormat()}`);
  },
    slotLabelFormat: function(date){
      return moment(date?.date).format(DateTimeFormatUtils.timeFormat());
    },

  },
  day: {
    titleFormat: function(date){
      return moment(date?.date).format(DateTimeFormatUtils.verboseDateFormat());
    },
    slotLabelFormat: function(date){
      return moment(date?.date).format(DateTimeFormatUtils.timeFormat());
    },
  },

}
export const hasVATPermissionForUser = () => {
  const loggedInUserData = JSON.parse(getStorage("loggedInUser"));
  if(loggedInUserData?.role === "fleetmanager" && !loggedInUserData?.fleetManager?.isCustomer) {
    return loggedInUserData?.permissions.includes("sale_tax_exempt_applicable")
  }
  return true
}

export const eligibleFile = [
  "png",
  "jpg",
  "pdf",
  "jpeg",
  "csv",
  "xls",
  "eml",
  "xlsx",
];

export const isShiposCarrier = () => {
  let result = false
  const carrierDetail = JSON.parse(getStorage("carrierDetail") ?? '{}')
  if (carrierDetail?.isShiposCarrier) result = true
  return result
}

export const isNewSettingsEnabled = () => {
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings")) ?? "{}"
  return Boolean(userBasicSettings?.showNewSettings)
}

export const isNewChargeCode = () => {
  let result = false
  const carrierDetail = JSON.parse(getStorage("carrierDetail") ?? '{}')
  if (carrierDetail?.isShiposCarrier) return result = true
  const userSetting = JSON.parse(getStorage("userBasicSettings") ?? "{}")
  if(userSetting?.isNewChargeCode) return result = true
  return result
}

export const getEmailDataForPerDiem = (customerData, carrierData) => {
  let email = [];
  let emailCc = [];
  if (customerData?.billingEmail || customerData?.email) {
    let billingEmailArray = [];
    let customerEmailArray = [];
    if (
      customerData?.billingEmail &&
      customerData?.billingEmail?.trim() !== ""
    ) {
      billingEmailArray = customerData?.billingEmail?.split(",");
    }

    if (customerData?.email && customerData?.email?.trim() !== "") {
      customerEmailArray = customerData?.email?.split(",");
    }

    if (
      customerData?.billingEmail &&
      customerData?.billingEmail?.trim() !== ""
    ) {
      email = billingEmailArray;
    } else {
      email = customerEmailArray;
    }
  }

  if (customerData?.isEmailNeedToSend) {
    let billingEmailArray = [];
    let extraEmailArray = [];
    if (carrierData?.billingEmail && carrierData?.billingEmail?.trim() !== "") {
      billingEmailArray = carrierData?.billingEmail?.split(",");
    }

    if (customerData?.extraEmail && customerData?.extraEmail?.trim() !== "") {
      extraEmailArray = customerData?.extraEmail?.split(",");
    }

    emailCc = billingEmailArray + extraEmailArray;
  } else {
    email = [];
    emailCc = [];
  }
  return { email, emailCc };
};
export const convertMetersToCarrierDefault = (distanceInMeters, isDefaultDistanceMeasureString = true) => {
  const distanceUnit = JSON.parse(getStorage("measureUnits"))?.distanceUnit ?? 'km';

  switch (distanceUnit) {
    case 'km':
      return `${((distanceInMeters ?? 0) * 0.001).print()} ${isDefaultDistanceMeasureString ? 'KM' : distanceUnit}`;
    default:
      return `${((distanceInMeters ?? 0) * 0.000621371).print()} ${isDefaultDistanceMeasureString ? 'Miles' : distanceUnit}`;
  }
}

export const convertMilesToCarrierDefault = (distanceInMiles, isDefaultDistanceMeasureString = true) => {
  const distanceUnit = JSON.parse(getStorage("measureUnits"))?.distanceUnit ?? 'km';

  switch (distanceUnit) {
    case 'km':
      return `${((distanceInMiles ?? 0) * 1.609344).print()} ${isDefaultDistanceMeasureString ? 'KM' : distanceUnit}`;
    default:
      return `${(distanceInMiles ?? 0).print()} ${isDefaultDistanceMeasureString ? 'Miles' : distanceUnit}`;
  }
}

export const filterPerDiemTab = (perDiemTab, activePortMarkets) => {
  const filteredPerDiemTab = [];
  const userSelectedPortMarket = JSON.parse(getStorage("carrierDetail"))
    ?.carrier?.portMarket;
  perDiemTab.forEach((singleTab) => {
    if (singleTab?.title === "Audit") {
      filteredPerDiemTab.push(singleTab);
    }
    if (
      (["Empty Returns Locations","Terminal Screenshots"].includes(singleTab?.title)) && userSelectedPortMarket?.length>0 &&
      activePortMarkets.find(mm=>userSelectedPortMarket?.includes(mm.value))
    ) {
      filteredPerDiemTab.push(singleTab);
    }else if(singleTab?.title=="Empties Available Slots" && ["LALB", "NJNY"].find(mm=>userSelectedPortMarket?.includes(mm))){
      filteredPerDiemTab.push(singleTab);
    }
  });
  return filteredPerDiemTab;
};

export function downloadImage(imageUrl, imageLabel, imageDate, setDownloading) {
  let headers = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  setDownloading(true);
  if (!imageUrl) {
    toastr.show("There is no image for this port", "error");
    setDownloading(false);
    return;
  }
  axios({
    url: imageUrl,
    responseType: "blob",
    headers,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${imageLabel}-${imageDate}.jpg`);
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      setDownloading(false);
    });
}

export function handleRoutingTabChange(loadDriverOrder, propDriverOrder) {
  let legIndex
  let hasAddress = true
  let driverOrder
  if (loadDriverOrder.length == 0) {
    driverOrder = _.cloneDeep(propDriverOrder)
  } else {
    driverOrder = _.cloneDeep(loadDriverOrder)
  }
  let validLeg = driverOrder.filter((order) =>
    ["CHASSISPICK", "CHASSISTERMINATION"].includes(order.type)
  )
  validLeg.forEach((leg, index) => {
    if (!leg.hasOwnProperty("customerId") || leg.customerId === null) {
      hasAddress = false
      legIndex = index
    }
  })
  if (!hasAddress) {
    toastr.show(`A valid location is required here. ${validLeg[legIndex].type}`, "error")
  } 
  return hasAddress
}

export const formatNumberShorthands = (number) => {
  if (number >= 1000) {
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const suffixIndex = Math.floor(Math.log10(number) / 3);
    const formattedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
    return `${formattedNumber}${suffixes[suffixIndex]}`;
  } else {
    return number.toString();
  }
}
export function isPubSubChatEnabled() {
  const settings = JSON.parse(localStorage.getItem('userBasicSettings'))
  if(settings && settings.isPubSubChatEnabled){
    return settings.isPubSubChatEnabled
  }
  return false
}

export function downloadSampleCSV (fileName,Header){
  if(!JSON.parse(getStorage("userBasicSettings"))?.isScaleEnabled) delete Header.Scale;
  
  let csv =[Object.keys(Header),Object.values(Header)]
  downloadCSV(csv.join("\n"), `${new Date().getTime()}-${fileName}.csv`);
}
export const isNewSettlementDesignEnabled = () =>{
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  if(setting && setting.isNewSettlementDesignEnabled){
    return setting.isNewSettlementDesignEnabled
  }else return false
}
export const Hotjar = (name) => {
  window.hj('event', `${name}`);
}
export const checkIfEnableDrawStartingPoint = () => {
  const settings = JSON.parse(localStorage.getItem("userBasicSettings"));
  if (settings?.enableDrawStartingPoint) {
    return settings?.enableDrawStartingPoint;
  }
  return false;
};

//for  track os container status  no to override
export const isDenySystemToOverrideStatuses = () => {
  const settings = JSON.parse(localStorage.getItem("userBasicSettings"));
  return settings?.isDenySystemToOverrideStatuses || false;
};
export const isMarginEnabled = () => {
  const settings = JSON.parse(localStorage.getItem('userBasicSettings'))
  if(settings && settings.isMarginEnabled){
    return settings.isMarginEnabled;
  }
  return false;
}

export const isTripEnabled = () => {
    const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? "{}")
    if(userBasicSettings?.isFreeFlow || userBasicSettings?.isMultiContainerEnabled){
      return true
    } 
    return false
}

export const AmplitudeInit = () => {
    const loggedInUser = JSON.parse(getStorage("loggedInUser"));
    const carrierDetail = JSON.parse(getStorage("carrierDetail"));
    const version = config.version;
    const loggedInUserName =  `${loggedInUser?.name || ''} ${loggedInUser?.lastName || ''}`;
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null , {
      defaultTracking: {
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      }});
      const identifyObj = new amplitude.Identify();
      identifyObj.set('carrierId', carrierDetail?._id)
      identifyObj.set('carrierEmail',carrierDetail?.email )
      identifyObj.set("role",loggedInUser?.role)
      identifyObj.set("isCustomer", loggedInUser?.fleetManager?.isCustomer || false)
      identifyObj.set("loggedInUserId", loggedInUser?._id || "")
      identifyObj.set("loggedInUserName", loggedInUserName?.trim() || "")
      identifyObj.set("loggedInUserEmail", loggedInUser.email || "")
      identifyObj.set("version",version)

      amplitude.identify(identifyObj, {
        user_id: loggedInUser.email
      })
}

export const amplitudeTrack = (trackEventName,eventProperties) => {
  amplitude.track(`${trackEventName}`,eventProperties);
}

export const isNewAccountReceivable = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isNewAccountReceivable ?? false;
  if (loggedInUser && loggedInUser.role === "carrier" && flag) {
    return true;
  } else if (flag && loggedInUser && loggedInUser.role === "fleetmanager") {
    return true;
  } 
  else {
    return false;
  }
}

export const isNewInvoice = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.billingConfiguration?.isBillingEnabled ?? false
  if (loggedInUser && loggedInUser.role === "carrier" && flag) {
    return true;
  } else if (flag && loggedInUser && loggedInUser.role === "fleetmanager") {
    return true;
  } 
  else {
    return false;
  }
}

export function getDefaultPolygon(lng, lat) {
  const centerPoint = turf.point([lng, lat]);
  const radius = 0.1; // Radius in kilometers (100 meters = 0.1 kilometers)
  const options = { steps: 64, units: 'kilometers' }; // Increase the "steps" for a smoother circle

  const circle = turf.circle(centerPoint, radius, options);
  const coordinates = circle.geometry.coordinates[0]; // Extract the coordinates of the circular polygon

  return [coordinates];
}

export const isNewBillingEnabled = () => {
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  if (setting && setting?.billingConfiguration?.isBillingEnabled) {
    return setting?.billingConfiguration?.isBillingEnabled
  } else return false
}

export const isMileStoneEnabled = () => {
  const setting =  JSON.parse(localStorage.getItem('userBasicSettings'))
  if (setting) {
    return setting.isMileStoneEnabled ? true : false
  } else return false
}

export const isRoutingService = () => {
  const setting =  JSON.parse(localStorage.getItem('userBasicSettings'))
  if (setting) {
    return setting.isRoutingService ? true : false
  } else return false
}

export const isNewEndorsement = () => {
  const setting =  JSON.parse(localStorage.getItem('userBasicSettings'))
  if (setting) {
    return !!(setting.isNewEndorsement)
  } else return false
}

export const isEmbeddedEmailEnabled = (skipCustomer = false) => {
  if(isCustomerLogin() && !skipCustomer) return false
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  if (setting) {
    return setting.isEmbeddedEmailEnabled ? true : false
  } else return false
}

export const isAccountServiceEnabled = () => {
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  if (setting?.billingConfiguration?.isBillingEnabled && setting?.isAccountServiceEnabled) {
    return true
  } else return false
}

export const isManualTax = () => {
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  if (setting && setting?.isCalculateTax && setting?.isManualTaxEnabled) {
    return setting?.isCalculateTax
  } else return false
}

export const isCustomerLogin = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  if (!loggedInUser) return false; // User is not logged in
  const { role, fleetManager } = loggedInUser;
  if (role === "customer") return true; // Customer login
  if (role === "fleetmanager" && fleetManager) return !!fleetManager?.isCustomer; // Fleet manager of Customer 
  return false; // Other roles
};
export const getAccountingServiceInfo = () => {
  return new Promise(async (resolve, reject) => {
    const token = getStorage("token");
    let url = "user/get-accounting-service-info";
    const carrierSettings = JSON.parse(localStorage.getItem('userBasicSettings'))
    if (isCustomerLogin() || !(carrierSettings?.isAccountServiceEnabled)) return resolve()
    HTTP("get", url, null, {
      Authorization: token,
    }).then((result) => {
      resolve(result?.data?.data);
    })
      .catch((error) => {
        reject(error);
      });
  })
}

export const capitalizeFirstLetterOfEachWord = (str, seprateWith) => {
  str = str.toLowerCase()
  let words
  if (seprateWith === SEPARATE_WITH.SPACE) {
    words = str.split(' ');
  } else if (seprateWith === SEPARATE_WITH.UNDER_SCORE) {
    words = str.split('_');
  }

  let capitalizedWords = words.map(word => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  });

  return capitalizedWords.join(' ');
}
export const getValidDrivers = (driverList,driverValidation,info,driverPreferencesCond)=> {
  let ddrivers = driverList
  let drivers = []
  ddrivers.forEach((d) => (d.disableValue = false));
  if(driverValidation && driverValidation?.length > 0) {
    if(driverValidation.includes('driverPreferences') && (info.overWeight || info.hazmat || info.liquor) && driverPreferencesCond) {
      ddrivers.forEach((d) => {
        if((info.hazmat && !d.driver.hazmat) || 
        (info.overWeight && !d.driver.overweight && !_.intersection(d.driver.owerWeightStates, info.consignee.map((d) => d.state)).length > 0) ||
        (info.liquor && d.driver.liquor === "false")) {
          d.disableValue = true
        }
      });
    }
    if(driverValidation.includes('driverAssignedToTruck')) {
      ddrivers.forEach((d)=> {
        if(!d.driver?.truck) {
          d.disableValue = true
        }
      })
    }
    if(driverValidation.includes('fleetownerAssignedToTruck')) {
      ddrivers.forEach((d)=> {
        if(!d.driver?.truck?.fleetTruckOwner) {
          d.disableValue = true
        }
      })
    }
    drivers=ddrivers;
  } else {
    ddrivers.forEach((d) => d.disableValue = false)
    drivers = ddrivers;
  }
  return drivers
}

export const chargeNameMapper = (charge, carrierChargeCodes) => {
  const chargeName = carrierChargeCodes?.find((_charge) => _charge.value === charge);
  return chargeName?.chargeName || "";
};

export const checkChassisExtraPermission = () => {
  const user = getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser"));
  return user?.permissions?.includes("settings_chassis")
}

export const checkUserPermisison = (permission) => {
  let allow = false;
  const user = getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser"));
  if (user) {
    if (user.role === "carrier") {
      allow = true;
    } else if (user?.permissions) {
      permission.map((x) => {
        if (user?.permissions?.indexOf(x) > -1) {
          return (allow = true);
        }
      });
    }
    return allow;
  }
};

export const isCarrierOrBrokerOrHybrid = () => {
  const userRole = {
    isCarrier: false,
    isHybrid: false,
    isBroker: false,
  };

  const userPermission = isManageFleetOrOutSource();

  if (!userPermission?.isOutSource && userPermission?.isManageFleet) {
    userRole.isCarrier = true;
  }

  if (userPermission?.isOutSource && !userPermission?.isManageFleet) {
    userRole.isBroker = true;
  }

  if (userPermission?.isOutSource && userPermission?.isManageFleet) {
    userRole.isHybrid = true;
  }

  return userRole;
}

export const checkChrge5ReportEnabled = () => {
  const charge5Report= JSON.parse(getStorage("userBasicSettings"))?.charge5Report ?? false
  return charge5Report
}

export const getLoadRefNumberFromCharge = (chargeNumber) => {
  const parts = chargeNumber.split('_');
  if (/^[^a-zA-Z]/.test(parts[parts.length - 1])) parts.pop();
  return parts.join('_')
}


export const convertStatusName = (status) =>{
  if (typeof status !== 'string' || status.length === 0) {
    return status;
  }
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
}

export const getYearDifferenceStatus = (from, to) => {
  const diffInYears = moment(to).diff(from, 'years');
  return diffInYears >= 2
}

export function getDateOfSelectedDay(dayOfWeek, startDate) {
  const today = moment(startDate).tz(userTimeZone()).startOf('day');
  const selectedDay = moment().tz(userTimeZone()).startOf("day").day(dayOfWeek);
  let limit = 1000;
  while (selectedDay.isBefore(today) && limit > 0) {
    selectedDay.add(7, 'days');
    limit--;
  }
  return selectedDay.toISOString();
}

export function getDaysInMonth(month) {
  const firstDayOfMonth = moment({ month: month, day: 1 }).tz(userTimeZone()).startOf('day');
  const firstDayOfNextMonth = moment({ month: month + 1, day: 1 }).tz(userTimeZone()).startOf('day');
  const daysInMonth = firstDayOfNextMonth.diff(firstDayOfMonth, 'days');
  return daysInMonth;
}

export function getUpcomingDateWithMoment(selectedDate) {
  let monthAdded = false
  const currentDate = moment().tz(userTimeZone()).startOf('day');
  const daysInCurrentMonth = getDaysInMonth((currentDate.month()))
  const currentDayDate = currentDate.date()
  if (currentDayDate > selectedDate) {
    currentDate.add(1, 'months');
    monthAdded = true
  }
  if (selectedDate > daysInCurrentMonth) {
    currentDate.add(1, 'months');
    monthAdded = true
  }
  if (monthAdded) {
    const daysInNextMonth = getDaysInMonth((currentDate.month()))
    if (selectedDate > daysInNextMonth) {
      currentDate.add(1, 'months');
    }
  }
  currentDate.date(selectedDate);
  return currentDate.toISOString();
}

const getNextBiWeeklyDate = (date, startDate) => {
  const tz = userTimeZone()
  const EffectiveDate = moment(date).tz(tz).startOf('day')
  const today = moment(startDate).tz(tz).startOf('day')
  let limit = 1000
  while (EffectiveDate.isBefore(today) && limit > 0) {
    EffectiveDate.add(2, "weeks")
    limit--
  }
  return EffectiveDate.toISOString()
}

const getNextCustomDate = (date, interval, currentDate) => {
  const dayToAdd = interval ? interval : 1
  const tz = userTimeZone()
  const EffectiveDate = moment(date).tz(tz).startOf('day')
  const today = moment(currentDate).tz(tz).startOf('day')
  let limit = 1000
  while (today.date() > EffectiveDate.date() && limit > 0) {
    EffectiveDate.add(dayToAdd, "days")
    limit--
  }
  return EffectiveDate.toISOString()
}

const getDateForEveryWeek = (week, repeatCount, startWeekFrom, today) => {
  const tz = userTimeZone()
  const dayOfWeek = startWeekFrom
  const startDate = getDateOfSelectedDay(dayOfWeek, today)
  const start = moment(startDate).tz(tz);
  let current = _.cloneDeep(moment(start));
  while (repeatCount > 1) {
    current.add(week, "weeks");
    repeatCount = repeatCount - 1
    if (getYearDifferenceStatus(start, current)) break
  }
  return moment(current).tz(tz).toISOString()
}

const getDateForMonth = (month, startDate, repeatCount) => {
  const tz = userTimeZone()
  const dayOfMonth = month ? month : 1
  const start = moment(startDate).tz(tz);
  let current = _.cloneDeep(moment(start).startOf("month"));
  if (start.date() > dayOfMonth) {
    current.add(1, "months")
  }
  while (repeatCount > 1) {
    if (dayOfMonth <= current.daysInMonth()) {
      const _current = moment(current)
      const theDate = _current.date(dayOfMonth);
      current = moment(theDate).tz(tz)
      repeatCount = repeatCount - 1
      if (getYearDifferenceStatus(start, current)) break
    }
    current.add(1, "months");
  }
  return current?.toISOString()
}

const getBiMonthlyDate = (startDate, repeatCount) => {
  const tz = userTimeZone()
  const currentDate = moment(startDate).tz(tz).startOf('day');
  const firstDateOfMonth = moment(currentDate).date(1);
  const sixteenthDateOfMonth = moment(currentDate).date(16);
  const dayNumberOfCurrentDate = currentDate.date()
  if (dayNumberOfCurrentDate > 1) {
    firstDateOfMonth.add(1, 'month');
    if (dayNumberOfCurrentDate > 16) {
      sixteenthDateOfMonth.add(1, 'month');
    }
  }
  let endDate = _.cloneDeep(currentDate)
  for (let i = 1; i <= repeatCount; i++) {
    const isOdd = i % 2 !== 0

    if (dayNumberOfCurrentDate < 16) {
      if (dayNumberOfCurrentDate === 1) {
        // if current date is 1
        endDate = isOdd ? _.cloneDeep(moment(firstDateOfMonth).tz(tz)) : _.cloneDeep(moment(sixteenthDateOfMonth).tz(tz))

        if (isOdd) {
          firstDateOfMonth.add(1, 'month');
        } else {
          sixteenthDateOfMonth.add(1, 'month');
        }
        if (getYearDifferenceStatus(currentDate, endDate)) break

      } else {
        // if current date is in range of 2 to 15
        endDate = isOdd ? _.cloneDeep(moment(sixteenthDateOfMonth).tz(tz)) : _.cloneDeep(moment(firstDateOfMonth).tz(tz))

        if (isOdd) {
          sixteenthDateOfMonth.add(1, 'month');
        } else {
          firstDateOfMonth.add(1, 'month');
        }
        if (getYearDifferenceStatus(currentDate, endDate)) break
      }
    } else {
      if (dayNumberOfCurrentDate === 16) {
        // if current date is 16
        endDate = isOdd ? _.cloneDeep(moment(sixteenthDateOfMonth).tz(tz)) : _.cloneDeep(moment(firstDateOfMonth).tz(tz))

        if (isOdd) {
          sixteenthDateOfMonth.add(1, 'month');
        } else {
          firstDateOfMonth.add(1, 'month');
        }
        if (getYearDifferenceStatus(currentDate, endDate)) break
      } else {
        // if current date is in range of 17 to 31
        endDate = isOdd ? _.cloneDeep(moment(firstDateOfMonth).tz(tz)) : _.cloneDeep(moment(sixteenthDateOfMonth).tz(tz))

        if (isOdd) {
          firstDateOfMonth.add(1, 'month');
        } else {
          sixteenthDateOfMonth.add(1, 'month');
        }
        if (getYearDifferenceStatus(currentDate, endDate)) break
      }
    }
  }
  return endDate?.toISOString()
}

const getWeeklyDate = (settlementPeriod, repeatCount, today) => {
  const tz = userTimeZone()
  const dayOfWeek = settlementPeriod?.weeklySettings?.startWeekFrom
  const startDate = getDateOfSelectedDay(dayOfWeek, today)
  const start = moment(startDate).tz(tz);
  let current = _.cloneDeep(moment(start));
  while (repeatCount > 1) {
    current.add(1, "weeks");
    repeatCount = repeatCount - 1
    if (getYearDifferenceStatus(start, current)) break
  }
  return moment(current).tz(tz).toISOString()
}

const getBiWeeklyDate = (settlementPeriod, repeatCount, today) => {
  const tz = userTimeZone()
  const dayOfWeek = settlementPeriod?.biWeeklySettings?.effectiveDate
  const startDate = getNextBiWeeklyDate(dayOfWeek, today)
  const start = moment(startDate).tz(tz);
  let current = _.cloneDeep(moment(start));
  while (repeatCount > 1) {
    current.add(2, "weeks");
    repeatCount = repeatCount - 1
    if (getYearDifferenceStatus(start, current)) break
  }
  return moment(current).tz(tz).toISOString()
}

const getDailyDate = (startDate, repeatCount) => {
  const tz = userTimeZone()
  const start = moment(startDate).tz(tz).startOf('day');
  let current = _.cloneDeep(moment(start));
  while (repeatCount > 1) {
    current.add(1, "days");
    repeatCount = repeatCount - 1
    if (getYearDifferenceStatus(start, current)) break
  }
  return moment(current).tz(tz).toISOString()
}

const getCustomDate = (settlementPeriod, repeatCount, today) => {
  const tz = userTimeZone()
  const { effectiveDate, interval } = settlementPeriod?.customTypeSettings ?? {}
  const startDate = getNextCustomDate(effectiveDate, interval, today)
  const start = moment(startDate).tz(tz);
  let current = _.cloneDeep(moment(start));
  while (repeatCount > 0) {
    current.add(interval, "days");
    repeatCount = repeatCount - 1
    if (getYearDifferenceStatus(start, current)) break
  }
  return moment(current).tz(tz).toISOString()
}

const getWithoutPeriodDate = (settlementPeriod, repeatCount, today) => {
  const tz = userTimeZone()
  const { day, duration } = settlementPeriod
  const startDate = getDateOfSelectedDay(day, today)
  const start = moment(startDate).tz(tz);
  let current = _.cloneDeep(moment(start));
  while (repeatCount > 1) {
    current.add(duration + 1, "days");
    repeatCount = repeatCount - 1
    if (getYearDifferenceStatus(start, current)) break
  }
  return moment(current).tz(tz).toISOString()
}

export const getDatesAccordingToRule = (startDate, repeatCount, rule, settlementPeriod) => {
  const { type } = settlementPeriod;
  const { week, month, startWeekFrom } = rule;
  switch (type) {
    case "weekly":
      return getWeeklyDate(settlementPeriod, repeatCount, startDate);
    case "biweekly":
      return getBiWeeklyDate(settlementPeriod, repeatCount, startDate);
    case "daily":
      return getDailyDate(startDate, repeatCount);
    case "custom":
      return getCustomDate(settlementPeriod, repeatCount, startDate);
    default:
      if (week) {
        return getDateForEveryWeek(week, repeatCount, startWeekFrom, startDate);
      } else if (month || type === "monthly") {
        return getDateForMonth(month, startDate, repeatCount);
      } else if (type === "bimonthly") {
        return getBiMonthlyDate(startDate, repeatCount);
      } else if (!settlementPeriod?._id && Object.keys(settlementPeriod)?.length) {
        return getWithoutPeriodDate(settlementPeriod, repeatCount, startDate);
      } else {
        return null
      }
  }
};

export const getCarrierDistanceSetting = () => {
  return JSON.parse(getStorage("carrierDetail"))?.carrier?.distanceMeasure ?? "ml";
};
export const getTaxNote = (loadInfo) => {
  let taxNote = loadInfo?.taxDetail?.taxNote;
  let totalAmount = Number(loadInfo?.totalAmount)?.toFixed(2);
  if (taxNote?.includes('[currency-code]')) {
      taxNote = taxNote?.replaceAll("[currency-code]", taxNote?.includes('[document-amount]') ? '' : "USD");
  }
  if (taxNote?.includes('[document-amount]')) {
      taxNote = taxNote?.replaceAll("[document-amount]", (totalAmount ?? 0).toCurrency(loadInfo?.billToDetail?.currency));
  }
  return taxNote;
}


export const isISOTypeEnabled = () => {
  const settings = JSON.parse(localStorage.getItem('userBasicSettings'))
  if(settings && settings.isISOTypeEnabled){
    return settings.isISOTypeEnabled;
  }
  return false;
}
export const isFleetManagerLogin = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  if (loggedInUser?.role === "fleetmanager" && !loggedInUser?.fleetManager?.isCustomer) {
    return true; // Fleet manager login
  }
  return false; // Other roles or not logged in
};
export const isManageFleetOrOutSource = () => {
  let flags = {
    isOutSource: false, // this flag for ShipOS
    isManageFleet: true, // this flag for DrayOS
  }
  try {
    const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")
    if (flag.hasOwnProperty("isOutSource") || flag.hasOwnProperty("isManageFleet")) {
      flags.isOutSource = flag?.isOutSource ?? false
      flags.isManageFleet = flag?.isManageFleet ?? true;
      if (flag?.isOutSource && flag?.isManageFleet) {
        flags.isHybrid = true;
      }
      flags.isMultiTender = (
        flags.isOutSource || // For Brokers
        flag?.isMultiContainerEnabled || // For CombinedTrip Users
        flag?.isChassisV3 ||
        flag?.isDualTransactionEnabled // For Dual Transaction Users
      ) ?? false
      return flags
    } else {
      flags.isMultiTender = (
        flags.isOutSource || // For Brokers
        flag?.isMultiContainerEnabled || // For CombinedTrip Users
        flag?.isChassisV3 ||
        flag?.isDualTransactionEnabled // For Dual Transaction Users
      ) ?? false
      return flags
    }
  } catch (e) {
    return flags
  }
}

export const isAdminCustomerPortalPermission = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  if (
    loggedInUser?.role === "admin" &&
    loggedInUser?.employeePermission &&
    [adminEmployeePermissions.ACCESS_CUSTOMER_PORTAL]?.includes(
      loggedInUser?.employeePermission?.[0]
    )
  )
    return true;
  else return false;
};

export const isSuperAdminUser = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  
  return Boolean(
    loggedInUser?.role === "admin" &&
    loggedInUser?.employeePermission &&
    [adminEmployeePermissions.SUPER_ADMIN]?.includes(
      loggedInUser?.employeePermission?.[0]
    )
  )
}

export const IsELDEnabled = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = (JSON.parse(getStorage("userBasicSettings") ?? "{}")?.eldConfigs?.isEldEnabled) ?? false;
  if (flag && ["carrier", "fleetmanager"].includes(loggedInUser?.role) && flag && !loggedInUser?.fleetManager?.isCustomer) {
    return true;
  }
  return false;
}

export const isStrictDriverPayTypes = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const flag = (JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isStrictDriverPayTypes || JSON.parse(getStorage("flagConfig") ?? "{}")?.isStrictDriverPayTypes) ?? false;
  if (loggedInUser && loggedInUser.role === "carrier" && flag) {
    return true;
  } else if (flag && loggedInUser && loggedInUser.role === "fleetmanager") {
    return true;
  } 
  else {
    return false;
  }
}


export const isCarrierTariffEnabled = () => {
  const settings = JSON.parse(localStorage.getItem('userBasicSettings') ?? '{}');
  return settings?.tariffConfiguration?.isCarrierTariffEnabled;
}

export const isDriverTariffEnabled = () => {
  const settings = JSON.parse(localStorage.getItem('userBasicSettings') ?? '{}');
  return settings?.tariffConfiguration?.isDriverTariffEnabled;
}
export const isNegativeDriverPayAllowed = () => {
  return JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isNegativeDriverPayAllowed;
}

export const isNegativeChargeAllowed = () => {
  return JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isNegativeChargeAllowed;
}

export const isSupportMovesEnabled = () => {
  return JSON.parse(getStorage("carrierDetail") ?? "{}")?.isSupportMoveEnabled;
}

export const isNewSupportMovesEnabled = () => {
  return JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isNewSupportMoveEnabled ?? false;
}

export const supportMoveEnabled = () => {
  return JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isNewSupportMoveEnabled || JSON.parse(getStorage("carrierDetail") ?? "{}")?.isSupportMoveEnabled;
}
export const getSyncConfigSettings = () => {
  return new Promise(async (resolve, reject) => {
    const token = getStorage("token");
    let url = "connect-config-settings";
    if (isCustomerLogin() || !isConnectedWithOutSource()) return resolve()
    const authorization = { Authorization: getStorage("token") }
    HTTP("get", url, null, authorization).then((result) => {
      setSessionStorage("syncConfigSetting", JSON.stringify(result?.data?.data));
      resolve(result?.data?.data);
    }).catch((error) => {
      console.log("err",error)
      reject(error);
    });
  })
}

export const isConnectConfigEnabled = () => {
  const setting = getSessionStorage("syncConfigSetting", true)
  return setting?.isConnectConfigEnabled ?? false
}

export const getSyncConfig = () => {
  const syncConfigSetting = getSessionStorage("syncConfigSetting", true)
  return syncConfigSetting || {}
}
export const isConnectedWithOutSource = () => {
  let result = false
  const carrierDetail = JSON.parse(getStorage("carrierDetail") ?? '{}')
  const settings = JSON.parse(getStorage("userBasicSettings") ?? "{}")
  if (carrierDetail?.isShiposCarrier) result = true // baby carrier
  if (settings?.isConnectedWithOutSource) result = true // full drayos carrier
  if(isManageFleetOrOutSource()?.isOutSource) result = true // shipos carrier
  return result && settings?.isSyncRequest
}

export const isSyncRequest = () => {
  const settings = JSON.parse(getStorage("userBasicSettings") ?? "{}")
  return settings?.isSyncRequest
}
export const getDriverAndCarrierName = () => {
  return isManageFleetOrOutSource().isManageFleet ? (isManageFleetOrOutSource().isOutSource ? "Vendor / Driver" : "Driver") : "Carrier"
}

export const getVendorPayAndBillsSettings = () => {
  return JSON.parse(getStorage("userBasicSettings") ?? "{}")?.vendorBillConfiguration ?? {};
};

export const isVendorPayAndBillsEnabled = () => {
  return getVendorPayAndBillsSettings()?.isVendorBillsEnabled ?? false;
};


export const enableNewCustomerApprovalStatus = () => {
  const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}");
  return flag?.enableNewCustomerApprovalStatus ?? false;
};

export const isMultiQBEnabled = () => {
  return JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isMultiQBAccount ?? false;
}

export const generateRandomPasswordForFleet = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_+=<>?';
  let password = '';

  while (password.length < 10 || !/[A-Z]/.test(password) || !/\d/.test(password) || !/[^A-Za-z0-9]/.test(password)) {
      password = Array.from({ length: 12 }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
  }

  return password;
}
export const isCustomsNote = () => {
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isCustomsNote ?? false
  if (isFlagOn) {
    return true
  } else {
    return false
  }
}

export const customerApiCacheKeys = () => {
  return {
    getTmsCustomers : 'carrier/getTMSCustomers',
    getTmsCustomers1 : "carrier/getTMSCustomers?skip=0&limit=10",
    getTmsCustomers2 : "carrier/getTMSCustomers?limit=10&customerType=%5B%22caller%22%2C%22ALL%22%5D",
    getTmsCustomers3 : "carrier/getTMSCustomers?limit=10&customerType=%5B%22shipper%22%2C%22containerReturn%22%2C%22ALL%22%5D",
    getTmsCustomers4 : "carrier/getTMSCustomers?limit=10&customerType=%5B%22consignee%22%2C%22ALL%22%5D",
    getTmsCustomers5 : "carrier/getTMSCustomers?limit=10&customerType=%5B%22chassisTermination%22%2C%22chassisPick%22%2C%22ALL%22%5D",
    getTmsCustomers6 : "carrier/getTMSCustomers?limit=10&customerType=%5B%22ALL%22%5D"
  }
}

export const getFleetManagersCacheKey = () => {
   return {
    getFleetManagers : 'carrier/getFleetManagers?CSR=true&active=true',
    getFleetManagers1 : 'carrier/getFleetManagers?CSR=true'
   }
}
export const clearCacheOnLogin = (params) => {
  const customer_apis_for_cache_clear = customerApiCacheKeys()
  const fleet_managers_apis_for_cache_clear = getFleetManagersCacheKey()
  return {
    portMarkets: "setup/portMarkets",
    getUser: "getUser",
    getDefaultDateFormat : "user/getDefaultDateFormat",
    getUserAddressFormat : "user/getUserAddressFormat",
    getContainerSize : 'admin/getContainerSize',
    getContainerType : 'admin/getContainerType',
    getChassisSize : 'admin/getChassisSize',
    getChassisType :'admin/getChassisType',
    getTMSChassisOwner : 'carrier/getTMSChassisOwner',
    getTMSContainerOwner :'carrier/getTMSContainerOwner',
    ...fleet_managers_apis_for_cache_clear,
    ...customer_apis_for_cache_clear
  }
}
export const clearCacheOnLogout = (params) =>{
  return  {
    getUserThemeSettings : `user/getUserThemeSettings?carrierId=${params?.carrierId}`
  }
}


export const cacheURLKeys = (params)=>{ 
  return {
  ...clearCacheOnLogin(params),
  ...clearCacheOnLogout(params)
}};


export async function clearLocalCache(urls){
  try {
    const calls = [];
    const cacheUrls = Object.values(urls);
    for(const url of cacheUrls){
      calls.push(HTTP("post", url));
    }
    return await Promise.all(calls);
  } catch (error) {
    console.log(`Error in clearLocalCache ${error}`);
  }
}

export function clearGivenCache(url){
  try {
    return HTTP("post", url);
  } catch (error) {
    console.log(`Error in clearGivenCache ${error}`);
  }
}

export const isMultiTendering = () => {
  const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}");
  return flag?.isMultiTender ?? false;
};

export const nylasAccountId = () => {
  const carrierId = JSON.parse(getStorage("carrierDetail") ?? '{}')?._id
  const id = JSON.parse(getStorage('embeddedEmailAccount'))?.accountId ?? `systemId_${carrierId}`
  return id
}

export const isUserSpecificTimeZoneEnabled = () =>{
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'));
   return setting?.isUserSpecificTimeZoneEnabled ?? false
}

export const handlePlaceDetails = async (address, handleSelect) => {
  try {
    let response = await getPlaceDetails({ searchTerm: address })
    
    if (response && Object.keys(response)?.length) {

      response.location = [{ lng: response?.geometry?.location?.lng, lat: response?.geometry?.location?.lat }]
      response.formatted_address = response[defaultAddressFormat() === 'eu' ? 'eu_address' : 'us_address']
    }

    return [response]
  } catch (error) {
    console.log("🚀 ~ getPlaceDetailsResponse ~ error:", error)
  }
}
export const checkIsVAT = () => {
  let userSetting = JSON.parse(getStorage("userBasicSettings"));
  const isVAT = userSetting?.isVAT || false;
  return isVAT
}
export const getLatestTarrifAmount = (charges) => {
  const currentDate = moment();

  const foundCharge = _.find(charges, charge => {
    const startDate = moment(charge?.effectiveStartDate);
    const endDate = moment(charge?.effectiveEndDate);
    return currentDate.isBetween(startDate, endDate, null, '[]');
  });

  if (foundCharge) {
    return {
      amount: foundCharge?.amount || 0,
      minimumAmount: foundCharge?.minimumAmount || 0,
      freeUnits: foundCharge?.freeUnits || 0,
    };
  }

  const nearestCharge = charges?.reduce((nearest, charge) => {
    const startDate = moment(charge?.effectiveStartDate);
    const endDate = moment(charge?.effectiveEndDate);

    const startDiff = startDate.diff(currentDate, 'days');
    const endDiff = endDate.diff(currentDate, 'days');

    const closestDate = (Math.abs(startDiff) < Math.abs(endDiff) || (Math.abs(startDiff) === Math.abs(endDiff) && startDiff > 0))
      ? startDate
      : endDate;

    if (!nearest) {
      return { charge, closestDate };
    }

    const nearestDateDiff = nearest?.closestDate.diff(currentDate, 'days');
    const closestDateDiff = closestDate.diff(currentDate, 'days');

    if (Math.abs(closestDateDiff) < Math.abs(nearestDateDiff) ||
      (Math.abs(closestDateDiff) === Math.abs(nearestDateDiff) && closestDateDiff > nearestDateDiff) ||
      (Math.abs(closestDateDiff) === Math.abs(nearestDateDiff) && closestDateDiff === nearestDateDiff && closestDate.isSame(startDate))) {
      return { charge, closestDate };
    }

    return nearest;
  }, null);

  if (nearestCharge) {
    return {
      amount: nearestCharge?.charge.amount || 0,
      minimumAmount: nearestCharge?.charge.minimumAmount || 0,
      freeUnits: nearestCharge?.charge.freeUnits || 0,
    };
  }

  return { amount: 0, minimumAmount: 0, freeUnits: 0 };
};

export const isDualTransactionEnabled = () => {
  const isFlagOn = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isDualTransactionEnabled ?? false;
  if (isFlagOn) {
    return true
  } else {
    return false
  }
}
export const getEmailSettingUrl = () => {
  return !isNewSettingsEnabled() ? '/settings/embedded-email/mail-box' : '/company-settings/embedded-email/mail-box'
}

export const showCustomChargeReport = () => {
  const carrierId = getCarrierId();
  if(config.idForCustomChargeReport.indexOf(carrierId) > -1) return true;
  return false;
}

export const isChartsEnabled = () => {
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'))
  return setting?.isChartsEnabled || false
}

export const isCabotageEnabled = () => {
  const isCabotageEnabled = JSON.parse(getStorage("userBasicSettings"))?.isCabotageEnabled ?? false;
  return isCabotageEnabled;
}
