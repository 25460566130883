import { getStorage } from '../../../../services/Common.services';
import { HTTP } from '../../../../services/Http.service';
import {objToQueryParams} from '../../../../Components/Common/jsonToQueryParams';

export function validateLoads(params) {
  let url = 'tms/validateLoads';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function uploadLoad(params) {
  let url = 'tms/uploadLoad';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getCustomers() {
  let url = 'carrier/getTMSCustomers';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result.data && result.data.data)
            resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTerminal(params) {
  let url = 'getTerminal';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getChassisPickCustomers(searchTerm = null) {
  const obj = { limit: 10, customerType: ['ALL', 'chassisPick'] }
  if (searchTerm)
    obj.nameSearch = searchTerm

  let url = 'carrier/getTMSCustomers';
  url = url + "?" + objToQueryParams(obj);
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getChassisTerminationCustomers(searchTerm = null) {
  const obj = { limit: 10, customerType: ['ALL', 'chassisTermination'] }
  if (searchTerm)
    obj.nameSearch = searchTerm

  let url = 'carrier/getTMSCustomers';
  url = url + "?" + objToQueryParams(obj);

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getEmptyOriginCustomers(searchTerm = null) {
  const obj = { limit: 10, customerType: ['ALL', 'containerReturn'] }
  if (searchTerm)
    obj.nameSearch = searchTerm

  let url = 'carrier/getTMSCustomers';
  url = url + "?" + objToQueryParams(obj);

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      let data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function getTMSconsignee(searchTerm = null) {
  const obj = { limit: 10, customerType: ['ALL', 'consignee'] }
  if (searchTerm)
    obj.nameSearch = searchTerm

  let url = 'carrier/getTMSCustomers';
  url = url + "?" + objToQueryParams(obj);

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      let data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function getTMScaller(searchTerm = null) {
  const obj = { limit: 10, customerType: ['ALL', 'caller'] }
  if (searchTerm)
    obj.nameSearch = searchTerm

  let url = 'carrier/getTMSCustomers';
  url = url + "?" + objToQueryParams(obj);

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      let data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export async function getTMSContainerOwner(nameSearch = null) {
  const obj = { limit: 10, carrierId: JSON.parse(getStorage('@Carrier')) }
  if (nameSearch)
    obj.searchTerm = nameSearch

  let url = 'carrier/getTMSContainerOwner';
  url = url + "?" + objToQueryParams(obj);

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      const data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function getTMSshipper(searchTerm = null) {
  const obj = { limit: 10, customerType: ['ALL', 'shipper'] }
  if (searchTerm)
    obj.nameSearch = searchTerm

  let url = 'carrier/getTMSCustomers';
  url = url + "?" + objToQueryParams(obj);

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      let data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function validateCompanyNames(allCompanyNames, allContainer, allSecondaryReferenceNo) {
  let formData = new FormData();
  formData.append("customers", JSON.stringify(allCompanyNames));
  if (allContainer) {
    formData.append("containers", JSON.stringify(allContainer));
  }
  if (allSecondaryReferenceNo) {
    formData.append("secondaryReferenceNo", JSON.stringify(allSecondaryReferenceNo));
  }

  let url = 'bulkupload/validateCompanyNames';
  return new Promise((resolve, reject) => {
    HTTP('post', url, formData, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTMSCustomers(searchTerm = null, type) {
  const obj = { limit: 10, customerType: ['ALL', type] }
  if (searchTerm)
    obj.nameSearch = searchTerm

  let url = 'carrier/getTMSCustomers';
  url = url + "?" + objToQueryParams(obj);

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      let data = result.data.data.map(d => ({ value: d._id, label: d.company_name }));
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

let freeze = false //mark delay 
let timer //saved timer
export const getCustomerAsyncSearch = async (strSearch, type) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getTMSCustomers(strSearch, type)//request
        res(r);
      }, 500)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export function updateBulkUploadColumns(params) {
  let url = 'user/update-bulkUploadColumns';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getBulkUploadColumns(params) {
  let url = 'user/getBulkUploadColumns';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}