export const notification_email_options = [
    "Reference #",
    "Invoice No",
    "Load No",
    "Customer",
    "Pick Up Location",
    "Delivery Location",
    "Return Location",
    "Pickup Chassis",
    "Terminate Chassis",
    "Container Type",
    "Container Size",
    "Container Owner",
    "Container #",
    "Chassis Type",
    "Chassis Size",
    "Chassis Owner",
    "Weight",
    "Hot",
    "Hazmat",
    "Temperature",
    "Purchase Order #",
    "Booking #",
    "Shipment #",
    "Master Bill of Lading",
    "House Bill of Lading",             
    "Seal #",            
    "Vessel Name",  
    "Voyage",    
    "Pick Up #",      
    "Appointment #",      
    "Return #",           
    "Reservation #",
    "Tracking URL",
    "Last Free Day",
    "Vessel ETA",
    "Discharged Date",
    "Outgate Date",
    "Empty Day",
    "Ingate Date",
    "Ready To Return Date",
    "Load",
    "ERD",
    "CutOff",
    "Return",
    "Return Day",
    "Status",
    "Last Updated Status Time",
    "Delivery Time",
    "Driver Name",
    "Expected Time",
    "Appointment Time",
    "Driver Timezone",
    "Time Stamp",
    "Missing Fields",
];