import React,{useEffect,useState, useRef,memo} from "react";
import { Link } from "react-router";
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { 
  updateQueryString,
  getSessionDispatchView,  
  getStorage,
  getCarrierId,
  checkIfPerDiemEnabled,
  perDiemEnums,
  isShiposCarrier,
  DateTimeFormatUtils,
  toastr,
  isCustomerAccount,
  amplitudeTrack,
  setStorage,
  isValidContainer,
  isNewBillingEnabled,
  isAccountServiceEnabled,
  checkChrge5ReportEnabled,
  isManageFleetOrOutSource,
  isNewDeduction,
  isDriverTariffEnabled,
  supportMoveEnabled,
  isConnectedWithOutSource,
  isSyncRequest,
  isVendorPayAndBillsEnabled,
  isCarrierTariffEnabled,
  isEmbeddedEmailEnabled,
  isTripEnabled,
  showForTerminal,
  isCabotageEnabled,
  isDualTransactionEnabled,
} from "../../services/Common.services";
import * as actionCreators from '../../pages/tms/Dispatcher/actionCreators'
import * as tabs from "./items";
import configuration from "../../config";
import { Icondots, IconDeleteRed, IconPlus, IconPen, IconCheck, IconTimes, IconDownload, IconShareFill, IconDot,  IconUploadCloud, IconTrash, IconHomeSmall  } from "../Common/Icons";
import useHover from "../CustomHooks/useHover";
import PopperTooltip from "../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import moment from "moment";
import { shipOsTabs } from "../constants";
import { getEncodedMailAuditHref } from "../../services/emailAudit.services";
import { isDakosySlotsEnabled, isDefaultEmailFrom, isFlagEnabled, isNewCustomerRateEnabled } from "../../../src/Components/Common/functions";
import { AuditTypeEnum, DEFAULT_TIMEZONE, Organizations} from "../../pages/tms/constant";
import { getDispatcherViewById } from "../../pages/tms/Dispatcher/DispatcherView/actionCreators";
import { getAPProblemSyncCount } from "../../pages/tms/ProblemAccountingService/settlements/service";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, MAIL_VIEW_TYPES } from "../../pages/tms/Email/constant";
import { canManageCompanyViews } from "pages/tms/Email/helper";
import { newSettingsIndexRoute } from "pages/tms/NewSettings/CompanySettings/constant";



const firebase = configuration.getNewFirebaseInstance;
const db = firebase('sub-header-instance').database();

const DISABLED_NAVIGATION_PATHS = ['/driver-messaging', '/tms/load-chat', '/group-messaging', '/employee-messaging']

const drayosReports = tabs.reportItems?.map(report => report.title).filter(title => !["Carrier Transaction Report","Load Master", "Pricing Breakdown", "Invoice Breakdown", "Vendor Pay Breakdown"].includes(title))
const SubHeaderTab = (props) => {
  const { buttons, val, title, updateProblemInvoiceCount, removeDispatcherView, clearFilter, modalView, filteredData, isDispatcherView, reload, isLoadingBarShow, TabName, setDispatcherHeader, isBillingView, billingView, onSelectView, removeBillingView, totalProblemSyncCount, totalConnectNoticeCount, isEmailView, emailView, removeEmailView, unSelectCurrentView, isDraft = false, viewFilterChanged, createOrUpdateEmailView, defaultviewId } = props;
  // const [importStCounts,setImportStCount]=useState(0)
  const [stCounts,setStCount]=useState(0)
  const [pbCounts,setPbCount]=useState(0)
  const [dispatcherView,setDispatcherView] = useState() 
  const [showInput, setShowInput] = useState(false)
  const [problemInvoiceCount,setProblemInvoiceCount]=useState(0)
  const [_totalProblemSyncCount, setTotalProblemSyncCount]=useState(0)
  const [newView, setNewView] = useState("")
  const [showOptions, setShowOptions] = useState();
  const [filterType, setFilterType] = useState("all");
  const [selectedDay, setSelectedDay] = useState(moment());
  const [loadingDownloadBtn, setLoadingDownloadBtn] = useState(false);

  const [Ref1, setRef1] = useState(null);

  const [showButton, setShowButton] = useState();
  const [showChargeTile, setShowChargeTile] = useState();
  const [syncLoadCount,setSyncLoadCount] = useState({});
  const wrapperRef = useRef(null);

  const [referenceElementRef, referenceElement] = useHover();
  const dispatch = useDispatch();
  const { downloadReportParams} = useSelector((state) => state.TmsReducer);
  const { isDriverPayEnabled , isCarrierPayEnabled } = useSelector((state) => state.accountingServiceReducer);

  const isAccountServiceEnable = isAccountServiceEnabled()
  const isDisabledNavigationPath = (path) => {
    const blockedUrlIndex = DISABLED_NAVIGATION_PATHS.findIndex(path)
    if(blockedUrlIndex >= 0) return true ;
    return false
  }
  const { isOutSource, isManageFleet } = isManageFleetOrOutSource();

  useEffect(() => { 
    if(!dispatcherView?.length)
    setDispatcherView(getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews")))
  }, [isLoadingBarShow]);

  useOutsideAlerter(wrapperRef);
  const user =
    getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser"));
  const timeZone = localStorage.getItem("timeZone")
  let carrierId = null;
  if (user && user.role === 'fleetmanager') {
    carrierId = user.fleetManager.carrier;
  } else if (user && user.role === 'carrier') {
    carrierId = user._id;
  }
  const carrier =
    getStorage("carrierDetail") && JSON.parse(getStorage("carrierDetail"));
  const getAuthorize = (array) => {
    
    let arrayData= [...array]
    let tempArray = [];
    if(checkIfPerDiemEnabled() !== true){
      arrayData = arrayData.filter(singleData => singleData?.permission !== perDiemEnums.PER_DIEM_PERMISSION)
    }

    if(val === "customerItems") {
      const profilesToDisplay = ["Tariffs", "Customer Profile"];
      if(isEmbeddedEmailEnabled()) profilesToDisplay.push("People")
      if(!isEmbeddedEmailEnabled()) arrayData = arrayData.filter((e) => e?.title !== "People");
      if(!isNewCustomerRateEnabled()) arrayData = arrayData.filter((e) => e?.title !== "Tariffs");
      else arrayData = arrayData.filter((e) => profilesToDisplay.includes(e?.title));
    }

    if(val === 'accPayableItems') {
      const nonVendorPayAndBillTabs = ["Apply Carrier Payment", "Carrier Pay Dashboard"];
      const vendorPayAndBillTabs = ["Vendor Pay", "Vendor Bills", "Apply Vendor Payment"];
      if(isVendorPayAndBillsEnabled()) arrayData = arrayData.filter((e) => !nonVendorPayAndBillTabs?.includes(e?.title));
      else arrayData = arrayData.filter((e) => !vendorPayAndBillTabs?.includes(e?.title));
    }

    if(val === 'DrayosCarrier'){
      arrayData = arrayData.filter((tab) => isCarrierTariffEnabled() ? tab?.title !== "Carrier Rates" : tab?.title !== "Carrier Tariff"); 
    }
    
    if (
      user &&
      user.currentUserRole !== "carrier" &&
      user.permissions?.length &&
      arrayData.length
    ) {
      tempArray = arrayData.filter((item) => {
        if (!item.permission) {
          return item;
        }
        if (Array.isArray(item.permission)) {
          if (_.intersection(user.permissions, item.permission).length > 0) {
            return item;
          }
        } else if(checkIfPerDiemEnabled() && item.permission === perDiemEnums.PER_DIEM_PERMISSION){
          return item;
        } else {
          if (user.permissions.includes(item.permission)) {
            return item;
          }
        }
      });
    } else {
      tempArray.push(...arrayData);
    }
    
    return tempArray;
  };

  const getCountST = () => {
    const filterParams = {
      status: StreetTurnStatus,
      type_of_load: ["IMPORT", "EXPORT"],
      isStreetTurnLoads: false
    };
    actionCreators.getStreetTurnLoadCounts(filterParams).then((res) => {
      let ImpCount = 0
      let ExpCount = 0
      res.data.forEach(el => {
        ImpCount = el.IMPORT + ImpCount
        ExpCount = el.EXPORT + ExpCount
      })
      setStCount(ImpCount + ExpCount)
    });

  }

  const deselectView = () => {
    onSelectView(null)
    setShowChargeTile(null)
  }

  unSelectCurrentView && unSelectCurrentView(deselectView)

  const getEmptyReturnClosedCount = () => {
    return new Promise((resolve) => {
      try {
        const carrierId = getCarrierId();
        const dbRef = db.ref(`${carrierId}/problemEmptyReturnCount`);
        dbRef.once('value', (snapshot) => {
          if(snapshot.val()) {
            let count = parseInt(snapshot.val());
            if(isNaN(count)) {
              count = 0;
            }
            return resolve(count)
          }
          return resolve(0);
        });
      } catch(e) {
        console.error(e);
        return resolve(0);
      }
    });
  }

  const getPbCounts = async () => {
    const filterParams = {
      status: AllStatus,
      "caution": true,
    };

    if (localStorage.getItem("timeZone") !== "null") {
      actionCreators.getContainerInformationCount()
        .then(async res => {
          let resp = res.data;
          let count = 0;
          count = (resp.problemContainerCount ? resp.problemContainerCount : 0) + (resp.lfdCount ? resp.lfdCount : 0) + (resp.returnContainerCount ? resp.returnContainerCount : 0);
          if (isValidContainer()) {
            count += (resp.unMatchedContainersCount ? resp.unMatchedContainersCount : 0)
          }
          const emptyReturnClosedCount = await getEmptyReturnClosedCount();
          setPbCount(count + (emptyReturnClosedCount ?? 0));
        })
        .catch((err) => { })
    }
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowOptions()
          ReactTooltip.hide();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (val === "dispatchItems") {
      getCountST()
      getPbCounts()
    }
    if (val === "accRecievableItems" && isAccountServiceEnable && ((user?.role != 'customer') && !user?.fleetManager?.isCustomer)) {
      GetProblemInvoiceCount()
    }
  }, [updateProblemInvoiceCount])

  const GetProblemInvoiceCount = () => {
    let TotalCount = 0
    actionCreators.getProblemInvoiceCount().then((res) => {
      TotalCount = res.count;
      setProblemInvoiceCount(TotalCount)
    })
  }

const getConnectNoticeCount = () => {
  actionCreators.getSyncRequestCount().then((res) => {
    setSyncLoadCount(res)
  })
}

  useEffect(() => {
    let localView = getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews"));
    setDispatcherView(localView)
  }, [reload])

  useEffect(() => {

    if (dispatcherView && dispatcherView.length > 0 && !showButton && filteredData) {
      const query = new URLSearchParams(window.location.search.substring(1))
      // If viewID exist in query string, setButton to view and load filter data from query string
      // if(query.has("filter")) {
      //   const filterParams = getFilterParams("filter", query) 
      //   if(filterParams && filterParams.cv) return setShowButton(filterParams.cv)
      // }
      if (query.has("filter")) {
        if (query.has("view"))
          return setShowButton(query.get("view"))
      } else {
        if (query.has("view")) {
          dispatcherView.forEach((item) => {
            if (item._id === query.get("view")) {
              setShowButton(item._id)
              updateQueryString({ "view": item._id })
              filteredData(getSessionDispatchView(item._id, false) ? getSessionDispatchView(item._id, false) : item, true, false)

            }
          })
          return
        }
      }



      // Otherwise filter default views data
      dispatcherView.forEach((item) => {
        if (item.isDefaultView) {
          setShowButton(item._id)
          updateQueryString({ "view": item._id })
          filteredData(getSessionDispatchView(item._id, false) ? getSessionDispatchView(item._id, false) : item, false, false)

        }
      })

    }
  }, [dispatcherView])

  useEffect(() => {
    if(billingView && billingView?.length && !showChargeTile){

      billingView.forEach((item) => {
        if (item.isDefaultView) {
          setShowChargeTile(item._id)
          updateQueryString({ "view": item._id })
        }
      })
    }
  }, [billingView])

  useEffect(() => {
    if(emailView && emailView?.length && !showChargeTile){
      const query = new URLSearchParams(window.location.search.substring(1))
      const viewId = query?.has("view") ? query?.get("view") : null 
      if(viewId) {
        setShowChargeTile(viewId)
        updateQueryString({ "view": viewId })
      }
    }
  }, [emailView])

  const deleteView = (view, index) => {
    removeDispatcherView(view)
  }
  const deleteBillingView = (view) => {
    removeBillingView(view)
  }
  const deleteEmailView = (view) => {
    removeEmailView(view)
  }
  useEffect(() => {
    if (val === "reportItems") {
      const data = document.querySelector(".sub_header_tab a.active")
        ?.parentElement?.id;
      if (data) {
        const seletectTab = document.getElementById(data);
        const scrollContainer = document.getElementById("subheader_scroll");

        const SubHeaderAutoScroll = (event) => {
          event.preventDefault();
          scrollContainer.scrollLeft += event.deltaY;
        };
        scrollContainer.addEventListener("wheel", SubHeaderAutoScroll);
        seletectTab.scrollIntoView({ block: "nearest", inline: "nearest" });
        return () => {
          scrollContainer.removeEventListener("wheel", SubHeaderAutoScroll);
        };
      }
    }
  }, [buttons]);

  useEffect(() => {
    if (val === "accPayableItems" && (isDriverPayEnabled || isCarrierPayEnabled)) {
      getAPProblemSyncCount().then((res) => {
        const { totalCount } = res;
        setTotalProblemSyncCount(totalCount ?? 0);
      }).catch((err) => console.error(err));
    }
  }, [isDriverPayEnabled]);

  useEffect(() => {
    if(isConnectedWithOutSource()){
      getConnectNoticeCount()
    }
  }, [])
  const badgeIcon = (title) => {
    switch (title?.split(" ")?.join('_')) {
      case "Street_Turns":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white badge-success-500 badge-pill">{stCounts}</span>
        break;
      case "Problem_Containers":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white bg-warning-500 badge-pill">{pbCounts}</span>
        break;
      case "Problem_Invoices":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white bg-warning-500 badge-pill">{problemInvoiceCount}</span>
        break;
      case "Problem_Sync":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white bg-warning-500 badge-pill">{totalProblemSyncCount || _totalProblemSyncCount || problemInvoiceCount}</span>
        break;
      case "Connect_Notices":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white bg-warning-500 badge-pill">{totalConnectNoticeCount || syncLoadCount?.TOTALREQUEST || 0}</span>
        break;
      default:
        return null
        break;
    }
  }

  const handleSelect = (fdate, tdate) => {
    setSelectedDay(fdate);
    dispatch({
      type: "DRIVER_PLANNER_CURRENT_DATE",
      payload: {fdate, tdate}
    })
  }
  const onDispatcherClick = (item) => {
    if(item?.title === "Billing" || item?.title === "Invoices" || item?.title === "Mailbox"){
      onSelectView(null)
      setShowChargeTile(null)
    }
  }

  const downloadReport = async () => {
    try {
      setLoadingDownloadBtn(true); 

      const isEmailAuditTab = TabName === "emailAuditReport";
      downloadReportParams.dateFormat = DateTimeFormatUtils.fullDateTimeFormat();
      downloadReportParams.timeZone= timeZone ?? DEFAULT_TIMEZONE;

      if(!isEmailAuditTab){
        delete downloadReportParams.sender;
        delete downloadReportParams.status;
      }

      const href = await getEncodedMailAuditHref(downloadReportParams)

      if(href?.length > 35){
        const anchor = document.createElement('a');
        anchor.href = href;
        anchor.download=`${isEmailAuditTab ? 'Email' : 'Download'} Audit Report.csv`;
        anchor.target='_blank';
        anchor.click();
      }else toastr.show("No data available !", "info");
      setLoadingDownloadBtn(false); 
    } catch (err) {
      setLoadingDownloadBtn(false); 
      console.error(err);
      toastr.show("Failed to download !", "error");
    }
  }

  const auditReprotTabs = ["emailAuditReport", "downloadAuditReport"];
  const allowedTabs = shipOsTabs(tabs)
  let tabsArray =
    getStorage("currentUserRole") !== "customer" &&
    !title &&
    (val === "companySettings"
      ? tabs[val]() && tabs[val]().length > 0 && getAuthorize(tabs[val]())
      : tabs[val] && tabs[val].length > 0 && getAuthorize(tabs[val]));
  let customerArray =
    getStorage("currentUserRole") === "customer" &&
    !title &&
    (val === "companySettings"
      ? tabs[val]() && tabs[val]().length > 0 && getAuthorize(tabs[val]())
      : tabs[val] && tabs[val].length > 0 && getAuthorize(tabs[val]));

  if(isShiposCarrier()) tabsArray = [...allowedTabs];
  if (tabsArray && tabsArray?.length > 0) {
    if (isCustomerAccount() && !isFlagEnabled(AuditTypeEnum.IS_EMAIL_AUDIT_ENABLED) || isDefaultEmailFrom()) tabsArray = tabsArray.filter((d) => d.title !== "Email Audit Report");
    tabsArray = tabsArray.map(tab => {
      if (isNewBillingEnabled()) {
        if (tab.path == "/tms/billing/account-receivable") tab.title = "Billing"
        if (tab.path == "/account/billing-configuration") tab.title = "Accounting Configuration"
      }
      if (isAccountServiceEnable && tab.path == "/tms/desyncedQBLoads") {
        tab.title = "Problem Sync"
        tab.path = "/tms/problem-sync"
      }
      if(isDualTransactionEnabled()) {
        if(tab.path == "/tms/customer-service/dropped-dispatch-yard-container") {
          tab.title = "Dual Transactions"
        }
      }
      return tab
    })
    if (isNewBillingEnabled()) {
      tabsArray = tabsArray.filter(tab => tab.path != "/tms/billing/sub-customer-account-receivable")
    }
  }
  const lastCompanyViewTypeIndex = emailView?.findIndex(item => item?.viewType === MAIL_VIEW_TYPES.PERSONAL_VIEW) ?? false
  return (
    <div className="subheader">
      <div className="subheader__inner d-flex align-items-center py-0">
        <div className="d-flex flex-row align-items-center justify-content-between w-100">
        {val === "companySettings" && 
          <div className="d-flex align-items-center justify-content-center align-self-start" style={{minWidth: 40, height: 50}}>
            <Link
              className={`text-muted`}
              to={newSettingsIndexRoute}
            >
              <IconHomeSmall/>
            </Link>
          </div>
        }
          <ul
            className="nav nav-tabs nav-tabs-custom flex-grow-1 overflow-x-auto text-nowrap flex-nowrap mr-2"
            role="tablist"
            style={{ overflowY: "hidden" }}
            id="subheader_scroll"
          >
            {title && (
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  // to={item.path}
                  activeClassName={"active"}
                >
                  <span className="hide-menu">{title}</span>
                </Link>
              </li>
            )}

            {customerArray && customerArray?.map((item, index) => {
              if (val === "customerNewBillingTabs" && !isNewBillingEnabled()) return;
              return (
                <li className="nav-item sub_header_tab" key={index} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                  <Link
                    className={`nav-link`}
                    to={item.path}
                    activeClassName={"active"}
                  >
                    <span className="hide-menu">{item.title}</span>
                  </Link>
                </li>
              )
            })}
            
            {tabsArray && tabsArray?.map((item, i) => {
              const isShipOsTabs = !_.includes(allowedTabs,{title:item.title});
              const tabLink = isShiposCarrier() ? (!isShipOsTabs ? '' : item.path) : item.path;
                if (item.title === "Terminal Settings") {
                  return (
                    getStorage("currentUserRole") == "carrier" &&
                    configuration.idForTerminal.indexOf(
                      getStorage("currentUserID")
                    ) != -1 && (
                      <li className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                        <Link
                          className={`nav-link`}
                          to={item.path}
                          activeClassName={"active"}
                        >
                          <span className="hide-menu">{item.title}</span>
                        </Link>
                      </li>
                    )
                  );
                } else if (item.title === "Import Charge Report" || item.title === "Export Charge Report") {
                  return (
                    configuration.idForCustomChargeReport.indexOf(
                      carrierId
                    ) > -1 && isManageFleet &&  (
                      <li className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                        <Link
                          className={`nav-link`}
                          to={item.path}
                          activeClassName={"active"}
                        >
                          <span className="hide-menu">{item.title}</span>
                        </Link>
                      </li>
                    )
                  )
                } else if (item.title === "Charge 5") {
                  return (
                    checkChrge5ReportEnabled() && isManageFleet && (
                      <li className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                        <Link
                          className={`nav-link`}
                          to={item.path}
                          activeClassName={"active"}
                        >
                          <span className="hide-menu">{item.title}</span>
                        </Link>
                      </li>
                    )
                  )
                }
                
                if (item.title === "HWB Import Report") {
                  if (isManageFleet && (JSON.parse(getStorage("userBasicSettings") ?? "{}")?.HWBImportReportEnabled ?? false)) {
                    return ( <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                    <Link
                      className={`nav-link`}
                      to={item.path}
                      activeClassName={"active"}
                    >
                      <span className="hide-menu">{item.title}</span>
                    </Link>
                  </li>)
                  } else {
                    return (<></>);
                  }
                }
                if (item.title === "Support Move") {
                  if (isManageFleet && (supportMoveEnabled() ?? false)) {
                    return ( <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                    <Link
                      className={`nav-link`}
                      to={item.path}
                      activeClassName={"active"}
                    >
                      <span className="hide-menu">{item.title}</span>
                    </Link>
                  </li>)
                  } else {
                    return (<></>);
                  }
                }
                if (item.title === "Expense Report") {
                  if (isNewBillingEnabled()) {
                    return ( <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                    <Link
                      className={`nav-link`}
                      to={item.path}
                      activeClassName={"active"}
                    >
                      <span className="hide-menu">{item.title}</span>
                    </Link>
                  </li>)
                  } else {
                    return (<></>);
                  }
                }
              
              if (item.title === "Dynamic Driver Pay") {
                if (!isDriverTariffEnabled()) {
                    return ( <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                    <Link
                      className={`nav-link`}
                      to={item.path}
                      activeClassName={"active"}
                    >
                      <span className="hide-menu">{item.title}</span>
                    </Link>
                  </li>)
                  } else {
                    return (<></>);

                  }
                }
                if (item.title === "Deduction Report") {
                  if (isNewDeduction()) {
                    return ( <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                    <Link
                      className={`nav-link`}
                      to={item.path}
                      activeClassName={"active"}
                    >
                      <span className="hide-menu">{item.title}</span>
                    </Link>
                  </li>)
                  } else {
                    return (<></>);
                  }
                }

                if(item.title === "Driver Pay Rates") {
                  return (
                    <li className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                      <Link
                        className={`nav-link`}
                        to={JSON.parse(getStorage("carrierDetail"))?.newDriverPay ? "/tms/driver-pay-rates" : "/tms/payment-setting"}
                        activeClassName={"active"}
                      >
                        <span className="hide-menu">{item.title}</span>
                      </Link>
                    </li>
                  )
                }
                
                if(item.title ==="Problem Invoices" && !(user?.qbAccount !== undefined||carrier?.qbAccount !== undefined) && !isAccountServiceEnable){
                  return false
                }
                if (item.title === "My Apps" && (user?.role !== 'carrier' && user?.role !== 'fleetmanager')) {
                  return false
                }
                if(item.title === "Trips" && (!(isTripEnabled()) || !isManageFleet )) {
                  return (<></>);
                }
                if(item.title === "Rail Order" && !((JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isRailOrderEnabled ?? false) && isManageFleetOrOutSource()?.isHybrid)) {
                  return (<></>);
                }
                if(item.title === "Connect Notices"  && !isConnectedWithOutSource() && !isSyncRequest()) {
                  return (<></>);
                }
                if(item.title === "Credit Memos" && !(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.creditFlag ?? false)) {
                  return (<></>);
                }
                if(item.title === "Users & Permission" && JSON.parse(getStorage("loggedInUser"))?.role === "fleetmanager" && isShiposCarrier()) {
                  return <></>
                }
                if(item.title === "General Info" && JSON.parse(getStorage("loggedInUser"))?.role !== "carrier" && isShiposCarrier()){
                  return <></>
                }
                if(item?.title === "Connect Configuration" && !isConnectedWithOutSource() && !isSyncRequest()){
                  return <></>
                }
                if(item.title === "Dynamic Tax" && !(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isVAT ?? false)) {
                  return (<></>);
                }
                if(item.title === "Invoices" && !(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.billingConfiguration?.isBillingEnabled ?? false)) {
                  return (<></>);
                }
                if(item.title==="Problem Sync" && val === "accPayableItems" && (isManageFleet || isOutSource) && !(isDriverPayEnabled || isCarrierPayEnabled)){
                  return (<></>);
                }
                if(item.title==="Carrier Pay Dashboard" && val === "accPayableItems" && isManageFleet && !isOutSource){
                  return (<></>);
                }
                if(item.title==="Apply Carrier Payment" && val === "accPayableItems" && isManageFleet && !isOutSource){
                  return (<></>);
                }
                if(item.title==="Driver Pay" && val === "accPayableItems" && isOutSource && !isManageFleet){
                  return (<></>);
                }
                if(item.title==="Driver Settlements" && val === "accPayableItems" && isOutSource && !isManageFleet){
                  return (<></>);
                }
                if(item.title==="Dynamic Expenses" && val === "accPayableItems" && isOutSource && !isManageFleet){
                  return (<></>);
                }
                if(item.title==="Embedded Email" && val === "settingItems" && !isEmbeddedEmailEnabled()){
                  return (<></>);
                }
                if(item.title === "Apply Payments" && (JSON.parse(getStorage("userBasicSettings") ?? "{}")?.creditFlag ?? false)){
                  return (
                    <li className="nav-item sub_header_tab d-flex align-items-center justify-content-center px-3" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                    <Link
                      className="d-flex flex-column align-items-center justify-content-center nav-link p-0"
                      to={tabLink}
                      activeClassName={"active"}
                    >
                      <span className="hide-menu">Apply
                        {badgeIcon(item.title)}</span>
                         Payments & Credits
                    </Link>
                   </li>
                )
                }
                if(item.title === "Settlement Settings") {
                  return (
                    <li className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                      <Link
                        className={`nav-link ${window.location.pathname === "/tms/billing/settlement-periods" ? 'active': ''}`}
                        to={item.path}
                        activeClassName={"active"}
                      >
                        <span className="hide-menu">{item.title}</span>
                      </Link>
                    </li>
                  )
                }
                if(item.title === "Messaging") {
                  return (
                    <li className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                      <Link
                      className={isDisabledNavigationPath(window.location.pathname) ? "active nav-link" : "nav-link"}
                      to={isDisabledNavigationPath(window.location.pathname) ? null: isManageFleet ? `${item.path}` : "/employee-messaging"}
                      activeClassName="active"
                    >
                      <span className="hide-menu">{item.title}
                      { badgeIcon(item.title) }</span>
                    </Link> 
                    </li>
                  )
                }
                if (item.title === "Enterprise Connection Setup" && !(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isEnterpriseLoginEnabled ?? false)) {
                  return (<></>);
                }
                if (item.title === "Cabotage" && !(isCabotageEnabled())) {
                  return (<></>);
                }
                if (item.title === "Slots Availability" && !(isDakosySlotsEnabled())) {
                  return (<></>);
                }
                if (item.title === "Driver Itinerary" && !isManageFleet) {
                  return (<></>);
                }
                if (item.title === "Planner" && !isManageFleet) {
                  return (<></>);
                }
                if (item.title === "Online Sent Tendered Load" && isManageFleet) {
                  return (<></>);
                }
                if (["Online Tendered Loads V2"].includes(item.title) && isOutSource) {
                  return (<></>)
                }
                if (["Carrier Transaction Report"].includes(item.title) && !isOutSource) {
                  return (<></>)
                }
                if (["Vendor Pay Breakdown"].includes(item.title) && !isOutSource) {
                  return (<></>)
                }
                if (val === "reportItems" && drayosReports.includes(item.title) && !isManageFleet) {
                  return (<></>)
                }
                if (item.title === "Customer Profile" && (isEmbeddedEmailEnabled() ?? false)) {
                  return (
                    <li onClick={() => item?.title === "Dispatcher" ? setDispatcherHeader() : onDispatcherClick(item)} className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                      <Link
                        className="nav-link"
                        to={tabLink}
                        activeClassName={"active"}
                      >
                        <span className="hide-menu">{Organizations}</span>
                      </Link>
                    </li>
                  )
                }
                if (item.title === "Custom Report" && !['carrier','fleetmanager']?.includes(user?.role )) {
                  return <></>
                }
                return (
                  <li onClick={() => onDispatcherClick(item)} className="nav-item sub_header_tab" key={i} id={`tab${item?.title?.split(" ")?.join("_")}`}>
                    <Link
                      className="nav-link d-flex align-items-center"
                      to={tabLink}
                      activeClassName={"active"}
                    >
                      {item?.icon} {item.title}
                      { badgeIcon(item.title) }
                    </Link> 
                  </li>
                );
              })}
            {isDispatcherView && dispatcherView && dispatcherView.length > 0 && dispatcherView.map((item, i) => {
              if (item.viewName) {

                if (item.viewName == newView) {
                  setShowInput(!showInput);
                  setNewView("")
                }
                return (
                  <li className="nav-item" key={i}>
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <span className={`nav-link hide-menu ${showButton === item._id && window.location?.search ? "active" : ""}`} onClick={() => {
                        getDispatcherViewById(item._id).then(viewData => {
                          if(viewData?.dispatcherHeaders) {
                            let views = (getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews")))
                            let viewIndex = views.findIndex(d => d._id === item._id )
                            if(viewIndex !== -1) {
                              views[viewIndex] = viewData
                              setStorage("dispatcherViews", JSON.stringify(views))
                            }
                          }
                          filteredData(
                            getSessionDispatchView(item._id, false) ? getSessionDispatchView(item._id, false) : item
                            , true
                          )
                        })
                        setShowButton(item._id)
                        updateQueryString({ "view": item._id })
                        let eventProperties = {
                          source: "view_open"
                        }
                        amplitudeTrack( "DISPATCHER_VIEW",eventProperties)
                      }
                      }> {item.viewName} {item.isDefaultView && <span class="badge badge-sm font-12 ml-1 text-white badge-status-light-blue badge-pill"><IconCheck /></span>}
                      </span>
                      {showButton == item._id &&
                        <React.Fragment>
                          <span className="pointer" onClick={() => {
                            setShowOptions()
                            setShowButton()
                            clearFilter(true, true)
                          }}>
                            <IconTimes />
                          </span>
                          <span
                            data-tip
                            data-for="addView"
                            data-event="click"
                            ref={(ref) => setRef1(ref)}
                            className="pointer  tooltip-label"
                            onClick={() => {
                              setShowOptions(showOptions != item._id ? item._id : undefined)
                              ReactTooltip.show(Ref1);
                            }}
                          >
                            <Icondots className='ml-2' />
                          </span>
                          <ReactTooltip
                            place="bottom"
                            effect="solid"
                            id="addView"
                            className="dispatcher-info"
                          >
                            <div className="bg-white h-90 p-10 rounded-lg" ref={wrapperRef}>
                              <div className="pl-10 pr-10 pb-2 pt-1 pointer">
                                <h6 className="font-14 m-0" onClick={() => {
                                  setShowOptions()
                                  modalView(item)
                                  ReactTooltip.hide();
                                }} ><IconPen className="text-gray-500" /> <span className="ml-10">Edit</span></h6>

                              </div>
                              <div className="pl-10 pr-10 pb-1 pt-2 pointer">
                                <h5 className="font-14 m-0" onClick={() => {
                                  setShowOptions()
                                  deleteView(item, i)
                                  ReactTooltip.hide();
                                }}><IconDeleteRed /> <span className="ml-10 text-danger">Delete</span></h5>
                              </div>
                            </div>
                          </ReactTooltip>
                        </React.Fragment>
                      }
                    </div>
                  </li>
                )
              }            })
            }
              {!isShiposCarrier() && isDispatcherView && (
                <li className="nav-item">
                  <span 
                    className="nav-link hide-menu" 
                    onClick={()=>{
                      !isShiposCarrier() && modalView()
                    }}> 
                    <span ref={referenceElementRef}>
                      <IconPlus className="wh-15px" />
                    </span>
                  </span>
                    {referenceElement && 
                      <PopperTooltip isOpen={referenceElement} refNo={referenceElementRef?.current} name={'Add New View'}  color={'gray-700'} cssClass={"add_view"} />
                    }
                </li>
              )}
              {billingView && billingView.map((item, i)  => {
                  if (item.viewName) {
                    return (
                      <li className="nav-item" key={i}>
                        <div className="h-100 d-flex align-items-center justify-content-center">
                          <span className={`nav-link hide-menu ${showChargeTile === item._id && window.location?.search ? "active" : ""}`} onClick={() => {
                            onSelectView(item._id)
                            setShowChargeTile(item._id)
                            updateQueryString({ "view": item._id })
                          }
                          }> {item.viewName} {item.isDefaultView && <span class="badge badge-sm font-12 ml-1 text-white badge-status-light-blue badge-pill"><IconCheck /></span>}
                          </span>
                          {showChargeTile == item._id &&
                            <React.Fragment>
                              <span className="pointer" onClick={() => {
                                onSelectView(null)
                                setShowChargeTile(null)
                              }}>
                                <IconTimes />
                              </span>
                              <span
                                data-tip
                                data-for="addView"
                                data-event="click"
                                ref={(ref) => setRef1(ref)}
                                className="pointer  tooltip-label"
                                onClick={() => {
                                  setShowOptions(showOptions != item._id ? item._id : undefined)
                                  ReactTooltip.show(Ref1);
                                }}
                              >
                                <Icondots className='ml-2' />
                              </span>
                              <ReactTooltip
                                place="bottom"
                                effect="solid"
                                id="addView"
                                className="dispatcher-info"
                              >
                                <div className="bg-white h-90 p-10 rounded-lg" ref={wrapperRef}>
                                  <div className="pl-10 pr-10 pb-2 pt-1 pointer">
                                    <h6 className="font-14 m-0" onClick={() => {
                                      setShowOptions()
                                      modalView(item)
                                      ReactTooltip.hide();
                                    }} ><IconPen className="text-gray-500" /> <span className="ml-10">Edit</span></h6>
    
                                  </div>
                                  <div className="pl-10 pr-10 pb-1 pt-2 pointer">
                                    <h5 className="font-14 m-0" onClick={() => {
                                      setShowOptions()
                                      deleteBillingView(item)
                                      ReactTooltip.hide();
                                    }}><IconDeleteRed /> <span className="ml-10 text-danger">Delete</span></h5>
                                  </div>
                                </div>
                              </ReactTooltip>
                            </React.Fragment>
                          }
                        </div>
                      </li>
                    )
                  }
              })}
              {isBillingView && !isShiposCarrier() && (
                <li className="nav-item">
                  <span 
                    className="nav-link hide-menu" 
                    onClick={()=>{
                      !isShiposCarrier() && modalView()
                    }}> 
                    <span ref={referenceElementRef}>
                      <IconPlus className="wh-15px"/>
                    </span>
                  </span>
                    {referenceElement && 
                      <PopperTooltip isOpen={referenceElement} refNo={referenceElementRef?.current} name={'Add New View'}  color={'gray-700'} cssClass={"add_view"} />
                    }
                </li>
              )}
              {emailView && emailView?.length > 0 && <li className="d-flex align-items-center"><div className="vr-line h-14px mx-10"></div></li>}

              {emailView && emailView.map((item, i) => {
                if (item.viewName) {
                  const isEditable = item?.viewType === MAIL_VIEW_TYPES.PERSONAL_VIEW ? true : (item?.viewType === MAIL_VIEW_TYPES.COMPANY_VIEW && canManageCompanyViews()) ? true : false
                  return (
                    <>
                    {lastCompanyViewTypeIndex > 0 && i=== lastCompanyViewTypeIndex && <li className="d-flex align-items-center"><div className="vr-line h-14px mx-10" ></div></li>}
                    <li className="nav-item" key={i}>
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <span className={`nav-link hide-menu ${showChargeTile === item._id && window.location?.search ? "active" : ""}`} onClick={() => {
                          if (showChargeTile === item._id) return
                          let eventProperties = {
                            source: AMPLITUDE_EVENTS_SOURCE.TOP_VIEW_BAR
                          }
                          amplitudeTrack(AMPLITUDE_EVENTS.SELECT_VIEW, eventProperties);
                          onSelectView(item._id)
                          setShowChargeTile(item._id)
                          updateQueryString({ "view": item._id })
                        }
                        }> {item.viewName} {item?._id + "" === defaultviewId && <span class="badge badge-sm font-12 ml-1 text-white badge-status-light-blue badge-pill"><IconCheck /></span>}
                        </span>
                        {(showChargeTile == item._id && viewFilterChanged && isEditable) ? <IconDot className="text-primary" /> : item?.viewType === MAIL_VIEW_TYPES.COMPANY_VIEW ? < IconShareFill className="text-muted" /> : <></>}
                        {showChargeTile == item._id &&
                          <React.Fragment>
                            <span
                              data-tip
                              data-for="addView"
                              data-event="click"
                              ref={(ref) => setRef1(ref)}
                              className="pointer  tooltip-label"
                              onClick={() => {
                                setShowOptions(showOptions != item._id ? item._id : undefined)
                                ReactTooltip.show(Ref1);
                              }}
                            >
                              <Icondots className="text-dark wh-20px" />
                            </span>
                            <ReactTooltip
                              place="bottom"
                              effect="solid"
                              id="addView"
                              className="dispatcher-info o-1"
                            >
                              <div className="bg-white h-90 p-10 rounded-lg" ref={wrapperRef}>
                              {viewFilterChanged && isEditable && 
                                    <div className="pointer popdrop__dropdown-item" onClick={() => {
                                      createOrUpdateEmailView(item.viewName,null,{},item._id)
                                      ReactTooltip.hide();
                                      setShowOptions()
                                    }} >
                                      {item?.viewType === MAIL_VIEW_TYPES.COMPANY_VIEW  ? <><IconUploadCloud className="text-primary"/>
                                        <span className="ml-10 text-primary">Publish Filter Changes</span></> : <><IconCheck className="text-primary" />
                                        <span className="ml-10 text-primary">Save filter changes</span></>}
                                    </div>
                                  }
                                  <div className="pointer popdrop__dropdown-item d-flex align-items-center gap-10" onClick={() => {
                                      setShowOptions()
                                      modalView(item)
                                      ReactTooltip.hide();
                                  }} ><IconPen className="text-gray-500" /> Edit</div>
                                  {isEditable &&
                                    <div className="pointer popdrop__dropdown-item d-flex align-items-center gap-10 text-danger" onClick={() => {
                                      setShowOptions()
                                      deleteEmailView(item)
                                      ReactTooltip.hide();
                                    }}><IconTrash /> Delete</div>
                                  }
                              </div>
                            </ReactTooltip>
                          </React.Fragment>
                        }
                      </div>
                    </li>
                    </>
                  )
                }
              })}
              {isEmailView && (
                <li className="nav-item">
                  <span 
                    className={`nav-link hide-menu ${isDraft ? "pointer-not-allowed" : ""}`} 
                    onClick={()=>{
                      if(isDraft) return;
                      modalView()
                    }}> 
                    <span ref={referenceElementRef}>
                      <IconPlus className="wh-15px"/>
                    </span>
                  </span>
                    {referenceElement && !isDraft && 
                      <PopperTooltip isOpen={referenceElement} refNo={referenceElementRef?.current} name={'Add New View'}  color={'gray-700'} cssClass={"add_view"} />
                    }
                </li>
              )}
          </ul>
          {TabName && auditReprotTabs.includes(TabName) && 
            <button 
              className={`btn btn-primary text-white ${val === "adminAuditReport" && "mt-2"}`}
              onClick={downloadReport}
            >
              {loadingDownloadBtn ? 
                <>
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true">
                  </span>
                  Downloading
                </>  : <><IconDownload className="mr-2 ml-auto" /> Download</>}
            </button>
          }
          <div className="ml-auto d-flex flex-shrink-0 ml-2">{buttons}</div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(memo(SubHeaderTab));

const StreetTurnStatus = [
  "AVAILABLE",
  "PENDING",
  "DISPATCHED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED",
  "HOOKCONTAINER_DEPARTED",
  "STOPOFF_DEPARTED",
  "STOPOFF_ARRIVED",
];

const AllStatus = ["AVAILABLE","PENDING","DEPARTED","DISPATCHED","CHASSISPICK_ARRIVED","CHASSISPICK_DEPARTED","PULLCONTAINER_ARRIVED","PULLCONTAINER_DEPARTED","DROPCONTAINER_ARRIVED","DROPCONTAINER_DEPARTED","HOOKCONTAINER_ARRIVED,","HOOKCONTAINER_DEPARTED","DELIVERLOAD_ARRIVED","DELIVERLOAD_DEPARTED","RETURNCONTAINER_ARRIVED","RETURNCONTAINER_DEPARTED","CHASSISTERMINATION_ARRIVED","CHASSISTERMINATION_DEPARTED"]
