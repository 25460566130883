import { useContext, useRef, useState } from "react"
import { IconAngleArrowDown } from "Components/Common/Icons"
import { EMAIL_CONTEXT, EMAIL_SELECT_ALL_DROPDOWN, EMAIL_TOOL_TIPS, PERMISSION_ERROR } from "../../constant"
import SelectCustomComponent from "./../../../../tms/SelectComponent/index"
import _ from "lodash"
import EmailToolTip from "../EmailDetails/EmailToolTip"


const CheckboxFormatter = ({ row, isHeader, onSelectRow, selectedRows }) => {
  const [showHeaderCheckDrop, setShowHeaderCheckDrop] = useState(false)
  const cellRef = useRef()

  const context = useContext(EMAIL_CONTEXT)
  const { allEmails } = context
  const isAllChecked = allEmails?.length === selectedRows?.length && selectedRows?.length !== 0
  const selectedEmailIds = selectedRows?.map(mail => mail?.id + "")
  const isChecked = selectedEmailIds?.includes(row?.id)

  const handleSelectSingle = (e) => {
    const emails = _.cloneDeep(selectedRows)
    const { checked } = e.target
    let newSelectedEmail = []
    if (checked) {
      newSelectedEmail = [...emails, row]
    } else {
      newSelectedEmail = emails?.filter(mail => mail?.id !== row?.id)
    }
    onSelectRow(false, newSelectedEmail)
  }

  const handleSelectAll = (e) => {
    const { checked } = e.target
    if (checked) {
      onSelectRow(true, allEmails)
    } else {
      onSelectRow(true, [])
    }
  }

  const handleSelectFromDropDownAll = (key) => {
    const emails = _.cloneDeep(allEmails)
    let selectedEmail = []
    switch (key) {
      case "all":
        selectedEmail = emails
        break;
      case "none":
        selectedEmail = []
        break;
      case "read":
        selectedEmail = emails?.filter(email => !email?.unread)
        break;
      case "unread":
        selectedEmail = emails?.filter(email => email?.unread)
        break;
      case "starred":
        selectedEmail = emails?.filter(email => email?.starred)
        break;
      case "unstarred":
        selectedEmail = emails?.filter(email => !email?.starred)
        break;
      default:
        break;
    }
    onSelectRow(true, selectedEmail)
  }


  const handleShowHeaderCheckDrop = () => {
    setShowHeaderCheckDrop((prevState) => !prevState)
  }

  if (isHeader) {
    // Render checkbox for the header
    return (
      <>
        <div className="d-flex align-items-center email-drop-down"  >
          <div
            data-tip={EMAIL_TOOL_TIPS.SELECT_ALL}
            data-for="email-table-tooltip"
          >
          <input
            className="rdg-checkbox"
            type="checkbox"
            onClick={(e) => {
              handleSelectAll(e)
            }}
            checked={isAllChecked}
          />
          {/* <EmailToolTip toolTipId={"check-box-all"} /> */}
          </div>
          <span className="pointer" onClick={handleShowHeaderCheckDrop} ref={cellRef}>
            <IconAngleArrowDown className="text-muted" />
          </span>
        </div>
        {showHeaderCheckDrop &&
          <SelectCustomComponent
            name='mailType'
            refClassName="email-drop-down"
            cellRef={cellRef}
            listData={EMAIL_SELECT_ALL_DROPDOWN}
            keyName="label"
            onSelect={(name, value) => {
              handleSelectFromDropDownAll(value?.value)
              handleShowHeaderCheckDrop()
            }}
            unselectCell={handleShowHeaderCheckDrop}
          />
        }
      </>
    )
  } else {
    // Render checkbox for individual cells
    return (
      <div
        data-tip={EMAIL_TOOL_TIPS.SELECT}
        data-for="email-table-tooltip"
        className="d-flex align-items-center"
      >
        <input
          className="rdg-checkbox"
          type="checkbox"
          checked={isChecked} // Set checkbox state based on selected rows
          onChange={(e) => {
            handleSelectSingle(e)
          }}
        />
        {/* <EmailToolTip toolTipId={`check-box-${row?.id}`} width={`w-80px`} /> */}
      </div>
    )
  }
}

export default CheckboxFormatter
