import React, { Component } from 'react';
import "./utils/currency";
import "babel-polyfill"
import Routes from './routes';
import './Components/Common/interceptor';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Helmet from 'react-helmet';

import configs from './redux/store';
import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import { setupGAnalytics } from './utils/googleAnalytics';
import { getStorage, isShiposCarrier } from './services';
import config from './config';
import {  set_new_server_api_path } from 'services/newServerAPIs';
const firebase = config.firebase;

const { store, persistor } = configs()
class App extends Component {

  constructor(props) {
    super(props);
    this.isShipOsCarrier = isShiposCarrier()
  }
  componentWillUnmount() {
    if (this.hapiVersionUpgradRef) {
      this.hapiVersionUpgradRef.off("value");
    }
  }
  componentDidMount() {
    
    this.hapiVersionUpgradRef = firebase.database().ref("hapi-version/change");
    this.hapiVersionUpgradRef.on("value",async (snapshot) => {
      const data = snapshot.val();
      if(!data && !this.state?.version){
        await set_new_server_api_path();
      }
      const load = JSON.parse(data);
      const version = load?.version;
      if(version !== this.state?.version){
        this.setState({version})
        await set_new_server_api_path(true);
      }
    });

    // setupHotjar();
    setupGAnalytics();
    caches.keys().then(keyList => {
      keyList = keyList.filter((key) => key.includes('webpack-offline'));
      return Promise.all(
        keyList.map(key => {
          return caches.delete(key);
        }),
      );
    });
  }
  render() {
    return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* {!this.isShipOsCarrier &&  
          <>
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20578305.js"></script>
            </Helmet>
            </>
          }  */}
          <Routes />
        </PersistGate>
      </Provider>
    </>
    );
  }
};

export default App;