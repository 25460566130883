import React,{useEffect,useRef} from 'react'
import { Modal } from "react-bootstrap";
import { useState } from 'react';
import { getTripDetail } from '../actionsCreator';
import config from '../../../../config';
import { getCarrierId, getStorage } from '../../../../services';
import moment from 'moment';
import NewRoutingTab from './Tabs/NewRouting';
import { getTripDetailFromRedis } from 'services/common';

const firebase = config.firebase;
const db = firebase.database();

const TripInfoModal = ({tripId,closeModal,stopLoader}) => {
    const [trip,setTrip] = useState(null)
    const tripRef = useRef(null)
	const firstTripRef = useRef(null)

   useEffect(() => {
    getTripDetails()
   }, []);

   const getTripDetails = ()=>{
       const params = { tripId: tripId }
       getTripDetail(params).then((res) => {
        if(res) setTrip(res)
        stopLoader();
       }).catch(err => {
           console.log(err)
       })
   }

    useEffect(() => {
        subscribeToFirebase();
        return () => {
            unsubscribeFromFirebase();
        };
    }, []);

    const subscribeToFirebase = () => {
        unsubscribeFromFirebase();
        tripRef.current = db.ref(`${getCarrierId()}/trip-edit`);
        tripRef.current.on("value", (snapshot) => {
            if (firstTripRef.current) {
                firstTripRef.current = false;
                return;
            }
            updatedLoadUsingFirebaseData(snapshot)
        });
    };


    const updatedLoadUsingFirebaseData = (snapshot) => {
        if (snapshot && snapshot.val()) {
            const tripInfo = JSON.parse(snapshot.val());
            if (tripInfo && moment().diff(moment(tripInfo.updatedAt), "seconds") <= 10) {
                getTripDetailFromRedis(tripInfo).then((trip)=>{
                    if(trip){
                        setTrip((prev) => {
                            if (prev?.tripNumber === trip?.tripNumber) {
                                return trip
                            }
                            return prev
                        })
                    }
                    
                }).catch(err => {console.log(err)})
                
            }
        }
    }

    const unsubscribeFromFirebase = () => {
        if (tripRef.current) {
            tripRef.current?.off();
        }
    };

    if (trip) {
        return (
            <Modal
                show={true}
                centered={true}
                className="modal-extend modal-full p-4 tripModal overflow-hidden rounded-5"
                scrollable={true}
            >
                <Modal.Body className="bg-gray-50 p-3 h-100">
                        <NewRoutingTab orderDetails={trip} updateTripDetails={(data) => setTrip(data)} closeModal={()=>{setTrip(null);closeModal()}} />
                </Modal.Body>
            </Modal>
        )
    }
  
}

export default TripInfoModal