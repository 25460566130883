import _ from "lodash"
import { getCarrierByUserId, getDriverAndCarrierName, getStorage, getValidDrivers, isManageFleetOrOutSource, isNewSupportMovesEnabled, isSupportMovesEnabled, showForTerminal, toastr } from "../../../services"
import { getDefaultAddress } from "../Dispatcher/actionCreators"
import { DRIVER_ORDER_VALIDATION, statusType, SUPPORT_MOVE_TO_ROUTING } from "../LoadRouting/constants"
import { createLoadStatus, findPrevValidEventIndex, movesFromDriverOrder } from "../LoadRouting/utilities"
import { EXPORT_LOAD_TYPE, IMPORT_LOAD_TYPE, SUPPORT_MOVE_EVENTS, TRIP_TYPES, loadEvents, supportMoveTypes, tmsDrops, tmsHooks } from "../constant"

export const routingEventsOnDropValidation = (driverOrder, loadDetails, currentIndex, loadStatusType, customer) => {
  const _driverOrder = _.cloneDeep(driverOrder)

  if (currentIndex === _driverOrder.length) {
    const afterLiftOffAndNotChassisEvent =
      _driverOrder?.[currentIndex - 1]?.type === loadEvents.LIFTOFF &&
      ![loadEvents.CHASSISTERMINATION, loadEvents.CHASSISPICK].includes(loadStatusType)
    if (_driverOrder?.[currentIndex - 1]?.type === loadEvents.DROPCONTAINER || afterLiftOffAndNotChassisEvent) {
      const HookEvent = createLoadStatus(
        DRIVER_ORDER_VALIDATION[_driverOrder?.[currentIndex - 1]?.type]?.relatedEvent,
        _driverOrder?.[currentIndex - 1]?.customerId,
        null,
        _driverOrder?.[currentIndex]?.prevType,
        _driverOrder?.[currentIndex]?.loadAssignedDate,
        _driverOrder?.[currentIndex]?.isGrayPool,
        null,
        null
      )

      _driverOrder.splice(currentIndex, 0, HookEvent)
      currentIndex += 1
    }
  }

  const belowEvent = _driverOrder?.[currentIndex]

  if (belowEvent && belowEvent?.isVoidOut) return

  const isDeliveryInLoad = loadDetails?.consignee?.length
  if (!isDeliveryInLoad) {
    const isDeliveryEvent = loadStatusType === loadEvents.DELIVERLOAD
    const isDriverInMove = driverOrder?.[currentIndex]?.driver
    if (isDeliveryEvent && isDriverInMove) {
      toastr.show("A valid location is required here. DELIVERLOAD", "error")
    }
  }

  let driverId = belowEvent?.driver
  let prevType = belowEvent?.prevType
  let isGrayPool = belowEvent?.isGrayPool
  let moveId = belowEvent?.moveId
  let loadAssignedDate = belowEvent && belowEvent.loadAssignedDate
  let moveBreak = false

  let prevValidEventIndex = findPrevValidEventIndex(_driverOrder, currentIndex)
  const prevValidEvent = _driverOrder?.[prevValidEventIndex] || null
  if (currentIndex === _driverOrder.length || loadStatusType === loadEvents.CHASSISTERMINATION) {
    driverId = prevValidEvent?.driver
    prevType = prevValidEvent?.type
    isGrayPool = prevValidEvent?.isGrayPool
    moveId = prevValidEvent?.moveId
    loadAssignedDate = prevValidEvent?.loadAssignedDate
  }

  const newEventsToAdd = []
  newEventsToAdd.push(
    createLoadStatus(
      loadStatusType,
      customer?.[`${loadStatusType}CUSTOMER`],
      driverId,
      prevType,
      loadAssignedDate,
      isGrayPool,
      null,
      moveId
    )
  )

  if (DRIVER_ORDER_VALIDATION[loadStatusType]?.relatedEvent && driverOrder.length > currentIndex) {
    newEventsToAdd.push(
      createLoadStatus(
        DRIVER_ORDER_VALIDATION[loadStatusType]?.relatedEvent,
        customer?.[`${loadStatusType}CUSTOMER`],
        null,
        prevType,
        loadAssignedDate,
        isGrayPool,
        null,
        null
      )
    )
    moveBreak = true
  }

  _driverOrder.splice(currentIndex, 0, ...newEventsToAdd)

  if (moveBreak) {
    _driverOrder.forEach((event, index) => {
      if (index > currentIndex) {
        delete event.driver
        delete event.loadAssignedDate
        delete event.moveId
      }
    })
  }
  return _driverOrder
}
export const getCustomerFields = (newCustomer) => {
  const status = {};
  status._id = newCustomer?.value;
  status.city = newCustomer?.allInfos?.city;
  status.state = newCustomer?.allInfos?.state;
  status.country = newCustomer?.allInfos?.country;
  status.zip_code = newCustomer?.allInfos?.zip_code;
  status.isTIROptional = newCustomer?.allInfos?.isTIROptional;
  status.geofence = newCustomer?.allInfos?.geofence;
  status.address = newCustomer?.allInfos?.address;
  status.company_name = newCustomer?.allInfos?.company_name;
  status.preferredCurrency = newCustomer?.allInfos?.preferredCurrency;
  return status;
};
export const removeEventFromLocalState = (driverOrder, eventIndex) => {
  const _driverOrder = _.cloneDeep(driverOrder)

  const currentEventType = _driverOrder?.[eventIndex]?.type;
  const releatedEventType = DRIVER_ORDER_VALIDATION[currentEventType]?.relatedEvent || "";
  let fromIndex = eventIndex;
  let totalEventsToRemove = 1;

  if (tmsDrops.includes(currentEventType) && !_driverOrder?.[eventIndex]?.isVoidOut){
      const relatedEventIndex = _driverOrder.findIndex((x,i)=> i>eventIndex && x.type === releatedEventType && !x.isVoidOut);
      
      if (relatedEventIndex !== -1){
        totalEventsToRemove = relatedEventIndex - eventIndex + 1;
      }else{
        totalEventsToRemove = _driverOrder.length - eventIndex;
      }
  }
  if (tmsHooks.includes(currentEventType)&& !_driverOrder?.[eventIndex]?.isVoidOut){
      const nextDrop = _driverOrder.findIndex((x,i)=> i>eventIndex && tmsDrops.includes(x.type) && !x.isVoidOut);
      const relatedEventIndex = findPrevValidEventIndex(_driverOrder, eventIndex);

      if (nextDrop !== -1 && relatedEventIndex !== -1) {
          fromIndex = relatedEventIndex;
          totalEventsToRemove = eventIndex - relatedEventIndex + 1;
      } else {
        totalEventsToRemove = _driverOrder.length - eventIndex;
      }
  }
  _driverOrder.splice(fromIndex, totalEventsToRemove)  
  return _driverOrder;
}

export const convertSupportMoveToDriverOrder = (supportMoves = []) => {
  if (supportMoves.length > 0) {
    supportMoves.forEach(SMove => {
      SMove.isSupportMove = true;
      SMove.loadAssignedDate = SMove?.tripAssignedDate;
      SMove.move = _.cloneDeep(SMove.tripOrder);
      SMove.nextMove = SMove?.tripOrder?.[1]?.moveId;
      SMove.nextReferenceNo = SMove?.tripOrder[1]?.reference_number;
      SMove.prevMove = SMove?.tripOrder?.[0]?.moveId;
      SMove.prevReferenceNo = SMove?.tripOrder[0]?.reference_number;
      SMove.driver = SMove?.driverId;
      SMove.move.forEach(move => {
        move.tripType = SMove?.tripType;
        move.supportMoveId = SMove?._id;
      });
      SMove.move = mapSupportMoveToRouting(SMove);
      SMove.distance = SMove.distance || SMove.move?.[1]?.distance || SMove.move?.[0]?.distance || 0;
    })
    return supportMoves;
  }
  return [];
};

export const createLegsFromDriverOrder = (driverOrders, supportMoves = []) => {
  const driverMoves = movesFromDriverOrder(driverOrders)
  if (isNewSupportMovesEnabled()) {
    supportMoves = supportMoves.filter(sMove => [TRIP_TYPES.SM_MANUAL, TRIP_TYPES.SM_SYSTEM].includes(sMove.tripType));
  }
  if(isNewSupportMovesEnabled() && supportMoves.length > 0) {
    supportMoves.forEach(SMove => {
      SMove.move = _.cloneDeep(SMove.tripOrder);
      SMove.nextMove = SMove?.tripOrder?.[1]?.moveId;
      SMove.nextReferenceNo = SMove?.tripOrder[1]?.reference_number;
      SMove.prevMove = SMove?.tripOrder?.[0]?.moveId;
      SMove.prevReferenceNo = SMove?.tripOrder[0]?.reference_number;
      SMove.driver = SMove?.driverId;
      SMove.move.forEach(move => {
        move.tripType = SMove?.tripType;
        move.supportMoveId = SMove?._id;
      });
      SMove.distance = SMove.distance || SMove.move?.[1]?.distance || SMove.move?.[0]?.distance || 0;
    })
  } else if (isSupportMovesEnabled() && supportMoves.length > 0) {
    supportMoves.forEach(SMove => {
      SMove.tripType = TRIP_TYPES.SM_SYSTEM;
    });
  }
  const _supportMoves = _.cloneDeep(supportMoves)

  const allDriverMoves = []

  let i = 0
  while (i < driverMoves.length) {
    const currentMove = driverMoves?.[i];
    const moveId = currentMove?.[0]?.moveId + "";
    const prevSupportMove = _supportMoves?.filter((sMove) => moveId && ((sMove.nextMove + "" === moveId && [TRIP_TYPES.SM_SYSTEM].includes(sMove?.tripType)) || ([TRIP_TYPES.SM_MANUAL].includes(sMove?.tripType) && sMove.tripOrder?.[0]?.moveId + "" === moveId && [loadEvents.CHASSISPICK, SUPPORT_MOVE_TO_ROUTING.CHASSIS_STARTED].includes(sMove.tripOrder?.[0].type))));
    const nextSupportMove = _supportMoves?.filter((sMove) => moveId && ((sMove.prevMove + "" === moveId && [TRIP_TYPES.SM_SYSTEM].includes(sMove?.tripType)) || ([TRIP_TYPES.SM_MANUAL].includes(sMove?.tripType) && sMove.tripOrder?.[1]?.moveId + "" === moveId && [loadEvents.CHASSISTERMINATION, SUPPORT_MOVE_TO_ROUTING.CHASSIS_STARTED, SUPPORT_MOVE_TO_ROUTING.DROPCHASSIS].includes(sMove.tripOrder?.[1].type))));
    if (prevSupportMove.length > 0) {
      prevSupportMove.forEach(move => allDriverMoves.push(mapSupportMoveToRouting(move)))
    }
    allDriverMoves.push(driverMoves[i])
    if (nextSupportMove.length > 0) {
      nextSupportMove.forEach(move => allDriverMoves.push(mapSupportMoveToRouting(move)))
    }
    i++
  }
  return allDriverMoves;
}
export const validateOrderStatus = (_driverOrder, selectedLoads) => {
  let inValidStatus = _driverOrder.find(
    (status) =>
      status.driver &&
      !status.customerId &&
      [loadEvents.CHASSISPICK, loadEvents.RETURNCONTAINER, loadEvents.CHASSISTERMINATION].indexOf(
        status.type
      ) === -1
  );
  if (inValidStatus) {
    toastr.show(
      "A valid location is required here." + inValidStatus.type,
      "error"
    );
    return false;
  }


  const fDriverIndx = _driverOrder.findIndex(
    (status) => !(status?.driver || status?.drayosCarrier) && !status.isVoidOut
  );
  if (fDriverIndx > -1) {
    _driverOrder.forEach((status, i) => {
      if (i > fDriverIndx) {
        if (!status.isVoidOut && status.driver) {
          inValidStatus = true;
        }
      }
    });
    if (inValidStatus && !isManageFleetOrOutSource().isMultiTender) {
      toastr.show(`${getDriverAndCarrierName()} is required`, "error");
      return false;
    }
  }

  if (selectedLoads?.type_of_load != "ROAD") {
    if (
      [
        loadEvents.DROPCONTAINER,
        loadEvents.LIFTOFF,
        loadEvents.RETURNCONTAINER,
        loadEvents.CHASSISTERMINATION,
      ].indexOf(_driverOrder[_driverOrder.length - 1].type) === -1
    ) {
      toastr.show(
        "Last status must be either dropped, return container or chassis termination",
        "error"
      );
      return false;
    }
  }
  return true;
};


export const getDrivers = (info, dIndex, event, drivers) => {
  let _drivers = []
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? "{}")
  let driverValidation = []
  if (userBasicSettings && userBasicSettings.driverValidation && userBasicSettings.driverValidation?.length > 0) {
    driverValidation = userBasicSettings.driverValidation
  }
  if (drivers) {
    let ddrivers = drivers
    ddrivers.forEach((d) => (d.disableValue = false))
    const isDeliveryBefore =
      info.driverOrder.findIndex((d, index) => index < dIndex && d.type === loadEvents.DELIVERLOAD) !== -1
    const isDeliveryInThisMove = event.findIndex((event) => event.type === loadEvents.DELIVERLOAD) !== -1
    const lastDropEvent = info.driverOrder[dIndex - 1]
    let eventBeforeDrop
    info.driverOrder.forEach((event, index) => {
      if (index < dIndex - 1 && !event.isVoidOut) eventBeforeDrop = event
    })
    let isLoaded =
      info?.type_of_load === IMPORT_LOAD_TYPE
        ? !isDeliveryBefore
        : info?.type_of_load === EXPORT_LOAD_TYPE
          ? isDeliveryBefore || isDeliveryInThisMove
          : false
    if (
      lastDropEvent &&
      eventBeforeDrop &&
      lastDropEvent.prevType === loadEvents.DELIVERLOAD &&
      ((lastDropEvent.customerId &&
        eventBeforeDrop.customerId &&
        _.isEqual(lastDropEvent.customerId, eventBeforeDrop.customerId)) ||
        (lastDropEvent.customerId?._id &&
          eventBeforeDrop.customerId?._id &&
          lastDropEvent.customerId._id + "" === eventBeforeDrop.customerId._id + ""))
    ) {
      if (info.isReadyForPickup) {
        isLoaded = info.type_of_load === EXPORT_LOAD_TYPE
      } else {
        isLoaded = info.type_of_load === IMPORT_LOAD_TYPE
      }
    }
    _drivers = getValidDrivers(ddrivers, driverValidation, info, isLoaded)
  } else {
    _drivers = []
  }
  return _drivers.map((d) => {
    return {
      value: d._id,
      label: `${d.name} ${d.lastName || ""}`.trim(),
      allInfos: d,
      isDisabled: d.disableValue,
      isDeleted: d.isDeleted,
      isBlocked: d.isBlocked,
    }
  })
}

export const getCarriers = (info, carrierData) => {
  let drayosCarriers = [];
  if (carrierData?.length) {
    if (showForTerminal())
      carrierData = carrierData?.filter(cd => cd.terminals?.includes(info.terminal?._id))

    let dCarriers = carrierData;
    dCarriers?.forEach((d) => d.disableValue = false)
    drayosCarriers = dCarriers;
  } 

  return drayosCarriers.map((d) => {
    return {
      value: d._id,
      label: d.company_name,
      allInfos: d,
    };
  });
 
}

// TODO: Update Driver Oder Befor API CALL
export const _tempEditDriverOder = (key, value, move, driverOrder) => {
  let _driverOrder = _.cloneDeep(driverOrder);
  if (move.length) {
    move.map((p) => {
      let _findIndex = _driverOrder.findIndex((d) => d?._id === p?._id && !d?.isVoidOut)
      if (_findIndex != -1) {
        if (key === 'driver') {
          if (value) {
            _driverOrder[_findIndex][`${key}`] = {
              isDeleted: value?.isDeleted,
              lastName: value?.allInfos?.lastName,
              name: value?.allInfos?.name,
              _id: value?.value
            }
          } else {
            delete _driverOrder[_findIndex][`${key}`]
          }
        } else if (key === 'drayosCarrier'){
          if (value) {
            _driverOrder[_findIndex][`${key}`] = {
              contactName: value?.allInfos?.company_name,
              company_name: value?.allInfos?.company_name,
              mobile: value?.allInfos?.mobile,
              MCNumber: value?.allInfos?.MCNumber,
              _id: value?.value
            }
          } else {
            delete _driverOrder[_findIndex][`${key}`]
          }
        }
      }
    })
  }
  return _driverOrder;
}
const mapSupportMoveToRouting = (sMove) => {
  if (sMove.move) {
    sMove.move.forEach((m, mIndex) => {
      m.driver = sMove.driver
      m.isSupportMove = true
        m.distance = sMove.distance || m.distance || m?.[mIndex]?.distance || 0
      if(isSupportMovesEnabled()) {
        if ([supportMoveTypes.BOBTAIL_STARTED, supportMoveTypes.CHASSIS_STARTED].includes(m.type)) {
          m.conReferenceNo = sMove.prevReferenceNo
        }
        if ([supportMoveTypes.BOBTAIL_ENDED, supportMoveTypes.CHASSIS_ENDED].includes(m.type)) {
          m.conReferenceNo = sMove.nextReferenceNo
        }
      } else {
        if (mIndex === 0) {
          m.conReferenceNo = m.reference_number
        } else {
          m.conReferenceNo = m.reference_number
        }
      }
    })
    return sMove.move
  } else {
    return
  }
}

export const getAddressdefaultOptions = (event, customers, chassisTerms, shippers, consignees) => {
  let addressdefaultOptions = []
  if (
    [
      loadEvents.RETURNCONTAINER,
      loadEvents.DROPCONTAINER,
      loadEvents.HOOKCONTAINER,
      loadEvents.LIFTOFF,
      loadEvents.LIFTON,
      loadEvents.STOPOFF,
    ].includes(event.type)
  ) {
    addressdefaultOptions = customers
  } else if ([loadEvents.CHASSISPICK, loadEvents.CHASSISTERMINATION, SUPPORT_MOVE_TO_ROUTING.CHASSIS_STARTED, SUPPORT_MOVE_TO_ROUTING.DROPCHASSIS].includes(event.type)) {
    addressdefaultOptions = chassisTerms
  } else if ([loadEvents.PULLCONTAINER].includes(event.type)) {
    addressdefaultOptions = shippers
  } else if ([loadEvents.DELIVERLOAD].includes(event.type)) {
    addressdefaultOptions = consignees
  }
  return addressdefaultOptions;
}

export const getDefaultDropLocation = async (carrierId, customer, loadDetails) => {
  let _customer = _.cloneDeep(customer)
  if (loadDetails.terminal && carrierId) {
    await getCarrierByUserId(carrierId).then((response) => {
      let details = response.data.data
      if (details?.carrier?.terminalDefaultDrop?.length > 0) {
        const terminalDefaultDrop = details.carrier.terminalDefaultDrop.find(
          (tdd) => tdd.terminal === loadDetails.terminal._id
        )
        if (terminalDefaultDrop) {
          return _customer = {
            ..._customer,
            DROPCONTAINERCUSTOMER: terminalDefaultDrop.customerId,
            LIFTOFFCUSTOMER: terminalDefaultDrop.customerId,
          }
        }
      }
    })
  } else if (loadDetails.carrier?.carrier?.defaultAddress) {
    const storedDefaultDropLocation = localStorage.getItem('defaultDropLocation');
    const stored_default_customer =  JSON.parse(storedDefaultDropLocation);
    if (stored_default_customer?._id) {
      return _customer = {
        ..._customer,
        DROPCONTAINERCUSTOMER: stored_default_customer,
        LIFTOFFCUSTOMER: stored_default_customer,
      }
    } else {
      await getDefaultAddress({
        _id: loadDetails.carrier.carrier.defaultAddress,
      }).then((dropcontainerCustomer) => {
        localStorage.setItem('defaultDropLocation', JSON.stringify(dropcontainerCustomer));
        return _customer = {
          ..._customer,
          DROPCONTAINERCUSTOMER: dropcontainerCustomer,
          LIFTOFFCUSTOMER: dropcontainerCustomer,
        }
      })
    }
    
  }

  return _customer;
}

export const validateTenderParams = (params) => {
  if(!params?.loadId) {
    return ["Load id is required."]
  }
  if(!params?.vendorId) {
    return ["Vendor id is required."]
  }
  if(!params?.moveId) {
    return ["Move id is required."]
  }
  return [null];
}
const ERROR_MESSAGES = {
  DRIVER_REQUIRED : "A Driver is required",
  INVALID_EVENT_TYPE : "Invalid event type",
  INVALID_SUPPORT_MOVE_TYPE : "Invalid support move type",
  INVALID_CUSTOMER_PROFILE : "Invalid customer profile"
}

export const manualSupportMoveValidation = (droppedIndex, driverOrder, loadStatusType) => {
  const currentEventIndex = droppedIndex >= 100 ? droppedIndex - 100 : droppedIndex;
  let currentEvent = driverOrder[currentEventIndex];
  if (currentEvent?.isVoidOut) currentEvent = driverOrder[currentEventIndex + 1];

  const currentMove = driverOrder.filter(dOrder => !dOrder?.isVoidOut && dOrder.moveId + "" === driverOrder[currentEventIndex].moveId + "");

  if (!currentEvent?.type || !currentMove?.length) return [{}, {}, ERROR_MESSAGES.INVALID_SUPPORT_MOVE_TYPE];

  if (loadStatusType === SUPPORT_MOVE_TO_ROUTING.CHASSISPICK) {
    if (![loadEvents.PULLCONTAINER, loadEvents.LIFTON].includes(currentEvent.type)) return [{}, {}, ERROR_MESSAGES.INVALID_EVENT_TYPE];
  } else if (loadStatusType === SUPPORT_MOVE_TO_ROUTING.CHASSISTERMINATION) {
    if (![loadEvents.RETURNCONTAINER, loadEvents.LIFTOFF, loadEvents.CHASSISPICK].includes(currentEvent.type)) return [{}, {}, ERROR_MESSAGES.INVALID_EVENT_TYPE];
  } else if (loadStatusType === SUPPORT_MOVE_TO_ROUTING.DROPCHASSIS) {
    if (![loadEvents.RETURNCONTAINER, loadEvents.LIFTOFF].includes(currentEvent.type)) return [{}, {}, ERROR_MESSAGES.INVALID_EVENT_TYPE];
  }
  return [currentMove, currentEvent, null];
}
export const SUPPORT_MOVES_LABEL = {
  ...statusType,
  CHASSIS_STARTED: 'Chassis Started',
  CHASSIS_ENDED: 'Chassis Ended',
  BOBTAIL_STARTED: 'Bobtail Start',
  BOBTAIL_ENDED: 'Bobtail End',
  CHASSISTRANSFER: "Chassis Transfer",
  BOBTAILTRANSFER: "Bobtail Transfer",
  CHASSISPICK: "Hook Chassis",
  CHASSISTERMINATION: 'Terminate Chassis',
  PULLCONTAINER: "Pickup Container",
  DROPCHASSIS: 'Drop Chassis'
};

export const getLableForNewSupportMove = (driverOrder) => {
  const _driverOrderTypes = driverOrder.map((move) => move.type)

  if (_driverOrderTypes.includes(SUPPORT_MOVE_EVENTS.CHASSISPICK)) return SUPPORT_MOVES_LABEL[SUPPORT_MOVE_EVENTS.CHASSISPICK]
  if (_driverOrderTypes.includes(SUPPORT_MOVE_EVENTS.CHASSISTERMINATION)) return SUPPORT_MOVES_LABEL[SUPPORT_MOVE_EVENTS.CHASSISTERMINATION]
  if (_driverOrderTypes.includes(SUPPORT_MOVE_EVENTS.DROPCHASSIS)) return SUPPORT_MOVES_LABEL[SUPPORT_MOVE_EVENTS.DROPCHASSIS]
  if (_driverOrderTypes.includes(SUPPORT_MOVE_EVENTS.BOBTAILTRANSFER)) return SUPPORT_MOVES_LABEL[SUPPORT_MOVE_EVENTS.CHASSISTERMINATION]
}

export const getRelatedEventIndex = (driverOrder, currentEventIndex) => {
  let relatedEventIndex = -1;
  const currentEventType = driverOrder[currentEventIndex].type;
  if ([loadEvents.LIFTOFF, loadEvents.DROPCONTAINER].includes(currentEventType)) {
    relatedEventIndex = driverOrder.findIndex((dOrder, dOrderIndex) => ([loadEvents.LIFTON, loadEvents.HOOKCONTAINER].includes(dOrder.type) && !dOrder.isVoidOut && dOrderIndex > currentEventIndex));
  } else if ([loadEvents.LIFTON, loadEvents.HOOKCONTAINER].includes(currentEventType)) {
    const _revDriverOrder = _.cloneDeep(driverOrder).reverse();
    const prevLiftOffIndex = _revDriverOrder.findIndex((dOrder, dOrderIndex) => ([loadEvents.LIFTOFF, loadEvents.DROPCONTAINER].includes(dOrder.type) && !dOrder.isVoidOut && dOrderIndex > (driverOrder.length - currentEventIndex - 1)));
    if (prevLiftOffIndex !== -1 && driverOrder.length - prevLiftOffIndex - 1 >= 0) {
      relatedEventIndex = driverOrder.length - prevLiftOffIndex - 1;
    }
  }
  return relatedEventIndex;
}

export const createTripOrderFromLoads = (loads) => {
  const _selectedMoves = _.cloneDeep(loads);
  let priority = _selectedMoves[0]?.driverOrder?.[0]?.priority;
  _selectedMoves.forEach((move) => {
    move.driverOrder.forEach((_moveEvent) => {
      _moveEvent.eventId = _moveEvent._id;
      _moveEvent.loadId = move._id;
      
      if (_.isNumber(priority))
        _moveEvent.priority = priority;
      else
          delete _moveEvent.priority
      
      if (_moveEvent?.customerId?._id || _moveEvent?.customerId)
      _moveEvent.customerId = _moveEvent.customerId?._id || _moveEvent.customerId;

      if (_moveEvent?.driver?._id || _moveEvent?.driver)
        _moveEvent.driver = _moveEvent.driver?._id || _moveEvent.driver;
      
      if (_moveEvent?.loadAssignedDate)
        _moveEvent.tripAssignedDate = _moveEvent.loadAssignedDate;

      delete _moveEvent._id;
      delete _moveEvent.appointmentFromTime;
      delete _moveEvent.appointmentToTime;
      delete _moveEvent.loadAssignedDate;
    });
  });
  const combinedTrip = _.flatten(_selectedMoves.map((d) => d.driverOrder.filter((d) => !d.isVoidOut)));
  return combinedTrip;
}