import React, { useEffect, useState } from "react";
import moment from "moment";
import { createLegsFromDriverOrder, legTypeToHuman } from '../Common/functions'
import { getStorage, DateTimeFormatUtils } from "../../services";
import { IconAmount, IconCheck, IconCheckCircle, IconRightMark } from "../Common/Icons";
import { getDurationInHHMMFormat } from "../../pages/tms/LoadRouting/utilities";
import { isCustomerPortalUser } from "../../utils";
const NewSummaryRoutingHistory = (props) => {
  const { selectedLoads } = props;
  const timeZone = getStorage("timeZone");
  const [permissions, setPermissions] = useState(null);
  const [driverLegs, setDriverLegs] = useState(null);
  const [legIndexes, setLegIndexes] = useState(null);
  const [measureUnit, setMeasureUnit] = useState(null);
  const [hideTracking, setHideTracking] = useState(false);
  const setting = JSON.parse(getStorage("userBasicSettings"));
  const chackPermissions = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser) {
      if (loggedInUser.role === "carrier" || loggedInUser.role === "customer" ||loggedInUser.role === "fleetcustomer") {
        return true;
      } else if (loggedInUser.permissions.length !== 0) {
        if (loggedInUser.permissions.includes("customer_service_order_status")){ 
          return true;
        }
        else {return false}
      }

      else {return false}
    }
  }


  useEffect(() => {
    const currentUser = JSON.parse(getStorage("loggedInUser"));
    if(currentUser.permissions?.length !== 0) {
      if(isCustomerPortalUser() && !currentUser.permissions.includes("customer_employee_load_summary")){
        setHideTracking(true);
      }
    }
    setPermissions(chackPermissions())
  }, [])
  useEffect(() => {
    const { driverLegs, legIndexes } = createLegsFromDriverOrder(selectedLoads?.driverOrder)
    setDriverLegs(driverLegs);
    setLegIndexes(legIndexes);
    setMeasureUnit(selectedLoads?.carrier?.carrier?.distanceMeasure)
  }, [selectedLoads]);

  return (
    <>
    {permissions && !hideTracking &&
      <div className="card p-10 mb_5">
        <h5 className="mt_3 font-14 mb-10">New Tracking</h5>
        {/* START TrackBar Design */}
        <div className="">
          {driverLegs?.map((leg, legIndex) => {
            let legColor = legIndex % 2 == 0 ? "blue" : "pink";
            let firstStatus = leg.find((status) => !status?.isVoidOut);
            let showStatus = leg[leg.length - 1]?.departed;
            if (!firstStatus) firstStatus = leg[0];
            const firstValidIndex = leg.findIndex(d => !d.isVoidOut);
            const firstValidEvent = leg?.[firstValidIndex];
            if (leg.length > 0) {
              return (
                <>
                  <div className="dlist__card dlist__distance--tracking mb-1 px-1 pt-10 shadow-none text-gray-100 border-1 rounded-lg">
                    <div className="d-flex justify-content-between align-items-center mb_4">
                      <div className="">
                        <span className={`badge bg-${legColor}-normal text-white badge-rounded font-10 font-weight-500 line-height-14 px-2 mr-1`}>
                        {showStatus && 
                        (<IconRightMark className="text-white mr-1" />)}  Move {legIndex + 1}
                        </span>
                      </div> 
                      <div>
                        </div>   
                      {firstStatus.driver && !hideTracking && (
                        <div className="d-flex align-items-center text-muted">
                          <div className="avatar-circle avatar-xs undefined text-uppercase mr-1">
                              {firstStatus.driver?.name[0]}
                          </div>
                          <h5 className="font-12 mb-0 font-weight-500">
                              {firstStatus?.driver
                                  ? `${firstStatus.driver?.name} ${
                                          firstStatus.driver?.lastName || ""
                                      }`.trim()
                                  : null}
                          </h5>
                        </div>)}
                    </div>
                    <div className="text-muted font-weight-normal font-12 mb-10">
                        {firstValidEvent?.arrived ? `${moment(firstValidEvent?.arrived)
                          .tz(timeZone)
                          .format(`${DateTimeFormatUtils.abbreviatedDateFormat()} • ${DateTimeFormatUtils.timeFormat()}`)}`
                          : "-"}
                       </div>       
                    <div className="dlist__distance">
                      {leg.map((legItem, key) => {
                        const nextValidEventIndex = leg.findIndex((d,i)=> i>key&&!d.isVoidOut);
                        const nextValidEvent = leg?.[nextValidEventIndex];
                          return (
                            
                                  <div className="dlist__distance--row d-flex " key={key}>
                                      <div className="dlist__timeframe">
                                        {(legItem?.arrived && legItem?.departed) ?   
                                          <div className={`dlist__timeframe--icon ${key === 0 ? 'mt-0' : 'mt-1'}`}>
                                          <IconCheckCircle className="wh-12px flex-shrink-0" />
                                          </div> :
                                          <div className={`dlist__timeframe--circle ${(legItem?.arrived || legItem?.departed)&& !(legItem?.arrived && legItem?.departed) ? 'border-primary-500' : "" }   ${key === 0 ? 'mt-0' : 'mt-1'}`}></div>}
                                      </div>
                                      
                                      <div className={`dlist__distance--info w-100 ${(legItem?.arrived || legItem?.departed)&& !(legItem?.arrived && legItem?.departed) ? 'border-primary-500' : "" } `}>
                                        <div className="dlist__distance--wrapper">
                                          <div className="flex-shrink-0 mb-1">
                                              <span className={`badge badge-sm text-capitalize badge-load  ${(legItem.arrived && legItem.departed) ? 'bg-gray-300 text-white' : (legItem.arrived || legItem.departed) ? 'bg-blue-500 text-white' : 'bg-gray-50 text-gray-700' } `}>
                                              {legItem?.type && legTypeToHuman(legItem?.type)}
                                              </span>
                                              {legItem?.isVoidOut && <span className="warning badge badge-sm badge-danger ml-2">Voided Out</span>}
                                          </div>
                                          <div className="d-flex align-items-start pb-1">
                                          <div className="flex-grow-1">
                                              <h5 class="mb-1 text-uppercase">
                                              <span class="text-dark font-12 font-weight-normal">
                                              {legItem?.company_name && legItem?.company_name}
                                              </span>
                                              </h5>
                                              <p class="text-muted mb-0">
                                              <span>
                                                  {legItem?.address &&
                                                      legItem.address?.address}
                                              </span>
                                              </p>
                                          </div>
                                          </div>
                                            {(legItem?.arrived || legItem?.departed )  &&
                                            <div className="d-flex align-items-center justify-content-between pb-1">
                                                <div className="w-45">
                                                    <p className="text-muted font-12 mb-0 font-weight-500">
                                                      Arrived
                                                    </p>
                                                    <div className="text-muted font-weight-normal font-12">{legItem?.departed
                                                                    ? `${moment(legItem?.departed)
                                                                    .tz(timeZone)
                                                                    .format(`${DateTimeFormatUtils.abbreviatedDateFormat()} • ${DateTimeFormatUtils.timeFormat()}`)}`
                                                                    : "-"}</div>
                                                </div>
                                                {nextValidEventIndex >=0 && <div className="mx-1">
                                                    <span className="badge bg-gray-50 font-weight-bold text-gray-900 font-10 minwidth-40px">
                                                    {nextValidEvent?.arrived ?
                                                                legItem?.departed &&
                                                                `${getDurationInHHMMFormat(
                                                                  nextValidEvent?.arrived,
                                                                  legItem?.departed,
                                                                  (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: legItem?.timeZone || timeZone})
                                                              )}` : '-'}
                                                    </span>
                                                </div>}
                                                {nextValidEventIndex >= 0 && <div className="w-45">
                                                    <p className="text-muted mb-0 font-12 font-weight-500">
                                                    Departed
                                                    </p>
                                                    <div className="text-muted font-weight-normal font-12">
                                                        {nextValidEvent?.arrived
                                                            ? `${moment(nextValidEvent?.arrived)
                                                            .tz(timeZone)
                                                            .format(`${DateTimeFormatUtils.abbreviatedDateFormat()} • ${DateTimeFormatUtils.timeFormat()}`)}`
                                                            : "-"}
                                                        </div>
                                                </div>}
                                            </div>}
                                          </div>
                                            { !legItem.isVoidOut && nextValidEvent && 
                                              <div className="dlist__hr d-flex">
                                                  <span className="badge badge-light font-12 font-weight-bold dlist__hr__time dlist__hr__time-left rounded-lg minwidth-40px outline-3">
                                                    {
                                                      (( nextValidEvent && (nextValidEvent?.arrived && nextValidEvent?.departed) ?
                                                        `${getDurationInHHMMFormat(
                                                          nextValidEvent?.departed,
                                                          nextValidEvent?.arrived,
                                                          (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: nextValidEvent?.timeZone || timeZone})
                                                      )}` : '-')
                                                      )}
                                                    </span>
                                                    {!hideTracking && (<span className="badge badge-light font-12 dlist__hr__time font-weight-bold rounded-lg minwidth-40px outline-3">
                                                    {
                                                        ( `${nextValidEvent?.defaultDistance || nextValidEvent?.distance} ${measureUnit ?? 'mi'}` || 0) 
                                                    }
                                                    </span>)}
                                              </div> 
                                            }
                                          </div>
                                  </div>
                              
                          );
                      })}
                    </div>
                  </div>
                  {(legIndex+1) < driverLegs.length && 
                          <div className="dlist__hr d-flex">
                          <span className="badge badge-light font-12 font-weight-bold" style={{height: '20px', position: 'absolute', top: "50%", transform: "translateY(-50%)"}}>
                                  {
                                      driverLegs[legIndex+1][0]?.arrived &&
                                      driverLegs[legIndex][leg.length -1]?.departed &&
                                          `${getDurationInHHMMFormat(
                                            driverLegs[legIndex+1][0]?.arrived,
                                            driverLegs[legIndex][leg.length -1]?.departed,
                                            (setting?.isUserSpecificTimeZoneEnabled && {
                                              fromTimeZone: driverLegs?.[legIndex+1]?.[0]?.timeZone || timeZone, 
                                              toTimeZone: driverLegs?.[legIndex+1]?.[0]?.timeZone || timeZone
                                            })
                                  )}`}
      
                          </span> 
                      </div>
                  }
                  
                </>
              );
            }
          })}
        </div>
        {/* END TrackBar Design */}
      </div>
    }
    </>
  );
};

export default NewSummaryRoutingHistory;
