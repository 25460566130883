import React from "react"
import { Modal } from "react-bootstrap"
import { IconWarning } from "../../../../Components/Common/Icons"

const PromptAlert = ({ showPrompt, confirm, closePrompt, content, loader, backdrop = true, modalBodyClass = "", animation = true }) => {
  return (
    <Modal show={showPrompt} onHide={closePrompt} dialogClassName="modal-sm modal-dialog-centered" backdrop={backdrop} animation={animation}>
      <Modal.Body className={`text-center pt-35 ${modalBodyClass ?? ""}`}>
        <IconWarning className={"mb-15 w-54 h-54 text-warning"} />
        <div className="font-20 font-weight-500 line-height-25 mb-15">{content}</div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary mr-10" onClick={confirm}>
          {loader && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
          Confirm
        </button>
        <button className="btn btn-outline-dark" onClick={closePrompt}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PromptAlert
