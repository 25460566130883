import moment from 'moment'
import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { DateTimeFormatUtils } from '../../../../../../services';
import InputMask from 'react-input-mask';
import DateTimePicker from './DateTimePicker';

const ExactDateWrapper = React.memo(({ defaultDate, isShowTime, onChange, OnBlurUpdate, onRemoveDate, displayTimeZone, placeholder, hideShowSelectDate, className, disabled,inputClassName, dateFormat, timeFormat, name, isLoader, componentClassName, popupPlacement, disableUptoThisday }) => {
  const [show,setShow] = useState(false)
  const inputRef = useRef()
  const inputTimeFormat = timeFormat || DateTimeFormatUtils.fullDateTimeFormat()
  const getDateInFormat = (_date) => {
    return (_date && !isShowTime)
    ? moment(_date).tz(displayTimeZone)?.format(dateFormat || DateTimeFormatUtils.fullDateFormat())
    : (_date && isShowTime)
        ? moment(_date).tz(displayTimeZone)?.format(`${inputTimeFormat}`) 
        : ""
  }
  const [inputDate,setInputDate] = useState("")

  useEffect(()=>{
    setInputDate(getDateInFormat(defaultDate))
  },[timeFormat, defaultDate])
  useEffect(() => {
    if(!defaultDate){
      setInputDate("")
    }
		const keydown = (e) => {
			if (e.keyCode === 9) setShow(false);
		}
		document.addEventListener('keydown', keydown);
		return () => {
			document.removeEventListener('keydown', keydown);
		}
	}, [defaultDate]);

  const handleInputDateChange = (_date) =>{
    setInputDate(_date)
    const isValidDate = moment(_date,inputTimeFormat,true).isValid()
    if(isValidDate){
        const UTCDate = moment(_date,inputTimeFormat);
        const _dateChanged = moment.tz(moment(UTCDate,inputTimeFormat).toString(), displayTimeZone)
        .add("minutes", moment(UTCDate).tz(displayTimeZone).utcOffset() * -1)
        .toISOString()
        onChange && onChange(_dateChanged)
    }
  }

  const handleOnBlurUpdate = ()=>{
    const _date = inputDate;
    const isValidDate = moment(_date,inputTimeFormat,true).isValid()
    if(isValidDate){
    const UTCDate = moment(_date,inputTimeFormat);
      const _dateChanged = moment.tz(moment(UTCDate,inputTimeFormat).toString(), displayTimeZone)
      .add("minutes", moment(UTCDate).tz(displayTimeZone).utcOffset() * -1)
      .toISOString()
      setInputDate(getDateInFormat(_dateChanged))
      OnBlurUpdate && OnBlurUpdate(_dateChanged)
    }
    else{
      setInputDate(null)
    }
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 13 || e.keyCode === 9){
      e.target.blur()
      handleOnBlurUpdate()
      setShow(false)
    }
  }
  const dateUtilsObj = {
    "DD/MM/YY": "99/99/99",
    "DD-MMM-YY": "99-aaa-99",
    "DD-MM-YY": "99-99-99",
    "MM/DD/YY": "99/99/99",
    "MMM-DD-YY": "aaa-99-99",
    "MM-DD-YY": "99-99-99",

    "DD/MM/YY hh:mm A": "99/99/99 99:99 aa",
    "DD-MMM-YY hh:mm A": "99-aaa-99 99:99 aa",
    "DD-MM-YY hh:mm A": "99-99-99 99:99 aa",
    "MM/DD/YY hh:mm A": "99/99/99 99:99 aa",
    "MMM-DD-YY hh:mm A": "aaa-99-99 99:99 aa",
    "MM-DD-YY hh:mm A": "99-99-99 99:99 aa",

    "DD/MM/YY HH:mm": "99/99/99 99:99",
    "DD-MMM-YY HH:mm": "99-aaa-99 99:99",
    "DD-MM-YY HH:mm": "99-99-99 99:99",
    "MM/DD/YY HH:mm": "99/99/99 99:99",
    "MMM-DD-YY HH:mm": "aaa-99-99 99:99",
    "MM-DD-YY HH:mm": "99-99-99 99:99",
  }
  function convertLetterToNine(str) { 
    return dateUtilsObj[str]
  } 

    return (
    <>
      <div ref={inputRef} onKeyDown={handleKeyDown} className={componentClassName}>
        <InputMask
          mask={convertLetterToNine(timeFormat)}
          className={`form-control loader-black-field ${name === "loadAssignDate" && "form-control-xs"} ${name === "arrivedDate" && "form-control-xss"} transition-white-field ${inputClassName ? inputClassName : "" } ${isLoader ? "loader" :""}`}
          value={inputDate || getDateInFormat(defaultDate)}
          disabled={disabled && !show}
          onChange={(e)=>handleInputDateChange(e.target.value)}
          placeholder={placeholder || "Select"}
          onFocus={()=> setShow(true)}
        />  
        </div>
        {show && <DateTimePicker 
            onRemoveDate={onRemoveDate}
            displayTimeZone={displayTimeZone}
            OnBlurUpdate={()=>{handleOnBlurUpdate()}}
            onChange={(e)=>{
                setInputDate(getDateInFormat(e))
                onChange && onChange(e)
            }}
            isShowTime={isShowTime}
            inputRef={inputRef}
            defaultDate={defaultDate
              ? moment(defaultDate).tz(displayTimeZone)?.format("lll")
              : ""}
            
            hideShowSelectDate={hideShowSelectDate}
            setShow={setShow}
            className={className}
            popupPlacement={popupPlacement}
            disableUptoThisday={disableUptoThisday}
        />}
    </>
  )
}, (prevProps, nextProps) => {
    if (prevProps.defaultDate === nextProps.defaultDate) {
      return true;
    }
    return false; 
  });

export default ExactDateWrapper