import React from 'react';
import { IconChatFill } from '../../Icons';
import { getStorage, isShiposCarrier } from '../../../../services';

// HeaderChatIconComponent accepts two properties: [messageCount: number] and [onClick?: MouseEventHandler<HTMLAnchorElement>]
// onClick is optional. When messageCount is null the message counter will not be displayed
const HeaderChatIconComponent = (props) => {
    const { onClick, messageCount, isChatPopDrop } = props;
    const isShipOsCarrier = isShiposCarrier()
    return (
        <a
            data-tip
            data-for="Chat"
            href="javascript:void(0)"
            // id="floatingChatsSideBarHandler"
            className={`nav-link position-relative ${
                isChatPopDrop === true ? "text-primary" : "text-gray-300"
            } ${isShipOsCarrier && "disabled__add__tab"}`}
            onClick={!isShipOsCarrier && onClick}
            >
            <IconChatFill />
            {messageCount > 0 && <span className="notification-icon-header">{messageCount}</span>}
        </a>
    );
};

export default HeaderChatIconComponent;
