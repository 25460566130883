import _, { cloneDeep } from "lodash";
import { mapOptionToProfile } from "../service/profileService";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getTarrifChargeProfiles } from "../service/chargeProfileService";
import { useDispatch } from "react-redux";

export const useChargeGroup = ({ initialData, onUpdate, forAdvanceCharges, resetChargeGroup }) => {
  const [filterChargeGroups, setFilterChargeGroups] = useState([]);

  const [chargeGroups, setChargeGroups] = useState([]);
  const deletedCharges = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'CLEAR_CHARGEPROFILE_FILTER' });
  }, []);

  const updateBillTo = useCallback((index, billToUpdate) => {
    const chargeGroupClone = _.cloneDeep(chargeGroups);
    if(chargeGroupClone?.[index]) {
      chargeGroupClone[index].billTo = mapOptionToProfile(billToUpdate);
      setChargeGroups(chargeGroupClone);
    }
  }, [chargeGroups]);

  const advanceFilter = useCallback((e) => {
    if(!forAdvanceCharges) return true;
    if(forAdvanceCharges && e.billTo.name === `${forAdvanceCharges}`) return true;
    return false;
  }, [forAdvanceCharges])

  const updateCharges = useCallback((index, chargeData) => {
    const chargeGroupsClone = _.cloneDeep(chargeGroups)?.filter(advanceFilter);
    if(chargeGroupsClone?.[index]) {
      chargeGroupsClone[index].oneOffCharges = chargeData?.oneOffCharges;
      chargeGroupsClone[index].chargeProfiles = chargeData?.chargeProfiles;
      chargeGroupsClone[index].chargeProfileGroups = chargeData?.chargeProfileGroups;
    }

    if(initialData) initialData.chargeGroups = chargeGroupsClone;
    setChargeGroups(chargeGroupsClone);
  }, [chargeGroups, forAdvanceCharges]);

  const addChargeGroup = useCallback((billTo) => {
    const chargeGroupsClone = _.cloneDeep(chargeGroups);
    chargeGroupsClone.push({
      billTo: billTo,
      oneOffCharges: [],
      chargeProfiles: [],
      chargeProfileGroups: [],
    });
    setChargeGroups(chargeGroupsClone);
  }, [chargeGroups, forAdvanceCharges]);

  const removeChargeGroup = useCallback((index) => {
    if(!forAdvanceCharges) {
      return setChargeGroups(chargeGroups?.filter((d,i) => i !== index) ?? []);
    }
    
    deletedCharges.current = chargeGroups?.filter((e) => {
      if(!forAdvanceCharges) return true;
      if(forAdvanceCharges && e.billTo.name === `${forAdvanceCharges}`) return true;
      return false;
    })?.find((d,i) => i === index);
    
    return setChargeGroups(chargeGroups?.filter((e) => e.billTo.name !== deletedCharges.current.billTo.name ) ?? []);
  }, [chargeGroups, forAdvanceCharges]);

  const updateData = useMemo(() => {
    return {
      chargeGroups: [
        ...(chargeGroups?.filter((e) => {
          if(!forAdvanceCharges) return true;
          if(forAdvanceCharges && e.billTo.name === `${forAdvanceCharges}`) return true;
          return false; 
        })?.map((e) => {
          return {
            billTo: e.billTo,
            oneOffCharges: e?.oneOffCharges,
            chargeProfiles: e?.chargeProfiles,
            chargeProfileGroups: e?.chargeProfileGroups,
          };
        }) ?? []),
      ].filter(Boolean),
    };
  }, [chargeGroups])

  useEffect(() => {
    if(onUpdate) {
      if(deletedCharges.current && forAdvanceCharges) {
        onUpdate(updateData, forAdvanceCharges, deletedCharges.current);
        deletedCharges.current = null;
      } else {
        onUpdate(updateData, forAdvanceCharges);
      }
    }
  }, [updateData, forAdvanceCharges]);

  useEffect(() => {
    if(!resetChargeGroup && initialData) {
      setChargeGroups(initialData?.chargeGroups);
    }
  }, [initialData, resetChargeGroup]);


  const handleApplyFilter = async (payload, isEmptyPayload) => {
    try {
      if(isEmptyPayload) return setFilterChargeGroups(initialData?.chargeGroups ?? []);

      const filterPayload = {
        vendorType: payload?.vendorType,
        tariffData: cloneDeep(initialData ?? {}),
        filterParams: payload ?? {}
      }

      delete filterPayload.tariffData._id;
      delete filterPayload.tariffData.owner;
      delete filterPayload.tariffData.updatedAt;
      delete filterPayload.tariffData.createdAt;
      delete filterPayload.filterParams.vendorType;

      const result = await getTarrifChargeProfiles(filterPayload);

      setFilterChargeGroups(result?.chargeGroups ?? []);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    chargeGroups,
    filterChargeGroups,
    addChargeGroup,
    updateCharges,
    updateBillTo,
    removeChargeGroup,
    handleApplyFilter,
  };
}