import { useCallback, useEffect, useRef, useState } from "react";
import { formatDate, formateParticipants } from "../helper";
import EmailContextMenu from "./EmailContextMenu";
import { IconAttachment } from "Components/Common/Icons";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_TABS, statusLabels } from "../constant";
import EachEmailTag from "./EmailTags/EachEmailTag";
import StarEmailToggle from "./StarEmailToggle";
import _ from 'lodash'
import { amplitudeTrack } from "../../../../services";
import ReadUnreadEmailToggle from "./ReadUnreadEmailToggle";
import SafeTextDecode from "Components/Common/SafeContent/SafeTextDecode";
import EmailTime from "./EmailTime/EmailTime";

const SingleCard = ({ 
    key, 
    index, 
    emailData, 
    activeNav, 
    allEmailStatus, 
    toggleStar, 
    loaderIds, 
    onClick, 
    rowData, 
    isOnClickEvent, 
    setActiveCardIndex,
    isSelected,
    isEmailMode ,
    isEditModeOn,
    selectedRows,
    handleCheckboxChange,
    handleBottomUnread,
    setSelectedRows,
    setRightClickKey,
    _getCounts,
    handleOnArchive,
    hanldeOnDelete,
    scrollToSelectedElement,
    setShowCreateTag,
    readUnreadIds,
    activePage,
    allTags,
    isFromLoad,
    setIsCardListFocused,
    isSentEmailTab
    }) => {
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [cellRef, setCellRef] = useState(null); 
    const [isCardHovered, setIsCardHovered] = useState(false)
    const elementRef = useRef()

    const selectedEmailIds = selectedRows?.map(mail => mail?.id + "")
    const isChecked = selectedEmailIds?.includes(emailData?.id)


    const handleSelectSingle = useCallback((e) => {
      const emails = _.cloneDeep(selectedRows)
      const { checked } = e.target
      let newSelectedEmail = []
      if (checked) {
        newSelectedEmail = [...emails, emailData]
      } else {
        newSelectedEmail = emails?.filter(mail => mail?.id !== emailData?.id)
      }
      handleCheckboxChange(false, newSelectedEmail)
    },[selectedRows])


    let debouncedFunction = _.debounce(()=>{
        if (emailData?.id + "" === rowData?.thread_id + "") return
        onClick(emailData, null, null, null, true, null, true)
      }, 1000);
    

    useEffect(() => {
        if(isSelected){
            if(!isOnClickEvent.current)debouncedFunction()
        }
        return () => {
            if(!isOnClickEvent.current) debouncedFunction.cancel();
        };
    }, [isSelected,isOnClickEvent.current]);
  
    const isSentTab = EMAIL_TABS.SENT === activeNav;
    const isDraft = activeNav === "Drafts" || emailData?.object == "draft";
    const dateToDisplay = isDraft && emailData?.date ? emailData?.date : isSentEmailTab ? (emailData?.latestMessageSentDate ?? emailData?.lastMessageTime) : emailData?.latestMessageReceivedDate
  
    const lable = emailData.status || "OPEN";
    const status = statusLabels?.find((s) => s.value === lable);
  
    const handleContextMenu = (e) => {
      e.preventDefault();
      if (e.button === 2) {
        setCellRef({ x: e.clientX, y: e.clientY });
      }
      if(!isFromLoad) {
        setShowContextMenu(true);
      }
    };
  
    const onClose = () => {
      setShowContextMenu(false);
    };

    const readHoverClass = !emailData?.unread && (isCardHovered || isSelected) && "text-defaultbrand-100"; 
    const unreadHoverClass = emailData?.unread && isCardHovered && "text-defaultbrand-700"; 

    const eventCardProperties = {
      source: AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_ROW
    }
  
    return (
      <div
        key={key}
        id={`drag-${emailData.id}`}
        className={`p-2 rounded-5 pointer  ${isSelected ? `inset-shadow-2-primary bg-brand-50` : `bg-white hover-bg-gray-25`}`}
        onClick={(e) => {
          setIsCardListFocused(true)
          if (!e.target.closest('.form-check-input')) {
          if (isSelected) return;
          setRightClickKey('')
          onClick(emailData, null, null, null, true)
          isOnClickEvent.current = true
          setActiveCardIndex(index)
          amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_OPEN, eventCardProperties);
          scrollToSelectedElement(index)
          setRightClickKey(null)
          setShowContextMenu(false)
          
        }}}
        ref={elementRef}
        onContextMenu={(e) => handleContextMenu(e)}
        onMouseEnter={()=>setIsCardHovered(true)}
        onMouseLeave={()=>setIsCardHovered(false)}
      >
        <div className="d-flex align-items-start justify-content-between mb-2 gap-10"  id={`mail-${index}`}>

            <div className="font-medium d-flex align-items-start text-truncate">
              {isEditModeOn && 
                <div className="form-check pl-0 mr_5">
                  <div
                    className="pl-0 pointer-not-allowed"
                    data-tip="Select"
                    data-for="email-table-tooltip"
                  >
                  <input
                      type="checkbox"
                      className="form-check-input ml-0"
                      checked={isChecked} // Set checkbox state based on selected rows
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectSingle(e)
                      }}
                    />
                  </div>
                </div>
              }
              <div className="text-truncate">
                <div className="d-flex align-items-center gap-3 mb_2">
                  {!isDraft && <ReadUnreadEmailToggle row={emailData} handleBottomUnread={handleBottomUnread} readUnreadIds={readUnreadIds} eventProperties={eventCardProperties} isEmailMode={isEmailMode} unreadHoverClass={unreadHoverClass} readHoverClass={readHoverClass} />}
                  {isDraft ?
                    <div className={`text-truncate font-12 ${emailData?.unread ? "font-weight-bold" : "font-weight-500"} text-dark text-capitalize`}>{"Draft"}</div> :
                    <div className={`text-truncate font-12 ${emailData?.unread ? "font-weight-bold" : "font-weight-500"} text-dark text-capitalize`}>{formateParticipants(emailData,isSentTab)}</div>
                  }
                  {emailData?.messageIds?.length  > 1 && <div className="text-muted">{emailData?.messageIds?.length}</div>}
                </div>
                
                {!!allEmailStatus.length && status && <span className={`badge badge-sm ${status?.badgeClass} ${isEditModeOn?"ml-20":""}`}>{status?.label}</span>}
              </div>
            </div>
            

          <div className="d-flex align-items-center gap-3 flex-shrink-0">
            <span className="text-gray-500">{formatDate(dateToDisplay)}</span>
            {!isDraft &&  <StarEmailToggle row={emailData} toggleStar={toggleStar} loaderIds={loaderIds} eventProperties = {eventCardProperties} />}
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          <div className={`font-medium clamp-1 text-dark font-12 ${emailData?.unread ? "font-weight-bold" : "font-weight-500"}`}>
            {emailData?.subject || "No Subject"}
          </div>
          {emailData?.snippet &&
            <div className="d-flex">
              <div className="text-muted clamp-2 text-muted font-12 font-weight-normal">
                <SafeTextDecode text={emailData?.snippet} />
              </div>
            </div>
          }
        </div>
        
        {emailData &&
          <div className="d-flex align-items-center justify-content-between mt-1 gap-5 maybe-empty">
            <EachEmailTag key={emailData.id} row={emailData} isEmailMode={isEmailMode} allTags={allTags} />
            {emailData &&
              <div className="d-flex align-items-center gap-5 ml-auto">
                {emailData?.hasAttachments &&
                  <IconAttachment className="flex-shrink-0 text-gray-300 wh-16px" />
                }
                {emailData?.timeElapsedLow && emailData?.timeElapsedMedium && emailData?.timeElapsedHigh && <EmailTime row={emailData} />}
              </div>
            }
          </div>
        }
        
  
        {showContextMenu &&
          <EmailContextMenu
            cellRef={cellRef}
            showContextMenu={showContextMenu}
            onClose={onClose}
            row={emailData}
            _getCounts={_getCounts}
            hanldeOnDelete={hanldeOnDelete}
            handleOnArchive={handleOnArchive}
            onClick={() => onClick && onClick(emailData, null, null, null, true)}
            setRightClickKey={setRightClickKey}
            setSelectedRows={setSelectedRows}
            setShowCreateTag={setShowCreateTag}
            handleBottomUnread={handleBottomUnread}
            eventProperties={{ source: AMPLITUDE_EVENTS_SOURCE.CARD_RIGHT_CLICK_MENU }}
            activePage={activePage}
          />}
      </div>
    );
  };

  export default SingleCard;