import { IconPen, IconTrash } from "Components/Common/Icons";
import { isManageFleetOrOutSource, checkUserPermisison, isFreeFlowOn, isConnectedWithOutSource, isSyncRequest, isRailOrderOn, isEmbeddedEmailEnabled, isNewBillingEnabled, isCreditMemo, isVendorPayAndBillsEnabled, isCarrierTariffEnabled, isNewDeduction, getStorage, isMarginEnabled, isGlobalizationEnabled, showCustomChargeReport, isAccountServiceEnabled, showForTerminal, isChartsEnabled, isTripOn, isDualTransactionEnabled, isDriverTariffEnabled } from "services";
import { allTabsIndexRoutes } from "../constant";
import { Organizations } from "pages/tms/constant";
import { isNewCustomerRateEnabled } from "Components/Common/functions";
import { checkAppPermission } from "../TrackAndAppointments/constant";
import { MY_APP_FEATURES } from "../../../../../constants";
import { EMBEDDED_EMAIL_SETTINGS_TAB } from "pages/tms/Email/constant";
import { isCabotageEnabled } from "../../../../../services";

export const accountSetupTabItems = () => {
  const defaultRoute = allTabsIndexRoutes.accountSetupIndexRoute

  return ([
    ...(checkUserPermisison(["settings_invoice_setup"]) ? [{
      title: "Document Designer",
      path: `${defaultRoute}/document-designer`,
    }] : []),
    ...(checkUserPermisison(["settings_group_settings"]) ? [{
      title: "Groups",
      path: `${defaultRoute}/groups`,
    }] : []),
    ...(["carrier"].includes(getStorage("currentUserRole")) && checkUserPermisison(["settings_general_info"]) ? [{
      title: "Port Markets",
      path: `${defaultRoute}/port-market`,
    }] : []),
    ...(checkUserPermisison(["other_role_management"]) ? [{
      title: "Role Management",
      path: `${defaultRoute}/role-management`,
    }] : []),
    ...(["carrier"].includes(getStorage("currentUserRole")) && checkUserPermisison(["settings_general_info"]) ? [{
      title: "Routing",
      path: `${defaultRoute}/routing`,
    }] : []),
    ...(showForTerminal() && checkUserPermisison(["settings_terminal"]) && getStorage("currentUserRole") === "carrier"
      ? [
        {
          title: "Terminals",
          path: `${defaultRoute}/terminals`,
        },
      ]
      : []),
    ...(checkUserPermisison(["other_permission"]) ? [{
      title: "Users & Permissions",
      path: `${defaultRoute}/users`
    }] : []),
    ...(
      isCabotageEnabled() ? [
        {
          title: "Cabotage",
          path: `${defaultRoute}/cabotage`
        }
      ] : []
    )
  ])
};

export const groupSettingsTabItems = () => ([
  {
    id: 1,
    title: "Customer Group Settings",
    path: `${allTabsIndexRoutes.accountSetupIndexRoute}/groups`,
  },
  {
    id: 2,
    title: "Cities Settings",
    path: `${allTabsIndexRoutes.accountSetupIndexRoute}/groups/cities`,
  },
  {
    id: 3,
    title: "Postal / Zip Codes",
    path: `${allTabsIndexRoutes.accountSetupIndexRoute}/groups/zipcode`,
  },
  ...(isManageFleetOrOutSource().isOutSource ? [
    {
      id: 4,
      title: "Carrier Group Settings",
      path: `${allTabsIndexRoutes.accountSetupIndexRoute}/groups/carrier`,
    }
  ] : []),
]);

export const roleManagementColumnNames = {
  ROLES: "Roles"
}

export const routingHeaders = {
  TERMINAL_DROPS: "Terminal Drops",
  DEFAULT_DROP: "Default Drop",
  STOPOFF: "Stop Off",
  OPTION: "Option"
}

export const usersAndPermissionsColumnHeaders = ["Name", "System Roles", "Custom Roles", "Email", "Phone"]

export const DEFAULT_DROP_LABEL = "Automatically add a drop location when a drop event is added to a load."

export const BRANCH_DROP_LABEL = "Select a Drop location for each Terminal. If a Terminal Drop is blank, the drop event for a Load with that Terminal selected, will use the Default Drop."

export const STOPOFF_LABEL = "Create routing event options that can be used in load routing. (Eg. Fuel Stops)"

export const roleManagementColumns = ({ _deleteRole, handleEdit }) => {
  const column = [
    {
      key: "role",
      name: roleManagementColumnNames.ROLES,
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-500">{row?.roleName}</div>;
      },
    },
    {
      key: "edit",
      name: "",
      width: 50,
      minWidth: 50,
      formatter: ({ row }) => {
        return (
          <div className="font-12 w-100 d-flex justify-content-center">
            <button onClick={() => handleEdit(row)} type="button" className="btn btn-link text-muted">
              <IconPen />
            </button>
          </div>
        );
      },
    },

    {
      key: "delete",
      name: "",
      width: 50,
      minWidth: 50,
      formatter: ({ row }) => {
        return (
          <div className="font-12 w-100 d-flex justify-content-center">
            <button onClick={() => _deleteRole(row?._id)} type="button" className="btn btn-link text-muted">
              <IconTrash />
            </button>
          </div>
        );
      },
    },
  ]

  return column
}

const communicationTabPermissions = {
  communication_emails: "Emails",
  communication_messaging: "Messaging",
};

const loadInfoCommunicationTab = () => {
  return {
    customer_service_messaging: isEmbeddedEmailEnabled() ? "Communication" : "Messaging",
    ...(isEmbeddedEmailEnabled() && communicationTabPermissions),
  }
};

const embeddedEmailInboxPermissions = {
  connect_personal_mail: "Connect Personal Mailbox",
  manage_company_views: "Manage Company Views"
}

const embeddedEmail = {
  'embedded_email_mailbox': EMBEDDED_EMAIL_SETTINGS_TAB.COMPANY_MAILBOX,
  'embedded_email_rules': EMBEDDED_EMAIL_SETTINGS_TAB.RULES,
  'embedded_email_tags': EMBEDDED_EMAIL_SETTINGS_TAB.TAGS,
};

const driverDeductionPermissions = {
  driver_deduction_approve: "Approve",
  driver_deduction_allow_delete: "Allow Delete",
  driver_deduction_adjust: "Adjust",
};

const billingPermissions = {
  billing_invoice_edit_permission: "Edit",
  billing_invoice_send_email_permission: "Send",
  billing_invoice_approve_permission: "Approve",
  billing_invoice_permission: "Invoiced",
  billing_invoice_download_permission: "Download",
  billing_invoice_delete_permission: "Delete",
};

const invoicePermissions = {
  invoice_void_invoice: "Void",
};
const creditMemoPermissions = {
  credit_memo_edit_permission: "Edit",
  credit_memo_send_email_permission: "Send",
  credit_memo_approve_permission: "Approve",
  credit_memo_invoice_permission: "Invoiced",
  credit_memo_download_permission: "Download",
  credit_memo_delete_permission: "Delete",
};

const dynamicSettlementPermissions = {
  billing_driver_settlement_review: "Review",
  billing_driver_settlement_Finalize: "Finalize",
};

const newSettlementDriverPayPermission = {
  billing_driver_approve: "Approve",
  billing_driver_allow_delete: "Allow Delete",
  billing_driver_adjust: "Adjust",
};

const driverExpensePermission = {
  driver_expense_adjust: "Edit Expense",
};

const driverPayPermissions = () => ({
  ...newSettlementDriverPayPermission,
  ...(isNewBillingEnabled() && driverExpensePermission),
});

const customerProfilePermissions = {
  customer_profile_customers: "Customers",
  customer_profile_shipper: "Shipper/Port",
  customer_profile_consignee: "Consignee",
  customer_profile_chassis: "Chassis",
};

const accountSetup = {
  settings_invoice_setup: "Document Designer",
  settings_group_settings: "Groups",
  other_role_management: "Role Management",
  other_permission: "Users & Permissions",
};

const companyPreferences = {
  settings_general: "Configuration",
  settings_load_status_colors: "Dispatcher Load Board",
};

const equipment = {
  settings_chassis: "Chassis",
  settings_commodity: "Commodity List",
  settings_container: "Container",
  settings_equipment_params: "Parameters",
  'settings_validations': 'Validations'
};

const accounting = () => ({
  ...(["carrier", "fleetmanager"].includes(getStorage("currentUserRole")) && {
    settings_billing_configuration: `${isNewBillingEnabled() ? "Accounting" : "Billing"} Configuration`,
  }),
  "accounting_options": "Accounting Options",
  ...(JSON.parse(getStorage("userBasicSettings"))?.isVAT && { "dynamic-tax": "Dynamic Tax" }),
  settings_expense: "Expenses",
});

const connections = () => ({
  ...(isConnectedWithOutSource() && isSyncRequest() && { settings_connect_configuration: "Connect Configuration" }),
  ...(["carrier", "fleetmanager"].includes(getStorage("currentUserRole")) && {
    settings_developer: "Developer Settings",
  }),
  settings_my_apps: "My Apps",
});

const financeApps = {
  'quickbooks_settings': 'Quickbooks Settings'
}

const email = {
  settings_email_configuration: "Configuration",
  expiry_notifications: "Expiry Notifications",
  'records_settings': 'Records',
  'template_settings': 'Template',
  'template_group_settings': 'Template Group'
};

const trackingAndAppointments = () => ({
  ...(checkAppPermission(MY_APP_FEATURES.APPOINTMENT) && { appointment_list: "Appointment List" }),
  ...(checkAppPermission(MY_APP_FEATURES.APPOINTMENT) && { appointment_settings: "Appointment Settings" }),
  "container-tracking-credentials": "Container Tracking Credentials",
  "container-tracking": "Container Tracking Settings",
});

const securityFieldCsv = {
  security_fields_csv: "Can see Security Fields in CSV"
}
export const mainSubPermissions = () => ({
  billing_driver_pay: driverPayPermissions(),
  driver_deduction_show: driverDeductionPermissions,
  billing_driver_settlement: dynamicSettlementPermissions,
  billing_account_receivable: billingPermissions,
  invoice_account_receivable: invoicePermissions,
  credit_memo: creditMemoPermissions,
  other_profile_customer: customerProfilePermissions,
  account_setup: accountSetup,
  company_preferences: companyPreferences,
  equipment: equipment,
  accounting: accounting(),
  connections: connections(),
  email: email,
  tracking_and_appointments: trackingAndAppointments(),
  customer_service_messaging: communicationTabPermissions,
  finance_apps: financeApps,
  embedded_email_inbox: embeddedEmail,
  other_profile_driver: securityFieldCsv
});

export const roles = () => {
  return {
    ...(isManageFleetOrOutSource()?.isManageFleet && { tracking: { tracking: "Tracking" } }),
    dispatcher: {
      dispatcher: "Dispatcher",
      ...(isManageFleetOrOutSource()?.isManageFleet && { dispatcher_driver_itinerary: "Driver Itinerary" }),
      ...(isDualTransactionEnabled() ? { dispatcher_double_moves: "Dual Transactions" } : { dispatcher_double_moves: "Double Moves" }),
      dispatcher_street_turns: "Street Turns",
      dispatcher_problem_container: "Problem Container",
      dispatcher_page_edit: "Edit Dispatcher Page",
      ...(isTripOn() && { load_free_flow: "Trips" }),
      ...(isConnectedWithOutSource() && isSyncRequest() && { dispatcher_connect_notices: "Connect Notices" }),
      ...(isRailOrderOn() && { load_rail_order: "Load Rail Order" }),
    },
    customer_service: {
      customer_service_dashboard: "Dashboard",
      customer_service_load_info: "Loads",
      customer_service_quotes: "Quotes",
      customer_service_dropped_container: "Yard Check",
      customer_service_tendered_loads: "Tendered Loads",
      customer_service_load_calendar: "Load Calendar",
      customer_service_bulk_upload: "Bulk Uploads",
      ...(isManageFleetOrOutSource()?.isManageFleet && { customer_service_drive_messaging: "Driver Messaging" }),
      customer_service_employee_messaging: "Employee Messaging",
      customer_service_group_messaging: "Group Messaging",
      customer_service_customer_messaging: "Customer Messaging",
      ...(isManageFleetOrOutSource()?.isManageFleet && {
        customer_service_driver_group_messaging: "Driver Group Chat",
      }),
      customer_service_deleted_load: "Deleted Load",
    },
    dashboard: {
      customer_service_load_info: "Loads",
      customer_service_billing: "Billing",
      customer_service_documents: "Documents",
      customer_service_payments: "Payments",
      customer_service_order_status: "Routing",
      ...(isManageFleetOrOutSource()?.isManageFleet && { customer_service_driver_pay: "Driver Pay" }),
      ...((isManageFleetOrOutSource()?.isManageFleet || isManageFleetOrOutSource()?.isOutSource) && {
        customer_service_tracking: "Tracking",
      }),
      ...loadInfoCommunicationTab(),
      customer_service_audit: "Audit",
      customer_service_notes: "Notes",
      ...(isManageFleetOrOutSource()?.isOutSource && { post_tender_carrier_removal: "Post Tender Carrier Removal" }),
    },
    accounts_receivable: {
      ...(isNewBillingEnabled() && { billing_account_receivable: "Billing", ...billingPermissions }),
      ...(!isNewBillingEnabled() && { billing_account_receivable: "Accounts Receivable" }),
      ...(isNewBillingEnabled() && { invoice_account_receivable: "Invoices", ...invoicePermissions }),
      ...{ billing_batch_apply_payment: "Apply Payment" },
      ...(!isNewBillingEnabled() && { billing_subaccount_receivable: "Sub Account Receivable" }),
      reports_aging: "Aging",
      billing_chassis_validation: "Chassis Audit",
      billing_problem_invoices: "Problem Invoices",
      ...(isCreditMemo() && { credit_memo: "Credit Memo", ...(isNewBillingEnabled() && creditMemoPermissions) }),
      waiting_time_audit: "Waiting Time Audit",
    },
    accounts_payable: {
      ...(isManageFleetOrOutSource()?.isManageFleet && { billing_driver_pay: "Driver Pay" }),
      ...(isManageFleetOrOutSource()?.isOutSource && { billing_driver_pay: "Carrier Pay" }),
      ...{ driver_deduction_show: "Deduction", ...driverDeductionPermissions },
      ...(isManageFleetOrOutSource()?.isManageFleet && { billing_driver_settlement: "Driver Settlements" }),
      ...(isManageFleetOrOutSource()?.isManageFleet && { dynamic_dynamic_expense: "Dynamic Expense" }),
      ...dynamicSettlementPermissions,
      ...driverPayPermissions(),
      vendor_problem_bills: "Problem Sync",
    },
    ...(isVendorPayAndBillsEnabled() && {
      vendor_pay_and_bill: {
        billing_vendor_pay: "Vendor Pays",
        billing_vendor_bills: "Vendor Bills",
        billing_vendor_payment: "Vendor Payment",
      },
    }),
    ...(isManageFleetOrOutSource()?.isManageFleet && {
      drivers: {
        other_profile_driver: "Driver Profile",
        other_assign_truck: "Truck Assignments",
        safety_truck_owner: "Truck Owners",
        other_driver_pay_rate: "Driver Pay Rates",
        billing_settlement_setting: "Driver Deduction Rates",
        ...(!isDriverTariffEnabled() && {other_dynamic_driver_pay: "Dynamic Driver Pay"}),
        ...securityFieldCsv
      },
    }),
    ...(isManageFleetOrOutSource()?.isOutSource && {
      carriers: {
        other_profile_driver_shipos: "Carrier Profile",
        other_driver_pay_rate: isCarrierTariffEnabled() ? "Tariff" : "Carrier Pay Rates",
      },
    }),
    ...(isManageFleetOrOutSource()?.isManageFleet && {
      equipments: {
        other_profile_truck: "Truck Profiles",
        other_profile_chassis: "Chassis Profiles",
        other_profile_trailer: "Trailer Profiles",
      },
    }),
    [isEmbeddedEmailEnabled() ? Organizations : "customers"]: {
      other_profile_customer: isEmbeddedEmailEnabled() ? Organizations : "Customer Profile",
      ...(isEmbeddedEmailEnabled() && { other_people: "People" }),
      ...customerProfilePermissions,
      ...(!isNewCustomerRateEnabled()
        ? {
            other_group_customer: "Group Customer",
            other_customer_rate: "Customer Rate",
            other_dynamic_pricing: "Dynamic Pricing",
            other_customer_discount: "Customer Discount",
          }
        : {
            other_customer_rate: "Tariff",
          }),
    },
    reports: {
      ...(isManageFleetOrOutSource()?.isManageFleet && { reports_aging: "Aging" }),
      "reports_master_load-master-report": "Load Master Report",
      reports_pricing_breakdown: "Pricing Breakdown",
      ...(isManageFleetOrOutSource()?.isManageFleet
        ? {
            reports_admin_dashboard: "Admin Dashboard",
            reports_admin_dashboard_2: "Admin Dashboard 2",
            reports_clock_report: "Clock Report",
            reports_charge_report: "Charge Report",
            reports_charge_report_2: "Charge Report 2",
            reports_charge_report_3: "Charge Report 3",
            reports_charge_report_4: "Charge Report 4",
            reports_customer_revenue: "Customer Revenue",
            reports_driver_pay: "Driver Pay",
            reports_driver_pay_summary: "Driver Pay Summary",
            reports_driver_revenue: "Driver Revenue",
            reports_forecast_report: "Forecast Report",
            reports_new_driver_revenue: "New Driver Revenue",
            reports_leg_type: "Leg Type",
            reports_lfd_report: "LFD Report",
            reports_loads_report: "Loads Report",
            reports_loads_report_pricing: "Load Report Pricing",
            reports_productivity_report: "Productivity Report",
            reports_productivity_report_2: "Productivity Report 2",
            reports_return_report: "Return Report",
            reports_revenue_report: "Revenue Report",
            reports_status_driver: "Status Driver",
            reports_summary_report: "Summary Report",
            ...(JSON.parse(getStorage("carrierDetail") ?? "{}")?.isSupportMoveEnabled && {
              "support-move-report": "Support Move Report",
            }),
            ...(isNewDeduction() && { "deduction-report": "Deduction Report" }),
            reports_vessel_report: "Vessel Report",
            reports_profitability_report: "Profitability Report",
            reports_chassis_history: "Chassis History",
            reports_clock_in_out: "ClockIn-ClockOut Report",
            reports_external_charge_report: "External Charge Report",
            email_audit_report: "Email Audit Report",
            ...(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.HWBImportReportEnabled && {
              report_HWB: "HWB Import Report",
            }),
            on_time_performance_report: "On-Time Performance Report",
            ...(isCreditMemo() && { reports_credit_memo_report: "Credit Memo Report" }),
          }
        : {}),
        ...(isManageFleetOrOutSource()?.isOutSource && { 
          "carrier_transaction_report": "Carrier Transaction Report",
          "vendorpay_breakdown_report": "Vendor Pay Breakdown"
        }),      
      ...(isNewBillingEnabled() && { report_expense: "Expense Report" }),
      ...(showCustomChargeReport() && {
        reports_import_charge: "Import Charge Report",
        reports_export_charge: "Export Charge Report",
      }),
    },
    ...(isEmbeddedEmailEnabled() && {
      embedded_email: embeddedEmailInboxPermissions
    }),
    settings: {
      ...{ account_setup: "Account Setup", ...accountSetup },
      ...{ accounting: "Accounting", ...accounting() },
      ...{ company_preferences: "Company Preferences", ...companyPreferences },
      ...{ connections: "Connections", ...connections() },
      ...{ email: "System Generated Emails", ...email },
      ...(isEmbeddedEmailEnabled() && { embedded_email_inbox: "Embedded Email Inbox", ...embeddedEmail }),
      ...{ equipment: "Equipment", ...equipment },
      ...(isAccountServiceEnabled() && { finance_apps : "Finance", ...financeApps}),
      ...{ tracking_and_appointments: "Tracking & Appointments", ...trackingAndAppointments() },
    },

    other: {
      deleteloads: "Delete Loads",
      rebill_loads: "Rebill Loads",
      customer_credit_limit: "Customer Credit Limit",
      customer_payment_terms: "Customer Payment Terms",
      print_all_docs: "Print all Docs",
      approve_invoice: "Can Invoice a Bill",
      sale_tax_exempt_applicable: "Sale Tax Exempt/Applicable",
      enable_deleted_loads: "Enable Deleted Loads",
      map_eld_profiles: "Map ELD Profiles",
      ...(isNewBillingEnabled() && isMarginEnabled() && { show_load_margin: "View Load Margin" }),
      ...(isChartsEnabled() && {"charts_dashboard": "Charts Dashboard"}), 
      allow_user_to_assign_all_terminals:'Allow all terminals to be assigned to profiles'
    },
  };
};

const permissionCheckForCheckbox = (permissionToCheck, permission) => {
  if(permissionToCheck[permission]){
    return true;
  }
  return false;
}

export const permissionCheck = (d) => {
  return (
    d !== "security_fields_csv" &&
    d !== "accounting_options" &&
    !permissionCheckForCheckbox(customerProfilePermissions, d) &&
    !permissionCheckForCheckbox(dynamicSettlementPermissions, d) &&
    !permissionCheckForCheckbox(driverDeductionPermissions, d) &&
    !permissionCheckForCheckbox(billingPermissions, d) &&
    !permissionCheckForCheckbox(driverPayPermissions(), d) &&
    !permissionCheckForCheckbox(creditMemoPermissions, d) &&
    !permissionCheckForCheckbox(invoicePermissions, d) &&
    !permissionCheckForCheckbox(accountSetup, d) &&
    !permissionCheckForCheckbox(financeApps, d) &&
    !permissionCheckForCheckbox(companyPreferences, d) &&
    !permissionCheckForCheckbox(equipment, d) &&
    !permissionCheckForCheckbox(accounting(), d) &&
    !permissionCheckForCheckbox(connections(), d) &&
    !permissionCheckForCheckbox(email, d) &&
    !permissionCheckForCheckbox(trackingAndAppointments(),d) &&
    !permissionCheckForCheckbox(communicationTabPermissions, d) &&
    !permissionCheckForCheckbox(embeddedEmail, d)
  )
}

export const disableChildPermissions = (permissionToCheck, permissions) => {
  if(!permissions?.length || !permissionToCheck) return true;
  if(permissions?.includes(permissionToCheck)){
    return false;
  }
  return true;
}

export const chassisFlags = ["other_chassis_owner", "other_chassis_size", "other_chassis_type"]
export const containerFlags = ["other_container_owner", 'other_container_size', 'other_container_type',"other_container_free_day"]
export const containerAndChassisSettingPermissions = [...chassisFlags, ...containerFlags]

export const PORT_MARKET_LABEL = "Port Market"

export const unNecessarySettingsGroupList = ["account_setup", "accounting", "company_preferences","connections","email", "embedded_email_inbox","embedded_email","equipment","finance_apps","tracking_and_appointments", "notification_settings",];

const updatePermissionsWithFlags = (permissions, basePermission, flags) => {
  const updatedPermissions = [...permissions];

  if (updatedPermissions.includes(basePermission)) {
    // Add flags if they aren't already present
    flags.forEach(flag => {
      if (!updatedPermissions.includes(flag)) {
        updatedPermissions.push(flag);
      }
    });
  } else {
    // Remove flags if present
    flags.forEach(flag => {
      const index = updatedPermissions.indexOf(flag);
      if (index !== -1) {
        updatedPermissions.splice(index, 1);
      }
    });
  }

  return updatedPermissions;
};

export const updatePermissionsForChassisAndContainer = (permissions) => {
  // Process chassis flags
  let updatedPermissions = updatePermissionsWithFlags([...permissions], "settings_chassis", chassisFlags);

  // Process container flags
  updatedPermissions = updatePermissionsWithFlags(updatedPermissions, "settings_container", containerFlags);

  return updatedPermissions.filter(permission => !['settings_chassis', 'settings_container'].includes(permission));
};
