import moment from "moment";
import _ from "lodash";
import { getStorage, isDualTransactionEnabled } from "../../../../services";
import config from "../../../../config";
import { loadEvents, TRIP_TYPES } from "pages/tms/constant";
const userTimeZone = () => getStorage("timeZone") ?? moment.tz.guess();

export const DRIVERPLANNERDATERANGE = () => ({
  Today: [moment().tz(userTimeZone()).startOf("day"), moment().tz(userTimeZone()).endOf("day")],
  Tomorrow: [
    moment().tz(userTimeZone()).subtract(-1, "days").tz(userTimeZone()).startOf("day"),
    moment().tz(userTimeZone()).subtract(-1, "days").tz(userTimeZone()).endOf("day"),
  ],
  Weekly: [
    moment().tz(userTimeZone()).startOf("isoWeek"),
    moment().tz(userTimeZone()).startOf("isoWeek").add(6, "days")
  ]
})

export const mapTripToLoad = (trip) => {
  const driverOrders = [];

  for (const tripOrder of trip?.tripOrder) {
    if (!tripOrder) {
      continue;
    }
    tripOrder['loadAssignedDate'] = tripOrder?.tripAssignedDate;
    let driverOrder = JSON.parse(JSON.stringify(tripOrder));
    const customerId = JSON.parse(JSON.stringify(driverOrder?.customerId || {}) ) ?? {};
    delete customerId?._id;
    driverOrder = {
      ...driverOrder,
      ...customerId,
    }

    driverOrders.push(driverOrder);
  }
  let isChassisForTrip = driverOrders.some((order) => { return order.type === "CHASSISPICK"})
  const mappedTrip = {
    _id: trip?._id,
    updatedAt: trip?.updatedAt,
    createdAt: trip?.createdAt,
    type_of_load: trip?.orderId?.type_of_load || trip?.type_of_trip,
    status: trip?.status,
    reference_number: trip?.tripNumber,
    callerInfo: trip?.orderId?.callerInfo,
    shipper: trip?.orderId?.shipperInfo,
    isDeleted: trip?.isDeleted,
    isActive: trip?.isActive,
    isAccept: trip?.isAccept,
    isTripStartable: trip?.isTripStartable,
    isTrip: true,
    orderId: trip?.orderId?._id,
    containerSizeName: trip?.orderId?.containerSize?.name,
    containerSize: trip?.orderId?.containerSize,
    callerbillLandingNo: trip?.orderId?.callerbillLandingNo,
    driver: driverOrders.find(el=>!el.isVoidOut)?.driverId??trip.driverId,
    driverOrder: driverOrders,
    isChassisForTrip : isChassisForTrip,
    priority:driverOrders.find(el=>!el.isVoidOut)?.priority,
    loadAssignedDate :driverOrders.find(el=>!el.isVoidOut)?.tripAssignedDate,
    isVoidOut:trip?.isVoidOut,
    isCombinedTrip:[TRIP_TYPES.COMBINED].includes(trip?.tripType) || false,
    combinedMoves:trip?.combinedMoves,
    isSupportMove: [TRIP_TYPES.SM_MANUAL, TRIP_TYPES.SM_SYSTEM].includes(trip?.tripType) || false,
    supportMoveType: [TRIP_TYPES.SM_MANUAL, TRIP_TYPES.SM_SYSTEM].includes(trip?.tripType) ? trip?.tripType : null,
    tripType: trip?.tripType,
    destinationETA: trip?.destinationETA ?? null,
    terminal: trip?.terminal ?? null
  };

  if(trip?.chassisId) {
    mappedTrip.chassisId = trip?.chassisId?._id ?? trip?.chassisId
    if(trip?.chassisId?._id) {
      mappedTrip.chassisIdName = trip?.chassisId?.chassisNo;
      mappedTrip.chassisNo = trip?.chassisId?.chassisNo;
    }
  }
  return mappedTrip;
}
export const MOVE_STATUS_STARTED = {
  DROPCONTAINER: "BOBTAIL_STARTED",
  RETURNCONTAINER: "CHASSIS_STARTED",
  CHASSISTERMINATION: "BOBTAIL_STARTED",
  LIFTOFF: "CHASSIS_STARTED",
}
export const MOVE_STATUS_ENDED = {
  CHASSISPICK: "BOBTAIL_ENDED",
  PULLCONTAINER: "CHASSIS_ENDED",
  HOOKCONTAINER: "BOBTAIL_ENDED",
  LIFTON: "CHASSIS_ENDED",
}

export const isCombineTripEnabled = () => {
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings"));
  return userBasicSettings?.isMultiContainerEnabled;
}

export  const checkLoadTerminalForFM=(loadDetail)=>{
  const currentUser = JSON.parse(getStorage("loggedInUser"))
  if (getStorage("currentUserRole") == "fleetmanager" &&
    config?.idForTerminal.indexOf(currentUser?.fleetManager?.carrier) != -1
  ) {
    if (loadDetail?.terminal && currentUser?.fleetManager.terminals && currentUser?.fleetManager?.terminals.length > 0) {
      if (currentUser?.fleetManager?.terminals.indexOf(loadDetail?.terminal?._id+"") ===-1) return false ;
    }
  }
  return true
}
export const REQUIRED_TRIP_PARAMS = ['reference_numbers','tripOrder','tripOrderId', 'combineTripType']
export const TRIP_ORDER_PARAMS = ['eventId','customerId','isVoidOut','driver','type',
                                  'prevType','distance','address','city','state','country',
                                  'zip_code','company_name','tripAssignedDate','isBobtail',
                                  'isGrayPool','priority','moveId','loadId','defaultDistance', 'stopOffType', 'stopOffTypeName', 'timeZone']
export const REQUIRED_TRIP_RE_ARRANGE_PARAMS = ['tripOrderId','tripOrder']
export const REQUIRED_TRIP_RE_ARRANGE_ORDER_PARAMS = ['eventId','customerId','isVoidOut','driver','type',
                                  'prevType','distance','address','city','state','country','reference_number',
                                  'zip_code','company_name','tripAssignedDate','isBobtail',
                                  'isGrayPool','priority','moveId','loadId','defaultDistance', '_id', 'stopOffType', 'stopOffTypeName'];

export const SUPPORT_MOVE_TYPES = {
  "MANUAL": "MANUAL",
  "SYSTEM": "SYSTEM"
}

export const sortDriverPlannerMoves = (driverLoads) => {
  driverLoads = driverLoads?.sort((a, b) => {
    let driverOrderA = a?.driverOrder ? a.driverOrder.find((dOrder) => !dOrder.isVoidOut) : a.tripOrder.find((dOrder) => !dOrder.isVoidOut);
    let driverOrderB = b?.driverOrder ? b.driverOrder.find((dOrder) => !dOrder.isVoidOut) : b.tripOrder.find((dOrder) => !dOrder.isVoidOut);
    return moment(driverOrderA.loadAssignedDate ?? driverOrderA.tripAssignedDate).diff(moment(driverOrderB.loadAssignedDate ?? driverOrderB.tripAssignedDate)) || driverOrderA.priority - driverOrderB.priority;
  });

  if (isDualTransactionEnabled()) {
    const combinedTripIds = [];
    const newDriverLoads = [];
    driverLoads?.forEach(item => {
      // Check driverOrder for dualTransactions
      const hasDualTransaction = item?.driverOrder?.some(order => order?.isDualTransaction && !order?.isVoidOut);
      const reference_number = item?.reference_number;
      if (hasDualTransaction) {
        const firstEvent = item?.driverOrder?.find(order => order?.isDualTransaction && !order?.isVoidOut);
        // If this reference number is not yet in the output, add it
        if (firstEvent?.dualTransactionTo) {
          const combineTripId = firstEvent?.combineTripId?._id || firstEvent?.combineTripId;
          if (!combinedTripIds.includes(combineTripId + "")) {
            combinedTripIds.push(combineTripId + "");
            newDriverLoads.push(item);
            const toLoad = driverLoads?.find(load => load?.driverOrder[0]?.dualTransactionFrom && load?.reference_number === firstEvent?.dualTransactionTo?.reference_number);
            if (toLoad?.driverOrder?.find(dOrder => !dOrder?.isVoidOut && dOrder?.dualTransactionFrom?.reference_number === reference_number)) {
              newDriverLoads.push(toLoad);
            }
          }
        } else if (firstEvent?.dualTransactionFrom) {
          const combineTripId = firstEvent?.combineTripId?._id || firstEvent?.combineTripId;
          if (!combinedTripIds.includes(combineTripId + "")) {
            combinedTripIds.push(combineTripId + "");
            const fromLoad = driverLoads?.find(load => load?.driverOrder[0]?.dualTransactionTo && load?.reference_number === firstEvent?.dualTransactionFrom?.reference_number);
            if (fromLoad?.driverOrder?.find(dOrder => !dOrder?.isVoidOut && dOrder?.dualTransactionTo?.reference_number === reference_number)) {
              newDriverLoads.push(fromLoad);
            }
            newDriverLoads.push(item);
          }
        } else {
          newDriverLoads.push(item);
        }
      } else {
        newDriverLoads.push(item);
      }
    });
    driverLoads = newDriverLoads;
  }

  return driverLoads;
}

export const sortLoadsAndSupportMove = (driverLoads) => {
  if (!driverLoads?.length) return [];
  driverLoads = sortDriverPlannerMoves(driverLoads);

  const { supportMoves, loads } = driverLoads?.reduce((acc, load) => {
      if (load?.isSupportMove && load?.loadId) {
          acc.supportMoves.push(load);
      } else {
          acc.loads.push(load);
      }
      return acc;
  }, { supportMoves: [], loads: [] });

  const newDriverLoads = [];
  loads.forEach(load => {
      const firstValidEvent = load.driverOrder.find(dOrder => !dOrder?.isVoidOut);
      const loadsSupportMove = supportMoves.filter(SM => SM.loadId + "" === load._id + "" && firstValidEvent.moveId + "" === SM.driverOrder[0].moveId + "");
      if (loadsSupportMove.length > 0) {
          const { hookChassisMove, terminateChassis, remainingMove } = loadsSupportMove.reduce((acc, SM) => {
              if (SM.driverOrder[0].type === loadEvents.CHASSISPICK) {
                  acc.hookChassisMove.push(SM);
              } else if (SM.driverOrder[1].type === loadEvents.CHASSISTERMINATION) {
                  acc.terminateChassis.push(SM);
              } else if (SM.tripType === TRIP_TYPES.SM_MANUAL){
                  acc.remainingMove.push(SM);
              }
              return acc;
          }, { hookChassisMove: [], terminateChassis: [], remainingMove: [] });

          if (hookChassisMove.length) newDriverLoads.push(...hookChassisMove);
          newDriverLoads.push(load);
          if (terminateChassis.length) newDriverLoads.push(...terminateChassis);
          if (remainingMove.length) newDriverLoads.push(...remainingMove);
      } else {
          newDriverLoads.push(load);
      }
  })
  return newDriverLoads;
}

export const DRIVER_PLANNER_VIEW_MODE = {
  DETAILED: "DETAILED",
  COMPACT: "COMPACT",
  LIST: "LIST",
}
export const FILTER_TYPE = {
  AS_OF_TODAY : "as of today",
  WEEKLY: 'weekly',
  UPCOMING: "upcoming",
  ASSIGNED: "assigned"
}

export const SUPPORT_MOVE_LOCATION_NAMES = {
  CHASSISPICK: {
    from: "Hook Chassis Location"
  },
  CHASSISTERMINATION: {
    from: "Hook Chassis Location",
    to: "Terminate Chassis Location"
  },
  DROPCHASSIS: {
    from: "Hook Chassis Location",
    to: "Drop Chassis Location"
  },
  BOBTAILTRANSFER: {
    from: "Starting Location",
    to: "Destination Location"
  },
  BOBTAILTOHOOK: {
    from: "Start Bobtail",
    to: "Hook Chassis Location"
  }
}