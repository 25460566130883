import React from "react";
import Select from "react-select";

import PriceCell from "../../PriceCell";
import { smallSelectStyle } from "assets/js/select-style";
import { IconMinus, IconPlus } from "Components/Common/Icons";
import SelectCustomComponent from "../../../../SelectComponent";
import { Currency, isCustomerAccount, isGlobalizationEnabled } from "services";
import { newSmallSelectStyle } from "../../../../../../assets/js/select-style";
import { IconAngleArrowDown } from "../../../../../../Components/Common/Icons";
import NewPriceInput from "../../../../../../Components/Common/PriceInput/NewPriceInput";

export default function PricingTable(props) {
    const {
        quoteDetails, chargeCodeList, unitOfMeasureOptions, selectedCell, isReadOnlyMode,
        handleSelectCell, handleAddPriceRows, handleUnselectCell, handlePricingchanges, handleDeletePriceRow,
    } = props;

    return (
        <div className="bg-gray-50 px-30 pt-10 pb-20 rounded-5">
            <table className="table table-card table-card-sm">
                <thead>
                    <tr>
                        <th scope="col" className="text-dark">Name of Charge</th>
                        <th scope="col">Description</th>
                        <th scope="col" className="text-right">Price</th>
                        <th scope="col">Unit Count</th>
                        <th scope="col">Unit of Measure</th>
                        <th scope="col" className="text-right">Free Units</th>
                        <th scope="col" className="text-right">
                            {isGlobalizationEnabled() ? (quoteDetails?.invoiceCurrency?.currencyCode ?? Currency.defaultCarrierCurrency()?.currencyCode) : '$'} Per Unit
                        </th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {quoteDetails?.pricing &&
                        quoteDetails?.pricing.length > 0 &&
                        quoteDetails?.pricing.map((item, key) => {
                            let charges = chargeCodeList && chargeCodeList.filter((d) => d.isActive && !quoteDetails?.pricing.find(item => d.value == item.name));

                            return (
                                <tr key={key} className="select-custom-input">
                                    <td
                                        onClick={() => handleSelectCell(`name-${key}`)}
                                        style={{ transition: "background 0.4s ease-out" }}
                                        className={`name-${key} px-0 cell-1 ${selectedCell === `name-${key}` ? 'newdriverrow td-clicked' : ""} pl-2 pr-1 text-dark`}
                                    >
                                        <div className="d-flex justify-content-between align-items-center text-dark">
                                            {item.name ? chargeCodeList.find((d) => d.value === item.name)?.chargeName : <span className="text-muted">Select Charge</span>}
                                            <IconAngleArrowDown />
                                            {
                                                selectedCell === `name-${key}` && (
                                                    <SelectCustomComponent
                                                        name='charges'
                                                        searchable={true}
                                                        keyName="chargeName"
                                                        refClassName="cell-1"
                                                        unselectCell={handleUnselectCell}
                                                        listData={charges && charges.filter((charge) => charge.isActive)}
                                                        onSelect={(name, value) => handlePricingchanges(key, { target: { value: value.value, name: "name" } })}
                                                    />
                                                )
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="description"
                                            value={item.description ?? ''}
                                            placeholder="Add Description..."
                                            onChange={(e) => handlePricingchanges(key, e)}
                                            className="form-control border-0 text-left text-dark bg-transparent p-0 line-height-30"
                                        />
                                    </td>
                                    <td className="d-flex justify-content-between align-items-center">
                                        <PriceCell index={key} data={item} handleChange={(e) => handlePricingchanges(key, e)} />
                                    </td>
                                    <td>
                                        <NewPriceInput
                                            name="unit"
                                            value={item.unit}
                                            disabled={item.perType === "percentage"}
                                            onChange={(val) => handlePricingchanges(key, { target: { value: val, name: "unit", } })}
                                        />
                                    </td>
                                    <td className={`perType-${key} px-0`} style={{ transition: "background 0.4s ease-out" }}>
                                        <Select
                                            options={unitOfMeasureOptions}
                                            className="w-150"
                                            styles={newSmallSelectStyle}
                                            value={item.perType && unitOfMeasureOptions.find((op) => op.value === item.perType)}
                                            onChange={({ value }) => handlePricingchanges(key, { target: { value, name: "perType" } })}
                                            menuPosition="fixed"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            name="freeDays"
                                            value={item.freeDays}
                                            disabled={item.perType === "percentage"}
                                            onChange={(e) => item.perType && handlePricingchanges(key, e)}
                                            className="form-control border-0 text-right text-dark bg-transparent p-0 line-height-30"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            name="chargePerDay"
                                            value={item.chargePerDay}
                                            disabled={item.perType === "percentage"}
                                            onChange={(e) => item.perType && handlePricingchanges(key, e)}
                                            className="form-control border-0 text-right text-dark bg-transparent p-0 line-height-30"
                                        />
                                    </td>
                                    <td>
                                        <button type="button" disabled={isReadOnlyMode} onClick={() => handleDeletePriceRow(key)} className="btn btn-circle btn-xs bg-soft-danger">
                                            <IconMinus />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {
                !isCustomerAccount() && (
                    <button type="button" disabled={isReadOnlyMode} onClick={handleAddPriceRows} className="btn btn-white btn-sm text-primary mt-10">
                        <IconPlus className="mr-2" />
                        Add New charge
                    </button>
                )
            }
        </div>
    )
}
