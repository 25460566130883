import React, { useState } from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import AsyncCreatableSelect from 'react-select/async-creatable';

import { newSmSelectStyle, smallSelectStyle } from "../../../../../../assets/js/select-style";
import {
  IconAngleArrowDown,
  IconAngleArrowTop,
  IconDrag,
  IconInfoCircleFilledWhite,
  IconMinus,
  IconPool,
} from "../../../../../../Components/Common/Icons";
import { useAdvanceRoutingTemplate } from "../../../hooks/useAdvanceRoutingTemplate";
import "../../../Styles/style.scss";
import { getCustomerCityAsyncSearch, getCustomerGroupAsyncSearch } from "../../../service/customerRateService";
import { convertProfileListToOption } from "../../../service/profileService";
import { useCustomerSelect } from "../../../hooks/useCustomerSelect";
import { moveTypeToLabel } from "../../../service/routeValidationService";

const AdvancedRoutingTemplate = ({ onChange, routingData }) => {
  const [routeCollapsable, setShowRouteCollapsable] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const {
    routingTemplateOptions,
    selectedRoutingTemplate,
    handleRoutingTemplate,
    eventOptions,
    separatedMoves,
    onDragEnter,
    onDragExit,
    dragIndex,
    addEvent,
    setDraggedEvent,
    updateEventProfiles,
    removeEvent,
    stopOffOptions,
    stopOffValuesToOption,
    handleStopOffChange,
  } = useAdvanceRoutingTemplate({
    routingTemplate: routingData?.routingTemplate ?? null,
    selectedMoves: routingData?.routingRules ?? null,
    onUpdate: onChange,
  });

  const {getRoutingLoadOptions, getRoutingDefaultOptions } = useCustomerSelect();

  const handleToggleRouteCollapsable = () => {
    setShowRouteCollapsable((prevShowFormRow) => !prevShowFormRow);
  };

  let eventIndex = 0;

  return (
    <div className="card bg-gray-50 p-10 mb-10">
      <div className="font-16 mb-10 font-medium pointer" onClick={handleToggleRouteCollapsable}>
        Route Conditions
        <span data-tip data-for="route-settings">
          <IconInfoCircleFilledWhite className="ml-1" />
        </span>
        <ReactTooltip id="route-settings" type="dark" effect="solid" className="w-350">
          Load Tariffs can be matched to Loads based on specific route conditions.  Choose a Routing Template and/or customize the Route Events.  
          For relevant Events, specify one or more Profiles to be matched.
        </ReactTooltip>
        {routeCollapsable ? <IconAngleArrowTop className="ml-2" /> : <IconAngleArrowDown className="ml-2" />}
      </div>
      {routeCollapsable && (
        <div className="form-row">
          <div className="col-md" style={{ maxWidth: "210px" }}>
            <div className="card p-10 mb-0 position-sticky" style={{top: 0}}>
              <label className="font-medium">Routing Template</label>
              <Select
                styles={smallSelectStyle}
                options={routingTemplateOptions}
                value={selectedRoutingTemplate}
                onChange={(e) => {
                  handleRoutingTemplate(e);
                }}
              />
              <div className="hr-light"></div>
              <div className="font-medium mb-10">Drag & Drop to Events to Load</div>
              <div className="d-flex flex-column gap-4">
                {eventOptions?.filter((e) => e?.leg != "COMPLETED")?.map((e) => {
                  return (
                    <button
                      className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable"
                      draggable={true}
                      onDragStart={() => {
                        setIsDragging(true);
                        setDraggedEvent(e?.leg);
                      }}
                      onDragEnd={() => {
                        setIsDragging(false);
                        onDragExit();
                      }}
                    >
                      <IconDrag className="mr-10 text-gray-200" />
                      {e?.legTitle}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md">
            {separatedMoves?.map((moves, i) => {
              const dropIndex = eventIndex;

              return (
                <div className="card mb-10" key={i}>
                  {moves?.[0]?.isGrayPool && (
                    <div className="bg-gray-700 d-flex align-items-center p-10 rounded-top-left rounded-top-right">
                      <IconPool className="text-white" />
                      <h5 className="font-16 font-weight-500 text-white ml-10 mb-0">Gray Pool</h5>
                    </div>
                  )}
                  <div className="p-10">
                    <div
                      className={`badge ${
                        moves?.[0]?.isGrayPool ? "bg-gray-700" : "badge-blue"
                      } text-white badge-rounded font-12 font-weight-500 py-2 px-10`}
                    >
                      {moves?.[0]?.isGrayPool && "Gray"} Container Move {i + 1}
                    </div>
                  </div>
                  <div className="hr-light m-0"></div>
                  <div className="p-10 d-flex flex-column">
                    <div
                      className={`pb-1 align-items-end`}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={() => {
                        addEvent();
                      }}
                      onDragEnter={() => {
                        onDragEnter(i, dropIndex);
                      }}
                      onDragExit={() => {
                        onDragExit();
                      }}
                    >
                      {isDragging && (
                        <div
                          className={`mt-1 drag-space ${
                            dragIndex?.moveIndex === i && dragIndex?.hoverIndex === dropIndex && "drag-space--enlarge"
                          }`}
                        >
                          Drop Your Status Here
                        </div>
                      )}
                    </div>
                    {moves?.map((e, j) => {
                      const moveIndex = eventIndex;
                      eventIndex++;
                      return (
                        <>
                          <div className="outline-card p-10 d-flex form-row align-items-center" key={j}>
                            <div className="font-medium col-md-3 d-flex align-items-center">
                              {moveTypeToLabel(e.type)}
                              {e.type === "STOPOFF" &&
                                <Select
                                  placeholder="All"
                                  className={`w-200 ml-2`}
                                  options={stopOffOptions()}
                                  styles={newSmSelectStyle}
                                  value={stopOffValuesToOption(e.customValue)}
                                  onChange={(stopoffUpdate) => handleStopOffChange(moveIndex, stopoffUpdate)}
                                  isClearable={true}
                                />
                              }
                            </div>
                            <div className="col-md-9 d-flex align-items-center">
                              <AsyncCreatableSelect
                                className="w-100"
                                defaultOptions={getRoutingDefaultOptions(e)}
                                loadOptions={(strSearch) => getRoutingLoadOptions(e, strSearch )}
                                cacheOptions
                                isMulti={true}
                                placeholder="All Customers"
                                styles={smallSelectStyle}
                                value={convertProfileListToOption(e.profile)}
                                onChange={(updateValue) => {
                                  updateEventProfiles(moveIndex, updateValue);
                                }}
                                formatCreateLabel={(inputValue) => `Add "${inputValue}" Zip/Postal Code`}
                              />
                              <button onClick={() => removeEvent(moveIndex)} className="btn btn-circle btn-xs bg-soft-danger ml-15">
                                <IconMinus />
                              </button>
                            </div>
                          </div>
                          <div
                            className={`pb-1 align-items-end`}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={() => {
                              addEvent();
                            }}
                            onDragEnter={() => {
                              onDragEnter(i, moveIndex + 1);
                            }}
                            onDragExit={() => {
                              onDragExit();
                            }}
                          >
                            {isDragging && (
                              <div
                                className={`mt-1 drag-space ${
                                  dragIndex?.moveIndex === i &&
                                  dragIndex?.hoverIndex === moveIndex + 1 &&
                                  "drag-space--enlarge"
                                }`}
                              >
                                Drop Your Status Here
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedRoutingTemplate;
