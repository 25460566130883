import React,{useState, useEffect} from "react";
import moment from "moment";
import Select from "react-select";
import { Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from 'react-select/async-creatable';

import { ENUM } from "./constant";
import AllMails from "pages/tms/Email/AllMails";
import configuration from "../../../../../config";
import PricingTable from "./components/PricingTable";
import { LoaderBar } from "Components/Common/LoaderBar";
import { smallSelectStyle } from "assets/js/select-style";
import { useAddEditQuote } from "./hooks/useAddEditQuote";
import { isCustomerAccount } from "../../../../../services";
import { getCustomers } from "../../../CustomerRates/service/customerRateService";
import { IconInfoFocus, IconMessage, IconCalendar } from "Components/Common/Icons";
import { useCustomerSelect } from "pages/tms/CustomerRates/hooks/useCustomerSelect.js";
import { getContainerSize, getContainerType } from "../../../Dispatcher/actionCreators";
import { DateTimeFormatUtils, getStorage, isEmbeddedEmailEnabled, isEurope } from "services";
import { getCustomerCityAsyncSearch } from 'pages/tms/CustomerRates/service/customerRateService.js';
import DateWrapper from 'pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper';
import * as emailServices from '../../../../../services/email.services'
import { getTimeZone } from "pages/tms/NewDispatcher/constants";

export default function AddEditQuote({ editId, onClose, onSave, isReadOnlyMode = false }) {
    const {groupedProfilesOptions, groupedCitiesOptions, customerFilterOptions, groupedZipCodesOptions,  consigneeFilterOptions, shipperFilterOptions } = useCustomerSelect();
    const hookDetails = useAddEditQuote({ editId, onClose, onSave });
    const {
        isSaving, countries, terminals, activeTab, commodities, userBasicSettings,
        quoteDetails, chassisTypes, peopleOptions, peopleOptionsValue, totalMailCount, 
        containerTypes, containerSizes,shipperSelectValue, consigneeSelectValue, customerSelectValue,
        emptyOriginSelectValue, isFetchingPricing, countryCodeLoadOptions, debouncedPeopleOptions, 
        getChassisTypes, getCurrency, handleChange, handleTabChange, handleCloseModal, 
        handleAfterDisconnectMail, getCustomerRates, handleCommodityChanges, handleAddEditLoadQuoteSettings,
        onAddressSearch,
    } = hookDetails;
    

    return (
        <Modal bsSize="xl" show={true} className="load-quote-modal credit-info-modal" dialogClassName="modal-dialog-scrollable modal-fullheight" backdropClassName="z-1050">
            {isFetchingPricing && <LoaderBar fullPageLoader={true}></LoaderBar>}

            <Modal.Header className="w-100 pb-0 px-0 d-block">
                <Modal.Title id="myModalLabel" className="px-30">
                    {editId ? <>Quote #: {quoteDetails?.quote}</> : "Add New Quote"}
                </Modal.Title>

                {
                    !isCustomerAccount() && isEmbeddedEmailEnabled() && editId && (
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" id="load-tabs">
                            <li className="nav-item" onClick={() => handleTabChange(1)}>
                                <a className={`nav-link ${activeTab === 1 ? "active" : ""}`} role="tab">
                                    <IconInfoFocus />
                                    <div className={`nav-link__text mt_4 ${true === "load" ? "active_text font-semi-bold" : ""}`}>Quotes Info</div>
                                </a>
                            </li>
                            <li className="nav-item position-relative" onClick={() => handleTabChange(2)}>
                                <span
                                    className="d-flex align-items-center justify-content-center position-absolute badge badge-primary font-12 badge-pill text-white text-center" style={{
                                        outline: '2px solid #FFFFFF',
                                        minWidth: 17,
                                        height: 17,
                                        outline: '2px solid #fff',
                                        left: '49%',
                                        transform: 'translateX(-50%)',
                                        top: '10px'
                                    }}
                                >
                                    {totalMailCount}
                                </span>
                                <a className={`nav-link ${activeTab === 2 ? "active" : ""}`} role="tab">
                                    <IconMessage />
                                    <div className={`nav-link__text mt_4 ${true === "load" ? "active_text font-semi-bold" : ""}`}>Communication</div>
                                </a>
                            </li>
                        </ul>
                    )
                }

            </Modal.Header>

            <Modal.Body className="d-flex flex-column">
                
                    {
                        activeTab === 1 && (
                            <div className={`${isReadOnlyMode ? "disabled-pointer-events" : ""}`}>
                                <div className="mb-20">
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <div className="form-row-md">
                                                <h4 className="font-12 mb-10 col-md-12 font-weight-500">
                                                    <span className="text-danger">*</span> Load Type
                                                </h4>
                                                <div className="col">
                                                    <div className="form-check form-group">
                                                        <input
                                                            id="import"
                                                            type="radio"
                                                            name="loadType"
                                                            className="form-check-input"
                                                            onClick={() => handleChange('type_of_load', ENUM.IMPORT)}
                                                            checked={quoteDetails?.type_of_load === ENUM.IMPORT}
                                                        />
                                                        <label className="form-check-label font-12 font-weight-500 text-dark" for="import">Import</label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-check form-group">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="import"
                                                            name="loadType"
                                                            checked={quoteDetails?.type_of_load === ENUM.EXPORT}
                                                            onClick={() => handleChange('type_of_load', ENUM.EXPORT)}
                                                        />
                                                        <label className="form-check-label font-12 font-weight-500 text-dark" for="import">
                                                            Export
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-check form-group">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="import"
                                                            name="loadType"
                                                            checked={quoteDetails?.type_of_load === ENUM.ROAD}
                                                            onClick={() => handleChange('type_of_load', ENUM.ROAD)}
                                                        />
                                                        <label className="form-check-label font-12 font-weight-500 text-dark" for="import">
                                                            Road
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 d-flex align-items-center gap-10">
                                            
                                            {
                                                !isCustomerAccount() && (
                                                    <div className="form-group w-100">
                                                    <label className="col-form-label">
                                                        Customer
                                                    </label>
                                                    <AsyncSelect
                                                        isClearable
                                                        value={customerSelectValue}
                                                        onChange={(callers) => {
                                                            getCurrency(callers?.allInfos, quoteDetails?.selectedTerminal?.allInfo);
                                                            handleChange('caller', callers);
                                                        }}
                                                        styles={smallSelectStyle}
                                                        defaultOptions={customerFilterOptions}
                                                        loadOptions={(strSearch) => getCustomers(strSearch)}
                                                    />
                                                </div>
                                                )
                                            }

                                            {
                                                !isCustomerAccount() && isEmbeddedEmailEnabled() && (
                                                    <div className="form-group w-100">
                                                        <label className="col-form-label">People</label>
                                                        <AsyncSelect
                                                            isClearable
                                                            styles={smallSelectStyle}
                                                            defaultOptions={peopleOptions}
                                                            loadOptions={debouncedPeopleOptions}
                                                            onChange={(people) => handleChange('people', people)}
                                                            value={peopleOptionsValue}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="hr-light mb-20 mt-10"></div>

                                    <div className="form-row">
                                        <div className="form-group col">
                                            <label className="col-form-label">
                                                <span className="text-danger">*</span> Pick Up Location
                                            </label>
                                            <AsyncCreatableSelect
                                                isMulti
                                                cacheOptions
                                                styles={smallSelectStyle}
                                                value={shipperSelectValue}
                                                isDisabled={isReadOnlyMode}
                                                placeholder='City/State/ZipCode/Profile/Profile-Group'
                                                formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                                defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                                onChange={(selectedOptions) => handleChange("shipper", selectedOptions)}
                                                loadOptions={(strSearch) => onAddressSearch(strSearch, { groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions }, ['ALL', 'shipper'])}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label className="col-form-label">
                                                <span className="text-danger">*</span> Delivery Location
                                            </label>
                                            <AsyncCreatableSelect
                                                isMulti
                                                cacheOptions
                                                styles={smallSelectStyle}
                                                isDisabled={isReadOnlyMode}
                                                value={consigneeSelectValue}
                                                placeholder='City/State/ZipCode/Profile/Profile-Group'
                                                formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                                defaultOptions={[...consigneeFilterOptions, ...groupedZipCodesOptions]}
                                                onChange={(selectedOptions) => handleChange("consignee", selectedOptions)}
                                                loadOptions={(strSearch) => onAddressSearch(strSearch, { groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions }, ['ALL', 'consignee'])}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label className="col-form-label">Return Location</label>
                                            <AsyncCreatableSelect
                                                isMulti
                                                cacheOptions
                                                styles={smallSelectStyle}
                                                isDisabled={isReadOnlyMode}
                                                value={emptyOriginSelectValue}
                                                placeholder='City/State/ZipCode/Profile/Profile-Group'
                                                formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                                defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                                onChange={(selectedOptions) => handleChange("emptyOrigin", selectedOptions)}
                                                loadOptions={(strSearch) => onAddressSearch(strSearch, { groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions }, ['ALL', 'containerReturn', 'shipper'])}
                                            />
                                        </div>
                                        <div className="w-150 mt-25 ml-15 col">
                                            <div className="bg-secondary font-12 text-white rounded-lg p-1 px-2">
                                                <span className="font-weight-500">Total: </span>
                                                <span className="font-weight-normal">{quoteDetails?.totalMiles} {JSON.parse(getStorage("userBasicSettings") ?? "{}")?.distanceMeasureUnit}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        {
                                            ((getStorage("currentUserRole") == "fleetmanager" &&
                                                configuration.idForTerminal.indexOf(JSON.parse(getStorage("loggedInUser")).fleetManager.carrier) != -1) ||
                                                (getStorage("currentUserRole") == "carrier" &&
                                                    configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)) && (
                                                <div className="form-group col-3">
                                                    <label className="col-form-label">Terminal</label>
                                                    <Select
                                                        isClearable
                                                        options={terminals}
                                                        styles={smallSelectStyle}
                                                        value={terminals?.find(d => d.value === quoteDetails?.selectedTerminal?._id)}
                                                        onChange={(terminal) => {
                                                            getCurrency(quoteDetails?.caller?.allInfos, terminal?.allInfo);
                                                            handleChange('selectedTerminal', terminal);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            isEurope() && (
                                                <div className="form-group col-3">
                                                    <label className="col-form-label">
                                                        Country Code
                                                    </label>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        name='countryCode'
                                                        styles={smallSelectStyle}
                                                        placeholder="Country Code"
                                                        defaultOptions={countries}
                                                        value={quoteDetails?.countryCode}
                                                        loadOptions={countryCodeLoadOptions}
                                                        onChange={(countryCode) => handleChange('countryCode', countryCode)}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className="hr-light mb-20 mt-10"></div>
                                    <div className="form-row">
                                        <div className="form-group col-2">
                                            <label className="col-form-label">
                                                <span className="text-danger">*</span> # of Loads
                                            </label>
                                            <input
                                                type="number"
                                                value={quoteDetails?.numberofLoad}
                                                onChange={({ target: { value } }) => handleChange('numberofLoad', value.replace(/[^0-9]/g, ""))}
                                                placeholder="How many loads do you want the quote for?"
                                                className="form-control"
                                            />
                                        </div>
                                        {
                                            !isCustomerAccount() && (
                                                <div className="form-group col">
                                                    <label className="col-form-label">
                                                        <span className="text-danger">*</span> Valid Upto
                                                    </label>
                                                    <div className="input-wrapper">
                                                        <DateWrapper
                                                            placeholder="From"
                                                            isShowTime={true}
                                                            hideShowSelectDate={true}
                                                            disableUptoThisday={moment()}
                                                            displayTimeZone={getTimeZone()}
                                                            onChange={(e) => handleChange('validUpTo', e)}
                                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                            defaultDate={quoteDetails?.validUpTo ? moment(quoteDetails?.validUpTo) : null}
                                                        />
                                                        <div className="input-icon">
                                                            <IconCalendar />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="form-group col">
                                            <label className="col-form-label">Container Type</label>
                                            <AsyncSelect
                                                styles={smallSelectStyle}
                                                loadOptions={getContainerType}
                                                getOptionLabel={(val) => val?.name || val?.label}
                                                value={containerTypes.find(d => d._id == quoteDetails?.containerType?._id)}
                                                onChange={(containerType) => handleChange('containerType', containerType)}
                                                defaultOptions={containerTypes?.map((ct) => ({ label: ct.name, value: ct._id, allInfos: ct }))}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label className="col-form-label">Container Size</label>
                                            <AsyncSelect
                                                styles={smallSelectStyle}
                                                loadOptions={getContainerSize}
                                                getOptionLabel={(val) => val?.name || val?.label}
                                                onChange={(containerSize) => handleChange('containerSize', containerSize)}
                                                defaultOptions={containerSizes?.map((cs) => ({ label: cs.name, value: cs._id, allInfos: cs }))}
                                                value={containerSizes.find(d => d._id == quoteDetails?.containerSize?._id)}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label className="col-form-label">Chassis Type</label>
                                            <AsyncSelect
                                                styles={smallSelectStyle}
                                                loadOptions={getChassisTypes}
                                                getOptionLabel={(val) => val?.name || val?.label}
                                                onChange={(chassisType) => handleChange('chassisType', chassisType)}
                                                defaultOptions={chassisTypes?.map((ct) => ({ label: ct.name, value: ct._id, allInfos: ct }))}
                                                value={chassisTypes.find(d => d._id == quoteDetails?.chassisType?._id)}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row mt-10">
                                        <div className="form-group col-3">
                                            <label className="col-form-label">
                                                Commodity
                                            </label>
                                            {
                                                JSON.parse(getStorage("measureUnits")).isCommodityProfileShow == true ? (
                                                    <div className="form-group m-0">
                                                        <Select
                                                            options={commodities}
                                                            styles={smallSelectStyle}
                                                            value={commodities.find((pt) => pt.value == quoteDetails?.commodity?.[0]?.profile)}
                                                            onChange={(value) => {
                                                                const temp = [...quoteDetails?.commodity];
                                                                temp[0]["profile"] = value.value;
                                                                handleChange('commodity', [...temp]);
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name="profile"
                                                        className="form-control"
                                                        placeholder="Enter Commodity"
                                                        value={quoteDetails?.commodity?.[0]?.profile}
                                                        onChange={(e) => handleCommodityChanges(0, e)}
                                                        style={{ transition: "background 0.4s ease-out" }}
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="form-group col-7">
                                            <label className="col-form-label">Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Add Description..."
                                                name="description"
                                                onChange={(e) => handleCommodityChanges(0, e)}
                                                value={quoteDetails?.commodity?.[0]?.description ?? ''}
                                            />
                                        </div>
                                        <div className="form-group col-1">
                                            <label className="col-form-label">Weight, LBS</label>
                                            <input
                                                type="number"
                                                name="weight"
                                                className="form-control"
                                                value={quoteDetails?.commodity?.[0]?.weight}
                                                onChange={(e) => handleCommodityChanges(0, e)}
                                            />
                                        </div>
                                        <div className="form-group col-1">
                                            <label className="col-form-label">Weight, KG</label>
                                            <input
                                                type="number"
                                                name="weightKgs"
                                                className="form-control"
                                                value={quoteDetails?.commodity?.[0]?.weightKgs}
                                                onChange={(e) => handleCommodityChanges(0, e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row pb-20 pt-15">
                                        <div className="col">
                                            <div className="form-group form-check m-0">
                                                <input
                                                    type="checkbox"
                                                    name="hazmat"
                                                    className="form-check-input"
                                                    checked={quoteDetails?.hazmat}
                                                    onClick={() => handleChange('hazmat', !quoteDetails?.hazmat)}
                                                />
                                                <label className="form-check-label" htmlFor="hazmat">Hazmat</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group form-check m-0">
                                                <input
                                                    type="checkbox"
                                                    name="overweight"
                                                    className="form-check-input"
                                                    checked={quoteDetails?.overweight}
                                                    onClick={() => handleChange('overweight', !quoteDetails?.overweight)}
                                                />
                                                <label className="form-check-label" htmlFor="overweight">Overweight</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group form-check m-0">
                                                <input
                                                    name="liquor"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={quoteDetails?.liquor}
                                                    onClick={() => handleChange('liquor', !quoteDetails?.liquor)}
                                                />
                                                <label className="form-check-label" htmlFor="liquor">Liquor</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group form-check m-0">
                                                <input
                                                    name="hot"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={quoteDetails?.hot}
                                                    onClick={() => handleChange('hot', !quoteDetails?.hot)}
                                                />
                                                <label className="form-check-label" htmlFor="hot">Hot</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group form-check m-0">
                                                <input
                                                    name="genset"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={quoteDetails?.genset}
                                                    onClick={() => handleChange('genset', !quoteDetails?.genset)}
                                                />
                                                <label className="form-check-label" htmlFor="genset">Genset</label>
                                            </div>
                                    </div>
                                    {
                                        userBasicSettings?.isScaleEnabled && (
                                            <div className="col">
                                                <div className="form-group form-check m-0">
                                                    <input
                                                        name="scale"
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={quoteDetails?.scale}
                                                        onClick={() => handleChange('scale', !quoteDetails?.scale)}
                                                    />
                                                    <label className="form-check-label" htmlFor="scale">Scale</label>
                                                </div>
                                            </div>
                                        )
                                    }
                                        <div className="col">
                                            <div className="form-group form-check m-0">
                                                <input
                                                    name="isStreetTurn"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={quoteDetails?.isStreetTurn}
                                                    onClick={() => handleChange('isStreetTurn', !quoteDetails?.isStreetTurn)}
                                                />
                                                <label className="form-check-label" htmlFor="isStreetTurn">Street Turn</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-warning-50 p-10 rounded">
                                        <h6 className="mb-10 font-14 font-weight-500 text-black">Notes</h6>
                                        <textarea
                                            placeholder="Add Some Notes...."
                                            value={quoteDetails?.quoteNotes}
                                            className="form-control textarea-height-sm"
                                            onChange={({ target: { value } }) => handleChange('quoteNotes', value)}
                                        ></textarea>
                                    </div>

                                    <div className="mt-15 d-flex justify-content-end">
                                        <button
                                            className="btn btn-primary mt-10"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getCustomerRates();
                                            }}
                                            disabled={isReadOnlyMode}
                                        >Calculate Rate</button>
                                    </div>
                                </div>

                                <PricingTable {...hookDetails} isReadOnlyMode={isReadOnlyMode} />

                            </div>
                        )
                    }

                    {
                        activeTab === 2 && (
                            <div className="bg-gray-50 p-2 rounded-5 d-flex flex-column flex-grow-1">
                                <AllMails onDisconnectMail={handleAfterDisconnectMail} isFromQuote={true} quoteNumber={quoteDetails?.quote} quoteId={quoteDetails?._id} setIsDraft={() => {}} />
                            </div>
                        )
                    }


            </Modal.Body>
            <Modal.Footer className={`d-flex justify-content-end`}>
                <div className="d-flex align-items-end">
                    <button type="button" className="btn btn-close" onClick={handleCloseModal}>Cancel</button>

                    {
                        (editId && isReadOnlyMode) ? <></> : (
                            <button type="button" disabled={isSaving} className="btn btn-success d-flex gap-5" onClick={handleAddEditLoadQuoteSettings}>
                                {isSaving && <Spinner size='sm' />}
                                {editId ? "Update" : "Add New Quote"}
                            </button>
                        )
                    }
                   
                </div>
            </Modal.Footer>
        </Modal>
    );
}
