import { useContext, useEffect, useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import DataGrid from "react-data-grid"

import confirm from "Components/Common/ConfirmAert"
import ReactTooltip from "react-tooltip"
import { amplitudeTrack, getStorage, toastr } from "services"
import TableLoader from "../../../../Components/Common/LoaderBar/TableLoader"
import TableSkeleton from "../../../../Components/TableSkeleton"
import { disconnectToLoads, disconnectToQuotes, draftLoadEmailInfo, getEmailPeople, getSignleDraft } from "../actionCreator"
import { columns } from "../columns"
import { EMAIL_CONTEXT, EMAIL_TABS, OUTLOOK_PROVIDER, PREV_NEXT_EMAIL, tableSkeletonHeader } from "../constant"
import { getThreadId, isEmbeddedEmailConnected, isExternalEmail, isGenAIEnabled, isSentTabSelected } from "../helper"
import { useArchiveEmail, useDeleteDraftEmail, useGetMailById, useMoveEmail, useToggleMarkAsUnread, useToggleStar } from "../hooks"
import CreateEmailModal from "./CreateEmailModal"
import EmailBottombar from "./EmailBottombar"
import EmailDetailSkeleton from "./EmailDetails/EmailDetailSkeleton"
import EmailDetailsModal from "./EmailDetails/EmailDetailsModal"
import EmailReadingMode from "./EmailReadingMode"
import _ from "lodash"
import { isAtMiddleOrLower } from "../../NewDispatcher/DispatcherTable/Functions"
import moment from "moment"

export const EmptyRowsRenderer =() => {
  return (
    <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
      <div style={{padding: "20px 10px", backgroundColor: 'white', fontSize: "12px"}}>
        No Result
      </div>
    </div>
  );  
}

const EmailTable = ({
  isLoading,
  allEmails,
  setIsLoading,
  activeNav,
  _getCounts,
  isFromLoad,
  loadId,
  _getEmailByLoad,
  allLabels,
  quoteId,
  isFromQuote,
  _getEmailByQuote,
  onDisconnectMail,
  allEmailStatus,
  allEmailSignature,
  getAllEmailSignatures,
  setShowCreateLabelModal,
  setShowCreateTag,
  isEmailMode,
  handleSearchChange,
  showFilterBar,
  emailFilters,
  setFilterKey,
  subCardFilter,
  setSubCardKey,
  isApiCall,
  selectedRow,
  setSelectedRow,
  selectedRows,
  setSelectedRows,
  showEmailDetailsModal,
  setShowEmailDetailsModal,
  showEmailDetailsModalR,
  setShowEmailDetailsModalR,
  fetchData,
  skipRef,
  allTags,
  isTagsLoading,
  assignTagsToEmails,
  handleSearchTerm,
  getTags,
  activePage,
  isCompleted,
  threadUpdates,
  updateActivePageKey,
  isSubFolderActive,
  searchTerm,
  handleHideBatchMenu,
  isEditModeOn,
  setIsEditMode
}) => {
  const context = useContext(EMAIL_CONTEXT)
  const { setAllEmails } = context
  const [rightClickKey, setRightClickKey] = useState("");
  const [emailPeople, setEmailPeople] = useState([]);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false)
  const [draftEmail, setDraftEmail] = useState({})
  const [isLoadDetected,setIsLoadDetected] = useState(false)
  const [draftloadEmails, setDraftloadEmails] = useState([]);
  const [peopleLoader, setPeopleLoader] = useState(false);
  const [isGetEmail, setIsGetEmail] = useState(false);
  const [nextEmail, setNextEmail] = useState(null);
  const [prevEmail, setPrevEmail] = useState(null);
  const embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount"));
  const { provider } = embeddedEmailAccount ?? {};
  const isSentEmailTab = isSentTabSelected(activeNav, allLabels ?? [])
  const canUseGenAI = isGenAIEnabled() 

  const scrollPositionRef = useRef(0)

  useEffect(()=>{
    setShowEmailDetailsModal(false)
    setShowEmailDetailsModalR(false)
  },[activeNav])
  
  const updateDraftloadEmails=(data)=>{
    if(data?.length===0){
      setIsLoadDetected(false)
    }
    setDraftloadEmails(data)
  }



  const trashCb = (folderIds) => {
    setShowEmailDetailsModal(false)
    _getCounts(folderIds);
  };
  const archiveCallback = (folderIds) => { 
    _getCounts(folderIds); 
    setShowEmailDetailsModal(false); 
  }
  const getEmailByIdCb = ({ isManage, row, result, readMode, prevNextType, isUpDownKey, isButtonCLick }) => {
    let currentData = {}
    if(prevNextType === PREV_NEXT_EMAIL.PREV) {
      currentData = prevEmail
    } else if (prevNextType === PREV_NEXT_EMAIL.NEXT) {
      currentData = nextEmail
    }
    if (isButtonCLick && prevNextType) {
      const response = _toggleMarkAsUnread({ ids: [currentData?.thread_id], isUnread: false });
      if (response?.success) {
        setAllEmails(allEmails => allEmails?.map((d) => {
          if (d.id === currentData?.thread_id) {
            return { ...d, unread: false };
          } else {
            return d;
          }
        }));
        
        _getCounts();
      }
    }
    
    if (prevNextType === PREV_NEXT_EMAIL.PREV) {
      setPrevEmail(result)
      setIsLoading(false)
      setIsGetEmail(false)
      return
    }

    if (prevNextType === PREV_NEXT_EMAIL.NEXT) {
      setNextEmail(result)
      setIsLoading(false)
      setIsGetEmail(false)
      return
    }

    if (!isManage && row.unread && !isUpDownKey) {
      const response = _toggleMarkAsUnread({ ids: [getThreadId(row)], isUnread: false });
      if (response?.success) {
        row.unread = false;
        _getCounts();
      }
    }
    if (!prevNextType) {
      setIsLoading(false)
      setIsGetEmail(false)
      setSelectedRow(result);
      if(!isFromLoad){
        handlePrevAndNextEmail(null, PREV_NEXT_EMAIL.PREV, result)
        handlePrevAndNextEmail(null, PREV_NEXT_EMAIL.NEXT, result)
      }
      if (readMode) setShowEmailDetailsModalR(true)
      else setShowEmailDetailsModal(true);
    }
  };

  const { _moveEmail, moveEmailloaderIds } = useMoveEmail(allEmails, setAllEmails, trashCb);
  const { _archiveEmail, archiveLoaderIds } = useArchiveEmail(activeNav, allEmails, setAllEmails, archiveCallback);
  const { _deleteDraftEmail, deleteLoaderIds } = useDeleteDraftEmail(allEmails, setAllEmails, _getCounts);
  const { toggleStar, loaderIds } = useToggleStar(allEmails, setAllEmails, _getCounts, activeNav, selectedRow, setSelectedRow);
  const { _toggleMarkAsUnread, readUnreadIds } = useToggleMarkAsUnread(allEmails, setAllEmails, _getCounts);
  const { _getEmailById, loading:isMailInfoLoader } = useGetMailById(getEmailByIdCb);
  const deleteLoader = activeNav === EMAIL_TABS.DRAFT ? deleteLoaderIds : moveEmailloaderIds
  const handleShowEmailModal = (showPopup = false, emailData = {}) => {
    setShowSendEmailModal(showPopup)
    setDraftEmail(emailData)
  }

  const [rowOpen, setRowOpen] = useState()
  useEffect(() => {
    if (selectedRow?.message) {
      setRowOpen(selectedRow.message)
    }
  }, [selectedRow])

  useEffect(() => {
    if (
      threadUpdates &&
      rowOpen &&
      rowOpen?.threadId === threadUpdates?.threadId &&
      moment().diff(moment(threadUpdates?.updatedAt), "seconds") <= 10
    ) {
      setRowOpen((row) => {
        if(row){
          if (threadUpdates.hasOwnProperty("starred")) row.starred = threadUpdates.starred;
          if (threadUpdates.hasOwnProperty("unread")) row.unread = threadUpdates.unread;
        }
        return row;
      });
      setSelectedRow((row) => {
        if(row){
        const { message, thread, thread_id } = row;
        if (threadUpdates.hasOwnProperty("starred")) {
          if (message) {
            message.starred = threadUpdates.starred;
          }
          thread?.length &&
            thread?.forEach((t) => {
              t.starred = threadUpdates.starred;
            });
        }
        if (threadUpdates.hasOwnProperty("unread")) {
          if (message) {
            message.unread = threadUpdates.unread;
          }
          thread?.length &&
            thread.forEach((t) => {
              t.unread = threadUpdates.unread;
            });
        }
        return { message, thread, thread_id };
       };
       return row;
      });
    }
  }, [threadUpdates]);
  const isEmailConnected = isEmbeddedEmailConnected()
  const handleCheckboxChange = (fromHeader = false, selectedEmails = []) => {
    if(fromHeader){
      setSelectedRows(selectedEmails)
    }else{
      setSelectedRows(selectedEmails)
    }
  }

  const _getDraftLoadEmailInfo = async(id)=>{
    if(!isEmailConnected) return
    try {
      let res = await draftLoadEmailInfo(id)
      if(res?.length>0) {
        res = res.map((e, index) => ({ ...e, index }))
        setIsLoadDetected(true);
        setDraftloadEmails(res);
      }else{
        setIsLoadDetected(false);
      }
    } catch (error) {
      console.log(error)
      setIsGetEmail(false)
      setIsLoadDetected(false);
    }
  }
  const handleRowClick = (row, isManage, fullRowData, isReply, readMode, prevNextType, isUpDownKey, isButtonCLick) => {
    if(!row) return
    if(isEmailMode === 'grid')setSelectedRows([])

    if (activeNav === EMAIL_TABS.DRAFT || row?.object === "draft") {
      const id = (row?.object === "draft" && OUTLOOK_PROVIDER.includes(provider)) ? row?.threadId : row?.id
      setIsGetEmail(true)
      getSignleDraft(id).then((res) => {
        setIsGetEmail(false)
        handleShowEmailModal(true, res)
      })
    } else {
      const threadId = row?.object === "message" ? row?.threadId : row?.id
      const externalEmail = isExternalEmail(row?.grantId)
      if (!isManage && !prevNextType) {
        _getEmailPeople(row, externalEmail)
      }
      setIsGetEmail(true)
      if(!prevNextType) setRowOpen(row)
        const isRetrieveFromNylas = row?.isRetrieveFromNylas ?? false
      if (externalEmail && row) {
        _getEmailById({ id:threadId, callbackProps: { row, isManage }, isReply: !!isReply, readMode, prevNextType, isUpDownKey, isButtonCLick: (isButtonCLick || !prevNextType ) ? true : false, skipMiddleWare: true, isRetrieveFromNylas });
        // if(!prevNextType) setSelectedRow(result)
        // setIsGetEmail(false)
        // if (readMode) setShowEmailDetailsModalR(true)
        // else setShowEmailDetailsModal(true)
      } else {
        _getEmailById({ id:threadId, callbackProps: { row, isManage }, isReply: !!isReply, readMode, prevNextType, isUpDownKey, isButtonCLick: (isButtonCLick || !prevNextType ) ? true : false, isRetrieveFromNylas});
      }
    }
  }

  useEffect(()=>{
    if(selectedRow?.thread_id && canUseGenAI){
      _getDraftLoadEmailInfo(selectedRow?.thread_id)
    }
  },[selectedRow])

  const _getEmailPeople = (row, isExternalEmail) => {
    setPeopleLoader(true)
    let params = {
      threadId: `"${row?.object === "thread" ? row?.id : row?.threadId || getThreadId(rowOpen)}"`,
      isExternalEmail: isExternalEmail ?? false,
    }
    if(!isEmailConnected) params.skipMiddleWare = true
    getEmailPeople(params).then((res) => { 
      const peoples = res?.length > 1 ? _.uniqBy(res, 'email')  : res
      setEmailPeople(peoples || []);
      setPeopleLoader(false)
    }).catch((err) => {
      console.log(err);
      setPeopleLoader(false)
    })
  }

  const handleHideEmailDetailsModal = () => {
    if(!showEmailDetailsModal) return
    setShowEmailDetailsModal(false)
    setEmailPeople([])
    setEmailPeople([])
  }

  const rowKeyGetter = (row) => {
    return row.id
  }

  const handleOnArchive = async (ids, event, eventProperties) => {
    setSelectedRows([])
    const params = { ids: ids };
    try {
      await _archiveEmail(params, ids);
      if(isEmailMode == 'card') {
        prevEmail && prevEmail?.thread_id ? handlePrevEmail() : handleNextEmail()
      }
    } catch (error) {
      console.log("error",error)
    }
    if (event && eventProperties) {
      amplitudeTrack(event, eventProperties);
    }
  }

  const hanldeOnDelete = (row, event, eventProperties, isEmailDetails) => {
    setSelectedRows([])
    confirm(
      "Delete Email",
      "Are you sure you want to delete email?",
      (value) => {
        if (value) {
          const params = { ids: isEmailDetails ? [row?.threadId] : [row.id]};
          if (!!row?.draftIds?.length && activePage === EMAIL_TABS.DRAFT) {
            _deleteDraftEmail({ ids: row.draftIds.map((d) => d) });
          }else if(!!row?.id && activePage === EMAIL_TABS.DRAFT){
            _deleteDraftEmail({ ids: [row.id] });
          } else {
            _moveEmail(params, true);
          }
          if (event && eventProperties) {
            amplitudeTrack(event, eventProperties);
          }
        }
      }
    )
  }

  const handleDisconnect = (message,event,eventProperties) => {
    setSelectedRows([])
    confirm(
      "Disconnect Load",
      "Are you sure you want to disconnect the load?",
      (value) => {
        if (value) {
          const threadId = message?.object === "thread" ? message?.id : message?.threadId
          let params = {
            id: loadId,
            threadIds: [threadId]
          }
          if(!isEmailConnected) params.skipMiddleWare = true
          const isExternal = isExternalEmail(message.grantId)
          params.isExternalMessage = isExternal ?? false
          disconnectToLoads(params).then((res) => {
            toastr.show("Load disconnected successfully.", "success");
            _getEmailByLoad({skip:0},true);
            if(event && eventProperties){
              amplitudeTrack(event,eventProperties)
            }
          }).catch((err) => {
            console.log(err);
          })
        }
      }
    )
    
  }

  const handleDisconnectQuote = (message) => {
    confirm(
      "Disconnect Quote",
      "Are you sure you want to disconnect the quote?",
      (value) => {
        if (value) {
          const threadId = message?.object === "thread" ? message?.id : message?.threadId;
          let params = { quoteId, threadIds: [threadId]}

          if(!isEmailConnected) params.skipMiddleWare = true;

          disconnectToQuotes(params).then((res) => {
            toastr.show("Quote disconnected successfully.", "success");
            _getEmailByQuote({skip:0},true);
            onDisconnectMail();
          }).catch((err) => {
            console.log(err);
          })
        }
      }
    )
    
  }

  const handlePrevAndNextEmail = (emailIndex, prevNextType, onClickEmail, isButtonCLick=false) => {
    if (Object.keys(onClickEmail ?? {})?.length) {
      emailIndex = allEmails?.findIndex((obj) => {
        return obj?.id === onClickEmail?.thread_id;
      });
    }

    if (
      emailIndex > -1 &&
      emailIndex < allEmails?.length &&
      prevNextType === PREV_NEXT_EMAIL.PREV
    ) {
      const index = onClickEmail ? emailIndex + 1 : emailIndex + 2
      handleRowClick(allEmails[index], null, null, null, true, PREV_NEXT_EMAIL.PREV, false, isButtonCLick)
    }
    else if (
      prevNextType === PREV_NEXT_EMAIL.PREV &&
      emailIndex === allEmails?.length - 1
    ) {
      if (skipRef.current !== 0) fetchData()
    }
    else if (
      emailIndex > 0 &&
      prevNextType === PREV_NEXT_EMAIL.NEXT
    ) {
      const index = onClickEmail ? emailIndex - 1 : emailIndex - 2
      handleRowClick(allEmails[index], null, null, null, true, PREV_NEXT_EMAIL.NEXT, false, isButtonCLick)
    }
  }

  const handlePrevEmail = () => {
    const index = allEmails?.findIndex((obj) => {
      return obj?.id === selectedRow?.thread_id;
    });
    if (
      index > -1 &&
      index < allEmails?.length &&
      allEmails[index + 1]
    ) {
      _getEmailPeople(prevEmail, isExternalEmail(prevEmail?.grantId))
      setSelectedRow(prevEmail);
      setNextEmail(selectedRow)
      setRowOpen(allEmails[index + 1])
      handlePrevAndNextEmail(index, PREV_NEXT_EMAIL.PREV, null, true)
      if((index + 1) === (allEmails?.length - 1)) {
        setPrevEmail(null)
      }
    }
    if (index === allEmails?.length) handlePrevAndNextEmail(index, PREV_NEXT_EMAIL.PREV, null, true)
  };

  const handleNextEmail = () => {
    const index = allEmails?.findIndex((obj) => {
      return obj?.id === selectedRow?.thread_id;
    });
    if (index > 0) {
      _getEmailPeople(prevEmail, isExternalEmail(prevEmail?.grantId))
      setSelectedRow(nextEmail);
      setPrevEmail(selectedRow)
      setRowOpen(allEmails[index - 1])
      handlePrevAndNextEmail(index, PREV_NEXT_EMAIL.NEXT, null, true)
      if((index - 1) === 0) {
        setNextEmail(null)
        if(allEmails[0]?.id){
          const response = _toggleMarkAsUnread({ ids: [allEmails[0]?.id], isUnread: false });
          if (response?.success) {
            setAllEmails(allEmails => allEmails?.map((d) => {
              if (d.id === selectedRow?.thread_id) {
                return { ...d, unread: false };
              } else {
                return d;
              }
            }));
          }
        }
      }
    }
  };

  const handleBottomUnread = (ids, isUnread) => {
    _toggleMarkAsUnread({ ids, isUnread });
  }

  useEffect(() => {
    if(isEmailMode === "card") return
      columns({
        selectedRows,
        handleCheckboxChange,
        handleRowClick,
        handleOnArchive,
        hanldeOnDelete,
        setRightClickKey: null,
        isFromLoad,
        handleDisconnect,
        _getCounts,
        activeNav,
        toggleStar,
        loaderIds,
        allLabels,
        archiveLoaderIds,
        deleteLoader,
        setSelectedRows,
        allEmailStatus,
        setShowCreateTag,
        allTags,
        readUnreadIds,
        activePage,
        setShowCreateLabelModal,
        isSubFolderActive
      });
  }, [allEmails])

  useEffect(() => {
    handleHideBatchMenu()
  }, [activeNav])

  const tooltipRebuild = () => {
    ReactTooltip.rebuild(); 
  }

  useEffect(() => {
    // Update the tooltip
    tooltipRebuild(); 
  }, [handleRowClick]);

  const handleScroll = (e) => {
    const {isMiddle, isScrollingDown} = isAtMiddleOrLower(e, scrollPositionRef )
      if (isLoading || isCompleted || !isMiddle || !isScrollingDown) return;
      if(skipRef.current !== 0) fetchData();
  }

  const updateSelectedRowAfterReply = (data)=>{
    if (selectedRow && data){
      _getEmailPeople(null, isExternalEmail(data.message?.grantId))
      setSelectedRow(data)
    } 
  }
  
  return (
    <>
    {isLoading && !skipRef.current && isEmailMode === 'grid' ? <TableSkeleton tableHeaders={tableSkeletonHeader} isCheckboxAvailable={true} isSentTab={EMAIL_TABS.SENT === activeNav} /> : 
    isEmailMode === 'grid' && <>
      <DataGrid
        columns={columns({
          selectedRows,
          handleCheckboxChange,
          handleRowClick,
          handleOnArchive,
          hanldeOnDelete,
          setRightClickKey,
          isFromLoad,
          handleDisconnect,
          _getCounts,
          activeNav,
          toggleStar,
          loaderIds,
          allLabels,
          archiveLoaderIds,
          deleteLoader,
          setSelectedRows,
          isFromQuote,
          handleDisconnectQuote,
          allEmailStatus,
          setShowCreateLabelModal,
          setShowCreateTag,
          handleBottomUnread,
          allTags,
          readUnreadIds,
          activePage,
          isSentEmailTab,
          isSubFolderActive
        })}
        rows={allEmails}
        className="fill-grid rdg-light rdg-no-outline rdg-email-table"
        headerRowHeight={30}
        rowHeight={40}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        rowKeyGetter={rowKeyGetter}
        components={{
          noRowsFallback: <EmptyRowsRenderer />
        }}
        onScroll={(e) => {
          handleScroll(e);
          tooltipRebuild();
        }}
        id="rdg-email-table"
        data-testid="rdg-email-table"
        style={isFromLoad ? { height: "calc(100vh - 294px)" } : {}}
        rowClass={(row) => `drag-${row.id}`}
      />
      {/* {isLoading && skipRef.current && <TableLoader />} */}
    </>
    }
    {isEmailMode === "card" &&
      <EmailReadingMode
            rowData={selectedRow} 
            handleSearchChange={handleSearchChange}
            hanldeOnDelete={hanldeOnDelete} 
            handleOnArchive={handleOnArchive} 
            rightClickKey={rightClickKey}
            handleRowClick={handleRowClick}
            toggleStar={toggleStar}
            isToggleLoading={loaderIds.includes(rowOpen?.id)}
            emailPeople={emailPeople}
            isLoading={isLoading}
            isApiCall={isApiCall}
            setIsLoading={setIsLoading}
            getEmailPeople={_getEmailPeople}
            rowOpen={rowOpen}
            isLoadDetected={isLoadDetected} 
            draftloadEmails={draftloadEmails}
            updateDraftloadEmails={updateDraftloadEmails}
            loadId={loadId}
            setRowOpen={setRowOpen}
            peopleLoader={peopleLoader}
            isFromLoad={isFromLoad}
            allEmails={allEmails}
            handleScroll={handleScroll}
            activeNav={activeNav}
            allEmailStatus={allEmailStatus}
            showFilterBar={showFilterBar}
            loaderIds={loaderIds}
            showEmailDetailsModalR={showEmailDetailsModalR}
            hide={handleHideEmailDetailsModal}
            emailFilters={emailFilters}
            setFilterKey={setFilterKey}
            subCardFilter={subCardFilter}
            setSubCardKey={setSubCardKey}
            isMailInfoLoader={isMailInfoLoader}
            isEmailMode={isEmailMode}
            handleNextEmail={handleNextEmail}
            handlePrevEmail={handlePrevEmail}
            prevEmail={prevEmail}
            nextEmail={nextEmail}
            handleCheckboxChange={handleCheckboxChange}
            selectedRows={selectedRows}
            setIsGetEmail={setIsGetEmail}
            isGetEmail={isGetEmail}
            skipRef={skipRef}
            archiveLoaderIds={archiveLoaderIds}
            handleBottomUnread={handleBottomUnread}
            setSelectedRows={setSelectedRows}
            setRightClickKey={setRightClickKey}
            _getCounts={_getCounts}
            isEditModeOn={isEditModeOn}
            setIsEditMode={setIsEditMode}
            allTags={allTags}
            handleSearchTerm={handleSearchTerm}
            isTagsLoading={isTagsLoading}
            setShowCreateTag={setShowCreateTag}
            getTags={getTags}
            readUnreadIds={readUnreadIds}
            activePage={activePage}
            allEmailSignature={allEmailSignature}
            getAllEmailSignatures={getAllEmailSignatures}
            threadUpdates={threadUpdates}
            isSentEmailTab={isSentEmailTab}
            updateActivePageKey={updateActivePageKey}
            searchTerm={searchTerm}
            _getDraftLoadEmailInfo={_getDraftLoadEmailInfo}
            setIsLoadDetected={setIsLoadDetected}
            updateSelectedRowAfterReply={updateSelectedRowAfterReply}
      />
    }
    
      {isGetEmail && isEmailMode === 'grid' ? <EmailDetailSkeleton /> : showEmailDetailsModal  && isEmailMode === 'grid' && (
        <Modal show={showEmailDetailsModal} dialogClassName="modal-fullpage modal-dialog-scrollable modal-email-dialog vh-100" animation={false}>
          <EmailDetailsModal 
            show={showEmailDetailsModal} 
            hide={handleHideEmailDetailsModal} 
            rowData={selectedRow} 
            hanldeOnDelete={hanldeOnDelete} 
            handleOnArchive={handleOnArchive} 
            rightClickKey={rightClickKey}
            handleRowClick={handleRowClick}
            toggleStar={toggleStar}
            isArchiveLoader={archiveLoaderIds.includes(rowOpen?.threadId)}
            isToggleLoading={loaderIds.includes(rowOpen?.threadId)}
            isReadUnreadLoding={readUnreadIds.includes(rowOpen?.threadId)}
            emailPeople={emailPeople}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            getEmailPeople={_getEmailPeople}
            rowOpen={rowOpen}
            isLoadDetected={isLoadDetected} 
            draftloadEmails={draftloadEmails}
            updateDraftloadEmails={updateDraftloadEmails}
            loadId={loadId}
            setRowOpen={setRowOpen}
            peopleLoader={peopleLoader}
            isFromLoad={isFromLoad}
            emailFilters={emailFilters}
            setFilterKey={setFilterKey}
            subCardFilter={subCardFilter}
            setSubCardKey={setSubCardKey}
            handleNextEmail={handleNextEmail}
            handlePrevEmail={handlePrevEmail}
            prevEmail={prevEmail}
            nextEmail={nextEmail}
            setIsGetEmail={setIsGetEmail}
            isGetEmail={isGetEmail}
            archiveLoaderIds={archiveLoaderIds}
            isEmailMode={isEmailMode}
            handleBottomUnread={handleBottomUnread}
            setShowCreateTag={setShowCreateTag}
            allTags={allTags}
            activePage={activePage}
            allEmailSignature={allEmailSignature}
            getAllEmailSignatures={getAllEmailSignatures}
            setRightClickKey={setRightClickKey}
            setSelectedRow={setSelectedRow}
            threadUpdates={threadUpdates}
            updateActivePageKey={updateActivePageKey}
            _getDraftLoadEmailInfo={_getDraftLoadEmailInfo}
            setIsLoadDetected={setIsLoadDetected}
            updateSelectedRowAfterReply={updateSelectedRowAfterReply}
          />
        </Modal>
      )}
      {selectedRows?.length > 0 && (
        <>
          <EmailBottombar
            handleHideBatchMenu={handleHideBatchMenu}
            selectedRows={selectedRows}
            allEmails={allEmails}
            _getCounts={_getCounts}
            setAllEmails={setAllEmails}
            activeNav={activeNav}
            isFromLoad={isFromLoad}
            isFromQuote={isFromQuote}
            quoteId={quoteId}
            loadId={loadId}
            _getEmailByLoad={(param, reset)=>{_getEmailByLoad(param, reset)}}
            _getEmailByQuote={_getEmailByQuote}
            onDisconnectMail={onDisconnectMail}
            allEmailSignature={allEmailSignature}
            getAllEmailSignatures={getAllEmailSignatures}
            allEmailStatus={allEmailStatus}
            allTags={allTags}
            isTagsLoading={isTagsLoading}
            handleSearchTerm={handleSearchTerm}
            assignTagsToEmails={assignTagsToEmails}
            isEmailMode={isEmailMode}
            handleOnArchive={handleOnArchive}
            activePage={activePage}
            selectedRow={selectedRow}
            getReply={(isReply) => {
              handleRowClick(rowOpen, null, null, isReply)
            }}
            isSubFolderActive={isSubFolderActive}
            updateActivePageKey={updateActivePageKey}
          />
        </>
      )}
      {showSendEmailModal &&
        <CreateEmailModal
          show={showSendEmailModal}
          hide={() => { handleShowEmailModal(false, {}) }}
          draftEmail={draftEmail}
          allEmailSignature={allEmailSignature}
          getAllEmailSignatures={getAllEmailSignatures}
        />}

      <ReactTooltip 
        id="email-table-tooltip" 
        effect="solid"
        delayShow={200}
      />
    </>
  )
}

export default EmailTable
