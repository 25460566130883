const REPORT_NAMES = {
  // Admin
  CREATED_LOADS: "Created Loads",
  OPEN_LOADS: "Open Loads",
  PICKED_UP: "Picked Up",
  DELIVERED: "Delivered",
  RETURNED: "Returned",
  LOADS_BY_TYPE: "Loads By Type",
  LOADS_BY_CUSTOMER: "Loads By Cutomer",
  TOP_DELIVERY_LOCATIONS: "Top Delivery Locations",
  TOP_RETURN_LOCATIONS: "Top Return Locations",
  TOP_PICK_UP_LOCATIONS: "Top Pick Up Locations",
  COMPLETED_LOADS: "Completed Loads",
  // Broker
  BROKER_TENDERS_ACCEPTED:"Tenders Accepted",
  BROKER_TENDERS_REJECTED: "Tenders Rejected",
  BROKER_AMOUNT_OF_TENDERS:"Amount of Tenders",
  BROKER_TENDERS_ACCEPTED_BY_CARRIER: "Tenders Accepted by Carrier",
  BROKER_TENDERS_SENT_BY_CARRIER: "Tenders Sent by Carrier",
  BROKER_INVOICES_RECEIVED_BY_CARRIER: "Invoices Received by Carrier",

  // Employees
  DRIVER_PAY_APPROVES: "Driver Pay Approved",
  DRIVER_SETTLEMENTS_FINALIZED: "Driver Settlements Finalized",
  // Financials
  FINANCIAL_DAYS_TO_INVOICE: "Days to Invoice",
  FINANCIAL_NUMBER_OF_INVOICES: "Number of Invoices",
  FINANCIAL_TOTAL_PROFIT: "Total Profit",
  FINANCIAL_GROSS_DRIVER_PAY: "Gross Driver Pay",
  FINANCIAL_GROSS_VENDOR_PAY: "Gross Vendor Pay",
  FINANCIAL_AVERAGE_MARGIN: "Average Margin",
  FINANCIAL_REVENUE_BY_LOAD_TYPE: "Revenue by Load Type",
  FINANCIAL_REVENUE_BY_CUSTOMER: "Revenue by Customer",
  FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: "Revenue by Delivery Location",
  FINANCIAL_MOST_PROFITABLE_CUSTOMERS: "Most Profitable Customers",
  FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: "Most Profitable Delivery Locations",
  FINANCIAL_REVENUE_BY_CHARGE_NAME: "Revenue by Charge Name",
  FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: "Most Profitable Customers Percentage",
  FINANCIAL_PAST_DUE_INVOCES: "Past Due invoices",
  FINANCIAL_TOP_EARNING_DRIVERS: "Top Earning Drivers",
  FINANCIAL_PERCENTAGE_OF_MARGIN: "Percentage of Margin",
  // Equipment
  EQUIPMENT_TRUCK_USAGE: "Truck Usage",
  EQUIPMENT_CHASSIS_USAGE :"Chassis Usage",
  EQUIPMENT_TRAILER_USAGE : "Trailer Utilization",
  EQUIPMENT_CHASSIS_LAST_USED: "Chassis",
  EQUIPMENT_TRUCK_LAST_USED: "Truck",
  EQUIPMENT_TRAILER_LAST_USED: "Trailer",

  // Customer service
  CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: "Employee Updates per Load",
  CUSTOMER_SERVICE_LOADS_CREATED: 'Loads Created',
  CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: "Loads Assigned to CSR",
  CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY: "Loads Created by Day",

  // Operations
  OPERATION_LOADS_COMPLETED: "Completed - Day",
  OPERATION_LOADS_COMPLETED_BY_WEEK: "Completed - Day Of Week",
  OPERATION_TOTAL_MOVES_COMPLETED: "Total Moves Completed",
  OPERATION_MOVES_COMPLETED_BY_DRIVER: "Moves Completed By Driver",
  OPERATION_EVENTS_BY_DRIVER: "Breakdown Of Moves By Driver",
  OPERATION_TIME_TO_TERMINATE: "Time To Terminate",
  OPERATION_TIME_TO_RETURN: "Time To Return",
  OPERATION_DISPATCHES_BY_EMPLOYEE: "Dispatches By Employee",
  OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: "Daily Average Moves Per Driver",

  // System
  SYSTEM_CHARGE_CREATION_PERCENTAGE: "Charge Creation Percentage",
  SYSTEM_PAY_CREATION_PERCENTAGE: "Pay Creation Percentage",
  SYSTEM_LOAD_STATUS_PERCENTAGE: "Load Status Percentage",
  SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: "Document Uploads Percentage",
  SYSTEM_LOAD_CREATED_BY: "Load Created By",
  SYSTEM_CRON_REPORTS: "Cron Reports",

  // Employee
  EMPLOYEE_DRIVER_PAY_APPROVED: "Driver Pay Approved",
  EMPLOYEE_SETTLMENT_APPROVED: "Driver Settlements Finalized",

  // Customer
  CUSTOMER_CUSTOMER_PORTAL_USAGE: "Customer Portal Usage",
  CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: "Customer Portal Usage Percentage",
  CUSTOMER_TIMES_MARKED_READY_TO_RETURN: "Times Marked Ready to Return",

  // Track
  TRACK_PER_DIEM_PERCENTAGE: "Per Diem Percentage",
  TRACK_LAST_FREE_DAY_PERCENTAGE: "LFD Percentage",
  TRACK_NUMBER_OF_TIRS: "TIRs Uploaded",
  TRACK_PER_DIEM_TOTAL: "Per Diem Total",
  TRACK_DEMURRAGE_TOTAL: "Demurrage Total",
  TRACK_DWELL_TIME: "Dwell Time at Port",
  TRACK_NO_OF_APPTS: "Number of Appts",
}

const CREATED_LOADS = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CREATED_LOADS,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}
const COMPLETED_LOADS = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.COMPLETED_LOADS,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const PICKED_UP = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.PICKED_UP,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const DELIVERED = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.DELIVERED,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const RETURNED = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.RETURNED,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const FINANCIAL_NUMBER_OF_INVOICES = {
  columns: [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.Customer}</div>;
      },
    },
    {
      key: "revenue",
      name: "# of Invoices",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["# of Invoices"])}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_NUMBER_OF_INVOICES,
  isFilter: false
}

const FINANCIAL_GROSS_DRIVER_PAY = {
  columns: [
    {
      key: "company_name",
      name: "Driver Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver Name"]}</div>;
      },
    },
    {
      key: "revenue",
      name: "Gross Pay",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Gross Pay"])?.toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_GROSS_DRIVER_PAY,
  isFilter: false
}

const FINANCIAL_GROSS_VENDOR_PAY = {
  columns: [
    {
      key: "company_name",
      name: "Vendor Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Vendor Name"]}</div>;
      },
    },
    {
      key: "revenue",
      name: "Gross Pay",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Gross Pay"])?.toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_GROSS_VENDOR_PAY,
  isFilter: false
}

const FINANCIAL_DAYS_TO_INVOICE = {
  columns: [
    {
      key: "company_name",
      name: "Load #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load #"]}</div>;
      },
    },
    {
      key: "revenue",
      name: "Days To Invoice",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Days To Invoice"])}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_DAYS_TO_INVOICE,
  isFilter: false
}

const FINANCIAL_AVERAGE_MARGIN = {
  columns: [
    {
      key: "load",
      name: "Load Number",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Number"]}</div>;
      },
    },
    {
      key: "margin",
      name: "Margin ($)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Margin ($)"])}</div>;
      },
    },
    {
      key: "margin",
      name: "Margin (%)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Margin (%)"])} %</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_AVERAGE_MARGIN,
  isFilter: false
}

const FINANCIAL_REVENUE_BY_CUSTOMER = {
  columns: [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "revenue",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)?.toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_CUSTOMER,
  // description: "Total revenue broken down by Customer",
  isFilter: false
};

const LOADS_BY_TYPE = {
  columns: [
    {
      key: "name",
      name: "Load Type",
      formatter: ({ row }) => {
        return <span className={`font-12 text-white font-weight-normal badge ml-2 ${typeOfLoadBadgeColor[row?.name]}`}>{row?.name}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.LOADS_BY_TYPE,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
};

const TOP_RETURN_LOCATIONS = {
  columns: [
    {
      key: "company_name",
      name: "Return Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.TOP_RETURN_LOCATIONS,
  // description: "Total revenue broken down by Customer",
  isFilter: false
};

const BROKER_TENDERS_ACCEPTED = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.BROKER_TENDERS_ACCEPTED,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const BROKER_TENDERS_REJECTED = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.BROKER_TENDERS_REJECTED,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const BROKER_AMOUNT_OF_TENDERS = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.BROKER_AMOUNT_OF_TENDERS,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const BROKER_TENDERS_ACCEPTED_BY_CARRIER = {
  columns: [
    {
      key: "carrier",
      name: "Carrier",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "# of Tenders Accepted",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.BROKER_TENDERS_ACCEPTED_BY_CARRIER,
  // description: "All the created loads for that date range broken down by Customer",
  isFilter: false
};

const BROKER_TENDERS_SENT_BY_CARRIER = {
  columns: [
    {
      key: "carrier",
      name: "Carrier",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "# of Tenders Sent",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.BROKER_TENDERS_SENT_BY_CARRIER,
  // description: "All the created loads for that date range broken down by Customer",
  isFilter: false
}

const BROKER_INVOICES_RECEIVED_BY_CARRIER = {
  columns: [
    {
      key: "carrier",
      name: "Carrier",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "# of Invoices Receives",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.BROKER_INVOICES_RECEIVED_BY_CARRIER,
  // description: "All the created loads for that date range broken down by Customer",
  isFilter: false
}

const FINANCIAL_REVENUE_BY_LOAD_TYPE = {
  columns: [
    {
      key: "name",
      name: "Load Type",
      formatter: ({ row }) => {
        return <div className={`font-12 text-white font-weight-normal badge ml-2 ${typeOfLoadBadgeColor[row?.company_name]}`}>{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_LOAD_TYPE,
  // description: "Total revenue broken down by Load Type",
  isFilter: false
};

const FINANCIAL_REVENUE_BY_DELIVERY_LOCATION = {
  columns: [
    {
      key: "company_name",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
  // description: "Total revenue broken down by Delivery Location",
  isFilter: false
};

const FINANCIAL_MOST_PROFITABLE_CUSTOMERS = {
  columns: [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Profit",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_MOST_PROFITABLE_CUSTOMERS,
  // description: "Customers broken down by which are the most profitable",
  isFilter: false
};

const FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS = {
  columns: [
    {
      key: "company_name",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Profit",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
  // description: "Delivery locations broken down by which are the most profitable",
  isFilter: false
};

const FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE = {
  columns: [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Profit ($ amount)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
    {
      key: "percentage",
      name: "Profit (% of Total Profit)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{`${(row?.percentage || 0)} %`}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
  // description: "Customers profitability broken down as a perctange. So seeing what percentage that customer is of your total profit",
  isFilter: false
};

const FINANCIAL_PAST_DUE_INVOCES = {
  columns: [
    {
      key: "name",
      name: "Invoice #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Date Invoiced",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{`${(row?.count || 0)}`}</div>;
      },
    },
    {
      key: "status",
      name: "Payment Status",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{`${(row?.status || "")}`}</div>;
      },
    }
  ],
  title: REPORT_NAMES?.FINANCIAL_PAST_DUE_INVOCES,
  // description: "Percentage of invoices past due",
  isFilter: false
};

const FINANCIAL_TOP_EARNING_DRIVERS = {
  columns: [
    {
      key: "company_name",
      name: "Driver Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Net Pay",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_TOP_EARNING_DRIVERS,
  // description: "Drivers with the highest net pay (driver pay - deductions)",
  isFilter: false
};

const FINANCIAL_PERCENTAGE_OF_MARGIN = {
  columns: [
    {
      key: "Margin Badge",
      name: "Margin Badge",
      formatter: ({ row }) => {
        const colorOfBadges = {
          "Low": "bg-danger-500",
          "Middle": "bg-warning-300",
          "High": "bg-success-500"
        }
        return <div className={`font-12 p-2 text-dark font-weight-normal badge ${colorOfBadges?.[row?.company_name]} ml-2`}>{row?.company_name}</div>;
      },
    },
    {
      key: "% of Loads",
      name: "% of Loads",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)} %</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_PERCENTAGE_OF_MARGIN,
  // description: "Drivers with the highest net pay (driver pay - deductions)",
  isFilter: false
};

const FINANCIAL_REVENUE_BY_CHARGE_NAME = {
  columns: [
    {
      key: "chargeName",
      name: "Charge Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "percentage",
      name: "% of Total Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.percentage}</div>;
      },
    },
    {
      key: "count",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_CHARGE_NAME,
  // description: "Total revenue broken down by charge name",
  isFilter: false
};

const FINANCIAL_TOTAL_PROFIT = {
  columns: [
    {
      key: "chargeName",
      name: "Load Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Customer"]}</div>;
      },
    },
    {
      key: "percentage",
      name: "Profit",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Profit"]}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_CHARGE_NAME,
  isFilter: false
}

const TOP_DELIVERY_LOCATIONS = {
  columns: [
    {
      key: "company_name",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.TOP_DELIVERY_LOCATIONS,
  // description: "Total revenue broken down by Customer",
  isFilter: false
};

const TOP_PICK_UP_LOCATIONS = {
  columns: [
    {
      key: "company_name",
      name: "Pick Up Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.TOP_PICK_UP_LOCATIONS,
  // description: "Total revenue broken down by Customer",
  isFilter: false
};

const LOADS_BY_CUSTOMER = {

  columns: [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Load Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.LOADS_BY_CUSTOMER,
  isFilter: false
};

const typeOfLoadBadgeColor = {
  Import: 'bg-purple-200',
  Export: 'bg-slategray-600',
  Road: 'bg-green-150',
  Bill: 'bg-steelblue-400',
}

const OPERATION_LOADS_COMPLETED = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_LOADS_COMPLETED,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const OPERATION_TOTAL_MOVES_COMPLETED = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_LOADS_COMPLETED_BY_WEEK,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const OPERATION_TIME_TO_RETURN = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_TIME_TO_RETURN,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const OPERATION_TIME_TO_TERMINATE = {
  columns: [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_TIME_TO_TERMINATE,
  // description: "All the created loads for that date range broken down by type",
  isFilter: false
}

const OPERATION_LOADS_COMPLETED_BY_WEEK = {
  columns: [
    {
      key: "day",
      name: "Day of Week",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_loads",
      name: "Loads Completed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_LOADS_COMPLETED_BY_WEEK,
  isFilter: false
};

const OPERATION_MOVES_COMPLETED_BY_DRIVER = {
  columns: [
    {
      key: "driver_name",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_loads",
      name: "Moves Completed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_MOVES_COMPLETED_BY_DRIVER,
  isFilter: false
};

const OPERATION_DISPATCHES_BY_EMPLOYEE = {
  columns: [
    {
      key: "employee",
      name: "Employee",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Number of Dispatches",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_DISPATCHES_BY_EMPLOYEE,
  isFilter: false
};

const OPERATION_EVENTS_BY_DRIVER = {
  columns: [
    {
      key: "driver_name",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "Pulled",
      name: "Show Pulled",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Pulled || 0)}</div>;
      },
    },
    {
      key: "Delivered",
      name: "Delivered",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Delivered || 0)}</div>;
      },
    },
    {
      key: "Dropped",
      name: "Dropped",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Dropped || 0)}</div>;
      },
    },
    {
      key: "Returned",
      name: "Returned",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Returned || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_EVENTS_BY_DRIVER,
  isFilter: false
};

const OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER = {
  columns: [
    {
      key: "employee",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Average No. of moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
  isFilter: false
};

const CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD = {
  columns: [
    {
      key: "employee",
      name: "Employee Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Update Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
  isFilter: false
};

const CUSTOMER_SERVICE_LOADS_CREATED = {
  columns: [
    {
      key: "employee",
      name: "Employee Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Loads Created",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CUSTOMER_SERVICE_LOADS_CREATED,
  isFilter: false
};

const CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR = {
  columns: [
    {
      key: "employee",
      name: "CSR Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "pending_load",
      name: "Pending",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.pending_load || 0}</div>;
      },
    },
    {
      key: "dispatched_load",
      name: "Dispatched",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.dispatched_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_chassis_load",
      name: "Enroute to Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_chassis_load || 0}</div>;
      },
    },
    {
      key: "arrived_to_chassis_load",
      name: "Arrived to Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_to_chassis_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_pick_container_load",
      name: "Enroute to Pick Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_pick_container_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_pick_container_load",
      name: "Arrived at Pick Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_pick_container_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_deliver_load",
      name: "Enroute to Deliver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_deliver_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_deliver_load",
      name: "Arrived at Deliver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_deliver_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_drop_container_load",
      name: "Enroute to Drop Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_drop_container_load || 0}</div>;
      },
    },
    {
      key: "dropped_load",
      name: "Dropped",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.dropped_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_hook_container_load",
      name: "Enroute to Hook Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_hook_container_load || 0}</div>;
      },
    },
    {
      key: "arrived_to_hook_container_load",
      name: "Arrived to Hook Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_to_hook_container_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_return_load",
      name: "Enroute to Return",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_return_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_return_load",
      name: "Arrived at Return",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_return_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_stop_off_load",
      name: "Arrived at Stop Off",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_stop_off_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_stop_of_load",
      name: "Enroute to Stop Off",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_stop_of_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_return_chassis_load",
      name: "Enroute to Return Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_return_chassis_load || 0}</div>;
      },
    },
    {
      key: "arrived_to_return_chassis_load",
      name: "Arrived to Return Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_to_return_chassis_load || 0}</div>;
      },
    },
    {
      key: "available_load",
      name: "Available",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.available_load || 0}</div>;
      },
    },
    {
      key: "departed_load",
      name: "Departed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.departed_load || 0}</div>;
      },
    },
    {
      key: "completed_load",
      name: "Completed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.completed_load || 0}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
  isFilter: false
};

const CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY = {
  columns: [
    {
      key: "day",
      name: "Load Created Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.day}</div>;
      },
    },
    {
      key: "employee",
      name: "Employee Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.employeeName}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Counts of Loads Created",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY,
  isFilter: false
};

const SYSTEM_PAY_CREATION_PERCENTAGE = {
  columns: [
    {
      key: "name",
      name: "Pay Creation Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ],
  title: REPORT_NAMES?.SYSTEM_PAY_CREATION_PERCENTAGE,
  isFilter: false
}

const SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE = {
  columns: [
    {
      key: "name",
      name: "Document Upload Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ],
  title: REPORT_NAMES?.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
  isFilter: false
}

const SYSTEM_LOAD_CREATED_BY = {
  columns: [
    {
      key: "name",
      name: "Load Creation Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ],
  title: REPORT_NAMES?.SYSTEM_LOAD_CREATED_BY,
  isFilter: false
}

const SYSTEM_LOAD_STATUS_PERCENTAGE = {
  columns: [
    {
      key: "name",
      name: "Load Status Update Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ],
  title: REPORT_NAMES?.SYSTEM_LOAD_STATUS_PERCENTAGE,
  isFilter: false
}

const SYSTEM_CRON_REPORTS = {
  columns: [
    {
      key: "name",
      name: "Report Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.reportName}</div>;
      },
    },
    {
      key: "count",
      name: "# of times sent",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "Last Sent",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.lastSent || '')}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.SYSTEM_CRON_REPORTS,
  isFilter: false
}

const SYSTEM_CHARGE_CREATION_PERCENTAGE = {
  columns: [
    {
      key: "name",
      name: "Pay Creation Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ],
  title: REPORT_NAMES?.SYSTEM_CHARGE_CREATION_PERCENTAGE,
  isFilter: false
}

const EMPLOYEE_SETTLMENT_APPROVED = {
  columns: [
    {
      key: "name",
      name: "Employee Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Count of Settlements Approved",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EMPLOYEE_SETTLMENT_APPROVED,
  isFilter: false
}

const EMPLOYEE_DRIVER_PAY_APPROVED = {
  columns: [
    {
      key: "name",
      name: "Employee Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Count of Approved Line Items",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EMPLOYEE_DRIVER_PAY_APPROVED,
  isFilter: false
}

const CUSTOMER_CUSTOMER_PORTAL_USAGE = {
  columns: [
    {
      key: "name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "# of Active Sessions",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "lastSession",
      name: "Last Session",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.lastSession}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CUSTOMER_CUSTOMER_PORTAL_USAGE,
  isFilter: false
}

const CUSTOMER_TIMES_MARKED_READY_TO_RETURN = {
  columns: [
    {
      key: "name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Load #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "lastDateMarkedReadytoReturn",
      name: "Date marked ready to Return",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.lastDateMarkedReadytoReturn}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
  isFilter: false
}

const CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE = {
  columns: [
    {
      key: "name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Active within Timeframe",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
  isFilter: false
}
const TRACK_PER_DIEM_PERCENTAGE = {
  columns: [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.referenceNumber}</div>;
      },
    },
    {
      key: "loadReturnedOnTime",
      name: "Per Diem",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.loadReturnedOnTime}</div>;
      },
    },
  ],
  title: REPORT_NAMES.TRACK_PER_DIEM_PERCENTAGE,
  isFilter: false
}

const TRACK_LAST_FREE_DAY_PERCENTAGE = {
  columns: [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.referenceNumber}</div>;
      },
    },
    {
      key: "loadPickedOnTime",
      name: "LFD on Time",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.loadPickedOnTime}</div>;
      },
    },
  ],
  title: REPORT_NAMES.TRACK_LAST_FREE_DAY_PERCENTAGE,
  isFilter: false
}

const TRACK_NUMBER_OF_TIRS = {
  columns: [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.referenceNumber}</div>;
      },
    },
    {
      key: "tirUploaded",
      name: "TIR Uploaded",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.tirUploaded}</div>;
      },
    },
  ],
  title: REPORT_NAMES.TRACK_NUMBER_OF_TIRS,
  isFilter: false
}

const TRACK_PER_DIEM_TOTAL = {
  columns: [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.referenceNumber}</div>;
      },
    },
    {
      key: "freeReturnDate",
      name: "Free Return Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.freeReturnDate}</div>;
      },
    },
    {
      key: "ingateDate",
      name: "Ingate Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.ingateDate}</div>;
      },
    },
    {
      key: "perDiemDaysCount",
      name: "Per Diem Days Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.perDiemDaysCount}</div>;
      },
    },
  ],
  title: REPORT_NAMES.TRACK_PER_DIEM_TOTAL,
  isFilter: false
}

const TRACK_DEMURRAGE_TOTAL = {
  columns: [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.referenceNumber}</div>;
      },
    },
    {
      key: "lastFreeDay",
      name: "LFD Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.lastFreeDay}</div>;
      },
    },
    {
      key: "outgateDate",
      name: "Outgate Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.outgateDate}</div>;
      },
    },
    {
      key: "demurrageDaysCount",
      name: "Demurrage Days Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.demurrageDaysCount}</div>;
      },
    },
  ],
  title: REPORT_NAMES.TRACK_DEMURRAGE_TOTAL,
  isFilter: false
}

const TRACK_DWELL_TIME = {
  columns: [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.referenceNumber}</div>;
      },
    },
    {
      key: "dwellDay",
      name: "Dwell Time (days)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{Number(row?.dwellDay)?.toFixed(2)}</div>;
      },
    },
  ],
  title: REPORT_NAMES.TRACK_DWELL_TIME,
  isFilter: false
}

const TRACK_NO_OF_APPTS = {
  columns: [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.referenceNumber}</div>;
      },
    },
    {
      key: "confirmedApptCount",
      name: "# of Appts (Confirmed)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.confirmedApptCount}</div>;
      },
    },
  ],
  title: REPORT_NAMES.TRACK_NO_OF_APPTS,
  isFilter: false
}

const EQUIPMENT_CHASSIS_USAGE = {
  columns: [
    {
      key: "chassisNo",
      name: "Chassis #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Count of Completed Moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EQUIPMENT_CHASSIS_USAGE,
  isFilter: false
};

const EQUIPMENT_TRUCK_USAGE = {
  columns: [
    {
      key: "truckNo",
      name: "Truck #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.truckNo}</div>;
      },
    },
    {
      key: "driverName",
      name: "Driver Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.driverName}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Count of Completed Moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EQUIPMENT_TRUCK_USAGE,
  isFilter: false
};

const EQUIPMENT_TRAILER_USAGE = {
  columns: [
    {
      key: "trailerNo",
      name: "Trailer #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Count of Completed Moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EQUIPMENT_TRAILER_USAGE,
  isFilter: false
};

const EQUIPMENT_CHASSIS_LAST_USED = {
  columns: [
    {
      key: "chassisNo",
      name: "Chassis #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "dateLastUsed",
      name: "Date Last Used",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "daysSinceLastUse",
      name: "Days Since Last Use",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EQUIPMENT_CHASSIS_LAST_USED,
  isFilter: false
};

const EQUIPMENT_TRUCK_LAST_USED = {
  columns: [
    {
      key: "truck",
      name: "Truck #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "dateLastUsed",
      name: "Date Last Used",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "daysSinceLastUse",
      name: "Days Since Last Use",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EQUIPMENT_TRUCK_LAST_USED,
  isFilter: false
};

const EQUIPMENT_TRAILER_LAST_USED = {
  columns: [
    {
      key: "trailer",
      name: "Trailer #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "dateLastUsed",
      name: "Date Last Used",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "daysSinceLastUse",
      name: "Days Since Last Use",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)}</div>;
      },
    },
  ],
  title: REPORT_NAMES?.EQUIPMENT_TRAILER_LAST_USED,
  isFilter: false
};

export const REPORT_CONFIGS = {
  //Admin
  LOADS_BY_TYPE,
  LOADS_BY_CUSTOMER,
  TOP_RETURN_LOCATIONS,
  TOP_DELIVERY_LOCATIONS,
  TOP_PICK_UP_LOCATIONS,
  COMPLETED_LOADS,
  CREATED_LOADS,
  PICKED_UP,
  DELIVERED,
  RETURNED,

  //Broker
  BROKER_AMOUNT_OF_TENDERS,
  BROKER_TENDERS_ACCEPTED,
  BROKER_TENDERS_REJECTED,
  BROKER_TENDERS_ACCEPTED_BY_CARRIER,
  BROKER_TENDERS_SENT_BY_CARRIER,
  BROKER_INVOICES_RECEIVED_BY_CARRIER,

  //Financials
  FINANCIAL_REVENUE_BY_CUSTOMER,
  FINANCIAL_NUMBER_OF_INVOICES,
  FINANCIAL_GROSS_DRIVER_PAY,
  FINANCIAL_GROSS_VENDOR_PAY,
  FINANCIAL_DAYS_TO_INVOICE,
  FINANCIAL_AVERAGE_MARGIN,
  FINANCIAL_REVENUE_BY_LOAD_TYPE,
  FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
  FINANCIAL_MOST_PROFITABLE_CUSTOMERS,
  FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
  FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
  FINANCIAL_PAST_DUE_INVOCES,
  FINANCIAL_TOP_EARNING_DRIVERS,
  FINANCIAL_PERCENTAGE_OF_MARGIN,
  FINANCIAL_REVENUE_BY_CHARGE_NAME,
  FINANCIAL_TOTAL_PROFIT,

  //Operations
  OPERATION_LOADS_COMPLETED,
  OPERATION_TOTAL_MOVES_COMPLETED,
  OPERATION_TIME_TO_RETURN,
  OPERATION_TIME_TO_TERMINATE,
  OPERATION_LOADS_COMPLETED_BY_WEEK,
  OPERATION_MOVES_COMPLETED_BY_DRIVER,
  OPERATION_DISPATCHES_BY_EMPLOYEE,
  OPERATION_EVENTS_BY_DRIVER,
  OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,

  //Customer Service
  CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
  CUSTOMER_SERVICE_LOADS_CREATED,
  CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
  CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY,
  
  //System
  SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
  SYSTEM_PAY_CREATION_PERCENTAGE,
  SYSTEM_LOAD_CREATED_BY,
  SYSTEM_CHARGE_CREATION_PERCENTAGE,
  SYSTEM_LOAD_STATUS_PERCENTAGE,
  SYSTEM_CRON_REPORTS,

  // Employee
  EMPLOYEE_SETTLMENT_APPROVED,
  EMPLOYEE_DRIVER_PAY_APPROVED,

  // Customer
  CUSTOMER_CUSTOMER_PORTAL_USAGE,
  CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
  CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,

  // Equipment
  EQUIPMENT_CHASSIS_USAGE,
  EQUIPMENT_TRUCK_USAGE,
  EQUIPMENT_TRAILER_USAGE,
  EQUIPMENT_CHASSIS_LAST_USED,
  EQUIPMENT_TRUCK_LAST_USED,
  EQUIPMENT_TRAILER_LAST_USED,
  
  //Track
  TRACK_PER_DIEM_PERCENTAGE,
  TRACK_LAST_FREE_DAY_PERCENTAGE,
  TRACK_NUMBER_OF_TIRS,
  TRACK_PER_DIEM_TOTAL,
  TRACK_DEMURRAGE_TOTAL,
  TRACK_DWELL_TIME,
  TRACK_NO_OF_APPTS,
}