import React, { memo, useState, useRef, useEffect } from 'react'
import BillingCard from './Components/BillingCard';
import Footer from './Components/Footer';
import { getBillingChargeLists } from 'pages/tms/Load/Billing/actionCreator'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import _ from 'lodash';
import { getByload } from 'pages/tms/CreditMemoV2/actionCreator';
import BillingSummaryCountCard from 'Components/Common/BillingSummaryCountCard';
import {  getStorage, isCreditMemo } from 'services';
import { LoaderBar } from 'Components/Common/LoaderBar';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import * as tmsAction from "../../../tms/Load/actionCreator";
import { amplitudeTrack } from '../../../../services';

const BillingModule = (props) => {
  const { loads, updateLoadSummaryCreditMemos, getLoadNotesCount } = props;
  const historyTabList = [{label:"Charge", value: "charge"}, {label:"Invoice", value: "invoice"}];
  const [billingCards, setBillingCards] = useState([]);
  const [reloadBillingSummary, setReloadBillingSummary] = useState(1)
  const scrollWrapperRef = useRef(null);
  const [loadCreditMemos, setLoadCreditMemos] = useState([])
  const [rateRecordReference, setRateRecordReference] = useState(null)

  const [showInvoiceApprovalHistory, setShowInvoiceApprovalHistory] = useState(false)
  const [historyLoader, setHistoryLoader] = useState(false)
  const [allHistory, setAllHistory] = useState([]);
  const [historyListBasedOnHistoryType, setHistoryListBasedOnHistoryType] = useState([]);
  const [selectedHistoryTab, setSelectedHistoryTab] = useState("charge");
  useEffect(() => {
    const data = {
      "loadId" : loads?._id
    }
    props.actions.getBillingChargeLists(data).then((res) => {
      if (res?.status === 200) {
        const rateRecordReferenceData= res?.data?.data?.find(singleResponseData => {
          if(singleResponseData?.rateRecordReference?._id || singleResponseData?.rateRecordReference?.name) return true;
        })

        if(rateRecordReferenceData) setRateRecordReference(rateRecordReferenceData?.rateRecordReference)
        setBillingCards(res?.data?.data);
      }
    }).catch()

  }, [loads]);

  useEffect(() => {
    billingCards?.map((data) => {
      if (!data?.billToId) {
        if (scrollWrapperRef?.current) {
          scrollWrapperRef?.current?.scrollIntoView({ behavior: "smooth", block: "end"});
        }
      }
    })

  }, [billingCards, scrollWrapperRef]);

  useEffect(() => {
    if(isCreditMemo()){
      getAllCreditMemoForLoad()
    }
  }, [])

  const getAllCreditMemoForLoad = async () => {
    const query = {
      reference_number: loads?.reference_number
    }
    const data = await getByload(query);
    setLoadCreditMemos(data)
    updateLoadSummaryCreditMemos(data)
  }

  const updateChargeInChargeList = (charge, chargeIndex) => {
    const chargeList = _.cloneDeep(billingCards);
    let index = chargeIndex;
    if (!chargeIndex) index = chargeList.findIndex((obj) => obj?._id === charge?._id);

    if (index !== -1) {
      chargeList[index] = charge;
      setBillingCards(chargeList);
    } else {
      console.log(`Object with id ${charge?._id} not found.`);
    }
  };


  const addSupplementalCharges = () => {
    const findLastIndex = billingCards.length && billingCards[billingCards?.length - 1];
    if (findLastIndex?.billToId || billingCards?.length <= 0) {
      let eventProperties = {
        source: "AR_BILLING",
        loadNo: `${loads?.reference_number}`
      }
      amplitudeTrack(`${"AR_BILLING_ADD_CHARGE_SET"}`,eventProperties)
      const _pricingObj = {
        billToId: undefined,
        id: Math.random().toString(16).slice(2),
        pricing: [],
      };
      const newPricing = [];
      newPricing.push(_pricingObj);
      setBillingCards([...billingCards, ...newPricing]);
    }
  }

  const handleShowInvoiceHistory = (billingRow) => {
    setHistoryLoader(true)
    setShowInvoiceApprovalHistory(true)
    const params  = { 
      reference_number:billingRow?.isDefault ?  billingRow?.load_reference_number : billingRow?.charge_reference_number,
      charge_reference_number: billingRow?.charge_reference_number,
      isDefault: billingRow?.isDefault,
    };
    tmsAction.getApprovalPricingHistory(params).then((data) => {
        setAllHistory(data);
        const list = data?.filter(history=>history?.historyType===selectedHistoryTab) ?? [];
        setHistoryListBasedOnHistoryType(list);
        setHistoryLoader(false)
    }).catch(err => {
      setHistoryLoader(false)
      setShowInvoiceApprovalHistory(false)
    })

  }

  const handleReloadOfBillingSummary = () => {
    setReloadBillingSummary((reloadBillingSummary) => reloadBillingSummary + 1);
  }

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const handleSelectHistoryTab = (value)=>{
    const list = allHistory?.filter(history=>history?.historyType===value) ?? [];
    setHistoryListBasedOnHistoryType(list);
    setSelectedHistoryTab(value)
  }
  return (
    <>
    {loggedInUser.role === "carrier" || loggedInUser.permissions.includes("customer_service_billing") ? 
    <div ref={scrollWrapperRef}>

      {/* Top card section */}
      <div className="card p-15 mb-10 border-top mb-10 position-sticky top-0 z-4">
        <BillingSummaryCountCard loads={loads} selectedTab={"billing-total"} reloadBillingSummary={reloadBillingSummary} rateRecordReference= {rateRecordReference} getLoadNotesCount={getLoadNotesCount} />
      </div>

      {/* <BillingCard /> */}
        {billingCards?.map((row, index) => {
          return (
            <BillingCard
              billingCards={billingCards}
              setBillingCards={setBillingCards}
              billingRow={row}
              billingIndex={index}
              updateChargeInChargeList={updateChargeInChargeList}
              loadCreditMemos={loadCreditMemos?.find((charge) => charge._id === row.charge_reference_number) || []}
              getAllCreditMemoForLoad={getAllCreditMemoForLoad}
              handleReloadOfBillingSummary={handleReloadOfBillingSummary}
              handleShowInvoiceHistory={handleShowInvoiceHistory}
              {...props}
            />
          );
        })}
      
      {/* Invoice Approval History For Shipos  */}
      <Modal bsSize="sm" show={showInvoiceApprovalHistory} centered animation={false} className="modal backdrop_modal_open">
        <Modal.Header className='pb-2'>
          <Modal.Title>Approval History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul className="nav nav-compact gap-15 mb-1" role="tablist" style={{ width: 'fit-content'}}>
          {
            historyTabList?.map((d, i) => (
              <li className="nav-item" key={i.toString()}>
                <a
                  href="#!"
                  className={`nav-link ${selectedHistoryTab === d.value ? 'active' : ""}`}
                  onClick={() => handleSelectHistoryTab(d.value)}
                >{d.label}</a>
              </li>
            ))
          }
        </ul>
          {historyLoader && <LoaderBar />}
          <div className="mb-20">
            <table id="auditTable" className="table table-card table-card--wrap mb-10">
             <thead>
                <tr>
                  <th style={{ width: "25%" }}>Status</th>
                  <th style={{ width: "15%" }}>Amount</th>
                  <th style={{ width: "10%" }}>Time</th>
                  <th style={{ width: "50%" }}>Reason</th>
                </tr>
              </thead>

              <tbody>
                {historyListBasedOnHistoryType?.length >0
                  ? historyListBasedOnHistoryType?.map((row, index) => {
                      return (
                        <>
                          <tr>
                            <td className="d-flex align-items-center">
                              <span className="badge badge-gray-100 badge-sm">{row.status}</span>
                            </td>
                            <td>
                              <span className="badge badge-gray-100 badge-sm">$ {row.amount?.toFixed(2)}</span>
                            </td>
                            <td>
                              <div>{moment(row?.date).tz(getStorage("timeZone")).format("MM/DD/YY")}</div>
                              <div className="text-muted">
                                {moment(row?.date).tz(getStorage("timeZone")).format(`hh:mm`)}
                              </div>
                            </td>
                            <td>
                              <div className="auditField">
                                <div className="mb-2">
                                  <span>{row?.reason ? row?.reason : "-"}</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={() => setShowInvoiceApprovalHistory(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {/* Footer Section */}
      <div>
        <Footer addSupplementalCharges={addSupplementalCharges} disabled={loads?.isDeleted}/>
      </div>
    </div> :
      <div className="nopermission">
        <h1>You do not have permission to view this page</h1>
      </div>
    }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    billingChargeLists: state.TmsReducer.billingCharges,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getBillingChargeLists }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(BillingModule));
