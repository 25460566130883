import React, { Component, Fragment } from "react";

import * as Yup from "yup";
import $ from "jquery";
import _ from "lodash";

import Select from "react-select";
import { smallSelectStyle } from "../../../assets/js/select-style";
import { withFormik } from "formik";
import { connect } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import ReactTooltip from "react-tooltip";
import configuration from "../../../config";
import { Organization, Organizations, People, TMS_GET_CUSTOMERS_ALL } from "../constant";
import * as actionCreators from "./actionCreators";
import { AddProfile, getTerminal, syncCustomerToQB } from "../services";
import AddCustomerComponent from "./AddCustomerComponent";
import { getCustomers, getSubCustomer, getTaxCodes } from "./../Dispatcher/actionCreators";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import { Spinner } from "../../../Components/Common/Components/Component";
import UpgradeToPro from '../.../../../../Components/Common/UpgradeToPro';
import NewUpgradeToPro from "../../../Components/Common/NewUpgradeToPro/Index"
import { assignCSRtoCustomer, getTMSCustomers } from "./actionCreators";
import {
  deletTmsCustomer,
  getStorage,
  toastr,
  isTrialUser,
  splitStreetNameNumber,
  getAddressTerminology,
  addressFormatEnum,
  defaultAddressFormat,
  addressParser,
  isEurope,
  changePropertyName,
  cleanMobileNumber,
  isShiposCarrier,
  isGlobalizationEnabled,
  getDefaultPolygon,
  isAccountServiceEnabled,
  isNewBillingEnabled,
  showForTerminal,
  isManageFleetOrOutSource,
  checkCustomerProfilePermission,
  isEmbeddedEmailEnabled,
  amplitudeTrack,
  clearLocalCache,
  customerApiCacheKeys
} from "../../../services/Common.services";


import Papa from "papaparse";
import "../style.css";
import confirm from "../../../Components/Common/ConfirmAert";
import CustomerList from "./CustomerList";
import TabList from "./TabList";
import {
  IconDownload,
  IconPlus,
  IconAngleArrowRight,
  IconQuickBook,
  IconAngleArrowLeft,
  IconSearchData,
  IconDocument,
  IconNotes,
  IconAngleArrowDown,
  IconUsers,
  IconMapWithMarker,
  IconUsersGroup,
  IconSendEmail,
  IconAt,
  IconPhone,
  IconUserSingle,
  IconMapMarkerOutline,
  IconTimes,
  IconTimesThin,
  IconTimesThinClose,
  IconCancelCircle,
  IconPlusSmall,
  IconCheckCircle,
  IconPen,
  IconTrash,
} from "../../../Components/Common/Icons";
import config from "../../../config";
import SubHeaderTab from "../../../Components/SubHeaderTab";
import { dissolve } from "@turf/turf";
import { debounce } from "throttle-debounce";
import DataGrid, { Row } from "react-data-grid";
import { isAtBottom } from "../NewDispatcher/DispatcherTable/Functions";
import { customerJoiValidateKeys, isCustomerTypeFound, newTabLists,} from "./utility";
import { browserHistory } from 'react-router';
import DynamicTableHeight from "../../../Components/Common/DynamicTableHeight";
import { DONTSYNCME } from "../Settings/QuickbooksSetup/constant";
import { titleCustomerOrOrg, titleCustomersOrOrgs } from "../Load/utility";
import { checkRequiredFieldsOfAddress } from "../AddressComponent/helpers";


const firebase = config.firebase;
const sortingOptions = {
  "Company Name": "company_name",
  Phone: "mobile",
  "Main Contact Name": "main_contact_name",
  Address: "address.address",
  City: "city",
  State: "state",
  "Zip Code" : "zip_code"
};

const cardOptions = [
  { label: "Customers", value: ["caller", "ALL"], countKey: "customer" },
  {
    label: "Shipper/PORT",
    value: ["shipper", "containerReturn", "ALL"],
    countKey: "shipper",
  },
  { label: "Consignees", value: ["consignee", "ALL"], countKey: "consignees" },
  {
    label: "Chassis",
    value: ["chassisTermination", "chassisPick", "ALL"],
    countKey: "chassis",
  },
];

let tabLists = [
  {
    label: "Customer",
    icon: <IconUsers />,
    id: 1,
  },
  {
    label: "Documents",
    icon: <IconDocument /> ,
    id: 2,
  },
  {
    label: "Geofence",
    icon: <IconMapWithMarker />,
    id: 3,
  },
  {
    label: "Notes",
    icon: <IconNotes />,
    id: 4,
  },
  {
    label: "Audit",
    icon: <IconSearchData />,
    id: 5,
  },
  {
    label: "Employees",
    icon: <IconUsersGroup />,
    id: 6,
  },
  {
    label: "Email Notification",
    icon: <IconSendEmail />,
    id: 7,
  },
];

const sortableFields = [
  { name: 'Company Name', key:"company_name", field: 'company_name' },
  { name: 'Phone',key:"mobile", field: 'mobile'},
  { name: 'Main Contact Name', key:"main_contact_name", field: 'main_contact_name' },
  { name: 'City',key:"city", field: 'city'},
  { name: 'State',key:"state", field: 'state' },
  { name: 'Zip Code',key:"zip_code", field: 'zip_code' },
]
const onCreditHold = 'On Credit Hold'
const notOnCreditHold = 'Not On Credit Hold'
let filterOnHoldOptions=[ { value: 'All', label: 'All' }, { value: 'On Hold', label: 'On Hold' }, { value: 'Not On Hold', label: 'Not On Hold' }]
let filterOnCreditHoldOptions = [ { value: 'All', label: 'All' }, { value: onCreditHold, label: onCreditHold }, { value: notOnCreditHold, label: notOnCreditHold }]
let Selected_customer = {
  companyID: 0,
  company_name: "",
  email: "",
  address: "",
  lat: "",
  lng: "",
  city: "",
  countries:[],
  state: "",
  country: "",
  zip_code: "",
  main_contact_name: "",
  secondary_contact_name: "",
  mobile: "",
  billingEmail: "",
  password: "",
  credit_limit: "",
  payment_terms: "",
  account_hold: false,
  credit_hold : false,
  qbEmail: "",
  documents: [],
  geofence: {
    lng: undefined,
    lat: undefined,
    zoom: undefined,
    polygonData: [],
  },
  fleetCustomer: "",
  secondaryPhoneNo: "",
  officeHoursStart: "",
  officeHoursEnd: "",
  currency: "",
};
const checkIsManageFleetOrOutSource = isManageFleetOrOutSource()

export const EmptyRowsRenderer =() => {
  return (
    <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
      <div style={{padding: "20px 10px", backgroundColor: 'white', fontSize: "12px"}}>
        No Result
      </div>
    </div>
  );  
}
const removeExtraFields = ["isEdi214EmptyDate", "isEdi214LastFreeDate", "isEdi214HookContainer", "isEdi214DropContainer", "isEdi214PullContainer", "isEdi214DeliverLoad"]
const carrierDetail = JSON.parse(getStorage("carrierDetail"));

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      customers: [],
      Selected_customer: Selected_customer,
      addCustomer: false,
      qbRoute: "",
      isConnected: false,
      isQbOnline:false,
      documents: [],
      tab: 1,
      showAddModal: false,
      Terminals: [],
      fitlerDeletedEnabled: "Active",
      isActive: false,
      resetId: undefined,
      counts: {
        total: 0,
        consignees: 0,
        customer: 0,
        shippers: 0,
      },
      hoverImage: false,
      searchTerm: "",
      sortBy: { company_name: 1 },
      selectedCard: null,
      choosenTerminals: [],
      upgradeTopro : false,
      isSaving: false,
      filterOnHold: 'All',
      filterOnCreditHold : 'All',
      assignedCSR : [],
      AllCSRList : [],
      showAllCSRList : false,
      csrSearchTerm: "",
      isloading : false,
      showRemoveIcon : undefined,
      state: [],
      city: [],
      countryCode: null,
      country: [],
      totalCustomers: 0,
      skipCount: 0,
      skip: 0,
      sortColumns: [],
      columns: [],
      allCustomers: [],
      isLoading: false,
      loadsPerReq: 20,
      preferredCurrencies: [],
      qbTaxOptions: [],
      isConnectEnabled: false,
      qbButtonDisable:false,
      isCalculateTax: JSON.parse(getStorage("userBasicSettings"))?.isCalculateTax?true:false,
      addressValidation: {
        address:false, 
        city: false, 
        state: false, 
        country: false
      },
    };
    this.remountKey = 0;
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.fetchTaxCodes = this.fetchTaxCodes.bind(this);
    this.isShiposCarrier = isShiposCarrier()
    this.isAccountServiceEnabled = isAccountServiceEnabled()
  }

  getColumns (){
    let currentUser = JSON.parse(getStorage("loggedInUser"));
    let {customerProfileCustomersPermission, customerProfileShipperPermission, customerProfileConsigneePermission, customerProfileChassisPermission} = checkCustomerProfilePermission(currentUser)
    const columns = [
      {
        key:"edit",
        name:"",
        width: 65,
        minWidth: 65,
        formatter: ({row})=>{
          return !row.isDeleted && (
            <div className="cell-content text-center">
              <button
                type="button"
                className="btn btn-link text-muted"
                disabled={currentUser.role === "fleetmanager" && (!(customerProfileCustomersPermission && row?.customerType.includes("caller")) && 
                  !(customerProfileShipperPermission && (row?.customerType.includes("shipper") || row?.customerType.includes("containerReturn"))) &&
                  !(customerProfileConsigneePermission && row?.customerType.includes("consignee")) && 
                  !(customerProfileChassisPermission && (row?.customerType.includes("chassisPick") || row?.customerType.includes("chassisTermination"))) &&
                  !(customerProfileCustomersPermission && customerProfileShipperPermission && customerProfileConsigneePermission && customerProfileChassisPermission)
                )}
                onClick={() => this.selectCustomer(row)}
              >
                <IconPen />
              </button>
            </div>
          )
        },
      },
      {
        key:"delete",
        name:"",
        width: 65,
        minWidth: 65,
        formatter: ({row})=>{
          return row.isDeleted ? (
            <div className="cell-content text-center">
              <button
                type="button"
                className="btn btn-link text-muted"
                disabled={currentUser.role === "fleetmanager" && (!(customerProfileCustomersPermission && row?.customerType.includes("caller")) && 
                  !(customerProfileShipperPermission && (row?.customerType.includes("shipper") || row?.customerType.includes("containerReturn"))) &&
                  !(customerProfileConsigneePermission && row?.customerType.includes("consignee")) && 
                  !(customerProfileChassisPermission && (row?.customerType.includes("chassisPick") || row?.customerType.includes("chassisTermination"))) &&
                  !(customerProfileCustomersPermission && customerProfileShipperPermission && customerProfileConsigneePermission && customerProfileChassisPermission)
                )}
                onClick={() => this.removeCustomer(row)}
              >
                <IconCheckCircle />
              </button>
            </div>
          ) : (
            <div className="cell-content text-center">
              <button
                type="button"
                className="btn btn-link text-muted"
                disabled={currentUser.role === "fleetmanager" && (!(customerProfileCustomersPermission && row?.customerType.includes("caller")) && 
                  !(customerProfileShipperPermission && (row?.customerType.includes("shipper") || row?.customerType.includes("containerReturn"))) &&
                  !(customerProfileConsigneePermission && row?.customerType.includes("consignee")) && 
                  !(customerProfileChassisPermission && (row?.customerType.includes("chassisPick") || row?.customerType.includes("chassisTermination"))) &&
                  !(customerProfileCustomersPermission && customerProfileShipperPermission && customerProfileConsigneePermission && customerProfileChassisPermission)
                )}
                onClick={() => this.removeCustomer(row)}
              >
                <IconTrash />
              </button>
            </div>
          )
        },
      },
      {
        key:"company_name",
        name:"Company Name",
        sortable: true,
        resizable: true,
        formatter: ({row})=>{
          return <div class="cell-content">
            {row.company_name}
            {(row.account_hold) && (
              <span class="badge badge-status-dark-grey badge-sm text-white ml-2">Account Hold</span>
            )}
          </div>
        },
      },
      {
        key:"mobile",
        name:"Phone",
        resizable: true,
        sortable: true,
        formatter: ({row})=>{
          return <div class="cell-content">{row.mobile}</div>
        },
      },
      {
        key:"main_contact_name",
        name:"Main Contact Name",
        sortable: true,
        resizable: true,
        formatter: ({row})=>{
          return <div class="cell-content">{row.main_contact_name}</div>
        },
      },
      {
        key:"address1",
        name:"Address1",
        resizable: true,
        formatter: ({row})=>{
          return <div class="cell-content">{splitStreetNameNumber(row.address1)}</div>
        },
      },
      {
        key:"city",
        name:"City",
        sortable: true,
        resizable: true,
        formatter: ({row})=>{
          return <div class="cell-content">{row.city}</div>
        },
      },
      {
        key:"state",
        name:"State",
        sortable: true,
        resizable: true,
        formatter: ({row})=>{
          return <div class="cell-content">{row.state}</div>
        },
      },
      {
        key:"zip_code",
        name:`${getAddressTerminology()}`,
        sortable: true,
        resizable: true,
        formatter: ({row})=>{
          return <div class="cell-content">{row.zip_code}</div>
        },
      },
      
    ]
   if(isEurope()) {
    columns.push( {
      key:"countryCode",
      name:"Country Code",
      sortable: true,
      resizable: true,
      formatter: ({row})=>{
        return <div class="cell-content">{row?.countryCode}</div>
      },
    },)
   } 
    this.setState({columns}) 
  }

  getCustomersLimit = (payload) => {
    this.props.dispatch({
      type: TMS_GET_CUSTOMERS_ALL,
      payload,
    });
  };

  handleInput = (e) => {
    // this.setState({ searchTerm: e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""), activePage: 1 }, () => {
    //   this.delayedQuery();
    // })
    this.setState({ searchTerm: e.target.value, activePage: 1 }, () => {
      this.delayedQuery();
    });
  };
  delayedQuery = _.debounce(() => {
    this.setState({skip: 0}, () => {
      this.getcustomerData();
    })
  }, 500);

  getCounts(data) {
    this.props.actions
      .getTMSCustomerCount(data)
      .then((res) => this.setState({ counts: res, totalCustomers: res.total }))
      .catch((err) => console.log(err));
  }

  getCountryCode() {
    this.props.actions.getCountryCode().then(countries => {
    const allCountryCodes = changePropertyName(countries)
      this.setState({countries: allCountryCodes})})
  }

  
  removeCustomer = async (customer) => {
    const response = await getSubCustomer({ subCustomerId: customer._id });
    if (response.isUsed) {
      toastr.show("This customer is already used in subcustomer.", "warning");
      return;
    }

    if (customer && customer._id) {
      confirm(
        `${customer.isDeleted ? "Activate Customer?" : "Delete Customer?"}`,
        `Are you sure ${
          customer.isDeleted
            ? "you want to activate this customer?"
            : "you want to delete this customer?"
        }`,
        (confirm) => {
          if (confirm) {
            deletTmsCustomer({
              _id: customer._id,
              isDeleted: !customer.isDeleted,
            }).then((result) => {
              if(result && result.data && result.data.data) {
                let { allCustomers } = this.state;
                allCustomers.forEach((obj, i) => {
                  if(obj._id === customer._id) {
                    allCustomers[i].isDeleted = result.data.data.success;
                  }
                })
                this.setState({allCustomers})
              }
              toastr.show(
                `Successfully ${customer.isDeleted ? "Enabled" : "Deleted"}`,
                "success"
              );
              this.setState({skip: 0}, () => {
                this.getcustomerData()
              })
            });
          }
        }
      );
    }
  };
  handleCompleteDownload = () => {
    // debugger
    const { selectedCard, counts, isActive } = this.state;
    let csvalues = [];
    let params = { isDeleted: isActive };

    if (selectedCard) {
      params = {
        customerType: JSON.stringify(selectedCard.value),
      };
    }

    getCustomers(params, counts.total).then((result) => {
      if (result && result.length > 0) {
        csvalues = result.map((value) => {
          let Terminals = [];
          if (this.state.Terminals && this.state.Terminals.length > 0) {
            Terminals = this.state.Terminals.map((D) => {
              return {
                value: D._id,
                label: D.name,
              };
            });
          }
          let terminalNameArray = value.newTerminal.map((terminal) => {
          const terminalName = Terminals.find((terminalName) =>terminalName.value + "" == terminal + "" )
            return terminalName?.label
          })
          terminalNameArray = terminalNameArray.filter((array) => array != undefined)

          const invoiceCurrencyWithBranch = value.invoiceCurrencyWithBranch.map((currency) => ` ${currency?.currency?.name ? currency?.currency?.name : ""} ${currency?.currency?.symbol? `(${currency?.currency?.symbol})` : ""}`)
          const DefaultCurrency = ` ${value?.invoiceCurrencyWithCarrier?.currencyCode? value?.invoiceCurrencyWithCarrier?.currencyCode: ""}  ${value?.invoiceCurrencyWithCarrier?.symbol ? `(${value?.invoiceCurrencyWithCarrier?.symbol})` : ""}`
     
          let csvVal = {};
          csvVal["COMPANY NAME"] = value.company_name ? value.company_name : "";
          csvVal["PHONE"] = value.mobile ? value.mobile : "";
          csvVal["MAIN CONTACT NAME"] = value.main_contact_name
            ? value.main_contact_name
            : "";
          csvVal["ADDRESS"] =
            defaultAddressFormat() === addressFormatEnum.EU ?
              splitStreetNameNumber(value?.address?.address.trim())
              :
              value.address
                ? value.address.address
                  ? value.address.address.split(",")[0]
                  : ""
                : "";

          csvVal["CITY"] = value.city ? value.city : "";
          csvVal["STATE"] = value.state ? value.state : "";
          csvVal["ZIP CODE"] = value.zip_code ? value.zip_code : "";
          csvVal["CUSTOMER TYPE"] = value.customerType ? value.customerType : " ";
          const isCallerConsigneeAll = isCustomerTypeFound(value, ['caller', 'ALL'])
          csvVal["CREDIT LIMIT"] = value.credit_limit && isCallerConsigneeAll ? value.credit_limit : " ";
          csvVal["DEFAULT PAYMENT TERMS"] = value?.defaultPaymentTerms?.days  ? value.defaultPaymentTerms.days : value?.payment_terms ? value.payment_terms : " ";
          csvVal["CREDIT HOLD"] = "";
          if (isCallerConsigneeAll) {
            csvVal["CREDIT HOLD"] = value.credit_hold ? "YES" : "NO";
          }
          csvVal["ACCOUNT HOLD"] = "";
          const isCallerConsigneeALLShipper = isCustomerTypeFound(value, ['caller', 'ALL', "consignee", "shipper"])
          if (isCallerConsigneeALLShipper) {
            csvVal["ACCOUNT HOLD"] = value.account_hold ? "YES" : "NO";
          } 
          csvVal["CURRENCY"] = value.currency && isCallerConsigneeALLShipper ? value.currency : " ";
          if (terminalNameArray?.length > 0) {
            csvVal["TERMINAL"] = terminalNameArray?.length > 0 ? terminalNameArray : " ";
          }
          if (isGlobalizationEnabled()) {
            csvVal["DEFAULT CURRENCY"] = ""
            csvVal["INVOICE CURRENCY BRANCH"]=""
          }
          if ( isGlobalizationEnabled() && isCallerConsigneeAll) {
            csvVal["DEFAULT CURRENCY"] = DefaultCurrency ? DefaultCurrency : " ";
          }
          if (isGlobalizationEnabled() && isCallerConsigneeAll && invoiceCurrencyWithBranch?.length > 0) {
            csvVal["INVOICE CURRENCY BRANCH"] = invoiceCurrencyWithBranch?.length > 0 ? invoiceCurrencyWithBranch : "";
          }
          if (this.state.isCalculateTax) {
            csvVal["TAX RATE"] = ""
            csvVal["TAX TYPE"] = ""
          }
          if (this.state.isCalculateTax && isCallerConsigneeAll) {
            csvVal["TAX RATE"] = value?.masterTaxAll?.taxRate ? value?.masterTaxAll?.taxRate : " ";
            csvVal["TAX TYPE"] = value.taxType ? value.taxType : " ";
          }
          return csvVal;
        });

        var csv = Papa.unparse(csvalues);
        amplitudeTrack( `DOWNLOAD_CSV_BUTTON`,{source: 'customer_profile'})
        this.onDownloadClick(csv);
      }
    });
  };
  onDownloadClick = (csv) => {
    toastr.show("Please wait while downloading.", "success");
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `${new Date().getTime()}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${new Date().getTime()}.csv`);
    tempLink.click();
  };

  checkUserPermission() {
    let loggedInuser = JSON.parse(getStorage("loggedInUser"));
    return loggedInuser?.role === 'customer' ? false : (loggedInuser?.role === 'fleetmanager' && loggedInuser?.fleetManager?.isCustomer ? false : true);
  }

  getcustomerData(appendCustomers) {
    let payload = {
      limit: this.state.loadsPerReq,
      skip: this.state.skip,
    };
    let countPayload = {};

    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      payload.choosenTerminals = JSON.stringify(
        this.state.choosenTerminals.map((Data) => {
          return Data.value;
        })
      );
    }

    if (this.state.fitlerDeletedEnabled === "Inactive") {
      payload.isDeleted = true;

      countPayload.isDeleted = true;
    }
    if(this.state.filterOnHold ==='On Hold'){
      payload.account_hold = true
      countPayload.account_hold =true
    }

    if (this.state.filterOnHold ==='Not On Hold'){
      payload.account_hold = false
      countPayload.account_hold = false
    }
    if(this.state.filterOnCreditHold == onCreditHold){
      payload.credit_hold = true
      countPayload.credit_hold = true
    }
    if(this.state.filterOnCreditHold == notOnCreditHold){
      payload.credit_hold = false
      countPayload.credit_hold = false
    }
    
    if (
      payload.choosenTerminals &&
      this.state.choosenTerminals &&
      this.state.choosenTerminals.length > 0
    ) {
      countPayload.choosenTerminals = payload.choosenTerminals;
    }
    if (this.state.searchTerm) {
      payload.searchTerm = this.state.searchTerm;
      countPayload.searchTerm = this.state.searchTerm;
    }
    if (this.state.sortColumns.length > 0) {
      const col = this.state.sortColumns[0].columnKey;
      const direction = this.state.sortColumns[0].direction;
      let sortOrder = direction === "ASC" ? 1 : -1;
      const sortableField = sortableFields.find(el => el.key === col);
      if (sortableField) {
        const sortField = { [sortableField.field]: sortOrder };
        payload.sortBy = JSON.stringify(sortField);
      }
    }
    if (this.state.selectedCard) {
      payload.customerType = JSON.stringify(this.state.selectedCard.value);
      countPayload.customerType = JSON.stringify(this.state.selectedCard.value);
    }
    countPayload.isIncludeInvalid = true
    this.getCounts(countPayload);
    this.setState({isLoading: true})
    this.props.actions.getCurrencyList().then((res) => {
      this.setState({preferredCurrencies: res});
    }).catch((err) => {
      console.error(err);
    });
    
    if(this.checkUserPermission()) {
      payload.isIncludeInvalid = true
      payload.isGenerateSignUrl = true
      this.props.actions.getAllCustomers(payload).then((res) => {
        let allCustomers = res
        if(appendCustomers) {
          allCustomers = [...this.state.allCustomers, ...res];
        } else {
          this.remountKey = this.remountKey + 1;
        }
        
        this.setState({allCustomers,isLoading:false}, () => {this.handleDefaultScroll()})
      })
    } else {
      this.setState({isLoading:false}, () => {this.handleDefaultScroll()})
    }
    
  }

  checkEnabledConnect = () => {
    actionCreators.checkIsConnectEnabled().then(data => {
        this.setState({isConnectEnabled: data.isEnabled ? data.isEnabled : false})})
  }

  async componentDidMount() {
    this.getColumns();
    const querySearch = _.get(this.props, "location.query.id");
    if (
      (getStorage("currentUserRole") === "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) !== -1) ||
      (getStorage("currentUserRole") === "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) !== -1)
    ) {
      const response = await getTerminal()
      const Terminals = response.data.data ? response.data.data : [];
      this.setState({ Terminals });
    }
    this.openSearchedCustomer(querySearch);
    const loggedUser= getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser"))
    if (getStorage("currentUserRole") !== "customer" && !this.isAccountServiceEnabled) {
      if(loggedUser && loggedUser.role === "carrier" &&
      (!!loggedUser.agentToken &&
      !!loggedUser.endpoint)){
        this.setState({isQbOnline:false})
      }else{
        this.setState({isQbOnline:true})
      }
      
    }
    // when user role carrier and carrier fleetmanager
    if(this.checkUserPermission() && !this.isShiposCarrier && this.checkIsManageFleetOrOutSource?.isManageFleet) {
      this.checkEnabledConnect()
    }
    this.getCountryCode()
    this.getcustomerData();
    this.listAllCSR();
    document.addEventListener("mousedown", this.handleClickOutside);


    if (getStorage("loggedInUser") != null) {
      const currentUser = JSON.parse(getStorage("loggedInUser"));
      let carrierId = currentUser._id;

      if (currentUser.role === "driver") {
        carrierId = currentUser.driver.carrier;
      }
      if (currentUser.role === "fleetmanager") {
        carrierId = currentUser.fleetManager.carrier;
      }
      this.socketCustomerDataRef = firebase
      .database()
      .ref(`${carrierId}/updateCustomer`);
   
      this.socketCustomerDataRef.on("value", (snapshot) => {
        const customerDetails = JSON.parse(snapshot.val());
     
        if (customerDetails) {
          const customerId = customerDetails.customerId;
          const actionType = customerDetails.type;
          
          if (actionType === "update") {
            this.props.actions
            .getTMSCustomerById({ customerId })
            .then(() => {
            });
          }
        }
      });
    }
    this.fetchTaxCodes();
  }

  fetchTaxCodes() {
    if(getStorage("userBasicSettings")){
      const settings = JSON.parse(getStorage("userBasicSettings"));
      if(settings?.isCalculateTax){
        getTaxCodes().then((codes) => {
          const options = codes?.map((code ) => {
            return {
              label: `${code?.taxType} @ ${(code?.taxRate ?? 0)?.print()} %`,
              value: code,
            }
          })
          this.setState({
            qbTaxOptions: options,
          });
        })
      }
    }
  }

  openCustomerProfile = async () =>{
    const queryParams = this?.props?.location?.query;
    const customerId = queryParams?.customerId;
    const isGenerateSignUrl = queryParams?.isGenerateSignUrl   
    
    const params={
      customerId:customerId
    } 
    if(isGenerateSignUrl) params.isGenerateSignUrl = true;
    if(customerId){
      await this.props.actions.getTMSCustomerById(params)
      .then((res)=>{
        if(res){
          this.selectCustomer(res)
        }
      })
      .catch((err)=>{
        console.error(err, "index.js")
      })
    }
  }

  componentWillMount() {
    const isTerminal = showForTerminal()
    if (isTerminal) {
      const Terminals = this?.props?.Terminals
      this.setState({ Terminals })
    }
    this.openCustomerProfile()
  }


  componentWillReceiveProps(props1) {
    let Customer = props1.customers;
    if (Customer && Customer.length > 0) {
      this.setState({ customers: [...Customer] }, () => {});
    }
    const newId = _.get(props1, "location.query.id");
    const oldId = _.get(this.props, "location.query.id");
    if (newId && newId !== oldId) {
      this.openSearchedCustomer(newId);
    }
    
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.setState({assignedCSR : []})
  }

  openSearchedCustomer(querySearch) {
    if (querySearch) {
      const data = {
        allIds: JSON.stringify([querySearch]),
      };
      actionCreators
        .getCustomersByIDS(data)
        .then((customers) => {
          this.selectCustomer(customers[0]);
        })
        .catch((error) => {
        });
    }
  }

  resetform() {
    this.setState({ Selected_customer });
  }

  camelize(str) {
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }
  handleClearQueryParams = () => {
    const { pathname } = browserHistory.getCurrentLocation();
    browserHistory.push({ pathname, query: {} });
    this.setState({
      addressValidation: {
        address:false, 
        city: false, 
        state: false, 
        country: false
      },
    })
  }

  closeModal() {
    this.setState({ addCustomer: false, tab: 1, showAddModal: false });
    this.handleClearQueryParams()
  }

  updateState(data) {
    this.setState(data);
  }

  addressValidationSetState = () => {
    this.setState({
      addressValidation: {
        address: false,
        city: false,
        state: false,
        country: false
      }
    })
  }

  submitForm = (data) => {
    let self = this;
    let customerProfileData = {};
    return new Promise((resolve, reject) => {
     if(data){
      if (data.documentFile && !data.fileType) {
        toastr.show("You must choose a type.", "warning");
        this.setState({ tab: 2 });
        reject('Please choose file type');
        return;
      }
     
       customerProfileData = typeof data == 'object' && JSON.parse(JSON.stringify(data));
       if(customerProfileData.mcNumber) {
        customerProfileData.mcNumber = customerProfileData.mcNumber.trim();
       }
      if (customerProfileData.employeeEmails) {
        delete customerProfileData.employeeEmails;
      }
      customerProfileData.shareLoadNotes = data?.shareLoadNotes !== undefined ? data?.shareLoadNotes : false
      customerProfileData.shareBillingNotes = data?.shareBillingNotes !== undefined ? data?.shareBillingNotes : false

      // New addressService Validation
      const validationState = checkRequiredFieldsOfAddress(customerProfileData, ['address', 'city', 'state', 'country']);

      if (Object.keys(validationState).length > 0) {
        this.setState({
          addressValidation: {
            ...validationState
          }
        });
        return;
      }

      customerProfileData.address = {
        address: customerProfileData.address,
        lat: customerProfileData.lat ? customerProfileData.lat : 12,
        lng: customerProfileData.lng ? customerProfileData.lng : 12,
        address1: customerProfileData.address1,
        city: customerProfileData.city,
        state: customerProfileData.state,
        country: customerProfileData.country,
        zip_code: customerProfileData.zip_code
      };
      if(data.externalSystemID) {
        let externalSystemID = data.externalSystemID.trim()
        if(externalSystemID.length > 0) customerProfileData.externalSystemID = externalSystemID
      }

      customerProfileData.geofence = JSON.parse(customerProfileData.geofence);
      if (_.isEmpty(customerProfileData.geofence.polygon)) {
      const defaultPolygonData = getDefaultPolygon(customerProfileData.address.lng, customerProfileData.address.lat);
        customerProfileData.geofence.polygon = defaultPolygonData;
      }
      customerProfileData.geofence = JSON.stringify(customerProfileData.geofence);

      customerProfileData.companyID =
        this.camelize(customerProfileData.company_name) +
        Math.floor(Math.random() * 10000 + 1);
      delete customerProfileData.chassisTerminationUsageCount;
      delete customerProfileData.emptyOriginUsageCount;
      delete customerProfileData.chassisPickUsageCount;
      delete customerProfileData.consigneeUsageCount;
      delete customerProfileData.shipperUsageCount;
      delete customerProfileData.callerUsageCount;

      delete customerProfileData.lat;
      delete customerProfileData.lng;
      if (customerProfileData.createdAt) {
        delete customerProfileData.createdAt;
      }
      if (customerProfileData.updatedAt) {
        delete customerProfileData.updatedAt;
      }
      delete customerProfileData.__v;
      delete customerProfileData.carrier;
      delete customerProfileData.SyncToken;
      delete customerProfileData.qbId;
      delete customerProfileData.type;
      delete customerProfileData.usageCount;
      delete customerProfileData.pdfMap;
      delete customerProfileData.invalidCount;
      delete customerProfileData.isConnected;
      delete customerProfileData.isFailed;
      delete customerProfileData.credit_hold;
      if (customerProfileData.mobile) {
        customerProfileData.mobile = cleanMobileNumber(customerProfileData.mobile)
      }
      if(customerProfileData.secondaryPhoneNo){
        customerProfileData.secondaryPhoneNo = cleanMobileNumber(customerProfileData.secondaryPhoneNo)
      }

      if (!customerProfileData._id && !customerProfileData.password) {
        customerProfileData.password = "123456";
      }

      if (data.documentFile && data.fileType) {
        customerProfileData.document = data.documentFile;
        customerProfileData.fileType = data.fileType;
        customerProfileData.filename = `${new Date().getTime().toString()}`;
        delete data.documentFile;
        delete customerProfileData.documentFile;
      }
      Object.keys(customerProfileData).forEach((key) => {
        if (customerProfileData[key] === null || removeExtraFields.includes(key)) {
          delete customerProfileData[key];
        }
      });

      if (!customerProfileData.officeHoursStart) {
        customerProfileData.officeHoursStart = "";
      }
      if (!customerProfileData.officeHoursEnd) {
        customerProfileData.officeHoursEnd = "";
      }
      if (customerProfileData.salesAgents) {
        customerProfileData.salesAgents = JSON.stringify(
          customerProfileData.salesAgents.map((d) => d.value)
        );
      }
     }
      this.setState({ isSaving: true })
      this.state.Selected_customer = {
        ...this.state.Selected_customer,
        main_contact_name: data.main_contact_name,
        email: data.email,
        mobile: data.mobile,
        address : data.address
        ? data.address
        : "",
        address1 : data.address1
        ? defaultAddressFormat() === addressFormatEnum.EU ? 
        splitStreetNameNumber(data?.address1?.trim())
        : data?.address1
        : "",
        receiverEmail: data.receiverEmail,
        secondary_contact_name: data.secondary_contact_name,
        secondaryPhoneNo: data.secondaryPhoneNo,
        externalSystemID: data.externalSystemID,
        company_name: data.company_name,
        city: data.city,
        state: data.state,
        zip_code: data.zip_code,
        country: data.country,
        qbEmail: data.qbEmail,
        account_hold: data.account_hold,
        companyField: data.companyField,
        payType: data.payType,
        canEditLoad: data.canEditLoad,
        isTIROptional: data.isTIROptional,
        mcNumber: data.mcNumber,
        officeHoursStart: data.officeHoursStart,
        officeHoursEnd: data.officeHoursEnd,
        newTerminal: data.newTerminal,
        credit_limit: data.credit_limit,
        accountingInfo: data?.accountingInfo ?? {},
        organizationDomain: data?.organizationDomain ?? ""
      }
     delete customerProfileData.assignedCSR
     delete customerProfileData?.formattedAddress1;
     if(customerProfileData.customerCombinationMethod) customerProfileData.customerCombinationMethod = data.customerCombinationMethod
     if(customerProfileData.isSeparateInvoicePerTerminal) customerProfileData.isSeparateInvoicePerTerminal = data.isSeparateInvoicePerTerminal;
     if(!['caller', 'shipper', 'consignee', 'ALL'].some(value => data.customerType.includes(value))) {
      delete customerProfileData.email;
      delete this.state.Selected_customer.email;
    }
    if(data?.accountID){
      customerProfileData.accountID = data.accountID
    }
    if (data?.accountingInfo) {
      delete data.accountingInfo
    }

     const filteredPayload = customerJoiValidateKeys.reduce((filtered, key) => {
        if (customerProfileData.hasOwnProperty(key)) {
            filtered[key] = customerProfileData[key];
        }
        return filtered;
      }, {});
      filteredPayload.isGenerateSignUrl = true
      AddProfile("carrier/addTMSCustomer", filteredPayload)
        .then((result) => {
          if (customerProfileData._id) {
            this.setState({isSaving: false})
            clearLocalCache(customerApiCacheKeys())
            toastr.show("Successfully updated", "success");
          } else {
            this.setState({isSaving: false, showAddModal: false})
            if(this.props.isQuickAdd){
              this.props.falsyData("customer");
            }
            toastr.show("Successfully added", "success");
          }

          setTimeout(() => {
            $("#closeModalButton").click();
            if(result && result.data && result.data.data) {
              let { allCustomers } = this.state;
              allCustomers.forEach((obj, i) => {
                if(obj._id === result.data.data._id) {
                  allCustomers[i] = result.data.data;
                  allCustomers['email']=data.email;
                }
              })
              this.setState({allCustomers, skip: 0}, () => this.getcustomerData())
            }
          }, 1000);

          if (
            result.data &&
            result.data.data &&
            result.data.data.documents &&
            result.data.data.documents.length > 0
          ) {
            self.setState({ documents: result.data.data.documents });
          }

          if(result?.data?.data){
            this.setState((prev) =>({ Selected_customer:{...prev.Selected_customer, account_hold : result?.data?.data.account_hold }}));
          }

          resolve();
        })
        .catch((error) => {
          this.setState({ tab: 1, isSaving: false, Selected_customer: this.createStateSelectedCustomerObject(data)});
          reject();
        });
        
    });
  };

  createStateSelectedCustomerObject(data) {
    const customerTags = () => {
      try {
        const parsedCustomerTags = JSON.parse(data?.customerTags)
        return parsedCustomerTags
      } catch (error) {
        return [] 
      }
    }

    const requiredDocList = () => {
      try {
        const parsedCustomerTags = JSON.parse(data?.requiredDocList)
        return parsedCustomerTags
      } catch (error) {
        return [] 
      }
    }

    return {
      ...this.state.Selected_customer,
      ...data,
      ...(Array.isArray(data?.customerTags) ? {customerTags: data?.customerTags} : {customerTags: customerTags()}),
      ...(Array.isArray(data?.requiredDocList) ? {requiredDocList: data?.requiredDocList} : {requiredDocList: requiredDocList()})
    }
  }

  firstORlast(k) {
    this.props.dispatch({
      type: TMS_GET_CUSTOMERS_ALL,
      payload: {
        skip: 0,
        limit: k,
      },
    });
  }

  selectCustomer = (customer) => {
    if (customer) {
      const Selected_customer = JSON.parse(JSON.stringify(customer));
      Selected_customer["fleetCustomer"] = customer.fleetCustomer
        ? customer.fleetCustomer
        : "";
      Selected_customer["lat"] = customer.address ? customer.address.lat : "";
      Selected_customer["lat"] = customer.address ? customer.address.lat : "";
      Selected_customer["lng"] = customer.address ? customer.address.lng : "";
      Selected_customer["address"] = customer.address
        ? customer.address.address
        : "";
      Selected_customer["address1"] = customer.address1
      ? defaultAddressFormat() === addressFormatEnum.EU ? 
      splitStreetNameNumber(customer?.address1?.trim())
      : customer?.address1
      : "";
      Selected_customer["newTerminal"] = customer.newTerminal
        ? customer.newTerminal
        : "";
      Selected_customer["notificationEmails"] = customer.notificationEmails;
      Selected_customer["extraSubCustomer"] = customer.extraSubCustomer;

      if (customer.newTerminal && customer.newTerminal.length > 0) {
        let newTerminal = [];
        this.state.Terminals.map((Data) => {
          if (customer.newTerminal.indexOf(Data._id) != -1) {
            newTerminal.push({
              value: Data._id,
              label: Data.name,
            });
          }
        });
        Selected_customer["newTerminal"] = newTerminal;
      }
      let geofence = {
        lng: customer?.geofence?.center[0]
          ? customer?.geofence?.center[0]
          : undefined,
        lat: customer?.geofence?.center[1]
          ? customer?.geofence?.center[1]
          : undefined,
        zoom: customer?.geofence?.zoom ? customer?.geofence?.zoom : undefined,
        polygonData: customer?.geofence?.polygon,
        startPoint: customer?.geofence?.startPoint
      };
      Selected_customer["geofence"] = geofence;
      if(customer.assignedCSR && customer.assignedCSR.length > 0){
        const CSR = customer.assignedCSR
                      .map((obj) => ({
                        value: obj._id,
                        label: obj.name + " " + obj.lastName,
                        allInfos: obj,
                      }));
         
            
            this.setState({ assignedCSR: CSR });
       }else{
        this.setState({ assignedCSR: [] });
       }
      if(Selected_customer["taxRate"])
        Selected_customer["taxRate"] = parseFloat(Selected_customer["taxRate"] * 100).toFixed(2);
      this.setState({ addCustomer:false},()=>{
        this.setState({
          Selected_customer,
          addCustomer: true,
          documents: customer.documents ? customer.documents : [],
          tab: !this.state.tab ? 1 : this.state.tab,
          customerType : Selected_customer?.customerType
        });
      })
        
    }
  };

  sortOption(header) {
    let key = sortingOptions[header];
    let order = 1;
    if (this.state.sortBy[sortingOptions[header]])
      order = this.state.sortBy[sortingOptions[header]] * -1;
    this.setState(
      {
        sortBy: {
          [key]: order,
        },
      },
      () => {
        this.getcustomerData();
      }
    );
  }

  filterStatus(option) {
    this.setState(
      {
        selectedCard:
          this.state.selectedCard &&
          this.state.selectedCard.label === option.label
            ? null
            : option,
        skip: 0, 
        allCustomers: [],
        skipCount:0 
      },
      () => this.getcustomerData()
    );
  }

  setChangePasswordId = (resetId) => {
    this.setState({ resetId });
  };

  setTab = (tab) => {
    this.setState({ tab });
  };
  listAllCSR = (search) => {
    
    let data = {
      CSR: true,
      active : true
    };
    if(search){
        data.searchTerm = search 
    }
    return new Promise((resolve, reject) => {
      this.props.actions.listAllFleetManagers(data).then((options) => {
        const CSR = [];
        let allDatas = options || [];

        allDatas.forEach((obj) => {
          CSR.push({
            value: obj.value,
            label: obj.allInfos.name + " " + obj.allInfos.lastName,
            allInfos: obj,
          });
        });

        this.setState({ AllCSRList: CSR });
        resolve(options);
      });
    });
  }
 
  assignCSRtoCustomer = (csrList,msg) => {
    const formData = new FormData()
    formData.append('assignedCSR', JSON.stringify(csrList.map(item=>item.value)))
    formData.append('customerId',  this.state.Selected_customer._id)
    assignCSRtoCustomer(formData)
    .then(result =>  {
      const CSR = [];
      let allDatas = result.assignedCSR || [];
      allDatas.forEach((obj) => {
        CSR.push({
          value: obj._id,
          label: obj.name + " " + obj.lastName,
          allInfos: obj,
        });
      });
      toastr.show(msg,'success')
      this.setState({ assignedCSR: CSR, isloading : false });
      this.getcustomerData()
    })
    .catch((err)=> this.setState({ isloading : false }))
  }
  assignCSR = (csr) => {
    this.setState({isloading : true})
    let csrList = [...this.state.assignedCSR]
     const existingCSR =  csrList.find(item=> item.value === csr.value)
      if(!existingCSR){
        csrList.push(csr)
        this.assignCSRtoCustomer(csrList, `${csr.label} assigned successfully`)
      }else{
        toastr.show(`${csr.label} is already added`, "error")
        this.setState({isloading : false})
      }
      this.setState({showAllCSRList : false})

  }
  
  removeCSRfromCustomer = (csr) =>{
    this.setState({isloading : true})
    let csrList = this.state.assignedCSR.filter(item=> item.value != csr.value)
    this.assignCSRtoCustomer(csrList, `${csr.label} Removed Successfully`)
  }
  handleClickOutside(event) {
    if (this.state.showAllCSRList && this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({showAllCSRList : false})
    }
  }

  handleDefaultScroll = () => {
    const dataTable = document.getElementsByClassName('rdg r104f42s700-beta13 fill-grid rdg-light customer-grid');
    if (dataTable && dataTable[0]) {
      this.handleScroll({ currentTarget: dataTable[0] }, true);
    }
  }

  handleScroll = (e) => {
    if (this.state.isLoading || isAtBottom(e)) return;
    if (this.state.skip + this.state.loadsPerReq < this.state.totalCustomers) {
      this.setState({skip: this.state.loadsPerReq + this.state.skip}, () => {
        this.getcustomerData(true)
      })
    }
  }

  onSortColumnsChange = ((sortColumns) => {
    this.setState({sortColumns: sortColumns.slice(-1)}, () => {
      this.getcustomerData();
    })
  });

  syncCustomerWithQB (params){
    this.setState({qbButtonDisable:true})
    syncCustomerToQB(params).then((result) => {
      if (result) toastr.show("successfully synced", "success");
      this.setState({qbButtonDisable:false})
    }).catch((err) => {
      console.log(err, 'err')
      this.setState({qbButtonDisable:false})
    })
  }

  render() {
    let allCustomers = this.props.customers;
    if (!allCustomers) {
      allCustomers = [];
    }


    const enabledCount = _.cloneDeep(allCustomers).filter(
      (d) => d.isDeleted === false
    ).length;
    const disabledCount = _.cloneDeep(allCustomers).filter(
      (d) => d.isDeleted === true
    ).length;

    let Terminals = [];
    if (this.state.Terminals && this.state.Terminals.length > 0) {
      Terminals = this.state.Terminals.map((D) => {
        return {
          value: D._id,
          label: D.name,
        };
      });
    }
    let companyAddress = [];
    if (this.state.Selected_customer) {
      if (this.state.Selected_customer.address) {
        companyAddress.push(this.state.Selected_customer.address);
      }
    }

    if (["Enabled", "Inactive"].includes(this.state.fitlerDeletedEnabled))
      allCustomers = allCustomers.filter((d) =>
        this.state.fitlerDeletedEnabled === "Enabled"
          ? !d.isDeleted
          : d.isDeleted
      );


      const checkPermission =()=>{
        if(isTrialUser()){
          this.setState({upgradeTopro: !this.state.upgradeTopro})
        }
      }
    
    const filteredAllCSR = this.state.AllCSRList.filter((item)=>{
      return !this.state.assignedCSR.find(csr => csr.value == item.value)
    })
    let showAddRemoveOptions = (getStorage("currentUserRole") == "carrier" || (getStorage("currentUserRole") == "fleetmanager" && JSON.parse(getStorage("loggedInUser")).fleetManager && JSON.parse(getStorage("loggedInUser")).fleetManager.admin  )) 
    let currentUser = JSON.parse(getStorage("loggedInUser"));  
    let {customerProfileCustomersPermission, customerProfileShipperPermission, customerProfileConsigneePermission, customerProfileChassisPermission} = checkCustomerProfilePermission(currentUser)
    const updatedTabList = isEmbeddedEmailEnabled() ? newTabLists : tabLists
    return (
        <div className="position-relative subheader-wrapper">
          {this.state.isLoading && <LoaderBar></LoaderBar>}
          {!this.props.isQuickAdd &&
            <div> 
              <SubHeaderTab
                val="customerItems"
                buttons={
                  <Fragment>
                    <button
                      className="btn btn-outline-light mr-2"
                      onClick={() => this.handleCompleteDownload()}
                    >
                      <IconDownload className="mr-2" />
                      Download CSV
                    </button>
                    {(this.checkUserPermission() && (customerProfileCustomersPermission || 
                      customerProfileShipperPermission || customerProfileConsigneePermission || customerProfileChassisPermission)
                    ) && <button
                      className="btn btn-success"
                      onClick={() => {
                        this.resetform();
                        this.setState({
                          showAddModal: true,
                          documents: [],
                          newTerminal: null,
                        });
                      }}
                    >
                      <IconPlus className="mr-2" />
                      Add New {titleCustomerOrOrg()}
                    </button>}
                  </Fragment>
                }
              />
              {this.state.addCustomer && (
                <div className="subheader">
                  <div className="subheader__inner d-flex">
                    <div className="d-flex flex-row align-items-center">
                      <button
                        className="btn btn-outline-light"
                        onClick={() => {
                          if (this.props?.location?.state?.returnUrl) {
                            return this.props?.router?.push(this.props?.location?.state?.returnUrl)
                          }
                          this.setState({ addCustomer: false, tab: 1 });
                          this.handleClearQueryParams()
                        }}
                      >
                        <IconAngleArrowLeft />
                      </button>
                      <div className="d-flex ml-3 align-items-center">
                        <h5 className="mb-0">
                          {this.state.Selected_customer &&
                            this.state.Selected_customer.company_name}
                        </h5>
                      </div>
                    </div>
   
                    <div class="ml-auto position-relative">
                      <button
                        class="btn btn-outline-light mr-1"
                        onClick={() => {
                          this.setState({ addCustomer: false, tab: 1 });
                          this.handleClearQueryParams()
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        class="btn btn-success"
                        onClick={() => {
                          $(".btncustomersave").trigger("click");
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              )}
   
              <div className={`page-fluid`}>
                {!this.state.addCustomer ? (
                  <React.Fragment>
                    <div className="top-page" id="dth-top-page">
                      {/* Title and Pagination */}
                      <div className="pb-15 d-flex align-items-center justify-content-between">
                        <h4 className="font-20 mb-0">{titleCustomersOrOrgs()}</h4>
                        {/* <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          innerClass="pagination mb-0"
                          firstPageText="First"
                          lastPageText="Last"
                          activePage={this.state.activePage}
                          itemsCountPerPage={50}
                          totalItemsCount={this.state.counts.total}
                          pageRangeDisplayed={5}
                          onChange={(page) => {
                            this.setState({ activePage: page }, () => {
                              this.getcustomerData(page);
                            });
                          }}
                        /> */}
                      </div>
                      {/* Customer Cards */}
                      <div className={`form-row mb-15 ${this.state.selectedCard ? 'hasactive' : ''}`}>
                        {cardOptions.map((option, index) => {
                          return (
                            <div
                              className="col-xl col-md-3 col-sm-6 col-6 mb-1"
                              key={index}
                            >
                              <div
                                className={`ncard d-flex flex-column justify-content-between p-10 sbox--wrapper pointer ${
                                  this.state.selectedCard &&
                                  this.state.selectedCard.label === option.label
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  amplitudeTrack( `${option?.label?.toUpperCase()}_CARD`,{source: 'customer_profile'})
                                  this.filterStatus(option)
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <div className={`mr-10 sbox sbox--default px-2 h-100 ${
                                  this.state.selectedCard &&
                                  this.state.selectedCard.label === option.label
                                    ? "sbox--active"
                                    : ""
                                }`}>
                                    {this.state.counts[option.countKey]}
                                  </div>
                                  <h6 className="card-title text-uppercase text-muted mb-0">
                                    {option.label}
                                  </h6>
                                  <div className="ml-auto">
                                  {
                                    
                                    this.state.selectedCard &&
                                    this.state.selectedCard.label === option.label
                                      ? <IconAngleArrowDown />
                                      : <IconAngleArrowRight />
                                  }
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* Table Filter */}
                      <div className="filter-row d-flex align-items-center">
                        {/* Search Bar */}
                        <div className="app-search header-search">
                          <span className="search-icon"> </span>
                          <div className="position-relative">
                            <input
                              className="bill-control input-search-left form-control"
                              name=""
                              id="customerSearchInput"
                              placeholder="Search"
                              value={this.state.searchTerm}
                              onChange={(d) => this.handleInput(d)}
                            />
                          </div>
                        </div>
                        {/* Active or Inactive */}
                        <div className="mr-2 w-150">
                          <Select
                            options={[
                              { value: "Active", label: "Active" },
                              { value: "Inactive", label: "Inactive" },
                            ]}
                            value={{
                              value: this.state.fitlerDeletedEnabled,
                              label: this.state.fitlerDeletedEnabled,
                            }}
                            styles={smallSelectStyle}
                            onChange={({ value }) =>
                              this.setState(
                                {
                                  fitlerDeletedEnabled: value,
                                  isActive: !this.state.isActive,
                                  skip: 0
                                },() => { this.getcustomerData();}
                              )
                            }
                          />
                        </div>

                        <div className="form-group mr-2 mb-0  w-150">
                          <Select
                            options={filterOnHoldOptions}
                            value={this.state.filterOnHold && filterOnHoldOptions.find(el=>el.value===this.state.filterOnHold)}
                            styles={smallSelectStyle}
                            onChange={({ value }) => {
                              let account_hold = false;
                              if(value ==='On Hold'){
                                account_hold = true;
                              }else if (value ==='Not On Hold'){
                                account_hold = false;
                              }
                              this.setState({ filterOnHold: value, account_hold, skip: 0 }, () => {
                                this.getcustomerData();
                              })
                            }}
                          />
                        </div>

                        {/* Select Terminal */}
                        {((getStorage("currentUserRole") === "fleetmanager" &&
                          configuration.idForTerminal.indexOf(
                            JSON.parse(getStorage("loggedInUser")).fleetManager
                              .carrier
                          ) != -1) ||
                          (getStorage("currentUserRole") == "carrier" &&
                            configuration.idForTerminal.indexOf(
                              getStorage("currentUserID")
                            ) != -1)) && (
                          <div className="form-group mb-0 w-150">
                            <Select
                              onChange={(choosenTerminals) =>
                                this.setState({ choosenTerminals }, () => {
                                  this.getcustomerData();
                                })
                              }
                              styles={smallSelectStyle}
   
   
                              isMulti
                              name="choosenTerminals"
                              options={Terminals}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                        )}
                      </div>
                    </div>
   
                    {/* Customer Profile Table */}
                    <DynamicTableHeight pageId={'customer-profile'} />
                    <DataGrid
                      key={this.remountKey}
                      columns={this.state.columns}
                      rows={this.state.allCustomers}
                      sortColumns={this.state.sortColumns}
                      onSortColumnsChange={this.onSortColumnsChange}
                      rowHeight={50}
                      defaultColumnOptions={{
                        sortable: true,
                      }}
                      headerRowHeight={30}
                      className={"fill-grid rdg-light customer-grid"}
                      onScroll={this.handleScroll}
                      components={{
                        noRowsFallback: <EmptyRowsRenderer />
                      }}
                      style={{height: `calc(100vh - var(--dynamic-table-height-offset))`}} 
                    />
                    {/* <div className="table-responsive" style={{height: 'calc(100vh - 318px)'}}>
                      {this.state.isCustomerLoading && <LoaderBar></LoaderBar>}
                      <table className="table table-card">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            {[
                              "Company Name",
                              "Phone",
                              "Main Contact Name",
                              "Address1",
                              "City",
                              "State",
                              "Zip Code"
                            ].map((th, index) => {
                              return (
                                <th
                                  key={index}
                                  scope="col"
                                  onClick={() => this.sortOption(th)}
                                  className={sortingOptions[th] ? "table-sort table-sort--default" : ""}
                                >
                                  {th}
                                </th>
                              );
                            })}
   
                            {((getStorage("currentUserRole") === "fleetmanager" &&
                              configuration.idForTerminal.indexOf(
                                JSON.parse(getStorage("loggedInUser")).fleetManager
                                  .carrier
                              ) != -1) ||
                              (getStorage("currentUserRole") === "carrier" &&
                                configuration.idForTerminal.indexOf(
                                  getStorage("currentUserID")
                                ) !== -1)) && <th scope="col">Terminal</th>}
   
                          </tr>
                        </thead>
                        <tbody>
                          {allCustomers.map((customer) => {
                            return (
                              <CustomerList
                                customer={customer}
                                setChangePasswordId={this.setChangePasswordId}
                                Terminals={this.state.Terminals}
                                selectCustomer={this.selectCustomer}
                                removeCustomer={this.removeCustomer}
                                key={customer._id}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    </div> */}
                  </React.Fragment>
                ) : (
                  <div className="form-row h-100">
   
                    <div className="col-md" style={{ maxWidth: 270, width: "20%" }}>
                      <div className="card p-15 mb-1">
                        <h5>About</h5>
                        <div className="d-flex align-items-top justify-content-between mb-10">
                          <span className="text-muted mr-10"><IconUserSingle /></span>
                          <span>
                            {" "}
                            {this.state.Selected_customer &&
                              this.state.Selected_customer.main_contact_name}
                          </span>
                        </div>
                        <div className="d-flex align-items-top justify-content-between mb-10">
                          <span className="text-muted mr-10"><IconPhone /></span>
                          <span>
                            {" "}
                            {this.state.Selected_customer &&
                              this.state.Selected_customer.mobile}
                          </span>
                        </div>
                        <div className="d-flex align-items-top justify-content-between mb-10">
                          <span className="text-muted mr-10"><IconAt /></span>
                          <span className="text-break">
                            {" "}
                            {this.state.Selected_customer &&
                              this.state.Selected_customer.email}
                          </span>
                        </div>
                        <div className="d-flex align-items-top justify-content-between mb-10">
                          <span className="text-muted mr-10"><IconMapMarkerOutline /></span>
                          <span>
                            {" "}
                            {defaultAddressFormat() === addressFormatEnum.EU ? 
                              addressParser(this.state.Selected_customer)
                              : this.state.Selected_customer?.address}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                        <span className="text-muted">Customer Reps </span>&nbsp;                    
                        <div className="group d-flex align-items-center mt-1">
                          
                          {this.state.assignedCSR && this.state.assignedCSR.length > 0 && 
                            this.state.assignedCSR.slice(0, 3).map((csr, index)=>{
                              
                            return(
                              <React.Fragment>
                                <div className="group__avatar  position-relative">
                                  <div className={ `avatar-circle avatar-sm ${csr.allInfos && !csr.allInfos.profilePicture  && "border-1 text-primary"}`} 
                                   data-tip data-for={csr.value}  

                                   onMouseEnter={()=> this.setState({showRemoveIcon : index})} 
                                   onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                                    {csr.allInfos && csr.allInfos.profilePicture ?
                                       <img className="avatar rounded-circle" src={csr.allInfos.profilePicture}  />
                                     : <div className="avatar-circle__initial">{`${csr.label[0]}${csr.label.split(" ")[1][0]}`}</div>
                                    }
                                  {this.state.showRemoveIcon == index &&  showAddRemoveOptions &&
                                    <div className="notification-icon-header z-1 pointer" 
                                      onClick={()=>this.removeCSRfromCustomer(csr)}  
                                      onMouseEnter={()=> ReactTooltip.hide()}
                                      style={{top: "-7px", color : "red", backgroundColor : "#dbe2eb"}} >
                                        <IconTimesThinClose/>
                                        </div>}
                                  </div>
                                </div>
                                <ReactTooltip
                                  place="bottom"
                                  effect="solid"
                                  id={csr.value}
                                >
                                  <span key ={index}>{csr.label} 
                                  </span>
                                </ReactTooltip>
                                </React.Fragment>
                            )
                          })}
                          {this.state.assignedCSR && this.state.assignedCSR.length > 3 && (
                            <div className="group__avatar z-3">
                              <div className="avatar avatar-circle avatar-sm bg-light border-1 text-primary" data-tip data-for="more"  onMouseEnter={()=> this.setState({showRemoveIcon : 'more'})}>
                               <span className="text-dark"> +{this.state.assignedCSR.length - 3}</span> 
                              </div>

                              {this.state.showRemoveIcon == 'more' &&  <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated z-3" style={{right : "0" }} ref={this.wrapperRef}>
                              <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }} onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                                {this.state.assignedCSR && this.state.assignedCSR.length > 3 && 
                                    this.state.assignedCSR.slice(3).map((csr, index)=>{
                                  return(
                                    <div  key={index} className="d-flex align-items-center mb-10 pointer" >
                                      <div className="flex-shrink-0 mr-10 align-self-center">
                                        {
                                          csr && csr.allInfos && csr.allInfos.profilePicture ? 
                                          <img className="avatar rounded-circle " src={csr.allInfos.profilePicture}  /> : 
                                          <div className="avatar-circle undefined">
                                          {`${csr.allInfos.name[0]}${csr.allInfos.lastName[0]}`}
                                        </div>
                                        }                                          
                                      </div>
                                      <div className="flex-grow-1 text-truncate">
                                        <h5 className="font-12 font-weight-500 text-dark m-0">{csr.label}</h5>
                                      </div>
                                      {showAddRemoveOptions && 
                                      <div onClick={()=>this.removeCSRfromCustomer(csr)} >
                                      <IconTimesThin />
                                      </div>}
                                      
                                    </div>
                                  ) 
                                })}
                              </div>
                            </div>}
                            </div>
                          )}
                          {showAddRemoveOptions && <div className="ml-10 group__avatar">
                               {this.state.isloading ? 
                                <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                  >
                                  </span>
                              : <div className="avatar-circle avatar-sm bg-blue-500 pointer"  onMouseEnter={()=> this.setState({showRemoveIcon : undefined})}  onClick={()=>{this.setState({showAllCSRList : !this.state.showAllCSRList,csrSearchTerm : ""}); this.listAllCSR()}}> 
                                <div className="avatar-circle__initial text-white font-10" >
                                <IconPlusSmall /> 
                                </div>   
                                </div>}  
                              
                          
                            {this.state.showAllCSRList && 
                              <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated popdrop--hcenter z-1" ref={this.wrapperRef}>
                                <div className="app-search position-relative mb-1">
                                  <span className="search-icon ml-1"></span>
                                  <div className="position-relative mb-10">
                                    <input 
                                      type="input" 
                                      class="form-control input-search-left bg-gray-50" 
                                      placeholder="Search CSR.." 
                                      value={this.state.csrSearchTerm} 
                                      onChange={ e => {
                                        this.setState({csrSearchTerm : e.target.value});
                                          debounce(this.listAllCSR(e.target.value),500)
                                          }} 
                                    />
                                  </div>
                                </div>
                                <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }}>
                                  {filteredAllCSR && filteredAllCSR.length > 0 ? filteredAllCSR.map((csr, index)=>{
                                    return(
                                      <div  key={index} className="d-flex align-items-center mb-10 pointer" onClick={()=>this.assignCSR(csr)}>
                                        <div className="flex-shrink-0 mr-10 align-self-center">
                                        {
                                          csr && csr.allInfos && csr.allInfos.allInfos && csr.allInfos.allInfos.profilePicture ? 
                                          <img className="avatar rounded-circle " src={csr.allInfos.allInfos.profilePicture}  /> : 
                                          <div className="avatar-circle undefined">
                                          {`${csr.label[0]}${csr.label.split(" ")[1][0]}`}
                                        </div>
                                        }
                                        </div>
                                        <div className="flex-grow-1 text-truncate">
                                          <h5 className="font-12 font-weight-500 text-dark m-0">{csr.label}</h5>
                                        </div>
                                      </div>
                                    ) 
                                  }) : <div> No CSR's Available</div>}
                                 
                                </div>
                              </div>
                            }
                          </div>}
                          
                        </div>

                      </div>
                    </div>
                    {this.state?.customerType.intersect([
                          "caller",
                          "ALL",
                        ]).length > 0 && this.isAccountServiceEnabled && this.state?.Selected_customer?.accountingInfo?.accountingId!==DONTSYNCME && <div>
                            <button
                              className="btn btn-primary text-white mt-2"
                              style={{ width: '260px' }}
                              disabled={this.state.qbButtonDisable}
                              onClick={() => this.syncCustomerWithQB({ customerIds: [this.state.Selected_customer?._id] })}
                            >
                              {this.state.qbButtonDisable && <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}Sync With QuickBooks
                            </button>
                        </div>}
                  </div>              
                 
                  
                    <div className="col-md" style={{width: '80%'}}>
                      <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom bg-white">
                        {updatedTabList.map((list) => {
                          return (
                            <TabList
                              {...list}
                              key={list.id}
                              tab={this.state.tab}
                              setTab={this.setTab}
                              Selected_customer={this.state.Selected_customer}
                            />
                          );
                        })}
                      </ul>

                      <div className="tab">
                          {this.state.addCustomer && (
                            this.isShiposCarrier && [6, 7].indexOf(this.state.tab) > -1 ?
                              <div className="py-5">
                                <NewUpgradeToPro />
                              </div>
                              :
                              <AddCustomerComponent
                                Terminals={this.state.Terminals}
                                submitForm={this.submitForm}
                                closeModal={() => this.closeModal()}
                                updateState={(val) => this.updateState(val)}
                                initialValues={this.state.Selected_customer}
                                {...this.props}
                                {...this.state}
                                documents={this.state.documents}
                                added={() => {
                                  this.getcustomerData();
                                }}
                                preferredCurrencies={this.state.preferredCurrencies}
                                qbTaxOptions={this.state.qbTaxOptions}
                                fetchTaxCodes={this.fetchTaxCodes}
                                addressValidation={this.state.addressValidation}
                                addressValidationSetState={this.addressValidationSetState}
                              />
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
          <Modal show={this.state.showAddModal || this.props.isQuickAdd} dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable" animation={false}>
            <Modal.Header>
              <Modal.Title>{isEmbeddedEmailEnabled() ? `Add ${Organization}` : 'Add New Customer'}</Modal.Title>
            </Modal.Header>
            <ModalBody>

                <AddCustomerComponent
                  Terminals={this.state.Terminals}
                  submitForm={this.submitForm}
                  AddModal={this.state.showAddModal || this.props.isQuickAdd}
                  closeModal={() => this.closeModal()}
                  updateState={(val) => this.updateState(val)}
                  initialValues={this.state.Selected_customer}
                  {...this.props}
                  {...this.state}
                  documents={this.state.documents}
                  added={() => {
                    this.getcustomerData();
                  }}
                  isQuickAdd={this.props.isQuickAdd}
                  showAddModal={this.state.showAddModal}
                  isOnlyPopup={this.props.isOnlyPopup}
                  preferredCurrencies={this.state.preferredCurrencies}
                  qbTaxOptions={this.state.qbTaxOptions}
                  fetchTaxCodes={this.fetchTaxCodes}
                  selectedCard={this.state.selectedCard}
                  customerProfileCustomersPermission={customerProfileCustomersPermission}
                  customerProfileShipperPermission={customerProfileShipperPermission}
                  customerProfileConsigneePermission={customerProfileConsigneePermission}
                  customerProfileChassisPermission={customerProfileChassisPermission}
                />
   
            </ModalBody>
            <Modal.Footer>
              <button
                className="btn btn-close"
                onClick={() => {
                  this.setState({ showAddModal: false, tab: 1 });
                  if(this.props.isQuickAdd){
                    this.props.falsyData("customer");
                  }
                }}
              >
                Cancel
              </button>
              {this.state.tab !== 5 && (
                <button
                  className={`btn ${
                    this.state.Selected_customer &&
                    this.state.Selected_customer._id
                      ? "btn-primary"
                      : "btn-success"
                  }`}
                  disabled={this.state.isSaving }
                  onClick={() => {
                    $(".btncustomersave").trigger("click");
                    // this.submitForm(this.submitForm)
                  }}
                >
                  {this.state.Selected_customer &&
                  this.state.Selected_customer._id
                    ? "Update New Customer"
                    : `Add New ${titleCustomerOrOrg()}`}
                </button>
              )}
            </Modal.Footer>
          </Modal>
          
          {this.state.resetId ? <Modal show={this.state.resetId ? true : false} className="show">
            <ResetCustomerPassword
              setResetId={() => this.setState({ resetId: undefined })}
              customerId={this.state.resetId}
              actions={this.props.actions}
            />
          </Modal> : null}
          

          <Modal show={this.state.upgradeTopro} bsSize="md" onHide={() => this.setState({upgradeTopro:false})}>
             { this.state.upgradeTopro && <div className="m-5 pt-5">
                    <UpgradeToPro/>
              </div> }     
          </Modal>
        </div>
      );
  }
}

Yup.addMethod(Yup.string, "sameAs", function (ref, message) {
  return this.test("sameAs", message, function (value) {
    let other = this.resolve(ref);
    return !other || !value || value === other;
  });
});

const ResetForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;
  return (
    <div className="center-child">
      <Modal.Header>
        <h4 className="modal-title" id="myModalLabel">
          Reset Password
        </h4>
        <button
          type="button"
          className="close"
          onClick={() => props.setResetId()}
        >
          <IconTimes />
        </button>
      </Modal.Header>
      <div className="sign-up-form">
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <Modal.Body>
              <div className={`form-group  ${errors.password && "has-error"}`}>
                <label htmlFor="password">
                  New Password
                  <span
                    className="required-field"
                    title="This field is reqired."
                  >
                    *
                  </span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password && (
                  <span className="text-danger">{errors.password}</span>
                )}
              </div>
              <div
                className={`form-group  ${
                  errors.confirmPassword && "has-error"
                }`}
              >
                <label htmlFor="password">
                  Confirm Password
                  <span
                    className="required-field"
                    title="This field is reqired."
                  >
                    *
                  </span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <span className="text-danger">{errors.confirmPassword}</span>
                )}
              </div>
              {isSubmitting && (
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <Spinner />
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-close"
                onClick={() => {
                  props.setResetId();
                }}
              >
                Close
              </button>
              <button
                type="submit"
                name="resetCustomerPassword"
                id="resetCustomerPassword"
                value="Save"
                className="btn btn-danger"
              >
                Reset
              </button>
            </Modal.Footer>
          </div>
        </form>
      </div>
    </div>
  );
};

const ResetCustomerPassword = withFormik({
  mapPropsToValues: (props) => ({
    password: "",
    confirmPassword: "",
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(5, "Enter atleast 5 characters.")
      .max(25, "Enter less than 25 characters.")
      .required("Password is required."),
    confirmPassword: Yup.string()
      .sameAs(Yup.ref("password"), "passwords doesn't match")
      .min(5, "Enter atleast 5 characters.")
      .max(50, "Enter less than 50 characters.")
      .required("Password confirm is required"),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const password = values.password;
    const customerId = props.customerId;
    props.actions
      .resetCustomerPassword({
        customerId,
        password,
      })
      .then((result) => {
        props.setResetId();
        toastr.show("Your password has been successfully changed!", "success");
        setSubmitting(false);
      })
      .catch((Err) => {})
      .finally(() => {
        setSubmitting(false);
      });
  },
})(ResetForm);

function mapStateToProps(state) {
  return {
    customers: state.TmsReducer.customersAll,
    isCustomerLoading: state.TmsReducer.isCustomerAllLoading,
    Terminals: state.HomeReducer.terminals,

  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch: dispatch,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Customer);