import moment from "moment";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import {
  IconDecreaseArrow,
  IconDownload,
  IconIncreaseArrow
} from "../../../../../../Components/Common/Icons";
import { COUNTER_CARDS_POPUP, COUNTER_CARDS_POPUP_NOT_REQUIRED, REPORT_NAMES, REPORT_TYPES } from "../../constants";
import { checkDateRange, downloadCsvFromBuffer, fixedNumer, getDataForChartFromDB } from '../../helper';
import Popup from "../Popup";
import SkeletonCard from './SkeletonCard';
import { amplitudeTrack } from "services";

const CounterCard = (props) => {

  const {
    title,
    description,
    count,
    percentageChange,
    startDate,
    endDate,
    reportType,
    fromExpandedCard,
    downloadCSV,
    isDownload = false,
    setIsPopup,
    isToFixedNeeded,
    percentageChangeNotRequired,
    preFixOfcardCount,
    hideCounts
  } = props;

  const isNegative = percentageChange < 0;
  const isPastDueInvoice = reportType === REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES
  const [chartCount, setChartCount] = useState(count || 0)
  const [chartPercentageChange, setChartPercentageChange] = useState(percentageChange || 0)
  const [isLoading, setIsLoading] = useState(false)
  const [fromDate, setFromDate] = useState(startDate)
  const [toDate, setToDate] = useState(endDate)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isCounterCardPopup, setIsCounterCardPopup] = useState(false)
  const [rowDataForPopup, setRowDataForPopup] = useState([])

  const getDataForCounterCard = async (from, to, isDownload) => {
    try {
      if (!isDownload) setIsLoading(true)

      const _startDate = from || fromDate
      const _endDate = to || toDate

      let payload = {
        chartType: reportType,
        fromDate: _startDate,
        toDate: _endDate
      }

      let responseData = {}
      if (isDownload) payload.isDownload = true
      responseData = await getDataForChartFromDB(payload, reportType)

      if (responseData?.count > -1 && !isDownload) {
        setChartCount(responseData?.count)
      }
      if (responseData?.percentageChange || responseData?.percentageChange === 0) {
        setChartPercentageChange(responseData?.percentageChange)
      }
      setIsLoading(false)

      return responseData
    } catch (error) {
      console.log("🚀 ~ getDataForCounterCard ~ error:", error)
      setIsLoading(false)
      setChartCount(0)
      setChartPercentageChange(0)
    }
  }

  const handleOnDownload = async () => {
    setIsDownloading(true)
    if (downloadCSV) await downloadCSV();

    let eventProperties = {
      report_name: reportType
    }
    amplitudeTrack("DOWNLOAD_REPORT", eventProperties)
    setIsDownloading(false)
  }

  const handleOnCloseOfCounterCardPopup = () => {
    setIsCounterCardPopup(false)
  }

  const downloadCSVForCounterCard = async () => {
    let csvBuffer = []
    if(COUNTER_CARDS_POPUP_NOT_REQUIRED?.includes(reportType)) {
      const csv = Papa.unparse(rowDataForPopup, { delimiter: ',' });
      csvBuffer = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    } else {
      csvBuffer = await getDataForCounterCard(startDate, endDate, true)
    }
    downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`)
  }

  useEffect(() => {
    if (!fromExpandedCard) getDataForCounterCard()
  }, [])

  useEffect(() => {
    if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate))) return
    setFromDate(startDate)
    setToDate(endDate)
    if (!fromExpandedCard) getDataForCounterCard(startDate, endDate)
  }, [startDate, endDate])

  if (isLoading)
    return <SkeletonCard style={{ width: "100%", height: "100%" }} />
  else
    return (
      <>
        <div className="px-20 py-15 d-flex flex-column">
          <div className="d-flex justify-content-between pb-15">
            <div className="">
              {title &&
                <div
                  className="font-14 font-medium line-height-20"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    transition: 'text-decoration 0.3s'
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.textDecoration = 'underline'
                  }}
                  onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                  onClick={() => {
                    let eventProperties = {
                      report_name: reportType
                    }
                    amplitudeTrack("VIEW_COUNTER_CARD", eventProperties)
                    if (COUNTER_CARDS_POPUP_NOT_REQUIRED?.includes(reportType)) {
                      setRowDataForPopup([{
                        "Event Name": REPORT_NAMES[reportType],
                        count: chartCount || count || 0,
                      }])
                      setIsCounterCardPopup(true)
                      return
                    }
                    if (COUNTER_CARDS_POPUP.includes(reportType)) {
                      setIsCounterCardPopup(true)
                      return
                    }
                    setIsPopup && setIsPopup(true)
                  }}
                >
                  {title}
                </div>}
              {description &&
                <div className="font-12 text-left text-muted font-weight-normal clamp-1">
                  {description}
                </div>}
            </div>
            <div>
              {isDownload && (
                <div className="d-flex">
                  {isDownloading ? <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span> :
                    <button
                      className="btn btn-outline-light wh-32px px-2"
                      onClick={() => {
                        if (isDownloading) return
                        handleOnDownload()
                      }}
                    >
                      <IconDownload className="text-muted " />
                    </button>}
                </div>
              )}
            </div>
          </div>
          {!hideCounts && (chartCount || count || chartPercentageChange > 0 || (endDate && startDate)) && <div className="d-flex gap-10 align-items-center">
            <div
              className="font-weight-bold font-40 text-dark">
              {`${preFixOfcardCount ? preFixOfcardCount : ''}${ isToFixedNeeded ? fixedNumer(chartCount || count || 0) : (chartCount || count || 0)}`}
            </div>
            <div>
              {!percentageChangeNotRequired && <>
                <div className="align-items-center d-flex gap-5">
                  {(isNegative || chartPercentageChange < 0) ? <IconDecreaseArrow className={isPastDueInvoice ? "text-success" : "text-danger"} /> : <IconIncreaseArrow className={isPastDueInvoice ? "text-danger" : "text-success"} />}
                  <span
                    className={`badge badge-pill badge-margin text-white ${
                      isPastDueInvoice
                        ? (isNegative || chartPercentageChange < 0) 
                          ? "bg-success-500"  // Green for negative change in past due invoices
                          : "bg-danger-500"   // Red for positive change in past due invoices
                        : (isNegative || chartPercentageChange < 0)
                          ? "bg-danger-500"   // Red for negative change in normal cases
                          : "bg-accent-500"   // Accent color for positive change in normal cases
                    }`}
                  >
                    {(isNegative || chartPercentageChange < 0) ? "" : "+"}
                    {chartPercentageChange || percentageChange || 0}%
                  </span>
                </div>
                {endDate && startDate &&
                  <div className="font-weight-normal font-12 text-gray-500">
                    {checkDateRange(startDate, endDate)}
                  </div>}
              </>}
            </div>
          </div>}
        </div>
        {isCounterCardPopup && <Popup
          rows={rowDataForPopup}
          downloadCSV={downloadCSVForCounterCard}
          reportType={reportType}
          isPopup={isCounterCardPopup}
          handleOnClose={handleOnCloseOfCounterCardPopup}
          fromDate={startDate}
          toDate={endDate}
        />}
      </>

    );
};

export default CounterCard;
