import { getStorage, setStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams from '../../../Components/Common/jsonToQueryParams';
import { euAddressFormate} from '../Dispatcher/actionCreators';
import { addressFormatEnum } from '../../../services/Common.services';
import _ from 'lodash';
import config from '../../../config';
import { SET_MEASURE_UNITS } from '../constant';
const QBURL= process.env.REACT_APP_QB_API_URL

export function RemoveReport(params) {
  let url = 'driver/removeCustomReport';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getFrequencyResult(params) {
  let fields = []
  if (params === "Weekly") {
    fields = [
      { value: '1', label: 'Monday' },
      { value: '2', label: 'Tuesday' },
      { value: '3', label: 'Wednesday' },
      { value: '4', label: 'Thursday' },
      { value: '5', label: 'Friday' },
      { value: '6', label: 'Saturday' },
      { value: '7', label: 'Sunday' },
    ]
  } else if (params === "Specific Day of each month") {
    [...new Array(31)].forEach((d, i) =>
      fields.push({ value: i + 1, label: i + 1 })
    )
  }
  return fields;
}
export function getCurrentDay(params) {
  let fields = [
    { value: '0_D', label: 'Current day' },
    { value: '7_D', label: 'Last 7 Days' },
    { value: '14_D', label: 'Last 14 Days' },
    { value: '30_D', label: 'Last 30 Days' },
    { value: '3_M', label: 'Last 3 Months' },
    { value: '6_M', label: 'Last 6 Months' },
    { value: '12_M', label: 'Last 12 Months' },
  ]
  return fields;
}
export function getFrequency(params) {
  let fields = [
    'Every 15 Minutes',
    'Every half hour',
    'Every hour',
    'Daily',
    'Weekly',
    'Every 2 Weeks',
    'Monthly',
    'Specific Day of each month',
  ]
  return fields;
}
export function getReportType() {
  let fields = [
    'report1',
    'report2',
    'report3',
    'report4',
    'Equipment Report',
    'Revenue Report',
    'Driver Pay Report',
    'LFD Report',
    'Return Report',
    'Master Report'
  ]
  return fields;
}
export function getfilds(params) {
  let fields = []
  if (params === "report1") {
    fields = [
      'BILL DATE',
      'BILL NUMBER',
      'BASE PRICE',
      'FUEL',
      'OVER WEIGHT',
      'REFERRER',
      'DETENTION',
      'CHASSIS',
      'PREPULL',
      'TOTAL CHARGES',
      'DAYS',
      'TOLL'
    ]
  } else if (params === "report2") {
    fields = [
      'STATUS',
      'LAST FREE DAY',
      'CUSTOMER',
      'LOAD #',
      'PICK UP BY',
      'DELIVERY LOCATION',
      'DELIVERY APPT',
      'RETURNED',
      'PO #',
      'DAY TOTAL',
      'WEEK TOTAL',
      'HOOK TO CONTAINER'
    ]
  } else if (params === 'report3') {
    fields = [
      'PICK UP BY',
      'DAY TOTAL',
      'WEEK TOTAL',
      'WEEK NUMBER',
      'MONTH TOTAL',
    ]
  } else if (params === 'report4') {
    fields = [
      'Order No #',
      'Container No',
      'Status',
      'ETA',
      'Last Free Day',
      'Pick up Date',
      'Port Of Discharge',
      'Deliver To',
      'Vessel',
    ]
  } else if (params === 'Equipment Report') {
    fields = [
      'LOAD #',
      'EQUIPMENT #',
      'DRIVER NAME',
      'LOAD STATUS',
      'BEGINING',
      'END',
      'CONTAINER #',
      'FROM LOCATION',
      'TO LOCATION',
    ]
  } else if (params === 'Driver Pay Report') {
    fields = [
      'DRIVER NAME',
      'LOAD TYPE',
      'LOAD #',
      'LOAD STATUS / DESCRIPTION',
      'DATE',
      'CONTAINER #',
      'DRIVER PAY',
    ]
  } else if (params === 'Revenue Report') {
    fields = [
      'COMPANY NAME',
      'LOAD TYPE',
      'LOAD #',
      'DATE',
      'CONTAINER #',
      'BASE RATE',
      'XTRA RATE',
      'TOTAL CHARGES',
    ]
  } else if (params === 'LFD Report') {
    fields = [
      'LOAD STATUS',
      'DRIVER',
      'LOAD #',
      'MOVE',
      'ETA',
      'LFD',
      'CUT',
      'PICKUP',
      'APT',
      'CUSTOMER',
      'CONTAINER',
      'CHASSIS',
      'SIZE',
      'TYPE',
      'SSL',
      'BOL / BKG',
      'PORT',
      'CONSIGNEE',
      'LOCATION',
      'RTN',
    ]
  } else if (params === 'Return Report') {
    fields = [
      'LOAD STATUS',
      'DRIVER',
      'LOAD #',
      'MOVE',
      'ETA',
      'LFD',
      'CUT',
      'PICKUP',
      'APT',
      'CUSTOMER',
      'CONTAINER',
      'CHASSIS',
      'SIZE',
      'TYPE',
      'SSL',
      'BOL / BKG',
      'PORT',
      'CONSIGNEE',
      'LOCATION',
      'RTN',
    ]
  } else if (params === 'Master Report'){
    fields = [
      'callerPONo',
      'approvedDate',
      'doNo',
      'lastFreeDay',
      'otherReference',
      'temperature',
      'totalWeight',
      'sealNo',
      'chassisNo',
      'callerBookingNo',
      'PONumber',
      'reservationNo',
      'shipperAddress',
      'consigneeAddress',
      'chassisPickName',
      'chassisTerminationName',
      'pricing',
      'emptyDay',
      'custom',
      'freight',
      'Status',
      'Move',
      'Load #',
      'Consignee',
      'Customer',
      'Seal Booking No.',
      'Shipment',
      'PICKUP #',
      'Reference #',
      'Purchase Order #',
      'Return #',
      'Vessel Name',
      'Port',
      'Container Type',
      'Container Size',
      'Chassis Size',
      'Chassis Type',
      'SSL',
      'Container #',
      'BOL/BKG',
      'APT',
      'Return',
      'PICKUP',
      'Cut',
      'ETA',
      'Next Address',
      'Return Location',
      'Broker Hold',
      'Carrier Hold',
      'Miles',
      'Billing Note',
      'Driver Note',
      'Yard Note',
      'Office Note',
      'Paid Amount',
      'Total Amount',
      'Remaining Amount',
      'Over Weight',
      'Terminal',
      'Appointment No.',
      'Load Assigned Date',
      'Billing Date'
    ]
  }
  return fields;
}
export function ReportAddUpdate(params) {
  let url = 'driver/addEditCustomReport';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function updateUserSettings(params) {
  let url = 'driver/updateUserSettings';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getCustomers(params) {
  let obj
  if (params) {
    obj = { searchTerm: params }
  }
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result.data && result.data.data) {
          let customers = [];

          result.data.data.forEach(el => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(el, labelData)
              customers.push({ value: el._id, label: `${el.company_name} | ${labelData.join(', ')}`, allInfos: el });
            } else {
            customers.push({ value: el._id, label: `${el.company_name} | ${el.address ? el.address.address : ''}`, allInfos: el });
            }
          })
          resolve(customers);
        }
        else {
          reject(result)
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function enableNewBillingAccountHold() {
  const url = "new-billing/enableAccountHold";
  return new Promise((resolve, reject) => {
    HTTP("post", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.log("---- enableNewBillingAccountHold error -----", error)
        reject(error);
      })
  })
}
export function disableNewBillingAccountHold() {
  const url = "new-billing/disableAccountHold";
  return new Promise((resolve, reject) => {
    HTTP("post", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.log("---- disableNewBillingAccountHold error -----", error)
        reject(error);
      })
  })
}

export function disableAccountHold() {
  const url = "tms/disableAccountHold";
  return new Promise((resolve, reject) => {
    HTTP("post", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.log("---- disableAccountHold error -----", error)
        reject(error);
      })
  })
}

export function enableAccountHold() {
    const url = "tms/enableAccountHold";
    return new Promise((resolve, reject) => {
      HTTP("post", url, null, {
        'authorization': getStorage('token'),
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log("---- enableAccountHold error -----", error)
          reject(error);
        })
    })
  }

  // Update groups for Carriers
  export function updateCarrierSettings(params, dispatch) {
    let url = 'user/updateCarrierGroupedSettings';
      return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            if (result){
              const measureUnits = JSON.parse(getStorage("measureUnits"));
              const weightUnit = _.get(measureUnits, "weightUnit", "lbs");
              const distanceUnit = _.get(measureUnits, "distanceUnit", "mi");
              const isCommodityProfileShow = _.get(measureUnits, 'isCommodityProfileShow')
              if (result.data && result.data.data && result.data.data.data) {
                const groupedProfiles = _.get(result, 'data.data.data.groupedProfiles')
                const groupedCities = _.get(result, 'data.data.data.groupedCities')
                const groupedZipcodes = _.get(result, 'data.data.data.groupedZipcodes')

                setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow }))
                if(dispatch) {
                  dispatch({
                    type: SET_MEASURE_UNITS,
                    payload: { groupedProfiles, groupedCities, groupedZipcodes }
                  });
                }
              }
              resolve(result.data.data)
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  //get user
  export function getUser(){
    return new Promise((resolve, reject) => {
      HTTP("get", "getUser", null, {
        Authorization: getStorage("token"),
      }).then((result) => {
        if (result.data.statusCode === 200) {
          let user = result.data.data.user;
          delete user?.groupedProfiles;
          delete user?.groupedCities;
          setStorage("loggedInUser", JSON.stringify(user));
          resolve(user);
        }
      }).catch((err)=>reject(err));
    })
  }

  export async function getTokenGenerateDevelopment(params) {
    let url = 'tms/generate-token';
    url = params ? url + "?" + Object.entries(params).map(e => e.join('=')).join('&') : url;
    const token = tmsToken();
    const response = HTTP('get', url, {}, {
      authorization: token
    })
    return response;
  }

export async function getPublicToken(params) {
  let url = 'tms/get-public-token';
  url = params ? url + "?" + Object.entries(params).map(e => e.join('=')).join('&') : url;
  const token = tmsToken();
  const response = HTTP('get', url, {}, {
    authorization: token
  })
  return response;
}

export function addWebhook(params) {
  let url = 'tms/webhook';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function getWebhook() {
  let url = 'tms/webhook';
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const tmsToken = function () {
  const token = getStorage('token');
  return token;
}

export function addNewCharge(payload){
  return function (dispatch) {
    let url = 'chargeCode/addNewChargeCode';
    return new Promise((resolve, reject) => {
      dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:true })
      HTTP('post', url, payload, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result && result.data && result.data.data)
          dispatch({ type: 'SET_CHARGE_CODE', payload: result.data.data })
            dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:false })
            resolve(result.data.data)
        })
        .catch((error) => {
          dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:false })
          reject(error);
        });
    })
  }
}

export function editCharge(payload){
  let url = 'chargeCode/updateChargeCode';
  return new Promise((resolve, reject) => {
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function editChargeOrder(payload){
  let url = 'chargeCode/updateChargeCodeOrder';
  return new Promise((resolve, reject) => {
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getChargeCodes (){
  return function (dispatch) {
  let url = 'chargeCode/getChargeCode'
  return new Promise((resolve, reject) => {
    dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:true })
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data)
          dispatch({ type: 'SET_CHARGE_CODE', payload: result.data.data })
          dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:false })
          resolve(result.data.data)
      })
      .catch((error) => {
        dispatch({ type: 'SET_LOADING_CHARGE_CODE', payload:false })
        reject(error);
      });
  })
} 
}

export function getDefaultChargeCodes (){
  return function (dispatch) {
    let url = 'chargeCode/getDefaultChargeCodes';
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result && result.data && result.data.data)
            dispatch({ type: 'SET_DEFAULT_CHARGE_CODE', payload: result.data.data })
            resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  } 
}

export function getAutoEmailConfig (){
  let url = 'user/get-auto-email-config'
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })

}

export function addAutoEmailConfig (payload){
  let url = 'user/auto-email-config';
  return new Promise((resolve, reject) => {
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function addChargeCodetoQuickbook (payload){
  let url = 'chargeCode/addChargeCodeToQuickbooks';
  return new Promise((resolve, reject) => {
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function syncChargeNameToQuickBooks (payload){
  let url = 'chargeCode/syncChargeNameToQuickBooks';
  return new Promise((resolve, reject) => {
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function setUpCustomField (payload){
  let url = 'setup-custom-field';
  return new Promise((resolve, reject) => {
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data && result.data.data.customFields && result.data.data.customFields[0]){
          resolve(result.data.data.customFields[0])
        } else { 
          resolve({})
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getCustomFields (payload){
  let url = 'get-custom-field';
  return new Promise((resolve, reject) => {
    HTTP('get', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data[0] && result.data.data[0].customFields && result.data.data[0].customFields[0]){
          resolve(result.data.data[0] && result.data.data[0])
        } else { 
          resolve({})
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export async function getQbCustomFieldData(params) {
  let url = 'get-qb-custom-field';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTmsDriverPayAudit(params) {
  let url = 'tms/get-driver-payment-audit';
  url = params ? url + "?" + "userId="+params : url;
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export async function getMasterCustomers(data) {
  let url = 'master-customers';
  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, {}, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        result.data && result.data.data ? resolve(result.data.data) : resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export async function confirmMasterCustomers(data) {
  let url = 'customer-review';
  return new Promise((resolve, reject) => {
    HTTP('post', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        result.data && result.data.data ? resolve(result.data.data) : resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export async function acceptRejectMasterCustomers(data) {
  let url = 'customer-review';
  return new Promise((resolve, reject) => {
    HTTP('put', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        result.data && result.data.data ? resolve(result.data.data) : resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export async function getCustomerRequest(data) {
  let url = 'customer-request';
  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        result.data && result.data.data ? resolve(result.data.data) : resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getCities(country_name,searchTerm, isAddState) {
  let url = 'getCities';
  let data = { limit: 10 };
  if(searchTerm) data.searchTerm = searchTerm;
  if(country_name) data.country_name = JSON.stringify(country_name)
  
  url = url + "?" + jsonToQueryParams(data);
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      const cities = result.data.data;
      resolve(cities.map((city) => ({ label: `${city.city}, ${city.state_id}`, value: isAddState ? `${city.city}, ${city.state_id}` : city.city })))
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export const searchCSR = (searchTerm) => {
  let url = 'carrier/getFleetManagers';
  url = url + "?" + jsonToQueryParams({ CSR: true, searchTerm });
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const csrs = result.data.data;
          resolve(csrs.map((csr) => ({ label: `${csr.name}`, value: csr._id })));
        })
        .catch((error) => {
          reject(error);
        });
    })
}

// Trackos
export async function connectWithTrackosApi(postData) {
  const data = {
    "subscriptionType":postData
  }
  const url= `myapp/syncCarriertotrackos`;
  return new Promise((resolve, reject) => {
    HTTP('post', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        result.data && result.data.data ? resolve(result.data.data) : resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}


// Check If TrackosSynced
export const checkifTrackosSynced = ()  => (dispatch) =>  {
  let url=`myapp/checkifSynced`;
  return new Promise((resolve, reject) => {
    HTTP('post', url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      let response =result.data && result.data.data ? result.data.data : result;
      dispatch({
        type: "CHECK_TRACKOSSYNC_SUCCESS",
        payload: response
      })
      resolve(response)
    })
      .catch((error) => {
        reject(error);
      });
  })
}
// Get All App Settings 
export const getStandardPort = ()  => (dispatch) =>  {
  let url = `myapp/getStandardPort`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        dispatch({
          type: "GET_STPORTS_SUCCESS",
          payload: response && response.data && response.data.data
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
// Get All App Settings 
export const getAppSettings = ()  => (dispatch) =>  {
  let url = `myapp/getSetting`;
  return new Promise((resolve, reject) => {
    HTTP('post', url, null, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        dispatch({
          type: "GET_APPSETTINGS_SUCCESS",
          payload: response && response.data && response.data.data && response.data.data.message && response.data.data.message.myappFeature
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

// Update DrayosTrack subscriptionSetting
export function updateSubscription (data) {
  return function (dispatch) {
    let url='myapp/updateSubscription';
    return new Promise((resolve, reject) => {
      HTTP('post', url, data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          dispatch({
            type: "GET_APPSETTINGS_SUCCESS",
            payload: result && result.data && result.data.data && result.data.data.message
          })
          
        dispatch(checkifTrackosSynced());
        resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
} 
// get port Credentials
export async function getPortCredentials(){
  const url = 'myapp/getcredentials'
  return HTTP('post', url,  null, {
    'authorization': getStorage('token'),
  })
}
// Update port Credentials
export async function updatePortCredentials(data){
  const url = 'myapp/updatecredentials'
  return HTTP('post', url,  data, {
    'authorization': getStorage('token'),
  })
}
// End Of Trackos Syncing


export async function setupInvoiceMemo(data){
  const url = 'setup-invoice-memo'
  return HTTP('post', url,  data, {
    'authorization': getStorage('token'),
  })
}

export function getSetting (payload){
  let url = 'get-setting';
  return new Promise((resolve, reject) => {
    HTTP('get', url, payload, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result && result.data && result.data.data){
        resolve(result.data.data)
      } else { 
        resolve({})
      }
    })
    .catch((error) => {
      reject(error);
    });
  })
}
export function getExpiryNotification() {
  let url = 'expiring-notification/getConfiguration'
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function updateSettings(params) {
  let url = 'update-setting';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function addExpiryNotification(data) {
  let url = 'expiring-notification/add'
  return new Promise((resolve, reject) => {
    HTTP('post', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
          resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
  })

}

export const getAcceptedPorts = ()  => (dispatch) =>  {
  let url = `myapp/getAcceptedPorts`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        dispatch({
          type: "GET_ACCEPTEDPORTS_SUCCESS",
          payload: response && response.data && response.data.data
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function updateBillingOptions(type, params) {
  let url = 'settings/update-billing-options'
  return new Promise((resolve, reject) => {
    HTTP('put', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let userBasicSettings = result?.data.data;
        
        delete userBasicSettings?.groupedProfiles;
        delete userBasicSettings?.groupedCities;
        delete userBasicSettings?.groupedZipcodes;

        setStorage("userBasicSettings", JSON.stringify(userBasicSettings));
        resolve(result?.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })

}
  // let url = `myapp/getAcceptedPorts`;
  // return new Promise((resolve, reject) => {
  //   HTTP('get', url, null, {
  //     'authorization': getStorage('token'),
  //   })
  //     .then((response) => {
  //       dispatch({
  //         type: "GET_ACCEPTEDPORTS_SUCCESS",
  //         payload: response && response.data && response.data.data
  //       })
  //       resolve(response)
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // })

  export function syncedChargeCodeList(){
    return new Promise((resolve, reject) => {
      HTTP("get", "chargeCode/syncedChargeCodeList", null, {
        Authorization: getStorage("token"),
      }).then((result) => {
        resolve(result?.data?.data??[]);
      }).catch((err)=>reject(err));
    })
  }



export function manageGroupSettings(action, params, dispatch) {
  let API_PROMISE = null;
  if ( action === "CREATE" ) {
    API_PROMISE = HTTP(
      'post', 
      'user/create-group-profile-settings', 
      params, 
      {
        'authorization': getStorage('token'),
      }
    )
  } else if ( action === "UPDATE" ) {
    API_PROMISE = HTTP(
      'put', 
      'user/update-group-profile-settings', 
      params, 
      {
        'authorization': getStorage('token'),
      }
    )
  } else if ( action === "DELETE" ) {
    API_PROMISE = HTTP(
      'delete', 
      `user/delete-group-profile-settings?type=${params.type}&groupId=${params.groupId}`, 
      null, 
      {
        'authorization': getStorage('token'),
      }
    )
  }

  if(!API_PROMISE) return;

  return new Promise((resolve, reject) => {
    API_PROMISE
      .then((result) => {
        if (result){
          const measureUnits = JSON.parse(getStorage("measureUnits"));
          const weightUnit = _.get(measureUnits, "weightUnit", "lbs");
          const distanceUnit = _.get(measureUnits, "distanceUnit", "mi");
          const isCommodityProfileShow = _.get(measureUnits, 'isCommodityProfileShow')
          if (result?.data?.data) {
            const groupedProfiles = _.get(result, 'data.data.groupedProfiles')
            const groupedCities = _.get(result, 'data.data.groupedCities')
            const groupedZipcodes = _.get(result, 'data.data.groupedZipcodes')

            setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow }))
            if (dispatch) {
              dispatch({
                type: SET_MEASURE_UNITS,
                payload: { groupedProfiles, groupedCities, groupedZipcodes }
              });
            }
          }
          resolve(result.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
} 
