import moment from "moment";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import { getStorage } from "services";
import { getAdminChartCounts, getBrokerChartCounts, getCustomerChartCounts, getCustomerServiceChartCounts, getEmployeeChartCounts, getFinancialsChartCounts, getOperatinsChartCounts, getSystemChartCounts, getTrackChartCounts, getEquipmentChartCounts } from "./actionCreator";
import { ADMIN_CHARTS, BROKER_CHARTS, CUSTOMER_CHARTS, CUSTOMER_SERVICE_CHARTS, EMPLOYEE_CHARTS, FINANCIALS_CHARTS, OPERATIONS_CHARTS, REPORT_TYPES, SYSTEM_CHARTS, TRACK_CHARTS, EQUIPMENT_CHARTS } from "./constants";
import numbro from "numbro";
import _ from "lodash";


export const DATE_RANGE = () => {
  const tz = getStorage("timeZone")
  // "Today": [moment().tz(tz).startOf('day'), moment().tz(tz).endOf('day')],
  return {
    "Yesterday": [moment().tz(tz).subtract(1, 'day').startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last Week": [moment().tz(tz).subtract(1, 'week').startOf('week'), moment().tz(tz).subtract(1, 'week').endOf('week')],
    "Last 7 Days": [moment().tz(tz).subtract(7, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last 15 Days": [moment().tz(tz).subtract(15, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last 30 Days": [moment().tz(tz).subtract(30, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last Month": [moment().tz(tz).subtract(1, "month").startOf("month"), moment().tz(tz).subtract(1, "month").endOf("month")],
    "Last 3 Month": [
      moment().tz(tz).subtract(3, "month").startOf("month"),
      moment().tz(tz).subtract(1, "month").endOf("month"),
    ],
  }
}

export const convertDataForChart = (data, reportType, isTableData) => {
  const desiredOrder = ["Import", "Export", "Road", "Bill"];

  if(isTableData && reportType === REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES){
    return data?.map(item => ({ name: item?.["Invoice #"], count: item?.["Date Invoiced"], status: item?.["Payment Status"] }))
  }

  if(isTableData && reportType === REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE){
    return data?.map(item => ({ name: item?.company_name, count: item?.loadCount}))
  }
  const transformers = {
    // Admin
    [REPORT_TYPES.LOADS_BY_TYPE]: () => {
      const chartData = typeOfLoadDataMapper(data, "Load Type", "count", "name");
      return chartData?.length > 0 ? chartData.sort((a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)) : data;
    },

    [REPORT_TYPES.LOADS_BY_CUSTOMER]: () => 
      data.map(item => ({ company_name: item?.["Customer"], count: item?.["Load Count"], ...item })),

    [REPORT_TYPES.TOP_DELIVERY_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.["Count"], ...item })),

    [REPORT_TYPES.TOP_PICK_UP_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Pick Up Location"], count: item?.["Count"], ...item })),

    [REPORT_TYPES.TOP_RETURN_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Return Location"], count: item?.["Count"], ...item })),
    
    // Broker
    [REPORT_TYPES.BROKER_TENDERS_SENT_BY_CARRIER]: () => 
      data.map(item => ({ company_name: item?.["Carrier"], count: item?.["# of Tenders Sent"], ...item })),

    [REPORT_TYPES.BROKER_TENDERS_ACCEPTED_BY_CARRIER]: () => 
      data.map(item => ({ company_name: item?.["Carrier"], count: item?.["# of Tenders Accepted"], ...item })),

    [REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER]: () => 
      data.map(item => ({ company_name: item?.["Carrier"], count: item?.["# of Invoices Receives"], ...item })),

    // Financials
    [REPORT_TYPES.FINANCIAL_REVENUE_BY_LOAD_TYPE]: () =>{
      const chartData = typeOfLoadDataMapper(data, "Load Type", "Revenue", "company_name");
      return chartData?.length > 0 ? chartData.sort((a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)) : data;
    },

    [REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME]: () =>
      data.map(item => ({ chargeName: item?.["Charge Name"], count: item?.["Revenue"], percentage: item?.["% of Total Revenue"] })),

    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE]: () => 
      data.map(item => ({ name: item?.["Customer"], count: item?.["Profit ($ amount)"], percentage: item?.["Profit (% of Total Profit)"] })),
    
    [REPORT_TYPES.FINANCIAL_PERCENTAGE_OF_MARGIN]: () => 
      data.map(item => ({ company_name: item?.["Margin Badge"], count: item?.["% of Loads"] })),

    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS]: () => 
      data.map(item => ({ company_name: item?.["Customer"], count: item?.Profit })),

    [REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION]: () => 
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.Revenue })),

    [REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS]: () =>
      data.map(item => ({ company_name: item?.["Driver Name"], count: item?.["Net Pay"] })),
    
    [REPORT_TYPES.FINANCIAL_REVENUE_BY_CUSTOMER]: () =>
      data.map(item => ({ company_name: item?.["Customer"], count: item?.Revenue })),

    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS]: () =>
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.Profit })),

    // Operation
    [REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER]: () =>
      data.map(item => ({ company_name: item?.["Driver"], count: item?.["Average number of Moves"] })),
    
    [REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER]: () =>
      data.map(item => ({ company_name: item?.["Driver"], count: item?.["Moves Completed"] })),
    
    [REPORT_TYPES.OPERATION_LOADS_COMPLETED_BY_WEEK]: () =>
      data.map(item => ({ company_name: item?.["Day of Week"], count: item?.["Loads Completed"] })),
    
    [REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE]: () =>
      data.map(item => ({ company_name: item?.["Employee"], count: item?.["Number of Dispatches"] })),
    
    [REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER]: () =>
      data.map(item => ({ name: item?.["Driver"], ...item })),

    // Customer Service
    [REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED]: () =>
      data.map(item => ({ company_name: item?.name, count: item?.loads })),

    [REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY]: () =>
      data.map(item => ({ company_name: item?.day, count: item?.loads, ...item })),

    // Empployee
    [REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED]: () =>
      data.map(item => ({ company_name: item?.["Employee Name"], count: item?.["Count of Approved Line Items"] })),

    [REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED]: () =>
      data.map(item => ({ company_name: item?.["Employee Name"], count: item?.["Count of Settlements Approved"] })),

    [REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE]: () =>
      data.map(item => ({ name: item?.name, count: parseFloat(item?.percentage || 0) })),  

    // Equipment
    [REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE]: () =>
      data.map(item => ({ company_name: item?.["Chassis #"], count: item?.["Count of Completed Moves"] })),

    [REPORT_TYPES.EQUIPMENT_TRUCK_USAGE]: () =>
      data.map(item => ({ company_name: `${item?.["Driver Name"]} ( ${item?.["Truck #"]} )`, count: item?.["Count of Completed Moves"], truckNo: item?.["Truck #"], driverName: item?.["Driver Name"] })),

    [REPORT_TYPES.EQUIPMENT_TRAILER_USAGE]: () =>
      data.map(item => ({ company_name: item?.["Trailer #"], count: item?.["Count of Completed Moves"] })),
    
    [REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Chassis #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),

    [REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Truck #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),

    [REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Trailer #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),
    [REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE]: () => 
      data.map(item => ({ ...item, name: item?.name, count: Math.round(item?.percentage) })),

    [REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE]: () => 
      data.map(item => ({ ...item, name: item?.name, count: Math.round(item?.percentage) })),
};

  return transformers[reportType] ? transformers[reportType]() : data;
};

export function checkDateRange(startDate, endDate, isDefultLabel = 'Custom Range') {
  const start = moment(startDate);
  const end = moment(endDate);
  const userTimeZone = getTimeZone();
  const dateRange = DATE_RANGE() || {}
  for (const [rangeName, [rangeStart, rangeEnd]] of Object.entries(dateRange)) {
    if (moment(start).tz(userTimeZone).format("DD/MM/YYYY") === moment(rangeStart).format("DD/MM/YYYY") && moment(end).tz(userTimeZone).format("DD/MM/YYYY") === moment(rangeEnd).format("DD/MM/YYYY")) {
      return rangeName;
    }
  }
  return isDefultLabel;
}

export const downloadCsvFromBuffer = (csvBuffer = '', fileName = 'fileName') => {
  if (!csvBuffer) return;

  try {
    // Convert the buffer to a Blob
    const csvBlob = new Blob([csvBuffer], { type: 'text/csv' });

    // Create a URL for the Blob and set up a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(csvBlob);
    link.download = `${fileName}.csv`;

    // Append to body, trigger download, and remove link immediately after
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL to free up memory
    setTimeout(() => URL.revokeObjectURL(link.href), 100);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
};

export const getDataForChartFromDB = async (payload, reportType) => {
  let responseData = {}
  const timeZone = getTimeZone();

  payload.fromDate = moment(payload.fromDate).tz(timeZone).format('MM/DD/YYYY')
  payload.toDate = moment(payload.toDate).tz(timeZone).format('MM/DD/YYYY')
  
  if (ADMIN_CHARTS?.includes(reportType)) { 
    responseData = await getAdminChartCounts(payload)
  }

  if (FINANCIALS_CHARTS?.includes(reportType)) {
    responseData = await getFinancialsChartCounts(payload)
  }

  if (OPERATIONS_CHARTS?.includes(reportType)) {
    responseData = await getOperatinsChartCounts(payload)
  }

  if (CUSTOMER_SERVICE_CHARTS?.includes(reportType)) {
    responseData = await getCustomerServiceChartCounts(payload)
  }

  if (SYSTEM_CHARTS?.includes(reportType)) {
    responseData = await getSystemChartCounts(payload)
  }

  if (BROKER_CHARTS?.includes(reportType)) {
    responseData = await getBrokerChartCounts(payload)
  }
  
  if (EMPLOYEE_CHARTS?.includes(reportType)) {
    responseData = await getEmployeeChartCounts(payload)
  }

  if (CUSTOMER_CHARTS?.includes(reportType)) {
    responseData = await getCustomerChartCounts(payload)
  }

  if (EQUIPMENT_CHARTS?.includes(reportType)) {
    responseData = await getEquipmentChartCounts(payload)
  }
  if (TRACK_CHARTS?.includes(reportType)) {
    responseData = await getTrackChartCounts(payload)
  }

  if (responseData && !payload?.isDownload && Object.keys(responseData)?.length > 0) {
    responseData.reportData = convertDataForChart(responseData?.reportData, reportType, payload?.isTableData)
    if(!payload?.isTableData) responseData = {
      ...responseData,
      reportData: responseData?.reportData?.filter(item => item && ( !_.isNumber(item?.count || 0) || item?.count > 0 ))
  }
  }
  return responseData
}

export const typeOfLoadDataMapper = (data, payloadObjKey1, payloadObjKey2, responseObjKey3, responseObjKey4 = "count") => {
  const transformedData = []
  data.forEach(item => {
    const transformedObj = {}
    let type;
    switch (item[payloadObjKey1]) {
      case 'IMPORT':
        type = 'Import';
        break;
      case 'EXPORT':
        type = 'Export';
        break;
      case 'ROAD':
        type = 'Road';
        break;
      case 'BILL_ONLY':
        type = 'Bill';
        break;
      default:
        type = item.type;
    }
    transformedObj[responseObjKey3] = type
    transformedObj[responseObjKey4] = item[payloadObjKey2]
    transformedData?.push(transformedObj);
  })
  return transformedData
}


export const getRowsOfIndexDB = (inputObj, regex) => {
  const financialKeys = [];
  for (const key in inputObj) {
    if (key?.startsWith(regex)) {
      financialKeys.push(key);
    }
  }

  return financialKeys;
}

export const formatNumber = (number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getDataKeysForStackedBarChart = (data, keysForStackedBarChart) => {
  if (!data || !Array.isArray(data) || data.length === 0 || !data[0]) {
      return [];
  }
  const keys = Object.keys(data[0]);
  return keys.filter(key => keysForStackedBarChart.includes(key));
};

export const formatTimeZoneString = (str) => {
  return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}


export const fixedNumer = (number = 0) => {
  let average = true
  if (number < 1000) {
    average = false
  }
  return numbro(number).format({
    average: average,
    mantissa: 2,
    optionalMantissa: false
  })?.replace('k', 'K').replace('m', 'M').replace('b', 'B').replace('t', 'T')
}