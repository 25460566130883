import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import $ from "jquery";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import confirm from "../../../Components/Common/ConfirmAert";
import CustomCreatableSelect from "../../../Components/Common/CustomSelect/creatable";
import {
  IconCheck,
  IconInfo,
  IconInfoDarkI,
  IconTimes,
  IconTrash,
  IconUpload
} from "../../../Components/Common/Icons";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import { convertTMSStatusName } from "../../../Components/Common/functions";
import FullScreenUploader from "../../../Components/FullScreenUploader";
import { smallSelectStyle } from "../../../assets/js/select-style";
import configuration from "../../../config";
import {
  currencies,
  customerSubType,
  differnceFinder,
  getAddressTerminology,
  getStorage,
  isGlobalizationEnabled,
  toastr
} from "../../../services";
import { DateTimeFormatUtils, amplitudeTrack, checkCustomerProfilePermission, checkIfEnableDrawStartingPoint, checkUserPermisison, isAccountServiceEnabled, isEmbeddedEmailEnabled, isEurope, isManageFleetOrOutSource, isMultiQBEnabled, isNewBillingEnabled, isNewInvoice, isShiposCarrier, showForTerminal } from "../../../services/Common.services";
import { validateEmail } from "../../../utils";
import CompanyDomainModal from "../../tms/Customer/CompanyDomainModal";
import { createFullAddressFromStreet } from "../AddressComponent/helpers";
import { getCustomers, getTaxCodes } from "../Dispatcher/actionCreators";
import UploadedDocuments from "../Load/DocumentList";
import { directFactoryFlags, loadFlags } from "../Load/utility";
import { getTimeZone } from "../NewDispatcher/constants";
import { checkBillingConfig } from "../carrier/AccountReceivable/utils";
import CustomerAccessManagement from "../carrier/CustomerAccessManagement";
import { DEFAULT_TERMINAL_TERMS, LOAD_CHARGE_COMBINATION, PAYMENT_TERMS_METHOD, PAYMENT_TERMS_OPTIONS, PAYMENT_TERMS_OPTIONS_TERMINAL } from "../constant";
import {
  ButtonComponent,
  DateTimeField,
  InputField,
  SelectField
} from "../formComponent";
import {
  deleteCustomerDoc,
  getDocumentType,
  getFleetCustomers,
  removeFleetFromCustomer,
} from "../services";
import SearchPlaces from "./../AddressComponent/SearchPlaces";
import AddTaxComponent from "./AddTaxComponent";
import GeofenceTab from "./Tabs/Geofence";
import NewGeofenceTab from "./Tabs/NewGeofence";
import * as actionCreateors from "./actionCreators";
import "./style.css";
import { validateCustomerAddress } from "./utility";


mapboxgl.accessToken = configuration.map_box_api_key;


class AddCustomerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fleetCustomerdata: [],
      showFileUploadModal: false,
      features: [],
      selectedDoc: "",
      companyID: 0,
      progress: 0,
      qbRoute: "",
      isConnected: false,
      terminals: [],
      fileType: null,
      terminal: "",
      TerminalRequired:false,
      tab: 1,
      documentFile: null,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      type: "",
      address: "",
      isLoading: false,
      documents: [],
      allCustomerTags: [],
      allCustomerDocTypes: [],
      allEmail: [],
      email: '',
      emailList: [],
      inputValueData: '',
      errorInfo: false,
      showModalInfo: false,
      formattedAddress1: props.Selected_customer?.address1 ? props.Selected_customer?.address1 : "",
      canEditLoad: props.Selected_customer?.canEditLoad
        ? props.Selected_customer?.canEditLoad
        : "",
      isTIROptional: props.Selected_customer?.isTIROptional
        ? props.Selected_customer?.isTIROptional
        : "",
      payType: props.Selected_customer?.payType
        ? props.Selected_customer?.payType
        : "",
      fleetCustomer: props.Selected_customer?.fleetCustomer
        ? props.Selected_customer?.payType
        : "",
        countryCode: props.Selected_customer?.countryCode && props.countries ? props?.countries.find(country => country.value === props.Selected_customer?.countryCode) : null,
      city: props.Selected_customer?.city ? props.Selected_customer?.city : "",
      state: props.Selected_customer?.state ? props.Selected_customer?.state : "",
      country: props.Selected_customer?.country ? props.Selected_customer?.country : "",
      credit_limit: props.Selected_customer?.credit_limit
        ? props.Selected_customer?.credit_limit
        : "",
      balance: props.Selected_customer?.balance
        ? props.Selected_customer?.balance
        : "",
      notificationEmails: props.Selected_customer?.notificationEmails
        ? props.Selected_customer?.notificationEmails.split(",")
        : [],
        extraEmail: props.Selected_customer?.extraEmail
        ? props.Selected_customer?.extraEmail.split(",")
        : [],
      customerType: props.Selected_customer?.customerType
        ? props.Selected_customer?.customerType
        : (props.customerProfileCustomersPermission && props.customerProfileShipperPermission && 
        props.customerProfileConsigneePermission && props.customerProfileChassisPermission) ?
        ["ALL"] : [],
      customerTags: props.Selected_customer?.customerTags
        ? props.Selected_customer?.customerTags
        : [],
      requiredDocList: props.Selected_customer?.requiredDocList
        ? props.Selected_customer?.requiredDocList
        : [],
      notes:
        props.Selected_customer && props.Selected_customer?.notes
          ? props.Selected_customer?.notes
          : "",
      lng:
        props &&
        props.Selected_customer &&
        props.Selected_customer?.geofence &&
        props.Selected_customer?.geofence.lng
          ? props.Selected_customer?.geofence.lng
          : -74.0029393,
      lat:
        props &&
        props.Selected_customer &&
        props.Selected_customer?.geofence &&
        props.Selected_customer?.geofence.lat
          ? props.Selected_customer?.geofence.lat
          : 40.717718,
      address_lng:
          props &&
          props.Selected_customer &&
          props.Selected_customer?.lng
            ? props.Selected_customer?.lng
            : "",
      address_lat:
          props &&
          props.Selected_customer &&
          props.Selected_customer?.lat
            ? props.Selected_customer?.lat
            : "",
      zoom:
        props &&
        props.Selected_customer &&
        props.Selected_customer?.geofence &&
        props.Selected_customer?.geofence.zoom
          ? props.Selected_customer?.geofence.zoom
          : 15.91,
      polygonData:
        props &&
        props.Selected_customer &&
        props.Selected_customer?.geofence &&
        props.Selected_customer?.geofence.polygonData
          ? props.Selected_customer?.geofence.polygonData
          : [],

      startingPointLatLong:
        props &&
        props.Selected_customer &&
        props.Selected_customer?.geofence &&
        props.Selected_customer?.geofence.startPoint
          ? props.Selected_customer?.geofence.startPoint
          : [],

      audits: [],
      newTerminal: props.Selected_customer?.newTerminal || [],
      CustID: null,
      portUsername: props.Selected_customer?.portUsername
        ? props.Selected_customer?.portUsername
        : "",
      extraSubCustomer: _.get(
        props,
        "Selected_customer.extraSubCustomer",
        []
      ).map((d) => ({ label: d.company_name, value: d._id })),
      salesAgents: _.get(props, "Selected_customer.salesAgents", []).map(
        (d) => ({ label: d?.name, value: d?._id })
      ),
      officeHoursStart: props.Selected_customer?.officeHoursStart
        ? props.Selected_customer?.officeHoursStart
        : "",
      officeHoursEnd: props.Selected_customer?.officeHoursEnd
        ? props.Selected_customer?.officeHoursEnd
        : "",
      currency: props.Selected_customer?.currency
        ? props.Selected_customer?.currency
        : "",
      defaultSubCustomer: [],
      defaultSalesAgents: [],
      preferredCurrency: props.Selected_customer?.preferredCurrency?._id,
      
      doc_all:props.Selected_customer?.doc_all
        ? props.Selected_customer?.doc_all
        : false,        
      doc_tir: props.Selected_customer?.doc_tir
        ? props.Selected_customer?.doc_tir
        : false,
      doc_pod: props.Selected_customer?.doc_pod
        ? props.Selected_customer?.doc_pod
        : false,
      doc_none: props.Selected_customer?.doc_none
        ? props.Selected_customer?.doc_none
        : false,
      isOnlyPopup: false,
      isSaving: true,
      csvPreviewUrl: "",
      xlsPreviewUrl: "",
      address1: props.Selected_customer?.address1 ??  "",
      customerSubType:props.Selected_customer.customerSubType ? props.Selected_customer.customerSubType:'',
      companyField: props.Selected_customer.companyField ? props.Selected_customer.companyField : '',
      showFullDropScreen:false,
      invoiceCurrencyWithBranch: [
        // {
        //   terminal: '',
        //   value: {}
        // }
      ],
      invoiceCurrencyWithCarrier: null,
      taxType: props.Selected_customer?.taxType
      ? props.Selected_customer?.taxType
      : "",
      taxRate: props.Selected_customer?.taxRate
      ? props.Selected_customer?.taxRate
      : "",
      qbSalesTaxId: props.Selected_customer?.qbSalesTaxId
      ? props.Selected_customer?.qbSalesTaxId
      : "",
      masterTaxAll: props.Selected_customer?.masterTaxAll
      ? props.Selected_customer?.masterTaxAll
      : "",
      taxOptions: [],
      userSettings: null,
      qbTaxOptions: [],
      qbTaxSelected: null,
      addTax: false,
      taxInputValue: "",
      currentMode:null,
      registrationNumber: props.Selected_customer?.registrationNumber || "",
      registrationCountry: props.Selected_customer?.registrationCountry ? props?.countries?.find(country => country.value === props.Selected_customer?.registrationCountry) : null,
      isEditView: props?.Selected_customer?.masterTaxAll ? true : false,
      shareLoadNotes: props?.Selected_customer?.shareLoadNotes || isShiposCarrier(),
      shareBillingNotes: props?.Selected_customer?.shareBillingNotes || isShiposCarrier(),
      isConnectEnabled: props?.isConnectEnabled || false,
      loadChargeCombination: props?.Selected_customer?.customerCombinationMethod ?? 'MANUAL',
      isSeparateInvoicePerTerminal: props?.Selected_customer?.isSeparateInvoicePerTerminal ?? true, 
      paymentTermsMethod: props?.Selected_customer?.paymentTermsMethod ?? 'day',
      terminalSpecificPaymentTerms : [],
      defaultPaymentTerms : { paymentTermsMethod : 'day'},
      isShowAddressModal: false,
      isShowAddressSuggestions: true,
      externalSystemID: props?.Selected_customer?.externalSystemID || "",
      disabledExternalSystemID : props?.Selected_customer?.externalSystemID ? true : false,
      organizationDomain: props?.Selected_customer?.organizationDomain || "",
      accountID:props.Selected_customer?.accountingInfo?.accountID || (this.props.accountingServiceInfo?.companyFiles?.length >=1 ? this.props.accountingServiceInfo?.companyFiles[0]?.accountID : ""),
      domainNameErr: false,
      usedOrgName:"",
      countriesWithAddressErrorException: {
        'HT': [
            'zip_code', // Haiti does not have zipcodes
        ]
      },
      accountHold:props?.Selected_customer?.account_hold || false,
      countryName: "",
    };
    const invoiceCurrencyWithCarrier = _.find(this.props.preferredCurrencies, {
      value: props.Selected_customer?.invoiceCurrencyWithCarrier?._id ?? props.Selected_customer?.invoiceCurrencyWithCarrier
    });
    if(invoiceCurrencyWithCarrier){
      this.state.invoiceCurrencyWithCarrier = invoiceCurrencyWithCarrier
    }

    if(props.Selected_customer?.defaultPaymentTerms){
      this.state.defaultPaymentTerms =  props.Selected_customer?.defaultPaymentTerms
    }
    const invoiceCurrencyWithBranch = props.Selected_customer?.invoiceCurrencyWithBranch?.map((invoice) => {
      return {
        terminal: _.find(this.state.newTerminal, {
          value: invoice?.branch?._id ?? invoice?.branch
        }),
        data: _.find(this.props.preferredCurrencies, {
          value: invoice?.currency?._id ?? invoice?.currency
        })
      }
    })
    const terminalSpecificPaymentTerms = props.Selected_customer?.terminalSpecificPaymentTerms?.map((paymentTerms)=>{
      return{
        terminal: _.find(this.state.newTerminal, {
          value: paymentTerms?.branch?._id ?? paymentTerms?.branch
        }),
        data: _.find(PAYMENT_TERMS_OPTIONS_TERMINAL, {
          value: paymentTerms?.paymentTermsMethod
        }),
        days: paymentTerms?.days
      }
    })
    if(terminalSpecificPaymentTerms){
      this.state.terminalSpecificPaymentTerms = terminalSpecificPaymentTerms
    }
    if(invoiceCurrencyWithBranch){
      this.state.invoiceCurrencyWithBranch = invoiceCurrencyWithBranch
    }
    this.loggedInUser = null;
    this.tempEmail = null;
    this.startingPointMarker = null
    this.updateData = this.updateData.bind(this);
    this.getCustomerOptions = this.getCustomerOptions.bind(this);
    this.listAllFleetManagers = this.listAllFleetManagers.bind(this);
    this.handleNotificationEmailsChange =
      this.handleNotificationEmailsChange.bind(this);
    this.handleExtraEmailsChange =
      this.handleExtraEmailsChange.bind(this);
    this.SingleValue = this.SingleValue.bind(this);
    this.isShiposCarrier = isShiposCarrier()
    this.updateStates = this.updateStates.bind(this);
    this.updateEmailConfiguration = this.updateEmailConfiguration.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.isManageFleetOrOutSource = isManageFleetOrOutSource();
    this.handleChangeOfDomain = debounce(1000, this.checkCompanyDomain);
  }


  isAddressFieldRequired = (field) => {
    return !(this.state.countriesWithAddressErrorException?.[this.state.country_short]?.includes(field) ?? false);
  }

  


  isAddressFieldRequired = (field) => {
    return !(this.state.countriesWithAddressErrorException?.[this.state.country_short]?.includes(field) ?? false);
  }

  

  userBasicSettings = JSON.parse(localStorage.getItem('userBasicSettings'));
  isVAT = this.userBasicSettings?.isVAT || false 

  deleteEquipmentDoc = (params) => {
    deleteCustomerDoc(params)
      .then((response) => {
        const documents =
          response &&
          response.data &&
          response.data.data &&
          response.data.data.documents;

        this.props.added();
        this.props.updateState({ documents });
        this.setState({ imagePreviewUrl: "", selectedDoc: null });
        toastr.show("Successfully deleted", "success");
      })
      .catch((error) => console.log(error));
  };

  requirePassword = (value) => {
    if (!this.props.initialValues._id) {
      if (!value) {
        return "Please enter password";
      } else if (value && value.length < 5) {
        return "Password length should be greater than 5  character long*";
      } else if (value && value.length > 50) {
        return "Password length should be less than 50 character long*";
      } else {
        return undefined;
      }
    }
  };

  requireEmail = (value) => {
      if (!value || value == '') {
        return "Please enter email";
      } else {
        return undefined;
      }
  };

  requireTerminal = (value) =>{
    if (!value || value.length == 0) {
      return "Terminal is required*";
    } else {
      return undefined;
    }
  }
  
  onEmailInputChange = (textInput, { action }) => {
    this.setState({tempEmail: textInput})
    if (action === "input-change") {
      this.tempEmail = textInput
    }
    if (action === "set-value" || action === "menu-close") {
      this.tempEmail = null;
    }
    if (this.tempEmail !== null && action === "input-blur") {
      const emailList = this.state.emailList
      const findDuplicateTempEmail = emailList.find((email) => email.value === this.tempEmail )
      
      if(!findDuplicateTempEmail) {
        this.addEmails(this.tempEmail);
      }
      this.tempEmail = null;
    }
};

  address = (value) => {
    if (!value) {
      return "Address is not allowed to be empty";
    } else {
      return undefined;
    }
  };

  handleNotificationEmailsChange(notificationEmails) {
    notificationEmails = notificationEmails.filter((d) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
    );
    this.setState({ notificationEmails });
  }

  handleExtraEmailsChange(extraEmail) {
    extraEmail = extraEmail.filter((d) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
    );
    this.setState({ extraEmail });
  }

  removeQuery = (queryNames) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    queryNames.forEach((q) => delete location.query[q]);
    browserHistory.push(location);
  };

  onSelect = (place) => {
    let lng = place.geometry.location.lng;
    let lat = place.geometry.location.lat;
    this.props.change("address", place.formatted_address);
    this.props.change("lat", lat);
    this.props.change("lng", lng);
    if (place.address_components.length) {
      let address1 = "";
      place.address_components.forEach((data) => {
        // if (data.types.indexOf("street_number") != "-1") {
        //   address1 = `${data.short_name}`;
        // }
        // if (data.types.indexOf("route") != "-1") {
        //   let sortAddress = `${address1} ${data.short_name}`;
        //   this.props.change("address", sortAddress);
        // }
        
        if (data.types.indexOf("locality") != "-1") {
          this.props.change("city", data.long_name);
        }
        if (data.types.indexOf("administrative_area_level_1") != "-1") {
          this.props.change("state", data.short_name);
        }

        if (data.types.indexOf("country") != "-1") {
          this.props.change("country", data.short_name);
        }

        if (data.types.indexOf("postal_code") != "-1") {
          this.props.change("zip_code", data.long_name);
        }
      });
    }
    this.setState({ features: [] });
  };

  removeFleetFromCustomer(_id) {
    removeFleetFromCustomer({ _id }).then((res) => {
      if (res.statusCode === 200) {
        toastr.show("Removed!", "success");
      }
    });
  }
  updateDocument = (e) => {
    this.setState({ fileType: e.target.value });
  };

  // getGooglePlaceData = async () => {
  //   const value = this.state.address || "";
  //   if (value !== "") {
  //     const response = await getGoogleAutoPlaces(value);
  //     if(response.data && response.data.results.length > 0) {
  //       this.setState({ features: response.data.results });
  //     }
  //   }
  // };

  // getPlace = (e) => {
  //   const value = e.target.value;

  //   if (value) {
  //     this.getGooglePlaceData();
  //     this.setState({ address: value });
  //   } else {
  //     this.setState({ address: "", features: [] });
  //   }
  // };

  difference(origObj, newObj) {
    function changes(newObj, origObj) {
      let arrayIndexCounter = 0;
      return _.transform(newObj, function (result, value, key) {
        if (!_.isEqual(value, origObj[key])) {
          let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key;
          result[resultKey] =
            _.isObject(value) && _.isObject(origObj[key])
              ? changes(value, origObj[key])
              : value;
        }
      });
    }
    return changes(newObj, origObj);
  }

  componentDidMount() {
    let self = this;
    if (self.props.tab === 1 || self.props.tab === 7) {
      $("#formContainer").show();
    } else {
      $("#formContainer").hide();
    }


    // Get address exceptions from system config
    self.props.actions.getAddressExceptionsFromSystemConfig().then(res => {
      self.setState({countriesWithAddressErrorException: res}) 
    })

    const selectedCustomer = self.props.Selected_customer || {};

    if (selectedCustomer._id) {
      self.props.actions
        .getTMSCustomerAudit(selectedCustomer._id, { isGenerateSignUrl: true })
        .then((res) => {
          const audits = res || [];
          const newAudit = [];
          if (audits && audits.length) {
            for (let i = 0; i < audits.length; i++) {
              if (i === 0) {
                newAudit.push(audits[i]);
              } else {
                const diff = this.difference(
                  audits[i - 1].data,
                  audits[i].data
                );
                audits[i].data = diff;
              }
            }
          }

          self.setState({ audits });
        });
      if (selectedCustomer.billingEmail) {
        const billingEmail = selectedCustomer.billingEmail.includes(",")
          ? selectedCustomer.billingEmail.split(",")
          : [selectedCustomer.billingEmail];
        const allEmail = billingEmail
          .filter((email) => email)
          .map((email) => ({ label: email, value: email }));
        this.setState({ allEmail, emailList: allEmail });
      }
    }

    self.props.actions.getCustomerTags().then((tags) => {
      let allTags = tags.map((tag) => ({ label: tag.tag, value: tag.tag }));
      self.setState({ allCustomerTags: allTags });
    });

    getDocumentType().then((tags) => {
      let allTypes = tags.map((tag) => ({ label: tag.label, value: tag.value }));
      self.setState({ allCustomerDocTypes: allTypes });
    });
    if (this.props && this.props.addType) {
      this.setState({ customerType: [`${this.props.addType}`] });
    }

    this.getCustomerOptions();
    this.listAllFleetManagers();
    this.updateState();
    this.setState({showFullDropScreen:true})

    if(getStorage("userBasicSettings")){
      const settings = JSON.parse(getStorage("userBasicSettings"));
      if(settings?.isCalculateTax){
      
        this.setState({userSettings: settings})
        
        if(!this.props?.qbTaxOptions || this.props?.qbTaxOptions?.length === 0){
          this.fetchTaxCodes()
        } else {
          let options =this.props?.qbTaxOptions
          this.mapOptionsForTaxCodes(options)
        }
      }
    }
  }

  checkCompanyDomain = (organizationDomain) => {
    const validDomainRegex = /^([a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+)$/;
    this.setState({ organizationDomain: organizationDomain, isUserNameValid:null })
    if(!organizationDomain?.trim()?.length){
      this.setState({
        domainNameErr:null
      })
      return
    }
    if (!validDomainRegex.test(organizationDomain)) {
      this.setState({ domainNameErr: true });
      return
    } else {
      this.setState({ domainNameErr: false });
    }
  }

  mapOptionsForTaxCodes(options) {
    const taxSelected = options?.find((tax)=> {
      return tax.value?.taxType === this.state.taxType &&
        parseFloat(tax.value.taxRate) === parseFloat(this.state.taxRate)
    })
    let selectedOption
    if(this.state.taxType && !isNaN(this.state.taxRate)) {
      selectedOption = {
        label: `${this.state?.taxType} @ ${this.state?.taxRate} %`,
        value: {
          taxType: this.state.taxType,
          taxRate: this.state.taxRate,
          qbSalesTaxId: this.state.qbSalesTaxId,
          _id: this.state.masterTaxAll?._id ?? this.state.masterTaxAll,
          isDeleted: this.state.masterTaxAll?.isDeleted ?? false,
        },
      }
      if(!taxSelected) {
        options =  [
            selectedOption,
            ...options
          ]
      }
    }

    this.setState({
      qbTaxOptions: options,
      qbTaxSelected: taxSelected ? taxSelected : selectedOption
    })
  }

  fetchTaxCodes() {
    getTaxCodes().then((codes) => {
      let options = codes?.map((code ) => {
        return {
          label: `${code?.taxType} @ ${(code?.taxRate ?? 0)?.print()} %`,
          value: code,
        }
      })
      this.mapOptionsForTaxCodes(options);
    })
  }

  _loadTaxOption(
    inputValue,
    callback
  ) {
    this.setState({
      taxInputValue: inputValue,
    });
    getTaxCodes({
      ...(inputValue && {nameSearch: inputValue})
    }).then((codes) => {
      const options = codes?.map((code ) => {
        return {
          label: `${code?.taxType} @ ${(code?.taxRate ?? 0)?.print()} %`,
          value: code,
        }
      })
      callback(options)
    })
  }
  loadTaxOption = _.debounce(this._loadTaxOption, 500)

  updateState() {
    const { documents } = this.props;
    this.setState({ documents });
  }
  componentWillUnmount() {
    this.setState({showFullDropScreen:false})
  }
  componentDidUpdate(prevProps, prevState) {
    const customerType =
      prevProps.Selected_customer && prevProps.Selected_customer.customerType
        ? prevProps.Selected_customer.customerType
        : [];
    if (
      this.props.Selected_customer?.customerType &&
      !_.isEqual(customerType, this.props.Selected_customer?.customerType)
    ) {
      this.setState({
        customerType: this.props.Selected_customer?.customerType,
      });
    }

    if (!_.isEqual(this.state.documents, this.props.documents)) {
      this.updateState();
    }

    const customerTags =
      prevProps.Selected_customer && prevProps.Selected_customer.customerTags
        ? prevProps.Selected_customer.customerTags
        : [];
    const requiredDocList =
      prevProps.Selected_customer && prevProps.Selected_customer.requiredDocList
        ? prevProps.Selected_customer.requiredDocList
        : [];
    const currentDocTypes =
      this.props.Selected_customer && this.props.Selected_customer?.requiredDocList
        ? this.props.Selected_customer?.requiredDocList
        : [];
    const currentProps =
      this.props.Selected_customer && this.props.Selected_customer?.customerTags
        ? this.props.Selected_customer?.customerTags
        : [];
    if (currentProps && !_.isEqual(customerTags, currentProps)) {
      this.setState({ customerTags: currentProps });
    }
    if(currentDocTypes && !_.isEqual(requiredDocList, currentDocTypes)){
      this.setState({ requiredDocList: currentDocTypes });
    }

    const polygonData =
      prevProps.Selected_customer &&
      prevProps.Selected_customer.geofence &&
      prevProps.Selected_customer.geofence.polygonData
        ? prevProps.Selected_customer.geofence.polygonData
        : [];
    if (
      this.props.Selected_customer &&
      this.props.Selected_customer?.geofence &&
      this.props.Selected_customer?.geofence.polygonData &&
      !_.isEqual(polygonData, this.props.Selected_customer?.geofence.polygonData)
    ) {
      this.setState({
        polygonData: this.props.Selected_customer?.geofence.polygonData,
      });
    }
    if(this.props?.Selected_customer?.account_hold !== prevProps?.Selected_customer?.account_hold){
      this.setState({accountHold:this.props?.Selected_customer?.account_hold})
    }
  }

  updateData(data){
    this.setState(data)
  }

  handleInputChange = (event) => {
    this.setState({
      inputValueData: event.target.value,
      errorInfo: false,
    });

    const { inputValueData } = this.state;
    if (inputValueData.includes('random')) {
      this.setState({ errorInfo: true });
    } else {
      const validDomainRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+$/;
      if (!validDomainRegex.test(inputValueData)) {
        this.setState({ errorInfo: true });
      } else {
        this.setState({ errorInfo: false });
      }
    }
  };

  openModalInfo = () => {
    this.setState({ showModalInfo: true });
  };

  closeModalInfo = () => {
    this.setState({ showModalInfo: false });
  };

  componentWillMount = async () => {
    this.loggedInUser = JSON.parse(getStorage("loggedInUser"));
    this.fetchData();
  };
  fetchData = () => {
    getFleetCustomers()
      .then((data) => {
        if (data.length !== 0) {
          let id = this.props.Selected_customer?.fleetCustomer;
          this.setState({
            fleetCustomer: data.filter((d) => d.value === id),
            fleetCustomerdata: data,
          });
        }
      })
      .catch((error) => {});
  };
  componentWillReceiveProps(props) {
    if (props.tab === 1 || props.tab === 7) {
      $("#formContainer").show();
    } else {
      $("#formContainer").hide();
    }
    // if (props.tab === 3) {
    //   self.loadMap();
    // }
   
    if(props.Selected_customer?.credit_limit != this.state.credit_limit){
      this.setState({credit_limit : props.Selected_customer?.credit_limit})
    }
    if(props.Selected_customer?.balance != this.state.balance){
      this.setState({balance :  props.Selected_customer?.balance})
    }
    
  }

  uploadResult = (file, preview, type) => {
    if(file.type.match("image/png")|| file.type.match("image/jpg") ||file.type.match("image/jpeg")  || file.type.match("application.pdf") ||
    file.type.match("text/csv") || file.type.match("application/vnd.ms-excel") || file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")){
      this.setState({showFileUploadModal:true})
      if (file.type.indexOf("image") == -1) {
        type = file.type.split("/")[1];
      }
      setTimeout(() => {
        this.setState({ progress: 100, isSaving: false });
      }, 1000);
      this.setState(
        {
          documentFile: null,
          [`${type}PreviewUrl`]: null,
          pdfPreviewUrl: null,
          [type]: null,
        },
        () => {
          this.setState({
            documentFile: file,
            [`${type}PreviewUrl`]: preview,
            [type]: preview,
          });
        }
      );
      if (type == "pdf") {
        this.setState({ imagePreviewUrl: null, csvPreviewUrl: null, xlsPreviewUrl: null, });
        let base64result = preview.split(",")[1];
        var pdfData = atob(base64result);
        var loadingTask = pdfjsLib?.getDocument({ data: pdfData });
        loadingTask.promise.then(function (pdf) {
          // Fetch the first page.
          pdf.getPage(1).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport(scale);
            // Prepare canvas using PDF page dimensions.
            var canvas = document.getElementById("the-canvas");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            // Render PDF page into canvas context.
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext);
          });
        });
      } else if(type === "csv") {
        let csvPreviewUrl = {pre: preview, name: file.name};
        this.setState({csvPreviewUrl, xlsPreviewUrl: null, pdfPreviewUrl: null, imagePreviewUrl: null})
      } else if(type === "vnd.ms-excel" || type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        let xlsPreviewUrl = {pre: preview, name: file.name};
        this.setState({xlsPreviewUrl, csvPreviewUrl: null, pdfPreviewUrl: null, imagePreviewUrl: null})
      }
    } else {
      toastr.show("Please enter only PDF, CSV, XLS, XLSX and/or Image (JPG, PNG, JPEG ) file formats.", "error");
      return;
    }
  };

  previewImage = (data) => {
    if (!data.url) {
      toastr.show("There is no document.", "warning");
      return;
    }
    let extension;
    let fileName = data.url;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.url },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.url, pdfPreviewUrl: null });
      }
    }
  };

  downloadDoc(doc) {
    $.ajax({
      url: doc.url,
      xhrFields: {
        responseType: "blob",
      },
      success: (blob) => {
        const extension = doc.url.split(".").pop();
        if (extension != "pdf") {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          link.download = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.click();
        } else {
          var blob = new Blob([blob], { type: "application/pdf" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.download = fileName;
          link.click();
        }
      },
    });
  }

  setCustomerType(arr) {
    let currentUser = JSON.parse(getStorage("loggedInUser"));
    let {customerProfileCustomersPermission, customerProfileShipperPermission, customerProfileConsigneePermission, customerProfileChassisPermission} = checkCustomerProfilePermission(currentUser)
    let { customerType } = this.state;
    if (
      customerType.length == 1 &&
      customerType.indexOf("ALL") > -1 &&
      arr.indexOf("ALL") === -1
    ) {
      customerType = arr;
      this.setState({ customerType });
      return;
    }
    if (customerType.intersect(arr).length > 0) {
      customerType = customerType.subtract(arr);
    } else {
      customerType = customerType.insert(arr);
    }
    if (customerProfileCustomersPermission && customerProfileShipperPermission && customerProfileConsigneePermission && customerProfileChassisPermission && arr.indexOf("ALL") > -1) {
      customerType = ["ALL"];
    }
    if (customerProfileCustomersPermission && customerProfileShipperPermission && customerProfileConsigneePermission && customerProfileChassisPermission && 
      customerType.intersect([
        "caller",
        "shipper",
        "containerReturn",
        "consignee",
        "chassisTermination",
        "chassisPick",
      ]).length === 6
    ) {
      customerType = ["ALL"];
    }

    if (customerProfileCustomersPermission && customerProfileShipperPermission && customerProfileConsigneePermission && customerProfileChassisPermission && customerType.length === 0) {
      customerType = ["ALL"];
    }

    this.setState({ customerType });
  }

  save = () => {
    $("#profileButton").click();
  };

  getCustomerOptions = (search) => {
    if (!search) {
      return new Promise((resolve, reject) => {
        getCustomers(null, 10).then((options) => {
          const allDatas = options.filter(
            (op) => op.value !== _.get(this.props, "Selected_customer._id")
          );
          const subCustomers = [];

          allDatas.forEach((obj) => {
            subCustomers.push({
              value: obj._id,
              label: `${obj.company_name}, ${
                obj.address ? obj.address.address : ""
              }`,
              allInfos: obj,
            });
          });

          this.setState({ defaultSubCustomer: subCustomers });

          resolve(subCustomers);
        });
      });
    }

    return new Promise((resolve, reject) => {
      getCustomers(search).then((options) => {
        const result = options.filter(
          (op) => op.value !== _.get(this.props, "Selected_customer._id")
        );
        resolve(result);
      });
    });
  };

  listAllFleetManagers = (search) => {
    if (!search) {
      return new Promise((resolve, reject) => {
        this.props.actions
          .listAllFleetManagers({ salesAgent: true }, 10)
          .then((options) => {
            const salesAgents = [];
            let allDatas = options || [];

            allDatas.forEach((obj) => {
              salesAgents.push({
                value: obj._id,
                label: `${obj.name}`,
                allInfos: obj,
              });
            });

            this.setState({ defaultSalesAgents: salesAgents });

            resolve(salesAgents);
          });
      });
    }

    let searchData = {
      searchTerm: search,
      salesAgent: true,
    };
    return new Promise((resolve, reject) => {
      this.props.actions.listAllFleetManagers(searchData).then((options) => {
        resolve(options);
      });
    });
  };

  isDisabled(permission) {
    switch (permission) {
      case "credit-limit":
        if (this.loggedInUser && this.loggedInUser.role === "fleetmanager") {
          return !this.loggedInUser.permissions.includes(
            "customer_credit_limit"
          );
        }
        return false;
      case "payment-terms":
        if (this.loggedInUser && this.loggedInUser.role === "fleetmanager") {
          return !this.loggedInUser.permissions.includes(
            "customer_payment_terms"
          );
        }
        return false;

      default:
        return false;
    }
  }

  addCustomerTags(values) {
    const formData = new FormData();
    formData.append("tag", values);
    this.setState({ customerTags: [...this.state.customerTags, values] }, () =>
      console.log("customerTag :: ", this.state.customerTags)
    );
    this.setState({ requiredDocList: [...this.state.requiredDocList, values] }, () =>
      console.log("requiredDocList :: ", this.state.requiredDocList)
    );
    this.props.actions
      .addCustomerTags(formData)
      .then(() => {
        toastr.show("Customer Tags Add", "success");
      })
      .catch((err) => {});
  }

  addEmails(values) {
    const value = { label: values, value: values };
    this.setState({ emailList: [...this.state.emailList, value] });
  }

  getCustomerTags = async (strSearch) => {
    let freeze = true;
    return new Promise(async (res, err) => {
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer);
        let timer = setTimeout(async () => {
          freeze = false;
          const r = await this.props.actions.getCustomerTags({
            searchText: strSearch,
          });
          res(r.map((d) => ({ label: d.tag, value: d.tag })));
        }, 1000);
      });

      p.then(function (x) {
        res(x);
      });
    });
  };

  getCustomerDocTypes = async (strSearch) => {
    let freeze = true;
    return new Promise(async (res, err) => {
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer);
        let timer = setTimeout(async () => {
          freeze = false;
          const r = this.props.actions.getDocumentType().then((docs) => {
            this.setState({ DOC_OPTIONS: docs });
          });
          res(r.map((d) => ({ label: d.label, value: d.value })));
        }, 1000);
      });

      p.then(function (x) {
        res(x);
      });
    });
  };
  validateFormSubmit = () => {
    const { valid } = this.props;
    if (!valid) {
      toastr.show("Please enter all mandatory fields and try again!", "error");
    }
  };

  billingEmail = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)
      ? false
      : true;

  setFeatureValue = (key, val) => {
    this.setState({ [key]: val });
    if (key === "doc_all" && val) {
      this.setState({
        doc_all: true,
        doc_tir: false,
        doc_pod: false,
        doc_none: false,
      });
    } else if (key === "doc_all" && !val) {
      this.setState({ doc_all: false });
    } else if (key === "doc_none" && val) {
      this.setState({
        doc_all: false,
        doc_tir: false,
        doc_pod: false,
        doc_none: true,
      });
    } else if (key === "doc_none" && !val) {
      this.setState({ doc_none: false });
    } else if (key !== "doc_all" && key !== "doc_none") {
      this.setState({ doc_none: false, doc_all: false });
    }
  };

  handleShowFileUploadModal = () => {
    this.setState({
      showFileUploadModal: true,
      documentFile: null,
      progress: 0,
      fileType: null,
      isSaving: true
    });
  };
  deleteDocument = (id) => {
    confirm(
      "Remove Document?",
      "Are you sure you want to remove this document?",
      (confirm) => {
        if (confirm) {
          this.deleteEquipmentDoc({
            docId: id,
            _id: this.props.Selected_customer?._id,
          })
        }
      }
    );
  };
  handleChange = (address1) => {
    const trimedAddress = (address1 || '').replace(/,/g, '').trim() + ',';
    this.setState({ address1: trimedAddress });
  };
  handleCityChange = (city) => {
    const trimedCity = (city || '').replace(/,/g, '').trim() + ',';
    this.setState({ city: trimedCity });
  };
  handleStateChange = (state) => {
    const trimedState = (state || '').replace(/,/g, '').trim() + ',';
    this.setState({ state: trimedState });
  };
  handleCountryChange = (country) => {
    const trimedCountry = (country || '').replace(/,/g, '').trim() + ',';
    this.setState({ country: trimedCountry });
  };

  handleSelect = async (response) => {
    if (response && Object.keys(response).length > 0) {
      let place = _.cloneDeep(response);

      this.props.change("address", place.address);
      this.setState({address: place.address})
      
      if (place?.lat && place?.lng) {
        this.props.change("lat", place?.lat);
        this.props.change("lng", place?.lng);
        this.setState({lat: place.lat})
        this.setState({lng: place.lng})
        this.setState({address_lat: place.lat})
        this.setState({address_lng: place.lng})
      }
      
      if (place?.address1) {
        //need to check state
        this.setState({ address1: place?.customAddress || place?.address1 });
        this.props.change("address1", place?.customAddress || place?.address1);
        this.props.addressValidationSetState && this.props.addressValidationSetState()
      } else {
        this.setState({ address1: "" });
        this.props.change("address1", "");
        this.setState({ address: "" });
        this.props.change("address", "");
      }

      //Reset values for zip and state
      this.setState({
        zip_code: "",
        state: ""
      });
      this.props.change("zip_code", '');
      this.props.change("state", '');

      if (place?.city) {
        this.setState({ city: place?.city });
        this.props.change("city", place?.city);
      }
      if (place?.state) {
        this.setState({ state: place?.state });
        this.props.change("state", place?.state);
      }
      
      if (place?.country) {
        this.setState({ country: place?.country });
        this.setState({countryName: place?.countryName})
        this.props.change("country", place?.country);
      }

      if (place?.zip_code) {
        this.props.change("zip_code", place?.zip_code);
      }
    }
  };

  handleSelectCity = async (response) => {
    if(response && Object.keys(response)?.length > 0) {
      let place = _.cloneDeep(response);
      if (place.city) {
        this.props.change("city", place.city);
        this.setState({ city: place.city });
      }
    }
}
handleSelectState = async (response) => {
  if(response && Object.keys(response)?.length > 0) {
    let place = _.cloneDeep(response);
    if (place.state) {
      this.props.change("state", place.state);
      this.setState({ state: place.state });
    }
  }
}
handleSelectCountry = async (response) => {
  if (response && Object.keys(response)?.length > 0) {
    let place = _.cloneDeep(response);
    if (place.country) {
      this.props.change("country", place.country);
      this.setState({ country: place.country });
    }
  }
}

  isDuplicate = (arr) => {
    var valueArr = arr.map(function(item){ return item.label });

    var isDuplicate = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(item) != idx 
    });

    return isDuplicate;
  }

  findDuplicateEmails = (arr) => {
    const newSet = new Set();
    const filteredArr = arr.filter(el => {
      const duplicate = newSet.has(el.label);
      newSet.add(el.label);
      return !duplicate;
    });
    return filteredArr;
  };

  _handleKeyDown = (e) => {
    const emailList = this.state.emailList;
    const checkDuplicateEmail = emailList.some((el) => el.value === this.state.tempEmail);
    if (e.key === 'Tab') {
      if(checkDuplicateEmail) {
        e.preventDefault();
        toastr.show("Please check! You have added a duplicate email", "warning");
        return;
      } 
    }
  }
  handleTerminalChange = (newTerminal) => {      
    let data = Object.values(newTerminal)
    if(this.isManageFleetOrOutSource?.isOutSource){
      const isAllIncluded = data.find(val => val.value === 'ALL');
      let Terminal = this.props.Terminals?.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
      data = isAllIncluded ? Terminal.filter(ob => ob.value !== 'ALL') : data;
    }
    if (checkUserPermisison(['allow_user_to_assign_all_terminals'])) {
      const isAllIncluded = data.find(val => val.value === 'ALL');
      let Terminal = this.props.AllTerminals?.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
      data = isAllIncluded ? Terminal.filter(ob => ob.value !== 'ALL') : data;
    }

    const invoiceCurrencyWithBranch = 
      this.state.invoiceCurrencyWithBranch?.filter((currency)=> {
        const terminalExists = data?.find((terminal) => {
          return terminal?.value === currency?.terminal?.value
        })
        return terminalExists
    })
    const terminalSpecificPaymentTerms = this.state?.terminalSpecificPaymentTerms?.filter((paymentTerms)=>{
      const terminalExists = data?.find((terminal) =>{
          return terminal?.value === paymentTerms?.terminal?.value
      })
      return terminalExists
    })
    const addedTerminals = data.filter((data) => !this.state?.terminalSpecificPaymentTerms.some((paymentTerms) => paymentTerms?.terminal?.value === data?.value));
    const newTerminalSpecificPaymentTerms = []; 
    addedTerminals?.forEach((terminal) => {
      newTerminalSpecificPaymentTerms.push({
        data: DEFAULT_TERMINAL_TERMS,
        terminal: terminal,
      })
    })
    const addTerminalSpecificPaymentTerms = [
      ...terminalSpecificPaymentTerms,
      ...newTerminalSpecificPaymentTerms
    ]
    const defaultPaymentTerms ={
      "days" : 0,
      "paymentTermsMethod" : "day"
    }
    this.setState({ newTerminal:data, invoiceCurrencyWithBranch: invoiceCurrencyWithBranch, terminalSpecificPaymentTerms : addTerminalSpecificPaymentTerms, defaultPaymentTerms: defaultPaymentTerms })
  }
  handleTerminalCurrencyChange = (value, terminal, branchCurrency) => {
    let updatedCurrency = [
      ...this.state.invoiceCurrencyWithBranch
    ]
    if(branchCurrency){
      updatedCurrency = updatedCurrency?.map((currency) => {
        if(currency?.terminal?.label === terminal?.label) {
          return {
            ...currency,
            data: value,
          }
        }
        return {
          ...currency,
        }
      })
    } else {
      updatedCurrency.push({
        terminal: terminal,
        data: value,
      })
    }

    this.setState({ 
      invoiceCurrencyWithBranch: updatedCurrency,
    })
  }

  handleTerminalPaymentTermsChange = (value, terminal, paymentTerms) => {
    let updatedPaymentTerms = [
      ...this.state?.terminalSpecificPaymentTerms
    ]
    if (paymentTerms) {
      updatedPaymentTerms = updatedPaymentTerms?.map((payment) => {
        if (payment?.terminal?.value === terminal?.value) {
          if(value?.value === 'default') payment.days = 0
          return {
            ...payment,
            data: value,
          }
        }
        return {
          ...payment,
        }
      })
    }
    else {
      updatedPaymentTerms.push({
        terminal: terminal,
        data: value,
      })
    }
    this.setState({ terminalSpecificPaymentTerms: updatedPaymentTerms })
  }

  handleInputTerminalPaymentTerms = (value, terminal, paymentTerms) =>{
    let updatedPaymentTerms = [
      ...this.state?.terminalSpecificPaymentTerms
    ]
    if (paymentTerms) {
      updatedPaymentTerms = updatedPaymentTerms?.map((payment) => {
        if (payment?.terminal?.value === terminal?.value) {
          return {
            ...payment,
            days: Number(value),
          }
        }
        return {
          ...payment,
        }
      })
    }else if(!paymentTerms && value){
      updatedPaymentTerms.push({
        terminal,
        days: value,
      })
    }
    this.setState({ terminalSpecificPaymentTerms: updatedPaymentTerms })
  }

  updateStates = (stateName, value, configStateName) => {
    let oldArray = this.state[`${stateName}`].length > 0 && this.state[`${stateName}`].map((p) => p.value);
    let newArray = value.length > 0 && value.map((p) => p.value);
    let diffVal = _.difference(oldArray,newArray)
    if (diffVal.length > 0) {
      this.setState({
        [`${stateName}`]: this.state[`${configStateName}`]?.length > 0 ? this.state[`${configStateName}`]?.filter((item) => !diffVal.includes(item.status)) : []
      })
    }
      this.setState({
        [`${stateName}`]: value
      })
  }
  updateEmailConfiguration = (stateName,statusName,value) => {
    let val = value.map((item) => {return item.value});
    let index = this.state[`${stateName}`].findIndex((item) => item['status'] === statusName)
    if (index !== -1 ) {
      let edit = _.cloneDeep(this.state[`${stateName}`]);
      edit[index]['timeInterval'] = val;
      this.setState({
        [`${stateName}`] : edit
      })
    } else {
      let newAdd = _.cloneDeep(this.state[`${stateName}`])
      let add = { 'status':  `${statusName}`, 'timeInterval' : val }
      newAdd.push(add);
      this.setState({
        [`${stateName}`] : newAdd
      })
    }
  }
  renderTerminalCurrency() {
    return(
      <>
        <div className="hr-light mt-10 mb-20"></div>
        <div className="font-14 font-medium mb-15">Currency</div>
        <div className="form-row">
          {
            ["carrier", "fleetmanager"].includes(getStorage('currentUserRole')) &&
            <div className="col-lg-2">
              <label>Default Currency</label>
              <Select
                options={this.props.preferredCurrencies}
                styles={smallSelectStyle}
                defaultValue={this.state.invoiceCurrencyWithCarrier}
                onChange={(value) => {
                  this.setState({ invoiceCurrencyWithCarrier: value })
                }}
              />
            </div>
          }
        {
            this.state.newTerminal && this.state.newTerminal?.map((terminal) => {
              const branchCurrency = this.state.invoiceCurrencyWithBranch?.find((currency) => {
                return currency?.terminal?.value === terminal.value
              })
              return <div className="col-lg-2">
                <label>{terminal?.label}</label>
                <Select
                  key={`branch_currency_${terminal?.label}_${terminal?.value?.substring(3,6)}}`}
                  options={this.props.preferredCurrencies}
                  styles={smallSelectStyle}
                  value={branchCurrency?.data ?? ''}
                  onChange={(value) => this.handleTerminalCurrencyChange(value, terminal, branchCurrency)}
                />
              </div>
            })
          }
        </div> 
    </>
    )
  }

  renderTerminalPayment(){
    return(
      <React.Fragment>
      {isNewBillingEnabled() && <>
      <div class="hr-light my-20"></div>
      <div className="font-medium font-14 mb-15 d-flex mr-1 align-items-center">Payment Terms
        <div data-tip data-for="customer-info" className="pointer ml-10" >
          <IconInfoDarkI className="text-gray-100"/>
        </div>
        <ReactTooltip id="customer-info" className="type-gray w-150 text-wrap text-break line-break px-1" effect="solid">
          <span className="font-11">For date calculation, holidays & bank closing dates will be ignored</span>
        </ReactTooltip>
      </div>
      {this.isDisabled("payment-terms") && <ReactTooltip id='payment-terms'><span>You do not have permission to change Payment Terms</span></ReactTooltip>}
      <div data-tip data-for="payment-terms" className="d-flex align-items-center mb-2">
        <div className="avatar-circle avatar avatar-xs bg-white border-1 border-gray-100"><span className="text-dark">1</span></div>
        <span className="font-12 text-dark font-weigth-500 minwidth-90px ml-10">Default</span>
        <span className="font-12 text-dark font-weigth-500 ml-10">From</span>
        <Select
          isDisabled={this.isDisabled("payment-terms")}
          isClearable={false}
          value={PAYMENT_TERMS_OPTIONS?.find((el) => this.state?.defaultPaymentTerms?.paymentTermsMethod === el?.value )}
          styles={smallSelectStyle}
          onChange={(value) => {
            const updatedDefaultPaymentTerms = { ...this.state.defaultPaymentTerms, paymentTermsMethod: value?.value };
            this.setState({ defaultPaymentTerms: updatedDefaultPaymentTerms })
          }}
          options={PAYMENT_TERMS_OPTIONS}
          className="ml-10 w-200"
        />
        <span className="font-12 text-dark font-weigth-500 ml-10"> + </span>
        <input className="form-control wh-32px ml-10 px-0 text-center"
            disabled={this.isDisabled("payment-terms")}
            type="number"
            onChange={(e) => {
              let value = e?.target?.value
              const updatedDefaultPaymentTerms = { ...this.state.defaultPaymentTerms, days: Number(value) };
              this.setState({defaultPaymentTerms : updatedDefaultPaymentTerms})
            }}
            value={this.state?.defaultPaymentTerms?.days ?? 0}
        />
        <span className="font-12 text-dark font-weigth-500 ml-10">days</span>
      </div>
      </>}
        {
          this.state.newTerminal && this.state.newTerminal?.map((terminal, index) => {
            const paymentTerms = this.state?.terminalSpecificPaymentTerms?.find((payments) => {
              return payments?.terminal?.value == terminal?.value
            })
            return <div data-tip data-for="payment-terms" className="d-flex align-items-center mb-2">
              <div className="avatar-circle avatar avatar-xs bg-white border-1 border-gray-100"><span className="text-dark">{index + 2}</span></div>
              <span className="font-12 text-dark font-weigth-500 minwidth-90px ml-10">{terminal?.label}</span>
              <span className="font-12 text-dark font-weigth-500 ml-10">From</span>
              <Select
                isDisabled={this.isDisabled("payment-terms")}
                isClearable={false}
                value={PAYMENT_TERMS_OPTIONS_TERMINAL?.find((el) => paymentTerms?.data?.value === el?.value)}
                styles={smallSelectStyle}
                onChange={(value) => this.handleTerminalPaymentTermsChange(value, terminal, paymentTerms)}
                options={PAYMENT_TERMS_OPTIONS_TERMINAL}
                className="ml-10 w-200"
              />
              {paymentTerms?.data?.value !== 'default' && (
              <>
              <span className="font-12 text-dark font-weigth-500 ml-10"> + </span>
              <input className="form-control wh-32px ml-10 px-0 text-center"
                disabled={this.isDisabled("payment-terms")}
                value={paymentTerms?.days}
                type="number"
                onChange={(e) => {
                  let value = e?.target?.value
                  this.handleInputTerminalPaymentTerms(value, terminal, paymentTerms)
                }} />
              <span className="font-12 text-dark font-weigth-500 ml-10">days</span> 
              </>)}
            </div>
          })
        }
    </React.Fragment>
    )
  }

  SingleValue({
    children,
    data,
    ...props
  }){
    return <components.SingleValue {...props} isDisabled={data?.value?.isDeleted}>
      {children} {" "}
      {
        this.state?.isEditView &&
        <b className={`badge ${data?.value?.isDeleted ? "badge-gray-300" : "badge-status-green"} text-white`}>
          {data?.value?.isDeleted ? "Inactive": "Active"}
        </b>
      }
    </components.SingleValue>
  }
  renderTaxInformation = (customerType,accountID) => {
    const isTaxEnabled = this.props.accountingServiceInfo?.allInfo?.find(e => e?.accountID===accountID)?.settings?.isTaxEnable || this.props.accountingServiceInfo?.allInfo[0]?.settings?.isTaxEnable || false
    let defaultOptions = this.state.qbTaxOptions
    if(accountID && isMultiQBEnabled()){
      defaultOptions = defaultOptions.filter(e => e?.value?.accountID === accountID)
    }
    return (
      (isTaxEnabled && isAccountServiceEnabled() ? 
      <>
        {customerType.intersect([
          "caller",
          "ALL",
        ]).length > 0 && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Tax Type</label>
              <AsyncSelect
                    value={this.state.qbTaxSelected}
                    defaultOptions={defaultOptions}
                    // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    // name='chatSearch'
                    placeholder={`Select...`}
                    styles={smallSelectStyle}
                    loadOptions={(input, callback) => {
                      this.loadTaxOption(input, callback)
                    }}
                    onChange={(value) => {
                      this.setState({ qbTaxSelected: value })
                    }}
                    components={{
                      SingleValue: this.SingleValue
                    }}
                />
            </div>
          </div>
        )}
      </>
      : 
      this.state.userSettings?.isCalculateTax &&
      customerType.intersect([
        "caller",
        "ALL",
      ]).length > 0 &&
      <>
          <div className="col-md-3">
            <div className="form-group">
                    <label >
                      Tax Type
                    </label>
                    <AsyncCreatableSelect
                      styles={smallSelectStyle}
                      defaultOptions={this.state.qbTaxOptions}
                      value={this.state.qbTaxSelected}
                      onChange={(value) => {
                        this.setState({ qbTaxSelected: value })
                      }}
                      loadOptions={(input, callback) => {
                        this.loadTaxOption(input, callback)
                      }}
                      createOptionPosition="first"
                      formatCreateLabel={() =>
                        `Add New Tax Information`
                      }
                      placeholder={`Select...`}
                      onCreateOption={() =>
                        this.setState({ addTax: true })
                      }
                      className="tax"
                      components={{
                        SingleValue: this.SingleValue
                      }}
                      
                    />
                  </div>  
                  </div>
      </>
    ))
  }  

  showCreditLimitLabel = () => {
    const label = `Credit Limit (${!isGlobalizationEnabled() ? "$" : ""}${
      !this.state.credit_limit && !this.state.balance
        ? 0
        : Math.abs(this.state.credit_limit - this.state.balance).toFixed(2)
    } ${
      this.state.credit_limit < this.state.balance ? "over limit" : "remaining"
    } )`;
    return (
      <div>
      {label}
      {this.state?.credit_limit < this.state?.balance ?
        <span className="badge badge-status-dark-grey badge-sm text-white ml-2">Credit Limit Exceeded</span>
       : ''
      }
      </div>
  );
  };
  
  render() {
    var { handleSubmit, reset, countries } = this.props;
    const { inputValueData, errorInfo } = this.state;
    let { customerType, documents } = this.state;
    const isConsignee = customerType.intersect(["consignee"]).length > 0;    
    let Terminal = [...this.props.Terminals];
    if(checkUserPermisison(['allow_user_to_assign_all_terminals'])){
      Terminal = [...this.props.AllTerminals]
    }
    const preferredCurrencies = [ ... (this.props?.preferredCurrencies ?? []) ]
    const isTerminal = showForTerminal();
    if (Terminal && Terminal.length > 0) {
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
      if(this.isManageFleetOrOutSource?.isOutSource||checkUserPermisison(['allow_user_to_assign_all_terminals'])) Terminal.unshift({value:'ALL',label:this.isManageFleetOrOutSource?.isOutSource ? "ALL":"All Terminals"})
    }
    const hasQBId = !!this.props.Selected_customer?.accountingInfo?.accountingId ;
    const filterCounties = (country) => {
      return countries.filter((i) =>
        i?.label?.toLowerCase().includes(country?.toLowerCase())
      );
    };

    const countryCodeLoadOptions = (
      inputValue,
      callback
    ) => {
        callback(filterCounties(inputValue));
    };    
    const isOnlyPortShipper = customerType.length == 2 && customerType.includes("shipper") && customerType.includes("containerReturn")  
    let currentUser = JSON.parse(getStorage("loggedInUser"));
    let {customerProfileCustomersPermission, customerProfileShipperPermission, customerProfileConsigneePermission, customerProfileChassisPermission} = checkCustomerProfilePermission(currentUser)
    return (
      <React.Fragment>
        {this.props.isLoader && <LoaderBar></LoaderBar>}
        {this.props.tab == 2 && (
          <React.Fragment>
              <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
                <h4 className="mb-0 font-20">
                {documents && documents.length === 0 ? "No ": documents.filter((doc) => doc.checked).length} Documents
                </h4>
                <button
                  className="ml-auto btn btn-primary"
                  onClick={() => this.handleShowFileUploadModal()}
                >
                  <IconUpload className="mr-2" />
                  Upload Document
                </button>
              </div>
              {documents && (
                <div style={{ overflowY: 'auto', height: 'calc(100vh - 287px)', overflowX: 'hidden' }}>
                <UploadedDocuments
                  listDocuments={documents.filter((doc) => doc.checked)}
                  deleteDocument={this.deleteDocument}
                  onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                  newView={this.newView}
                  selectedDoc={this.state.selectedDoc}
                  setSelectedDoc={(docIndex) => {
                    this.setState({ selectedDoc: docIndex });
                  }}
                  handleShowUploadModal={this.handleShowUploadModal}
                  uploadResult={this.uploadResult}
                  multi={false} 
                  type={"image"}
                />
                </div>
              )}
            {/* <h4 className="card-title">Documents</h4>
         <div className="table-responsive">
              <table className="table table-card">
                <thead>
                  <tr>
                    <th width="200px" scope="col">
                      Doc Type
                    </th>
                    <th scope="col">Date</th>
                    <th scope="col">User</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents &&
                    documents.length > 0 &&
                    documents.map((info, key) => {
                      return (
                        <tr
                          key={key}
                          id={info._id}
                          style={{
                            display: !info.checked ? "none" : "",
                          }}
                        >
                          <td>{info.type}</td>
                          <td>{moment(info.date).format("MM/DD/YYYY")}</td>
                          <td>{info.userId.name}</td>
                          <td>
                            <span className="lint d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() => {
                                  this.previewImage(info);
                                }}
                                className="btn btn-none text-primary"
                              >
                                <i
                                  style={{
                                    color:
                                      this.state.selectedDoc &&
                                      this.state.selectedDoc._id === info._id
                                        ? "#a5db03"
                                        : "",
                                  }}
                                  className="uil uil-eye font-18"
                                />
                              </button>

                              <button
                                className="btn btn-none text-primary"
                                onClick={() => this.downloadDoc(info)}
                              >
                                <IconDownload />
                              </button>
                              <button
                                type="button"
                                className="btn btn-none text-danger"
                                onClick={() =>
                                  this.deleteEquipmentDoc({
                                    docId: info._id,
                                    _id: this.props.Selected_customer?._id,
                                  })
                                }
                              >
                                <IconTrash />
                              </button>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>   */}
          </React.Fragment>
        )}

        <div
          id="formContainer"
          className={`${
            this.props.initialValues._id ? "card py-30 px-20" : ""
          }`}
          style={{
            height: "100%",
            display: this.props.tab == 1 || this.props.tab == 7 ? "" : "none",
          }}
        >
          {this.props.tab === 1 && (
            <div>
              {this.props.isQuickAdd && (
                <div className="font-medium mb-10 font-14">Customer Type</div>
              )}
              <div className="d-flex mb-4">
                <div className="form-check form-check-primary pr-30 ml-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="caller"
                    onClick={() => this.setCustomerType(["caller"])}
                    checked={
                      customerType.intersect(["caller"]).length > 0
                        ? true
                        : false
                    }
                    disabled={!customerProfileCustomersPermission}
                  />
                  <label className="form-check-label" for="caller">
                    Customer
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="shipper"
                    onClick={() =>
                      this.setCustomerType(["shipper", "containerReturn"])
                    }
                    checked={
                      customerType.intersect(["shipper", "containerReturn"])
                        .length > 0
                        ? true
                        : false
                    }
                    disabled={!customerProfileShipperPermission}
                  />
                  <label className="form-check-label" for="shipper">
                    Port/Shipper
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="consignee"
                    onClick={() => this.setCustomerType(["consignee"])}
                    checked={
                      customerType.intersect(["consignee"]).length > 0
                        ? true
                        : false
                    }
                    disabled={!customerProfileConsigneePermission}
                  />
                  <label className="form-check-label" for="consignee">
                    Consignee
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="chassisPick"
                    onClick={() =>
                      this.setCustomerType([
                        "chassisTermination",
                        "chassisPick",
                      ])
                    }
                    checked={
                      customerType.intersect([
                        "chassisPick",
                        "chassisTermination",
                      ]).length > 0
                        ? true
                        : false
                    }
                    disabled={!customerProfileChassisPermission}
                  />
                  <label className="form-check-label" for="chassisPick">
                    Chassis
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ALL"
                    onClick={() => this.setCustomerType(["ALL"])}
                    checked={customerType.intersect(["ALL"]).length > 0 ? true : false}
                    disabled={!(customerProfileCustomersPermission && customerProfileShipperPermission && customerProfileConsigneePermission && customerProfileChassisPermission)}
                  />
                  <label className="form-check-label" for="all">
                    All
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="">
            <input type="hidden" value="something" />
            <form
              onSubmit={handleSubmit(async(data) => {
                this.setState({ isLoading: true });
                if(this.state.customerType?.length === 0) {
                  toastr.show("Please select customer type", 'error');
                  return;
                }
                if(isEmbeddedEmailEnabled()){
                  if ((typeof this.state.isUserNameValid === "boolean" && !this.state.isUserNameValid) || this.state.domainNameErr) {
                    toastr.show("Please select different domain name.", 'error');
                    this.setState({ isLoading: false })
                    return;
                  }
                  if(this.state.organizationDomain){
                    data['organizationDomain'] = this.state.organizationDomain.toLowerCase()
                  }
                }
                data['isAddressVerified'] = false;
                // if (isOnlyPortShipper) data.email = ''
                // if(!this.state.address1 && data.address) {
                  // data['address1'] = data.address.split(',')[0];
                // } else {
                  data['address1'] = this.state.address1;
                // }
                if(data['state'] && (validateCustomerAddress(data['state'].trim()))) {
                  toastr.show("Please check! You have added wrong state", "error");
                  return;
                }
                if(data['country'] && (validateCustomerAddress(data['country'].trim()))) {
                  toastr.show("Please check! You have added wrong country", "error");
                  return;
                }
                if(data['city'] && (validateCustomerAddress(data['city'].trim()))) {
                  toastr.show("Please check! You have added wrong city", "error");
                  return;
                }
                if(data['zip_code'] && (validateCustomerAddress(data['zip_code'].trim()))) {
                  toastr.show("Please check! You have added wrong zip code", "error");
                  return;
                }

                // if(data.address.split(',')[0] !== data.address1) {
                //   data.address = data?.address?.replace(/\s*,\s*/g, ',').replace(/,{2,}/g, ',').trim();
                //   this.state.address = data.address;
                // }
                // if(!data.address) {
                //   data.address = `${data.address1 ? data.address1 : ""} ${data.city ? data.city + "," : ""} ${data.state ? data.state + "," : ""} ${data.zip_code ? data.zip_code + ",": ""} ${data.country ? data.country : ""}`
                // }
                if(data?.address1) {
                  data.address = createFullAddressFromStreet(data?.address1, data?.country, this.state.countryName, data?.zip_code, data?.city, data?.state)
                }
                if (this.state.currency) {
                  data["currency"] = this.state.currency;
                }
                if(this.state.countryCode && isEurope()){
                  data["countryCode" ] = this.state.countryCode.value;
                }
                if (this.state.preferredCurrency) {
                  data["preferredCurrency"] = this.state.preferredCurrency;
                }
                if(this.state.customerSubType){
                  data["customerSubType"] = this.state.customerSubType
                }
                if (this.state.canEditLoad) {
                  data["canEditLoad"] = this.state.canEditLoad;
                }
                if (this.state.isTIROptional) {
                  data["isTIROptional"] = this.state.isTIROptional;
                }
                data["notes"] = this.state.notes ? this.state.notes : "";
                if (this.state.payType) {
                  data["payType"] = this.state.payType;
                }
                if(data.countries) delete data.countries
                
                if (this.state.taxType) {
                  data["taxType"] = this.state.taxType;
                } else {
                  data["taxType"] = '';
                }
                if (this.state.taxRate) {
                  data["taxRate"] = this.state.taxRate;
                } else {
                  data["taxRate"] = ''
                }

                if(isMultiQBEnabled()){
                  if(!this.state.accountID){
                    toastr.show("Please select quickbooks comapny file.", "error");
                    return;
                  }else{
                    data["accountID"] = this.state.accountID;
                  }
                }
                if(this.state.userSettings?.isCalculateTax) {
                  if(this.state.qbTaxSelected) {
                    data["taxRate"] = this.state.qbTaxSelected?.value?.taxRate;
                    data["taxType"] = this.state.qbTaxSelected?.value?.taxType;
                    data["qbSalesTaxId"] = this.state.qbTaxSelected?.value?.qbSalesTaxId;
                    data["masterTaxAll"] = this.state.qbTaxSelected?.value?._id;
                  } else {
                    data["taxRate"] = '';
                    data["taxType"] = '';
                    data["qbSalesTaxId"] = '';
                    data["masterTaxAll"] = '';
                  }
                }

                let emailList = _.cloneDeep(this.state.emailList);
                // validation before saving
                const checkDuplicateEmail = emailList.some((el) => el.value === this.state.tempEmail);
                if(checkDuplicateEmail) {
                  toastr.show("Please check! You have added a duplicate email", "warning");
                  return;
                } 
                
                // validation on saving
                const checkDuplicateOnSave = this.isDuplicate(emailList)
                if(checkDuplicateOnSave) {
                  emailList = this.findDuplicateEmails(emailList);
                  this.setState({emailList: emailList })
                }
                
                const inValidEmailList = emailList.filter(
                  (email) => !this.billingEmail(email.value)
                );
                if (inValidEmailList && inValidEmailList.length > 0) {
                  toastr.show("Please check the format of the billing email.", "error");
                  this.setState({ isLoading: false });
                  return;
                }

                let billingEmail = "";
                (emailList || []).forEach((email) => {
                  billingEmail +=
                    emailList.length === 1 ? email.value : email.value + ",";
                });

                data["billingEmail"] = billingEmail;
                data["notificationEmails"] =
                  this.state.notificationEmails.toString();
                data["extraEmail"] =
                  this.state.extraEmail.toString();
                if (this.state.fleetCustomer) {
                  data["fleetCustomer"] = this.state.fleetCustomer.value;
                }
                if (this.state.documentFile) {
                  data.documentFile = this.state.documentFile;
                  data.fileType = this.state.fileType;
                }
                data["geofence"] = JSON.stringify({
                  center: [this.state.lng, this.state.lat],
                  zoom: this.state.zoom,
                  polygon: this.state.polygonData,
                 ...(checkIfEnableDrawStartingPoint()&& this.state.startingPointLatLong.length>1&& {startPoint: this.state.startingPointLatLong})
                });
                data["customerType"] = this.state.customerType;
                data["notes"] = this.state.notes;
         
                if((getStorage("currentUserRole") == "fleetmanager" &&
                configuration.idForTerminal.indexOf(
                  JSON.parse(getStorage("loggedInUser")).fleetManager
                    .carrier
                ) != -1) ||
                (getStorage("currentUserRole") == "carrier" &&
                  configuration.idForTerminal.indexOf(
                    getStorage("currentUserID")
                  ) != -1) ||this.state.newTerminal)
                 { 
                   if(this.state.newTerminal && this.state.newTerminal.length>0){
                    data["newTerminal"] = this.state.newTerminal.filter(d => d.value).map((D) => {
                      return D.value
                    }) 
                   }
                } 
                if (this.state.CustID) {
                  data["_id"] = this.state.CustID;
                }
                if (this.state.customerTags) {
                  data["customerTags"] = JSON.stringify(
                    this.state.customerTags
                  );
                }
                if(this.state.requiredDocList !== data["requiredDocList"]){
                  data["requiredDocList"] = JSON.stringify(
                    this.state.requiredDocList
                  )
                }
                else{
                  delete data.requiredDocList;
                }
                if(this.state.extraSubCustomer && this.state.extraSubCustomer.length > 0){
                  data.extraSubCustomer = JSON.stringify(this.state.extraSubCustomer.map((d) => d.value));
                }else {
                  data.extraSubCustomer = []
                }
                // data.extraSubCustomer = this.state.extraSubCustomer;
                data.salesAgents = this.state.salesAgents;

                if (!data.officeHoursStart || data.officeHoursStart === "") {
                  delete data.officeHoursStart;
                }

                if (data.company_name) {
                  data.company_name = data.company_name.trim();
                }

                if (!data.officeHoursEnd || data.officeHoursEnd === "") {
                  delete data.officeHoursEnd;
                }
                data["doc_all"] = this.state.doc_all;
                data["doc_tir"] = this.state.doc_tir;
                data["doc_pod"] = this.state.doc_pod;
                data["doc_none"] = this.state.doc_none;
                
                if (this.state.registrationCountry) {
                  data['registrationCountry'] = this.state.registrationCountry.value;
                }

                if (this.state.registrationNumber) {
                  data['registrationNumber'] = this.state.registrationNumber;
                }

                if(isGlobalizationEnabled()) {
                  const newTerminalList = this.state.newTerminal?.map((e) => e?.value);
                  const allowedNewInvoiceCurrencyMapList = [];
                  
                  for(const branch of newTerminalList) {
                    const found = this.state.invoiceCurrencyWithBranch?.find((e) => e?.terminal?.value === branch);
                    if(found) {
                      allowedNewInvoiceCurrencyMapList.push(found);
                    }
                  }
  
                  if(allowedNewInvoiceCurrencyMapList?.length > 0){
                    const mapBranchCurrency = allowedNewInvoiceCurrencyMapList?.map((currency) => {
                      return {
                        branch: currency?.terminal?.value,
                        currency: currency?.data?.value
                      }
                    })
                    data["invoiceCurrencyWithBranch"] = JSON.stringify(mapBranchCurrency);
                  } else {
                    data["invoiceCurrencyWithBranch"] = JSON.stringify([]);
                  }
  
                  if(this.state.invoiceCurrencyWithCarrier){
                    data["invoiceCurrencyWithCarrier"] = this.state.invoiceCurrencyWithCarrier?.value;
                  }
                }
                if (isNewInvoice()) {
                  const newTerminalList = this.state.newTerminal?.map((e) => e?.value);
                  const allowedNewPaymentTermsMapList = [];
                  for (const branch of newTerminalList) {
                    const found = this.state?.terminalSpecificPaymentTerms?.find((e) => e?.terminal?.value === branch);
                    if (found) {
                      allowedNewPaymentTermsMapList.push(found);
                    }
                  }
                  if(allowedNewPaymentTermsMapList?.length > 0){
                    let isValidterms = false
                    const mapBranchPaymentTerms = allowedNewPaymentTermsMapList?.map((paymentTerms) => {
                      if(paymentTerms.days && !paymentTerms.data) isValidterms = true 
                      return {
                        branch: paymentTerms?.terminal?.value,
                        paymentTermsMethod: paymentTerms?.data?.value,
                        days : paymentTerms?.days
                      }
                    })
                    if(isValidterms) return toastr.show('Please select valid payment terms',"error")
                    data["terminalSpecificPaymentTerms"] = JSON.stringify(mapBranchPaymentTerms);
                  }else {
                    data["terminalSpecificPaymentTerms"] = JSON.stringify([]);
                  }
                  if(this.state.defaultPaymentTerms?.paymentTermsMethod && this.state.defaultPaymentTerms?.days >= 0){
                    data["defaultPaymentTerms"] = JSON.stringify(this.state?.defaultPaymentTerms)
                  }
                }else{
                  delete data.defaultPaymentTerms
                  delete data.terminalSpecificPaymentTerms
                }

                if(this.state.shareLoadNotes !== undefined && (this.state.isConnectEnabled || this.isShiposCarrier)){
                  data["shareLoadNotes"] = this.state.shareLoadNotes
                }

                if(this.state.shareBillingNotes !== undefined && (this.state.isConnectEnabled || this.isShiposCarrier)){
                  data["shareBillingNotes"] = this.state.shareBillingNotes
                }

                if(this.state.loadChargeCombination) {
                  data.customerCombinationMethod = this.state.loadChargeCombination
                }else{
                  data.customerCombinationMethod = 'MANUAL'
                }

                if(this.state.isSeparateInvoicePerTerminal) {
                  data.isSeparateInvoicePerTerminal = this.state.isSeparateInvoicePerTerminal
                }else{
                  data.isSeparateInvoicePerTerminal = false
                }

                if(this.state.paymentTermsMethod) {
                  data.paymentTermsMethod = this.state.paymentTermsMethod
                }else{
                  data.paymentTermsMethod = 'day'
                }
                if(this.state.externalSystemID) {
                  let externalSystemID = this.state.externalSystemID.trim()
                  if(externalSystemID.length > 0) {
                    data["externalSystemID"] = externalSystemID.toString()
                  }
                }
                if(data.email){
                  data["email"]=data.email
                }
                if(this.props.Selected_customer?.main_contact_name){
                  data["main_contact_name"]= data.main_contact_name
                }
                this.props
                  .submitForm(data)
                  .then((addedDataId) => {
                    if(data["externalSystemID"]) {
                      this.setState({disabledExternalSystemID : true})
                    } else {
                      this.setState({disabledExternalSystemID : false})
                    }
                    if (addedDataId) {
                      this.setState({ CustID: addedDataId });
                      this.props.closeModal()
                    } else if (!this.props.initialValues._id) {
                      reset();
                      this.setState({
                        qbTaxSelected: null,
                      });
                      this.setState({
                        countryCode: null
                      });
                    }
                    if (!addedDataId) {
                      this.setState({
                        features: [],
                        documentFile: null,
                        imagePreviewUrl: null,
                        pdfPreviewUrl: null,
                        fileType: null,
                        csvPreviewUrl: null, 
                        xlsPreviewUrl: null
                      });
                    }

                    this.setState({
                      isLoading: false,
                      showFileUploadModal: false,
                      isShowAddressSuggestions: true
                    });
                    if (this.props.isQuickAdd) {
                      this.setState({ isOnlyPopup: !this.props.isOnlyPopup });
                    }
                    
                    if(data['organizationDomain']){
                      this.setState({organizationDomain:data["organizationDomain"]})
                    }
                  })
                  .catch((e) => {
                    this.setState({ isLoading: false });
                  });
              })}
            >
              {this.props.isQuickAdd && <div class="hr-light my-20"></div>}
              {this.props.tab === 1 && (
             <React.Fragment>
                <div className="form-row">
                  <div className="col-lg-12">
                    <div className="font-14 font-medium mb-15">Company</div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <Field
                        type="text"
                        className="form-control"
                        component={InputField}
                        autoComplete="company_name"
                        required={true}
                        name="company_name"
                        label="Company Name"
                        validate={[required, validateWhiteSpace]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                        <label> <span className="text-danger">*</span> Address</label>
                        <SearchPlaces
                          placeHolder="Enter Street Address"
                          // isCreateable={true}
                          isIcon={true}
                          onChange={this.handleSelect}
                          defaultValue={this.state.address1 || this.state.formattedAddress1}
                          placeType={"ADDRESS"}
                          existingData={{
                            country: this.state.country,
                            address1: this.state.address1,
                            city: this.state.city,
                            state: this.state.state,
                            lat: this.state.address_lat,
                            lng: this.state.address_lng,
                            zip_code: this.props?.Selected_customer?.zip_code
                          }}
                          isEmpty={this.props.addressValidation?.address || false}
                        />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                    <label> <span className="text-danger">*</span> City</label>
                        <SearchPlaces
                          placeHolder="Enter City"
                          onChange={this.handleSelectCity}
                          defaultValue={this.state.city}
                          placeType={"CITY"}
                          isEmpty={this.props.addressValidation?.city || false}
                        />
                      </div>
                  </div>

                  { isEurope() && <div className="form-group col-4">
                  <div className="form-group">
                      <label className="col-form-label">
                        Country Code
                      </label>
                      <AsyncSelect
                        value={this.state.countryCode}
                        cacheOptions
                        styles={smallSelectStyle}
                        loadOptions={countryCodeLoadOptions}
                        defaultOptions={countries}
                        name='countryCode'
                        placeholder="Country Code"
                        onChange={(countryCode) => {this.setState({countryCode})}}
                      />
                    </div>
                  </div>}

                  <div className="col-lg-2">
                    <div className="form-group">
                    <label> <span className="text-danger">*</span> State</label>
                        <SearchPlaces
                          placeHolder="Enter State"
                          onChange={this.handleSelectState}
                          placeType={"STATE"}
                          defaultValue={this.state.state}
                          isEmpty={this.props.addressValidation?.state || false}
                        />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <Field
                        component={InputField}
                        type="text"
                        className="form-control"
                        required={this.isAddressFieldRequired(`zip_code`)}
                        label={getAddressTerminology()}
                        validate={this.isAddressFieldRequired(`zip_code`) ? [required, validateWhiteSpace] : [] }
                        normalize={zipcodeNormalize}
                        name="zip_code"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                    <label> <span className="text-danger">*</span> Country</label>
                        <SearchPlaces
                          placeHolder="Enter Country"
                          onChange={this.handleSelectCountry}
                          placeType={"COUNTRY"}
                          defaultValue={this.state.country}
                          isEmpty={this.props.addressValidation?.country || false}
                        />
                    </div>
                  </div>
                  <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            component={InputField}
                            type="text"
                            className="form-control"
                            label="MC number"
                            normalize={numberalphaNormalize}
                            name="mcNumber"
                          />
                        </div>
                      </div>
                      {this.isVAT && (customerType.intersect(["caller","ALL",]).length > 0) && (
                      <>
                      <div className="col-md-4">
                              <div className="form-group new-set-label">
                              <label className="col-form-label" for="registrationNumber">Tax Registration Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.registrationNumber}
                                  // normalize={numberNormalize}
                                  name="registrationNumber"
                                  id="registrationNumber"
                                  onChange={(e)=>{
                                    this.setState({registrationNumber:e.target.value})}}
                                />
                              </div>
                            </div>
                        <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-form-label">
                                  Tax Country Code
                                </label>
                                <Select
                                  value={this.state.registrationCountry}
                                  styles={smallSelectStyle}
                                  options={countries}
                                  isSearchable={true}
                                  name='registrationCountry'
                                  placeholder="Tax Country Code"
                                  onChange={(val) => {
                                    this.setState({registrationCountry: val})
                                  }
                                }
                                />
                              </div>
                            </div>
                            </>
                      )}
                </div>
                <div className="hr-light mt-15 mb-20"></div>
                </React.Fragment>
              )}
              
              {this.props.tab === 1 && customerType.intersect(["caller", "consignee", "ALL"]).length > 0 && (
                <React.Fragment>
                  <div className="form-row">
                     <div className="col-md-12">
                     <div className="font-medium font-14 mb-15">Email</div>
                    </div>
                    <React.Fragment>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="email"
                              className="form-control"
                              component={InputField}
                              name="email"
                              required={
                                customerType.intersect(["caller"]).length > 0
                                  ? true
                                  : false
                              }
                              validate={
                                customerType.intersect(["caller"]).length > 0
                                  ? [this.requireEmail]
                                  : []
                              }
                              label={`Email and Customer Portal Admin Login`}
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                        {!this.props.initialValues._id && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <Field
                                type="text"
                                className="form-control"
                                component={renderField}
                                name="password"
                                required={
                                  customerType.intersect(["caller", "ALL"])
                                    .length > 0
                                    ? true
                                    : false
                                }
                                validate={
                                  customerType.intersect(["caller", "ALL"])
                                    .length > 0
                                    ? [this.requirePassword]
                                    : []
                                }
                                label={`Password `}
                                placeholder="Enter Password"
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="text"
                              className="form-control"
                              component={InputField}
                              name="receiverEmail"
                              label="Receiver Email"
                              placeholder="Enter Receiver Email"
                              validate={[receiverEmail]}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    {customerType.intersect(["caller", "ALL"]).length > 0 && (
                      <React.Fragment>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Billing Email</label>
                            <AsyncCreatableSelect
                              menuPlacement="top"
                              cacheOptions
                              isMulti
                              styles={smallSelectStyle}
                              defaultOptions={this.state.allEmail}
                              formatCreateLabel={(inputValue) =>
                                `Add "${inputValue}"`
                              }
                              value={this.state.emailList}
                              onChange={(value) => {
                                this.setState({ emailList: value });
                              }}
                              onCreateOption={(value) => this.addEmails(value)}
                              onInputChange={this.onEmailInputChange}
                              placeholder={"Enter Billing Email"}
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null,Menu: () => null}}
                              onKeyDown={this._handleKeyDown}
                            />
                          </div>
                        </div>
                        {isAccountServiceEnabled() && <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="text"
                              className="form-control"
                              component={InputField}
                              name="qbEmail"
                              label="QuickBooks Email"
                              validate={[quickBookEmail]}
                            />
                          </div>
                        </div>}
                      </React.Fragment>
                    )}
                  </div>
                  {isEmbeddedEmailEnabled() && <div className="form-group w-100">
                    <div className="position-relative w-100">
                      <Field
                        type="text"
                        className={`form-control w-200 ${errorInfo ? 'border-danger' : ''}`}
                        component={InputField}
                        name="organizationDomain"
                        label="Company Domain"
                        tooltipId="organizationDomain"
                        toolTipValue="When adding Person profiles based on emails, the Company Domain is used to match email addresses to the appropriate Organization."
                        errorInfo={typeof this.state.isUserNameValid === "boolean" && !this.state.isUserNameValid}
                        validIcon={this.state.isUserNameValid}
                        onChange={(e) => this.handleChangeOfDomain(e.target.value)}
                        value={this.state.organizationDomain}
                      />
                        {typeof this.state.isUserNameValid === "boolean" && !this.state.isUserNameValid && <div className="text-danger font-12 font-weight-normal pointer mt-1 d-inline-block" onClick={this.openModalInfo}>Click for more details</div>}
                        {this.state.domainNameErr && <div className="text-danger font-12 font-weight-normal mt-1 d-inline-block">Enter valid domain name.</div>}
                        {this.state.showModalInfo && ( 
                          <CompanyDomainModal closeModalInfo={this.closeModalInfo} usedDomain={this.state.organizationDomain} orgName = {this.state.usedOrgName} />
                        )}
                        </div>
                    </div>}
                    <div class="hr-light my-20"></div>
                    
                </React.Fragment>
              )}

              {this.props.tab === 1 && customerType.intersect(["ALL", "caller"]).length > 0 && isAccountServiceEnabled() && this.props?.accountingServiceInfo?.isMultiAccountEnabled && (
                <React.Fragment>
                  <div className="font-medium font-14 mb-15">Quickbooks Company File</div>
                  <div className="form-row">
                    <div className="col-md-3 d-flex align-items-center mb-10">
                      {this.props.accountingServiceInfo?.companyFiles?.map(el => {
                        return (
                          <div className="form-check form-check-primary pr-30 ml-20">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="accountID"
                              onChange={() => this.setState({ accountID: el.accountID })}
                              checked={
                                this.state.accountID === el.accountID
                                  ? true
                                  : false
                              }
                            />
                            <label className="form-check-label" for="accountID">
                              {el.accountName}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div class="hr-light my-20"></div>
                </React.Fragment>
              )}


              {this.props.tab === 1 && customerType.intersect([
                "caller",
                "consignee",
                "shipper",
                "ALL",
              ]).length > 0 && (
                <React.Fragment>
                  <div className="font-medium font-14 mb-15">Payment</div>
                  <div className="form-row">
                    {customerType.intersect(["caller", "ALL"]).length > 0 && (
                      <React.Fragment>
                        <div className="col-md-3">
                          <div className="form-group">
                            <Field
                              type="text"
                              disabled={this.isDisabled("credit-limit")}
                              className="form-control"
                              component={InputField}
                              name="credit_limit"
                              label={this.showCreditLimitLabel()}
                              normalize={numberNormalize}
                            />
                          </div>
                        </div>
                       {!isNewInvoice() && <div className="col-md-3">
                          <div className="form-group">
                            <Field
                              type="text"
                              disabled={this.isDisabled("payment-terms")}
                              className="form-control"
                              component={InputField}
                              name="payment_terms"
                              label="Payment Terms"
                              normalize={numberNormalize}
                            />
                          </div>
                        </div>}
                      </React.Fragment>
                    )}
                    {/* {customerType.intersect([
                      "caller",
                      "ALL",
                    ]).length > 0 && (
                      <div className="col-md-3">
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            component={SelectField}
                            name="credit_hold"
                            label="Credit Hold"
                            onChange={(credit_hold) => {
                              this.props.change("credit_hold", credit_hold);
                            }}
                            data={loadFlags}
                            disabled
                          />
                        </div>
                      </div>
                    )} */}
                    {customerType.intersect([
                      "caller",
                      "consignee",
                      "shipper",
                      "ALL",
                    ]).length > 0 && (
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Account Hold</label>
                          <Select
                            options={loadFlags}
                            styles={smallSelectStyle}
                            value={loadFlags.find((flag) => flag?.value === this.state?.accountHold)}
                            onChange={({value}) => {
                              this.setState({accountHold:value})
                              this.props.change("account_hold", value);
                            }
                            }
                          />
                        </div>
                      </div>
                    )}
                  { hasQBId && <ReactTooltip id='currency'><span>You cannot change currency once customer is synced to Quickbooks</span></ReactTooltip>}
                    {customerType.intersect([
                      "caller",
                      "consignee",
                      "shipper",
                      "ALL",
                    ]).length > 0 && (
                      <div 
                      data-tip data-for='currency'
                      className="col-md-3">
                        <div className="form-group">
                          <label>Currency {this.state.currency} </label>
                          <Select
                            options={currencies}
                            isDisabled={hasQBId}
                            styles={smallSelectStyle}
                            value={
                              this.state.currency &&
                              currencies.find(
                                (item) => item.value === this.state.currency
                              )
                            }
                            onChange={({ value }) =>
                              this.setState({ currency: value })
                            }
                          />
                        </div>
                      </div>
                    )}


                    {
                      this.renderTaxInformation(customerType,this.state.accountID)
                    }

                    <div className="col-md-3">
                    {customerType.intersect(["caller", "ALL"]).length > 0 && isNewBillingEnabled && (
                    <div className="form-group">
                      <label>Payment Term Method</label>
                      <Select
                        isClearable = {false}
                        value={PAYMENT_TERMS_METHOD.find((item) => item.value === this.state.paymentTermsMethod ) || 'day'}
                        styles={smallSelectStyle}
                        onChange={(value) => {
                          if(this.state.paymentTermsMethod === value.value) return
                          this.setState({paymentTermsMethod: value.value});
                        }}
                        options={PAYMENT_TERMS_METHOD}
                      />
                    </div>
                  )}
                    </div>
                  </div>
                  
                  <div class="hr-light my-20"></div>
                </React.Fragment>
              )}

              <React.Fragment>
                {this.props.tab === 1 && (
                  <React.Fragment>
                    <div className="font-14 font-medium mb-15">Contact</div>
                    <div className="form-row">
                      {customerType.intersect([
                        "caller",
                        "shipper",
                        "consignee",
                        "ALL",
                      ]).length > 0 ? (
                        <React.Fragment>
                          <div className="col-md-2">
                            <div className="form-group dropdown-on-top">
                              <Field
                                menuPlacement="top"
                                component={DateTimeField}
                                // className="form-control"
                                label="Office Hour Start"
                                name="officeHoursStart"
                                dateFormat={false}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group dropdown-on-top">
                              <Field
                                menuPlacement="top"
                                component={DateTimeField}
                                // className="form-control"
                                label="Office Hour End"
                                name="officeHoursEnd"
                                dateFormat={false}
                              />
                            </div>
                          </div>
                          {this.props.initialValues._id && 
                          <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="text"
                              className="form-control"
                              component={InputField}
                              name="externalSystemID"
                              label="External Id"
                              placeholder="External Id"
                              tooltipId="externalSystemID"
                              toolTipValue={this.state.disabledExternalSystemID ? "Once an External ID number is saved, it can’t be edited or removed." : null}
                              disabled={this.state.disabledExternalSystemID}
                            />
                          </div>
                        </div>}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {this.props.initialValues._id && 
                          <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="text"
                              className="form-control"
                              component={InputField}
                              name="externalSystemID"
                              label="External Id"
                              placeholder="External Id"
                              tooltipId="externalSystemID"
                              toolTipValue={this.state.disabledExternalSystemID ? "Once an External ID number is saved, it can’t be edited or removed." : null}
                              disabled={this.state.disabledExternalSystemID}
                            />
                          </div>
                        </div>}
                        </React.Fragment>
                      )}
                    </div>
                    <div className="form-row">
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            type="text"
                            className="form-control"
                            component={InputField}
                            name="main_contact_name"
                            label={`Main Contact Name`}
                            // normalize={fieldNormalize}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group new-set-label">
                          <Field
                            type="text"
                            className="form-control"
                            component={phoneComponent}
                            validate={[mobile]}
                            name="mobile"
                            label="Main Phone"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            type="text"
                            className="form-control"
                            component={InputField}
                            name="secondary_contact_name"
                            label="Secondary Contact Name"
                          />
                        </div>
                      </div>
                      {customerType.intersect([
                        "caller",
                        "consignee",
                        "shipper",
                        "containerReturn",
                        "ALL",
                      ]).length > 0 && (
                        <div className="col-md-2">
                          <div className="form-group new-set-label">
                            <Field
                              type="text"
                              className="form-control"
                              component={phoneComponent}
                              name="secondaryPhoneNo"
                              validate={[mobile]}
                              label="Secondary Phone"
                            />
                          </div>
                        </div>
                      )}
                     
                    </div>
                  </React.Fragment>
                )}
                {/* PropsTABS == 7 */}
                {
                this.props.tab !== 1 ?
                <>
                <div>
                  {`Email Notifications have been moved to Settings > Notification Settings, click this link to take you there: `}
                  <a className="text-primary" onClick={() => browserHistory.push(`/settings/notifications#templates`)}>Notification Settings</a>
                </div>
                </> 
                :
                <>
                <div className="form-row">
                  {customerType.intersect([
                    "ALL",
                    "caller",
                    "shipper",
                    "containerReturn",
                    "consignee",
                    "chassisTermination",
                    "chassisPick",
                  ]).length > 0 &&
                    ((getStorage("currentUserRole") == "fleetmanager" &&
                      configuration.idForTerminal.indexOf(
                        JSON.parse(getStorage("loggedInUser")).fleetManager
                          .carrier
                      ) != -1) ||
                      (getStorage("currentUserRole") == "carrier" &&
                        configuration.idForTerminal.indexOf(
                          getStorage("currentUserID")
                        ) != -1)) &&
                    this.props.tab === 1 && (
                      <React.Fragment>
                      <div className="col-md-4">                   
                        
                        <div className="form-group new-set-label">
                        <Field 
                        name="newTerminal"
                        required={true}
                        isMulti={true}
                        data={this.state.newTerminal}
                        value={this.state.newTerminal}
                        label="Terminal" 
                        onChange={this.handleTerminalChange}
                        component={ReduxFormSelect} 
                        validate={this.requireTerminal}
                        options={Terminal} />
                        </div>
                      </div> 
                    
                          </React.Fragment>
                    )}
                  {customerType.intersect(["shipper"]).length > 0 &&
                    this.props.tab === 1 && (
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            component={InputField}
                            type="text"
                            className="form-control"
                            label="Port Username"
                            name="portUsername"
                            disabled={!this.props.showAddModal}
                          />
                        </div>
                      </div>
                    )}
                  {customerType.intersect(["shipper"]).length > 0 &&
                    this.props.tab === 1 && (
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            component={InputField}
                            type="text"
                            className="form-control"
                            label="Port Password"
                            name="portPassword"
                            disabled={!this.props.showAddModal}
                          />
                        </div>
                      </div>
                    )}
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <label>Sales Agent</label>
                      <AsyncSelect
                        menuPlacement="top"
                        isMulti
                        styles={smallSelectStyle}
                        value={this.state.salesAgents}
                        defaultOptions={this.state.defaultSalesAgents}
                        loadOptions={this.listAllFleetManagers}
                        onChange={(salesAgents) =>
                          this.setState({ salesAgents })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <label>Customer Sub Type </label>
                        <Select
                          styles={smallSelectStyle}
                          options={customerSubType}
                          value={this.state.customerSubType && customerSubType.find(item => item.value === this.state.customerSubType)}
                          onChange={({ value }) => this.setState({ customerSubType: value })}
                        />
                      </div>
                    </div>
                    {isAccountServiceEnabled() && <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <Field
                        type="text"
                        className="form-control"
                        component={InputField}
                        name="companyField"
                        label="QuickBooks Company Field"
                        normalize = {fieldNormalize}
                      />
                    </div>
                  </div>}
                </div>
                </>
                }
              </React.Fragment>

              <div
                className="text-center"
                style={{ display: "none" }}
                // onClick={() => this.validateFormSubmit()}
              >
                <ButtonComponent
                  type="submit"
                  label="Add"
                  id="profileButton"
                  className="btn btn-primary btncustomersave"
                ></ButtonComponent>
                <br />
              </div>
              {this.props.tab === 1 && (
                <React.Fragment>
                  <div class="hr-light my-20"></div>
                  <div className="font-medium font-14 mb-15">Preferences</div>
                  <div className="form-row">
                    <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <label>Fleet Customer</label>
                        <Select
                          isClearable
                          value={this.state.fleetCustomer}
                          styles={smallSelectStyle}
                          onChange={(fleetCustomer) => {
                            if (
                              fleetCustomer === null &&
                              this.props.Selected_customer?._id
                            )
                              this.removeFleetFromCustomer(
                                this.props.Selected_customer?._id
                              );
                            this.setState({ fleetCustomer });
                          }}
                          options={this.state.fleetCustomerdata}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <label>Sub Customer For Portal</label>
                        <AsyncSelect
                          menuPlacement="top"
                          isMulti
                          styles={smallSelectStyle}
                          value={this.state.extraSubCustomer}
                          defaultOptions={this.state.defaultSubCustomer}
                          loadOptions={this.getCustomerOptions}
                          onChange={(extraSubCustomer) =>
                            this.setState({ extraSubCustomer })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <Field
                          onChange={(target, payType) => {
                            this.setState({ payType });
                          }}
                          type="text"
                          className="form-control"
                          component={SelectField}
                          name="payType"
                          label="Pay Type"
                          data={directFactoryFlags}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {this.props.tab === 1 && (
                <div className="form-row">
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <label>Customer Tags</label>
                      <AsyncCreatableSelect
                        menuPlacement="top"
                        cacheOptions
                        isMulti
                        styles={smallSelectStyle}
                        loadOptions={this.getCustomerTags}
                        defaultOptions={this.state.allCustomerTags}
                        formatCreateLabel={(inputValue) =>
                          `Add "${inputValue}"`
                        }
                        value={
                          this.state.customerTags &&
                          this.state.customerTags.map((d) => ({
                            label: d,
                            value: d,
                          }))
                        }
                        onChange={(value) => {
                          this.setState({
                            customerTags: value.map((d) => d.value),
                          });
                        }}
                        onCreateOption={(value) => this.addCustomerTags(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <Field
                        menuPlacement="top"
                        onChange={(target, canEditLoad) => {
                          this.setState({ canEditLoad });
                        }}
                        type="text"
                        className="form-control"
                        component={SelectField}
                        name="canEditLoad"
                        label="Can Edit Load"
                        data={loadFlags}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <Field
                        menuPlacement="top"
                        onChange={(target, isTIROptional) => {
                          this.setState({ isTIROptional });
                        }}
                        type="text"
                        className="form-control"
                        component={SelectField}
                        name="isTIROptional"
                        label="Tir Optional"
                        data={loadFlags}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.props.tab === 1 && customerType.intersect(["ALL", "caller"]).length > 0 && isNewBillingEnabled() && (
                <React.Fragment>
                  <div class="hr-light my-20"></div>
                  <div className="font-medium font-14 mb-15">Load Charge Combination For invoicing</div>
                  <div className="form-row">
                    <div className="col-md-4 d-flex align-items-center mb-10">
                      <div className="form-group new-set-label mb-0 w-150">
                        <Select
                          isClearable = {false}
                          value={LOAD_CHARGE_COMBINATION.find((item) => item.value === this.state.loadChargeCombination ) ?? 'MANUAL'}
                          styles={smallSelectStyle}
                          onChange={(value) => {
                            if(this.state.loadChargeCombination === value.value) return
                            this.setState({loadChargeCombination: value.value});
                          }}
                          options={LOAD_CHARGE_COMBINATION}
                        />
                      </div>
                      {isTerminal && <div className="form-check form-check-primary pr-30 ml-20">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="caller"
                          disabled={isAccountServiceEnabled()??false}
                          onChange={() => this.setState({isSeparateInvoicePerTerminal: !this.state.isSeparateInvoicePerTerminal})}
                          checked={
                            this.state.isSeparateInvoicePerTerminal
                            ? true
                            : false
                          }
                          />
                        <label className="form-check-label" for="caller">
                          Separate Invoices per Terminal
                        </label>
                      </div>}
                    </div>
                  </div>
                </React.Fragment>
              )}
              
              {isNewInvoice() && this.props.tab === 1 && (
               this.renderTerminalPayment()
              )}
              {
                isGlobalizationEnabled() && this.props.tab === 1 && customerType.intersect(["caller", "consignee", "ALL"]).length > 0 && customerType.intersect(["caller", "ALL"]).length > 0 && this.renderTerminalCurrency()
              }
              {this.props.tab === 1 && (this.userBasicSettings?.isDocRequiredToInvoice || checkBillingConfig("isDocRequiredForInvoice")) && customerType.intersect([
                "caller",
                "ALL",
              ]).length > 0 && (
                <div className="form-row">
                <div className="col-md-4">
                  <div className="form-group new-set-label">
                    <h5 className="mt-3">Document Types</h5>
                      <Select
                        isMulti
                        menuPlacement="auto"
                        styles={smallSelectStyle}
                        options={this.state.allCustomerDocTypes}
                        value={
                          this.state.requiredDocList &&
                          this.state.requiredDocList.map((d) => ({
                            label: d,
                            value: d,
                          }))
                        }
                        onChange={(value) => {
                          this.setState({
                            requiredDocList: value.map((d) => d.value),
                          })
                        }}
                      />
                  </div>
                </div>
                </div>
              )}
              { this.props.tab === 1 && (this.state.isConnectEnabled || this.isShiposCarrier) &&
                  <React.Fragment>
                    <div class="hr-light my-20"></div>
                    <div className="font-medium font-14 mb-15">Connect</div>
                    <div className="form-row">
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            menuPlacement="top"
                            onChange={(target, shareLoadNotes) => {
                              this.setState({ shareLoadNotes });
                            }}
                            type="text"
                            className="form-control"
                            component={SelectField}
                            disabled={this.isShiposCarrier}
                            name="shareLoadNotes"
                            label="Share Load Notes"
                            data={loadFlags}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            menuPlacement="top"
                            onChange={(target, shareBillingNotes) => {
                              this.setState({ shareBillingNotes });
                            }}
                            type="text"
                            className="form-control"
                            component={SelectField}
                            disabled={this.isShiposCarrier}
                            name="shareBillingNotes"
                            label="Share Billing Notes"
                            data={loadFlags}
                          />
                        </div>
                      </div>
                    </div>
                </React.Fragment>
              }

              {this.props.tab === 7 && (
                <React.Fragment>
                  <hr />
                  <div className="mb-2">
                    <h4>Email Attachments</h4>
                  </div>

                  <div
                    className={
                      this.state.doc_none ? "mb-2 div-invisible" : "mb-2"
                    }
                  >
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_all"
                        checked={this.state.doc_all ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_all", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_all">
                        ALL
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.doc_none ? "mb-2 div-invisible" : "mb-2"
                    }
                  >
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_tir"
                        checked={this.state.doc_tir ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_tir", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_tir">
                        TIR
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.doc_none ? "mb-2 div-invisible" : "mb-2"
                    }
                  >
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_pod"
                        checked={this.state.doc_pod ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_pod", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_pod">
                        POD
                      </label>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_none"
                        checked={this.state.doc_none ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_none", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_none">
                        NONE
                      </label>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </form>
          </div>
        </div>
        {this.props.tab===3 && (
          checkIfEnableDrawStartingPoint()? 
          <NewGeofenceTab isLoading={this.state.isLoading} Lng={this.state.lng} Lat={this.state.lat} Zoom={this.state.zoom} PolygonData={this.state.polygonData}  selectedCustomer={this.props.Selected_customer} updateData={this.updateData} startingPointLatLong={this.state.startingPointLatLong}/>:
          <GeofenceTab Lng={this.state.lng} Lat={this.state.lat} Zoom={this.state.zoom} PolygonData={this.state.polygonData}  selectedCustomer={this.props.Selected_customer} updateData={this.updateData}/>)}
      
        

        {this.props.tab == 4 && (
          <div className="card my-10">
            <div className="px-30 py-20">
              <div className="d-flex align-items-center mb-20">
                <h5 className="font-16 mb-0">Add Notes</h5>
              </div>
              <div style={{ height: "calc( 100% - 30px )" }}>
                <textarea
                  rows="5"
                  className="form-control"
                  value={this.state.notes}
                  style={{ height: "100%" }}
                  onChange={({ target }) =>
                    this.setState({ notes: target.value })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        )}

        {this.props.tab == 5 && (
          <div className="table-responsive">
            <table className="table table-card table-card--wrap">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Type</th>
                  <th>Time</th>

                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {this.state.audits &&
                  this.state.audits.map((d, index) => {
                    let description;
                    let url;
                    let company_name;

                    if (
                      ["DOCUMENT_UPLOAD", "DOCUMENT_REMOVE"].includes(d.type)
                    ) {
                      url = (
                        <a
                          className="badge badge-info"
                          href={d.data.document && d.data.document.url}
                          target="_blank"
                        >
                          {d.data.document &&  d.data.document.url}
                        </a>
                      );
                      description = !_.isNil(d.data.document && d.data.document.url) && (
                        <p>
                          {d.data.document.type} changed to{" "}
                          <b className="badge badge-info">{url}</b>
                        </p>
                      );
                    }
                    if (d.type === "ADD") {
                      description = "Company Name ";
                      company_name = (
                        <b className="badge badge-soft-warning">
                          {d.data.company_name}
                        </b>
                      );
                    }
                    if (d.type === "UPDATE") {
                      let data;
                      if (
                        index !== this.state.audits.length - 1 &&
                        this.state.audits[index + 1].userId
                      ) {
                        data = this.state.audits[index + 1].data;
                      }
                      const additionalInfo = d.data;
                      let dateFormat = ["officeHoursEnd"];
                      let differanceDescription = differnceFinder(
                        data,
                        additionalInfo
                      );

                      description = Object.keys(differanceDescription || [])
                        .filter(
                          (key1) =>
                            differanceDescription[key1] &&
                            typeof differanceDescription[key1] !== "object"
                        )
                        .map((key1) => {
                          if (dateFormat.includes(key1)) {
                            return (
                              <p>
                                {key1} changed to{" "}
                                <b className="badge badge-soft-warning">
                                  {moment(differanceDescription[key1]).format(
                                    "ddd MMM DD YYYY"
                                  )}
                                </b>
                              </p>
                            );
                          } else {
                            return (
                              !_.isNil(differanceDescription[key1]) && (
                                <p>
                                  {key1} changed to{" "}
                                  <b className="badge badge-soft-warning">
                                    {differanceDescription[key1].toString()}
                                  </b>
                                </p>
                              )
                            );
                          }
                        });
                    }
                    if(['ADDED_CSR', 'REMOVED_CSR'].includes(d.type)){

                      let csrName = ""
                      if(d.data){
                       csrName = d.data.name ? csrName+d.data.name+" " : "";
                       csrName = d.data.lastName ? csrName+d.data.lastName : ""
                      } 
                      const desc = d.type === "ADDED_CSR" ? "New CSR Assigned " : "CSR Removed "
                     description = <p>{desc}   <b className={`badge  ${d.type === "ADDED_CSR" ? "badge-soft-green" : "badge-soft-brown"}`}>{csrName}</b></p>
                   }
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="avatar-circle avatar-xs mr-1">
                              {" "}
                              {d.userId && `${d.userId.name.charAt(0)}${d.userId?.lastName ? d.userId?.lastName.charAt(0) : ""}`}
                            </span>
                            {`${d?.userId?.name}${d.userId?.lastName ? ` ${d.userId?.lastName}` : ""}${d?.adminId ? ` (via PortPro Admin User)` : ""}`}
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-gray-100 badge-sm text-capitalize">
                            {d.type.replaceAll(/_/g, " ").toLowerCase()}
                          </span>
                        </td>

                        <td>
                          <div>
                            {moment(d.createdAt)
                            .tz(getTimeZone({preferred: true}))
                              .format(DateTimeFormatUtils.fullDateFormat())}
                          </div>
                          <div className="text-muted">
                            {moment(d.createdAt)
                            .tz(getTimeZone({preferred: true}))
                              .format("LT")}
                          </div>
                        </td>

                        <td>
                          {description}
                          {company_name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}

        {this.props.tab == 6 && (
          <CustomerAccessManagement
            isCustomerProfile={true}
            CustomerID={this.props.Selected_customer?._id}
            isQuick={this.props.isQuick}
            initialValues={this.props.initialValues}
            selectedCustomer={this.props.Selected_customer}
          />
        )}

        <Modal show={this.state.showFileUploadModal} centered animation={false} className="backdrop_modal_open">
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card p-20 mb-0">
              <ImageUploader
                uploadResult={this.uploadResult}
                type={"image"}
                className="custom-file-input"
              />

              {this.state.documentFile && (
                <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                  <IconUpload className="mr-20" />
                  <div className="w-100">
                    <div className="font-medium d-flex justify-content-between w-100 mb-1">
                      <div>{this.state.documentFile.name}</div>
                      <div className="ml-auto">{this.state.progress}%</div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={`${this.state.progress}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${this.state.progress}%` }}
                      ></div>
                    </div>
                  </div>
                  <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                    <span
                      onClick={() =>
                        this.setState({
                          documentFile: null,
                          imagePreviewUrl: null,
                          pdfPreviewUrl: null,
                          progress: 0,
                          isSaving: true,
                          csvPreviewUrl: null, 
                          xlsPreviewUrl: null
                        })
                      }
                    >
                      {" "}
                      <IconTimes className="" />
                    </span>
                  </button>
                </div>
              )}
              {this.state.documentFile && (
                <div className="dropzone-fileupload d-flex">
                  {this.state.progress == 0 ? (
                    <button className="btn btn-warning btn-circle btn-xs mr-15">
                      <IconInfo />
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-circle btn-xs mr-15">
                      <IconCheck />
                    </button>
                  )}
                  <div className="document-preview document-preview--sm mr-3">
                    {this.state.imagePreviewUrl && (
                      <img src={this.state.imagePreviewUrl} alt={""} />
                    )}
                    {this.state.pdfPreviewUrl && (
                      <canvas id="the-canvas"></canvas>
                    )}
                    {this.state.csvPreviewUrl &&( 
                      <img
                        className="document-preview__image"
                        src="/assets/img/csv.png"
                        alt="csv.png"
                      />
                    )}
                    {this.state.xlsPreviewUrl && (
                      <img 
                        className="document-preview__image" 
                        src="/assets/img/xls.png" 
                        alt="xls.png" 
                      />
                    )}
                  </div>
                  <div className="w-100 d-flex">
                    <div>
                      <div className="font-medium ">
                        {this.state.documentFile.name}
                      </div>
                      <div className="text-muted">
                        {(this.state.documentFile.size * 0.0009765625).toFixed(
                          1
                        )}{" "}
                        kb
                      </div>
                    </div>
                    <div className="w-150 ml-auto">
                      <Select
                        menuPlacement="auto"
                        styles={smallSelectStyle}
                        menuPosition={"fixed"}
                        options={documentType}
                        value={
                          this.state.fileType &&
                          documentType.find(
                            (d) => d.value === this.state.fileType
                          )
                        }
                        onChange={({ value }) =>
                          this.setState({ fileType: value })
                        }
                      />
                    </div>
                  </div>
                  <button className="btn btn-link btn-circle btn-xs ml-3">
                    <span
                      onClick={() =>
                        this.setState({
                          documentFile: null,
                          imagePreviewUrl: null,
                          pdfPreviewUrl: null,
                          progress: 0,
                          isSaving: true,
                          csvPreviewUrl: null, 
                          xlsPreviewUrl: null
                        })
                      }
                    >
                      {" "}
                      <IconTrash />{" "}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                this.setState({ showFileUploadModal: false, isSaving: true, csvPreviewUrl: null, xlsPreviewUrl: null, fileType: "", isLoading: false});
              }}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.isSaving || this.state.isLoading}
              onClick={() => {
                if (this.state.documentFile) {
                  $(".btncustomersave").trigger("click");
                  //   if (this.state.fileType) {
                  //     this.setState({
                  //       showFileUploadModal: false,
                  //       documentFile: null,
                  //       imagePreviewUrl: null,
                  //       pdfPreviewUrl: null,
                  //       progress: 0,
                  //     });
                  //   } else {
                  //     toastr.show("Please select a type.", "warning");
                  //   }
                  // } else {
                  //   this.setState({
                  //     showFileUploadModal: false,
                  //     documentFile: null,
                  //     imagePreviewUrl: null,
                  //     pdfPreviewUrl: null,
                  //     progress: 0,
                  //   });
                } else {
                  toastr.show("Please select file", "error");
                }
              }}
            >
              {this.state.isLoading && (
                <span
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        { this.state.showFullDropScreen && this.props.tab === 2 && !this.state.showFileUploadModal && 
          <FullScreenUploader  uploadResult={this.uploadResult} multi={false} type={"image"}/>
        }
        <Modal show={this.state.addTax} centered animation={false} className="backdrop_modal_open">
          <Modal.Header>
            <Modal.Title>Tax Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddTaxComponent 
              handleSuccess={({option}) => {
                this.props?.fetchTaxCodes && this.props?.fetchTaxCodes()
                this.setState({
                  qbTaxOptions: [option,...this.state.qbTaxOptions]
                })
                this.setState({ addTax: false })
              }}
              initialValue={this.state.taxInputValue}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                this.setState({ addTax: false });
              }}
            >
              Close
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                $(".btncustomertaxsave").trigger("click");
              }}
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

AddCustomerComponent = reduxForm({
  // a unique name for the form
  form: "tmscustomeradd",
  enableReinitialize: true,
  // validate
})(AddCustomerComponent);

function mapStateToProps(state) {
  return {
    Fleetcustomers: state.TmsReducer.Fleetcustomers,
    Terminals: state.HomeReducer.terminals,
    AllTerminals: state.HomeReducer.allTerminals,
    callers: state.TmsReducer.callers,
    customers: state.TmsReducer.customers,
    shippers: state.TmsReducer.shippers,
    consignees: state.TmsReducer.consignees,
    allCustomers: state.TmsReducer.allCustomers,
    chassisTerms: state.TmsReducer.chassisTerms,
    accountingServiceInfo : state.accountingServiceReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreateors, dispatch),
  };
}

AddCustomerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCustomerComponent);
export default AddCustomerComponent;

export const phoneComponent = ({
  input,
  label,
  className,
  meta: { touched, error },
}) => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  return (
    <div>
      <label>{label}</label>
      <div>
        {phoneformat == "international" ? (
          <input
            className={className}
            {...input}
            autoComplete={input.name}
            mask="_"
          />
        ) : (
          <NumberFormat
            className={className}
            {...input}
            autoComplete={input.name}
            format="###-###-####"
            mask="_"
          />
        )}
      </div>
      {touched && error && (
        <span className="error" style={{ color: "red" }}>
          {error}
        </span>
      )}
    </div>
  );
};

const renderField = ({
  input,
  label,
  type,
  required,
  meta: { touched, error, warning },
}) => (
  <div className="form-group new-set-label">
      <label className="label-control d-block">
        {required && <span className="text-danger">*</span>} {label}
      </label>
      <input
        className="form-control"
        {...input}
        placeholder={label}
        type={type}
      />
      {touched &&
        ((error && <span className="text-danger">{error}</span>) ||
          (warning && <span className="text-warning">{warning}</span>))}
    </div>
);

const ReduxFormSelect = ({  input,
  label,
  required,
  options,
  value,
  data,
  isMulti,
  meta: { touched, error, warning }})=>{
    const isManageFleetOrOutSourceObj = isManageFleetOrOutSource()
    let valueData = []
    if(input?.value[0] && input?.value[0]?.label){
      valueData = input.value
    }else {
      valueData = data && data?.filter(d => d.value);
    }
    if(isManageFleetOrOutSourceObj?.isOutSource || checkUserPermisison(['allow_user_to_assign_all_terminals'])){
      let Terminal = options.map((Data) => {
        return {
          value: Data.value,
          label: Data.label,
        };
      });
      const isAllIncluded = valueData?.find(val => val.value === 'ALL');
      valueData = isAllIncluded ? Terminal?.filter(ob => ob.value !== 'ALL') : valueData;
      if (valueData?.length === Terminal?.length - 1) options = []
    }
  return (
    <div className="form-group new-set-label">
    <label className="label-control d-block">
      {required && <span className="text-danger">*</span>} {label}
    </label>
    
    <Select
      {...input}
      menuPosition={"fixed"}
      isMulti={isMulti}
      value={valueData}
      styles={smallSelectStyle}
      onChange={value => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      options={options}
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-warning">{warning}</span>))}
  </div>
 
  )
}

export const required = (value) => {
  if(value || typeof value === "number"){
    return undefined
  }else{
    return "is required*"
  }
};

const validatePercent = (value) => {
  try {
    if(value && (parseFloat(value) > 100 || parseFloat(value) <0) ){
      return "should be in range 0-100"
    }
  } catch(e) {
    return "should be in percent"
  }
  return undefined
};

export const validateWhiteSpace = (value) =>
  value && value.trim() !== "" ? undefined : "is required*";

const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const mobile = (value) => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  let phone = value || null;

  const phoneRegExp = phoneformat == 'international'?  /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/ :  
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

   
  if (phone) {
    phone = phone.replace(/-|_| /g, "");

    if (phone.length < 10) {
      return "Company Phone Number Have Atleast 10 Digits*";
    }
  }
  if(phone && !phoneRegExp.test(phone)){
    return `Invalid Mobile Number with ${phoneformat} format`
  }

  return undefined;
};

const receiverEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)
    ? "Please Enter Valid Receiver Email Address"
    : undefined;
    const quickBookEmail = (value) => {
      if (value) {
        if (value.length > 99) {
          return "should not exceed 99 characters.";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value)) {
          return "Please Enter Valid QuickBooks Email Address.";
        }
      }
      return undefined;
    };
    
const zipcodeNormalize = (value) => value && value.replace(/[^\w\s]/gi, "");
const fieldNormalize = (value) => value && value.replace(/[^[A-za-z ]/gi, "");
const numberNormalize = (value) => value && value.replace(/[^0-9]/gi, "");
const numberalphaNormalize = (value) => value && value.replace(/[^[A-za-z0-9 ]/gi, "");
const floatNormalize = (value) => {
  const data = value?.split(".")
  if(data.length > 2 || data[1]?.length > 2) {
    const front = data[0] || "0"
    const back = data[1]?.slice(0, 2) || "0"
    const finalValue = [front, back].join(".")
    return parseFloat(finalValue && finalValue.replace(/[^0-9.]/gi, "")).toFixed(2)
  }
  return value && value.replace(/[^0-9.]/gi, "")
};

const documentType = [
  { value: "Customer Contract", label: "CUSTOMER CONTRACT" },
  { value: "Rate Confirmation", label: "RATE CONFIRMATION" },
  { value: "Email", label: "Email" },
  { value: "Other", label: "Other" },
  { value: "Credit App", label: "Credit App" },
  { value: "Credit Report", label: "Credit Report" },
  { value: "Business License", label: "Business License" },
];