import { SUPPORT_MOVE_EVENTS } from "../constant";

export const statusType = {
    "PULLCONTAINER": "Pickup Container",
    "RETURNCONTAINER": "Return Container",
    "DELIVERLOAD": "Deliver Container",
    "DROPCONTAINER": "Drop Container",
    "HOOKCONTAINER": "Hook Container",
    "CHASSISPICK": "Hook Chassis",
    "CHASSISTERMINATION": "Terminate Chassis",
    'STOPOFF': 'Stop Off',
    "LIFTOFF":"Lift Off",
    "LIFTON":"Lift On",
    "CHASSISTRANSFER": "Chassis Transfer",
    "BOBTAILTRANSFER": "Bobtail Transfer",
    "BOBTAIL_ENDED": 'Bobtail End',
    "BOBTAIL_STARTED": 'Bobtail Start',
    "DROPCHASSIS": 'Drop Chassis'
};

export const ROUTING_DRAG_LEGS = [
    { leg: 'CHASSISPICK', legTitle: 'Hook Chassis' },
    { leg: 'PULLCONTAINER', legTitle: 'Pickup Container' },
    { leg: 'DELIVERLOAD', legTitle: 'Deliver Container' },
    { leg: 'RETURNCONTAINER', legTitle: 'Return Container' },
    { leg: 'DROPCONTAINER', legTitle: 'Drop Container' },
    { leg: 'STOPOFF', legTitle: 'Stop Off'},
    { leg: 'LIFTOFF', legTitle: 'Lift Off' },
    { leg: 'CHASSISTERMINATION', legTitle: 'Terminate Chassis' },
    { leg: 'COMPLETED', legTitle: 'Completed' },
];
export const ROUTING_SUPPORT_DRAG_LEGS = [
  { leg: 'CHASSISPICK', legTitle: 'Hook Chassis', isSupportMove: true },
  { leg: 'DROPCHASSIS', legTitle: 'Drop Chassis', isSupportMove: true },
  { leg: 'CHASSISTERMINATION', legTitle: 'Terminate Chassis', isSupportMove: true }
]
export const PLANNER_SUPPORT_DRAG_LEGS = [
  { leg: 'BOBTAILTRANSFER', legTitle: 'Bobtail', isSupportMove: true },
  { leg: 'BOBTAILTOHOOK', legTitle: 'Bobtail to Hook Chassis', isSupportMove: true },
  { leg: 'CHASSISPICK', legTitle: 'Hook Chassis', isSupportMove: true },
  { leg: 'CHASSISTERMINATION', legTitle: 'Terminate Chassis', isSupportMove: true },
  { leg: 'DROPCHASSIS', legTitle: 'Drop Chassis', isSupportMove: true }
]

export const SUPPORT_MOVE_TO_ROUTING = {
  'CHASSIS_STARTED': 'CHASSISTRANSFER',
  'DROPCHASSIS': 'DROPCHASSIS',
  'BOBTAIL_STARTED': 'BOBTAILTRANSFER',
  'CHASSISPICK': 'CHASSISPICK',
  'CHASSISTERMINATION': 'CHASSISTERMINATION',
  'BOBTAILTOHOOK': 'BOBTAILTOHOOK',
}

export const SUPPORT_MOVE_LEG_EVENTS = [
  SUPPORT_MOVE_TO_ROUTING.CHASSISPICK,
  SUPPORT_MOVE_EVENTS.CHASSISTERMINATION,
  SUPPORT_MOVE_EVENTS.DROPCHASSIS,
  SUPPORT_MOVE_EVENTS.BOBTAILTRANSFER
]

export const ROUTING_STATUS = Object.freeze({
    ENROUTE: "ENROUTE",
    DEPARTED:"DEPARTED",
    ARRIVED: "ARRIVED",
    UNASSIGNED: "UNASSIGNED",
    NONE:"NONE",
    DRIVER_NAME:"DRIVER NAME",
    LOAD_ASSINGED_DATE:"LOAD ASSIGNED DATE",
    VOID_OUT:"VOID OUT"
});

export const DRIVER_ORDER_VALIDATION = {
    PULLCONTAINER: {
      required: true,
      prevEvents: ["PULLCONTAINER", "CHASSISPICK", "STOPOFF", "HOOKCONTAINER", "LIFTON"],
      atLoadStart: true,
      atMoveStart: true
    },
    DELIVERLOAD: {
      required: true,
      prevEvents: ["PULLCONTAINER", "LIFTON", "HOOKCONTAINER", "STOPOFF", "DELIVERLOAD"],
      atLoadEnd: true,
    },
    DROPCONTAINER: {
      prevEvents: [ "PULLCONTAINER", "LIFTON", "HOOKCONTAINER", "STOPOFF", "DELIVERLOAD" ],
      atLoadEnd: true,
      isMoveSeparator: true,
      relatedEvent: "HOOKCONTAINER",
    },
    HOOKCONTAINER: {
      prevEvents: ["DROPCONTAINER"],
      relatedEvent: "DROPCONTAINER",
      atMoveStart: true
    },
    RETURNCONTAINER: {
      required: true,
      prevEvents: ["DELIVERLOAD", "LIFTON", "HOOKCONTAINER", "PULLCONTAINER", "STOPOFF"],
      atLoadEnd: true,
    },
    CHASSISPICK: {
      prevEvents: ["LIFTOFF", "CHASSISTERMINATION"],
      atLoadStart: true,
      atMoveStart: true
    },
    CHASSISTERMINATION: {
      prevEvents: ["RETURNCONTAINER", "LIFTOFF", "STOPOFF", "CHASSISPICK"],
      isMoveSeparator: true,
      atLoadEnd: true,
    },
    LIFTOFF: {
      prevEvents: ["PULLCONTAINER", "DELIVERLOAD", "HOOKCONTAINER", 'STOPOFF', "LIFTON"],
      atLoadEnd: true,
      isMoveSeparator: true,
      relatedEvent: "LIFTON",
    },
    LIFTON: {
      prevEvents: ["LIFTOFF", "CHASSISPICK", "CHASSISTERMINATION", "STOPOFF"],
      relatedEvent: "LIFTOFF",
      atMoveStart: true
    },
    STOPOFF: {
      prevEvents: [...Object.keys(statusType)],
    }
}; 

export const containerPositionConst = {
  FRONT: "FRONT",
  BACK: "BACK",
}