import _ from "lodash";
import React, { useState } from "react";
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts";
import { IconDot } from "../../../../../../Components/Common/Icons";
import { COLORS, HOVER_COLORS, ICON_COLORS, REPORT_TYPES } from "../../constants";
import Popup from "../Popup";
import StackedBarChartToolTip from "./StackedBarChartToolTip";
import { getDataKeysForStackedBarChart } from "../../helper";

const CustomLabel = (props) => {
    const { x, y, value, width, height } = props;
    return (
        <Text
            x={x + width + 5}
            y={y + height / 2}
            fill='#000'
            verticalAnchor="middle"
        >
            {value}
        </Text>
    );
};

const StackedBarChart = (props) => {
    const { 
        data, 
        isPopup, 
        setIsPopup, 
        keysForStackedBarChart, 
        reportType,
        startDate,
        endDate,
        downloadCSV
    } = props;
    const dataKeys = getDataKeysForStackedBarChart(data?.length > 0 ? data : [], keysForStackedBarChart);
    const lastKey = _.cloneDeep(dataKeys)?.pop();
    const roundedMaxDataValue = data && data?.length > 0
        ? Math.ceil(Math.max(...data?.map(d => Object.values(d).slice(1).reduce((a, b) => a + b, 0))) / 10) * 10
        : 0;

    const [activeBar, setActiveBar] = useState({ barIndex: null, stackIndex: null, key: null });

    const getFillColors = (stackIndex, key, barIndex) => {
        let fillColor = COLORS[stackIndex % COLORS.length];
        const stackFillColors = {
            Pulled: '#9E8FFA',
            Delivered: '#447780',
            Returned: '#84C46C',
            Dropped: '#3778B6',
        }

        const hovredStackFillColors = {
            Pulled: '#d4cefd',
            Delivered: '#bcd7dc',
            Returned: '#c2e2b6',
            Dropped: '#b0cde8',
        }

        if (activeBar.barIndex === barIndex && activeBar.stackIndex === stackIndex && activeBar.key === key) {
            if(reportType === REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER) return stackFillColors[key]
            fillColor = COLORS[stackIndex % COLORS.length];
        } else if (activeBar.barIndex !== null) {
            if(reportType === REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER) return hovredStackFillColors[key]
            fillColor = HOVER_COLORS[stackIndex % HOVER_COLORS.length];
        }
        return fillColor;
    };

    const handleStackedBarClick = () => {
       setIsPopup(true)
    };
    const handleOnCloseOfPopup = () => {
        setIsPopup(false)
    }

    const handleMouseEnter = (data, barIndex, stackIndex, key) => {
        setActiveBar({ barIndex, stackIndex, key });
    };

    const handleMouseLeave = () => {
        setActiveBar({ barIndex: null, stackIndex: null, key: null });
    };

    const CustomYAxisTick = ({ x, y, payload }) => {
        const label = payload.value.length > 10 ? `${payload.value.slice(0, 10)}...` : payload.value;

        return (
            <text
                x={x}
                y={y}
                dy={4}
                textAnchor="end"
                style={{
                    fontSize: '12px',
                    fill: 'currentColor',
                    whiteSpace: 'nowrap',
                    overflow: 'visible',
                    textOverflow: 'ellipsis',

                }}
            >
                {label}
            </text>
        );
    };

    return (
            <>
                <ResponsiveContainer height={'50%'} maxHeight={"100%"} maxWidth={"100%"} className="flex-grow-1 px-20">
                    <BarChart
                        data={data}
                        layout="vertical"
                >
                    <XAxis type="number"
                        tickLine={false}
                        domain={[0, roundedMaxDataValue]}
                        ticks={[0, roundedMaxDataValue]}
                        tickFormatter={(value) => (value === 0 || value === roundedMaxDataValue) ? parseFloat(value?.toFixed(2)) : ''}
                        padding={{ left: 1.8 }}
                    />
                    <YAxis type="category" 
                        dataKey="name" 
                        tick={<CustomYAxisTick />} 
                        tickLine={false} 
                        interval={0}  
                    />
                    <Tooltip 
                        cursor={{ fill: 'transparent' }} 
                        content={<StackedBarChartToolTip keysForStackedBarChart={keysForStackedBarChart}/>} 
                        shared={false}
                    />
                    {dataKeys?.map((key, index) => (
                        <Bar
                            key={key}
                            dataKey={key}
                            stackId="a"
                            // radius={key === lastKey ? [0, 5, 5, 0] : ''}
                            onMouseEnter={(e, barIndex) => handleMouseEnter(data[barIndex], barIndex, index, key)}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleStackedBarClick}
                            style={{ stroke: '#F0F0F0', strokeWidth: 2 }}

                        >
                            {data.map((entry, cellIndex) => (
                                <Cell
                                    key={`cell-${index}-${cellIndex}`}
                                    fill={getFillColors(index, key, cellIndex)}

                                />
                            ))}
                            {key === lastKey && <LabelList content={<CustomLabel />} />}
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>
            <div className="pb-2">
                <div className="d-flex gap-10 align-items-center">
                {dataKeys?.map((key, index) => {
                    const sum = data.reduce((total, item) => total + (item[key] || 0), 0);
                    return (
                        <React.Fragment key={key}>
                            <div className="d-flex align-items-center">
                                <div><IconDot className={ICON_COLORS[index % ICON_COLORS.length]} /></div>
                                <div className="font-12 font-weight-normal text-dark">{key}</div>
                                <div className="badge bg-gray-50 font-12 ml-2 badge-pill">
                                    {sum}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
                </div>
            </div>
            {isPopup &&
                <Popup
                    rows={data}
                    reportType={reportType}
                    isPopup={isPopup}
                    handleOnClose={handleOnCloseOfPopup}
                    fromDate={startDate}
                    toDate={endDate}
                    downloadCSV={downloadCSV}
                />}
        </>
    )
}

export default StackedBarChart;
