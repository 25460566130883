export const AllFieldState = {
    temperature: "",
    doNo: "",
    callerbillLandingNo: "",
    sealNo: "",
    deliveryOrderNo: "",
    releaseNo: "",
    callerPONo: "",
    shipmentNo: "",
    returnNo: "",
    secondaryReferenceNo: "",
    purchaseOrderNo: "",
    appointmentNo: "",
    reservationNo: "",
    bookingNo: "",

    containerNo: "",
    containerSize: "",
    containerType: "",
    containerOwner: "",
    chassisId: "",
    chassisNo: "",
    chassisSize: "",
    chassisType: "",
    chassisOwner: "",
    routeType: "",
    genset: "",
    scac: "",
    hazmat: false,
    hot: false,
    liquor: false,
    overHeight: false,
    overWeight: false,
    isGenset: false,
    billingNote: "",
    officeNote: "",
    cutOff: "",
    loadTime: "",
    return: "",
    containerAvailableDay: "",
    vessel: "",
    lastFreeDay: "",
    dischargedDate: "",
    emptyDay: "",
    freeReturnDate: "",
    outgateDate: "",
    ingateDate: "",
    readyToReturnDate: "",
    items: []
}

export const mappingFields = [
    'officeNote', 
    'callerbillLandingNo', 
    'shipmentNo', 
    'bookingNo', 
    'doNo', 
    'sealNo', 
    'deliveryOrderNo', 
    'releaseNo', 
    'secondaryReferenceNo', 
    'purchaseOrderNo', 
    'callerPONo', 
    'appointmentNo', 
    'returnNo', 
    'dischargedDate', 
    'outgateDate', 
    'freeReturnDate', 
    'emptyDay', 
    'ingateDate', 
    'readyToReturnDate', 
    'vesselETADate',
    'lastFreeDay',
    'containerNo',
    'genset',
    'temperature',
    'routeType',
    'scac',
    'hazmat',
    'overWeight',
    'liquor',
    'hot',
    'isGenset',
    'totalWeight',
    'items',
    'loadtime',
    'containerAvailableDay',
    'cutOff',
    'returnDate'
]

export const profileFields = [
    'caller',
    'emptyOrigin',
    'terminal',
    'containerSize',
    'containerType',
    'containerOwner',
    'chassisNo',
    'chassisSize',
    'chassisType',
    'chassisOwner'
]


export const ocrMappingFields = [
    "shipper",
    "consignee",
    "emptyOrigin",
    "containerSize",
    "containerType",
    "containerOwner",
    "caller",
    "terminal"
  ] 

export const importFields = ['vesselETADate', 'lastFreeDay', 'dischargedDate', 'emptyDay', 'freeReturnDate'];
export const exportFields = ['loadTime', 'containerAvailableDay', 'cutOff', 'return'];

export const loadRefNumbersRegex = /^[A-Za-z0-9-\.\,\/\:\#\&\@\(\)\"\'\s]{3,35}$/;
