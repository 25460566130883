import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import { Component } from "react";
import IdleTimer from "react-idle-timer";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { browserHistory, IndexRoute, Route, Router } from "react-router";
import { bindActionCreators } from "redux";
import {
  asyncActionNames,
  buildAsyncActions,
} from "./Components/Common/GlobalActionCreators";
import Loader from "./Components/Loader";

import Customers from "./pages/tms/Customer";
import { getMainTheme } from "./pages/tms/Profile/actionCreators";
import themesOptions from "./pages/tms/Profile/themeOptions";
import {
  allowedRoutes,
  changeTheme,
  checkIfPerDiemEnabled,
  clearStorage,
  getAccountCurrencies,
  getAccountingServiceInfo,
  getAllExchangeRate,
  getBasicSettings,
  getCarrierId,
  getConfigForGlobalFlag,
  getEmbeddedEmailAccount,
  getPortMarkets,
  getStorage,
  getSyncConfigSettings,
  getUserAddressFormat,
  getUserDateTimeFormat,
  isAccountServiceEnabled,
  isCarrierTariffEnabled,
  isConnectedWithOutSource,
  isCreditMemo,
  isCustomerAccount,
  isDriverTariffEnabled,
  isEmbeddedEmailEnabled,
  isISOTypeEnabled,
  isTripEnabled,
  isManageFleetOrOutSource,
  isNewAccountReceivable,
  isNewBillingEnabled,
  isNewDeduction,
  isShiposCarrier,
  isSyncRequest,
  isVendorPayAndBillsEnabled,
  removeItem,
  setStorage,
  supportMoveEnabled,
  IsELDEnabled,
  isGlobalizationEnabled,
  checkUserPermisison,
  isAdminCustomerPortalPermission,
  clearGivenCache,
  cacheURLKeys,
  isNewSettingsEnabled,
  showForTerminal,
  isChartsEnabled,
  isCabotageEnabled,
  isDualTransactionEnabled,
  isSuperAdminUser
} from "./services/Common.services";
import { HTTP } from "./services/Http.service";
import { sendPageChangeDataToGAnalytic } from "./utils/googleAnalytics";


import { withAuth0 } from "@auth0/auth0-react";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import { isDakosySlotsEnabled, isDefaultEmailFrom, isFlagEnabled, isNewCustomerRateEnabled } from "./Components/Common/functions";
import { AuditTypeEnum, NEW_CUSTOMER_ROUTES_ROLES } from "./pages/tms/constant";
import { titleCustomersOrOrgs } from "./pages/tms/Load/utility.js";
import * as commonActionCreators from './utils/actionCreator';


const loadable = (loader) => {
  return Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });
};

const actionNames = asyncActionNames("LOGIN");
const actionCreators = buildAsyncActions(actionNames);

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acl: null,
      permissions: null,
      user: null,
      userLoggedIn: false,
      isTimedOut: false,
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.idleHandler = this.idleHandler.bind(this)
  }

  _onAction(e) {
    const timezone = getStorage("timeZone");
    setStorage("isTimedOut", JSON.stringify(timezone ? moment().tz(timezone) : moment()))
  }

  _onActive(e) {
  }

  checkSessionTimeoutActive(data) {
    let carrierDetail = data?.carrier ?? JSON.parse(getStorage("carrierDetail"));
    let currentUserRole = data?.role ?? getStorage("currentUserRole");
    const enabledRoles = ["carrier", "fleetmanager"]
    return carrierDetail && carrierDetail?.strictSessionPolicy && enabledRoles.includes(currentUserRole)
  }
  idleHandler(clientOnly) {
    let carrierDetail = JSON.parse(getStorage("carrierDetail"));
    const prevTime = moment(JSON.parse(getStorage("isTimedOut")));
    const timezone = getStorage("timeZone");

    const currentTime = timezone ? moment().tz(timezone) : moment()
    const diff = moment.duration(
          currentTime.diff(prevTime)
        ).asMilliseconds()

    const isTimedOut = diff > (carrierDetail?.strictSessionTimeout ?? 1800000);
    if (isTimedOut && this.checkSessionTimeoutActive()) {
        if(clientOnly) {
          clearStorage()
        }else{
          this.props.commonActions.logoutUser();
        }

    }
  }
  _onIdle() {
    this.idleHandler(false);
    if(this.idleTimer)
      this.idleTimer?.reset();
  }

  isRouteAuthenticated = () => {
    const paths = [
      "/",
      "/sign-up",
      "/administrator",
      "/getresetToken",
      "/forget",
      "/verify",
      "/setup",
      "/sso",
    ];
    let path = "/" + window.location.pathname.split("/")[1];

    return !paths.includes(path)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(
      this.props?.user?.profile &&
      this.checkSessionTimeoutActive({
        role: this.props?.user?.profile?.role,
      })) {
        this.idleTimer?.start()
        const timezone = getStorage("timeZone");
        setStorage("isTimedOut", JSON.stringify(timezone ? moment().tz(timezone) : moment()))
    }
  }
  componentDidMount() {
    this.idleTimer?.start()
    if(!isAdminCustomerPortalPermission()) getConfigForGlobalFlag();
    if(this.checkSessionTimeoutActive()) {
      const timezone = getStorage("timeZone");
      setStorage("isTimedOut", JSON.stringify(timezone ? moment().tz(timezone) : moment()))
    } else {
      this.idleTimer?.pause()
    }

    window.addEventListener("sessionTimeout", () => {
      if(!this.checkSessionTimeoutActive()) {
        this.idleTimer?.pause()
      } else {
        this.idleTimer?.resume()
        const timezone = getStorage("timeZone");
        setStorage("isTimedOut", JSON.stringify(timezone ? moment().tz(timezone) : moment()))
      }
    })
    window.onstorage = () => {
      const isTimedOut = JSON.parse(getStorage("sessionEnd"))
      if (
        isTimedOut === true
      ) {
        if(this.isRouteAuthenticated()){
          window.location.reload()
          removeItem("sessionEnd")
        }
        return;
      }

      const loggedInUser = JSON.parse(getStorage("loggedInUser"));
      if (!loggedInUser) {
        if(this.isRouteAuthenticated())
          browserHistory.push("/");
      }
    }
  }

  componentWillMount() {
    removeItem("sessionEnd")
    this.idleHandler(true)
    changeTheme();
    const script = document.createElement("script");
    script.src = "/lib/MovingMarker.js";
    script.async = true;

    const script2 = document.createElement("script");
    script2.src = "/lib/leaflet-routing-machine.min.js";
    script2.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    const self = this;
    const userToken = getStorage("token");
    const timeZone = getStorage("timeZone");
    if (timeZone) {
      moment().tz(getStorage("timeZone"));
    }
    this.getPortMarkets();

    if (userToken && JSON.parse(getStorage("loggedInUser"))?.role !== "admin") {
      this._getThemeSetting();
      clearGivenCache(cacheURLKeys().getUser).then(() => {
        HTTP("get", "getUser", null, {
          Authorization: getStorage("token"),
        }).then((result) => {
          if (result.data.statusCode === 200) {
            let user = result.data.data.user;
            this.props.dispatch(actionCreators.success(user));
            const loggedInUser = _.cloneDeep(user);
            delete loggedInUser?.groupedProfiles;
            delete loggedInUser?.groupedCities;
            setStorage("loggedInUser", JSON.stringify(loggedInUser));
            self.setState({ user, permissions: user.permissions });
            if (!timeZone) {
              getTimeZone(user._id);
            }
          }
        });
      })

      getBasicSettings().then((res) => {
        getSyncConfigSettings().then(() => { }).catch(err => console.log("err", err));
        if(isEmbeddedEmailEnabled()) getEmbeddedEmailAccount()
      }).catch((error) => console.error(error));
      getUserDateTimeFormat();
      getUserAddressFormat();
      getAccountCurrencies();
      getAllExchangeRate();
      if(isAccountServiceEnabled()){
        getAccountingServiceInfo().then((res)=>{
          if(res) this.props.dispatch({ type: 'ACCOUNTING_SERVICE_COMPANYINFO', payload: res });
        })
      }
      
    }
    let loggedInUser = JSON.parse(getStorage("loggedInUser"));
    if (loggedInUser) this.setState({ acl: loggedInUser.acl });
    this.unlisten = browserHistory.listen((location, action) => {
      sendPageChangeDataToGAnalytic()
      try {
        if (location && location.query && location.query.reference_number) {
          document.title = `#${location.query.reference_number} `;
        } else {
          document.title = `PortPro: Drayage TMS `;
        }
      } catch (ex) {
        document.title = `PortPro: Drayage TMS `;
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  _getThemeSetting(){
    let carrierId=getCarrierId();
    getMainTheme ({carrierId:carrierId})
      .then((res) => {
        if(res){          
          setStorage("themeSetting", JSON.stringify(res));
          res.themeName && changeTheme(themesOptions.find(theme=>theme.name===res.themeName));
        }
      })
      .catch((err) =>{
        changeTheme();
        console.log(err)
      });
  }
  getPortMarkets() {
    this.props.actions.getPortMarkets();
  }

  authorize = (roles) => {
    let self = this;
    const customerPages = [
      "/customer-shipments",
      "/loads",
      "/tms/account-receivable",
      "/tms/at-yard-container-loads",
      "/tms/customer-service/load",
      "/tms/customer-service/Billing",
      "/tms/report/agingChart",
      "/tms/report/report1",
      "/tms/report/report2",
      "/tms/report/report3",
      "/tms/report/report4",
      "/tms/report/revenue-report",
      "/tms/report/driver-pay-report",
      "/tms/report/driver-pay-summary-report",
      "/tms/report/lfd-report",
      "/tms/report/return-report",
      "/tms/report/revenue",
      "/tms/report/aging",
      "load-quotes",
      "/tms/customer-load-quote",
      "/tms/report/load-master-report",
      "/tms/report/aging1",
    ];
    const customerEmployeePages = [
      "/tms/customer-service/load",
      "/tms/customer-service/Billing",
      "/tms/customer-load-quote"
    ];
    const userToken = getStorage("token");
    const user =
      getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser")); 

    if(
      NEW_CUSTOMER_ROUTES_ROLES.includes(roles[0]) && !isNewCustomerRateEnabled()
    ) return browserHistory.push("/nopermission");

    if (
      roles?.includes(AuditTypeEnum.EMAIL_AUDIT_REPORT) &&
      !isCustomerAccount() &&
      !isFlagEnabled(AuditTypeEnum.IS_EMAIL_AUDIT_ENABLED) &&
      isDefaultEmailFrom()
    ) return browserHistory.push("/nopermission");

    if (userToken && user) {
      // HTTP("get", "getUser", null, {
      //   Authorization: getStorage("token")
      // }).then(result => {
      //   if (result.data.statusCode === 200) {
      this.props.dispatch(actionCreators.success(user));
      // setStorage("loggedInUser", JSON.stringify(user));
      !user.permissions.includes('container-tracking') && user.permissions.push('container-tracking')
      self.setState({ user, permissions: user.permissions });
      var allow = false;
      let permissions = this.state.permissions || [];
      let loggedInUser = user;
      if (loggedInUser) {
        permissions = loggedInUser.permissions || [];
      }

      if (roles && loggedInUser) {
        isShiposCarrier() && !allowedRoutes.includes(roles[0]) && browserHistory.push("/upgrade")
        roles.map((x) => {
          if (permissions.includes(x)) {
            allow = true;
          }
          return;
        });
        if (loggedInUser.role === "carrier") allow = true;

        if (
          loggedInUser.role === "customer" &&
          customerPages.indexOf(window.location.pathname) >= 0
        ) {
          allow = true;
        }
        if(
          (loggedInUser.role === "fleetmanager" && loggedInUser.fleetManager.isCustomer) &&
          customerEmployeePages.indexOf(window.location.pathname) >= 0
        ){
          allow = true;
        }
        if (
          !permissions.length &&
          ["fleetmanager"].includes(loggedInUser.role)
        ) {
          if (
            loggedInUser.fleetManager &&
            !loggedInUser.fleetManager.isCustomer
          ) {
            browserHistory.push("/onboarding");
            return;
          }
        }

        if (!allow) browserHistory.push("/nopermission");
      }
      //   }
      // });
    }
  };

  setPageTitle = (title) => {
    const loggedInUser = getStorage("loggedInUser");
    const paths = [
      "/",
      "/sign-up",
      "/administrator",
      "/getresetToken",
      "/forget",
      "/verify",
      "/setup",
      "/sso",
    ];

    let path = "/" + window.location.pathname.split("/")[1];
    // if (!loggedInUser && !paths.includes(window.location.pathname)) {
    if (!loggedInUser && !paths.includes(path)) {
      browserHistory.push("/");
    }

    let val = title ? title + " | " : "";
    document.title = val + "PortPro: Drayage TMS ";
  };

  render() {
    return (
      <>
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        // crossTab={true}
        timeout={1000 * 60} />
      <Router history={browserHistory}>
        <Route
          path="/"
          component={loadable(() => import("./Components/Home/Home"))}
          onEnter={() => {
            this.setPageTitle("Home");
          }}
        >
          <IndexRoute
            component={loadable(() => import("./Components/Login/Login"))}
            onEnter={() => {
              this.setPageTitle("Login");
            }}
          />

          {/* ----------------- signup start --------------- */}
          <Route
            exact
            path="/sign-up"
            component={loadable(() => import("./Components/SignUp/SignUp"))}
            onEnter={() => {
              this.setPageTitle("Sign Up");
            }}
          />
          {/* <Route
            path="/sign-up/payment-verify/:userId"
            component={loadable(() => import("./Components/SignUp/SignUp"))}
            onEnter={() => {
              this.setPageTitle("Verify Payment");
            }}
          /> */}
          <Route
            path="/verify"
            component={loadable(() => import("./Components/SignUp/VerifyOTP"))}
            onEnter={() => {
              this.setPageTitle("Verify Otp");
            }}
          />
          <Route
            path="/registerEmployee"
            component={loadable(() =>
              import("./Components/SignUp/FleetManager")
            )}
            onEnter={() => {
              this.setPageTitle("Register Employee");
            }}
          />

          {/* ----------------- signup end --------------- */}

          {/* ---------------calender start -------------------- */}
          <Route
            path="/calendar"
            component={loadable(() => import("./pages/calendar"))}
            onEnter={() => {
              this.setPageTitle("Calendar");
            }}
          />
          {/* --------------- calender end -------------- */}

          {/* ---------------onboarding start -------------------- */}
          <Route
            path="/onboarding"
            component={loadable(() => import("./pages/OnboardingNew"))}
            onEnter={() => {
              this.setPageTitle("Onboarding");
            }}
          />
          {/* --------------- onboarding end -------------- */}

          <Route
            path="/account/"
            component={loadable(() =>
              import("./pages/trucker/EditProfileTrucker")
            )}
            onEnter={() => {
              this.setPageTitle("Account");
            }}
          >
            <Route
              path="/settings/general-info"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/CompanyInfo")
              )}
              onEnter={() => {
                this.authorize(["settings_general_info"]);
                this.setPageTitle("Company Info");
              }}
            />
            <Route
              path="/settings/notifications"
              component={loadable(() => {
                return import("./pages/tms/Settings/Notifications")
              }
                
              )}
              onEnter={() => {
                this.setPageTitle("Notifications");
              }}
            />  
            <Route
              path="/settings/notifications#records"
              component={loadable(() => {
                return import("./pages/tms/Settings/Notifications")
              }
              )}
              onEnter={() => {
                this.setPageTitle("Notifications Records");
              }}
            />

            <Route
              path="/settings/notifications#template-group"
              component={loadable(() => {
                return import("./pages/tms/Settings/Notifications")
              }
              )}
              onEnter={() => {
                // this.authorize(["notifications"]);
                this.setPageTitle("Notifications Records");
              }}
            />  
            <Route
              path="/settings/notifications#templates"
              component={loadable(() =>{
                return import("./pages/tms/Settings/Notifications")
              }
              )}
              onEnter={() => {
                this.setPageTitle("Notifications Tempates");
              }}
            />
            <Route
              path="/settings/notifications#domain"
              component={loadable(() => {
                return import("./pages/tms/Settings/Notifications")
              }
              )}
              onEnter={() => {
                // this.authorize(["notifications"]);
                this.setPageTitle("Notifications Records");
              }}
            />
            <Route
              path="/account/email-configuration/domain-configuration"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/EmailConfig")
              )}
              onEnter={() => {
                this.authorize(["settings_email_configuration"]);
                this.setPageTitle("Domain Configuration");
              }}
            />
            <Route
              path="/account/email-configuration"
              component={loadable(() =>
                import("./pages/tms/Settings/EmailConfiguration")
              )}
              onEnter={() => {
                this.authorize(["settings_email_configuration"]);
                this.setPageTitle("Email Configuration");
              }}
            />
            <Route
              path="/settings/embedded-email/rules"
              component={loadable(() =>{
                if(isEmbeddedEmailEnabled()){
                  return import("./pages/tms/Settings/EmbeddedEmail")
                }
                else{
                  return import("./pages/NoMatchpage")
                }
              }
              )}
              onEnter={() => {
                this.authorize(["embedded_email"]);
                this.setPageTitle("Rules");
              }}
            />
            <Route
              path="/account/DocumentConfig"
              component={loadable(() =>
                import("./pages/tms/DocumentConfiguration/DocumentConfig")
              )} 
              />
              <Route           
              path="/settings/developer-settings"
              component={loadable(() =>
                import("./pages/tms/Settings/DeveloperSetting")
              )}
              onEnter={() => {
                this.authorize(["settings_developer"]);
                this.setPageTitle("Developer Settings");
              }}
            />
              <Route           
              path="/settings/my-apps"
              component={loadable(() =>
                import("./pages/tms/Settings/MyApps")
              )}
              onEnter={() => {
                this.authorize(["settings_my_apps"]);
                this.setPageTitle("My Apps");
              }}
            />
            <Route
              path="/settings/my-apps/connect-eld"
              component={loadable(() =>
                import("./pages/tms/Settings/MyApps")
              )}
              onEnter={() => {
                this.authorize(["settings_my_apps"]);
                this.setPageTitle("My Apps");
              }}
            />
             <Route                      
              path="/settings/embedded-email/tags"
              component={loadable(() =>{
                if(isEmbeddedEmailEnabled()){
                  return import("./pages/tms/Settings/Tags")
                } else {
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.authorize(["embedded_email"]);
                this.setPageTitle("Emails Tags Settings")}}
            />
             <Route                      
              path="/settings/embedded-email/mail-box"
              component={loadable(() =>{
                if(isEmbeddedEmailEnabled()){
                  return import("./pages/tms/Settings/EmbeddedEmail")
                } else {
                  return import("./pages/NoMatchpage")
                }  
              })} 
              onEnter={() => {
                this.authorize(["embedded_email"])
                this.setPageTitle("Embedded Settings")}}
            />

            <Route
              path="/account/document-setup"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/InvoiceSettings")
              )}
              onEnter={() => {

                this.authorize(["settings_invoice_setup"]);
                this.setPageTitle("Invoice Setup");
              }}
            />
            <Route
              path="/account/accounting-preference"
              component={loadable(() =>
                import(
                  "./pages/trucker/EditProfileTrucker/AccountingPreference"
                )
              )}
              onEnter={() => {
                this.setPageTitle("Accounting Preference");
              }}
            />
            <Route
              path="/account/equipment-setting"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/EquipmentSetting")
              )}
              onEnter={() => {
                this.setPageTitle("Equipment Setting");
              }}
            />
            <Route
              path="/account/equipment-parameters"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/EquipmentParameters")
              )}
              onEnter={() => {
                this.authorize(["settings_equipment_params"]);
                this.setPageTitle("Equipment parameters");
              }}
            />

            <Route
              path="/settings/connect-configuration"
              component={loadable(() =>{
                if(isSyncRequest())
                  return import("./pages/tms/Settings/ConnectConfiguration/index")
                else
                  return import("./pages/NoMatchpage")
              })}
              onEnter={() => {
                this.authorize(["settings_connect_configuration"]);
                this.setPageTitle("Connect Configuration");
              }}
            />

            <Route
              path="/account/geo-fence"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/GeoFence")
              )}
              onEnter={() => {
                this.setPageTitle("Geo Fence");
              }}
            />
          </Route>

          <Route
            path="/company-settings/account-setup/terminals"
            component={loadable(() => {
              if(isNewSettingsEnabled() && showForTerminal() && getStorage("currentUserRole") === "carrier") return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Terminals");
            }}
          />
          <Route
            path="/company-settings/account-setup/document-designer"
            component={loadable(() =>{
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_invoice_setup"]);
              this.setPageTitle("Document Designer");
            }}
          />

          <Route
            path="/company-settings/account-setup/groups"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_group_settings"]);
              this.setPageTitle("Group Settings");
            }}
          />

          <Route
            path="/company-settings/account-setup/groups/cities"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_group_settings"]);
              this.setPageTitle("Group Cities Settings");
            }}
            />
            <Route
              path="/company-settings/account-setup/cabotage"
              component={loadable(() => {
                if (isNewSettingsEnabled() && isCabotageEnabled()) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
                else return import("./pages/NoMatchpage")
              }
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Group Settings");
              }}
            />
          <Route
            path="/company-settings/account-setup/groups/zipcode"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_group_settings"]);
              this.setPageTitle("Group Zipcode Settings");
            }}
          />

          <Route
            path="/company-settings/account-setup/groups/carrier"
            component={loadable(() =>{
              const {isOutSource} = isManageFleetOrOutSource();
              if(isOutSource && isNewSettingsEnabled()){
                return import("./pages/tms/NewSettings/CompanySettings/AccountSetup");
              }else{
                return import("./pages/NoMatchpage");
              }
            }
            )}
            onEnter={() => {
              this.authorize(["settings_group_settings"]);
              this.setPageTitle("Group Carrier Settings");
            }}
          />

            <Route
              path="/company-settings/account-setup/port-market"
              component={loadable(() => {
                if (isNewSettingsEnabled() && ["carrier"].includes(getStorage("currentUserRole"))) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
                else return import("./pages/NoMatchpage")
              }
              )}
              onEnter={() => {
                this.authorize(["settings_general_info"]);
                this.setPageTitle("Port Markets");
              }}
            />

          <Route
            path="/company-settings/account-setup/role-management"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["other_role_management"]);
              this.setPageTitle("Role Access Management");
            }}
          />

          <Route
            path="/company-settings/account-setup/routing"
            component={loadable(() => {
              if(isNewSettingsEnabled() && ["carrier"].includes(getStorage("currentUserRole"))) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_general_info"]);
              this.setPageTitle("Routing");
            }}
          />

          <Route
            path="/company-settings/account-setup/users"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/AccountSetup")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["other_permission"]);
              this.setPageTitle("Users");
            }}
          />

          <Route
            path="/company-settings"
            component={loadable(() => {
              const loggedInUser = JSON.parse(getStorage("loggedInUser"))
              if (
                (getStorage("currentUserRole") === "carrier" ||
                (loggedInUser?.role === "fleetmanager" && !loggedInUser?.fleetManager?.isCustomer)) &&
                isNewSettingsEnabled()
              ) {
                return import("./pages/tms/NewSettings/CompanySettings");
              } else return import("./pages/NoMatchpage");
            }
            )}
            onEnter={() => {
              this.setPageTitle("Company Settings");
            }}
          />

          <Route
            path="/company-settings/accounting/accounting-configuration"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Accounting")
              else return import("./pages/NoMatchpage")
            }
            )}              
            onEnter={() => {
              this.authorize(["settings_billing_configuration"]);
              this.setPageTitle("Accounting Configuration");
            }}
          />
          
          <Route
            path="/company-settings/accounting/currency"
            component={loadable(() => {
              if(isGlobalizationEnabled() && isNewSettingsEnabled() && ["carrier"].includes(getStorage("currentUserRole"))) return import("./pages/tms/NewSettings/CompanySettings/Accounting")
              else return import("./pages/NoMatchpage")
            }
            )}              
            onEnter={() => {
              this.authorize(["settings_general_info"]);
              this.setPageTitle("Currency");
            }}
          />

          <Route
            path="/company-settings/accounting/dynamic-tax"
            component={loadable(() =>{
             if(JSON.parse(getStorage("userBasicSettings"))?.isVAT && isNewSettingsEnabled()) {
              return import("./pages/tms/NewSettings/CompanySettings/Accounting")
             }else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["dynamic-tax"]);
              this.setPageTitle("Dynamic Tax");
            }}
          />

          <Route
            path="/company-settings/accounting/expenses"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Accounting")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_expense"]);
              this.setPageTitle("Expenses");
            }}
          />

          <Route
            path="/company-settings/company-preferences/advanced-company-preferences"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/CompanyPreferences")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_general_info"]);
              this.setPageTitle("Advanced Company Preferences");
            }}
          />

          <Route
            path="/company-settings/company-preferences/configuration"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/CompanyPreferences")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_general"]);
              this.setPageTitle("Configuration");
            }}
          />
          
          <Route
            path="/company-settings/company-preferences/dispatcher-load-board-preferences"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/CompanyPreferences")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_load_status_colors"]);
              this.setPageTitle("Dispatcher Load Board Preferences");
            }}
          />

          <Route
            path="/company-settings/company-preferences/theme"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/CompanyPreferences")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Logos / Menu Colors Theme");
            }}
          />

          <Route
            path="/company-settings/connections/connect-configuration"
            component={loadable(() =>{
              if(isSyncRequest() && isNewSettingsEnabled())
                return import("./pages/tms/NewSettings/CompanySettings/Connections")
              else
                return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.authorize(["settings_connect_configuration"]);
              this.setPageTitle("Connect Configuration");
            }}
          />

          <Route           
            path="/company-settings/connections/developer-settings"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Connections")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_developer"]);
              this.setPageTitle("Developer Settings");
            }}
          />

          <Route
            path="/company-settings/connections/enterprise-connection-setup"
            component={loadable(() =>{
              if(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isEnterpriseLoginEnabled && isNewSettingsEnabled()){
                return import("./pages/tms/NewSettings/CompanySettings/Connections")
              }else{
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["settings_general_info"]);
              this.setPageTitle("Enterprise Connection Setup");
            }}
          />

          <Route           
            path="/company-settings/connections/my-app"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Connections")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_my_apps"]);
              this.setPageTitle("My Apps");
            }}
          />

          <Route
            path="/company-settings/equipment/chassis/size"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["other_chassis_owner", "other_chassis_size", "other_chassis_type"]);
              this.setPageTitle("Chassis Size");
            }}
          />

          <Route
            path="/company-settings/equipment/chassis/type"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["other_chassis_owner", "other_chassis_size", "other_chassis_type"]);
              this.setPageTitle("Chassis Type");
            }}
          />

          <Route
            path="/company-settings/equipment/chassis/owner"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["other_chassis_owner", "other_chassis_size", "other_chassis_type"]);
              this.setPageTitle("Chassis Owner");
            }}
          />

          <Route
            path="/company-settings/equipment/container/size"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Container Size");
            }}
          />

          <Route
            path="/company-settings/equipment/container/type"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Container Type");
            }}
          />

          <Route
            path="/company-settings/equipment/container/owner"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["other_container_owner", 'other_container_size', 'other_container_type', 'other_container_free_day']);
              this.setPageTitle("Container Owner");
            }}
          />
          
          <Route
            path="/company-settings/equipment/container/free-day"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Container Free day");
            }}
          />

          <Route
            path="/company-settings/equipment/container/iso-codes"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              if(isISOTypeEnabled()){
                this.setPageTitle("Container ISO Code");
              }else{
                return import("./pages/NoMatchpage")
              }
            }}
          />

          <Route
            path="/company-settings/equipment/commodity-list"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_commodity"]);
              this.setPageTitle("Commodity List");
            }}
          />

          <Route
            path="/company-settings/equipment/parameters"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_equipment_params"]);
              this.setPageTitle("Parameters");
            }}
          />

          <Route
            path="/company-settings/equipment/validations"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Equipment")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_validations"]);
              this.setPageTitle("Validations");
            }}
          />

          <Route
            path="/company-settings/finance/quickbooks-settings"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Finance")
              else return import("./pages/NoMatchpage")
            }
            )}              
            onEnter={() => {
              this.authorize(["quickbooks_settings"]);
              this.setPageTitle("Quickbooks Settings");
            }}
          />

          <Route
            path="/company-settings/general/company-info"
            component={loadable(() => {
              if(isNewSettingsEnabled() && ["carrier"].includes(getStorage("currentUserRole"))) return import("./pages/tms/NewSettings/CompanySettings/General")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_general_info"]);
              this.setPageTitle("Company Info");
            }}
          />

          <Route
            path="/company-settings/email/domain-configuration"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Email")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["settings_email_configuration"]);
              this.setPageTitle("Domain Configuration");
            }}
          />

          <Route
            path="/company-settings/email/expiry-notifications"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Email")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["expiry_notifications"]);
              this.setPageTitle("Expiry Notification");
            }}
          />

          <Route
            path="/company-settings/email/notifications/records"
            component={loadable(() =>  {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Email")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["records_settings"])
              this.setPageTitle("Notifications Records");
            }}
          />

          <Route
            path="/company-settings/email/notifications/template-groups"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Email")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["template_group_settings"])
              this.setPageTitle("Notifications Template Group");
            }}
          />

          <Route
            path="/company-settings/email/notifications/templates"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/Email")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["template_settings"])
              this.setPageTitle("Notifications Templates");
            }}
          />

          <Route
            path="/company-settings/embedded-email/rules"
            component={loadable(() => {
              if(isEmbeddedEmailEnabled() && isNewSettingsEnabled()) {
                return import("./pages/tms/NewSettings/CompanySettings/EmbeddedEmail")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["embedded_email_rules"]);
              this.setPageTitle("Rules");
            }}
          />

          <Route                      
            path="/company-settings/embedded-email/tags"
            component={loadable(() =>{
              if(isEmbeddedEmailEnabled() && isNewSettingsEnabled()){
                return import("./pages/tms/NewSettings/CompanySettings/EmbeddedEmail")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["embedded_email_tags"]);
              this.setPageTitle("Emails Tags Settings")}}
          />

          <Route
            path="/company-settings/embedded-email/mail-box"
            component={loadable(() => {
              if (isEmbeddedEmailEnabled() && isNewSettingsEnabled()) {
                return import("./pages/tms/NewSettings/CompanySettings/EmbeddedEmail")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(['embedded_email_mailbox'])
              this.setPageTitle("Embedded Settings")
            }}
          />

          <Route
            path="/company-settings/track-and-appointments/appointments-list"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/TrackAndAppointments")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["appointment_list"]);
              this.setPageTitle("Appointment Lists");
            }}
          />

          <Route
            path="/company-settings/track-and-appointments/appointment-settings"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/TrackAndAppointments")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["appointment_settings"]);
              this.setPageTitle("Appointment Settings");
            }}
          />

          <Route
            path="/company-settings/track-and-appointments/container-tracking-credentials"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/TrackAndAppointments")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["container-tracking-credentials"]);
              this.setPageTitle("Container Tracking Credentials");
            }}
          />

          <Route
            path="/company-settings/track-and-appointments/container-tracking-settings"
            component={loadable(() => {
              if(isNewSettingsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/TrackAndAppointments")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["container-tracking"]);
              this.setPageTitle("Container Tracking Settings");
            }}
          />

          <Route
            path="/company-settings/track-and-appointments/slots-availability"
            component={loadable(() => {
              if(isNewSettingsEnabled() && isDakosySlotsEnabled()) return import("./pages/tms/NewSettings/CompanySettings/TrackAndAppointments")  
              else return import("./pages/NoMatchpage")            
            }
            )}
            onEnter={() => {
              this.authorize(["container-tracking"]);
              this.setPageTitle("Slots Availability");
            }}
          />

          <Route
            path="/settings"
            component={loadable(() => import("./pages/trucker/Settings"))}
            onEnter={() => {
              this.setPageTitle("Settings");
            }}
          >
            <Route
              path="/settings/users"
              component={loadable(() =>
                import("./pages/tms/carrier/PermissionRoleAssign")
              )}
              onEnter={() => {
                this.setPageTitle("Permission Role");
              }}
            />
            <Route
              path="/settings/trucks"
              component={loadable(() => import("./pages/tms/Truck"))}
              onEnter={() => {
                this.setPageTitle("Trucks");
              }}
            />
            <Route
              path="/settings/trailers"
              component={loadable(() => import("./pages/tms/Trailer"))}
              onEnter={() => {
                this.setPageTitle("Trailers");
              }}
            />
            <Route
              path="/settings/chassis"
              component={loadable(() => import("./pages/tms/AddChassis"))}
              onEnter={() => {
                this.authorize(["other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
            <Route
              path="/settings/customer"
              component={loadable(() => import("./pages/tms/Customer"))}
              onEnter={() => {
                this.setPageTitle("Customer");
              }}
            />
            <Route
              path="/settings/driver-rates"
              component={loadable(() =>
                import("./pages/tms/DriverPayment/DriverPayment")
              )}
              onEnter={() => {
                this.setPageTitle("Driver Rates");
              }}
            />
            <Route
              path="/settings/Subdomain"
              component={loadable(() =>
                import("./pages/Onboarding/AddDomainName")
              )}
              onEnter={() => {
                this.setPageTitle("Subdomain");
              }}
            />
            <Route
              path="/settings/Commodity"
              component={loadable(() =>
                import("./pages/Onboarding/CommodityProfile")
              )}
              onEnter={() => {
                this.authorize(["settings_commodity"]);
                this.setPageTitle("Commodity");
              }}
            />
            <Route
              path="/settings/assignTruck"
              component={loadable(() =>
                import("./pages/tms/AssignTruck/AssignTruck")
              )}
              onEnter={() => {
                this.authorize(["other_assign_truck"]);
                this.setPageTitle("Asign Truck");
              }}
            />
            <Route
              path="/settings/customer-rates"
              component={loadable(() =>
                import("./pages/tms/Load/loadPriceSettings")
              )}
              onEnter={() => {
                this.setPageTitle("Customer Rates");
              }}
            />
            <Route
              path="/settings/dynamic-pricing"
              component={loadable(() => import("./Components/DynamicPricing"))}
              onEnter={() => {
                // this.authorize(["dynamic_dynamic_pricing"]);
                this.setPageTitle("Dynamic pricing");
              }}
            />
            <Route
              path="/settings/expiry-notifications"
              component={loadable(() =>

                import("./pages/tms/Settings/ExpiryNotification")
              )}
              onEnter={() => {
                this.authorize(["expiry_notifications"]);
                this.setPageTitle("Expiry Notification");
              }}
            />
            <Route
              path="/settings/expense-setting"
              component={loadable(() =>
                import("./Components/GeneralExpenseSetting")
              )}
              onEnter={() => {
                this.authorize(["settings_expense"]);
                this.setPageTitle("General Expense Settings");
              }}
            />
            <Route
              path="/settings/general-setting"
              component={loadable(() =>

                import("./pages/tms/Settings/Settings")
              )}
              onEnter={() => {
                this.authorize(["settings_general"]);
                this.setPageTitle("General Settings");
              }}
            />
            <Route
              path="/settings/group-setting"
              component={loadable(() =>
                import("./pages/tms/Settings/GroupSettings")
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Group Settings");
              }}
            />

            <Route
              path="/settings/group-setting/cities"
              component={loadable(() =>
                import("./pages/tms/Settings/GroupSettingsCity")
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Group Cities Settings");
              }}
            />

            <Route
              path="/settings/group-setting/zipcode"
              component={loadable(() =>
                import("./pages/tms/Settings/GroupSettingsZipcode")
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Group Zipcode Settings");
              }}
            />

            <Route
              path="/settings/group-setting/carrier"
              component={loadable(() =>{
                const {isOutSource, isManageFleet} = isManageFleetOrOutSource();
                if(isOutSource){
                  return import("./pages/tms/Settings/GroupSettingsCarrier/GroupSettingsCarrier.c");
                }else{
                  return import("./pages/NoMatchpage");
                }
              }
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Group Carrier Settings");
              }}
            />

            <Route
              path="/settings/email-notification-setting"
              component={loadable(() =>
                import("./pages/tms/Settings/EmailNotificationReport")
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Email Notification Settings");
              }}
            />
            <Route
              path="/settings/ediSettings"
              component={loadable(() => import("./pages/tms/EdiSettings"))}
              onEnter={() => {
                this.setPageTitle("EDI Settings");
              }}
            />
            <Route
              path="/settings/loadStatusColors"
              component={loadable(() => import("./pages/tms/LoadStatusColors"))}
              onEnter={() => {
                this.authorize(["settings_load_status_colors"]);
                this.setPageTitle("Loads Status Color Settings");
              }}
            />
            <Route
            path="/settings/auth0-connect"
            component={loadable(() =>{
              if(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isEnterpriseLoginEnabled){
               return import("./pages/trucker/AuthConnection/index")
              }else{
               return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["settings_general_info"]);
              this.setPageTitle("Enterprise Connection Setup");
            }}
          />
          </Route>
            <Route
              path="/settings/cabotage"
              component={loadable(() => {
                if (isCabotageEnabled()) {
                  return import("./pages/tms/Settings/Cabotage")
                } else {
                  return import("./pages/NoMatchpage")
                }
              }
              )}
              onEnter={() => {
                this.authorize(["settings_general_info"]);
                this.setPageTitle("Cabotage");
              }}
            />


            <Route
              path="/settings/slots-availability"
              component={loadable(() => {
                if (isDakosySlotsEnabled()) return import("./pages/tms/ContainerTracking/SlotsAvailability")
                return import("./pages/NoMatchpage")
              }
              )}
              onEnter={() => {
                this.authorize(["container-tracking"]);
                this.setPageTitle("Slots Availability");
              }}
            />

          <Route
            path="/dynamic-pricing"
            component={loadable(() => import("./Components/DynamicPricing"))}
            onEnter={() => {
              // this.authorize(["other_dynamic_pricing"]);
              this.setPageTitle("Dynamic Pricing");
            }}
          />
          <Route
            path="/dynamic-expense"
            component={loadable(() => import("./Components/DynamicExpense"))}
            onEnter={() => {
              this.setPageTitle("Dynamic Expense");
            }}
          />

         <Route
            path="/dynamic-driverPay"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/DynamicDriverPay")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["other_dynamic_driver_pay"]);
              this.setPageTitle("Dynamic Driver Pay");
            }}
          />
          <Route
            path="/profile"
            component={loadable(() => import("./pages/tms/Profile"))}
            onEnter={() => {
              this.setPageTitle("Profile");
            }}
          />
          <Route
            path="/nopermission"
            component={loadable(() => import("./pages/nopermission"))}
            onEnter={() => {
              this.setPageTitle("No Permission");
            }}
          />
          <Route
            path="/new-company"
            component={loadable(() => import("./pages/tms/Profile"))}
            onEnter={() => {
              this.setPageTitle("Profile");
            }}
          />
          <Route
            path="/getresetToken"
            component={loadable(() => import("./Components/Token/Token"))}
            onEnter={() => {
              this.setPageTitle("Reset Password");
            }}
          />
          <Route
            path="/forget/:token"
            component={loadable(() => import("./Components/Forget/Forget"))}
            onEnter={() => {
              this.setPageTitle("Forget Password");
            }}
          />
          <Route
            path="/setup/:token"
            component={loadable(() => import("./Components/Setup/Setup"))}
            onEnter={() => {
              this.setPageTitle("Setup Password");
            }}
          />
          <Route
            exact
            path="/logout"
            onEnter={() => {
              this.props.commonActions.logoutUser();
            }}
          /> 
          <Route
            exact
            path="/sso"
            onEnter={(e) => {
              this.setPageTitle("SSO");
              const organization = e?.location?.query?.organization;
              if(organization) {
                const auth0 = this.props.auth0;
                const options = {
                  authorizationParams: {
                    organization: organization,
                  }
                }
                auth0.loginWithRedirect(options);
              }
            }}
          /> 
        
          <Route
            path="/track1"
            component={loadable(() => {
              if(isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/trucker/NewTrack1")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={(_nextState, replace) => {
              this.authorize(["tracking"]);
              this.setPageTitle("Track");

              if (IsELDEnabled()) {
                return replace("/tracking/drivers");
              }
            }}
          />
          <Route
            path="/tracking/trucks"
            component={loadable(() => {
              if(isManageFleetOrOutSource()?.isManageFleet && IsELDEnabled()){
                return import("./pages/trucker/Tracking/TrackingTrucksPage")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["tracking"]);
              this.setPageTitle("Tracking");
            }}
          />

          <Route
            path="/tracking/drivers"
            component={loadable(() => {
              if(isManageFleetOrOutSource()?.isManageFleet && IsELDEnabled()){
                return import("./pages/trucker/Tracking/TrackingDriversPage")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["tracking"]);
              this.setPageTitle("Tracking");
            }}
          />
          <Route
            path="/tracking"
            onEnter={(_nextState, replace) => {
              if (isManageFleetOrOutSource()?.isManageFleet && IsELDEnabled()) {
                replace("/tracking/drivers");
              } else {
                replace("/NoMatchpage");
              }
            }}
          />

          <Route
            path="/tendered"
            component={loadable(() => import("./pages/tms/Assigned/TenderedLoads"))}
            onEnter={() => {
              this.authorize(["customer_service_tendered_loads"]);
              this.setPageTitle("Tendered Loads");
            }}
          />
          <Route
            path="/tendered#port-pal"
            component={loadable(() => import("./pages/tms/Assigned/Assigned"))}
            onEnter={() => {
              this.authorize(["customer_service_tendered_loads"]);
              this.setPageTitle("PortPal");
            }}
          />
          <Route
            path="/tendered#port-pal-audit"
            component={loadable(() => import("./pages/tms/Assigned/Assigned"))}
            onEnter={() => {
              this.authorize(["customer_service_tendered_loads"]);
              this.setPageTitle("PortPal Audit");
            }}
          />
          <Route
            path="/new-tendered"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/TenderLoads/TenderLoads.c")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["customer_service_tendered_loads"]);
              this.setPageTitle(" New Tendered Loads");
            }}
          />
          <Route
            path="/send-tendered-online"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isOutSource){
                return import("./pages/tms/OnlineTenderBroker/OnlineTender")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["customer_service_online_tendered_loads"]);
              this.setPageTitle("Online Sent Tendered Loads");
            }}
          />

          <Route
            path="/tendered-online"
            component={loadable(() => {
                return import("./pages/tms/OnlineTender/OnlineTender")
            })}
            onEnter={() => {
              this.authorize(["customer_service_online_tendered_loads"]);
              this.setPageTitle("Online Tendered Loads");
            }}
          />
          
          <Route
            path="/notification"
            component={loadable(() =>
              import("./pages/tms/CustomerNotification")
            )}
            onEnter={() => {
              this.setPageTitle("Notifications");
            }}
          />
          <Route
            path="/loads"
            component={loadable(() =>
              import("./pages/trucker/CustomerLoad/CustomerLoad")
            )}
            onEnter={() => {
              this.setPageTitle("Loads");
            }}
          />
          {/* Customer Dropped Container */}
          <Route
            path="/tms/at-yard-container-loads"
            component={loadable(() =>
              import(
                "./pages/tms/carrier/AtYardContainerLoads/AtYardContainerLoads"
              )
            )}
            onEnter={() => {
              this.authorize([
                "dropped_containers",
              ]);
              this.setPageTitle("Dropped Container");
            }}
          />
          {/* Customer Account recievable */}
          <Route
            path="/tms/account-receivable"
            component={loadable(() =>{
              if(isNewBillingEnabled()){
                  return import(
                    "./pages/tms/carrier/AccountReceivable/CustomerReceivable"
                  )
              } else {
                return import(
                  "./pages/tms/carrier/AccountReceivable/AccountReceivable"
                )
              }
            })}
            onEnter={() => {
              this.authorize([
                "billing_account_receivable",
                "account_payable",
              ]);
              this.setPageTitle("Dropped Container");
            }}
          />

          <Route
            path= "/tms/customer/bills"
            component={loadable(() =>
              import(
                "./pages/tms/carrier/CustomerInvoiceReceivable"
              )
            )}
            onEnter={() => {
              this.authorize([
                "account_payable"
              ]);
              this.setPageTitle("Dropped Container");
            }}
          />

          {/* <Route
            path="/emails"
            component={loadable(() => import("./pages/tms/Emails"))}
            onEnter={() => {
              this.setPageTitle("Emails");
            }}
          />
          <Route
            path="/emails/:email_id"
            component={loadable(() => import("./pages/tms/Emails"))}
            onEnter={() => {
              this.setPageTitle("Email");
            }}
          /> */}
          <Route
            path="/tms/email"
            component={loadable(() => {
              if(isEmbeddedEmailEnabled()){
                return import("./pages/tms/Email/ConditionalEmails")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              if(!isEmbeddedEmailEnabled()) return browserHistory.push("/nopermission")
              this.setPageTitle("Emails");
            }}
          />
          <Route
            path="/tms/connect-email-successful"
            component={loadable(() => import("./pages/tms/Email/Components/ConnectEmbeddedEmail/ConnectEmbeddedEmailSuccessful"))}
            onEnter={() => {
              this.setPageTitle("Connect Email SuccessFully");
            }}
          />
          <Route
            path="/load-quotes"
            component={loadable(() =>
              import("./pages/tms/Load/LoadQuotesForCustomer")
            )}
            onEnter={() => {
              this.setPageTitle("Load Quotes");
            }}
          />
          <Route
            path="/truck-account-profile"
            component={loadable(() =>
              import("./pages/trucker/EditProfileTrucker/EditProfileTrucker")
            )}
            onEnter={() => {
              this.authorize(["account"]);
              this.setPageTitle("Truck Account profile");
            }}
          />
          <Route
            path="/driver-messaging"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/trucker/DriverMessaging/DriverMessaging")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["customer_service_drive_messaging"]);
              this.setPageTitle("Driver Messaging");
            }}
          />

          <Route
            path="/group-messaging"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/trucker/DriverMessaging/GroupMessaging")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              // this.authorize(["customer_service_group_messaging"]);
              this.setPageTitle("Group Messaging");
            }}
          />

          <Route
            path="/driver-group-messaging"
            component={loadable(() =>
              import("./pages/trucker/DriverMessaging/DriverGroupMessaging")
            )}
            onEnter={() => {
              // this.authorize(["customer_service_group_messaging"]);
              this.setPageTitle("Driver Group Chat");
            }}
          />

          <Route
            path="/employee-messaging"
            component={loadable(() =>
              import("./pages/trucker/DriverMessaging/EmployeeMessaging")
            )}
            onEnter={() => {
              // this.authorize(["customer_service_employee_messaging"]);
              this.setPageTitle("Employee Messaging");
            }}
          />

          <Route
            path="/fleetmanagement"
            component={loadable(() =>
              import("./pages/tms/FleetManagement/index")
            )}
            onEnter={() => {
              this.setPageTitle("fleet Customer");
            }}
          />

          <Route
            path="/carrieraccessmanagement"
            component={loadable(() => !isNewSettingsEnabled() ? import("./pages/tms/carrier/OldCarrierAccessManagement") : import("./pages/tms/carrier/CarrierAccessManagement")
            )}
            onEnter={() => {
              this.authorize(["other_permission"]);
              this.setPageTitle("Carrier Access Management");
            }}
          />
          <Route
            path="/roleaccessmanagement"
            component={loadable(() =>
              import("./pages/tms/carrier/RoleAccessManagement")
            )}
            onEnter={() => {
              this.authorize(["other_role_management"]);
              this.setPageTitle("Role Access Management");
            }}
          />
          <Route
            path="/permission-role-assign"
            component={loadable(() =>
              import("./pages/tms/carrier/PermissionRoleAssign")
            )}
            onEnter={() => {
              this.authorize(["other_permission"]);
              this.setPageTitle("Assign permission Role");
            }}
          />
          <Route
              path="/container-tracking"
              component={loadable(() =>
                import("./pages/tms/ContainerTracking")
              )}
              onEnter={() => {
                this.authorize(["container-tracking"]);
                this.setPageTitle("Container Tracking");
              }}
            />
          <Route
              path="/settings/dynamicTax"
              component={loadable(() =>{
               if(JSON.parse(getStorage("userBasicSettings"))?.isVAT) {
                return import("./pages/tms/Settings/dynamicTax/index")
               }else{
                return import("./pages/NoMatchpage")
               }
              }
              )}
              onEnter={() => {
                this.authorize(["dynamic-tax"]);
                this.setPageTitle("Dynamic Tax");
              }}
            />
          {/* --------------------------------- Billing Section --------------------- */}
          <Route
            path="/tms/billing"
            Component={loadable(() => import("./pages/tms/Settlement/Billing"))}
            onEnter={() => {
              this.setPageTitle("Billing");
            }}
          >
            <IndexRoute
              component={loadable(() =>
                import("./pages/tms/carrier/Transcation/NewTranscations")
              )}
              onEnter={() => {
                this.authorize(["billing_driver_pay"]);
                this.setPageTitle("Driver pay");
              }}
            />
            <Route
              path="/tms/billing/driver-pay"
              component={loadable(() => {
                  if (isManageFleetOrOutSource()?.isManageFleet) {
                    if(JSON.parse(getStorage("userBasicSettings"))?.newDriverPayScreen) {
                      return import("./pages/tms/carrier/Transcation/NewDriverPay");
                    } else {
                      return import("./pages/tms/carrier/Transcation/NewTranscations");
                    }
                  } else {
                    return import("./pages/NoMatchpage");
                  }
                }
              )}
              onEnter={() => {
                this.authorize(["billing_driver_pay"]);
                this.setPageTitle("Driver Pay");
              }}
              />

              <Route
                path="/tms/vendor-bills"
                component={loadable(() => isVendorPayAndBillsEnabled() ? import( "./pages/tms/VendorBills") : import("./pages/nopermission"))}
                onEnter={() => {
                  this.authorize(["billing_vendor_bills"]);
                  this.setPageTitle("Vendor Bills");
                }}
              />
              <Route
                path="/tms/vendor-pay"
                component={loadable(() => isVendorPayAndBillsEnabled() ? import("./pages/tms/VendorPay") : import("./pages/nopermission"))}
                onEnter={() => {
                  this.authorize(["billing_vendor_pay"]);
                  this.setPageTitle("Vendor Pay");
                }}
              />

            <Route
              path="/tms/dynamic-expense"
                component={loadable(() => { return import("./Components/DynamicExpense"); })}
              onEnter={() => {
                this.authorize(["dynamic_dynamic_expense"]);
                this.setPageTitle("Dynamic Expense");
              }}
            />
            <Route
              path="/tms/account-payable/problem-sync"
              component={loadable(() => import("./pages/tms/ProblemAccountingService/settlements/APProblemSync"))}
              onEnter={() => {
                this.authorize(["vendor_problem_bills"]);
                this.setPageTitle("Problem Sync");
              }}
            />
            <Route
              path="/tms/billing/new-transcation"
              component={loadable(() =>
                import("./pages/tms/carrier/Transcation/NewTranscations")
              )}
              onEnter={() => {
                this.setPageTitle("New Transactions");
              }}
            />
            <Route
              path="/tms/billing/payment"
              component={loadable(() =>  {
                  if(isNewBillingEnabled()){
                   return  import("./pages/tms/ApplyPayments/applyPayments")
                  }else{
                    return import("./pages/tms/QbSetting/QbPayment")
                  }
              }
              )}
              onEnter={() => {
                this.authorize(["billing_batch_apply_payment"]);
                this.setPageTitle("Payment");
              }}
            />
            <Route
              path="/tms/billing/carrier-pay"
              component={loadable(() => {
                  if (isManageFleetOrOutSource()?.isOutSource) {
                    return import("./pages/tms/carrier/CarrierPay")
                  } else {
                    return import("./pages/NoMatchpage")
                  }
                }
              )}
              onEnter={() => {
                this.authorize(["billing_driver_pay"]);
                this.setPageTitle("Carrier Pay");
              }}
            />
            <Route
              path="/tms/billing/apply-carrier-payment"
              component={loadable(() => {
                  if (isManageFleetOrOutSource()?.isOutSource) {
                    return import("./pages/tms/carrier/ApplyCarrierPay/ApplyCarrierPayment")
                  } else {
                    return import("./pages/NoMatchpage")
                  }
                }
              )}
              onEnter={() => {
                this.authorize(["billing_driver_pay"]);
                this.setPageTitle("Apply Carrier Payment");
              }}
            />
             <Route
              path="/tms/billing/apply-vendor-payment"
              component={loadable(() => {
                  if (isManageFleetOrOutSource()?.isOutSource) {
                    return import("./pages/tms/VendorPayment")
                  } else {
                    return import("./pages/NoMatchpage")
                  }
                }
              )}
              onEnter={() => {
                this.authorize(["billing_vendor_payment"]);
                this.setPageTitle("Apply Vendor Payment");
              }}
            />
            <Route
              path="/tms/credit-memo"
              component={loadable(() =>
               { 
                if(isNewBillingEnabled()){
                  return import("./pages/tms/CreditMemoV2/index.jsx")
                }
                else if(isCreditMemo() ){
                  return import("./pages/tms/CreditMemo/CreditMemos")
                 }else{
                  return import("./pages/NoMatchpage")
                 }}
              )}
              onEnter={() => {
                this.authorize(["credit_memo"]);
                this.setPageTitle("Credit Memo");
              }}
            />
           <Route
              path="/tms/billing/settlements"
              component={loadable(() => {
                  if (isManageFleetOrOutSource()?.isManageFleet) {
                    return import("./pages/tms/DynamicSettlement/index");
                  } else {
                    return import("./pages/NoMatchpage");
                  }
                }
              )}
              onEnter={() => {
                this.authorize(["billing_driver_settlement"]);
                this.setPageTitle("Settlements");
              }}
            />
            <Route
              path="/tms/billing/settings"
              component={loadable(() => {
                if (isManageFleetOrOutSource()?.isManageFleet){
                  if(isNewDeduction()){
                    return import("./pages/tms/Settlement/NewSettlementDriverDeductionSetting")
                  }else{
                    return import("./pages/tms/Settlement/DriverDeductionSetting")
                  }
                } else {
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.authorize(["billing_settlement_setting"]);
                this.setPageTitle("Billing Setting");
              }}
            />
            {/* TODO: separate permission? */}
            <Route
              path="/tms/billing/settlement-periods"
              component={loadable(() =>
                import("./pages/tms/Settlement/SettlementPeriodSettings")
              )}
              onEnter={() => {
                this.authorize(["billing_settlement_setting"]);
                this.setPageTitle("Settlement Settings");
              }}
            />
            <Route
              path="/tms/billing/account-receivable"
              component={loadable(() =>
                {
                  if(isNewBillingEnabled()){
                    return import(
                      "./pages/tms/carrier/AccountReceivable/ChargeReceivable"
                    )
                  } 
                  else if(isNewAccountReceivable()){
                    return import(
                      "./pages/tms/carrier/AccountReceivable/NewAccountReceivable"
                    )
                  } else {
                    return import(
                      "./pages/tms/carrier/AccountReceivable/AccountReceivable"
                    )
                  } 
                }
              )}
              onEnter={() => {
                this.authorize([
                  "billing_account_receivable"
                ]);
                this.setPageTitle(isNewBillingEnabled() ? "Billing" : "Accounts Receivable");
              }}
              />
              <Route
                path="/tms/billing/invoices"
                component={loadable(() =>{
                  if(isNewBillingEnabled() === true){
                    return import("./pages/tms/carrier/InvoiceRecivable");
                  } else {
                    return import("./pages/nopermission");
                  }
                }
                )}
                onEnter={() => {
                  this.authorize([
                    "invoice_account_receivable"
                  ]);
                  if (isNewBillingEnabled() === true) {
                    this.setPageTitle("Invoices");
                  } else {
                    this.setPageTitle("No Permission");
                  }
                }}
              />
              <Route
                path="/tms/billing/per-diem"
                component={loadable(() => {
                  if (checkIfPerDiemEnabled() === true) {
                    return import("./pages/tms/PerDiem")
                  } else {
                    return import("./pages/nopermission")
                  }
                }
                )}
                onEnter={() => {
                  if (checkIfPerDiemEnabled() === true) {
                    this.setPageTitle("Per Diem Audit");
                  } else {
                    this.setPageTitle("No Permission");
                  }
                }}
              />
              <Route
                path="/tms/billing/emptyReturn"
                component={loadable(() => {
                  if (checkIfPerDiemEnabled() === true) {
                    return import("./pages/tms/PerDiem/emptyReturn/index")
                  }else{
                    return import("./pages/nopermission")
                  }
                })}
                onEnter={() => {
                  if (checkIfPerDiemEnabled() === true) {
                      this.setPageTitle("Empty Return");
                  }else {
                    this.setPageTitle("No Permission");
                  }
                  
                }}
              />
              <Route
                path="/tms/billing/perdiem-screengrabs"
                component={loadable(() => {
                  if (checkIfPerDiemEnabled() === true) {
                    return import("./pages/tms/PerDiem/PerdiemScreengrab/index")
                  }else{
                    return import("./pages/nopermission")
                  }
                  
              })}
                onEnter={() => {
                  if (checkIfPerDiemEnabled() === true) {
                    this.setPageTitle("Perdiem Screengrabs");
                  }else{
                    this.setPageTitle("No Permission");
                  }
                 
                }}
              />
              <Route
                path="/tms/billing/waiting-time-audit"
                component={loadable(() => {
                  if (checkUserPermisison(["waiting_time_audit"]) === true) {
                    return import("./pages/tms/carrier/WaitingTimeAudit")
                  } else {
                    return import("./pages/nopermission")
                  }
                }
                )}
                onEnter={() => {
                  if (checkUserPermisison(["waiting_time_audit"]) === true) {
                    this.setPageTitle("Waiting Time Audit");
                  } else {
                    this.setPageTitle("No Permission");
                  }
                }}
              />
              
              <Route
                path="/tms/billing/aging1"
                component={loadable(() => {
                  if (isNewBillingEnabled()) {
                    return import("./pages/tms/reports/Aging/index")
                  } else {
                    return import("./pages/tms/reports/AgingReportsNew")
                  }
                }
                )}
                onEnter={() => {
                  this.authorize(["reports_aging"]);
                  this.setPageTitle("Aging");
                }}
              />
              <Route
                path="/tms/billing/emptiesAvailableSlots"
                component={loadable(() => {
                  if (checkIfPerDiemEnabled() === true) {
                    return import("./pages/tms/PerDiem/EmptiesSlots/index")
                  }else{
                    return import("./pages/nopermission")
                  }
                })}
                onEnter={() => {
                  if (checkIfPerDiemEnabled() === true) {
                      this.setPageTitle("Empties Available Slots");
                  }else {
                    this.setPageTitle("No Permission");
                  }
                  
                }}
              />
            <Route
              path="/tms/presidential-dashboard"
              component={loadable(() =>
                import("./pages/tms/carrier/Dashboard/PresidentialDashboard")
              )}
              onEnter={() => {
                this.setPageTitle("Presidential Dashboard");
              }}
            />
            <Route
              path="/tms/desyncedQBLoads"
                component={loadable(() => {
                  if (isAccountServiceEnabled()) {
                    return import("./pages/tms/ProblemAccountingService/index.js") 
                  } else {
                    return import("./pages/tms/carrier/DesyncedQBLoads/index")
                  }
                }
              )}
              onEnter={() => {
                this.authorize(["billing_problem_invoices"]);
                this.setPageTitle("Desynced QuickBooks Loads");
              }}
            />
            <Route
              path="/tms/billing/sub-customer-account-receivable"
              component={loadable(() =>
                {
                  if(isNewAccountReceivable()){
                    return import(
                      "./pages/tms/carrier/SubAccountReceivable/NewSubAccountReceivable"
                    )
                  } else {
                    return import(
                      "./pages/tms/carrier/SubAccountReceivable/SubAccountReceivable"
                    )
                  } 
                }
              )}
              onEnter={() => {
                this.authorize(["billing_subaccount_receivable"]);
                this.setPageTitle("Sub Accounts Receivable");
              }}
            />
            <Route
              path="/account/billing-configuration"
              component={loadable(() =>
                import("./pages/tms/Settings/BillingConfiguration")
              )}              
              onEnter={() => {
                this.authorize(["settings_billing_configuration"]);
                this.setPageTitle("Billing Configuration");
              }}
            />
          </Route>
          <Route
              path="/account/billing-configuration"
              component={loadable(() =>
                import("./pages/tms/Settings/BillingConfiguration")
              )}              
              onEnter={() => {
                this.authorize(["settings_billing_configuration"]);
                this.setPageTitle("Billing Configuration");
              }}
            />
          <Route
            path="/tms/deleted-loads"
            component={loadable(() => import("./pages/tms/Loads/DeletedLoads"))}
            onEnter={() => {
              this.authorize(["customer_service_deleted_load"]);
              this.setPageTitle("Deleted Loads");
            }}
          />
          <Route
            path="/tms/terminals"
            component={loadable(() =>
              import("./pages/tms/Terminals/Terminals")
            )}
            onEnter={() => {
              this.setPageTitle("Terminals");
            }}
          />
          <Route
            path="/tms/customer-service/calendar"
            component={loadable(() => import("./pages/tms/Load/LoadCalendar"))}
            onEnter={() => {
              this.authorize(["customer_service_load_calendar"]);
              this.setPageTitle("Load Calendar");
            }}
          />
          <Route
            path="/tms/customer-service/customer-dashboard"
            component={loadable(() =>
              import("./pages/tms/carrier/CustomerDashboard")
            )}
            onEnter={() => {
              this.setPageTitle("Customer Dashboard");
            }}
          />
          <Route
            path="/tms/customer-service/at-yard-container-loads"
            component={loadable(() =>import(
              "./pages/tms/carrier/AtYardContainerLoads/NewYardContainerLoads"
            ))}
            onEnter={() => {
              this.authorize([
                "customer_service_dropped_container"
              ]);
              this.setPageTitle("Dropped Container");
            }}
          />

          <Route
            path="/tms/customer-service/dropped-dispatch-yard-container"
            component={loadable(() => {
              if (isDualTransactionEnabled()){
                return import("./pages/tms/DualTranscation")
              } else {
                return import("./pages/tms/carrier/DoubleDispatchDroppedYard/DoubleDispatchDroppedYard")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["dispatcher_double_moves"]);
              this.setPageTitle(isDualTransactionEnabled() ? "Dual Transactions" : "Double Moves");
            }}
          />
          <Route
            path="/tms/customer-service/problem-container"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/ProblemContainer")
            )}
            onEnter={() => {
              this.authorize(["dispatcher_problem_container"]);
              this.setPageTitle("Problem Container");
            }}
          />
          <Route
            path="/tms/customer-service/street-turns"
            component={loadable(() =>
              import("./pages/tms/StreetTurn/StreetTurn")
            )}
            onEnter={() => {
              this.authorize(["dispatcher_street_turns"]);
              this.setPageTitle("Street Turns");
            }}
          />
          <Route
            path="/tms/customer-service/driver-itinerary"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/Dispatcher/DriverItinerary")
              } else {
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["dispatcher_driver_itinerary"]);
              this.setPageTitle("Driver Itinerary");
            }}
          />
          <Route
            path="/tms/customer-service/driver-planner"
            component={loadable(() =>{
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/Dispatcher/NewDriverItinerary")
              } else {
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["dispatcher_driver_itinerary"]);
              this.setPageTitle("Driver Planner");
            }}
          />
          <Route
            path="/tms/trips"
            component={loadable(() =>{
              if(isManageFleetOrOutSource()?.isManageFleet && isTripEnabled()){
               return import("./pages/tms/Trips")
              }else{
               return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["load_free_flow"]);
              this.setPageTitle("Trips");
            }}
          />
          <Route
            path="/tms/RailOrder"
            component={loadable(() =>{
             
              if((JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isRailOrderEnabled ?? false) && isManageFleetOrOutSource()?.isHybrid){
               return import("./pages/tms/RailOrder")
              }else{
               return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["load_rail_order"]);
              this.setPageTitle("Rail Order");
            }}
          />
          <Route
            path="/tms/connect-notices"
            component={loadable(() =>{
              if(isConnectedWithOutSource() && isSyncRequest()){
               return import("./pages/tms/ConnectNotices/index.js")
              }else{
               return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["dispatcher_connect_notices"]);
              this.setPageTitle("Connect Notices");
            }}
          />
          {/* for driver details pages  */}
          <Route
            path="/tms/customer-service/chassis-validation"
            component={loadable(() =>
              import("./pages/tms/carrier/ChassisValidation")
            )}
            onEnter={() => {
              this.authorize(["billing_chassis_validation"]);
              this.setPageTitle("Chassis Audit");
            }}
          />
         

          {/* ---------------------------- Bulk Uplaod ----------------------------- */}
          {/* <Route
            path="/tms/customer-service/bulk-upload"
            component={loadable(() => import("./pages/tms/carrier/BulkUpload"))}
            onEnter={() => {
              this.setPageTitle("Bulk Uploads");
            }}
          /> */}
          <Route
            path="/tms/customer-service/bulk-upload1"
            component={loadable(() =>
              import("./pages/tms/carrier/BulkUploadNew")
            )}
            onEnter={() => {
              this.authorize(["customer_service_bulk_upload"]);
              this.setPageTitle("Bulk Uploads");
            }}
          />
          <Route
            path="/tms/AssignTruck"
            component={loadable(() => {
            if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/AssignTruck/AssignTruck")
              } else {
                return import("./pages/NoMatchpage")
              }

            })}
            onEnter={() => {
              this.authorize(["other_assign_truck"]);
              this.setPageTitle("Assign Truck");
            }}
          />
          <Route
            path="/tms/customer"
            component={Customers}
            onEnter={() => {
              this.authorize(["other_profile_customer","customer"]);
              this.setPageTitle(titleCustomersOrOrgs());
            }}
          />
          <Route
            path="/tms/contacts"
            component={loadable(() => import("./pages/tms/Contacts"))}
            onEnter={() => {
              this.setPageTitle("Contacts");
            }}
          />
          <Route
            path="/tms/FleetCustomer"
            component={loadable(() => import("./pages/tms/FleetCustomer"))}
            onEnter={() => {
              this.authorize(["other_group_customer"]);
              this.setPageTitle("Group Customer");
            }}
          />
          <Route
            path="/tms/people"
            component={loadable(() => {
              if(isEmbeddedEmailEnabled()){
                return import("./pages/tms/People")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["other_people"]);
              this.setPageTitle("People");
            }}
          />
          <Route
            path="/tms/fleetmanagers"
            component={loadable(() =>
              import("./pages/tms/carrier/CustomerAccessManagement")
            )}
            onEnter={() => {
              this.setPageTitle("Employee List");
            }}
          />
           <Route
            path="/tms/AddChassis"
            component={loadable(() => {
            if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/AddChassis")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["safety_chassis", "other_profile_chassis"]);
              this.setPageTitle("Chassis");
            }}
          />
           <Route
            path="/tms/Truck"
            component={loadable(() => {
            if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/Truck")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["safety_truck", "other_profile_truck"]);
              this.setPageTitle("Truck");
            }}
          />
           <Route
            path="/tms/fleetTruck"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/FleetTruck")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["safety_truck_owner"]);
              this.setPageTitle("Fleet Owner");
            }}
          />
           <Route
            path="/tms/Trailer"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/Trailer")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["other_profile_trailer"]);
              this.setPageTitle("Trailer");
            }}
          />
          <Route
            path="/tms/Dispatcher"
            component={loadable(() => import("./pages/tms/NewDispatcher/index"))}
            onEnter={() => {
              this.authorize(["dispatcher"]);
              this.setPageTitle("Dispatcher");
            }}
          >
            <Route
              path="/tms/Dispatcher/load"
              component={loadable(() => import("./pages/tms/Load/Load"))}
              onEnter={() => {
                this.authorize(["customer_service_load_info"]);
                this.setPageTitle("Dispatcher");
              }}
            />
            <Route
              path="/tms/Dispatcher/billing"
              onEnter={() => {
                !isNewBillingEnabled() && this.authorize(["customer_service_billing"]);
                this.setPageTitle("Dispatcher Billing");
              }}
              component={loadable(() =>
                import("./pages/tms/Load/BillOfLading")
              )}
            />
            <Route
              path="/tms/Dispatcher/documents"
              component={loadable(() => import("./pages/tms/Load/Documents"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Documents");
              }}
            />
            <Route
              path="/tms/Dispatcher/payments"
              component={loadable(() => import("./pages/tms/Load/Payment"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Payment");
              }}
            />
            <Route
              path="/tms/Dispatcher/orderstatus"
              component={loadable(() =>
                import("./pages/tms/LoadRouting")
              )}
              onEnter={() => {
                this.setPageTitle("Dispatcher Routing");
              }}
            />
            <Route
              path="/tms/Dispatcher/driverpay"
              component={loadable(() => {
                if (isManageFleetOrOutSource()?.isManageFleet) {
                  return import("./pages/tms/carrier/Transcation/NewTranscations")
                } else {
                  return import("./pages/NoMatchpage")
                }
              }
               
                )}
              onEnter={() => {
                this.setPageTitle("Dispatcher Driver Pay");
              }}
            />
             <Route
              path="/tms/Dispatcher/carrierpay"
              component={loadable(() => {
                if (isManageFleetOrOutSource()?.isOutSource) {
                  return import("../src/Components/CustomerServiceSideBar/Tabs/CarrierPayComponent/CarrierPay")
                } else {
                  return import("./pages/NoMatchpage")
                }
              }
              )}
              onEnter={() => {
                this.setPageTitle("Dispatcher CarrierPay");
              }}
            />
             <Route
              path="/tms/Dispatcher/payable-expenses"
              component={loadable(() => {
                if (isManageFleetOrOutSource()?.isOutSource) {
                  return import("../src/Components/CustomerServiceSideBar/Tabs/PayableExpenses")
                } else {
                  return import("./pages/NoMatchpage")
                }
              }
              )}
              onEnter={() => {
                this.setPageTitle("Dispatcher CarrierPay");
              }}
            />
            <Route
              path="/tms/Dispatcher/tracking"
              component={loadable(() => import("./pages/tms/Load/LoadHistory"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Tracking");
              }}
            />
            <Route
              path="/tms/Dispatcher/messaging"
              component={loadable(() =>
                import("./pages/tms/Load/OneLoadMessaging")
              )}
              onEnter={() => {
                this.setPageTitle("Dispatcher Message");
              }}
            />
            
            <Route
              path="/tms/Dispatcher/audit"
              component={loadable(() => import("./pages/tms/Load/AuditLoad"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Audit");
              }}
            />
            <Route
              path="/tms/Dispatcher/notes"
              component={loadable(() => import("./pages/tms/Load/Notes"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Notes");
              }}
            />
          </Route>
          <Route
            path="/tms/ai"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/Callback")
            )}
            onEnter={() => {
              this.setPageTitle("AI");
            }}
          />

          <Route
            path="/tms/drayosCarrierProfile"
            component={loadable(() => {
                if (isManageFleetOrOutSource()?.isOutSource) {
                  return import("./pages/tms/DrayosCarrier");
                } else {
                  return import("./pages/NoMatchpage");
                }
              }
            )}
            onEnter={() => {
              this.authorize(["other_profile_driver_shipos"]);
              this.setPageTitle("drayosCarrierProfile");
            }}
          />
          <Route
            path="/tms/drayos-carrier-rates"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isOutSource) {
                return import("./pages/tms/Load/DrayosCarrierRates")
              } else {
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Carriers Rates");
            }}
          />
          <Route
            path="/tms/carrier-tariffs"
            component={loadable(() => {
              if (isCarrierTariffEnabled()) {
                return import("./pages/tms/CarrierTariff")
              } else {
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Vendor Tariffs");
            }}
          />

          <Route
            path="/tms/carrier-charge-profile"
            component={loadable(() => {
              if (isCarrierTariffEnabled()) {
                return import("./pages/tms/CarrierTariff/CarrierChargeProfile/CarrierChargeProfile")
              } else {
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Vendor Charge Profiles");
            }}
          />

          <Route
            path="/tms/carrier-charge-profile-groups"
            component={loadable(() => {
              if (isCarrierTariffEnabled()) {
                return import("./pages/tms/CarrierTariff/CarrierChargeProfileGroup/CarrierChargeProfileGroup")
              } else {
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Vendor Charge Profile Groups");
            }}
          />

          {/* <Route path='/dynamicpricesetting' component={loadable(() => import('./ConditionTest'))} /> */}
          <Route
            path="/tms/Container"
            component={loadable(() => import("./pages/tms/Container"))}
            onEnter={() => {
              this.setPageTitle("Container");
            }}
          />
          <Route
            path="/tms/Driver"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/Driver")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["other_profile_driver"]);
              this.setPageTitle("Driver");
            }}
          />
          <Route
            path="/tms/Container/Owner"
            component={loadable(() => import("./pages/tms/Container/NewContainer/Owner/ContainerOwner"))}
            onEnter={() => {
              this.authorize(["other_container_owner", 'other_container_size', 'other_container_type', 'other_container_free_day']);
              this.setPageTitle("Container Owner");
            }}
          />
          <Route
            path="/tms/Container/Size"
            component={loadable(() => import("./pages/tms/Container/NewContainer/Size/ContainerSize"))}
            onEnter={() => {
              // this.authorize(["other_container_size"]);
              this.setPageTitle("Container Size");
            }}
          />
          <Route
            path="/tms/Container/Type"
            component={loadable(() => import("./pages/tms/Container/NewContainer/Type/ContainerType"))}
            onEnter={() => {
              // this.authorize(["other_container_type"]);
              this.setPageTitle("Container Type");
            }}
          />
          <Route
            path="/tms/Container/FreeDay"
            component={loadable(() => import("./pages/tms/Container/NewContainer/FreeDay/ContainerFreeDay"))}
            onEnter={() => {
              // this.authorize(["other_container_free_day"]);
              this.setPageTitle("Container Free day");
            }}
          />
          <Route
            path="/tms/Container/ISOCode"
            component={loadable(() => import("./pages/tms/Container/ISOCode"))}
            onEnter={() => {
              if(isISOTypeEnabled()){
                this.setPageTitle("Container ISO Code");
              }
            }}
          />
          <Route
            path="/tms/Chassis"
            component={loadable(() => import("./pages/tms/Chassis"))}
            onEnter={() => {
              this.setPageTitle("Chassis");
            }}
          />
          <Route
            path="/tms/Chassis/Owner"
            component={loadable(() => import("./pages/tms/Chassis/Owner"))}
            onEnter={() => {
              this.authorize(["other_chassis_owner", "other_chassis_size", "other_chassis_type"]);
              this.setPageTitle("Chassis Owner");
            }}
          />
          <Route
            path="/tms/Chassis/Size"
            component={loadable(() => import("./pages/tms/Chassis/Size"))}
            onEnter={() => {
              // this.authorize(["other_chassis_size"]);
              this.setPageTitle("Chassis Size");
            }}
          />
          <Route
            path="/tms/Chassis/Type"
            component={loadable(() => import("./pages/tms/Chassis/Type"))}
            onEnter={() => {
              // this.authorize(["other_chassis_type"]);
              this.setPageTitle("Chassis Type");
            }}
          />
          <Route
            path="/tms/load-price-settings"
            component={loadable(() =>
              import("./pages/tms/Load/loadPriceSettings")
            )}
            onEnter={() => {
              this.authorize(["other_customer_rate"]);
              this.setPageTitle("Customer Rates");
            }}
          />
          <Route
            path="/tms/customer-discount"
            component={loadable(() =>
              import("./pages/tms/Load/CustomerDiscount")
            )}
            onEnter={() => {
              // this.authorize(["other_customer_discount"]);
              this.setPageTitle("Customer Discount");
            }}
          />
          <Route
            path="/tms/customer-dashboard"
            component={loadable(() =>
              import("./pages/tms/carrier/CustomerDashboard")
            )}
            onEnter={() => {

              this.authorize(["customer_service_dashboard"]);
              this.setPageTitle("Customer Dashboard");
            }}
          />
          <Route
            path="/tms/load-quote-settings"
            component={loadable(() =>
              import("./pages/tms/Load/LoadQuoteSettings")
            )}
            onEnter={() => {
              this.authorize(["customer_service_quotes"]);
              this.setPageTitle("Load Quotes");
            }}
          />
          <Route
            path="/tms/customer-load-quote"
            component={loadable(() => {
              if (isManageFleetOrOutSource().isManageFleet || isManageFleetOrOutSource().isOutSource){
                return import("./pages/tms/Load/CustomerLoadQuoteSettings")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["customer_service_quotes"]);
              this.setPageTitle("Load Quotes");
            }}
          />

          <Route
            path="/tms/load-chat"
            component={loadable(() =>
              import("./pages/tms/carrier/LoadChat/LoadChat")
            )}
            onEnter={() => {
              // this.authorize(["customer_service_customer_messaging"]);
              this.setPageTitle("Chat Messages");
            }}
          />
          <Route
            path="/tms/payment-setting"
            component={loadable(() =>
              import("./pages/tms/DriverPayment/DriverPayment")
            )}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Payment Setting");
            }}
            />
            <Route
              path="/tms/driver-tariffs"
              component={loadable(() => {
                if (isDriverTariffEnabled()) {
                  return import("./pages/tms/DriverTariff");
                } else {
                  return import("./pages/NoMatchpage");
                }
              })}
              onEnter={() => {
                this.authorize(["other_driver_pay_rate"]);
                this.setPageTitle("Driver Tariffs");
              }}
            />
            <Route
              path="/tms/driver-charge-profiles"
              component={loadable(() => {
                if (isDriverTariffEnabled()) {
                  return import("./pages/tms/DriverTariff/DriverChargeProfile/DriverChargeProfile");
                } else {
                  return import("./pages/NoMatchpage");
                }
              })}
              onEnter={() => {
                this.authorize(["other_driver_pay_rate"]);
                this.setPageTitle("Driver Charge Profiles");
              }}
            />
            <Route
              path="/tms/driver-charge-profiles-group"
              component={loadable(() => {
                if (isDriverTariffEnabled()) {
                  return import("./pages/tms/DriverTariff/DriverChargeProfileGroup/DriverChargeProfileGroup");
                } else {
                  return import("./pages/NoMatchpage");
                }
              })}
              onEnter={() => {
                this.authorize(["other_driver_pay_rate"]);
                this.setPageTitle("Driver Profiles Groups");
              }}
            />
          <Route
            path="/tms/driver-pay-rates"
            component={loadable(() => {
              if (isManageFleetOrOutSource().isManageFleet){
                return import("./pages/tms/DriverPayRates")
              } else {
                return import("./pages/NoMatchpage")
              }
            })}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Driver Pay Rates");
            }}
          />
          <Route
            path="/tms/revoke-QuickBooks"
            component={loadable(() => import("./pages/tms/RevokePage/Revoke"))}
            onEnter={() => {
              this.setPageTitle("Revoke QuickBooks");
            }}
          />

          {/* ------------------------ Reports ------------------- */}

          <Route
            path="/tms/report/dashboards"
            component={loadable(() => {
              if (isChartsEnabled()){
                return import("./pages/tms/reports/Dashboards")
              } else {
                return import("./pages/NoMatchpage")
              }
            }
            )}
            onEnter={() => {
              this.authorize(["charts_dashboard"]);
              this.setPageTitle("Charts Dashboard");
            }}
          />

          <Route
            path="/tms/report/load-master-report"
            component={loadable(() => {
              return import("./pages/tms/reports/LoadMasterReport")
            }
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_master_load-master-report"]);
              this.setPageTitle("Master Reports");
            }}
          />
           <Route
            path="/tms/report/carrier-transaction-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isOutSource ) return import("./pages/tms/reports/CarrierTransactionReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["carrier_transaction_report"]);
              this.setPageTitle("Carrier Transaction Report");
            }}
          />
          <Route
            path="/tms/report/leg-type-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/LegTypeReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_leg_type"]);
              this.setPageTitle("Leg Type Reports");
            }}
          />
          <Route
            path="/tms/report/ForcastReport"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/carrier/CustomerDashboard")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_forecast_report"]);
              this.setPageTitle("Forecast Reports");
            }}
          />
            <Route
              path="/tms/report/aging1"
              component={loadable(() => {
                if (isNewBillingEnabled() && isManageFleetOrOutSource()?.isManageFleet ) {
                  return import("./pages/tms/reports/Aging/index")
                } else if(isManageFleetOrOutSource()?.isManageFleet) {
                  return import("./pages/tms/reports/AgingReportsNew")
                } else return import("./pages/NoMatchpage")
              }
              )}
            onEnter={() => {
              this.authorize(["reports", "reports_aging"]);
              this.setPageTitle("Aging Reports");
            }}
          />
          <Route
            path="/tms/report/agingChart"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/AgingChart")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.authorize(["reports_admin_dashboard"]);
              this.setPageTitle("Aging Chart");
            }}
          />
          <Route
            path="/tms/report/reportDashboard"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/ReportDashboard")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_admin_dashboard_2"]);
              this.setPageTitle("Report Dashboard");
            }}
          />
          <Route
            path="/tms/report/productivityReport"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/ProductivityReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_productivity_report"]);
              this.setPageTitle("Productivity Report");
            }}
          />
          <Route
            path="/tms/report/productivityReport2"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/ProductivityReport2")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_productivity_report_2"]);
              this.setPageTitle("Productivity Report 2");
            }}
          />
          <Route
            path="/tms/report/report1"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            } )}
            reportType="charge-report-1"
            onEnter={() => {
              this.authorize(["reports_charge_report"]);
              this.setPageTitle("Charge Report");
            }}
          />
          <Route
            path="/tms/report/vendorpay-breakdown"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isOutSource) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            } )}
            reportType="vendorpay-breakdown"
            onEnter={() => {
              this.authorize(["vendorpay_breakdown_report"]);
              this.setPageTitle("VendorPay BreakDown");
            }}
          />
          <Route
            path="/tms/report/charge-3"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/ChargeReportMain")
              else return import("./pages/NoMatchpage")
            })}
            reportType="charge-report-3"
            onEnter={() => {
              this.authorize(["reports_charge_report_3"]);
              this.setPageTitle("Charge Report 3");
            }}
          />
          <Route
            path="/tms/report/charge-4"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/ChargeReport4")
              else return import("./pages/NoMatchpage")
            })}
            reportType="charge-report-4"
            onEnter={() => {
              this.authorize(["reports_charge_report_4"]);
              this.setPageTitle("Charge Report 4");
            }}
          />

          <Route
            path="/tms/report/charge-5"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/ChargeReport5")
              else return import("./pages/NoMatchpage")
            })}
            reportType="charge-report-5"
            onEnter={() => {
              this.authorize(["reports_charge_report_4"]);
              this.setPageTitle("Charge Report 5");
            }}
          />
          <Route
            path="/tms/report/report2"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            } )}
            reportType="load-report"
            onEnter={() => {
              this.authorize(["reports_loads_report"]);
              this.setPageTitle("Load Report");
            }}
          />
          <Route
            path="/tms/report/report4"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="vessel-report"
            onEnter={() => {
              this.authorize(["reports_vessel_report"]);
              this.setPageTitle("Vessel Report");
            }}
          />
          <Route
            path="/tms/report/chassishistory"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/ChassisHistory")
              else return import("./pages/NoMatchpage")
            })}
            reportType="chassishistory"
            onEnter={() => {
              this.authorize(["reports_chassis_history"]);
              this.setPageTitle("Chassis History");
            }}
          />
          <Route
            path="/tms/report/report5"
            component={loadable(() => {
              return import("./pages/tms/reports/Report1")
            })}
            reportType="pricing-breakdown"
            onEnter={() => {
              this.authorize(["reports_pricing_breakdown"]);
              this.setPageTitle("Pricing BreakDown");
            }}
          />
          <Route
            path="/tms/report/invoice-breakdown"
            component={loadable(() => {
              return  import("./pages/tms/reports/Report1")
            })}
            reportType="invoice-breakdown"
            onEnter={() => {
              this.authorize(["reports_pricing_breakdown"]);
              this.setPageTitle("Invoice Breakdown");
            }}
          />
          <Route
            path="/tms/report/report6"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="H"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report7"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="I"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report8"
            component={loadable(() =>  {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="J"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report9"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="K"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report10"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            } )}
            reportType="L"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report11"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="M"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report12"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="N"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report13"
            component={loadable(() =>{
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="P"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report14"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="Q"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report15"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="R"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/support-move-report"
            component={loadable(() => {
                if (supportMoveEnabled() && !(this.loggedInUser?.role === "fleetmanager" && this.loggedInUser?.fleetManager?.isCustomer) && isManageFleetOrOutSource()?.isManageFleet) {
                  return import("./pages/tms/reports/SupportMoveReport")
                } else {
                  return import("./pages/NoMatchpage")
                }
              }
            )}
            onEnter={() => {
              this.authorize(["support-move-report"]);
              this.setPageTitle("Support Move Report");
            }}
          />
          <Route
            path="/tms/report/deduction-report"
            onEnter={() => {
              this.authorize(["deduction-report"]);
              this.setPageTitle("Deduction Report");
            }}
            component={loadable(() =>{
              if(isNewDeduction() && isManageFleetOrOutSource()?.isManageFleet){
                return import("./pages/tms/reports/DeductionReport")
              }else{
                return import("./pages/NoMatchpage")
              }
            })}
            reportType="deduction-report"
          />
          <Route
            path="/tms/report/summary-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="summary-report"
            onEnter={() => {
              this.authorize(["reports_summary_report"]);
              this.setPageTitle("Summary Report");
            }}
          />
          
          <Route
            path="/tms/report/equipment-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/EquipmentReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Equipment Report");
            }}
          />
          <Route
            path="/tms/report/revenue-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/RevenueReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_revenue_report"]);
              this.setPageTitle("Revenue Report");
            }}
          />
          <Route
            path="/tms/report/driver-pay-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/DriverPayReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_driver_pay"]);
              this.setPageTitle("Driver Pay Report");
            }}
          />
          <Route
            path="/tms/report/driver-pay-summary-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/DriverPaySummaryReport")
              else return import("./pages/NoMatchpage")
            }
             
            )}
            onEnter={() => {
              this.authorize(["reports_driver_pay_summary"]);
              this.setPageTitle("Driver Pay Summary Report");
            }}
          />
          <Route
            path="/tms/report/lfd-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/LFDReport")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.authorize(["reports_lfd_report"]);
              this.setPageTitle("LFD Report");
            }}
          />
          <Route
            path="/tms/report/return-report"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/tms/reports/ReturnReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_return_report"]);
              this.setPageTitle("Return Report");
            }}
          />
          <Route
            path="/tms/report/aging/:customerID"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/tms/reports/AgingReportsNew")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Aging Report");
            }}
          />
          <Route
            path="/tms/report/revenue"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/AccountingRevenue")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_customer_revenue"]);
              this.setPageTitle("Accounting Revenue");
            }}
          />
          <Route
            path="/tms/report/driverrevenue"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/DriverRevenue")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_driver_revenue"]);
              this.setPageTitle("Driver Revenue");
            }}
          />
          <Route
            path="/tms/report/newdriverrevenue"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/newDriverRevenue")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_new_driver_revenue"]);
              this.setPageTitle("New Driver Revenue");
            }}
          />
          <Route
            path="/tms/report/driverstatusreport"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/tms/reports/DriverStatusReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_status_driver"]);
              this.setPageTitle("Driver Status Report");
            }}
          />
          <Route
            path="/tms/report/driverclockinoutreport"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/tms/reports/DrvierClockInOut")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.authorize(["reports_clock_report"]);
              this.setPageTitle("Driver Clock In Out Report");
            }}
          />
          <Route
            path="/tms/report/loaddriverrevenue"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/tms/reports/LoadDriverRevenue")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Load Driver Revenue");
            }}
          />
          <Route
            path="/tms/report/customer-list"
            onEnter={() => {
              this.setPageTitle("Customer List");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="customer-list"
          />
          <Route
            path="/tms/report/pricing-load-report"
            onEnter={() => {
              this.authorize(["reports_loads_report_pricing"]);
              this.setPageTitle("Pricing Load Report");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="pricing-load-report"
          />
          <Route
            path="/tms/report/charge-report-2"
            onEnter={() => {
              this.authorize(["reports_charge_report_2"]);
              this.setPageTitle("Charge Report 2");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="charge-report-2"
          />
          <Route
            path="/tms/report/HWB-report"
            onEnter={() => {
              this.authorize(["report_HWB"]);
              this.setPageTitle("HWB Import Report");
            }}
            component={loadable(() => {
                if (JSON.parse(getStorage("userBasicSettings") ?? "{}")?.HWBImportReportEnabled && !(this.loggedInUser?.role === "fleetmanager" && this.loggedInUser?.fleetManager?.isCustomer) && isManageFleetOrOutSource()?.isManageFleet) {
                  return import("./pages/tms/reports/HWBReport")
                } else {
                  return import("./pages/NoMatchpage")
                }
              }
            )}
            reportType="HWB-report"
          />
          <Route
            path="/tms/report/expense"
            onEnter={() => {
              this.authorize(["report_expense"]);
              this.setPageTitle("Expense Report");
            }}
            component={loadable(() => {
                if (isNewBillingEnabled()) {
                  return import("./pages/tms/reports/ExpenseReport");
                } else {
                  return import("./pages/NoMatchpage");
                }
              }
            )}
            reportType="expense-report"
          />
          <Route
            path="/tms/report/import-charge-report"
            onEnter={() => {
              this.authorize(["reports_import_charge"]);
              this.setPageTitle("Import Charge Report");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/ChargeReport")
              else return import("./pages/NoMatchpage")
            }
             
            )}
            reportType="import-charge-report"
          />
          <Route
            path="/tms/report/export-charge-report"
            onEnter={() => {
              this.authorize(["reports_export_charge"]);
              this.setPageTitle("Export Charge Report");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/ChargeReport")
              else return import("./pages/NoMatchpage")
            }
            )}
            reportType="export-charge-report"
          />
          <Route
            path="/tms/report/load-driver-revenue"
            onEnter={() => {
              this.setPageTitle("Driver Revenue");
            }}
            component={loadable(() =>  {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/Report1")
              else return import("./pages/NoMatchpage")
            })}
            reportType="load-driver-revenue"
          />
          <Route
            path="/tms/report/profitability-report"
            onEnter={() => {
              this.authorize(["reports_profitability_report"]);
              this.setPageTitle("Profitability Report");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/ProfitabilityReport")
              else return import("./pages/NoMatchpage")
            })}
            reportType="profitability-report"
          />
          <Route
            path="/tms/report/clock-in-clock-out-report"
            onEnter={() => {
              this.authorize(["reports_clock_in_out"]);
              this.setPageTitle("ClockIn-ClockOut Report");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/ClockInAndClockOut")
              else return import("./pages/NoMatchpage")
            })}
            reportType="clockIn-clockOut-report"
          />
          <Route
            path="/tms/report/external-charge"
            onEnter={() => {
              this.authorize(["reports_external_charge_report"]);
              this.setPageTitle("External Charge");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/ExternalCharge")
              else return import("./pages/NoMatchpage")
            }
            )}
            reportType="external-charge-report"
          />
          <Route
            path="/tms/report/email-audit"
            onEnter={() => {
              this.authorize(["reports", "email_audit_report"]);
              this.setPageTitle("Email Audit Report");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/admin/EmailAuditReport/index")
              else return import("./pages/NoMatchpage")
            }
            )}
          />
          <Route
            path="/tms/report/on-time-performance-report"
            component={loadable(() => {
                if (isManageFleetOrOutSource()?.isManageFleet) return   import("./pages/tms/reports/OnTimePerformanceReport")
                else return import("./pages/NoMatchpage")
              }
            )}
            onEnter={() => {
              this.authorize(["on_time_performance_report"]);
              this.setPageTitle("On-Time Performance Report");
            }}
          />

          <Route
            path="/tms/report/credit-memo-report"
            onEnter={() => {
              this.authorize(["reports_credit_memo_report"]);
              this.setPageTitle("Credit Memo Report");
            }}
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return  import("./pages/tms/reports/CreditMemoReport/CreditMemoReport.c")
              else return import("./pages/NoMatchpage")
            })}
            reportType="Credit-Memo-Report"
          />
          <Route
            path="/tms/report/equipment-issues"
            onEnter={() => {
              this.setPageTitle("Custom Reports");
            }}
            component={loadable(() => {
              const loggedInUser = JSON.parse(getStorage('loggedInUser'));
              if(["carrier", "fleetmanager"].includes(loggedInUser?.role)) return  import("./pages/tms/reports/EquipmentIssues/index.js")
              return import("./pages/NoMatchpage")
            })}
            reportType="Credit-Memo-Report"
          />
          {/* <Route
            path="/tms/customer-employees"
            component={loadable(() => import("./pages/tms/CustomerEmployees"))}
            onEnter={() => {
              this.setPageTitle("Employees");
            }}
          /> */}
          <Route
            path="/tms/AppointmentList"
            component={loadable(() => import("./pages/tms/AppointmentList"))}
            onEnter={() => {
              this.authorize(["appointment_list"]);
              this.setPageTitle("Appointment Lists");
            }}
          />
          <Route
            path="/tms/problem-sync"
            component={loadable(() => import("./pages/tms/ProblemAccountingService/index.js"))}
            onEnter={() => {
              this.authorize(["billing_problem_invoices"]);
              this.setPageTitle("Desynced QuickBooks Invoices");
            }}
          />
          <Route
            path="/tms/load-tariffs"
            component={loadable(() => import("./pages/tms/CustomerRates"))}
            onEnter={() => {
              this.authorize(["customer_rates", "other_customer_rate"]);
              this.setPageTitle("Load Tariffs");
            }}
          />
          <Route
            path="/tms/charge-profiles"
            component={loadable(() => import("./pages/tms/CustomerRates/ChargeProfiles"))}
            onEnter={() => {
              this.authorize(["charge_profiles","other_customer_rate"]);
              this.setPageTitle("Charge Profiles");
            }}
          />
          <Route
            path="/tms/charge-profiles-groups"
            component={loadable(() => import("./pages/tms/CustomerRates/ChargeGroups"))}
            onEnter={() => {
              this.authorize(["charge_groups","other_customer_rate"]);
              this.setPageTitle("Charge Profiles Groups");
            }}
          />

          {/* --------------------- for admin ------------------------*/}
          <Route
            path="/administrator"
            component={loadable(() => import("./pages/admin/login/AdminLogin"))}
            onEnter={() => {
              this.setPageTitle("Administrator");
            }}
          />
          <Route
            path="/admin"
            component={loadable(() => {
              if(isAdminCustomerPortalPermission()) return import("../src/Components/SupportRole");
              else return import("./pages/admin/dashboard/AdminCheck")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Admin");
            }}
          >
            {!isAdminCustomerPortalPermission() && 
            <>
            <IndexRoute
              component={loadable(() =>
                import("./pages/admin/dashboard/Dashboard")
              )}
              onEnter={() => {
                this.setPageTitle("Admin Dashboard");
              }}
            />
            <Route
              path="/carrier"
              component={loadable(() =>
                import("./pages/admin/dashboard/Carrier")
              )}
              onEnter={() => {
                this.setPageTitle("Carrier");
              }}
            />
            <Route
              path="/company"
              component={loadable(() =>
                import("./pages/admin/dashboard/Company")
              )}
              onEnter={() => {
                this.setPageTitle("Company");
              }}
            />
            </>}
          </Route>
          {/* --------------------- for admin ------------------------*/}
          <Route
            path="/customer-shipments"
            component={loadable(() => {
              if (isManageFleetOrOutSource()?.isManageFleet) return import("./pages/trucker/NewTrack/CustomerTrack")
              else return import("./pages/NoMatchpage")
            }

            )}
            onEnter={() => {
              this.authorize(["customer_shipments"]);
              this.setPageTitle("Customer Shipments");
            }}
          />
          <Route
            path="/admin/company-tms"
            component={loadable(() =>
              import("./pages/admin/dashboard/AddCompanyTMS")
            )}
            onEnter={() => {
              this.setPageTitle("Company Tms");
            }}
          />
          <Route
            path="/admin/short-name"
            component={loadable(() =>
              import("./pages/admin/dashboard/ShortNameCompany")
            )}
            onEnter={() => {
              this.setPageTitle("Short Name Company");
            }}
          />
          <Route
            path="/admin/locked-accounts"
            component={loadable(() =>
              import("./pages/admin/dashboard/LockedAccounts")
            )}
            onEnter={() => {
              this.setPageTitle("Locked Account");
            }}
          />
          <Route
            path="/admin/viewpayments/:id"
            component={loadable(() =>
              import("./pages/admin/dashboard/ViewPayments")
            )}
            onEnter={() => {
              this.setPageTitle("View Payments");
            }}
          />
          <Route
            path="/admin/PreRegisterUsers"
            component={loadable(() =>
              import("./pages/admin/dashboard/PreRegisterUsers")
            )}
            onEnter={() => {
              this.setPageTitle("Pre Register Users");
            }}
          />
          <Route
            path="/admin/CarrierFlagEnabled"
            component={loadable(() =>
              import("./pages/admin/dashboard/CarrierFlagsEnable")
            )}
            onEnter={() => {
              this.setPageTitle("Carrier Flag Enabled");
            }}
          />
          <Route
            path="/admin/CarrierFlagDetails"
            component={loadable(() =>
              import("./pages/admin/dashboard/CarrierFlagDetails")
            )}
            onEnter={() => {
              this.setPageTitle("Carrier Flag Details");
            }}
          />
          <Route
            path="/admin/TestAccountFlag"
            component={loadable(() =>
              import("./pages/admin/dashboard/TestAccountFlag")
            )}
            onEnter={() => {
              this.setPageTitle("Test Account Flag");
            }}
          />
          <Route
            path="/admin/chassis/size"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("./pages/admin/Chassis/Size")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Chasis Size");
            }}
          />
          <Route
            path="/admin/chassis/type"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("./pages/admin/Chassis/Type")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Chasis Type");
            }}
          />
          <Route
            path="/admin/chassis/owner"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("./pages/admin/Chassis/Owner")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Chasis Owner");
            }}
          />
          <Route
            path="/admin/container/size"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("./pages/admin/Container/Size")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Container Size");
            }}
          />
          <Route
            path="/admin/container/type"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("./pages/admin/Container/Type")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Container Type");
            }}
          />
          <Route
            path="/admin/container/Owner"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("./pages/admin/Container/Owner")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Container Owner");
            }}
          />
          <Route
            path="/admin/customers"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("./pages/admin/Customer")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Customers");
            }}
          />
          {/* =========== for support ============= */}
          <Route
            path="/admin/account-status"
            component={loadable(() => import("../src/Components/SupportRole"))}
            onEnter={() => {
              this.setPageTitle("Account Status");
            }}
          />

          <Route
            path="/admin/users"
            component={loadable(() => {
              if(isSuperAdminUser()) return import("../src/Components/SupportRole/Users")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Users");
            }}
          />

          <Route
            path="/admin/departments"
            component={loadable(() => {
              if(!isAdminCustomerPortalPermission() && JSON.parse(getStorage("loggedInUser"))?.role === "admin") return import("../src/Components/SupportRole/Departments")
              else return import("./pages/NoMatchpage")
            }
            )}
            onEnter={() => {
              this.setPageTitle("Departments");
            }}
          />

          <Route
            path="/admin/login-history"
            component={loadable(() =>{
              if(!isAdminCustomerPortalPermission() && JSON.parse(getStorage("loggedInUser"))?.role === "admin") return import("../src/Components/SupportRole/Audit")
              else return import("./pages/NoMatchpage")
            })}
            onEnter={() => {
              this.setPageTitle("Audit");
            }}
          />

          <Route
            path="/admin/quickbookServices/loads"
            component={loadable(() => import("../src/Components/SupportRole/LoadIndex"))}
            
            onEnter={() => {
              this.setPageTitle("Loads");
            }}
          />

          <Route 
            path="/admin/quickbookServices/qbDesktopCarriers"
            component={loadable(()=> import('../src/Components/SupportRole/qbdCarriers'))}
          />

            <Route
              path="/admin/Invoices"
              component={loadable(() => import("../src/Components/SupportRole/AdminInvoices"))}

              onEnter={() => {
                this.setPageTitle("Admin Invoices");
              }}
            />

            <Route
              path="/admin/public-api"
              component={loadable(() => import("../src/Components/SupportRole/PublicApiCredentials"))}

              onEnter={() => {
                this.setPageTitle("PublicApi Credentials");
              }}
            />

          <Route
            path="/admin/quickbookServices/Customers"
            component={loadable(() => import("../src/Components/SupportRole/CustomerQbIdUpdate"))}

            onEnter={() => {
              this.setPageTitle("Customers");
            }}
          />

          <Route
            path="/admin/quickbookServices/Payments"
            component={loadable(() => import("../src/Components/SupportRole/PaymentQbIdUpdate"))}

            onEnter={() => {
              this.setPageTitle("Payments");
            }}
          />

          <Route
            path="/admin/quickbookServices/updateTerminals"
            component={loadable(() => import("../src/Components/SupportRole/TerminalQbIdUpdate"))}
            onEnter={() => {
              this.setPageTitle("Update Terminal");
            }}
          />

          <Route
            path="/admin/quickbookServices/updateChargeCode"
            component={loadable(() => import("../src/Components/SupportRole/CarrierChargeQbIdUpdate"))}
            onEnter={() => {
              this.setPageTitle("Update ChargeCode");
            }}
          />
          
          <Route
            path="/admin/alertSystem"
            component={loadable(() => import("./pages/admin/AlertSystem/AlertSystem"))}
            onEnter={() => {
              this.setPageTitle("Alert System");
            }}
          />  

          {/* currencies route */}
          <Route
            path="/admin/currency"
            component={loadable(() => import("./pages/admin/Currency/CurrencyC"))}
            onEnter={() => {
              this.setPageTitle("Currency");
            }}
          />
          <Route
            path="/admin/currency-exchange-rate"
            component={loadable(() => import("./pages/admin/Currency/CurrencyExchangeRate"))}
            onEnter={() => {
              this.setPageTitle("Currency Exchange Rate");
            }}
          />

            {/* =========== for report V2 ============= */}
            <Route
              path="/tms/report-v2/orders-and-loads"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/OrderAndLoads/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Orders & Loads");
              }}
            />
            <Route
              path="/tms/report-v2/load-or-move-completion"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/LoadOrMoveCompletion/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Load/Move Completion");
              }}
            />
            <Route
              path="/tms/report-v2/loads-by-csr"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/LoadsByCSR/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Loads by CSR");
              }}
            />
            <Route
              path="/tms/report-v2/loads-by-dispatcher"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/LoadsByDispatcher/index.js")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Loads by Dispatcher");
              }}
            />
            <Route
              path="/tms/report-v2/driver-performance"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/DriverPerformance/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Driver Performance");
              }}
            />

            <Route
              path="/tms/report-v2/revenue-by-yard"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/RevenueByYard/index.js")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Revenue by Yard");
              }}
            />
            <Route
              path="/tms/report-v2/revenue"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/Revenue/index.js")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Revenue by Customer");
              }}
            />
            <Route
              path="/tms/report-v2/load-invoicing"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/LoadInvoicing/index.js")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Load invoiceing");
              }}
            />
            <Route
             path="/tms/report-v2/load-revenue"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/LoadRevenue/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Load Revenue");
              }}
            />

            <Route
              path="/tms/report-v2/invoices-by-charge-type"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/InvoicesByChargeType/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Invoices by Charge Type");
              }}
            />

            <Route
              path="/tms/report-v2/load-timing"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/LoadTiming/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Load Timing");
              }}
            />

            <Route
              path="/tms/report-v2/payable-legs-and-charge-legs"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/PayableLegsAndChargeLegs/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Payable Legs & Charge Legs");
              }}
            />
            <Route
              path="/tms/report-v2/accounts_payable"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/HighestDriversAndLegTypes/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Highest Drivers And Leg Types");
                this.authorize(["highest_drivers_leg_types"]);
              }}
            />
            <Route
              path="/tms/report-v2/equipment"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/Equipment/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("Equipment");
                this.authorize(["equipment"]);
              }}
            />
            <Route
              path="/tms/report-v2/autopopulatedFieldbytrack"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/AutopopulatedFieldbytrack/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("AutopopulatedFieldbytrack");
                this.authorize(["autopopulatedFieldbytrack"]);
              }}
            />
            <Route
              path="/tms/report-v2/cronReports"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/CronReports/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("CronReports");
                this.authorize(["cronReports"]);
              }}
            />
            <Route
              path="/tms/report-v2/documentUpoads"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/DocumentUploads/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("DocumentUpoads");
                this.authorize(["documentUpoads"]);
              }}
            />
            <Route
              path="/tms/report-v2/loadStatus"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/LoadStatus/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("LoadStatus");
                this.authorize(["loadStatus"]);
              }}
            />
            <Route
              path="/tms/report-v2/automatic-vs-manual-charges"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/AutomaticVsManualCharges/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("AutomaticVsManualCharges");
                this.authorize(["automatic-vs-manual-charges"]);
              }}
            />
            <Route
              path="/tms/report-v2/automatic-vs-manual-driverPay"
              component={loadable(() => {
                if(JSON.parse(getStorage("userBasicSettings"))?.isReportV2) {
                  return import("../src/pages/tms/ReportsV2/AutomaticVsManualDriverPay/index")
                }else{
                  return import("./pages/NoMatchpage")
                }
              })}
              onEnter={() => {
                this.setPageTitle("AutomaticVsManualDriverPay");
                this.authorize(["automatic-vs-manual-driverPay"]);
              }}
            />
          {/* portpro-connect route */}
          <Route
            path="/admin/portpro-connect"
            component={loadable(() => import("./pages/admin/PortProConnect/index"))}
            onEnter={() => {
              this.setPageTitle("PortPro Connect");
            }}
          />  
          <Route
            path="/admin/emailAuditReport"
            component={loadable(() => import("./pages/admin/EmailAuditReport/index"))}
            onEnter={() => {
              this.setPageTitle("Email Audit Report");
            }}
          />
          <Route
            path="/admin/update-charge-codes"
            component={loadable(() =>
              import("./pages/admin/TerminalChargeCode/UpdateChargeCodes")
            )}
            onEnter={() => {
              this.setPageTitle("Update Charge Code");
            }}
          />
          
          <Route
            path="/upgrade"
            component={loadable(() => import("./Components/Common/UpgradeToPro/index"))}
            onEnter={() => {
              this.setPageTitle("Upgrade To Pro");
            }}
          />   
          <Route
            path="*"
            component={loadable(() => import("./pages/NoMatchpage"))}
            onEnter={() => {
              this.setPageTitle("404 Page Not Found");
            }}
          />
        </Route>
      </Router>
      </>
    );
  }
}
// export default Routes
function mapStateToProps(state) {
  return {
    user: state.LoginReducer,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getPortMarkets }, dispatch),
  commonActions: bindActionCreators(commonActionCreators, dispatch),
  dispatch,
});

export default withAuth0(connect(mapStateToProps, mapDispatchToProps)(Routes));
