import { useState } from 'react';
import { Bar, BarChart, Cell, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Popup from "../Popup";
import CustomBarChartToolTip from './CustomBarChartToolTip';
import { fixedNumer } from '../../helper';

const CustomBarLabel = (props) => {
    const { x, y, width, height, value, isToFixedNeeded } = props;
    const formattedValue = isToFixedNeeded ? fixedNumer(value) : value;
    return (
      <text
        x={x + width + 1}
        y={y + height / 2}
        dy={"0.355em"}
        textAnchor="start"
        fill="currentColor"
        className="text-dark"
        fontSize={12}
      >
        {formattedValue}
      </text>
    );
  };

const HorizontalBarChart = (props) => {
    const { data = [],
        xAxisDataType,
        yAxisDataType,
        xAxisDomain,
        yAxisDataKey,
        showToolTip,
        showLegend,
        barValueKey,
        reportType,
        downloadCSV,
        startDate,
        endDate,
        isPopup,
        setIsPopup,
        tooltipPostFix,
        isToFixedNeeded,
    } = props

    const [activeIndex, setActiveIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    const handleBarClick = () => {
        setIsPopup(true)
     };
     const handleOnCloseOfPopup = () => {
         setIsPopup(false)
     }

    // Function to format X-axis ticks
    const formatXAxis = (value) => {
        // Only show ticks for the minimum and maximum values
        const domain = xAxisDomain || [0, 1500];
        return value === domain[0] || value === domain[1] ? parseFloat(value?.toFixed(2)) : '';
    };

    const CustomYAxisTick = ({ x, y, payload }) => {
        const label = payload.value.length > 10 ? `${payload.value.slice(0, 10)}...` : payload.value;

        return (
            <text
                x={x}
                y={y}
                dy={4}
                textAnchor="end"
                style={{
                    fontSize: '12px',
                    fill: 'currentColor',
                    whiteSpace: 'nowrap',
                    overflow: 'visible',
                    textOverflow: 'ellipsis',

                }}
            >
                {label}
            </text>
        );
    };

    return (
        <>
        
                <ResponsiveContainer height={'50%'} maxHeight={"100%"} maxWidth={"100%"} className="flex-grow-1">
                    <BarChart
                        layout="vertical"
                        data={data}
                        margin={{
                            right: 30,
                            left: 30,
                          }}
                    >
                        <XAxis
                            type={xAxisDataType}
                            domain={xAxisDomain}
                            tickFormatter={formatXAxis}
                            tickLine={false}
                            stroke="rgba(240, 240, 240, 1)"
                            tick={{ fill: 'currentColor' }}
                        />
                        <YAxis
                            type={yAxisDataType}
                            dataKey={yAxisDataKey}
                            tick={<CustomYAxisTick />}
                            tickLine={false}
                            interval={0}
                            stroke="rgba(240, 240, 240, 1)"
                        />
                        {showToolTip && 
                            <Tooltip  
                                content={<CustomBarChartToolTip 
                                    tooltipPostFix={tooltipPostFix}
                                    reportType={reportType}
                                    isToFixedNeeded={isToFixedNeeded}
                                    />} 
                                cursor={{ fill: 'transparent'}} 
                                shared={false}
                            />}
                        {showLegend && <Legend />}
                        <Bar
                            dataKey={barValueKey}
                            className='text-primary'
                            fill="currentColor"
                            radius={[0, 5, 5, 0]}
                            onMouseEnter={(data, index) => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleBarClick}
                        >
                            {data?.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill="currentColor"
                                    opacity={activeIndex === index || activeIndex === null ? 1 : 0.5}
                                  
                                />
                            ))}
                            <LabelList
                                dataKey={barValueKey}
                                content={<CustomBarLabel isToFixedNeeded={isToFixedNeeded}/>}
                                position="right"
                            />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            {isPopup &&
                <Popup
                    rows={data}
                    downloadCSV={downloadCSV}
                    reportType={reportType}
                    isPopup={isPopup}
                    handleOnClose={handleOnCloseOfPopup}
                    fromDate={startDate}
                    toDate={endDate}
                />
            }
            </>
    );
};

export default HorizontalBarChart;