import React, { Component } from "react";
import { Field } from "redux-form";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  getTerminal,
  removeValueLoad,
  getTrailers,
  getTrailerTypes,
  getTrailerSizes,
  editLoad,
  UpdatePricing,
} from "../services";
import configuration from "../../../config";
import {
  toastr,
  makeOptionsForSelect,showForTerminal,
  getStorage,
  isMilitaryTime,
  DateTimeFormatUtils,
  isGlobalizationEnabled,
  getSyncConfig,
  isConnectedWithOutSource,
  isManageFleetOrOutSource,
  isSyncRequest,
  amplitudeTrack,
} from "./../../../services/Common.services";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import { InputField, SelectField } from "../formComponent";
import Select from "react-select";
import { ALL_COMPLETED_LOADS_STATUS, SYNC_EVENT_TYPE, SYNC_REQUEST_SENT, getAlertSyncMessage, getMappedObjForSync, getSyncingKeys, loadFlags, updateClassInElements } from "./utility";

import {
  getTMSshipper,
  getTMSconsignee,
  getTMScaller,
  getCommodityProfile,
} from "../Dispatcher/actionCreators";
import {
  IconCalendar,
  IconCancel,
  IconDelete,
  IconMinus,
  IconPlus,
  IconTimes,
} from "../../../Components/Common/Icons";
import { newSmallSelectStyle, smallSelectStyle } from "../../../assets/js/select-style";
import _ from "lodash";
import confirm from "../../../Components/Common/ConfirmAert";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import CommodityComponent from "./CommodityComponent";
import { RoutingOnly } from "../../../Components/RoutingOnly/RoutingOnly";
import PopperTooltip from "../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import { Modal } from "react-bootstrap";
import RoutingEvent from "../LoadRouting/RoutingEvent";
import { getLoadSyncRequests } from "./actionCreator";
import SyncError from "./SyncError";
import SingleLoadSelection from "../ConnectNotices/LoadInfoChanges/SingleLoadSelection";
import { REQUEST_TYPES, STATUS, alertMessageType } from "../ConnectNotices/constant";
import ReviewButton from "./ReviewButton";
import { loadEvents } from "../constant";
import UpdateBillCharge from "../../tms/Load/UpdateBillCharge";
import { checkAccountReceivablePermission } from "../../../services";

const firebase = configuration.firebase;

const commodityItems = {
  commodity: "",
  description: "",
  pieces: null,
  units: null,
  weight: null,
  weightKgs: null,
  weightUnitType: "",
  pallets: null,
  palletsUnits: "",
};

class NewRoadLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminals: [],
      commodityProfiles: [],
      Terminals: [],
      items: isConnectedWithOutSource() ? [] : [{ ...commodityItems }],
      terminal:null,
      addCommodity: false,
      showDriverOrderStatus: false,
      isReviewModalVisible: false,
      alertMessages: [],
      pendingSyncRequests: [],
      syncLoader: true,
      syncBanner: true,
      totalRecievedSyncRequests: [],
      newSyncRequests : [],
      processingRequest:false,
      isShowUpdateModal : false,
      customerInfo : null
    };
    this.willCall = {};
    this.clearInfosOnAdd = this.clearInfosOnAdd.bind(this);
    this.onRemovePress = this.onRemovePress.bind(this);
    this.userTimeFormat = isMilitaryTime() ? DateTimeFormatUtils.fullDateTimeFormat() : "";
    this.timeZone = getStorage("timeZone");
    this.isUpdateConsigneeRouting = JSON.parse(getStorage("userBasicSettings"))?.isUpdateConsigneeRouting;
    this.isShowDeliveryReferenceField = JSON.parse(getStorage("userBasicSettings"))?.isShowDeliveryReferenceField;
    this.shiposOrConnectCarrier = isConnectedWithOutSource() && isSyncRequest();
    this.isManageFleetOrOutSource = isManageFleetOrOutSource()
    this.handleProcessingRequest = this.handleProcessingRequest.bind(this)
    this.isShowLiftOffReferenceField = JSON.parse(getStorage("userBasicSettings"))?.isShowLiftOffReferenceField;
  }

  handleProcessingRequest = (processingRequest) => {
    this.setState({processingRequest})
    setTimeout(() => {
      this.setState({ processingRequest: false });
    }, 5000); 
  }

  UNSAFE_componentWillReceiveProps(props) {
    let self = this;
    if(this.shiposOrConnectCarrier && this.state.syncLoader){
      const syncFields = getSyncConfig().syncConnectFields;
      this.loadingSync(syncFields, props.customerProfile)
    }
    if (props.Terminals && props.Terminals.length > 0) {
      self.setState({ Terminals: props.Terminals });
    }
    if (props.customerProfile.terminal) {
      if(props.customerProfile.terminal._id){
        this.setState({ terminal:{value: props.customerProfile.terminal._id, label:props.customerProfile.terminal.name} });  
      }
      if(props.customerProfile.terminal.value){
        this.setState({ terminal:props.customerProfile.terminal });  
      }
      
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      getTerminal().then((response) => {
        const Terminals = response.data.data ? response.data.data : [];
        self.setState({ Terminals });
      });
    }
  }
  repetiveAPICall = (obj) => {
    for(const property in obj){
      let finalObj = {
        reference_number: this.props.customerProfile.reference_number,
        [property]: obj[property],
        source: "loadinfo",
      }
        if(property === "containerNo") finalObj.isContainerConfirm = true
        editLoad(finalObj).then(()=>{}).catch(()=>{})
    }
    this.willCall = Object.assign({})
  }

  onRemovePress(e, input) {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      key: input.name,
    };
    removeValueLoad(params).then((result) => {
      this.willCall = Object.assign({})
      const element = document.querySelector(`.${input.name}`);
      let dates = ["Date","Day","vessel","return","cutOff","loadTime"];
      const isDate = dates.some((str)=>input.name.includes(str))
      const divInElement = isDate ? element?.querySelector("input") : element?.querySelector("div");
      const elementToChange = divInElement ? divInElement : element;
      elementToChange?.classList.remove("background-failure");
      elementToChange?.classList.add("background-success");
      setTimeout(()=>{
        elementToChange?.classList.remove("background-success","text-danger");
      },400)
      this.props.updateState(input.name, result.data.data[input.name]);
    });
  }
  onRemovePressOnDate(e, input, index) {
    let params = {
      reference_number: this.props.customerProfile.reference_number,
    };
    let { customerProfile } = this.props;
    delete customerProfile[`${input}Times`][index][`${input}FromTime`];
    delete customerProfile[`${input}Times`][index][`${input}ToTime`];
    params[`${input}Times`] = customerProfile[`${input}Times`];
    params[`${input}Times`].map((d) => {
      delete d._id;
      Object.keys(d).map((key) => {
        if (!d[key]) delete d[key];
      });
    }); // delete _id and falsy keys
    const finalParams = params[`${input}Times`].filter((obj) => !_.isEmpty(obj));
    params[`${input}Times`] = JSON.stringify(finalParams);
    let arr =
      input.indexOf("pickup") > -1
        ? `${input}From` === "pickupFrom"
          ? [`.pickupTo-${index}`, `.pickupFrom-${index}`]
          : [`.pickupTo-${index}`]
        : `${input}From` === "deliveryFrom"
        ? [`.deliveryTo-${index}`, `.deliveryFrom-${index}`]
        : [`.deliveryTo-${index}`];
        
      const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
      
      editLoad(params).then((res) => {
        if(res?.data?.data === SYNC_REQUEST_SENT){
          toastr.show(SYNC_REQUEST_SENT, "success");
          this.fetchSyncChanges(true);
       } else {
        delete params.source;
        let eventProperties = {
          source: "load_info_modal",
          fieldName: `${input}Times`,
          reference_number: this.props.customerProfile.reference_number
        }
        amplitudeTrack("EDIT_LOAD",eventProperties)
       }
        if (res.status === 200) {
          elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
          this.props.updateState(
            `${input}Times`,
            customerProfile[`${input}Times`]
          );
        }
      });
  }
  clearInfosOnAdd() {
    this.setState({
      caller: "",
      shipper0: "",
      consignee0: "",
    });
  }

  onPickDeliveryChanges = (time,update,i) => {
    let Times = [];
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    

    this.props.customerProfile[field].forEach((el,i)=>{
      el._id && Times.push({ ...this.props.customerProfile[controlName][i], customerId: el._id })
    })
    let filteredPickup = JSON.stringify(Times)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [controlName]: filteredPickup,
      }
      return
    }
    let arr =
    time.indexOf("pickup") > -1
      ? update === "pickupFrom"
        ? [`.pickupTo-${i}`, `.pickupFrom-${i}`]
        : [`.pickupTo-${i}`]
      : update === "deliveryFrom"
      ? [`.deliveryTo-${i}`, `.deliveryFrom-${i}`]
      : [`.deliveryTo-${i}`];

    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      } else {
        let eventProperties = {
          source: "load_info_modal",
          fieldName: field,
          reference_number: this.props.customerProfile.reference_number
        }
        amplitudeTrack("EDIT_LOAD",eventProperties)
      }
      Times = [];
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}
  onReConfirmUpdate = (data,reference_number,key) => {
    let title = "Container: " + data[key];
    let body = (
      <div>
        <h4>
            This container is already in the system.
        </h4>
        <div>
          <p>
            Do you want to save the{" "}
            {"Container: " + data[key]}{" "}
            to Load {reference_number} anyway?
          </p>
        </div>
      </div>
    );
    confirm(title, body, (confirm) => {
      if (confirm) {
        data.isContainerConfirm = true
        return editLoad(data).then((result)=>{
          const element = document.querySelector(`.${key}`);
          element?.classList.add("border-success");
          setTimeout(()=>{
            element?.classList.remove("border-success","border-red","text-danger");
          },3000)
        }).catch((err) => {
          const element = document.querySelector(`.${key}`);
          element?.classList.add("border-red","text-danger")
      });
      } else {
        return
      }
    });
};

  onSelectFieldChange = (key,value,isUpdateBillToCustomer = false) => {
    const element = document.querySelector(`.${key}`);
    const divInElement = element?.querySelector("div");
    const elementToChange = divInElement ? divInElement : element;
    const valueToSend = typeof value === "string" ? value.trim() : value;

    if(valueToSend === "" && !!this.props.originalLoad[key]){
      const params = {
        reference_number: this.props.customerProfile.reference_number,
        key: key
      };
      if (!this.props.disabled) {
        removeValueLoad(params).then((result) => {
          elementToChange?.classList.remove("background-failure");
          elementToChange?.classList.add("background-success");
          setTimeout(()=>{
            elementToChange?.classList.remove("background-success","text-danger");
          },400)
          this.props.updateState(key, result.data.data[key]);
        });
      }
      return
    }
    if(valueToSend === "" && !this.props.originalLoad[key]) return
    let finalValue;
    if (typeof valueToSend === "boolean" || typeof valueToSend === "string") {
      if(key === "freight" || key === "custom"){
      let change = valueToSend ? "HOLD" : null
      let noChange = _.isEqual(this.props.originalLoad[key],change)
      if(noChange) return
    }
    if(this.props.originalLoad[key]){
      let noChange = key === "terminal" ? _.isEqual(this.props.originalLoad[key]._id,valueToSend) : _.isEqual(this.props.originalLoad[key],valueToSend)
      if(noChange) return // || valueToSend === "" for not allowed empty elements
  }
      finalValue = valueToSend
    }
    else {
      let noChange = this.props.originalLoad[key] ? _.isEqual(this.props.originalLoad[key]._id,valueToSend._id) : false
      if(noChange) return
      finalValue = valueToSend._id
    }

    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      source: "loadinfo",
      isUpdateBillToCustomer
    }
    if(key === "containerNo") obj.isContainerConfirm = true
    if(key === "hot") obj.isHot = value;
    let list = ["secondaryReferenceNo"]
    if(this.props.isRequireReference && !list.includes(key)){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue
      }
      return
    }
    if (key === "secondaryReferenceNo") {
      obj.isCheckValidation = true
    }
    
      editLoad(obj).then((result)=>{
        if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
        if (result.data.data.hasSecondaryReferenceNo) {
          this.onReConfirmUpdateReferenceNo(
            obj,
            this.props.customerProfile.reference_number,
            key
          )
        } else {
          let eventProperties = {
            source: "load_info_modal",
            fieldName: key,
            value: finalValue,
            reference_number: this.props.customerProfile.reference_number
          }
          amplitudeTrack("EDIT_LOAD",eventProperties)
          elementToChange?.classList.remove("background-failure");
          elementToChange?.classList.add("background-success");
          if(!_.isEqual(this.willCall, {})){
            this.repetiveAPICall(this.willCall)
          }
          setTimeout(()=>{
            elementToChange?.classList.remove("background-success","text-danger");
          },400)
        }
        
      }).catch((err) => {
        elementToChange?.classList.add("background-error","text-danger");
        setTimeout(()=>{
          elementToChange?.classList.add("background-failure","text-danger");
          elementToChange?.classList.remove("background-error","text-danger");
        },400)
    })
  }

  onReConfirmUpdateReferenceNo = (data,reference_number,key) => {
    let title = "Reference #: " + data[key];
    let body = (
      <div>
        <h4>
            This Reference # is already in the system.
        </h4>
        <div>
          <p>
            Do you want to save the{" "}
            {"Reference #: " + data[key]}{" "}
            to Load {reference_number} anyway?
          </p>
        </div>
      </div>
    );
    confirm(title, body, (confirm) => {
      if (confirm) {
        data.isSecondaryReferenceNoConfirm = true
        const element = document.querySelector(`.${key}`);
        const divInElement = element?.querySelector("div");
        const elementToChange = divInElement ? divInElement : element;
        return editLoad(data).then((result)=>{
        if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
          elementToChange?.classList.remove("background-failure");
          elementToChange?.classList.add("background-success");
          if(!_.isEqual(this.willCall, {})){
            this.repetiveAPICall(this.willCall)
          }
          setTimeout(()=>{
            elementToChange?.classList.remove("background-success","text-danger");
          },400)
        }).catch((err) => {
          elementToChange?.classList.add("background-error","text-danger");
          setTimeout(()=>{
            elementToChange?.classList.add("background-failure","text-danger");
            elementToChange?.classList.remove("background-error","text-danger");
          },400)
      });
      } else {
        return
      }
    });
  };
  onMultipleSelectionChange = (key,value,i) =>{
    if(!value) return
    let finalValue;
    let controlField = key === "shipper" ? "pickupTimes": "deliveryTimes";
    let prevValue = this.props.originalLoad[key] && this.props.originalLoad[key].map((obj)=> obj._id)
    finalValue = value && value.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let noChange = _.isEqual(prevValue,finalValue)
    if(noChange) return
    const Times = this.props.customerProfile[controlField].filter((obj)=> !_.isEmpty(obj))
    const finalTimes = JSON.stringify(Times)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      [controlField]: finalTimes,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue,
        [controlField]: finalTimes,
      }
      return
    }
    if (this.isShowDeliveryReferenceField && key === "consignee") {
      let _deliveryReference = _.cloneDeep(this.props.customerProfile.deliveryReference)
      finalValue.forEach((ids, i) => {
          if(ids+"" !== _deliveryReference[i].customerId) {
            let customerName = value.find((item) => item._id === ids)
            _deliveryReference[i].customerId = ids
            _deliveryReference[i].customerName = customerName.company_name
          }
      })
      obj.deliveryReference = JSON.stringify(_deliveryReference)
    }
    const element = document.querySelector(`.${key}-${i} > div`);
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      } else {
        let eventProperties = {
          source: "load_info_modal",
          fieldName: key,
          value: finalValue,
          reference_number: this.props.customerProfile.reference_number
        }
        amplitudeTrack("EDIT_LOAD",eventProperties)
      }
      element?.classList.remove("background-failure");
      element?.classList.add("background-success");
        setTimeout(()=>{
          element?.classList.remove("background-success","text-danger");
        },400)
  }).catch(() => {
    element?.classList.add("background-error","text-danger");
    setTimeout(()=>{
      element?.classList.add("background-failure","text-danger");
      element?.classList.remove("background-error","text-danger");
    },400)
})}

  onRemoveField = (time, updateTime, updateList) => {
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let updatedTime = updateTime.filter((obj)=> !_.isEmpty(obj))
    let filteredPickup = JSON.stringify(updatedTime)
    let list = updateList.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      [field]: list,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
      ...this.willCall,
      [controlName]: filteredPickup,
      [field]: list,
      }
      return
    }
    if (this.isShowDeliveryReferenceField && time === "delivery") {
      let _deliveryReference = _.cloneDeep(this.props.customerProfile.deliveryReference)
      let newDeliveryReference = _deliveryReference.filter((item) => list.includes(item.customerId) )
      obj.deliveryReference = JSON.stringify(newDeliveryReference)
    }
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      toastr.show("Updated!", "success");
    }).catch((err) => {
      toastr.show('Failed to Update !', 'error');
  })
  }

  onCommodityChanges = (comdArr,index,name) => {
    comdArr.map((ele) => delete ele.volumeUnitQualifier)
    if(isConnectedWithOutSource()){
      comdArr = comdArr?.filter(obj => Object.values(obj).some(value => value !== "" && value !== null && value !== undefined))
    }
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: comdArr,
      ...(index > -1 && { commodityIndex: index }),
      source: "loadinfo"
    }
    if(this.props.isRequireReference && name){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: comdArr,
      }
      return
    }
    let elementArr;
    if(name === "weight" || name === "weightKgs"){
      let elements = [`.weight-${index}`,`.weightKgs-${index}`];
      elementArr = elements.map((el)=>document.querySelector(el))
    } else {
      const element = document.querySelector(`.${name}-${index}`)
      elementArr = name === "commodity" && JSON.parse(getStorage("measureUnits"))?.isCommodityProfileShow ? [element] : [element]
    }
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      } else {
        let eventProperties = {
          source: "load_info_modal",
          fieldName: name,
          reference_number: this.props.customerProfile.reference_number
        }
        amplitudeTrack("EDIT_LOAD",eventProperties)
      }
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
        !name && toastr.show("Successfully removed", "success");
    }).catch((err) => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
  })}

  tblvalchanges = (index, e,blur) => {
    const { name, value } = e.target;
    const old = [...this.state.items];
    if(value === "" &&  !this.props.originalLoad?.items[index] && this.props.originalLoad?.items[index][name]) return
    old[index][name] = value;
    if (name == "weight" && !blur) {
      old[index]["weightKgs"] = parseFloat((parseFloat(value) * 0.453592).toFixed(2));
    }
    if (name == "weightKgs" && !blur) {
      old[index]["weight"] = parseFloat(((parseFloat(value) * 2.20462).toFixed(2)));
    }
    if(name == "pieces"){
      old[index]["pieces"]= parseFloat(value)
    }
    if(name == "weight"){
      old[index]["weight"]= parseFloat(value)
    }
    if(name == "pallets"){
      old[index]["pallets"]= parseFloat(value)
    }
    if(name == "weightKgs"){
      old[index]["weightKgs"]= parseFloat(value)
    }
    this.setState({
      items: [...old],
    });
    let oldItems = [
      ...this.props.originalLoad?.items,
    ]
    oldItems?.forEach((item)=> {
      item && item._id && delete item._id
    })
    if(!_.isEqual(old, oldItems) && blur) this.onCommodityChanges(old,index,name)
  };

  DeleteItemRow = (index) => {
    let data = [...this.state.items];
    data.splice(index, 1);
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: data,
      }
      this.setState({
        items: data,
      })
      return
    }
    this.setState({
      items: data,
    },()=>{
      this.onCommodityChanges(data)
    });
  };

  AddItemRows = (item) => {
    let items = JSON.parse(JSON.stringify(this.state.items));
    items.push(item);
    this.setState({
      items,
      addCommodity: false
    });
    this.updateCommodity(items)
  };

  updateCommodity = (items) => {
    items.map((ele) => delete ele.volumeUnitQualifier)
    if(isConnectedWithOutSource()){
      items = items?.filter(obj => Object.values(obj).some(value => value !== "" && value !== null && value !== undefined))
    }
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: items,
      source: "loadinfo",
    }
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      let element = document.querySelector(`.fd-row-${items.length - 1 }`)
      element?.classList.add("background-success")
      setTimeout(()=>{
        element?.classList.remove("background-success")
      },400)
    })
  }

  updatePricing(isSaveCustomerRates) {
    let valid = true;
    var payload = {};
    let data = this.state.items.filter((d) => d.commodity);
    payload.items = data;
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    if (valid) {
      UpdatePricing("tms/updateItemsLoad", payload)
        .then((response) => {
          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show("Your pricing has been updated!", "success");
        })
        .catch((err) => {});
    } else {
      toastr.show("Please enter all required fields.", "warning");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isSaveCalled !== nextProps.isSaveCalled) {
      this.updatePricing();
    }
    let self = this;
    if (nextProps.customerProfile) {
      if (
        nextProps.customerProfile.items
      ) {
        let items = nextProps.customerProfile.items.map((D) => {
          if (D._id) {
            delete D._id;
          }
          return D;
        })
        if(isConnectedWithOutSource()) {
          items = items.filter(obj => Object.values(obj).some(value => value !== "" && value !== null && value !== undefined));
        }
        self.setState({ items });
      }
    }

    if (
      nextProps.newSyncRequests?.updatedAt &&
      (
        (this.state.newSyncRequests?.updatedAt &&
          new Date(nextProps.newSyncRequests?.updatedAt)?.toISOString() !== new Date(this.state.newSyncRequests?.updatedAt)?.toISOString()
        ) ||
        !this.state.newSyncRequests?.updatedAt)
    ) {
      this.setState({ newSyncRequests: nextProps.newSyncRequests }, () => {
        this.updateInfoOnRequestsChange(nextProps.newSyncRequests, true);
      });
    }

  }

  componentDidMount() {
    this.getCommodityAllProfile();
    if(this.shiposOrConnectCarrier) this.fetchSyncChanges();


  }

  componentWillUnmount() {

    this?.props?.tmsAction?.drayosTrackAlertStatus(null);
  }

  getCommodityAllProfile() {
    getCommodityProfile()
      .then((data) => {
        this.setState({
          commodityProfiles: data.data.data.map((c) => ({
            label: c.isRestricted ? `${c.name} (Restricted)` : c.name,
            value: c.name,
            name: c.name,
            isDisabled: c.isRestricted,
            allInfo: c,
          })),
        });
      })
      .catch((err) => {
        console.log("err----->>", err);
      });
  }
  onBlurChange = (e)=> this.onSelectFieldChange(e.target.name, e.target.value );

  getSeachTrailers = (params) => {
    const obj = { limit: 5 }
    if (params) {
      obj.searchTerm = params
    }
    return getTrailers(obj).then((data) => {
     return data;
    });
  };
  
  // show driver order status
  changeShowDriverOrderStatus = (value)=>{
    this.setState({
      showDriverOrderStatus: value,
    });
  }

  // handle on click add delivery location
  handleOnAddDeliveryLocation = ({deliveryTimes})=>{
    if(this.isUpdateConsigneeRouting){
      this.changeShowDriverOrderStatus(true);
    }else{
      let newConsignee = this.props.customerProfile && this.props.customerProfile.consignee ? this.props.customerProfile.consignee : [];
      newConsignee.push({});
      deliveryTimes.push({});
      this.props.updateState("deliveryTimes", deliveryTimes);
      this.props.updateState("consignee", newConsignee);
      this.setState({
        ["consignee" + newConsignee.length]: "",
      });
    }
  }

  // handle on remove delivery location
  handleOnRemoveDeliveryLocation = ({deliveryTimes, i})=>{
    if(this.isUpdateConsigneeRouting){
      this.changeShowDriverOrderStatus(true);
    }else{
      let newConsignee = this.props.customerProfile.consignee || [];
      if (newConsignee.length > 1) {
        newConsignee.splice(i, 1);
        this.props.updateState("consignee",newConsignee);
        this.setState({["consignee" + i]: "",});
        deliveryTimes.splice(i, 1);
        if (this.isShowDeliveryReferenceField) {
          let newDeliveryReference = this.props.customerProfile.deliveryReference || [];
          newDeliveryReference.splice(i, 1);
          this.props.updateState("deliveryReference",newDeliveryReference);
        }
        this.onRemoveField("delivery",deliveryTimes,newConsignee,i)
        this.props.updateState("deliveryTimes",deliveryTimes);
      }
    }
  }

  _saveDeliveryReferenceNumber = (index) => {
    let DeliveryReference = [];
    let noChange = _.isEqual(this.props.originalLoad?.deliveryReference,this.props.customerProfile?.deliveryReference)
    if (noChange) return;

    this.props.customerProfile?.consignee?.forEach((el,i)=>{
      el._id && DeliveryReference.push({ ...this.props.customerProfile?.deliveryReference?.[i], customerId: el._id, customerName: el.company_name })
    })
    let filteredPickup = JSON.stringify(DeliveryReference)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      deliveryReference: filteredPickup,
      source: "loadinfo"
    }
    const element =  document.querySelector(`.referenceNoForDeliveryLocations-${index}`)
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      element?.classList.remove("background-failure");
      element?.classList.add("background-success");
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }).catch(() => {
      element?.classList.add("background-error","text-danger")
      setTimeout(()=>{
        element?.classList.add("background-failure","text-danger");
        element?.classList.remove("background-error","text-danger");
      },400)
    })
  }

  onLiftoffReferenceNumberChange = (index) => {
    let noChange = _.isEqual(this.props.originalLoad?.liftOffReference,this.props.customerProfile?.liftOffReference)
    if (noChange) return;

    let liftOffReference = JSON.stringify(this.props.customerProfile?.liftOffReference)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      liftOffReference: liftOffReference,
      source: "loadinfo"
    }
    const element =  document.querySelector(`.liftOffReferenceNo-${index}`)
    editLoad(obj).then(()=>{
      element?.classList.remove("background-failure");
      element?.classList.add("background-success");
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }).catch(() => {
      element?.classList.add("background-error","text-danger")
      setTimeout(()=>{
        element?.classList.add("background-failure","text-danger");
        element?.classList.remove("background-error","text-danger");
      },400)
    })

  }

  handleOnRemovePickupLocation = ({pickupTimes, i})=> {
    let newShipper = this.props.customerProfile.shipper || [];
    if (newShipper.length > 1) {
      newShipper.splice(i, 1);
      this.props.updateState(
        "shipper",
        newShipper
      );
      this.setState({
        ["shipper" + i]: "",
      });
      pickupTimes.splice(i, 1);
      this.onRemoveField("pickup",pickupTimes,newShipper)
      this.props.updateState(
        "pickupTimes",
        pickupTimes
      );
    }
  }
  isLoadInfoDisabled = () => {
    const chargeDisableStatus = ["APPROVED","BILLING","PARTIAL_PAID", "FULL_PAID"];
    const loadDisableStatus = ["COMPLETED", "APPROVED", "BILLING", "REBILLING", "PARTIAL_PAID", "FULL_PAID"]
    return (chargeDisableStatus.includes(this.props.selectedLoads?.chargeStatus) && loadDisableStatus.includes(this.props.selectedLoads?.status));
  }
  // Function to show the Review modal
  showReviewModal = () => {
    this.setState({ isReviewModalVisible: true });
  };

  // Function to hide the Review modal
  hideReviewModal = () => {
    this.setState({ isReviewModalVisible: false });
  };

  loadingSync = (syncFields, customerProfile) => {
    const { reviewNeededKeys, autoAcceptKeys } = getSyncingKeys(syncFields, customerProfile, this.state.items)
    updateClassInElements(reviewNeededKeys, 'loader',false,this.isManageFleetOrOutSource?.isOutSource,"road")
    this.setState({ reviewNeededKeys, autoAcceptKeys })
  }

  getAlertInfo = (fieldName, syncIndex, event, subField, itemId) => {
    let syncParam = syncIndex
    if (fieldName === "loadRoutingLocation") {
      const eventType = SYNC_EVENT_TYPE?.[event] ?? ""
      syncParam = this.props?.selectedLoads?.driverOrder?.filter(x => x.type === eventType)?.filter(x => ![loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD].includes(x?.type) || x?.customerId )?.[syncIndex]?._id ?? ""
    }
    if (fieldName === "loadRoutingApptTimes") {
      const _events = {
        pickupTimes: loadEvents.PULLCONTAINER,
        deliveryTimes: loadEvents.DELIVERLOAD,
        returnTimes: loadEvents.RETURNCONTAINER
      }
      const eventType = _events[subField]
      syncParam = this.props?.selectedLoads?.driverOrder?.filter(x => x.type === eventType)?.filter(x => ![loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD].includes(x?.type) || x?.customerId )?.[syncIndex]?._id ?? ""
    }
    if (!this.shiposOrConnectCarrier || !this.state.alertMessages || this.state.alertMessages.length === 0) {
        return { message: false };
    }
    const message = getAlertSyncMessage(this.state.alertMessages, fieldName, syncParam, event, subField, itemId)
    return message;
  };

  mapObjForClass = (alerts) => {
    if (!this.shiposOrConnectCarrier || !alerts || alerts.length === 0) return "";
    const mappedObj = getMappedObjForSync(alerts)
    for (const [key, value] of Object.entries(mappedObj)) {
      updateClassInElements([key], value)
    }
  };

  updateInfoOnRequestsChange = (data, isSync = false)=>{
    const requests = data?.syncRequests ? _.cloneDeep(data?.syncRequests).reverse() : []
      updateClassInElements([...(this.state.reviewNeededKeys?.length ? this.state.reviewNeededKeys : []), ...(this.state.autoAcceptKeys?.length ?this.state.autoAcceptKeys : []) ], 'input-warning', true, this.isManageFleetOrOutSource.isOutSource)
    const pendingSyncRequests = requests?.filter((request) => request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.RECIEVED)
    const sentItemRequest = data?.syncRequests?.filter((request) => request?.fieldName === "items" && request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.SENT).map((request) => ({...request.value, requestID: request.requestID})) ?? []
    const receivedItemRequest = data?.syncRequests?.filter((request) => request?.fieldName === "items" && request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.RECIEVED).map((request) => request.value) ?? []
    const sentAddItemRequest = sentItemRequest?.filter((req) => req?.items?.actionType === "ADD") ?? []
    const receivedAddItemRequest = receivedItemRequest?.filter((req) => req?.items?.actionType === "ADD") ?? []
    const totalRecievedSyncRequests = data?.syncRequests?.filter((request) => request?.requestType === REQUEST_TYPES.RECIEVED) ?? []
    this.setState({
      alertMessages: requests,
      pendingSyncRequests: pendingSyncRequests,
      loadSyncRequestsData: data,
      sentAddItemRequest,
      receivedAddItemRequest,
      syncLoader: false,
      totalRecievedSyncRequests: totalRecievedSyncRequests
    }, () => {
      this.setAlertsAndClasses(requests)
      const needToDelay = (requests?.[0]?.requestType === REQUEST_TYPES.SENT && requests?.[0]?.status === STATUS.AUTO_ACCEPTED) || false;

      if (needToDelay) {
        setTimeout(() => {
          this.props.updateCustomerProfileOnRequest(isSync);
        }, 400);
      } else this.props.updateCustomerProfileOnRequest(isSync);
    });
  }

  fetchSyncChanges = async (isSync = false) => {
    getLoadSyncRequests({ load_reference_number: this.props.selectedLoads?.reference_number }).then((data) => {
      this.updateInfoOnRequestsChange(data, isSync);
    }).catch((err) => {
      updateClassInElements(this.state.reviewNeededKeys, 'loader', true)
      console.log("errr", err)
    })
  };

  setAlertsAndClasses = (alertMessages) => {
    updateClassInElements(this.state.reviewNeededKeys, 'loader', true, this.isManageFleetOrOutSource?.isOutSource,"road")
    this.mapObjForClass(alertMessages)
  }

  isPickUpSyncPending = (i) => {
    if (!this.shiposOrConnectCarrier) return false
    return ((this.getAlertInfo("loadRoutingLocation", i, "Pickup")?.message === alertMessageType.PENDING_RECIEVED))
  }

  isDeliverSyncPending = (i) => {
    if (!this.shiposOrConnectCarrier) return false
    return ((this.getAlertInfo("loadRoutingLocation", i, "Deliver")?.message === alertMessageType.PENDING_RECIEVED) )
  }

  checkUpdateCommodityIndex = (itemId) => {
    const updatedRequest = this.state.alertMessages.filter((alert) => alert?.value?.items?.actionType === "UPDATE" && alert.status === STATUS.PENDING && alert.value?.items?.commodity?.commodity?.itemId === itemId)
    if(updatedRequest?.length > 0) return true;
    else return false
  }

  checkRemovedCommodityIndex = (itemId) => {
    const removedRequest = this.state.alertMessages.filter((alert) => alert?.value?.items?.actionType === "REMOVE" && alert.status === STATUS.PENDING && alert.value?.items?.commodity?.itemId === itemId)
    if(removedRequest?.length > 0) return true;
    else return false
  }

  getRemovedCommodityTypeAtIndex = (itemId) => {
    const removedRequest = this.state.alertMessages?.find((alert) => alert?.value?.items?.actionType === "REMOVE" && alert.status === STATUS.PENDING && alert.value?.items?.commodity?.itemId === itemId)
    if(removedRequest?.requestType === REQUEST_TYPES.SENT) return {alertMessageType: alertMessageType.PENDING_SENT, requestID: removedRequest?.requestID }
    if(removedRequest?.requestType === REQUEST_TYPES.RECIEVED) return {alertMessageType: alertMessageType.PENDING_RECIEVED}
  }

  getUpdatedCommodityTypeAtIndex = (itemId, subField) => {
    const updatedRequest = this.state.alertMessages?.find((alert) => alert?.value?.items?.actionType === "UPDATE" && alert.status === STATUS.PENDING && alert.value?.items?.commodity?.commodity?.itemId === itemId && alert.value?.items?.commodity?.changedFields?.[subField])
    if(updatedRequest?.requestType === REQUEST_TYPES.SENT) return {alertMessageType: alertMessageType.PENDING_SENT, requestID: updatedRequest?.requestID }
    if(updatedRequest?.requestType === REQUEST_TYPES.RECIEVED) return {alertMessageType: alertMessageType.PENDING_RECIEVED}
  }

  renderClassesForShowCellOfItems = (field,syncIndex, event, subField, itemId) => {
    if(isConnectedWithOutSource()) {
    const alertInfo = this.getAlertInfo(field, syncIndex,event, subField, itemId)?.message;
    if (alertInfo) {
      if (alertInfo.hasOwnProperty(alertMessageType.PENDING_RECIEVED) && alertInfo[alertMessageType.PENDING_RECIEVED]) {
        return "outline-2 rounded-5 bg-warning-50 text-warning-300";
      }  
      if (alertInfo.hasOwnProperty(alertMessageType.PENDING_SENT) && alertInfo[alertMessageType.PENDING_SENT]) {
        return "outline-primary outline-2 rounded-5 bg-brand-50";
      }
      if (alertInfo.hasOwnProperty(alertMessageType.REJECTED_SENT) && alertInfo[alertMessageType.REJECTED_SENT]) {
        return "outline-2 rounded-5 outline-danger bg-danger-50";
      }
    }
    return "";
    }
  };
  renderClassesForShowReviewButton = (field,syncIndex, event, subField) => {
    if(isConnectedWithOutSource()) {
    const alertInfo = this.getAlertInfo(field, syncIndex,event, subField )?.message;
    if (alertInfo) {
      if (alertInfo.hasOwnProperty("PENDING_RECIEVED")) {
        return " request-wrapper ";
      } else if (alertInfo.hasOwnProperty("PENDING_SENT")) {
        return " request-wrapper request-wrapper-cancel ";
      }
    }
    return " ";
    }
  };

  isChargeDisable = () => {
    const chargeDisableStatus = ["APPROVED","BILLING","PARTIAL_PAID", "FULL_PAID"];
    return chargeDisableStatus.includes(this.props.selectedLoads?.chargeStatus);
  }

  updateBillToWhenCustomerUpdate = (isUpdateBillToCustomer = false) => {
    this.setState({isShowUpdateModal: false});
    this.onSelectFieldChange("caller", this.state.customerInfo, isUpdateBillToCustomer)
    this.props.updateState("caller", this.state.customerInfo);
  }

  render() {
    let self = this;
    let ddlDisabled = false;
    let pickupFrom = "";
    let pickupTo = "";
    let deliveryFrom = "";
    let deliveryTo = "";
    let loadTime = "";
    let containerAvailableDay = "";
    let isDisabled = this.props.disabled;
    let disabled = this.props.disabled || this.isLoadInfoDisabled()
    const isProfileLocationDisabled = ALL_COMPLETED_LOADS_STATUS.includes(this.props.selectedLoads?.status)

    const { billingInvoiceEditPermission } = checkAccountReceivablePermission();
    if (this.props.newRecord) disabled = true;
    if (
      this.props &&
      this.props.customerProfile &&
      Object.keys(this.props.customerProfile).length > 0
    ) {
      if (this.props.customerProfile.pickupFromTime) {
        pickupFrom = moment(this.props.customerProfile.pickupFromTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
        pickupTo = moment(this.props.customerProfile.pickupToTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.deliveryFromTime) {
        deliveryFrom = moment(
          this.props.customerProfile.deliveryFromTime
        ).format(DateTimeFormatUtils.fullDateTimeFormat());
        deliveryTo = moment(this.props.customerProfile.deliveryToTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.loadTime) {
        loadTime = moment(this.props.customerProfile.loadTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.containerAvailableDay) {
        containerAvailableDay = moment(
          this.props.customerProfile.containerAvailableDay
        ).format(DateTimeFormatUtils.fullDateFormat());
      }
      if (
        this.props.customerProfile.chassisNo &&
        typeof this.props.customerProfile.chassisNo != "string"
      )
        ddlDisabled = true;
    }
    const shippers =
      this.props.customerProfile && this.props.customerProfile.shipper
        ? this.props.customerProfile.shipper
        : [{}];
    const consignees =
      this.props.customerProfile && this.props.customerProfile.consignee
        ? this.props.customerProfile.consignee
        : [{}];
    let pickupTimes = this.props.customerProfile.pickupTimes
      ? this.props.customerProfile.pickupTimes
      : [{}];
    let deliveryTimes = this.props.customerProfile.deliveryTimes
      ? this.props.customerProfile.deliveryTimes
      : [{}];

    const driverOrder = this.props.selectedLoads?.driverOrder ?? []
    const deliveryEventsOfLoad = driverOrder?.filter((event)=>event.type === "DELIVERLOAD" && event?.customerId && !event.isVoidOut)
    const pickupEventsOfLoad = driverOrder?.filter((event)=>event.type === "PULLCONTAINER" && event?.customerId && !event.isVoidOut)
    
    const totalNumberOfSyncRequests = this.state.totalRecievedSyncRequests?.filter(request=>request?.fieldName!=="isLive") ?? [];
    const liftoffEventsOfLoad = driverOrder?.filter((event)=>event.type === "LIFTOFF" && !event.isVoidOut)
    return (
      <div className="tab mt-10" id="tab-load-info">
        {totalNumberOfSyncRequests?.length > 0 && this.shiposOrConnectCarrier && this.state.syncBanner && <div className="toast-container-relative position-relative mb-10">
          <div className="toast toast-warning mw-100 text-left mb-0 py-2 pr-0">
            <div className="toast-message d-flex align-items-center">
              {totalNumberOfSyncRequests?.length} Received Changes
              <button className="btn btn-sm btn-white ml-auto" onClick={this.showReviewModal}>
                Review Changes
              </button>
              <button className="btn btn-sm btn-link" onClick={() => this.setState({ syncBanner: false })}>
                <IconCancel />
              </button>
            </div>
          </div>
        </div>}
        { this.state.processingRequest  && (<div className="custom-main-toast mt-25 d-flex justify-content-center">
                <div className="toast--sm cstm-toast-warning cstm-toast-wrapper d-flex align-items-center">
                    <div>
                        <div className="font-14 font-weight-normal text-dark">Updating data change approval statuses...</div>
                    </div>
                </div>
          </div>)
        }
        {this.state.isReviewModalVisible &&
          <SingleLoadSelection handleProcessingRequest={this.handleProcessingRequest} isShow={this.state.isReviewModalVisible} handleClose={this.hideReviewModal} selectionData={[this.state.loadSyncRequestsData]} isFromLoadInfo={true} fetchSyncLoad={this.fetchSyncChanges}/>
        }
        <div className="form-row">
          <div className="col-md-6">
            {/* Customer Info */}
            <div className="card pt-30 pb-30 px-20 mb-10">
              <div>
                <h4 className="font-16 mb-20">
                  
                  Customer Info
                </h4>

                <div className="form-row">
                  <div className="col-lg form-group mb-0">
                    <label className={`label-control`}>Customer<span className="text-danger">*</span></label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      className="caller"
                      defaultOptions={makeOptionsForSelect(
                        this.props.callers.concat(this.props.allCustomers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      // isClearable
                      value={
                        (this.props.customerProfile &&
                          this.props.customerProfile.caller &&
                          this.props.customerProfile.caller.company_name) ||
                        this.state.caller
                          ? {
                              value: this.props.customerProfile.caller._id,
                              label:
                                this.props.customerProfile.caller.company_name,
                            }
                          : this.state.caller
                      }
                      onChange={(customer) => {
                        if (!customer) {
                          this.onRemovePress(null, { name: "caller" });
                          return;
                        }
                        if(billingInvoiceEditPermission && !this.isChargeDisable()) {
                          this.setState({
                            isShowUpdateModal : true,
                            customerInfo : customer.allInfos
                          });
                        } else {
                          this.onSelectFieldChange("caller", customer.allInfos);
                          this.props.updateState("caller", customer.allInfos);
                        }
                      }}
                      loadOptions={getTMScaller}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("caller")} />
                  </div>
                  {this.state.isShowUpdateModal && billingInvoiceEditPermission && !this.isChargeDisable() && (
                    <UpdateBillCharge 
                      show={this.state.isShowUpdateModal} 
                      onselect={(value) => {this.updateBillToWhenCustomerUpdate(value)}}
                      close={() => this.setState({isShowUpdateModal: false})} 
                    />
                  )}
                  { showForTerminal()  && (
                    <div className="form-group col-lg" style={{maxWidth: 170}}>
                      <label className="label-control">Terminal<span className="text-danger">*</span></label>
                      {
                        this.state.Terminals && 
                        <Select
                          styles={smallSelectStyle}
                          className="terminal"
                          options={this.state.Terminals.map(it=>({value:it._id, label:it.name}))}
                          value={this.state.terminal}
                          onChange={(opt) => {
                            this.setState({
                              terminal: opt,
                            });
                            this.onSelectFieldChange("terminal", opt.value)
                            this.props.updateState("terminal", opt);
                          }}
                          isDisabled={disabled || isGlobalizationEnabled()}
                        />
                      }
                      <SyncError alert={this.getAlertInfo("terminal")}/>
                    </div>
                  )} 
                </div>
                
              </div>

              <div className="hr-light my-25"></div>
              {shippers.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-10">Pick Up Location</h4>
                    <div className="form-row">
                      {shippers.map((shipper, i) => {
                        let disablePickupApt = pickupEventsOfLoad[i]?.combineTripId || pickupEventsOfLoad[i]?.arrived || this.isPickUpSyncPending(i);
                        const pendingEventUpdate = (this.getAlertInfo("loadRoutingLocation", i, "Pickup")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "From", "pickupTimes")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "To", "pickupTimes")?.message)
                        return (
                          <div className={`col-md-12 ${shippers?.length - 1 === i && (this.getAlertInfo("loadRoutingLocation", i, "Pickup")?.message) ? 'mb-10' : ''}`}>
                            <div className="form-row align-items-end">
                              <div className={`col-md-6 ${this.renderClassesForShowReviewButton("loadRoutingLocation", i, "Pickup")}`}>
                              <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingLocation", index : i, name:'Pickup'} } getAlertInfo={this.getAlertInfo} />
                              <label>&nbsp;</label>
                                <div className="form-group" key={i}>
                                  <div className={`input-wrapper mb-10`}>
                                    <AsyncSelect
                                      styles={smallSelectStyle}
                                      className={`shipper-${i} shipper-${pickupEventsOfLoad?.[i]?._id}`}
                                      defaultOptions={makeOptionsForSelect(
                                        this.props.shippersOptions,
                                        "company_name",
                                        "address.address",
                                        "_id"
                                      )}
                                      cacheOptions
                                      value={
                                        shipper && shipper.company_name
                                          ? {
                                              label: shipper.company_name,
                                              value: shipper._id,
                                              allInfos: shipper,
                                            }
                                          : this.state["shipper" + i]
                                          ? {
                                              label:
                                                this.state["shipper" + i]
                                                  .company_name,
                                              value: this.state["shipper" + i]._id,
                                              allInfos: this.state["shipper" + i],
                                            }
                                          : []
                                      }
                                      onChange={(shipper) => {
                                        let newShipper = self.props.customerProfile
                                          .shipper || [{}];
                                        newShipper[i] = shipper.allInfos;
                                        let newPickupTimes = self.props
                                          .customerProfile.pickupTimes || [];
                                          newPickupTimes[i].customerId = shipper.value;
                                        this.setState({
                                          ["shipper" + i]: shipper.label,
                                        });
                                        this.props.updateState(
                                          "shipper",
                                          newShipper
                                          );
                                          this.props.updateState(
                                            "pickupTimes",
                                            newPickupTimes
                                            );
                                          this.onMultipleSelectionChange("shipper", newShipper,i)
                                      }}
                                      loadOptions={getTMSshipper}
                                      isDisabled={disabled || pickupEventsOfLoad?.[i]?.tenderId?.drayOSLoadRefNo || isProfileLocationDisabled}
                                    />
                                    <SyncError alert={this.getAlertInfo("loadRoutingLocation", i, "Pickup")} className={'position-absolute'} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <React.Fragment>
                                  <div className="form-row">
                                    <div className={`col-lg ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "From", "pickupTimes")}`}>
                                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "pickupTimes", name: "From"} } getAlertInfo={this.getAlertInfo} />
                                      <label className="label-control">
                                        From
                                      </label>
                                      <div className={`input-wrapper pickupFrom-${i} pickupTimes-From-${pickupEventsOfLoad?.[i]?._id}`}>
                                      <div className="position-relative">
                                        <DateWrapper
                                          OnBlurUpdate={() => this.onPickDeliveryChanges("pickup","pickupFrom",i)}
                                          onChange={(e) => {
                                            this.props.datechangesPickupDelivery("pickupFromTime",e,i);
                                            // this.props.datechangesPickupDelivery("pickupToTime",e,i); 
                                          }}
                                          displayTimeZone={this.timeZone}
                                          isShowTime={true}
                                          timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                          disabled={disabled || isProfileLocationDisabled}
                                          defaultDate={pickupTimes[i] && pickupTimes[i].pickupFromTime} 
                                          hideShowSelectDate={true}
                                        />
                                        {pickupTimes[i] &&
                                        pickupTimes[i].pickupFromTime &&
                                        !(disabled || isProfileLocationDisabled) ? (
                                          <div
                                            className="input-icon"
                                            onClick={(e) => {
                                              this.onRemovePressOnDate(
                                                e,
                                                "pickup",
                                                i
                                              );
                                            }}
                                          >
                                            <IconTimes />
                                          </div>
                                        ) : (
                                          <div className="input-icon">
                                            <IconCalendar />
                                          </div>
                                        )}
                                      </div>
                                        <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "From", "pickupTimes")} className={'position-absolute'} />
                                        </div>
                                    </div>
                                    <div className="col-lg">
                                      <div className={`form-group ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "To", "pickupTimes")}`}>
                                      <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "pickupTimes", name: "To"} } getAlertInfo={this.getAlertInfo} />
                                        <label className="label-control">
                                          To
                                        </label>
                                        <div className={`input-wrapper pickupTo-${i} pickupTimes-To-${pickupEventsOfLoad?.[i]?._id}`}>
                                        <div className="position-relative">
                                        <DateWrapper
                                          OnBlurUpdate={()=> this.onPickDeliveryChanges("pickup","pickupTo",i)}
                                          onChange={(e) => 
                                          this.props.datechangesPickupDelivery("pickupToTime",e,i)
                                          }
                                          timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                          disabled={disabled || isProfileLocationDisabled}
                                          displayTimeZone={this.timeZone}
                                          isShowTime={true}
                                          defaultDate={pickupTimes[i] && pickupTimes[i].pickupToTime} 
                                          hideShowSelectDate={true}
                                        />
                                          {pickupTimes[i] &&
                                          pickupTimes[i].pickupToTime &&
                                          !(disabled || isProfileLocationDisabled) ? (
                                            <div
                                              className="input-icon"
                                              onClick={(e) => {
                                                this.onRemovePressOnDate(
                                                  e,
                                                  "pickup",
                                                  i
                                                );
                                              }}
                                            >
                                              <IconTimes />
                                            </div>
                                          ) : (
                                            <div className="input-icon">
                                              <IconCalendar />
                                            </div>
                                          )}
                                        </div>
                                          <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "To", "pickupTimes")} className={'position-absolute'} />
                                        </div>
                                      </div>
                                    </div>
                                  
                                    {this.props.customerProfile &&
                                      this.props.customerProfile.shipper &&
                                      this.props.customerProfile.shipper.length >
                                        1 && (
                                          
                                        <div className="col-lg form-group d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                           {(disablePickupApt || pendingEventUpdate) && <PopperTooltip
                                                    isOpen={this.state.hoveredElementId === `removePickupApt-${i}`} 
                                                    refNo={document.getElementById(`removePickupApt-${i}`)} 
                                                    name={pendingEventUpdate ? 'This event has pending requests' : 'Pickup event is either in trip or progress'}
                                                    color={'gray-700'} 
                                                    cssClass={"load-driver"} 
                                                />}
                                          <div
                                            className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                            id={`removePickupApt-${i}`}
                                            onMouseEnter={()=>{
                                              this.setState({hoveredElementId: `removePickupApt-${i}`})
                                            }}
                                            onMouseLeave={()=>{
                                              this.setState({hoveredElementId: null})
                                            }}
                                            onClick={(e) => {
                                              !(disabled || disablePickupApt || pendingEventUpdate || isProfileLocationDisabled) && this.handleOnRemovePickupLocation({pickupTimes, i})
                                            }}
                                            style={(disabled || disablePickupApt || pendingEventUpdate || isProfileLocationDisabled) ? {opacity: 0.5} : {}}
                                          >
                                            <IconMinus />
                                          </div>
                                        </div>
                                      )}
                                    
                                  </div>
                                </React.Fragment>
                              </div>
                            </div>
                          </div>
                        );
                        })}
                    </div>
                    <span style={(disabled || isProfileLocationDisabled) ? {cursor: "not-allowed"} : {}}>
                    <div
                      className="btn btn-outline-primary btn-sm"
                      onClick={()=>this.setState({ showLoadPopup: "PULLCONTAINER" })}
                      style={(disabled || isProfileLocationDisabled) ? {pointerEvents: "none", opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      Add Pick Up Location
                    </div>
                    </span>
                  </div>
                  <div className="hr-light"></div>
                </React.Fragment>
              )}
              {consignees.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-10">Delivery Location</h4>
                    <div className="form-row">
                    {consignees.map((consignee, i) => {
                      let disableRemoveApt = deliveryEventsOfLoad[i]?.combineTripId || deliveryEventsOfLoad[i]?.arrived || this.isDeliverSyncPending(i)
                      const pendingEventUpdate = (this.getAlertInfo("loadRoutingLocation", i, "Deliver")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "From", "deliveryTimes")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "To", "deliveryTimes")?.message)
                      return (
                        <div className={`col-md-12 ${consignees?.length - 1 === i && (this.getAlertInfo("loadRoutingLocation", i, "Deliver")?.message) ? 'mb-10' : ''}`}>
                          <div className="form-row align-items-end">
                            <div className={`col-md-6 ${this.renderClassesForShowReviewButton("loadRoutingLocation", i, "Deliver")}`}>
                            <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingLocation", index : i, name:'Deliver'} } getAlertInfo={this.getAlertInfo} />
                            <label>&nbsp;</label>
                              <div className="form-group" key={i}>
                                <div className={`input-wrapper`}>
                                  <AsyncSelect
                                    styles={{
                                      ...smallSelectStyle,
                                      control: (base, state) => ({
                                        ...smallSelectStyle.control(base, state),
                                        paddingLeft: (this.props?.customerProfile?.consignee?.length > 1 && this.isShowDeliveryReferenceField) ? "20px" : base.paddingLeft,
                                      }),
                                    }}
                                    className={`consignee-${i} consignee-${deliveryEventsOfLoad?.[i]?._id}`}
                                    defaultOptions={makeOptionsForSelect(
                                      this.props.consigneesOptions,
                                      "company_name",
                                      "address.address",
                                      "_id"
                                    )}
                                    cacheOptions
                                    value={
                                      consignee && consignee.company_name
                                        ? {
                                            label: consignee.company_name,
                                            value: consignee._id,
                                            allInfos: consignee,
                                          }
                                        : this.state["consignee" + i]
                                        ? {
                                            label:
                                              this.state["consignee" + i]
                                                .company_name,
                                            value:
                                              this.state["consignee" + i]._id,
                                            allInfos: this.state["consignee" + i],
                                          }
                                        : []
                                    }
                                    onChange={(consignee) => {
                                      let newConsignee = self.props
                                        .customerProfile.consignee || [{}];
                                      newConsignee[i] = consignee.allInfos;
                                      let newDeliveryTimes = self.props
                                        .customerProfile.deliveryTimes || [];
                                        newDeliveryTimes[i].customerId = consignee.value;
                                      this.setState({
                                        ["consignee" + i]: consignee.label,
                                      });
                                      this.props.updateState(
                                        "consignee",
                                        newConsignee
                                      );
                                      this.props.updateState(
                                        "deliveryTimes",
                                        newDeliveryTimes
                                        );
                                      this.onMultipleSelectionChange("consignee", newConsignee,i)
                                    }}
                                    loadOptions={getTMSconsignee}
                                    isDisabled={disabled || deliveryEventsOfLoad?.[i]?.tenderId?.drayOSLoadRefNo || isProfileLocationDisabled}
                                  />
                                  <SyncError alert={this.getAlertInfo("loadRoutingLocation", i, "Deliver")} className={'position-absolute'} />
                                {this.props.customerProfile &&
                                    this.props.customerProfile.consignee &&
                                    this.props.customerProfile.consignee.length > 1 && this.isShowDeliveryReferenceField &&
                                      <div className="input-icon left-0 right-inherit pt_6">
                                        <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100">
                                          {i+1}
                                        </div>
                                      </div>
                                }
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <React.Fragment>
                                <div className="form-row ">
                                  <div className={`col-md ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "From", "deliveryTimes")}`}>
                                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "deliveryTimes", name: "From"} } getAlertInfo={this.getAlertInfo} />
                                    <label className="label-control">
                                      From
                                    </label>
                                    <div className={`input-wrapper deliveryFrom-${i} deliveryTimes-From-${deliveryEventsOfLoad?.[i]?._id}`}>
                                    <div className="position-relative">
                                      <DateWrapper 
                                        disabled={disabled || isProfileLocationDisabled}
                                        OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryFrom",i)}
                                        onChange={(e) => {
                                          this.props.datechangesPickupDelivery("deliveryFromTime",e,i);
                                          // this.props.datechangesPickupDelivery("deliveryToTime",e,i); 
                                        }}
                                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                        displayTimeZone={this.timeZone}
                                        isShowTime={true}
                                        defaultDate={deliveryTimes[i] && deliveryTimes[i].deliveryFromTime} 
                                        hideShowSelectDate={true}
                                      />
                                      {deliveryTimes[i] &&
                                      deliveryTimes[i].deliveryFromTime &&
                                      !(disabled || isProfileLocationDisabled) ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "delivery",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />{" "}
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />{" "}
                                        </div>
                                      )}
                                      </div>
                                        <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "From", "deliveryTimes")} className={'position-absolute'} />
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className={`form-group ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "To", "deliveryTimes")}`}>
                                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "deliveryTimes", name: "To"} } getAlertInfo={this.getAlertInfo} />
                                      <label className="label-control">
                                        To
                                      </label>
                                      <div className={`input-wrapper deliveryTo-${i} deliveryTimes-To-${deliveryEventsOfLoad?.[i]?._id}`}>
                                      <div className="position-relative">
                                      <DateWrapper 
                                        disabled={disabled || isProfileLocationDisabled}
                                        OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryTo",i)}
                                        onChange={(e) => {
                                          this.props.datechangesPickupDelivery("deliveryToTime",e,i);
                                        }}
                                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                        displayTimeZone={this.timeZone}
                                        isShowTime={true}
                                        defaultDate={deliveryTimes[i] && deliveryTimes[i].deliveryToTime} 
                                        hideShowSelectDate={true}
                                      />
                                        {deliveryTimes[i] &&
                                        deliveryTimes[i].deliveryToTime &&
                                        !(disabled || isProfileLocationDisabled) ? (
                                          <div
                                            className="input-icon"
                                            onClick={(e) => {
                                              this.onRemovePressOnDate(
                                                e,
                                                "delivery",
                                                i
                                              );
                                            }}
                                          >
                                            <IconTimes />{" "}
                                          </div>
                                        ) : (
                                          <div className="input-icon">
                                            <IconCalendar />{" "}
                                          </div>
                                        )}
                                        </div>
                                      </div>
                                      <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "To", "deliveryTimes")} className={'position-absolute'} />
                                    </div>
                                  </div>
                                  {this.props.customerProfile &&
                                  this.props.customerProfile.consignee &&
                                  this.props.customerProfile.consignee.length >
                                    1 && (
                                    <div className="form-group col-md d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                      {(disableRemoveApt || pendingEventUpdate) && <PopperTooltip
                                                    isOpen={this.state.hoveredElementId === `removeDeliveryApt-${i}`} 
                                                    refNo={document.getElementById(`removeDeliveryApt-${i}`)} 
                                                    name={pendingEventUpdate ? 'This event has pending requests' : 'Delivery is either in trip or progress'}
                                                    color={'gray-700'} 
                                                    cssClass={"load-driver"} 
                                                />}
                                      <div
                                        className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                        id={`removeDeliveryApt-${i}`}
                                        onClick={(e) => {
                                          !(disabled || disableRemoveApt || pendingEventUpdate || isProfileLocationDisabled) && this.handleOnRemoveDeliveryLocation({deliveryTimes, i})
                                        }}
                                        onMouseEnter={()=>{
                                          this.setState({hoveredElementId: `removeDeliveryApt-${i}`})
                                        }}
                                        onMouseLeave={()=>{
                                          this.setState({hoveredElementId: null})
                                        }}
                                        style={(disabled || disableRemoveApt || pendingEventUpdate || isProfileLocationDisabled) ? {pointerEvents: "none", opacity: 0.5} : (this.props.customerProfile.driverOrder[0]?.combineTripId)? {pointerEvents: "none", opacity: 0.4} : {}}
                                      >
                                        <IconMinus />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </React.Fragment>
                            </div>
                          </div>
                        </div>
                     );
                    })}

                    </div>
                  <span style={(disabled || isProfileLocationDisabled) ? {cursor: "not-allowed"} : {}}>
                    <div
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        !disabled && !isProfileLocationDisabled && this.setState({ showLoadPopup: "DELIVERLOAD" });
                      }}
                      onMouseEnter={()=>{
                        this.setState({hoveredElementId: "addDeliveryApt"})
                      }}
                      onMouseLeave={()=>{
                        this.setState({hoveredElementId: null})
                      }}
                      style={(disabled || isProfileLocationDisabled) ? { opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      Add Delivery Location
                    </div>
                    </span>
                  </div>
                </React.Fragment>
              )}

              {this.state.showLoadPopup && this.props.selectedLoads && (
                <Modal
                  show={this.state.showLoadPopup}
                  centered={true}
                  // dialogClassName="modal-xl"
                  backdropClassName="z-1050"
                  className="modal-extend modal-full p-4"
                  scrollable={true}
                >
                  <Modal.Body className="bg-gray-50 p-3 h-100">
                    <RoutingEvent
                      selectedLoads={this.props.selectedLoads}
                      reference_number={this.props.selectedLoads?.reference_number}
                      closeStatusModal={() => {
                        this.setState({ showLoadPopup: false });
                      }}
                      dispatcherModal={true}
                      showEvent={this.state.showLoadPopup}
                      newSyncRequests={this.state.newSyncRequests}
                    />
                  </Modal.Body>
                </Modal>
              )}
              
              {this.state.showDriverOrderStatus && 
                <RoutingOnly
                  showDriverOrderModal={this.state.showDriverOrderStatus}
                  load = {this.props.selectedLoads}
                  setShowDriverOrderModal={this.changeShowDriverOrderStatus}
                  changeTopTab = {this.props.changeTopTab}
                ></RoutingOnly>
              }
              
              {/* Container Return */}
            </div>
          </div>

          <div className="col-md-6">
            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">
                
                Reference
              </h4>
              <div className="form-row mb-10">
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("callerbillLandingNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"callerbillLandingNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control callerbillLandingNo transition-white-field"
                    label="Master Bill of Lading"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerbillLandingNo"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("callerbillLandingNo")}
                  />
                </div>

                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("doNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"doNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control doNo transition-white-field"
                    label="House Bill of Lading"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="doNo"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("doNo")}
                  />
                </div>

                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("containerNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"containerNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control containerNo transition-white-field"
                    label="Reference Container #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="containerNo"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("containerNo")}
                  />
                </div>

                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("callerPONo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"callerPONo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control callerPONo transition-white-field"
                    label="Pick Up #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerPONo"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("callerPONo")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("secondaryReferenceNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"secondaryReferenceNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control secondaryReferenceNo transition-white-field"
                    component={InputField}
                    name="secondaryReferenceNo"
                    label="Reference #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("secondaryReferenceNo")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("shipmentNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"shipmentNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control shipmentNo transition-white-field"
                    label="Shipment #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="shipmentNo"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("shipmentNo")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("sealNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"sealNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control sealNo transition-white-field"
                    component={InputField}
                    name="sealNo"
                    label="Seal #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("sealNo")}
                  />
                </div>

                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("releaseNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"releaseNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control releaseNo transition-white-field"
                    label="Order #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="releaseNo"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("releaseNo")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("purchaseOrderNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"purchaseOrderNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control purchaseOrderNo transition-white-field"
                    component={InputField}
                    name="purchaseOrderNo"
                    label="Purchase Order #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("purchaseOrderNo")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("appointmentNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"appointmentNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control appointmentNo transition-white-field"
                    component={InputField}
                    name="appointmentNo"
                    label="Appointment #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("appointmentNo")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("returnNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"returnNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control returnNo transition-white-field"
                    component={InputField}
                    name="returnNo"
                    label="Return #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("returnNo")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("reservationNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"reservationNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control reservationNo transition-white-field"
                    component={InputField}
                    name="reservationNo"
                    label="Reservation #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("reservationNo")}
                  />
                </div>
                {this.props.customerProfile &&
                    this.props.customerProfile.consignee &&
                    this.props.customerProfile.consignee.length > 0 && 
                    this.isShowDeliveryReferenceField &&
                    this.props.customerProfile.consignee.map((val, i) => (
                      val?.company_name && (
                        <div className="form-group col-lg-4" key={`Reference-${i}`}>
                          <label>{`Delivery Reference #`}</label>
                          <div className="input-wrapper">
                            <input
                              className={`form-control pl-30 referenceNoForDeliveryLocations-${i} transition-white-field`}
                              style={{transition: "background 0.4s ease-out"}}
                              type="text"
                              name={`referenceNoForDeliveryLocations-${i}`}
                              autoComplete="off"
                              value={this.props.customerProfile?.deliveryReference?.[i]?.referenceNo ?? ""}
                              disabled={disabled}
                              onChange={(e) => {this.props.changeDeliveryReference("referenceNo",e.target.value ?? "",i)}}
                              onBlur={(e) => this._saveDeliveryReferenceNumber(i)}
                            />
                            {<>
                              <div data-tip data-for={`reference-${i}`} className="input-icon left-0 right-inherit mt pt_6 tooltip-label">
                                <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100">
                                  {i+1}
                                </div>
                                <div className="tooltip tooltip--gray-700 bs-tooltip-top" id={`reference-${i}`} data-placement="top-center">
                                  {val?.company_name}
                                  <span className="arrow"></span>
                                </div>
                              </div>
                              {this.props.customerProfile?.deliveryReference?.[i]?.referenceNo &&  !disabled && <div className="input-icon">
                              <span
                                onClick={(e) => {
                                  this.props.changeDeliveryReference("referenceNo","",i,true, this._saveDeliveryReferenceNumber);
                                }}
                              >
                                {" "}
                                <IconTimes />{" "}
                              </span>
                            </div>}
                            </>
                            }
                          </div>
                        </div>
                      )
                    ))
                }
                {this.props.customerProfile &&
                    liftoffEventsOfLoad.length > 0 &&
                    this.isShowLiftOffReferenceField &&
                    liftoffEventsOfLoad?.map((item, i) => (
                      item?.company_name && (
                        <div className="form-group col-lg-4" key={`liftOffReferenceNo-${i}`}>
                          <label>{`Lift Off Reference #`}</label>
                          <label className="label-control d-block"></label>
                          <div className="input-wrapper">
                          <input
                            className={`form-control pl-30 liftOffReferenceNo-${i} transition-white-field`}
                            style={{transition: "background 0.4s ease-out"}}
                            type="text"
                            name={`liftOffReferenceNo-${i}`}
                            autoComplete="off"
                            value={this.props.customerProfile?.liftOffReference?.[i]?.referenceNo ?? ""}
                            disabled={disabled}
                            onChange={(e) => {this.props.changeLiftOffReference("referenceNo",e.target.value ?? "",i, liftoffEventsOfLoad)}}
                            onBlur={(e) => this.onLiftoffReferenceNumberChange(i)}
                          />
                            {<>
                              <div data-tip data-for={`liftoff-reference-${i}`} className="input-icon left-0 right-inherit pt_6 tooltip-label">
                                <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100">
                                  {i+1}
                                </div>
                                <div className="tooltip tooltip--gray-700 bs-tooltip-top" id={`liftoff-reference-${i}`} data-placement="top-center">
                                {item?.company_name}                                  <span className="arrow"></span>
                                </div>
                              </div>
                              {this.props.customerProfile?.liftOffReference?.[i]?.referenceNo && !disabled && <div className="input-icon">
                                <span
                                onClick={(e) => {
                                  this.props.changeLiftOffReference("referenceNo","",i,liftoffEventsOfLoad,true, this.onLiftoffReferenceNumberChange);
                                }}
                              >
                                {" "}
                                <IconTimes />{" "}
                              </span>
                            </div>}
                            </>
                            }
                          </div>
                        </div>
                      )
                    ))
                }
              </div>
            </div>

            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">
                
                Equipment
              </h4>
              
              <div className="form-row ">
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("temperature")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"temperature"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control temperature transition-white-field"
                    label="Temperature"
                    name="temperature"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("temperature")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("hazmat")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"hazmat"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control hazmat transition-white-field"
                    component={SelectField}
                    backgroundColor="hazmat"
                    name="hazmat"
                    label="Hazmat"
                    data={loadFlags}
                    disabled={disabled}
                    onChange={(e)=> this.onSelectFieldChange("hazmat", e)}
                    alert={this.getAlertInfo("hazmat")} 
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("isHot")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"isHot"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control hot transition-white-field"
                    backgroundColor="isHot"
                    component={SelectField}
                    name="hot"
                    label="Hot"
                    disabled={disabled}
                    data={loadFlags}
                    onChange={(e)=> this.onSelectFieldChange("hot", e)}
                    alert={this.getAlertInfo("isHot")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("overWeight")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"overWeight"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className="form-control overWeight transition-white-field"
                    component={SelectField}
                    backgroundColor="overWeight"
                    name="overWeight"
                    label="OVERWEIGHT"
                    data={loadFlags}
                    disabled={disabled}
                    onChange={(e)=> this.onSelectFieldChange("overWeight", e)}
                    alert={this.getAlertInfo("overWeight")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("scac")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"scac"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control scac transition-white-field"
                    label="SCAC"
                    name="scac"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("scac")}
                  />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("trailer")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"trailer"} getAlertInfo={this.getAlertInfo} />
                  <label className="label-control">Trailer</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    className= "trailer"
                    cacheOptions
                    // defaultOptions
                    value={this.props.customerProfile.trailer ? {
                      label: this.props.customerProfile.trailer,
                      value: this.props.customerProfile.trailer,
                    } : ''}
                    onChange={(trailer) => {
                      !trailer
                        ? this.onRemovePress(null, { name: "trailer" })
                        : this.props.updateState("trailer", trailer.value);this.onSelectFieldChange("trailer", trailer.value)
                    }}
                    loadOptions={this.getSeachTrailers}
                    isClearable={
                      this.props.customerProfile.trailer ? true : false
                    }
                    isDisabled={disabled}
                  />
                  <SyncError alert={this.getAlertInfo("trailer")} />
                </div>
              </div>
              <div className="form-row">
              <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("trailerType")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"trailerType"} getAlertInfo={this.getAlertInfo} />
                  <label className="label-control">Type</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    className="trailerType"
                    cacheOptions
                    // defaultOptions
                    value={this.props.customerProfile.trailerType ? {
                      label: this.props.customerProfile.trailerType,
                      value: this.props.customerProfile.trailerType,
                    } : ''}
                    onChange={(trailerType) => {
                      this.onSelectFieldChange("trailerType", trailerType.value)
                      this.props.updateState(
                        "trailerType",
                        trailerType.value
                      );
                    }}
                    loadOptions={getTrailerTypes}
                    isDisabled={disabled}
                  />
                  <SyncError alert={this.getAlertInfo("trailerType")} />
                </div>
                <div className={`form-group col-lg-4 ${this.renderClassesForShowReviewButton("trailerSize")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads?.reference_number} showReviewModal={this.showReviewModal}  field={"trailerSize"} getAlertInfo={this.getAlertInfo} />
                  <label className="label-control">Size</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    className="trailerSize"
                    cacheOptions
                    // defaultOptions
                    value={this.props.customerProfile.trailerSize ? {
                      label: this.props.customerProfile.trailerSize,
                      value: this.props.customerProfile.trailerSize,
                    } : ''}
                    onChange={(trailerSize) => {
                      this.onSelectFieldChange("trailerSize", trailerSize.value)
                      this.props.updateState(
                        "trailerSize",
                        trailerSize.value
                      );
                    }}
                    loadOptions={getTrailerSizes}
                    isDisabled={disabled}
                  />
                  <SyncError alert={this.getAlertInfo("trailerSize")} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card py-20 px-30 mb-0">
          <h4 className="font-16 mb-0">Freight Description</h4>
        </div>

        <div className="table">
          <table className="table table-card table-card--ls mb-10 fd-row">
            <thead>
              <tr>
                <th scope="col" className="z-0" width="17%" >Commodity</th>
                <th scope="col" className="z-0">Description</th>
                <th width="10%" scope="col" className="z-0">
                  Pieces
                </th>
                <th width="10%" scope="col" className="z-0">
                  Weight LBS
                </th>
                <th width="10%" scope="col" className="z-0">
                  Weight KGS
                </th>
                <th width="10%" scope="col" className="z-0">
                  Pallets
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.items &&
                this.state.items.length > 0 &&
                this.state.items.map((data, key) => {
                  const isSent = this.shiposOrConnectCarrier ? this.state.sentAddItemRequest?.find((sent) => data.commodity === sent?.items?.commodity?.commodity) : false
                  if(isSent) return <></>
                  return (
                    <tr key={key} className={`select-custom-input fd-row-${key} ${this.state.addCommodity || disabled ? "disabled-pointer-events": ""} ${this.checkRemovedCommodityIndex(data?.itemId) ? `${this.getRemovedCommodityTypeAtIndex(data?.itemId)?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper-items request-wrapper-cancel" : "request-wrapper-items"} outline-2 rounded-5 outline-danger bg-danger-50` : ""}`}  style={this.state.addCommodity ? {opacity: "0.2", transition: "background 0.4s ease-out"}: {transition: "background 0.4s ease-out"}}>
                      <td className={`commodity-${key} px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "commodity") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'description', data?.itemId)}`: ""}`} style={{transition: "background 0.4s ease-out"}}>
                      <div className={`${this.checkRemovedCommodityIndex(data?.itemId) ? "ml-15 position-absolute top-0 left-0 bottom-0 d-flex align-items-center" : ""}`}>
                      {this.checkRemovedCommodityIndex(data?.itemId) && (this.getRemovedCommodityTypeAtIndex(data?.itemId)?.alertMessageType === alertMessageType.PENDING_SENT ? <IconDelete className="text-danger"/> : <IconMinus className="text-danger"/>)}
                        {JSON.parse(getStorage("measureUnits"))
                          .isCommodityProfileShow == true ? (
                          <div className={`form-group m-0 ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : ''}`}>
                            <Select
                              styles={newSmallSelectStyle}
                              options={this.state.commodityProfiles}
                              value={this.state.commodityProfiles.find(
                                (pt) => pt.value == data.commodity
                              )}
                              onChange={(value) => {
                                const temp = [...this.state.items];
                                temp[key]["commodity"] = value.value;
                                this.setState({
                                  items: [...temp],
                                });
                                this.props.updateState("items", temp);
                                let oldItems = [
                                  ...this.props.originalLoad?.items,
                                ]
                                oldItems[0] && delete oldItems[0]?._id
                                oldItems?.forEach((item)=> {
                                  item && item._id && delete item._id
                                })
                                const noChange = _.isEqual(temp, this.props.originalLoad?.items)
                                if(noChange) return
                                this.onCommodityChanges(temp,key,"commodity")
                              }}
                            />
                          </div>
                        ) : (
                          <input
                            className={`form-control border-0 ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                            style={{transition: "background 0.4s ease-out"}}
                            type="text"
                            name="commodity"
                            autoComplete="off"
                            value={data.commodity}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlur={(e) => this.tblvalchanges(key, e, true)}
                          />
                        )}
                        </div>
                        {this.checkUpdateCommodityIndex(data?.itemId, "commodity") && <SyncError alert={this.getAlertInfo('items', key, null, 'commodity', data?.itemId)} subField={'commodity'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "commodity", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.requestID}/>}
                      </td>
                      <td className={`description-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "description") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'description', data?.itemId)}`: ""}`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="text"
                          name="description"
                          value={data.description}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                        {this.checkUpdateCommodityIndex(data?.itemId, "description") && <SyncError alert={this.getAlertInfo('items', key, null, 'description', data?.itemId)} subField={'description'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "description", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.requestID}/>}
                      </td>
                      <td className={`pieces-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "pieces") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'pieces', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="number"
                          name="pieces"
                          value={data.pieces}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                        {this.checkUpdateCommodityIndex(data?.itemId, "pieces") && <SyncError alert={this.getAlertInfo('items', key, null, 'pieces', data?.itemId)} subField={'pieces'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "pieces", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.requestID}/>}
                      </td>
                      <td className={`weight-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "weight") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'weight', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="number"
                          name="weight"
                          value={data.weight}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                        {this.checkUpdateCommodityIndex(data?.itemId, "weight") && <SyncError alert={this.getAlertInfo('items', key, null, 'weight', data?.itemId)} subField={'weight'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "weight", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.requestID}/>}
                      </td>
                      <td className={`weightKgs-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "weightKgs") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'weightKgs', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="number"
                          name="weightKgs"
                          value={data.weightKgs}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                        {this.checkUpdateCommodityIndex(data?.itemId, "weightKgs") && <SyncError alert={this.getAlertInfo('items', key, null, 'weightKgs', data?.itemId)} subField={'weightKgs'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "weightKgs", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.requestID}/>}
                      </td>
                      <td className={`pallets-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "pallets") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'pallets', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          type="number"
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          name="pallets"
                          value={data.pallets}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                        {this.checkUpdateCommodityIndex(data?.itemId, "pallets") && <SyncError alert={this.getAlertInfo('items', key, null, 'pallets', data?.itemId)} subField={'pallets'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "pallets", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.requestID}/>}
                      </td>

                      <td style={disabled ? {cursor: "not-allowed", transition: "background 0.4s ease-out"} : {transition: "background 0.4s ease-out"}}>
                        <div
                          type="button"
                          style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                          onClick={() => this.DeleteItemRow(key)}
                          className="btn btn-circle btn-xs bg-soft-danger"
                        >
                          <IconMinus />
                        </div>
                      </td>
                      {this.checkRemovedCommodityIndex(data?.itemId) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getRemovedCommodityTypeAtIndex(data?.itemId)?.alertMessageType} requestID={this.getRemovedCommodityTypeAtIndex(data?.itemId)?.requestID}/>}
                    </tr>
                  );
                })}
                {this.state.sentAddItemRequest?.length > 0 && this.state.sentAddItemRequest?.map((request) => {
                const data = request?.items?.commodity
                return (
                  <tr className="outline-primary outline-2 rounded-5 bg-brand-50 request-wrapper-items request-wrapper-cancel">
                    <td width="17%" className='td-commodity px-0 form-group m-0 pl-2'><div className="position-absolute top-0 left-0 bottom-0 d-flex align-items-center ml-15"><IconPlus className="text-primary mr-10"/>{data?.commodity}</div></td>
                    <td className='td-description px-0 pl-2'>{data?.description}</td>
                    <td width="10%" className='td-pieces px-0 pl-2'>{data?.pieces}</td>
                    <td width="10%" className='td-weight px-0 pl-2'>{data?.weight}</td>
                    <td width="10%" className='td-weightKgs px-0 pl-2'>{data?.weightKgs}</td>
                    <td width="10%" className='td-pallets px-0 pl-2'>{data?.pallets}</td>
                    <td width="2%"></td>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={alertMessageType.PENDING_SENT} requestID={request?.requestID}/>
                  </tr>
                )
              })}
              {this.state.receivedAddItemRequest?.length > 0 && this.state.receivedAddItemRequest?.map((request) => {
                const data = request?.items?.commodity
                return (
                  <tr className="outline-warning outline-2 rounded-5 bg-warning-50 mx-1 px-2 py-1 request-wrapper-items">
                    <td width="17%" className='td-commodity px-0 form-group m-0 pl-2'><div className="position-absolute top-0 left-0 bottom-0 d-flex align-items-center ml-15"><IconPlus className="text-warning mr-10"/>{data?.commodity}</div></td>
                    <td className='td-description px-0 pl-2'>{data?.description}</td>
                    <td width="10%" className='td-pieces px-0 pl-2'>{data?.pieces}</td>
                    <td width="10%" className='td-weight px-0 pl-2'>{data?.weight}</td>
                    <td width="10%" className='td-weightKgs px-0 pl-2'>{data?.weightKgs}</td>
                    <td width="10%" className='td-pallets px-0 pl-2'>{data?.pallets}</td>
                    <td width="2%"></td>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={alertMessageType.PENDING_RECIEVED}/>
                  </tr>
                )
              })}
            {this.state.addCommodity && <CommodityComponent commodityProfiles={this.state.commodityProfiles} handleAddCommodity={this.AddItemRows} items={this.state.items} discardRow={()=>this.setState({ addCommodity: false })}/>}
            </tbody>
          </table>
          <span style={disabled ? {cursor: "not-allowed"} : {}}>
          <div
            type="button"
            className="btn btn-white btn-sm text-primary mt-10"
            onClick={() => !disabled && this.setState({ addCommodity: true })}
            style={disabled || this.state.addCommodity ? {pointerEvents: "none", opacity: 0.4} : {}}
          >
            <IconPlus className="mr-2" />
            Add Row
          </div>
          </span>
        </div>
      </div>
    );
  }
}

export default NewRoadLoadComponent;

const StatusButton = ({
  updateState,
  buttonStatus,
  preSetOrderStatus,
  loadStatus,
}) => {
  return (
    <div className="col-md-4">
      <div className="form-check form-group">
        <input
          type="radio"
          disabled
          checked={preSetOrderStatus !== buttonStatus.value ? true : false}
          className="form-check-input"
          id="prepull-drop-hook"
          name="pre-order-status"
        />
        <label className="form-check-label" for="prepull-drop-hook">
          {buttonStatus.label ? buttonStatus.label : "DEFAULT"}
        </label>
      </div>
    </div>
  );
};