import { useRef, useState } from 'react';
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

import _ from "lodash";
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { IconLongArrowRight, IconSaveAsDefault, IconSquareApproved, IconTimer, IconTimes, IconTrash, IconLike, IconCheck } from "../../../Components/Common/Icons";
import RedirectToRates from "../../../Components/CustomerServiceSideBar/Tabs/Components/RedirectToRates";
import { smallSelectStyle } from "../../../assets/js/select-style";
import { addressFormatEnum, addressParser, amplitudeTrack, DateTimeFormatUtils, defaultAddressFormat, getStorage, isNegativeDriverPayAllowed, isNewSupportMovesEnabled, loadStatus, makeOptionsForSelect, toastr, updateNewTranscation } from "../../../services";
import { createManualDriverPay, getDurationInHHMMFormat } from "../LoadRouting/utilities";
import { TransitionEffect } from '../LoadRoutingV2/TransitionEffect';
import { deleteSupportMove, removeVendorNewRouting, updateNewSupportMove, updateSupportMove, updateSupportMoveStatus } from '../LoadRoutingV2/actionCreator';
import { SUPPORT_MOVES_LABEL, getAddressdefaultOptions } from '../LoadRoutingV2/helpers';
import ExactDateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper";
import { loadEvents, TRIP_TYPES } from "../constant";
import { manualDriverPay } from "../services";
import { ROUTING_ADDRESS_FIELDS } from './DriverOrderUtility';
import ReactTooltip from 'react-tooltip';
import { getTimeZone } from '../NewDispatcher/constants';
import LoadInfoModal from '../../../Components/LoadInfoModal/LoadInfoModal';
import { SUPPORT_MOVE_TO_ROUTING } from '../LoadRouting/constants';
import { updateChassisDetail, updateRoutingStatus, makeTripStartableToDriver, rejectTrip, updateTrip } from '../FreeFlow/actionsCreator';
import { getIsLoadOrTripLive, isDriverAcceptMovesEnabled } from 'Components/Common/functions';
import { AmplitudeSourceName, AmplitudeSupportMoveEvent } from "utils/AmplitudeEventConstant";
import ChassisValidationModal from '../LoadRoutingV2/ChassisValidationModal';

const NewSupportMove = (props) => {
    const [editDriverPayData, setEditDriverPayData] = useState();
    const [driverPays, setDriverPays] = useState();
    const [loadModal, setLoadModal] = useState();
    const isNewSupportMoveEnabled = isNewSupportMovesEnabled();
    const { customers, shippers, consignees, chassisTerms, isEmptyEnabled } = useSelector(
        (state) => state.TmsReducer,
        shallowEqual
    )
	const [chassisValidation, setChassisValidation] = useState(false)
	const savedFunction = useRef();


    const updateDriverPay = (driverPay, value, status, nextValidLeg) => {
        value = value.replace(driverPay?.invoiceCurrency?.symbol || props.currencySymbol, '');
        const loadDetails = {
            _id: isNewSupportMoveEnabled && props.supportMoveDetail?.tripType === TRIP_TYPES.SM_MANUAL && props.supportMoveDetail?._id ? props.supportMoveDetail?._id : props.supportMoveDetail?.prevLoadId,
            reference_number: isNewSupportMoveEnabled && props.supportMoveDetail?.tripType === TRIP_TYPES.SM_MANUAL && props.supportMoveDetail?.tripNumber ? props.supportMoveDetail?.tripNumber :props.supportMoveDetail?.prevReferenceNo
        }
        if (driverPay) {
            const _editDriverPayData = _.cloneDeep(driverPay);
            _editDriverPayData.amount = value;
            setEditDriverPayData(_editDriverPayData)
        }
        else if (status && nextValidLeg) {
            const _editDriverPayData = _.cloneDeep(createManualDriverPay(loadDetails, status, nextValidLeg));
            _editDriverPayData.amount = value;
            if (isNewSupportMoveEnabled && props.supportMoveDetail?.tripType === TRIP_TYPES.SM_MANUAL && props.supportMoveDetail?.tripNumber) _editDriverPayData.isTrip = true 
            if (props.supportMoveDetail?.terminal && props.supportMoveDetail?.tripType === TRIP_TYPES.SM_MANUAL) _editDriverPayData.terminal = props.supportMoveDetail?.terminal
            setEditDriverPayData(_editDriverPayData)
        }
    }

    const onBlurHandleForDrivePay = (field) => {
        const _editDriverPayData = _.cloneDeep(editDriverPayData);
        if (!_editDriverPayData) return;

        if (_editDriverPayData?._id) {
            const actualPay = props.driverPays.find((d) => d._id === _editDriverPayData._id);
            if (!actualPay || parseFloat(actualPay.amount) === parseFloat(_editDriverPayData.amount)) {
                setEditDriverPayData(null)
                return;
            }
            const params = {
                id: _editDriverPayData._id,
                amount: _editDriverPayData.amount || 0,
                isManual: true,
                isFromSettlement: false
            }
            updateNewTranscation(params)
                .then((response) => {
                    const _driverPays = _.cloneDeep(props.driverPays) || [];
                    if (response?.data?.data) {
                        const dpIndex = _driverPays.findIndex((d) => d._id === response.data.data._id);
                        if (dpIndex > -1) _driverPays.splice(dpIndex, 1, response.data.data);
                    }
                    props.setNewDriverPays(_driverPays)
                    setEditDriverPayData(null)
                    TransitionEffect(field, true);
                }).catch(err => {
                    TransitionEffect(field, false);
                })
        }
        else {
            delete _editDriverPayData?.invoiceCurrency?.symbol;
            delete _editDriverPayData.isManual;
            delete _editDriverPayData.currencySymbol;
            const params = {
                ..._editDriverPayData,
                supportMove: props.supportMoveDetail?._id
            };
            manualDriverPay(params).then(async (response) => {
                const _driverPays = _.cloneDeep(driverPays) || [];
                if (response?.data?.data) _driverPays.push(response.data.data);
                props.setNewDriverPays(_driverPays)
                setDriverPays(_driverPays)
                setEditDriverPayData(null)
                TransitionEffect(field, true)
            }).catch(err => {
                console.log("🚀 ~ manualDriverPay ~ err:", err)
                TransitionEffect(field, false)
            })
        }
    }
    const addEditEventAddress = async (index, value, status, className) => {
        if (value?.value === props.move?.[index]?.customerId?._id) return;

        const _supportMove = _.cloneDeep(props.move);
        let params = { 
            _id: _supportMove[index]?.supportMoveId, 
            customerId: value?.value, 
            eventId: _supportMove[index]?._id
        }
        props.setLoading(true);
        updateSupportMove(params)
        .then((res) => {
            props.setLoading(false);
            TransitionEffect(className, true);
        })
        .catch((err) => {
            props.setLoading(false)
            TransitionEffect(className, false)
            console.log("🚀 ~ updateNewSupportMove ~ err:", err)
        })
    };
    const _deleteSupportMove = (index) => {
        const _supportMove = _.cloneDeep(props.move);
        let params = {
            _id: _supportMove[index]?.supportMoveId
        }
        props.setLoading(true)
        savedFunction.current = () => _deleteSupportMove(index)
        deleteSupportMove(params)
            .then((data) => {
                if (data?.isChassisv3) {
                    setChassisValidation(data?.isChassisv3)
                    return
                }
                let eventProperties = {
                    source: AmplitudeSourceName.ROUTING_TAB,
                  }
                  amplitudeTrack(AmplitudeSupportMoveEvent.SUPPORT_MOVE_DELETE, eventProperties);
                props.setLoading(false)
            })
            .catch((err) => {
                props.setLoading(false)
                console.log("🚀 ~ _deleteSupportMove ~ err:", err)
            })
    }
    const updateArrivedOrDeparted = (index, name, isRemove= false, date= null, className=null) => {
        const _supportMove = _.cloneDeep(props.move);
        if (date && (date === _supportMove[index].departed || date === _supportMove[index].arrived)) return;
        let params = {
            _id: _supportMove[index]?.supportMoveId,
            tripOrderId: _supportMove[index]?._id,
        }
        if (date) {
            params.time = date;
            params.type = name;
        } else {
            params.status = name;
            params.isRemove = isRemove;
        }
    
        props.setLoading(true)
        const APIName = date ? updateTrip : updateRoutingStatus;
        APIName(params)
          .then((result) => {
            toastr.show("You have updated the status!", "success");
            props.setLoading(false);
            if (className) {
                TransitionEffect(className, true)
            }
          })
          .catch((err) => {
            console.log("🚀 ~ file: index.jsx:728 ~ updateArrivedOrDeparted ~ err:", err)
            props.setLoading(false)
            if (className) {
                TransitionEffect(className, false)
            }
          })
      }

      const updateLegStatus = (tripId, legId, status) => {
        const params = {
          _id: tripId,
          tripOrderId: legId,
          status: status,
        };
        updateRoutingStatus(params)
          .then((res) => {
            // setIsLoading(false);
            // updateTripDetails(res)
          })
          .catch((err) => {
            console.log("🚀 ~ updateLegStatus ~ err:", err)
            // setIsLoading(false);
          });
      };
    const move = props.move;
    const firstMoveEvent = move[0];
    const isComplete = ["COMPLETED"].includes(props?.supportMoveDetail?.status)
    
    const handleMakeTripStartableToDriver = (e)=>{
        e.stopPropagation();
        makeTripStartableToDriver({
          _id: props?.supportMoveDetail?._id,
        })
        .then(() => {
          toastr.show("Trip Started", "success");
        })
        .catch((err) => {
          toastr.show(err.data.message, "error");
        });
      }

    const onChassisEventSuccess = (result) => {
        props.setLoading(false)
        if (savedFunction.current) {
            savedFunction.current()
            savedFunction.current = null
        }
    }

    const onChassisEventFailure = () => {
        props.setLoading(false)
    }

    const CustomValueContainer = ({ children, ...prop }) => {
        const selectedValue = prop?.getValue()[0]?.label;
        const isLoadLiveOrCompleted = getIsLoadOrTripLive({ ...props?.supportMoveDetail, isTrip:true })
        const isMoveAccepted =  props?.supportMoveDetail?.tripOrder?.length > 0 && props?.supportMoveDetail?.tripOrder?.every((trip) => trip?.accepted);
        
        return (
          <>
            <components.ValueContainer {...prop} className='pr_5'>
              <div className="d-flex align-items-center align-items-center justify-content-between">
                {children}
                {
                    selectedValue && (
                    <>
                        <div className="d-flex align-items-center">
                            {!isLoadLiveOrCompleted && isMoveAccepted ? (
                                <span className="wh-24px bg-brand-50 rounded-3 d-inline-flex align-items-center justify-content-center">
                                    <IconLike className="text-blue-500 wh-16px" />
                                </span>)
                                :
                                <button
                                    className="btn btn-xs btn-success"
                                    disabled={props?.supportMoveDetail?.isTripStartable || props?.supportMoveDetail?.status === "COMPLETED"}
                                    onMouseDown={handleMakeTripStartableToDriver}
                                >
                                    <IconCheck />
                                </button>}

                            {(
                                <button
                                    className="btn btn-xs btn-danger ml-1"
                                    onClick={(event) => {
                                        let params = {
                                            loadId: props.supportMoveDetail?.loadId,
                                            moveId: props?.supportMoveDetail?.tripOrder?.[0]?.moveId
                                        }
                                        props.setLoading(true)
                                        savedFunction.current = () => removeVendorNewRouting(params)
                                        removeVendorNewRouting(params)
                                            .then((data) => {
                                                if (data?.isChassisv3) {
                                                    setChassisValidation(data?.isChassisv3)
                                                    return
                                                }
                                                props.setLoading(false)
                                            })
                                            .catch((err) => {
                                                props.setLoading(false)
                                                console.log("🚀 ~ _deleteSupportMove ~ err:", err)
                                            })
                                    }}
                                >
                                    <IconTimes />
                                </button>
                            )}
                        </div>
                    </>
                  )
                }
              </div>
            </components.ValueContainer>
          </>
      
        );
      };

    const getComponentProps = () => {
        const isSupportMoveSystem = props?.supportMoveDetail?.tripType === "SM_SYSTEM";
        if (!isDriverAcceptMovesEnabled() || isSupportMoveSystem || props?.supportMoveDetail?.status === "COMPLETED") {
            return {
                Component: {},
                isDisabled: true,
            };
        }
        return {
            Component: { ValueContainer: CustomValueContainer },
            isDisabled: false,
        };
    };

    const { Component, isDisabled } = getComponentProps();
    
    return (
        <>
            <div className="card card-blue mb-10 p-15">
                <div className="">
                    <div className="row mb-0 align-items-center pb-3">
                        <span>
                            <div className={`btn bg-purple-150 rounded-6 card-${props.legColor}__avatar text-white mx-10 line-height-18 ml-15`}>
                                <div className="text-purple-400 font-12 font-weight-500">{`Support Move`}</div>
                            </div>

                        </span>
                        <div className="p-0">
                            <Select
                                value={firstMoveEvent?.driver ? {
                                    label: `${firstMoveEvent.driver.name} ${firstMoveEvent.driver.lastName || ""}`.trim(),
                                    value: firstMoveEvent.driver._id
                                } : ""}
                                className={`w-200 `}
                                isDisabled={isDisabled}
                                styles={smallSelectStyle}
                                components={Component}
                                menuIsOpen={false}
                                isSearchable={false}
                            />
                        </div>
                        <div className="p-0">
                        <div className="form-group w-200 mb-0 ml-10 date__picker-wrapper h-30">
                            <div className={`input-wrapper loadAssignDate`}>
                            <span className="input-icon-left" data-tip data-for={`loadAssignDate`}>
                                <IconTimer className="text-gray-300" />
                            </span>
                            <ReactTooltip
                                effect="solid"
                                id={`loadAssignDate`}
                                className="type-gray w-300 text-wrap text-break line-break"
                            >
                                <span>Use this date to select when you want to schedule the driver to work on the load</span>
                            </ReactTooltip>
                            <div>
                                <ExactDateWrapper
                                className={`loadAssignDate laDate`}
                                inputClassName="pl-35"
                                dateFormat="MM-DD-yyyy"
                                timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                isShowTime={true}
                                displayTimeZone={getTimeZone({preferred: true})}
                                disabled={true}
                                defaultDate={firstMoveEvent?.tripAssignedDate ? moment(firstMoveEvent.tripAssignedDate): ""}
                                // OnBlurUpdate={(e) => _updateLoadAssignDate(e, `loadAssignDate-${dIndex}`, move)}
                                hideShowSelectDate={true}
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-3 p-0 ml-4">
                            <div className='d-flex align-items-center'>
                                {!firstMoveEvent.isVoidOut && !firstMoveEvent.arrived && (
                                    <button
                                        className="btn btn-xss btn-purple-400 w-150 text-center"
                                        disabled={ !firstMoveEvent?.driver || firstMoveEvent?.eventId}
                                        onClick={() => updateLegStatus(firstMoveEvent.supportMoveId, firstMoveEvent?._id, "arrived")}
                                    >
                                        <span className="w-100">Start Move</span>
                                    </button>
                                )}

                                {(firstMoveEvent.driver || firstMoveEvent.drayosCarrier) && !firstMoveEvent.isVoidOut && firstMoveEvent.arrived && (

                                    <div className="form-group w-200 mb-0 h-30">
                                        <div className={`input-wrapper startMoveDate-firstMoveEvent`}>
                                            <span className="input-icon-left" data-tip data-for={`startMoveDate-firstMoveEvent`}>
                                                <IconSquareApproved className="text-gray-300" />
                                            </span>
                                            <ExactDateWrapper
                                                className={`startMoveDate-firstMoveEvent laDate right-0`}
                                                displayTimeZone={getStorage("timeZone")}
                                                inputClassName="pl-35"
                                                componentClassName={`date-arrived-${firstMoveEvent._id}`}
                                                disabled={isComplete || firstMoveEvent?.type !== loadEvents.CHASSISPICK}
                                                defaultDate={firstMoveEvent.arrived ? moment(firstMoveEvent.arrived) : ""}
                                                dateFormat="MM-DD-yyyy hh:mm A"
                                                timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                OnBlurUpdate={(_date) => updateArrivedOrDeparted(
                                                    0, 'arrived', false, _date, `support_move_arrivedDate-firstMoveEvent`
                                                )}
                                                hideShowSelectDate={true}
                                                isShowTime={true}
                                            />
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        {move?.length && move.map((_mEvent, index) => {
                            const nextValidLegIndex = move.findIndex((d, i) => i > index && !d.isVoidOut);
                            const dynamicHeight = `${111 * (nextValidLegIndex - index - 1) + 93 + "px"}`;
                            const nextValidLeg = move[nextValidLegIndex];
                            let nextEventDuration = getDurationInHHMMFormat(nextValidLeg?.time, _mEvent?.time);
                            const carrierDetails = JSON.parse(getStorage("carrierDetail"));
                            const distanceMeasure = carrierDetails?.carrier?.distanceMeasure || "mi";
                            const isEditingPay = editDriverPayData
                            let driverPay = props?.driverPays?.find(pay => pay.fromEventId === _mEvent._id && pay?.createdBy?._id === firstMoveEvent?.driver?._id);
                            if (isEditingPay) driverPay = editDriverPayData;
                            const regex = isNegativeDriverPayAllowed() ? /[0-9.-]/ : /[0-9.]/;
                            let timeDurationOfEvent = getDurationInHHMMFormat(nextValidLeg?.arrived, _mEvent?.departed);
                            const addressComp = ROUTING_ADDRESS_FIELDS[_mEvent.type]
                            const formattedAddress = addressParser(_mEvent);
                            const isCompletedSupportMove =  props?.supportMoveDetail?.status === "COMPLETED";
                            const isDisabled =  (_mEvent?.eventId && [TRIP_TYPES.SM_MANUAL].includes(firstMoveEvent.tripType)) || firstMoveEvent?.tripType === TRIP_TYPES.SM_SYSTEM;
                            const isDisabledForNextLeg = (nextValidLeg?.eventId && [TRIP_TYPES.SM_MANUAL].includes(firstMoveEvent.tripType)) || firstMoveEvent?.tripType === TRIP_TYPES.SM_SYSTEM;
                            
                            return (
                                <div className="position-relative">
                                    {index !== (move.length - 1) && !_mEvent.isVoidOut && (<div className="position-absolute driver__right" style={{ maxWidth: "270px" }}>
                                        <div className="position-relative z-1">
                                            <div className="position-absolute assign-arrow" style={{ height: dynamicHeight }}></div>
                                            <div className="pl-40">
                                                <div className="d-flex align-items-center mb-1">
                                                    <span className="mr-1 text-muted font-12 font-weight-normal">Driver Pay</span>
                                                    <div className="form-group m-0" style={{ width: "103px" }}>
                                                        <div className={`input-wrapper driverPay-${index}-${index}`}>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    name="driverPay"
                                                                    disabled={props.isLoading}
                                                                    className={`form-control form-control-xs driverPay-${index}-${index} pr-30 transition-white-field`}
                                                                    id={`driverPay-${index}-${index}`}
                                                                    placeholder={props.currencySymbol + "00.00"}
                                                                    value={
                                                                        driverPay
                                                                            ? `${driverPay?.invoiceCurrency?.symbol || props?.currencySymbol}${isEditingPay
                                                                                ? driverPay.amount
                                                                                : parseFloat(driverPay.amount || 0)?.toFixed(2)
                                                                            }`
                                                                            : ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        updateDriverPay(driverPay, e.target.value, _mEvent, nextValidLeg)
                                                                    }
                                                                    onBlur={(e) => onBlurHandleForDrivePay(`driverPay-${index}-${index}`)}
                                                                    onKeyPress={(event) => {
                                                                        if (event.key === "Enter") {
                                                                            onBlurHandleForDrivePay(`driverPay-${index}-${index}`)
                                                                        }
                                                                        if (!regex.test(event.key) ||
                                                                            (event.target.value.replace(driverPay?.invoiceCurrency?.symbol || props.currencySymbol, '').trim().indexOf('.') != -1
                                                                                && event.key === ".") || (event.key === "-" && event.target.value.replace(driverPay?.invoiceCurrency?.symbol || props.currencySymbol, '').trim() !== '')
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="input-icon p-0 pr-10">
                                                                {driverPay ?
                                                                    <RedirectToRates
                                                                        info={driverPay}
                                                                        redirectToRates={props?.redirectToRates}
                                                                    /> :
                                                                    <IconSaveAsDefault />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-1">
                                                    <span className="mr-15 text-muted font-12 font-weight-normal">Duration</span>
                                                    <div className="text-dark  font-12 font-weight-bold">{nextValidLeg?.time ? nextEventDuration : "-"}</div>
                                                </div>
                                                <div className="d-flex align-items-center mb-1">
                                                    <span className="mr-15 text-muted font-12 font-weight-normal">Distance</span>
                                                    <div className="text-dark  font-12 font-weight-bold">{(_mEvent.customerId && nextValidLeg.customerId && move?.[index+1]?.distance >= 0) ? move?.[index+1]?.distance + " " + distanceMeasure : "-"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                    <div className={"outline-card p-10 mb-1"}>
                                        <div className="d-flex align-items-center mb-10">
                                            <span className="font-14">
                                                {SUPPORT_MOVES_LABEL[_mEvent.type]}
                                            </span>
                                            {_mEvent.conReferenceNo && props.reference_number !== _mEvent.conReferenceNo && <>
                                                <span className="font-12 font-weight-500 text-dark p-1 border-gray-100 border-1 ml-20 ml-auto rounded pointer" 
                                                onClick={(e) => {
                                                    props.updateLoadInfo && props.updateLoadInfo(_mEvent.conReferenceNo);
                                                    e.stopPropagation();
                                                }} 
                                                data-tip 
                                                data-for={`support-move-${_mEvent.conReferenceNo}`}>
                                                    <IconLongArrowRight className="mr-1" /> {_mEvent.conReferenceNo}
                                                </span>
                                                <ReactTooltip
                                                    effect="solid"
                                                    id={`support-move-${_mEvent.conReferenceNo}`}
                                                    className="type-gray text-wrap text-break line-break"
                                                >
                                                    <span>Transfer Connected</span>
                                                </ReactTooltip>
                                            </>
                                            }
                                            {!isCompletedSupportMove && ((props.reference_number === _mEvent.conReferenceNo && ![TRIP_TYPES.SM_MANUAL].includes(firstMoveEvent.tripType)) || (!isDisabled && [TRIP_TYPES.SM_MANUAL].includes(firstMoveEvent.tripType))) && !_mEvent.departed && <>
                                                <button
                                                    className="btn btn-link text-danger p-0 ml-auto"
                                                    onClick={() => {
                                                        _deleteSupportMove(index)
                                                    }}
                                                >
                                                    <IconTrash />
                                                </button>
                                            </>
                                            }
                                        </div>
                                        <div className="d-flex form-row-md mb-10">

                                            <div className="form-group col">
                                                <div className="w-100">
                                                    <AsyncSelect
                                                        className={`w-100 support-move-event-${index}`}
                                                        defaultOptions={makeOptionsForSelect(
                                                            getAddressdefaultOptions(_mEvent, customers, chassisTerms, shippers, consignees),
                                                            "company_name",
                                                            "address.address",
                                                            "_id"
                                                        )}
                                                        cacheOptions
                                                        value={
                                                            defaultAddressFormat() === addressFormatEnum.EU
                                                                ? _mEvent.customerId && _mEvent.customerId?._id
                                                                    ? {
                                                                        label: `${_mEvent.customerId?.company_name} ${formattedAddress}`?.trim(),
                                                                        value: _mEvent.customerId?._id,
                                                                    }
                                                                    : null
                                                                : _mEvent.customerId && _mEvent.customerId?._id
                                                                    ? {
                                                                        label: `${_mEvent.customerId?.company_name} ${_mEvent?.customerId?.address?.address || ""}`?.trim(),
                                                                        value: _mEvent.customerId._id,
                                                                    }
                                                                    : null
                                                        }
                                                        loadOptions={addressComp ? addressComp.loadOptions : null}
                                                        onChange={(value) => addEditEventAddress(index, value, _mEvent, `support-move-event-${index}`)}
                                                        styles={smallSelectStyle}
                                                        isDisabled={isCompletedSupportMove || isDisabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-0 align-items-center">
                                            {!_mEvent.isVoidOut &&  !_mEvent.departed &&(
                                                <button
                                                    className="btn btn-xss btn-purple-400 w-150 text-center"
                                                    onClick={() => updateLegStatus(_mEvent.supportMoveId, _mEvent?._id, "departed")}
                                                    disabled={!firstMoveEvent?.driver || isCompletedSupportMove || isDisabled}
                                                >
                                                    <span className="w-100">Arrived</span>
                                                </button>
                                            )}
                                            {(_mEvent.driver || _mEvent.drayosCarrier) && !_mEvent.isVoidOut && _mEvent.departed && (
                                                <div className="form-group mb-0">
                                                    <div className={`input-wrapper support_move_departedDate-${index}`}>
                                                        <ExactDateWrapper
                                                            className={`support_move_departedDate-${index} laDate`}
                                                            isShowTime={true}
                                                            dateFormat="MM-DD-yyyy hh:mm A"
                                                            componentClassName={`date-departed-${_mEvent._id}`}
                                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                            displayTimeZone={getStorage("timeZone")}
                                                            defaultDate={_mEvent.departed ? moment(_mEvent.departed) : ""}
                                                            disabled={isCompletedSupportMove || isDisabled}
                                                            OnBlurUpdate={(_date) =>
                                                            	updateArrivedOrDeparted(
                                                            		index, 'departed', false, _date, `support_move_departedDate-${index}`
                                                            	)
                                                            }
                                                            hideShowSelectDate={true}
                                                            name="arrivedDate"
                                                        />
                                                            <div
                                                                className="input-icon p-0 pr-10 text-muted"
                                                                // onClick={() => updateArrivedOrDeparted(index, 'departed', true)}
                                                            >
                                                                {/* <IconTimes /> */}
                                                            </div>
                                                    </div>
                                                </div>
                                            )}
                                            {nextValidLeg && !_mEvent.isVoidOut &&   (
                                                <div className="mx-10 bg-gray-50 p-1 minwidth-60px px-1 h-24px text-center rounded-lg">
                                                    {(_mEvent?.arrived && _mEvent?.departed) ? (
                                                        
                                                        <span className="font-10 font-weight-bold">{timeDurationOfEvent}</span>
                                                    ) : (
                                                        <span
                                                            className="font-12 font-weight-bold"
                                                            style={{
                                                                color: "rgba(23, 42, 65, 0.5)",
                                                            }}
                                                        >
                                                            -
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                             {nextValidLeg && !nextValidLeg.isVoidOut && !nextValidLeg.arrived && (
                                                <button
                                                    className="btn btn-xss btn-purple-400 w-150 text-center"
                                                    onClick={() => updateArrivedOrDeparted(index, 'arrived')}
                                                    disabled={!firstMoveEvent?.driver ||isCompletedSupportMove || isDisabledForNextLeg}
                                                >
                                                    <span className="w-100">Departed</span>
                                                </button>
                                            )}
                                            {nextValidLeg && (nextValidLeg.driver || nextValidLeg.drayosCarrier) && !nextValidLeg.isVoidOut && nextValidLeg.arrived && (
                                                <div className="form-group mb-0">
                                                    <div className={`input-wrapper support_move_arrivedDate-${index}`}>
                                                        <ExactDateWrapper
                                                            className={`support_move_arrivedDate-${index} laDate`}
                                                            isShowTime={true}
                                                            dateFormat="MM-DD-yyyy hh:mm A"
                                                            componentClassName={`date-arrived-${nextValidLeg._id}`}
                                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                            displayTimeZone={getStorage("timeZone")}
                                                            defaultDate={nextValidLeg.arrived ? moment(nextValidLeg.arrived) : ""}
                                                            disabled={isCompletedSupportMove || isDisabledForNextLeg}
                                                            OnBlurUpdate={(_date) =>
                                                            	updateArrivedOrDeparted(
                                                            		index, 'arrived', false, _date, `support_move_arrivedDate-${index}`
                                                            	)
                                                            }
                                                            hideShowSelectDate={true}
                                                            name="arrivedDate"
                                                        />
                                                            <div
                                                                className="input-icon p-0 pr-10 text-muted"
                                                                // onClick={() => updateArrivedOrDeparted(index, 'arrived', true)}
                                                            >
                                                                {/* <IconTimes /> */}
                                                            </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                       
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="col" style={{ maxWidth: "240px" }}></div>
                </div>
            </div>
            {chassisValidation && <ChassisValidationModal
				validations={chassisValidation}
				loadDetails={props.loadDetails}
				onChassisEventSuccess={onChassisEventSuccess}
				onChassisEventFailure={onChassisEventFailure}
                updateSupportMovesInLocal={props.updateSupportMovesInLocal}
			/>}
        </>
    );
}

export default NewSupportMove
