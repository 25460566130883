import React, { useState, useRef, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import AsyncSelect from "react-select/async";
import { useOnClickOutside } from "hooks";
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import {
  IconAngleArrowDown,
  IconAngleArrowTop,
  IconInfoCircleFilledWhite,
  IconMinus,
  IconPlus,
  Icondots, 
  IconTrash,
} from "../../../../../Components/Common/Icons";
import AddChargeTable from "./addChargeTable";
import { getCustomerGroupAsyncSearch, getCustomers } from "../../service/customerRateService";
import { useCustomerSelect } from "../../hooks/useCustomerSelect";
import { useChargeGroup } from "../../hooks/useChargeGroup";
import { convertProfileToOption } from "../../service/profileService";

const AddNewCharge = ({ initialData, onUpdate, isDisabledAllCustomerRateModal, formErrors}) => {
  const [chargeCollapsable, setShowChargeCollapsable] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Use an object to track open/closed state
  const dropdownRefs = useRef(null); // Create an array of refs

  const handleToggleChargeCollapsable = () => {
    setShowChargeCollapsable((prevShowFormRow) => !prevShowFormRow);
  };

  const { customerFilterOptions, matchOptions, matchCustomer } = useCustomerSelect();
  const { chargeGroups, addChargeGroup, updateCharges, updateBillTo, removeChargeGroup } = useChargeGroup({
    initialData: initialData,
    onUpdate: onUpdate,
  });

  const toggleDropdown = (e, i) => {
    e.stopPropagation();
    setIsDropdownOpen((prevIndex) => (prevIndex === i ? null : i));
  };

  useOnClickOutside(dropdownRefs, () => {
    setIsDropdownOpen(null); // Close all dropdowns when clicking outside
  });

  return (
    <div className="card bg-gray-50 p-15 mb-10" key={chargeGroups}>
      <div className="font-16 font-medium mb-10 pointer" onClick={handleToggleChargeCollapsable}>
        Charge Sets
        <span data-tip data-for="charge-group1">
          <IconInfoCircleFilledWhite className="ml-1" />
        </span>
        <ReactTooltip id="charge-group1" type="dark" effect="solid" className="w-350">
          Charge Sets define the specific charges that will be automatically added to Loads.  For each Charge Set, you can specify the Bill To party.  
          For example, one Charge Set can add Base and Standard Accessorial charges billed to the Load Customer, and another Charge Set could add Per Diem charges billed to a another party.
        </ReactTooltip>
        {chargeCollapsable ? <IconAngleArrowTop className="ml-2" /> : <IconAngleArrowDown className="ml-2" />}
      </div>
      {chargeCollapsable && (
        <>
          {chargeGroups?.map((chargeGroup, i) => {
            const hasTableRowErrors = formErrors?.ruleErrorMessages?.[i];
            const chargeProfilesErr = formErrors?.profileError?.[i];
            
            return (
              <div key={i} className="card card-tborder border-gray-500 p-10 mb-10 position-relative" id={`charge-group-${i}`}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between gap-10">
                    <div className="font-14 font-medium">
                        <span className="text-danger">*</span>Bill To
                      </div>
                      <AsyncSelect
                        defaultOptions={customerFilterOptions}
                        placeholder="Select Customer"
                        value={convertProfileToOption(chargeGroup.billTo)}
                        onChange={(data) => updateBillTo(i, data)}
                        loadOptions={(search) => {
                          return getCustomerGroupAsyncSearch(search, matchOptions)
                        }} // TODO
                        styles={smallSelectStyle}
                        isClearable={true}
                      />
                  </div>
                  <div ref={isDropdownOpen === i ? dropdownRefs : null} className="position-relative">
                    <button className="btn btn-link btn-sm" onClick={(e) => toggleDropdown(e, i)}>
                      <Icondots />
                    </button>
                    {isDropdownOpen === i && (
                      <ul className="card card--shadow-5 popdrop popdrop--bottom-no-space popdrop--right position-absolute mb-0 p-2 animated w-200">
                        <li
                          onClick={() => {
                              removeChargeGroup(i); 
                              setIsDropdownOpen(null);
                          }}
                          className="rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer text-danger"
                        >
                          <IconTrash className="mr-10" />
                          Remove Charge Set
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                <AddChargeTable 
                  initialData={chargeGroup} 
                  onUpdate={(data) => updateCharges(i, data)} 
                  isDisabledAllCustomerRateModal={isDisabledAllCustomerRateModal}
                  hasTableRowErrors={hasTableRowErrors}
                  chargeProfilesErr={chargeProfilesErr}
                />
              </div>
            );
          })}
          <div className={`${chargeGroups?.length === 0 ? "card mb-0 bg-gray-100 p-10 align-items-center" : "mt-10"}`}>
            <button className="btn btn-white btn-sm text-primary" onClick={() => {
              addChargeGroup(matchCustomer?.parameterProfile)
            }}>
              <IconPlus className="mr-1" />
              Add New Charge Set
            </button>
          </div>
        </>
      )}

    </div>
  );
};

export default AddNewCharge;