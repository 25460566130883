import React from "react";
import Skeleton from "../Common/Skeleton";

const TableSkeleton = ({ tableHeaders, isCheckboxAvailable, size, isSentTab = false }) => {
  const skeletonLength = isCheckboxAvailable ? tableHeaders.length + 1 : tableHeaders.length;
  return (
    <table>
      <thead>
        <tr>
          {isCheckboxAvailable && (
            <th style={{ width: 42 }} className="pl-2 pt-2">
              <input type="checkbox" style={{height: '16px', width: '16px'}} />
            </th>
          )}
          {tableHeaders.map((item, index) => (
            <th style={item?.style || {}} className="pl-10 font-11 font-semi-bold text-muted">
              {(isSentTab && item?.label === "From/To") ? "To" : item?.label || ""}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: skeletonLength }).map((_, index) => (
          <td>
            <Skeleton count={size || 30} width='100%' height='30px' />
          </td>
        ))}
      </tbody>
    </table>
  );
};
export default TableSkeleton;