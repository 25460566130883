export const containerTableHeaders = {
    SIZE_NAME: "Size Name",
    LABEL: "Label",
    CONTAINER_NAME: "Container Name",
    CONTAINER_OWNER_TITLES: ["Company Name", "Label", "Contact Name", "Address", "Email"],
    SCAC: "SCAC"
}

export const containerFreeDayTableHeaders = {
    CUSTOMER: "Customer",
    OWNER: "Owner",
    TYPE: "Type",
    HOLIDAY: "Holiday",
    FIRST_WEEKEND: "First Weekend",
    DAY: "Day",
}

export const ISOCodesTableHeaders = {
    CONTAINER_SIZE: "Container Size",
    CONTAINER_TYPE: "Container Type",
    ISO_CODE: "ISO Code"
}