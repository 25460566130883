// import "./styles.css";
import React, { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { IconDot } from "../../../../../../Components/Common/Icons";
import { COLORS, HOVER_COLORS, ICON_COLORS, REPORT_TYPES } from "../../constants";
import Popup from "../Popup";
import { fixedNumer } from "../../helper";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, tooltipPostFix, isToFixedNeeded, reportType }) => {
    if (active && payload && payload.length) {
        const tooltipData = payload[0]
        return (
            <div className="p-3 rounded-5 bg-white">
                <div className="d-flex">
                    <IconDot />
                    <div className="text-dark">{`${tooltipData?.name} ${reportType === REPORT_TYPES.LOADS_BY_TYPE ? `${tooltipData?.value} Loads` :''}`}</div>
                </div>
                    {reportType !== REPORT_TYPES.LOADS_BY_TYPE && <div className="font-12 ml-2">{`${isToFixedNeeded ? fixedNumer(tooltipData?.value) : tooltipData?.value} ${tooltipPostFix || 'Loads'}`}</div>}
                <div className="font-12 ml-2" style={{ color: "#367BF6" }}>Click to view more details</div>
            </div>
        );
    }

    return null;
};

export default function PieDonutChart(props) {
    const {
        isHalfPieChart,
        isHoleChart,
        reportData: data,
        isPopup,
        setIsPopup,
        downloadCSV,
        isTooltipVisible = false,
        startDate,
        endDate,
        tooltipPostFix,
        isToFixedNeeded,
        reportType,
        innerRadius: customInnerRadius = 30,
        // getDataForPieChart,
        // exportPieChartData
    } = props

    const endAngle = isHalfPieChart && 180 || 360;
    const innerRadius = isHoleChart ? customInnerRadius : 0;
    const paddingAngle = isHoleChart && 2 || 0;
    const outerRadius = isHalfPieChart ? "200%" : "100%";
    const cyValue = isHalfPieChart ? "100%" : "50%";

    const [activeIndex, setActiveIndex] = useState(null);
    // const [isPopup, setIsPopup] = useState(false)

    const handlePieceClick = () => {
        setIsPopup(true)
        // getDataForPieChart()
    };

    const handleOnCloseOfPopup = () => {
        setIsPopup(false)
    }

    const handleMouseEnter = (_, index) => {
        if(!isTooltipVisible) return
        setActiveIndex(index + 1);
    };

    const handleMouseLeave = () => {
        if(!isTooltipVisible) return
        setActiveIndex(null);
    };

    // const renderCustomLabel = ({ name, value }) => {
    //     if(isTooltipVisible) return ''
    //     return `${value}`;
    // };

    const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        if(isTooltipVisible) return ''
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(2)}%`}
          </text>
        );
      };

    const getFillColors = (index) => {
        let fillColor = COLORS[index % COLORS.length]
        if ((index + 1) && activeIndex) fillColor = index === (activeIndex - 1) ? COLORS[index % COLORS.length] : HOVER_COLORS[index % HOVER_COLORS.length]
        return fillColor
    };

    return (
        <>
            <ResponsiveContainer height={"50%"} maxHeight={"100%"} maxWidth={"100%"} className="flex-grow-1" >
                <PieChart>
                    <Pie
                        data={data?.filter(e => e.count)}
                        startAngle={endAngle}
                        endAngle={0}
                        labelLine={false}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        fill="#8884d8"
                        dataKey="count"
                        paddingAngle={paddingAngle}
                        cy={cyValue}
                        onClick={handlePieceClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        label={renderCustomLabel}
                    >
                        {data?.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={getFillColors(index)}
                            />
                        ))}
                    </Pie>

                    {/* Add custom tooltip */}
                    {isTooltipVisible && <Tooltip content={<CustomTooltip payload={data} tooltipPostFix={tooltipPostFix} isToFixedNeeded={isToFixedNeeded} reportType={reportType}/>} position={{ y: 10 }} />}
                </PieChart>
            </ResponsiveContainer>
            <div className="d-flex justify-content-center gap-10 flex-wrap">
                {data?.map((entry, index) => {
                    return (
                        <div key={`pie_${index}`} className="d-flex align-items-center justify-content-center">
                            <div><IconDot className={ICON_COLORS[index % ICON_COLORS.length]} /></div>
                            <div className="font-12 font-weight-normal text-gray-300">{entry?.name}</div>
                            <div className="badge bg-gray-50 font-12 ml-2 badge-pill">{`${tooltipPostFix === "%" ? `${isToFixedNeeded ? fixedNumer(entry?.count) : entry?.count} ${tooltipPostFix}` : (isToFixedNeeded ? fixedNumer(entry?.count): entry?.count)}`}</div>
                        </div>
                    )
                })}
            </div>
            {isPopup &&
                <Popup
                    rows={data}
                    downloadCSV={downloadCSV}
                    reportType={props?.reportType}
                    isPopup={isPopup}
                    handleOnClose={handleOnCloseOfPopup}
                    fromDate={startDate}
                    toDate={endDate}
                />}
        </>
    );
}
